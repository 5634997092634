import { useCallback } from 'react';

import { Stack } from '@mui/material';

import { DateConstants, formatDate } from '@ecp/utils/date';

import { Alert, Button } from '@ecp/components';
import { useModalPath } from '@ecp/features/servicing/shared/routing';
import type { UseModalPathReturn } from '@ecp/features/servicing/shared/routing';
import type { UseUpdateVinReturn } from '@ecp/features/servicing/shared/state';
import { useSharedState } from '@ecp/features/servicing/shared/state';
import { IconMaterialDesignActionCheckCircle } from '@ecp/themes/base';

import { useStyles } from './EditVinConfirmation.styles';

export const EditVinConfirmation: React.FC<UseModalPathReturn> = (props) => {
  const { classes } = useStyles();
  const { sharedStateKey } = props;
  const [editVinResponse] = useSharedState<UseUpdateVinReturn>(sharedStateKey);
  const modalPath = useModalPath();
  const handleClose = useCallback(() => {
    modalPath.reset();
  }, [modalPath]);

  return (
    <Stack spacing={4}>
      <div className={classes.successIconWrapper}>
        <IconMaterialDesignActionCheckCircle className={classes.successIcon} />
      </div>
      <Stack spacing={2}>
        <h2>Your request was successfully submitted!</h2>
        <Stack spacing={1.5}>
          <p className={classes.subHeader}>Confirmation {editVinResponse?.confirmationId}</p>
          <p>
            We have received your request to edit your vehicle identification number, effective{' '}
            <b>{formatDate(editVinResponse?.date, DateConstants.MONTH_FULL_NAME_LONG_FORMAT)}</b>.
          </p>
          <Alert withIcon type='info'>
            <Stack spacing={3}>
              <p>Your changes will not immediately appear on your online account page.</p>
              <p>
                If you do not see the changes to your policy within three business days, please
                contact us.
              </p>
            </Stack>
          </Alert>
        </Stack>
      </Stack>
      <Stack spacing={1.5} component='ul' className={classes.list}>
        <li className={classes.listContent}>
          A revised declaration page and vehicle identification cards will be available online after
          we process your request
        </li>
        <li className={classes.listContent}>
          If your request resulted in a premium change, your billing will be adjusted and the
          revised premium will be reflected on your new declaration page; any new or remaining
          premium payment amounts will be updated online to reflect the change.
        </li>
        <li className={classes.listContent}>
          A confirmation email will be sent once the request has been processed.
        </li>
      </Stack>
      <Button className={classes.closeButton} variant='primary' onClick={handleClose}>
        CLOSE
      </Button>
    </Stack>
  );
};
