import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CreateAccountPage' })((theme) => ({
  root: {
    width: '100%',
  },
  subtitle: theme.typography.body3,
  loginLink: { color: theme.palette.text.link },

  errorTitle: theme.typography.body1Bold,
  navlink: {
    color: theme.palette.text.link,
  },
  infoText: { ...theme.typography.body2, color: theme.palette.text.secondary },
  submitButton: {
    width: 'fit-content',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));
