import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PageLayout' })(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    position: 'relative',
  },
}));
