import { useCallback, useState } from 'react';

import { Stack } from '@mui/material';
import dayjs from 'dayjs';

import { LoadingOverlay, ModalError } from '@ecp/features/servicing/shared/components';
import { type UseModalPathReturn as AdditionalInterestEditProps } from '@ecp/features/servicing/shared/routing';
import {
  useAddAdditionalInterest,
  usePolicy,
  useSharedState,
} from '@ecp/features/servicing/shared/state';
import type { AdditionalInterest, ErrorResponse } from '@ecp/features/servicing/shared/state';

import type { AdditionalInterestInputs } from '../AdditionalInterestForm';
import { AdditionalInterestForm } from '../AdditionalInterestForm';
import { useStyles } from './AdditionalInterestEdit.styles';

export const AdditionalInterestEdit: React.FC<AdditionalInterestEditProps> = (props) => {
  const { goForward, policyNumber, reset, sharedStateKey } = props;
  const {
    policyData,
    isLoading: isPolicyLoading,
    refetch,
    isFetching: isPolicyFetching,
  } = usePolicy(policyNumber);
  const policySourceSystemName = policyData?.policy.sourceSystemName;
  const { addAdditionalInterest, isSubmitting } = useAddAdditionalInterest();
  const [, setAddLandlordResponse] = useSharedState<AdditionalInterest>(sharedStateKey);
  const [submitError, setSubmitError] = useState<ErrorResponse>();

  if (!policyNumber) {
    throw new Error('Cannot call additional interest edit api without policyNumber');
  }

  const { classes } = useStyles();

  const isAdditionalInterestsAlreadyExist = !!policyData?.thirdPartyInterests?.length;
  const handleSubmit = useCallback(
    async (data: AdditionalInterestInputs) => {
      if (!policySourceSystemName) throw Error('policySourceSystemName is requred');
      const additionalInterest = {
        primary: !isAdditionalInterestsAlreadyExist,
        sendEmail: false,
        effectiveDate: dayjs().format('YYYY-MM-DD'),
        ...data,
      };
      // Need to filter empty string properties from body
      const filteredAdditionalInterest = Object.fromEntries(
        Object.entries(additionalInterest).filter(([_, value]) => value !== ''),
      ) as Omit<AdditionalInterest, 'email' | 'phone'>;

      const { success, error } = await addAdditionalInterest({
        policyNumber,
        policySourceSystemName,
        additionalInterests: [filteredAdditionalInterest],
      });
      if (success) {
        await refetch();
        setAddLandlordResponse(filteredAdditionalInterest);
        goForward();
      } else if (error) {
        setSubmitError(error);
      }
    },
    [
      addAdditionalInterest,
      goForward,
      policyNumber,
      policySourceSystemName,
      refetch,
      setAddLandlordResponse,
      isAdditionalInterestsAlreadyExist,
    ],
  );

  const contentBefore = (
    <Stack spacing={1.5}>
      <div className={classes.description}>
        Adding an interested party to your policy only gives permission to notify them about the
        status of your policy. They will not be able to make changes or submit claims. This has no
        impact on your premium.
      </div>
      <div className={classes.subDescription}>
        Notifications will be sent to the address you provide. Please confirm its accuracy before
        submitting.
      </div>
    </Stack>
  );

  if (submitError)
    return (
      <ModalError
        errorTitle='process your request at this time'
        errorDescription='processing your request'
      />
    );
  if (isPolicyLoading) return <LoadingOverlay />;

  return (
    <Stack spacing={3} classes={classes.root}>
      <h1 id='edit-vin-edit-title'>
        Add a landlord, property manager, or other third party to your policy
      </h1>
      <Stack spacing={4}>
        <AdditionalInterestForm
          formName='AddAdditionalInterest'
          onSubmitAction={handleSubmit}
          onReset={reset}
          contentBefore={contentBefore}
          isSubmitProcessing={isSubmitting || isPolicyFetching}
        />
      </Stack>
      <p>We may notify your landlord or property manager if you cancel your policy.</p>
    </Stack>
  );
};
