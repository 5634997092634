import type { PolicyResponse, PolicyStatus, UiPolicy } from '@ecp/features/servicing/shared/types';

import { isBookRolledPolicy } from './isBookRolledPolicy';

const consideredCanceledPolicyStatuses: PolicyStatus[] = ['Canceled', 'Expired'];
export const getUiPolicy = (policyResponse: PolicyResponse): UiPolicy => ({
  ...policyResponse,
  isInactive: consideredCanceledPolicyStatuses.includes(policyResponse.policy.status),
  isInBookRoll: isBookRolledPolicy(policyResponse),
});
