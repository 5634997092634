import { Fragment } from 'react';

import { Divider } from '@mui/material';

import { formatDate } from '@ecp/utils/date';

import { DocumentLink } from '@ecp/features/servicing/shared/components';
import {
  type Document,
  getProductLineFromPolicyResponse,
  isLegacyPolicy,
  usePolicy,
} from '@ecp/features/servicing/shared/state';

import { useStyles } from './DocumentTable.styles';

export interface DocumentTableProps {
  documentDataList: Document[] | undefined;
  maxDocs?: number;
  hideTableHeadings?: boolean;
  addPOIForNewDocumentSection?: boolean;
  policyNumber?: string;
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  addDivider?: boolean;
  idPostfix?: string;
}

export const DocumentTable: React.FC<DocumentTableProps> = (props) => {
  const {
    documentDataList,
    hideTableHeadings = false,
    maxDocs,
    addPOIForNewDocumentSection = false,
    policyNumber,
    addDivider = false,
    idPostfix = '',
  } = props;
  const { classes } = useStyles(undefined, { props });

  const { policyData } = usePolicy(policyNumber);

  const tableData = (documentDataList ?? []).sort(
    (a, b) =>
      new Date(b.documentCreationDate).getTime() - new Date(a.documentCreationDate).getTime(),
  );

  const dataForTable = tableData.slice(0, maxDocs);
  const isLegacy = isLegacyPolicy(
    getProductLineFromPolicyResponse(policyData),
    policyData?.policy.sourceSystemName,
  );

  const body = (
    <div className={classes.root}>
      <table>
        {!hideTableHeadings && (
          <thead>
            <tr>
              <th>Document title</th>
              <th>Issued on</th>
            </tr>
          </thead>
        )}
        <tbody>
          {addPOIForNewDocumentSection && !isLegacy && (
            <>
              <tr>
                <td>
                  <DocumentLink
                    policyNumber={policyNumber}
                    documentTitle='Proof of insurance'
                    variant='text'
                    trackingName='get_id_link'
                    trackingLabel='get_id'
                    objectType='link'
                    classes={{ root: classes.link }}
                  />
                </td>
                <td>{formatDate(new Date().toString())}</td>
              </tr>
              {dataForTable.length > 0 && (
                <tr>
                  <td colSpan={2}>
                    <Divider />
                  </td>
                </tr>
              )}
            </>
          )}
          {dataForTable?.map((doc, index) => (
            <Fragment key={`${doc?.documentId}${idPostfix}`}>
              <tr>
                <td>
                  <DocumentLink
                    document={doc}
                    classes={{ root: classes.link }}
                    variant='iconText'
                    idPostfix={idPostfix}
                  />
                </td>
                <td>{formatDate(doc?.documentCreationDate)}</td>
              </tr>
              {addDivider && (
                <tr>
                  <td colSpan={2}>
                    <Divider />
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
          {addPOIForNewDocumentSection && isLegacy && dataForTable.length === 0 && (
            <tr className={classes.emptyDocs}>
              <td>There are no new documents</td>
              <td />
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );

  return body;
};
