import { useCallback, useMemo, useState } from 'react';

import { datadogLog } from '@ecp/utils/logger';

import { env } from '@ecp/env';
import { useSharedState } from '@ecp/features/servicing/shared/state';
import type { ClassicPaymentToken } from '@ecp/features/servicing/shared/types';

enum UserMessageName {
  WORLDPAY_TRANSACTION = 'WORLDPAY_TRANSACTION',
}
export const messageNames = { ...UserMessageName };
export type MessageName = keyof typeof messageNames;

export interface MessageData {
  messageName: MessageName;
  transactionInfo: string;
}

export interface UseOnMessageReturn {
  onMessage: (event: Pick<MessageEvent<MessageData>, 'data' | 'origin'>) => Promise<void>;
  loaded: boolean;
  setLoaded: (loaded: boolean) => void;
}

const logInfo = (data: MessageData): void =>
  datadogLog({
    logType: 'info',
    message: `Payment Worldpay - Unknown message received - ${JSON.stringify(data)}`,
    context: {
      logOrigin:
        'libs/features/servicing/payments/src/components/MakePaymentEditPaymentClassic/PaymentsIFrame/useOnMessage.ts',
      functionOrigin: 'handleMessage',
    },
  });

export const useOnMessage = (policyNumber: string | undefined): UseOnMessageReturn => {
  const [, setSharedState] = useSharedState<ClassicPaymentToken>(
    `financialAccountToken-${policyNumber}`,
  );
  const [loaded, setLoaded] = useState(false);
  const onMessage = useCallback(
    async (event: Parameters<UseOnMessageReturn['onMessage']>[0]) => {
      const { data, origin } = event;
      if (origin.match(window.location.origin)) {
        env.static.nodeEnv === 'development' &&
          console.log('worldpay payment postMessage - event.data', event.data);

        if (typeof data !== 'string') {
          const { messageName, transactionInfo } = data;
          switch (messageName) {
            case 'WORLDPAY_TRANSACTION':
              setSharedState({
                financialAccountToken: transactionInfo.split('?')[1],
                isValid: true,
                isError: false,
              });
              break;
            default:
              logInfo(data);
              console.log('unknown event - event.data', JSON.stringify(event.data, null, 2));
          }

          return;
        }
      }
    },
    [setSharedState],
  );

  return useMemo(
    () => ({
      onMessage,
      loaded,
      setLoaded,
    }),
    [onMessage, loaded],
  );
};
