import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'NumberFormat' })((theme) => ({
  root: {
    ...theme.typography.body4,
    textAlign: 'inherit',
  },
  formControl: {},
  label: {
    ...theme.typography.body3,
    paddingBottom: 15,
  },
  textTertiary: {
    color: theme.palette.text.tertiary,
  },
}));
