import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'DashboardCardContent' })((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[200],
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  wrapper: {
    maxWidth: 1072,
    width: '100%',
    padding: '40px 24px',
    [theme.breakpoints.down('md')]: {
      padding: '24px 16px',
    },
  },
}));
