import { makeStyles } from '@ecp/themes/base';

export const mainHeaderHeight = 105;
export const middleHeaderHeight = 70;
const totalHeaderHeight = mainHeaderHeight;
const mobileHeight = middleHeaderHeight;

export const useStyles = makeStyles({ name: 'ServicingHeader' })((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.grey[50],
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    flexDirection: 'row',
    height: mainHeaderHeight,
    justifyContent: 'flex-start',
    padding: '15px 30px',
    zIndex: theme.zIndex.drawer + 1, // ensure header stays above drawer
    [theme.breakpoints.down('lg')]: {
      height: middleHeaderHeight,
      padding: '0 20px',
    },
    [theme.breakpoints.down('md')]: {
      height: mobileHeight,
      padding: '0 25px',
    },
  },
  button: {
    padding: 0,
    marginBottom: 3,
    '&:hover': {
      background: 'none',
    },
  },
  notLoggedInRoot: { justifyContent: 'center' },
  placeholder: {
    backgroundColor: theme.palette.grey[200],
    height: totalHeaderHeight,
    [theme.breakpoints.down('lg')]: {
      height: middleHeaderHeight,
    },
    [theme.breakpoints.down('md')]: {
      height: mobileHeight,
    },
  },
}));
