import type { BrandProduct } from '@ecp/features/servicing/shared/brand-data';
import { brand } from '@ecp/features/servicing/shared/brand-data';
import type { ProductLine, UiPolicy } from '@ecp/features/servicing/shared/types';

export const getBrandDataByType = (productLine: ProductLine): BrandProduct | undefined => {
  if (productLine === 'HOME') return brand.home;
  else if (productLine === 'AUTO') return brand.auto;
  throw Error(`Unsupported productLine '${productLine}'`);
};

export const getBrandDataWithFallback = (productLineHint?: ProductLine): BrandProduct => {
  const brandByType =
    (productLineHint ? getBrandDataByType(productLineHint) : undefined) ?? brand.auto ?? brand.home;
  if (!brandByType) {
    throw Error('No brandData found for partner');
  }

  return brandByType;
};

export const getBrandData = (policyResponse: UiPolicy | undefined): BrandProduct => {
  const productLine = policyResponse?.coverageOptions?.at(0)?.productLine;

  return getBrandDataWithFallback(productLine);
};
