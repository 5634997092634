import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'SiteWideLinks' })((theme) => ({
  root: {
    marginTop: 'auto',
    width: '100%',
    justifyContent: 'space-between',
  },
  links: {
    ...theme.typography.body3Underline,
    color: theme.palette.text.link,
  },
  logo: { width: 210 },
  header: {},
}));
