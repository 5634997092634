import { CircularProgress, Grid } from '@mui/material';

import { LogoSpinner } from '@ecp/components';
import { env } from '@ecp/env';

import { useStyles } from './LoadingOverlay.styles';

interface Props {
  title?: string;
  subtitle?: string;
}

export const LoadingOverlay: React.FC<Props> = (props) => {
  const { title, subtitle } = props;
  const { classes } = useStyles();

  const textContainer =
    title || subtitle ? (
      <div className={classes.textContainer}>
        {title && <h1 className={classes.title}>{title}</h1>}
        {subtitle && <h2 className={classes.subtitle}>{subtitle}</h2>}
      </div>
    ) : null;

  return (
    <Grid container className={classes.root}>
      <Grid item className={classes.wrapper}>
        {textContainer}
        {env.static.isAgent ? (
          // TODO: Remove CircularProgress for agent and use LogoSpinner for all consumers when UX decides which icon use for agent app.
          <CircularProgress size={128} aria-label='Loading...' />
        ) : (
          <LogoSpinner />
        )}
      </Grid>
    </Grid>
  );
};
