import { Grid } from '@mui/material';

import { useStyles } from './DashboardCardContent.styles';

interface Props {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
}

export const DashboardCardContent: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { children } = props;
  const { classes } = useStyles(undefined, { props });

  return (
    <Grid container className={classes.root}>
      <Grid item className={classes.wrapper}>
        {children}
      </Grid>
    </Grid>
  );
};
