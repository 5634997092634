import { isEqual, merge, parseDollar } from '@ecp/utils/common';

import type { AnswerValue, Field, OptionMetadata, OptionsMetadata, SubCoverages } from '@ecp/types';

import type { CoverageField, CoverageOptionData, CoveragesFields } from '../types';

export const unknownCoverageKey = 'unknownCoverage';

export const getDisplayValue = (label: React.ReactElement | string | undefined): string => {
  const labelStr = label?.toString() || 'Unavailable';
  const labelStrLowerCase = label?.toString().toLowerCase();
  switch (labelStrLowerCase) {
    case 'true':
      return 'Included';
    case 'no coverage':
    case 'not included':
    case 'none':
      return '+ Add Coverage';
    default:
      return labelStr;
  }
};

const buildCardData = (
  metaData: OptionMetadata | undefined,
  field: CoverageField,
  stateCode = '',
): CoverageOptionData => {
  const {
    props: { value, options },
    additionalMetaData = {} as OptionMetadata,
  } = field;
  const label = options?.find((e) => e.value === value)?.label ?? parseDollar(value, false);
  const stateOption = metaData?.stateOptions?.[stateCode];

  let newMetadata = metaData;
  if (stateOption) {
    const { subCoverages, ...metaDataWithoutSubCoverages } = metaData;
    newMetadata = { ...metaDataWithoutSubCoverages, ...stateOption };
    const stateSubCoverages = stateOption.subCoverages ?? {};
    newMetadata.subCoverages = merge({}, subCoverages, stateSubCoverages); // deep merge
    // Delete the state options since they have already been applied above
    delete newMetadata.stateOptions;
  }

  return {
    ...newMetadata,
    ...additionalMetaData,
    title: additionalMetaData.title || newMetadata?.title || '',
    type: value,
    displayValue: getDisplayValue(label),
    isCovered:
      !!label &&
      ![
        'not included',
        'no coverage',
        'not available',
        'rejected',
        'none',
        '+ Add Coverage',
      ].includes(label.toString().toLowerCase()),
    editable: !!(options && options.length > 1) || !options,
    field,
  };
};

const buildSubCoverage = (
  coverageFields: CoveragesFields,
  metadataSubCoverages?: SubCoverages,
  handleCoverageItemChange?: (field: Field) => (value: AnswerValue) => Promise<void>,
): SubCoverages | undefined => {
  const subCoverages: SubCoverages = {};

  if (metadataSubCoverages) {
    Object.keys(metadataSubCoverages).forEach((subCoverageKey) => {
      const field = coverageFields[subCoverageKey];
      if (field?.exists) {
        if (handleCoverageItemChange) {
          field.props.actionOnComplete = handleCoverageItemChange(field);
        }

        subCoverages[subCoverageKey] = {
          title: metadataSubCoverages[subCoverageKey].title,
          primaryText: metadataSubCoverages[subCoverageKey].primaryText,
          isPipSubCoverage: metadataSubCoverages[subCoverageKey].isPipSubCoverage,
          isExcludeDriver: metadataSubCoverages[subCoverageKey].isExcludeDriver,
          field,
        };
        field.noScrollError = !!metadataSubCoverages[subCoverageKey].isPipSubCoverage;
      }
    });
  }

  return isEqual(subCoverages, {}) ? undefined : subCoverages;
};

export const buildCoverageItems = (
  optionsMetadata: OptionsMetadata,
  coveragesFields: CoveragesFields,
  stateCode: string | undefined,
  handleCoverageItemChange?: (field: Field) => (value: AnswerValue) => Promise<void>,
): CoverageOptionData[] => {
  const knownItems: CoverageOptionData[] = Object.keys(optionsMetadata)
    .filter((coverageKey) => coveragesFields[coverageKey]?.exists)
    .map((coverageKey) => {
      const field = coveragesFields[coverageKey];
      if (handleCoverageItemChange) field.props.actionOnComplete = handleCoverageItemChange(field);
      const data = buildCardData(optionsMetadata[coverageKey], field, stateCode);
      // !TODO Consider removing refKey logic everywhere, since we have refFieldKey logic to
      // link mutually dependent fields for validation purposes, whereas it should reside in SAPI questions validation criteria
      const refKey = optionsMetadata[coverageKey].refFieldKey;
      if (refKey) {
        data.refField = coveragesFields[refKey];
      }
      const metadataSubCoverages = data.subCoverages;
      data.subCoverages = buildSubCoverage(
        coveragesFields,
        metadataSubCoverages,
        handleCoverageItemChange,
      );

      return data;
    });

  const unknownItems: CoverageOptionData[] = Object.keys(coveragesFields)
    .filter((coverageKey) => coverageKey.startsWith(unknownCoverageKey))
    .map((coverageKey) => {
      const field = coveragesFields[coverageKey];
      const data = buildCardData(optionsMetadata['unknown.coverage'], field, stateCode);

      return data;
    });

  return [...knownItems, ...unknownItems];
};
