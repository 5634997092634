import { useCallback, useEffect, useState } from 'react';

import { CircularProgress, Stack } from '@mui/material';

import { DateConstants, formatDate } from '@ecp/utils/date';

import { Button } from '@ecp/components';
import { env } from '@ecp/env';
import { ModalError } from '@ecp/features/servicing/shared/components';
import {
  MODAL_FLOW,
  MODAL_STEP,
  useModalPath,
  type UseModalPathReturn,
} from '@ecp/features/servicing/shared/routing';
import type { ClassicBillingResponse } from '@ecp/features/servicing/shared/state';
import {
  getPolicyDisplayType,
  saveInLoggedInSessionStorage,
  useBillingAccount,
  useBillingDetails,
  useMakePaymentWithNewMethod,
  usePolicy,
  useSharedState,
  useUser,
} from '@ecp/features/servicing/shared/state';
import type {
  ClassicPaymentToken,
  MakePaymentClassicInformation,
} from '@ecp/features/servicing/shared/types';
import {
  GraphicPaymentAmexImageUrl,
  GraphicPaymentMastercardImageUrl,
  GraphicPaymentVisaImageUrl,
  IconMaterialDesignNavigationWest,
  IconUISecure,
} from '@ecp/themes/base';

import { useStyles } from './MakePaymentEditPaymentClassic.styles';
import { PaymentsIFrame } from './PaymentsIFrame/PaymentsIFrame';
import { useOnMessage } from './PaymentsIFrame/useOnMessage';

export const MakePaymentEditPaymentClassic: React.FC<UseModalPathReturn> = (props) => {
  const { goBack, goForward, policyNumber, sharedStateKey } = props;
  const { classes } = useStyles();
  const [sharedState, setSharedState] =
    useSharedState<MakePaymentClassicInformation>(sharedStateKey);
  const [financialToken, setFinancialToken] = useSharedState<ClassicPaymentToken>(
    `financialAccountToken-${policyNumber}`,
  );
  const { setLoaded } = useOnMessage(policyNumber);
  const { user, isLoading: isLoadingUser } = useUser();
  const { isSubmitting, makePayment } = useMakePaymentWithNewMethod(policyNumber);
  const [submitError, setSubmitError] = useState<boolean>();
  const modalPath = useModalPath();
  const { policyData: policyResponse, isLoading: isLoadingPolicy } = usePolicy(policyNumber);
  const policySource = policyResponse?.policy?.sourceSystemName;
  const { refetch, isLoading: isLoadingBillingDetails } = useBillingDetails({
    billingAccountIds: policyResponse?.policy?.billingAccountNumber
      ? [policyResponse.policy.billingAccountNumber]
      : undefined,
  });
  const { billingAccount, isLoading: isLoadingBillingAccount } = useBillingAccount({
    policyNumber,
  });

  const isLoading =
    isLoadingBillingAccount || isLoadingBillingDetails || isLoadingPolicy || isLoadingUser;

  useEffect(() => {
    setSubmitError(financialToken?.isError);
  }, [financialToken?.isError]);

  useEffect(() => {
    if (financialToken?.financialAccountToken && financialToken?.isValid && !isLoading) {
      if (!user) throw new Error('User is undefined');
      if (!policyResponse) throw new Error('Policy is undefined');
      if (!billingAccount) throw new Error('BillingAccount is undefined');
      const { termNumber } = billingAccount as ClassicBillingResponse;
      if (!termNumber) throw new Error('Billing term is undefined');

      const todaysDateAndTime = new Date();
      setLoaded(false);
      makePayment({
        billingAccountNumber: policyResponse?.policy.billingAccountNumber,
        paymentTerm: termNumber,
        policySource,
        newPaymentMethod: {
          paymentAmount: sharedState?.paymentAmount,
          tokenDetails: {
            card: {
              financialAccountToken: financialToken.financialAccountToken,
            },
          },
        },
        auditInfo: {
          requestedDateTime: todaysDateAndTime.toISOString(),
          requestedBy: user.email,
          requestingSystem: env.static.sourceId,
        },
      }).then((response) => {
        const { success, error } = response;
        if (success) {
          setLoaded(true);
          setSharedState({
            ...(sharedState as MakePaymentClassicInformation),
            paymentDate: formatDate(success.date, DateConstants.MONTH_FULL_NAME_LONG_FORMAT),
            isSuccess: true,
            confirmationId: success.confirmationId,
          });
          setFinancialToken({
            financialAccountToken: '',
            isValid: financialToken.isValid,
            isError: false,
          });
          saveInLoggedInSessionStorage('payment', policyNumber, success.amount);
          refetch(policyResponse?.policy.billingAccountNumber);
          goForward();
        }
        if (error) setSubmitError(true);
      });
    } else if (!financialToken?.isValid && financialToken?.financialAccountToken === '') {
      modalPath.init(policyNumber ?? '', MODAL_FLOW.PAY_CLASSIC, MODAL_STEP.MODAL_EDIT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    financialToken?.financialAccountToken,
    financialToken?.isValid,
    financialToken?.isError,
    isLoading,
  ]);

  const handleBackClick = useCallback(async () => {
    goBack();
  }, [goBack]);

  if (submitError) {
    const policyType = getPolicyDisplayType(policyResponse);
    const errorDesc = (
      <span>
        processing the payment for your{' '}
        <b>
          {policyType} {policyNumber} policy
        </b>
      </span>
    );

    return <ModalError errorTitle='process your payment' errorDescription={errorDesc} />;
  }

  return (
    <Stack spacing={4} className={classes.root}>
      <Stack direction='row' className={classes.groupLabelStack} spacing={1}>
        <IconUISecure />
        <h3>Enter a credit/debit card for one-time use</h3>
      </Stack>
      <Stack direction='row' spacing={{ xs: 1, sm: 1, md: 3 }} className={classes.iconsSection}>
        <p className={classes.iconTitle}>Accepted Cards</p>
        <Stack direction='row' spacing={{ xs: 1.5, sm: 1.5, md: 2 }}>
          <img className={classes.image} src={GraphicPaymentVisaImageUrl} alt='visa-card' />
          <img className={classes.image} src={GraphicPaymentMastercardImageUrl} alt='master-card' />
          <img
            className={classes.imageWithPadding}
            src={GraphicPaymentAmexImageUrl}
            alt='amex-card'
          />
        </Stack>
      </Stack>
      <p>
        For security purposes, you have <b>10 minutes</b> to submit this request or the session will
        automatically refresh.
      </p>
      {!isSubmitting && (financialToken === null || financialToken?.isValid) && (
        <PaymentsIFrame {...modalPath} />
      )}
      {(isLoading || isSubmitting) && (
        <div className={classes.spinner}>
          <CircularProgress aria-label='Loading...' />
        </div>
      )}
      <Button
        className={classes.backButton}
        variant='iconTextMedium'
        icon={<IconMaterialDesignNavigationWest />}
        onClick={handleBackClick}
      >
        Back
      </Button>
    </Stack>
  );
};
