import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<void, 'checked' | 'focusVisible' | 'label' | 'thumb' | 'track'>(
  {
    name: 'Switch',
  },
)((...[theme, , classes]) => ({
  root: {
    // nested label class for specificity
    [`& .${classes.label}`]: {
      ...theme.typography.body1,
      marginLeft: theme.spacing(1),
    },
  },
  switchBase: {
    padding: 2,
    [`&.${classes.checked}`]: {
      transform: 'translateX(24px)',
      color: theme.palette.common.white,
      [`& + .${classes.track}`]: {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
    },
    [`&.${classes.focusVisible} .${classes.thumb}`]: {
      color: theme.palette.primary.main,
      borderWidth: '7px',
      borderColor: theme.palette.common.white,
      borderStyle: 'solid',
      width: 30,
      height: 30,
    },
  },
  switchRoot: {
    width: 54,
    height: 30,
    padding: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  thumb: {
    width: 26,
    height: 26,
  },
  track: {
    borderRadius: 32 / 2,
    border: `0px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[600],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
  label: {},
}));
