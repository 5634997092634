import { apiRoot, buildRequestHeaders } from '../servicingApiConfig';
import type { ServicingResponse } from '../servicingRequest';
import { servicingRequest } from '../servicingRequest';
import type { BillingResponse } from './types';

export const getBillingDetailsByAccountId = async (
  billingAccountId: string,
): Promise<ServicingResponse<BillingResponse>> => {
  const headers = await buildRequestHeaders({ withUserToken: true });

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-billing/v1/billings/${billingAccountId}`,
    options: {
      headers: headers,
      method: 'GET',
    },
  });
};
