import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutopayEdit' })((theme) => ({
  root: {},
  submitButtton: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: 'fit-content',
    },
  },
  icon: {
    width: 64,
    height: 64,
    alignSelf: 'center',
  },
  text: theme.typography.body3,
  title: theme.typography.h2,
  backButton: {
    marginLeft: 32,
  },
}));
