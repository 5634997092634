// Simple predicate functions for miscellaneous across-repo items

import type { AnswerValue, Falsy } from './types';

export const isTruthy = Boolean as unknown as <T>(value?: T | Falsy) => value is T;

export const hasValue = (value: AnswerValue): boolean => !!value || value === false || value === 0;

/**
 * Check if the value appears to be masked.
 *
 * Note: Use only in the basic scenario if the format doesn't allow an asterisk symbol `*`.
 *
 * Examples: date `** / ** / 1990`, VIN `XYZ****`.
 */
export const isMasked = (val: unknown): boolean => typeof val === 'string' && val.includes('*');

/**
 * Check if the Drivers License value appears to be masked.
 * For DL number it will start with an asterisk symbol: `*1234`.
 * DL number can include `*` but it never starts with `*`.
 */
export const isDlNumberMasked = (val: unknown): boolean =>
  typeof val === 'string' && val.startsWith('*');
