import { forwardRef, memo } from 'react';

import type { RadioProps } from '@mui/material';
import { Radio as MuiRadio } from '@mui/material';

import { useStyles } from './Radio.styles';

export const Radio = memo(
  forwardRef<HTMLButtonElement, RadioProps>((props, ref) => {
    const { classes, cx } = useStyles(props);

    return (
      <MuiRadio
        {...props}
        ref={ref}
        checkedIcon={
          <span
            className={cx(
              classes.icon,
              props.disabled ? classes.iconDisabledChecked : classes.iconChecked,
            )}
          />
        }
        color='primary'
        icon={
          <span className={cx(classes.icon, props.disabled && classes.iconDisabledUnchecked)} />
        }
      />
    );
  }),
);
