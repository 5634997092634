import { forwardRef, useCallback } from 'react';

import type { CheckboxProps as MuiCheckboxProps } from '@mui/material';
import { Checkbox as MuiCheckbox } from '@mui/material';

import { isType } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';
import { isHTMLElementWithValue } from '@ecp/utils/web';

import { IconUICheck } from '@ecp/themes/base';

import { useStyles } from './Checkbox.styles';

export type CheckboxProps = MuiCheckboxProps & {
  actionOnChange?: (value: string) => void;
  actionOnComplete?: (value: string) => void;
  variant?: 'card' | 'horizontalCard' | 'horizontalCardCuztomizedLabel' | 'paddedHorizontalCard';
};

export const Checkbox: React.FC<CheckboxProps> = forwardRef((props, ref) => {
  const { onChange, actionOnChange, actionOnComplete, variant, className, ...rest } = props;
  const { classes, cx } = useStyles();
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => {
      onChange?.(event, newChecked);

      if (
        !isType(event.currentTarget, HTMLElement) ||
        !isHTMLElementWithValue(event.currentTarget)
      ) {
        const message = 'Not a valid event target for Checkbox';
        datadogLog({
          logType: 'error',
          message,
          context: {
            logOrigin: 'libs/components/src/Checkbox/Checkbox.tsx',
            functionOrigin: 'handleChange',
          },
        });
        throw new Error(message);
      }

      const newVal = newChecked ? event.currentTarget.value : '';
      actionOnChange?.(newVal);
      actionOnComplete?.(newVal);
    },
    [onChange, actionOnChange, actionOnComplete],
  );

  return (
    <MuiCheckbox
      ref={ref}
      className={cx(classes.root, className)}
      checkedIcon={
        <IconUICheck
          className={cx(
            classes.icon,
            classes.iconChecked,
            variant === 'horizontalCard' || variant === 'horizontalCardCuztomizedLabel'
              ? classes.iconHorizontal
              : classes.iconVertical,
          )}
        />
      }
      color='primary'
      icon={
        <span
          className={cx(
            classes.icon,
            variant === 'horizontalCard' || variant === 'horizontalCardCuztomizedLabel'
              ? classes.iconHorizontal
              : classes.iconVertical,
          )}
        />
      }
      onChange={handleChange}
      {...rest}
    />
  );
});
