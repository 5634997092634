import { Stack } from '@mui/material';

import { parseDollar } from '@ecp/utils/common';
import { DateConstants, formatDate, isDateExpired } from '@ecp/utils/date';

import type { BillingResponse } from '@ecp/features/servicing/shared/state';

import { useStyles } from './PaymentDue.styles';

export interface PaymentDueProps {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  billingAccount: Pick<BillingResponse, 'minimumDue' | 'dueDate' | 'fullPayBalance'> | undefined;
  isAutopayEnabled?: boolean;
  isEscrow?: boolean;
}

export const PaymentDue: React.FC<PaymentDueProps> = (props) => {
  const { billingAccount, isAutopayEnabled = false, isEscrow = false } = props;
  const { classes } = useStyles(undefined, { props });

  const { minimumDue, dueDate, fullPayBalance } = billingAccount ?? {
    minimumDue: 0,
    dueDate: '',
    fullPayBalance: 0,
  };

  const amountDue =
    isEscrow || minimumDue === 0 ? parseDollar(fullPayBalance) : parseDollar(minimumDue);

  let paymentText = 'due';
  if (isAutopayEnabled && dueDate !== undefined && !isDateExpired(dueDate))
    paymentText = 'scheduled for';
  if (minimumDue === 0) paymentText = 'remaining balance';

  return (
    <Stack gap={0.5} className={classes.root} direction='row' alignItems='end' flexWrap='wrap'>
      <strong className={classes.amountDue}>{amountDue}</strong>{' '}
      <span className={classes.paymentInfo}>
        {paymentText}{' '}
        {minimumDue !== undefined && minimumDue > 0 ? (
          <strong>{formatDate(dueDate, DateConstants.MONTH_FULL_NAME_LONG_FORMAT)}</strong>
        ) : (
          ''
        )}
      </span>
    </Stack>
  );
};
