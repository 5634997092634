import { useCallback, useMemo, useState } from 'react';

import { Stack } from '@mui/material';

import { DateConstants, formatDate } from '@ecp/utils/date';
import { Form, useForm } from '@ecp/utils/form';

import { Button } from '@ecp/components';
import { env } from '@ecp/env';
import {
  LoadingButton,
  LoadingOverlay,
  ModalError,
} from '@ecp/features/servicing/shared/components';
import { useModalPath } from '@ecp/features/servicing/shared/routing';
import type { UseModalPathReturn as CaMileageReviewProps } from '@ecp/features/servicing/shared/routing';
import type {
  UpdateCaMileageRequest,
  UseUpdateCaMileageReturn,
} from '@ecp/features/servicing/shared/state';
import {
  type ErrorResponse,
  saveInLoggedInSessionStorage,
  useSharedState,
  useUpdateCaMileage,
  useUser,
} from '@ecp/features/servicing/shared/state';
import type { CaMileageSubmit } from '@ecp/features/servicing/shared/types';
import { IconMaterialDesignActionHelpOutline } from '@ecp/themes/base';

import { useStyles } from './CaMileageReview.styles';

export const CaMileageReview: React.FC<CaMileageReviewProps> = (props) => {
  const { goBack, goForward, sharedStateKey, policyNumber } = props;

  const { classes } = useStyles();

  const { user, isLoading: isUserLoading } = useUser();
  const { updateCaMileage } = useUpdateCaMileage();
  const modalPath = useModalPath();

  const [submitError, setErrorType] = useState<ErrorResponse>();
  const [sharedState] = useSharedState<CaMileageSubmit>(sharedStateKey);
  const [, setUpdateMembershipResponse] = useSharedState<UseUpdateCaMileageReturn>(sharedStateKey);

  const reviewPageLoadTime = useMemo(() => new Date().toISOString(), []);

  const handleClose = useCallback(() => {
    modalPath.reset();
  }, [modalPath]);

  const formContext = useForm();

  const { handleSubmit } = formContext;

  const onSubmit = useCallback(() => {
    handleSubmit(async () => {
      const todaysDateAndTime = new Date();
      const updateCaMileageRequest: UpdateCaMileageRequest = {
        policyNumber: policyNumber ?? '',
        vehicleInputs: sharedState?.vehicleInputs ?? [],
        auditInfo: {
          requestedDateTime: reviewPageLoadTime,
          requestedBy: user?.email ?? '',
          requestingSystem: env.static.sourceId,
          consumerStep: 'Update CaMileage',
          acknowledgement: sharedState?.acknowledgement ?? '',
          whenAccepted: formatDate(todaysDateAndTime.toString(), DateConstants.ANSWERS_FORMAT),
        },
      };
      const { error, success } = await updateCaMileage(updateCaMileageRequest);
      if (error) {
        if (error === 'unknown') setErrorType('unknown');
      } else if (success) {
        setUpdateMembershipResponse(success);
        saveInLoggedInSessionStorage('caMileageForm', policyNumber, 'submitted');
        goForward();
      }
    })();
  }, [
    goForward,
    policyNumber,
    setUpdateMembershipResponse,
    sharedState,
    updateCaMileage,
    reviewPageLoadTime,
    user?.email,
    handleSubmit,
  ]);

  if (submitError) {
    return (
      <ModalError
        errorTitle='update the mileage for your policy'
        errorDescription='updating the mileage for your policy'
      />
    );
  }
  const isLoading = isUserLoading;

  return isLoading ? (
    <LoadingOverlay />
  ) : (
    <Form onSubmit={onSubmit} formProviderProps={formContext}>
      <Stack spacing={4} classes={classes.root}>
        <div className={classes.successIconWrapper}>
          <IconMaterialDesignActionHelpOutline className={classes.actionIcon} />
        </div>
        <Stack spacing={5}>
          <Stack spacing={2}>
            <h2>Ready to submit your California Mileage Form?</h2>
            <p>You’ll be unable to make changes after submitting your form.</p>{' '}
          </Stack>
          <Stack spacing={2.5}>
            <Stack spacing={2.5} direction='row'>
              <LoadingButton type='submit' variant='primary' className={classes.submitButton}>
                SUBMIT FORM
              </LoadingButton>
              <Button className={classes.closeButton} variant='outlinePrimary' onClick={goBack}>
                BACK
              </Button>
            </Stack>
            <Button variant='iconTextLarge' onClick={handleClose} className={classes.cancelButton}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Form>
  );
};
