import { Button } from '@ecp/components';
import type { ButtonVariant } from '@ecp/components';
import { openMessenger } from '@ecp/features/shared/chat';
import { IconUIChat } from '@ecp/themes/base';

import { useStyles } from './ChatHelp.styles';

interface Props {
  displayText: string;
  trackingName?: string;
  trackingLabel?: string;
  variant: ButtonVariant;
  withIcon?: boolean;
}

export const ChatHelp: React.FC<Props> = (props) => {
  const { variant, displayText, trackingName, trackingLabel, withIcon = false } = props;
  const { classes } = useStyles();

  return (
    <Button
      trackingLabel={trackingLabel}
      trackingName={trackingName}
      onClick={openMessenger}
      variant={variant}
    >
      {withIcon && <IconUIChat className={classes.icon} title='chatIcon' />}
      {!withIcon && displayText}
    </Button>
  );
};
