import { useParams } from 'react-router-dom';

import { OfferingTextingHomesite } from './homesite';

export const OfferingTextingPage: React.FC = () => {
  const { opco } = useParams();

  let offeringTexting;
  switch (opco) {
    case 'homesite':
      offeringTexting = <OfferingTextingHomesite />;
      break;
    default:
      offeringTexting = <p>Partner Specific Offering Texting.</p>;
  }

  return offeringTexting;
};
