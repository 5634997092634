import { memo, useCallback } from 'react';

import { AppBar, Slide, useScrollTrigger } from '@mui/material';

import { userAuth, useUserAuth } from '@ecp/utils/auth';

import { Button } from '@ecp/components';
import { PAGE_PATH, usePageFlow } from '@ecp/features/servicing/shared/routing';

import { PartnerLogo } from '../PartnerLogo';
import { useStyles } from './ServicingHeader.styles';
import { ServicingHeaderBody } from './ServicingHeaderBody';

export const ServicingHeader: React.FC = memo(() => {
  const pageFlow = usePageFlow();
  const { classes, cx } = useStyles();
  const trigger = useScrollTrigger({ threshold: 50 });
  useUserAuth();

  const loggedIn = userAuth.isAuth;
  const handleClick = useCallback((): void => {
    pageFlow.navigate(userAuth.isAuth ? PAGE_PATH.DASHBOARD_INDEX : PAGE_PATH.AUTH_INDEX);
  }, [pageFlow]);

  return (
    <>
      <div className={classes.placeholder} />
      <Slide appear={false} direction='down' in={!trigger}>
        <AppBar className={cx(classes.root, !loggedIn && classes.notLoggedInRoot)}>
          <Button
            onClick={handleClick}
            className={classes.button}
            trackingName='top_partner_logo_link'
            trackingLabel='partner_logo_link'
            objectType='link'
          >
            <PartnerLogo />
          </Button>
          {loggedIn && <ServicingHeaderBody />}
        </AppBar>
      </Slide>
    </>
  );
});
