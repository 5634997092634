import { memo, useState } from 'react';

import type { MenuItemProps } from '@mui/material';
import { Link, List, Menu, MenuItem, Stack, Toolbar } from '@mui/material';

import { withAnalytics } from '@ecp/utils/analytics/tracking';
import { useEvent } from '@ecp/utils/react';

import { usePageFlow } from '@ecp/features/servicing/shared/routing';
import { IconUIExpandLess, IconUIExpandMore } from '@ecp/themes/base';

import { NavLink } from '../NavLink';
import { useStyles } from './HeaderNav.styles';
import { MobileNav } from './MobileNav';
import { useMenuItems } from './useMenuItems';

const SubmenuItem = withAnalytics<MenuItemProps>((props) => <MenuItem {...props} />, 'link');

interface Props {
  className?: string;
}

export const HeaderNav: React.FC<Props> = memo((props) => {
  const { className } = props;
  const { classes, cx } = useStyles();

  const { navigate } = usePageFlow();
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [submenuTitle, setSubmenuTitle] = useState(null);
  const [anchorEl, setAnchorEl] = useState();
  const { activeItem, activeSubItem, primaryItems } = useMenuItems();

  const handleToggleSubmenu = useEvent((event) => {
    setAnchorEl(event.currentTarget);
    setSubmenuOpen((open) => !open);
    setSubmenuTitle(event.currentTarget.dataset.title);
  });

  const handleSubmenuClick: NonNullable<MenuItemProps['onClick']> = useEvent((event) => {
    const url = event.currentTarget.dataset.url;
    if (!url) throw Error('cannot navigate to submenu url without url');
    navigate(url);
    setSubmenuOpen(false);
    setSubmenuTitle(null);
  });

  const openItemWithSubmenu = primaryItems.find((item) => submenuTitle === item.title);
  const submenuId = `submenu-${openItemWithSubmenu?.title}`;

  const mainMenuItems = primaryItems.map((item, key) => {
    const isActive = activeItem?.title === item.title;
    const hasSubmenu = !!item.items?.length;

    return (
      <li key={key} className={cx(isActive && classes.activeItem)}>
        {hasSubmenu ? (
          // using link to share all the same styles with NavLink
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            className={cx(
              classes.link,
              submenuOpen && classes.linkWithOpenSubmenu,
              isActive && classes.active,
            )}
            onClick={handleToggleSubmenu}
            data-title={item.title}
            aria-controls={submenuOpen ? submenuId : undefined}
            aria-haspopup='true'
            aria-expanded={submenuOpen ? 'true' : undefined}
          >
            <Stack direction='row' spacing={0.5} alignItems='center'>
              {item.title}
              {submenuOpen ? (
                <IconUIExpandLess className={classes.submenuIcon} data-testid='icon-expand-less' />
              ) : (
                <IconUIExpandMore className={classes.submenuIcon} data-testid='icon-expand-more' />
              )}
            </Stack>
          </Link>
        ) : (
          <NavLink
            to={item.url}
            className={cx(classes.link, isActive && classes.active)}
            aria-current={isActive ? 'page' : undefined}
            trackingName={item.trackingName}
            trackingLabel={item.trackingLabel}
          >
            {item.title}
          </NavLink>
        )}
      </li>
    );
  });

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.menu}>
        <Toolbar component='nav' className={classes.menuToolbar}>
          <List className={classes.list}>{mainMenuItems}</List>
        </Toolbar>
      </div>
      <Menu
        id={submenuId}
        anchorEl={anchorEl}
        open={submenuOpen}
        onClose={handleToggleSubmenu}
        MenuListProps={{
          'aria-label': `submenu navigation for ${openItemWithSubmenu?.title}`,
        }}
        classes={{ paper: classes.submenu }}
        autoFocus={false}
        PopoverClasses={{ paper: classes.submenuPaper }}
      >
        {openItemWithSubmenu?.items?.map((item) => (
          <SubmenuItem
            onClick={handleSubmenuClick}
            className={classes.submenuItem}
            data-url={item.url}
            key={item.url}
            selected={item.url === activeSubItem?.url}
            trackingName={item.trackingName}
            trackingLabel={item.trackingLabel}
            trackingDimensions={item.trackingDimensions}
            objectType={item.objectType}
          >
            {item.title}
          </SubmenuItem>
        ))}
      </Menu>
      <MobileNav classes={{ root: classes.mobileNav }} />
    </div>
  );
});
