import { useCallback, useRef, useState } from 'react';

import type { AccordionProps } from '@mui/material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CardMedia,
  Divider,
  Grid,
} from '@mui/material';

import { Button, Modal, Table } from '@ecp/components';
import { IconUIExclaimTriangle, IconUIExpandMore } from '@ecp/themes/base';
import { useIsMobile } from '@ecp/themes/base';

import type { CoverageOptionData } from '../../types';
import { useStyles } from './CoverageCard.styles';
import { CoverageCardItems } from './CoverageCardItems';
import {
  trackCoverageChangeToggleClick,
  trackCoverageReadMoreLessClick,
  trackViewPIPClick,
} from './util';

export interface Props extends Omit<CoverageOptionData, 'classes' | 'key'> {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  error?: string;
  expanded?: boolean;
  isDeductible?: boolean;
  key?: string | number;
  /** Accordion items expand-collapse event */
  onChange?: AccordionProps['onChange'];
  unmountOnExit?: boolean;
  vehicleId?: string;
}

export const CoverageCard: React.FC<Props> = (props) => {
  const {
    displayValue,
    editable,
    isCovered,
    isDeductible,
    listItems,
    field,
    subCoverages,
    expanded,
    unmountOnExit = false,
    primaryImage,
    title,
    primaryText,
    secondaryText,
    personalInjuryTable,
    error = field?.props.error,
    onChange,
    vehicleId,
  } = props;
  const { classes, cx } = useStyles(props, { props });
  const isMobile = useIsMobile();

  const subItemError = Object.values(subCoverages ?? {})
    .map((subCoverageItem) => subCoverageItem?.field?.props.error)
    .find((error) => error);

  const displayError = error || subItemError;

  const handleChange = useCallback(
    (event: React.SyntheticEvent<Element, Event>, isExpanded: boolean) => {
      trackCoverageChangeToggleClick(title, isExpanded);
      onChange?.(event, isExpanded);
    },
    [onChange, title],
  );

  const selectedLabel = field?.props?.options?.find((e) => e.value === field.props.value)?.label;

  const ref = useRef<HTMLDivElement>(null);

  const [isHelpExpanded, setIsHelpExpanded] = useState(false);
  const handleHelpToggle = useCallback(() => {
    setIsHelpExpanded((prevIsHelpExpanded) => {
      trackCoverageReadMoreLessClick(title, prevIsHelpExpanded);

      return !prevIsHelpExpanded;
    });
  }, [title]);

  const renderListItems = (): React.ReactElement | null => {
    if (!listItems) return null;

    return (
      <ul className={classes.listItems}>
        {listItems.map((listItem, index) => (
          <li className={classes.listItem} key={index}>
            {listItem}
          </li>
        ))}
      </ul>
    );
  };

  const [showPersonalInjuryTable, setShowPersonalInjuryTable] = useState(false);
  const handleSecondarySectionClick = useCallback(() => {
    setShowPersonalInjuryTable(true);
    trackViewPIPClick(title);
  }, [title]);
  const handleSecondarySectionKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement> | undefined) => {
      if (e?.key === 'Enter' || e?.key === ' ') {
        setShowPersonalInjuryTable(true);
        trackViewPIPClick(title);
      }
    },
    [title],
  );
  const handleDialogClose = useCallback(() => setShowPersonalInjuryTable(false), []);

  const renderSecondarySection = (): React.ReactElement => (
    <>
      <p className={classes.secondaryText}>{secondaryText}</p>
      {renderListItems()}
      {personalInjuryTable && (
        <>
          <div
            className={classes.breakdownContainer}
            onClick={handleSecondarySectionClick}
            onKeyPress={handleSecondarySectionKeyPress}
            role='button'
            tabIndex={0}
          >
            <Button variant='outlineSecondary' className={classes.breakdownButton}>
              View breakdown
            </Button>
          </div>
          {showPersonalInjuryTable && (
            // <Dialog
            //   titleText={`${title} Breakdown`}
            //   open={showPersonalInjuryTable}
            //   fullScreen
            //   className={classes.dialogRoot}
            //   onClose={handleDialogClose}
            // >
            //   <div className={classes.dialogContent}>
            //     <div className={classes.personalInjuryTableContainer}>{personalInjuryTable()}</div>
            //   </div>
            // </Dialog>
            <Modal
              title={`${title} Breakdown`}
              open={showPersonalInjuryTable}
              // fullScreen
              className={classes.dialogRoot}
              onClose={handleDialogClose}
            >
              <div className={classes.dialogContent}>
                <div className={classes.personalInjuryTableContainer}>
                  <Table {...personalInjuryTable} />
                </div>
              </div>
            </Modal>
          )}
        </>
      )}
    </>
  );

  const handleShowHideSubCoveragesClick = useCallback(() => ref.current?.click(), []);

  const cardMainContent = (
    <Grid container wrap='nowrap'>
      {!isMobile && (
        <Grid item>
          {typeof primaryImage === 'string' ? (
            <CardMedia className={classes.summaryImage} image={primaryImage} title={title} />
          ) : (
            primaryImage
          )}
        </Grid>
      )}
      <Grid item className={classes.summaryTitlePanel}>
        <p
          className={classes.summaryTitle}
          id={`${field?.key}-card-label-${field?.additionalMetaData?.group}${
            vehicleId ? `-${vehicleId}` : ''
          }`}
        >
          {title}
        </p>
        <p className={classes.summarySubTitle}>{isDeductible ? 'Deductible' : 'Coverage Limit'}</p>
        <p className={cx(classes.summaryValue, !isCovered && classes.summaryValueRejected)}>
          {displayValue || selectedLabel}
        </p>
      </Grid>
    </Grid>
  );

  return primaryText ? (
    <Accordion
      className={cx(classes.root, classes.accordionWrapper, displayError && classes.accordionError)}
      variant='outlined'
      expanded={expanded}
      onChange={handleChange}
      TransitionProps={{ unmountOnExit }}
    >
      <div>
        {displayError && (
          <div className={classes.summaryError} role='alert'>
            <IconUIExclaimTriangle className={classes.errorIcon} />
            &nbsp; {displayError}
          </div>
        )}
        <AccordionSummary
          classes={{
            content: classes.summaryContent,
          }}
          ref={ref}
          expandIcon={<IconUIExpandMore />}
        >
          {cardMainContent}
        </AccordionSummary>
      </div>
      <Divider className={classes.divider} />

      <AccordionDetails className={classes.detailsRoot}>
        <div className={classes.detailsContainer}>
          <p>{primaryText}</p>
          {isHelpExpanded && renderSecondarySection()}
          {(secondaryText || personalInjuryTable || listItems) && (
            <Button
              className={classes.detailsHelpLink}
              variant='textSmall'
              onClick={handleHelpToggle}
              aria-label={isHelpExpanded ? `Read less ${primaryText}` : `Read more ${primaryText}`}
              id={`${field?.key}-readMore-button-${field?.additionalMetaData?.group}${
                vehicleId ? `-${vehicleId}` : ''
              }`}
            >
              {isHelpExpanded ? 'Read less' : 'Read more'}
            </Button>
          )}
          {editable && (
            <CoverageCardItems field={field} subCoverages={subCoverages} title={title} />
          )}
        </div>
      </AccordionDetails>
      {subCoverages && (
        <Button
          variant='iconText'
          onClick={handleShowHideSubCoveragesClick}
          className={classes.expandSubCoveragesButton}
          id={`${field?.key}_expandSubCoveragesButton`}
        >
          {expanded ? '- Hide' : '+ Show'} Sub Coverages
        </Button>
      )}
    </Accordion>
  ) : (
    <div className={cx(classes.root, classes.noPrimaryTextCard)}>{cardMainContent}</div>
  );
};
