import { useCallback, useEffect } from 'react';

import IframeResizer from 'iframe-resizer-react';

import { ApiAlert, Card, LoadingOverlay } from '@ecp/features/servicing/shared/components';
import { useClaimsIframeUrl } from '@ecp/features/servicing/shared/state';

import { useStyles } from './ClaimsIframe.styles';
import { RedirectToSafeliteModal } from './RedirectToSafeliteModal';
import { useOnMessage } from './useOnMessage';

export const ClaimsIframe: React.FC = () => {
  const { classes } = useStyles();

  const {
    claimNumber,
    context,
    isIframeLoadError,
    launchSafelite,
    loaded,
    onMessage,
    setLoaded,
    shouldLaunchSafelite,
    subContext,
  } = useOnMessage();
  const { url } = useClaimsIframeUrl({ context, subContext, claimNumber });

  useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => window.removeEventListener('message', onMessage);
  }, [onMessage]);

  // try to keep our loading overlay up as long as possible before closing in favor of iframe
  const handleLoad = useCallback(() => {
    const timeout = setTimeout(() => setLoaded(true), 1000);

    return () => clearTimeout(timeout);
  }, [setLoaded]);

  return (
    <div className={classes.root}>
      {isIframeLoadError && <ApiAlert apiDescription='claims' />}
      {(!loaded || !url) && <LoadingOverlay />}
      {!isIframeLoadError && url && (
        <>
          <Card
            body={
              <IframeResizer
                src={url}
                title='Claims'
                className={classes.iframe}
                bodyMargin={0}
                bodyPadding={0}
                checkOrigin={false}
                minHeight={500}
                onLoad={handleLoad}
                heightCalculationMethod='taggedElement'
                data-testid='claims-iframe'
              />
            }
          />
          <RedirectToSafeliteModal
            launchSafelite={launchSafelite}
            shouldLaunchSafelite={shouldLaunchSafelite}
          />
          {/* {env.static.nodeEnv === 'development' && (
            <ClaimsIframeTestHarness onMessage={onMessage} />
          )} */}
        </>
      )}
    </div>
  );
};
