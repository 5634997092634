import type { PersistentStorage } from './storage';

// Creates object of values for specific key
export const addItemToObject = (
  key: string,
  val: object,
  storage: PersistentStorage,
  isGlobal = false,
): void => {
  const valueFromStorage = storage.getItem(key);
  // parse value to object or create empty object if there is now value
  const valueObject = valueFromStorage ?? {};
  // combine saved value with new object
  const newValueList = Object.assign(valueObject, val);

  storage.setItem(key, newValueList, isGlobal);
};

export const getItemFromObject = (
  key: string,
  objectKey: string,
  storage: PersistentStorage,
): string | null => {
  const valueFromStorage = storage.getItem(key) as Record<string, string>;
  // Returns value from object

  return valueFromStorage?.[objectKey];
};
