import { Grid, Stack } from '@mui/material';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { FeatureFlags, flagValues, flagVariables } from '@ecp/utils/flags';
import { localStorage } from '@ecp/utils/storage';

import { Alert } from '@ecp/components';
import {
  ContactUsPage,
  CreateAccountPage,
  CreatePasswordPage,
  EnterPasswordPage,
  LoginPage,
  OneTimeCodePage,
  VerifyIdentityPage,
} from '@ecp/features/servicing/authentication';
import { AuthenticationCard, BrandDisclaimer } from '@ecp/features/servicing/shared/components';
import { PAGE_PATH, removePageFlowPrefixFromPaths } from '@ecp/features/servicing/shared/routing';
import { localStorageKeys } from '@ecp/features/servicing/shared/state';

import { useStyles } from './AuthenticationLayout.styles';
import { LoginLink } from './LoginLink';

const routerPath = removePageFlowPrefixFromPaths(PAGE_PATH.AUTH_INDEX);

const Layout: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;
  const { classes, cx } = useStyles();

  const { pathname } = useLocation();

  const showDisclaimer = pathname === PAGE_PATH.LOGIN;
  const showLoginLink = pathname === PAGE_PATH.CREATE_ACCOUNT;
  const showOutageBanner =
    flagValues.OUTAGE_NOTICE &&
    (pathname === PAGE_PATH.CREATE_ACCOUNT || pathname === PAGE_PATH.LOGIN);
  const outageText = flagVariables[FeatureFlags.OUTAGE_NOTICE].OUTAGE_TEXT;

  return (
    <Grid container className={classes.root} spacing={0}>
      {showDisclaimer && <BrandDisclaimer />}
      {showLoginLink && (
        <Grid item xs={12} className={classes.loginLink}>
          <LoginLink />
        </Grid>
      )}
      <Grid item className={cx(classes.wrapper, showLoginLink && classes.withLoginLinkWrapper)}>
        <Stack spacing={2}>
          {showOutageBanner && outageText && (
            <Alert withIcon type='warning'>
              {outageText}
            </Alert>
          )}
          {children}
        </Stack>
      </Grid>
    </Grid>
  );
};

const AuthDisabled: React.FC = () => {
  const { classes } = useStyles();

  return (
    <AuthenticationCard>
      <Stack spacing={2} className={classes.disabledWrapper}>
        <h1>We're Sorry</h1>
        <p>
          This website is currently unavailable. If you need immediate assistance please contact us.
        </p>
      </Stack>
    </AuthenticationCard>
  );
};

export const AuthenticationLayout: React.FC = () => {
  const authDisabled = flagValues.DISABLE_AUTH_FLOW;
  const isAccountAlreadyExist =
    localStorage.getItem(localStorageKeys.accountExistKey) ||
    localStorage.getItem(localStorageKeys.emailStorageKey);

  return (
    <Layout>
      <Routes>
        {authDisabled ? (
          <>
            <Route index element={<AuthDisabled />} />
            <Route path='*' element={<Navigate replace to={routerPath.AUTH_INDEX} />} />
          </>
        ) : (
          <>
            <Route
              index
              element={
                <Navigate
                  replace
                  to={isAccountAlreadyExist ? routerPath.LOGIN : routerPath.CREATE_ACCOUNT}
                />
              }
            />
            <Route path={routerPath.LOGIN} element={<LoginPage />} />
            <Route path={routerPath.CREATE_ACCOUNT} element={<CreateAccountPage />} />
            <Route path={routerPath.CONTACTUS} element={<ContactUsPage />} />
            <Route
              path={routerPath.CREATE_ACCOUNT_CREATE_PASSWORD}
              element={<CreatePasswordPage />}
            />
            <Route path={routerPath.CREATE_ACCOUNT_ONE_TIME_CODE} element={<OneTimeCodePage />} />
            <Route
              path={routerPath.CREATE_ACCOUNT_VERIFY_IDENTITY}
              element={<VerifyIdentityPage />}
            />
            {/* recover email pages*/}
            <Route path={routerPath.RECOVER_EMAIL} element={<CreateAccountPage recoverEmail />} />
            <Route
              path={routerPath.RECOVER_EMAIL_ONE_TIME_CODE}
              element={<OneTimeCodePage recoverEmail />}
            />
            <Route
              path={routerPath.RECOVER_EMAIL_VERIFY_IDENTITY}
              element={<VerifyIdentityPage />}
            />
            <Route
              path={routerPath.RECOVER_EMAIL_RESET_PASSWORD}
              element={<CreatePasswordPage recoverEmail />}
            />
            <Route path={routerPath.RECOVER_EMAIL_ENTER_PASSWORD} element={<EnterPasswordPage />} />
            {/* recover password pages*/}
            <Route
              path={routerPath.RECOVER_PASSWORD}
              element={<CreateAccountPage recoverPassword />}
            />
            <Route
              path={routerPath.RECOVER_PASSWORD_ONE_TIME_CODE}
              element={<OneTimeCodePage recoverPassword />}
            />
            <Route
              path={routerPath.RECOVER_PASSWORD_VERIFY_IDENTITY}
              element={<VerifyIdentityPage />}
            />
            <Route
              path={routerPath.RECOVER_PASSWORD_RESET_PASSWORD}
              element={<CreatePasswordPage recoverPassword />}
            />
            {/* redirect to login if no match */}
            <Route path='*' element={<Navigate replace to={routerPath.AUTH_INDEX} />} />
          </>
        )}
      </Routes>
    </Layout>
  );
};
