type Listener = () => Promise<void>;

const messengerOpenedListeners: Listener[] = [];

/**
 * Registers a callback to be invoked each time the Messenger is opened.
 *
 * @param callback - The function to be called when the Messenger is opened.
 */
export const addMessengerOpenedListener = (callback: Listener): void => {
  messengerOpenedListeners.push(callback);
};

/**
 * Executes all registered Messenger opened listeners.
 */
export const runMessengerOpenedListeners = async (): Promise<void> => {
  await Promise.all(messengerOpenedListeners.map((listener) => listener()));
};

const conversationResetListeners: Listener[] = [];

/**
 * Registers a callback to be executed when the conversation is reset.
 *
 * @param callback - The function to be invoked upon conversation reset.
 */
export const addConversationResetListener = (callback: Listener): void => {
  conversationResetListeners.push(callback);
};

/**
 * Executes all registered conversation reset listeners.
 */
export const runConversationResetListeners = async (): Promise<void> => {
  await Promise.all(conversationResetListeners.map((listener) => listener()));
};

const conversationStartedListeners: Listener[] = [];

/**
 * Registers a callback to be executed when the conversation starts.
 *
 * @param callback - The function to be invoked when the conversation is started.
 */
export const addConversationStartedListener = (callback: Listener): void => {
  conversationStartedListeners.push(callback);
};

/**
 * Executes all registered conversation started listeners.
 */
export const runConversationStartedListeners = async (): Promise<void> => {
  await Promise.all(conversationStartedListeners.map((listener) => listener()));
};

const conversationClearedListeners: Listener[] = [];

/**
 * Registers a callback to be executed when the conversation is cleared.
 *
 * @param callback - The function to be invoked when the conversation is cleared.
 */
export const addConversationClearedListener = (callback: () => Promise<void>): void => {
  conversationClearedListeners.push(callback);
};

/**
 * Executes all registered conversation cleared listeners.
 */
export const runConversationClearedListeners = async (): Promise<void> => {
  await Promise.all(conversationClearedListeners.map((listener) => listener()));
};
