import { useCallback } from 'react';

import { Alert, Link } from '@ecp/components';
import { MODAL_FLOW, MODAL_STEP, useModalPath } from '@ecp/features/servicing/shared/routing';
import { isCaMileageFormRequired } from '@ecp/features/servicing/shared/state';
import type { UiPolicy } from '@ecp/features/servicing/shared/types';

interface CaMileageAlertProps {
  policyData: UiPolicy | undefined;
  onClose?: () => unknown;
}

export const CaMileageAlert: React.FC<CaMileageAlertProps> = (props) => {
  const { policyData, onClose } = props;

  const modalPath = useModalPath();
  const openCaMileageForm = useCallback(() => {
    if (!policyData) throw Error('Need policy to show CA Mileage alert');
    modalPath.init(policyData.policy.policyNumber, MODAL_FLOW.CA_MILEAGE, MODAL_STEP.MODAL_EDIT);
  }, [modalPath, policyData]);

  if (!isCaMileageFormRequired(policyData)) return null;

  return (
    <Alert type='warning' withAction withIcon hideOnClose={!onClose} onClose={onClose}>
      Your state requires us to verify your vehicle mileage.{' '}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid  */}
      <Link onClick={openCaMileageForm}>Complete California Mileage Form</Link>.
    </Alert>
  );
};
