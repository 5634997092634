import { Stack } from '@mui/material';

import { Button, PhoneLink } from '@ecp/components';
import type { UseModalPathReturn } from '@ecp/features/servicing/shared/routing';
import { usePolicy } from '@ecp/features/servicing/shared/state';
import { getBrandData } from '@ecp/features/servicing/shared/util';
import { GraphicUIAgentAvatar } from '@ecp/themes/base';

import { useStyles } from './ModalInterrupter.styles';

export const ModalInterrupter: React.FC<UseModalPathReturn> = (props) => {
  const { reset, policyNumber } = props;
  const { classes } = useStyles();
  const { policyData } = usePolicy(policyNumber);
  const customerServicePhoneNumber = getBrandData(policyData).mainCustServPhoneNum;

  return (
    <Stack className={classes.root} spacing={4}>
      <Stack spacing={3}>
        <div className={classes.iconWrapper}>
          <GraphicUIAgentAvatar className={classes.icon} />
        </div>
        <h2>Some changes cannot be made to your policy at this time.</h2>
        <p>
          For help, call <PhoneLink withUnderlinedLinkStyle number={customerServicePhoneNumber} />.
        </p>
      </Stack>
      <Button variant='primary' onClick={reset} className={classes.button}>
        Close
      </Button>
    </Stack>
  );
};
