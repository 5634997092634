import type { MouseEventHandler } from 'react';
import { useCallback } from 'react';

import { CircularProgress, Stack } from '@mui/material';

import { Button } from '@ecp/components';
import { Card } from '@ecp/features/servicing/shared/components';
import {
  PAGE_PATH,
  QUERY_PARAMS_AND_VALUE,
  usePageFlow,
} from '@ecp/features/servicing/shared/routing';
import { usePaperless } from '@ecp/features/servicing/shared/state';
import { IconUIEco } from '@ecp/themes/base';

import { useStyles } from './EnrollPaperlessCard.styles';

interface EnrollPaperlessCardProps {
  isErrorUserPolicies: boolean;
}

export const EnrollPaperlessCard: React.FC<EnrollPaperlessCardProps> = (prop) => {
  const { isErrorUserPolicies } = prop;
  const {
    allPoliciesAreInactive,
    isAllPoliciesPaperlessOn,
    isLoading,
    isError: isErrorPaperless,
  } = usePaperless({
    throwOnError: false,
  });
  const { classes } = useStyles();
  const pageFlow = usePageFlow();
  const handleClick = useCallback<NonNullable<MouseEventHandler<HTMLButtonElement>>>(() => {
    pageFlow.navigate({
      pathname: PAGE_PATH.PROFILE,
      search: QUERY_PARAMS_AND_VALUE.PREFERENCES_TRUE,
    });
  }, [pageFlow]);

  const body = (
    <Stack className={classes.root} spacing={2}>
      <Stack direction='row' alignItems='center' spacing={1}>
        <div>
          <IconUIEco className={classes.icon} />
        </div>
        <h3>
          {isAllPoliciesPaperlessOn
            ? 'All policies are enrolled in paperless delivery'
            : 'Go paperless'}
        </h3>
      </Stack>
      {!isAllPoliciesPaperlessOn && (
        <span className={classes.description}>
          Get faster delivery of important documents, have less clutter to file and store at home,
          and feel great because it's eco-friendly.
        </span>
      )}
      <Button
        className={classes.enrollButton}
        variant='outlinePrimary'
        onClick={handleClick}
        trackingName='enroll_button'
        trackingLabel='enroll_continue'
      >
        {isAllPoliciesPaperlessOn ? 'EDIT' : 'ENROLL'}
      </Button>
    </Stack>
  );
  if (isLoading) return <CircularProgress aria-label='Loading...' />;
  if ((isErrorUserPolicies && isErrorPaperless) || allPoliciesAreInactive) return null;

  return <Card className={classes.root} body={body} />;
};
