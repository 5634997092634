// import { makeStyles as mui4MakeStyles } from '@material-ui/core';
// import { ClassNameMap, Styles, WithStylesOptions } from '@material-ui/core/styles/withStyles';
// import { CSSInterpolation } from '@mui/material';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { Keyframes } from '@emotion/react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { Css, CSSInterpolation, CSSObject, Cx } from 'tss-react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { keyframes as keyframesTss } from 'tss-react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { makeStyles as makeStylesTss } from 'tss-react/mui';

import type { Theme } from '../theme';

// eslint-disable-next-line @typescript-eslint/ban-types
// export type MakeStyles<T = ThemeV4> = <Props extends object = {}, ClassKey extends string = string>(
//   styles: Styles<T, Props, ClassKey>,
//   options?: Omit<WithStylesOptions<T>, 'withTheme'>,
// ) => keyof Props extends never
//   ? () => ClassNameMap<ClassKey> // `makeStyles` where the passed `styles` do not depend on props
//   : (props: Props) => ClassNameMap<ClassKey>; // `makeStyles` where the passed `styles` do depend on props

// without this, TS error when calling `useStyles(undefined, { props })` in components
// https://github.com/microsoft/TypeScript/issues/15300#issuecomment-983509320
// eslint-disable-next-line @typescript-eslint/ban-types
type DeepIndexSignature<O extends object = {}> = {
  [P in keyof O]: O[P] extends object ? DeepIndexSignature<O[P]> : O[P];
};

// TODO cleanup typing - hopefully using module augmentation - https://theexperimentationlab.atlassian.net/browse/POL-392
export type MakeStyles = <
  Params = void,
  RuleNameSubsetReferencableInNestedSelectors extends string = never,
>(
  params?:
    | {
        name?: string | Record<string, unknown> | undefined;
      }
    | undefined,
) => <RuleName extends string>(
  cssObjectByRuleNameOrGetCssObjectByRuleName:
    | Record<RuleName, CSSObject>
    | ((
        theme: Theme,
        params: Params,
        classes: Record<RuleNameSubsetReferencableInNestedSelectors, string>,
      ) => Record<RuleNameSubsetReferencableInNestedSelectors | RuleName, CSSObject>),
) => (
  params: Params,
  styleOverrides?:
    | {
        props: {
          classes?: Record<string, string> | undefined;
        } & DeepIndexSignature;
        ownerState?: DeepIndexSignature | undefined;
      }
    | undefined,
) => {
  classes: Record<RuleName, string>;
  theme: Theme;
  css: Css;
  cx: Cx;
};

/**
 * Adapter for MUI makeStyles conveniently typed with a custom theme.
 * As theme is static, it doesn't make sense to pass Theme generic in every makeStyles call.
 * Components have different props so if your styles depend on any props from the component,
 * you would need to pass this component props type as generic in makeStyles call.
 *
 * So, instead of `makeStyles<Theme, Props>(..args)` you call:
 *  - `makeStyles<Props>(..args)` - when styles depend on component props
 *  - `makeStyles(..args)` - when styles don't depend on component props
 *
 * @see [Original MUI makeStyles type](https://github.com/mui-org/material-ui/blob/v4.x/packages/material-ui/src/styles/makeStyles.d.ts)
 * @todo Currently it can't infer ClassKey when Props generic is set. Track TS issue related to partial generics application:
 *  - https://github.com/microsoft/TypeScript/issues/10571
 *  - https://github.com/microsoft/TypeScript/pull/26349
 */
export const makeStyles = makeStylesTss as MakeStyles;

/**
 * This is a convenience wrapper over https://emotion.sh/docs/keyframes.
 * To keep our styles consistent, prefer using object notation vs dynamic strings.
 *
 * @example
 * export const useStyles = makeStyles({
 *   name: 'LeComponent',
 * })((theme) => ({
 *   root: {
 *     marginLeft: 5,
 *     display: 'inline',
 *   },
 *  loadingContainer: {
 *    '& span': {
 *      animation: keyframes({
 *        '0%, 80%, 100%': {
 *          transform: 'scale(0)',
 *          letterSpacing: 5,
 *        },
 *        '50%': {
 *          transform: 'scale(1)',
 *          letterSpacing: 5,
 *        },
 *      }),
 *      animationDuration: '1.4s',
 *      animationIterationCount: 'infinite',
 *      animationFillMode: 'both',
 *      display: 'inline-block',
 *      color: theme.palette.text.primary,
 *    },
 *    '& span:nth-child(2)': {
 *      animationDelay: '.2s',
 *    },
 *    '& span:nth-child(3)': {
 *      animationDelay: '.4s',
 *    },
 *  },
 *}));
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const keyframes: (...args: CSSInterpolation[]) => Keyframes = keyframesTss;
