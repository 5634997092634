import { useCallback } from 'react';

import type { ButtonProps } from '@ecp/components';
import { Button } from '@ecp/components';
import {
  PAGE_PATH,
  QUERY_PARAMS_AND_VALUE,
  usePageFlow,
} from '@ecp/features/servicing/shared/routing';

import { useStyles } from './ContactUsLink.styles';

interface Props extends ButtonProps {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
}

export const ContactUsLink: React.FC<Props> = (props) => {
  const { className, children, objectType, variant } = props;
  const { classes, cx } = useStyles(undefined, { props });

  const pageFlow = usePageFlow();

  const handleContactUsClick = useCallback(() => {
    pageFlow.navigate({ pathname: PAGE_PATH.FAQ, search: QUERY_PARAMS_AND_VALUE.CONTACT_US_TRUE });
  }, [pageFlow]);

  return (
    <Button
      {...props}
      objectType={objectType ?? 'link'}
      variant={variant ?? 'text'}
      className={cx(classes.root, variant === 'primary' && classes.linkText, className)}
      onClick={handleContactUsClick}
    >
      {children ?? 'contact us'}
    </Button>
  );
};
