import type { Breakpoints } from '../breakpoints';
import type { TypographyOverrides } from './typography.types';

/**
 * Temporary workaround for: https://github.com/microsoft/TypeScript/issues/41247.
 * Remove after TS 4.7.0 upgrade:
 * https://github.com/microsoft/TypeScript/issues/47920
 * https://www.staging-typescript.org/play?ts=4.7.0-pr-46827-30#code/C4TwDgpgBA0hIGcoF4oHICGaoB90CNs80BjI9AEzQG4AoWgegagGEALCEgaymDY2BQMAG2FQwAJwD2kCaChd4SElIB2AMykSAtrylQEU7dFCRaK1QkGHjABWlgEAeXVxEKKAG9aUX0IBcUAAMADQ+fviBAEQcolJRYX5QAB6BABxQTFAAoskkEAhIkjIQciBQAJZI6sIYAOZ1EBS0AL4GAlXqFQVQthhyFSIAPABKnFoUQ24IIVAArqpcqlIA7qoAfOt0jMwAKuDQFRpa2h1qUFLqUBAYJGziDqXyVVCnR8AYR020NhD2Ms5XEoAHQYYHAKQAMQqySaAAoAJR0X7-RwuabA-DAhBzfBWCRHOpwgCMSJ2UAAysAKqIhKprhJpBJZmgqJUkNoqghCeY1IZhBBgcIpESUQ5ARiKGTyexODw+AIhLTirJ5Ip3P1oJIChBVMBeZZBCJhKiJUoPN4khhAqFwr5IlAYhA4gk7VASIFgBI5hBWu1qQguj0xioJJNprMFktVhtqJlmBR2a8uTysvtIJVjjozvSJBAPl9mgKjR5jab0SCwRDobCKIi6MWoPhS6Jy0DEJjsbj8STpVlZdxePwjcrHgMegA3EQ+91qTQ6PQGIwmA60UzQSG3YBIVCeKAAbXVgXxhIAuoF8FIpAKMPSWnQKJxanmoOoFiRqec7nLdt6+Ag4VSKBNw-BAEUCCcpAqCgHyfTVX3fT96W-bgAEkEAAQRWDBFEAwI9yqLCcIgC8rxvO9wKgSDoLoCwrBSC03UI7DFE9b1fTaBAOkDbokBA7c6CyAAhKQ+CgJwYHMDhuF-OZ-0AskUK4dCiNw5IySybJGS0WYjkfZIDAqOpVAEOYXwoKQClUNBBBIPMBGgfp8AqL1+nKFUnl4g1+UFYUiWSYEAC8NOYABZKpTmAb9Ew8soqOnQ5qlqBpvjowRkgAJkYpJtDYn1Ej8BBonUEQEAgKI-S4gMgz4rcEG2LIMOWPhSmuZIMG0MABVoCBkjALRBHXVhry0C0oAkQJVDmbR8FKWY6km6bZuZJtFpm1r73JDDaUuIcdQMNgpDmYRE1m4bhQkGYmzkqBUIuVRhHKOYyqXYwLiuFrdA4PNgR6vqBtnQ1ehEfNgGgXc3RWNgXJIrxxsCDKAFZEfmhHkdmB0kcRqAWgK+1am4fD4eCVGSagCgbRx2YsiGABaC4AQAQkobAjnEAnoF2tBCDdfBhB9ImJrJhaycx5Gqcq7iaqgEMJiGE9VDqWYWBGiQtm869fJFOE+mLMHMX5iAQuGxlOGAR7X2SxpEwwJBSiZDWBSFbXddB3yjLYYAAH0zK6o3tnJVC9VKEr8kqTqBWMPUczXA4oFCqQpz58GvDdbRE4gOEKCqD5VHyNblso6iYNaWi+Vs-pssKj45EROHcbTjOs4RVOkiSLJEwQQ7jtO6ApvWiQ3QbpIrBkOu9xaSXqt4+PE4wZOoAAMhl8Yw3lr1CUjRZljWdWsjbg+D4APRP0+z-Pi+z-JQ+b98KJtuFFY2vyQoHhKccEAqmVpJ4GQkJEN+qpyjriQFDGkWo8xlQkBOQkDwdR50OMcWOGZbBQT1BlRMu4UgFzmlAEAOCJA4zLkDbQIBUHvDGkBYkQQcb+k6DPPoAxhjkPQRQdWaVXhkLQcAISGBMFwyAlEahURaFVXoT0Rh1JmHcIwXvZgElZjLCgMzVQEAmhNCgN9X0dFNbOyJKQlhwBgQBWrDCeECJjYUm7idBkDtGxgA8AY7hwIQANWYCrYOyRgBzAAaYHkQ17BNAqCQByO44b7iKgYDeitzxQDhKoAhLdkDrCbGRG4d5tgcO1BQSJgTs4hLBmEy0fgqjZAnLqSaKAUn0gAKRQCysgRpJMmLOAoBTKA9JkkdKgHUhpTTiRT3EUgPJwTQl0CAA
 */
const satisfies = <T extends TypographyOverrides>(p: T): T => p;

export const createTypography = (breakpoints: Breakpoints): TypographyOverrides =>
  satisfies({
    body1: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
    },
    body1Bold: {
      fontSize: 14,
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
    },
    body1BoldItalics: {
      fontSize: 14,
      fontStyle: 'italic',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
    },
    body1BoldUnderline: {
      fontSize: 14,
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
      textDecoration: 'underline',
    },
    body1Italics: {
      fontSize: 14,
      fontStyle: 'italic',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
    },
    body1ItalicsUnderline: {
      fontSize: 14,
      fontStyle: 'italic',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
      textDecoration: 'underline',
    },
    body1Underline: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
      textDecoration: 'underline',
    },
    body2: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
    },
    body2Bold: {
      fontSize: 12,
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
    },
    body2BoldItalics: {
      fontSize: 12,
      fontStyle: 'italic',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
    },
    body2Italics: {
      fontSize: 12,
      fontStyle: 'italic',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
    },
    body2Underline: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
      textDecoration: 'underline',
    },
    body3: {
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
    },
    body3Bold: {
      fontSize: 16,
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
    },
    body3BoldItalics: {
      fontSize: 16,
      fontStyle: 'italic',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
    },
    body3Italics: {
      fontSize: 16,
      fontStyle: 'italic',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
    },
    body3Underline: {
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
      textDecoration: 'underline',
    },
    body4: {
      fontSize: 18,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '24px',
      textAlign: 'left',
      [breakpoints.down('md')]: {
        fontSize: 16,
      },
    },
    body4Bold: {
      fontSize: 18,
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '24px',
      textAlign: 'left',
    },
    body4Underline: {
      fontSize: 18,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '24px',
      textAlign: 'left',
      textDecoration: 'underline',
    },
    button: {
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '18px',
      textAlign: 'left',
    },
    button2: {
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '18px',
      textAlign: 'left',
    },
    button2Medium: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '16px',
      textAlign: 'left',
    },
    button2MediumUnderline: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '16px',
      textAlign: 'left',
      textDecoration: 'underline',
    },
    button2Small: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '14px',
      textAlign: 'left',
    },
    button2SmallUnderline: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '14px',
      textAlign: 'left',
      textDecoration: 'underline',
    },
    button2Underline: {
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '18px',
      textAlign: 'left',
      textDecoration: 'underline',
    },
    buttonMedium: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '16px',
      textAlign: 'left',
    },
    buttonSmall: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '14px',
      textAlign: 'left',
    },
    fontFamily: 'Roboto',
    fontFamilyFallback: 'Helvetica',
    fontFamilySecondary: 'Arial',
    fontFamilySecondaryFallback: 'Arial',
    h1: {
      fontSize: 28,
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '32px',
      textAlign: 'left',
      [breakpoints.down('md')]: {
        fontSize: 24,
        lineHeight: '28px',
      },
    },
    h2: {
      fontSize: 22,
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '32px',
      textAlign: 'left',
      [breakpoints.down('md')]: {
        fontSize: 18,
        lineHeight: '24px',
      },
    },
    h3: {
      fontSize: 18,
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '24px',
      textAlign: 'left',
      [breakpoints.down('md')]: {
        fontSize: 16,
      },
    },
    h4: {
      fontSize: 16,
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '24px',
      textAlign: 'left',
      [breakpoints.down('md')]: {},
    },
    h5: {
      fontSize: 14,
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '24px',
      textAlign: 'left',
      [breakpoints.down('md')]: {
        fontWeight: 400,
      },
    },
    h6: {
      fontSize: 12,
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '24px',
      textAlign: 'left',
      [breakpoints.down('md')]: {
        fontWeight: 400,
      },
    },
    header1: {
      fontSize: 20,
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '24px',
      textAlign: 'left',
    },
    htmlFontSize: 14,
    subtitle: {
      fontSize: 22,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '32px',
      textAlign: 'left',
      [breakpoints.down('md')]: {
        fontSize: 18,
        lineHeight: '24px',
      },
    },
    subtitleUnderline: {
      fontSize: 22,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '32px',
      textAlign: 'left',
      textDecoration: 'underline',
    },
  } as const);
