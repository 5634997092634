import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PaymentStatusCardBody' })((theme) => ({
  root: {},
  borderedButton: {
    border: `1px solid ${theme.palette.secondary.main}`,
    height: 'fit-content',
    paddingBottom: 12,
    paddingTop: 12,
    width: 'fit-content',
    [theme.breakpoints.down('md')]: { width: '100%' },
  },
}));
