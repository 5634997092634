import { productPolicyDescriptionMap } from '@ecp/features/servicing/shared/constants';
import type { UiPolicy } from '@ecp/features/servicing/shared/types';

import { getProductLineFromPolicyResponse } from './getProductLineFromPolicyResponse';
import { isLegacyPolicy } from './isLegacyPolicy';

export const getPolicyDisplayType = (policyResponse: UiPolicy | undefined): string | undefined => {
  if (!policyResponse) return undefined;
  const productLine = getProductLineFromPolicyResponse(policyResponse);
  const isClassic = isLegacyPolicy(productLine, policyResponse.policy.sourceSystemName);
  const autoProduct = isClassic ? 'Auto' : 'Personal Auto';

  if (productLine === 'AUTO') {
    return autoProduct;
  }

  const productPolicyDescriptionDisplay =
    productPolicyDescriptionMap[policyResponse.coverageOptions.at(0)?.policyTypeCode ?? ''];

  return productPolicyDescriptionDisplay || 'Home';
};
