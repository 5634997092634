import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CaMileageConfirmation' })((theme) => ({
  root: {},
  closeButton: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 'fit-content',
    },
  },
  successIcon: {
    ...theme.mixins.setColorSvg(theme.palette.success.main),
    height: 64,
    width: 64,
  },
  successIconWrapper: {
    textAlign: 'center',
  },
}));
