import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ListItems' })((theme) => ({
  root: {
    listStyleType: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    padding: 0,
    margin: 0,

    [theme.breakpoints.down('md')]: {
      padding: '2px 0 16px 0',
    },
  },
  item: {
    ...theme.typography.body1,
    padding: 5,
    display: 'flex',
    marginRight: 40,
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start',
    },
  },
  svg: {
    ...theme.mixins.svg(),
    '& svg, &': {
      fontSize: 14,
      marginRight: 3,
      color: theme.palette.grey[700],
    },
  },
  banner: {
    padding: '10px 10px 10px 15px',
    listStyleType: 'none',
    display: 'flex',
    justifyContent: 'space-between',
  },
  expandIcon: theme.mixins.setColorSvg(theme.palette.primary.main),
  expandIconButton: {
    padding: 0,
    fontSize: 14,
  },
  bannerText: {
    ...theme.typography.body1Bold,
    color: theme.palette.primary.main,
  },
  icon: {},
}));
