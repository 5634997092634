import * as yup from 'yup';

import type { YupSchemaMap } from '@ecp/utils/form';

export interface PaymentFormFooterInputs {
  consent?: string;
}

export const makePaymentConsentSchema: YupSchemaMap<PaymentFormFooterInputs> = {
  consent: yup.string().required('Payment must be authorized'),
};
