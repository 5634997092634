import { Card as BaseCard } from '@ecp/components';

import { useStyles } from './Card.styles';

export type CardProps = React.ComponentProps<typeof BaseCard>;

export const Card: React.FC<CardProps> = (props) => {
  const { classes } = useStyles(undefined, { props });

  return <BaseCard divider={false} headerDivider={false} {...props} classes={classes} />;
};
