import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'Discounts' })((theme) => ({
  root: {},
  list: {
    paddingBottom: 12,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 0,
      '& >:not(style)+:not(style)': { marginTop: 12 },
    },
  },
  item: {
    alignItems: 'center',
    padding: '12px 0 0',
    width: '33%',
    [theme.breakpoints.down('md')]: {
      padding: 0,
      width: '100%',
    },
  },
  svg: { display: 'flex' },
}));
