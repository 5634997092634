import type { ValueOf } from 'type-fest';

/** Each value here defines GA dimension name. */
export const TrackingDimensions = {
  ACTIVE_POLICY_NUMBER: 'active_policy_number',
  AGENCY_NUMBER: 'agency_number',
  AGENT_ID: 'agent_id',
  APPLICATION_ID: 'application_id',
  BINDABLE_QUOTE: {
    AUTO: 'auto_bindable_quote',
    PROPERTY: 'property_bindable_quote',
  },
  CUSTOMER_ID: 'customer_id',
  DRIVER_REF: 'driver_ref',
  DRIVER_REF_PRIMARY: 'primary_driver_ref',
  ECID: 'customer_id',
  EFFECTIVE_DATE: {
    AUTO: 'auto_policy_effective_date',
    PROPERTY: 'property_policy_effective_date',
  },
  EMAIL_UUID: 'email_uuid',
  ENV_AND_EXPERIENCE_ID: 'envAndExperience',
  ENV_AND_PARTNER_ID: 'envAndPartnerCd',
  EXPERIENCE_ID_CURRENT: 'current_experience_id',
  EXPERIENCE_ID_INITIAL: 'initial_experience_id',
  EXPIRATION_DATE: {
    AUTO: 'auto_policy_expiration_date',
    PROPERTY: 'property_policy_expiration_date',
  },
  DALSESSION_ID: 'dalsession_id',
  INQUIRY_ID: 'inquiry_id',
  INTERACTION_ID: 'interaction_id',
  LOB_CURRENT: 'current_line_of_business',
  LOB_INITIAL: 'initial_line_of_business',
  MEMBERSHIP_TIER: 'membership_tier',
  MODAL_NAME: 'modal_name',
  OFFER_SET_ID: 'offer_set_id',
  OPTIMIZELY_VISITOR_ID: 'optimizely_visitor_id',
  PARTNER_ID: 'partner_level_one_code',
  POLICY_NUMBER: {
    AUTO: 'auto_policy_number',
    PROPERTY: 'property_policy_number',
  },
  PREFILL_FLOW: {
    AUTO: 'auto_prefill_flow',
    PROPERTY: 'property_prefill_flow',
  },
  PRODUCT: {
    AUTO: 'auto_product',
    PROPERTY: 'property_product',
  },
  QUOTE_NUMBER: {
    AUTO: 'auto_quote_number',
    PROPERTY: 'property_quote_number',
  },
  REDIRECT_ID: 'redirect_id',
  REF_ID: 'ref_id',
  RETREIVE_FLAG: 'retrieve_flag',
  SESSION_ID: 'session_id',
  STATE: 'state',
  STATUS: {
    AUTO: 'auto_policy_status',
    PROPERTY: 'property_policy_status',
  },
  USER_AGENT: 'user_agent',
  USER_ID: 'user_id',
  VEHICLE_REF: 'vehicle_ref',
  ZIP: 'zipcode',
} as const;

type NestedTrackingDimensionsKeys =
  | 'BINDABLE_QUOTE'
  | 'EFFECTIVE_DATE'
  | 'EXPIRATION_DATE'
  | 'POLICY_NUMBER'
  | 'PREFILL_FLOW'
  | 'PRODUCT'
  | 'QUOTE_NUMBER'
  | 'STATUS';

export type TrackingDimensionsNames =
  | ValueOf<Omit<typeof TrackingDimensions, NestedTrackingDimensionsKeys>>
  | ValueOf<(typeof TrackingDimensions)[NestedTrackingDimensionsKeys]>;

export type TrackingDimensionsObject = Partial<Record<TrackingDimensionsNames, string>>;
