import { useMemo } from 'react';

import type { Params } from 'react-router-dom';
import { generatePath, useSearchParams } from 'react-router-dom';

import { QUERY_PARAMS, useCurrentRoute } from '@ecp/features/servicing/shared/routing';
import type { NavOptions } from '@ecp/features/servicing/shared/types';

const needsEncodingDecoding = [
  QUERY_PARAMS.POLICY_NUMBER,
  QUERY_PARAMS.OKTA_LOGIN,
  QUERY_PARAMS.SEARCH_FOR,
];

export const encodeUrlValue = window.btoa;
export const decodeUrlValue = window.atob;

const encodeParams = (paramsProp: NavOptions): Record<string, string> =>
  Object.entries(paramsProp).reduce((existingObj, [key, value]) => {
    return {
      ...existingObj,
      [key]: needsEncodingDecoding.includes(key) ? encodeUrlValue(`${value ?? ''}`) : value,
    };
  }, {});
// generates path with encoded param if it is a part of needsEncodingDecoding
export const generateEncodedPath = (path: string, paramsProp: NavOptions): string => {
  const params = encodeParams(paramsProp);

  return generatePath(path, { ...params });
};

const decodeParams = (params: Params<string>): Params<string> =>
  Object.entries(params).reduce((existingObj, [name, value]) => {
    return {
      ...existingObj,
      [name]: needsEncodingDecoding.includes(name) ? decodeUrlValue(value ?? '') : value,
    };
  }, {});
// gets params and decodes it, if it is a part of needsEncodingDecoding
export const useDecodedParams = (): Params<string> => {
  const { params: urlParams } = useCurrentRoute();
  const [searchParams] = useSearchParams();

  const params = useMemo(
    () => ({
      ...urlParams,
      ...Object.fromEntries(searchParams),
    }),
    [searchParams, urlParams],
  );

  return useMemo(() => decodeParams(params), [params]);
};
