import { QUERY_KEYS } from '../constants';
import type { ApiRequestOptions } from '../servicingRequest';
import { getBillingDocuments } from './api';
import type { Documents } from './types';

export const getBillingDocumentKey = (
  billingNumber: string | undefined,
  documentId: string | undefined,
): Array<unknown> => [QUERY_KEYS.BILLINGACCOUNT_DOCUMENT, billingNumber, documentId];

export const getBillingDocumentsKey = (billingNumber: string): Array<unknown> => [
  QUERY_KEYS.BILLINGACCOUNT_DOCUMENTS,
  billingNumber,
];

export const getBillingDocumentsQuery = async (
  billingNumber: string,
  apiRequestOptions?: ApiRequestOptions,
): Promise<Documents> =>
  (await getBillingDocuments({ requestNumber: billingNumber }, apiRequestOptions)).payload;
