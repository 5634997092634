import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'LoginPage' })((theme) => ({
  root: {},
  loginButton: {
    width: 'fit-content',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  navlink: {
    color: theme.palette.text.link,
  },
  input: { width: '100%' },
}));
