import { Children } from 'react';

import { ScrollSpyMarkerClasses, TooltipWithIcon } from '@ecp/components';

import { useStyles } from './InfoCardList.styles';

type Props = {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  id: string;
  title: string;
  tooltip?: string;
};

export const InfoCardList: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { children, id, title, tooltip } = props;
  const { classes, cx } = useStyles(undefined, { props });

  return (
    <div className={classes.root}>
      <h3 className={cx(classes.title, ScrollSpyMarkerClasses.LEVEL_TWO)} id={id}>
        {title}
        {!!tooltip && <TooltipWithIcon className={classes.tooltip} title={tooltip} />}
      </h3>
      <ul className={classes.list}>
        {Children.map(children, (child, index) => child && <li key={index}>{child}</li>)}
      </ul>
    </div>
  );
};
