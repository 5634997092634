import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PolicyPaymentDisplay' })((theme) => ({
  root: {
    padding: '16px 16px 20px',
    borderRadius: 4,
  },
  paymentDetails: {
    padding: '8px 0 0',
    borderRadius: 0,
  },
  minimumDue: theme.typography.h1,
  paymentBorder: {
    borderRadius: 4,
    border: `1px solid ${theme.palette.other.border}`,
    padding: 24,
  },
  paymentContent: { padding: 0 },
}));
