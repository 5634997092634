import { memo } from 'react';

import { Stack } from '@mui/material';

import { ScrollSpyMarkerClasses } from '@ecp/components';
import type { OptionData } from '@ecp/features/shared/coverages';
import { CoverageCard } from '@ecp/features/shared/coverages';

import { useStyles } from './CoveragesSubSection.styles';

interface Props {
  coverageItems: OptionData[];
  isDeductible?: boolean;
  title: string;
  vehicleId?: string;
  showTitleInScrollspy?: boolean;
}

export const CoveragesSubSection: React.FC<Props> = memo((props) => {
  const {
    coverageItems,
    isDeductible = false,
    title,
    vehicleId,
    showTitleInScrollspy = true,
  } = props;
  const { classes } = useStyles();

  if (!coverageItems.length) return null;

  return (
    <Stack spacing={1}>
      <h4
        id={showTitleInScrollspy ? `${title.toLowerCase().replace(/ |'/g, '_')}` : ''}
        className={showTitleInScrollspy ? ScrollSpyMarkerClasses.LEVEL_TWO : ''}
      >
        {title}
      </h4>
      <Stack spacing={2}>
        {coverageItems.map((coverage) => (
          <CoverageCard
            key={`${coverage.title}-${vehicleId}`}
            {...coverage}
            isDeductible={isDeductible}
            classes={{ root: classes.card }}
            vehicleId={vehicleId}
          />
        ))}
      </Stack>
    </Stack>
  );
});
