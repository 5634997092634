import { forwardRef, memo, useCallback } from 'react';

import type { SwitchProps as MuiSwitchProps } from '@mui/material';
import { FormControlLabel, FormGroup, Switch as MuiSwitch } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';

import { useStyles } from './Switch.styles';

export interface SwitchProps extends MuiSwitchProps {
  label?: React.ReactNode;
  actionOnChange?(value: boolean): void;
  actionOnComplete?(value: boolean): void;
  trackingName?: string;
}

export const Switch: React.FC<SwitchProps> = memo(
  forwardRef((props, ref) => {
    const {
      actionOnChange,
      actionOnComplete,
      checked,
      label,
      onChange,
      value,
      trackingName,
      ...rest
    } = props;
    const { classes } = useStyles();

    const handleChange = useCallback<NonNullable<SwitchProps['onChange']>>(
      (event, active) => {
        actionOnChange?.(active);
        actionOnComplete?.(active);
        onChange?.(event, active);
        if (trackingName) {
          trackClick({
            action: trackingName,
            label: active ? 'Yes' : 'No',
            objectType: 'toggle button',
          });
        }
      },
      [actionOnChange, actionOnComplete, onChange, trackingName],
    );

    return (
      <FormGroup className={classes.root}>
        <FormControlLabel
          control={
            <MuiSwitch
              checked={checked}
              onChange={handleChange}
              focusVisibleClassName={classes.focusVisible}
              disableRipple
              classes={{
                root: classes.switchRoot,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
              }}
              inputRef={ref}
              {...rest}
            />
          }
          label={label}
          classes={{
            label: classes.label,
          }}
        />
      </FormGroup>
    );
  }),
);
