/**
 * Sets the default delay in milliseconds for loading the Genesys chat script.
 * This delay ensures the chat script does not load prematurely, avoiding bandwidth competition
 * with session creation API calls, which can negatively impact the Largest Contentful Paint (LCP).
 */
export const DEFAULT_TIMEOUT = 6000;

/**
 * Specifies the URL for the Genesys bootstrap script. Once this script is loaded and executed,
 * Genesys SDK will be available at `window.Genesys`.
 */
export const GENESYS_BOOTSTRAP_SCRIPT_URL =
  'https://apps.usw2.pure.cloud/genesys-bootstrap/genesys.min.js';
