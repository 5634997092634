import { cloneElement, useCallback, useState } from 'react';

import type { FormControlLabelProps } from '@mui/material';
import { ButtonBase, Divider, FormControlLabel } from '@mui/material';

import {
  IconUICheckCircleSolid,
  IconUIInfo,
  IconUIPlus,
  IconUIQuestion,
  useIsMobile,
} from '@ecp/themes/base';
import { IconUITooltip } from '@ecp/themes/partners/amfam-adv';
import type { CardOption } from '@ecp/types';

import { Tooltip } from '../../Tooltip';
import { useStyles } from './CardFormControlLabel.styles';

export interface Props extends Omit<FormControlLabelProps, 'label'> {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  option: CardOption;
  showAddIcon?: boolean;
  disabledOptionTip?: string;
  divider?: boolean;
  profileCard?: boolean;
  size?: 'small' | 'medium' | 'large' | 'sizeHorizontal' | 'sizeIconCard' | 'sizePaddedHorizontal';
  controlType?: string;
  variant?:
    | 'card'
    | 'editCard'
    | 'classicCompact'
    | 'horizontalCard'
    | 'iconCard'
    | 'buttonCardWithRadioButton'
    | 'horizontalCardCuztomizedLabel'
    | 'paddedHorizontalCard';
  className?: string;
  tabIndex?: number;
  onButtonClick?(val: string, newChecked: boolean): void;
  cardWithShowMoreOrLessProps?: boolean;
}

export const CardFormControlLabel: React.FC<Props> = (props) => {
  const {
    checked,
    showAddIcon,
    classes: propClasses, // unused, just don't want to pass along in rest
    profileCard,
    control,
    variant = 'card',
    disabled,
    disabledOptionTip,
    divider,
    id,
    name,
    option,
    size = 'small',
    controlType,
    className,
    tabIndex = -1,
    onButtonClick,
    cardWithShowMoreOrLessProps,
    ...rest
  } = props;
  const isHorizontal =
    variant === 'horizontalCard' ||
    variant === 'horizontalCardCuztomizedLabel' ||
    variant === 'paddedHorizontalCard';
  const isPadded = variant === 'paddedHorizontalCard';
  const isIconCard = variant === 'iconCard';
  const isButtonCardVariant = variant === 'buttonCardWithRadioButton';
  const { classes, cx } = useStyles();

  const isMobile = useIsMobile();
  const handleTooltipClick = useCallback((event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    // when tooltip is clicked, don't allow event to propogate to parent and check/uncheck control
    event.stopPropagation();
    event.preventDefault();
  }, []);

  const hanldeKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === ' ' || event.key === 'Enter') {
        onButtonClick?.(option.value, !checked);
      }
    },
    [checked, onButtonClick, option.value],
  );

  const [showInfo, setShowInfo] = useState(false);
  const handleHover = useCallback((event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();

    setShowInfo(event.type === 'mouseenter');
  }, []);
  const showTooltip = !!option.helpText || (!!option.image && isMobile && !!option.icon);

  const getLabel = (): React.ReactElement => {
    const tooltipEl = showTooltip && (
      <Tooltip title={option.helpText || ''}>
        <button
          aria-label={`${option.label}-tooltip`}
          className={cx(classes.help, isHorizontal && propClasses?.help)}
          onClick={handleTooltipClick}
          onMouseDown={handleTooltipClick}
          onMouseUp={handleTooltipClick}
          type='button'
        >
          <IconUIQuestion
            className={cx(
              classes.helpIcon,
              isHorizontal && classes.helpIcon,
              isHorizontal && propClasses?.helpIcon,
            )}
          />
        </button>
      </Tooltip>
    );
    if (option.iconProduct && isIconCard) {
      return (
        <>
          {tooltipEl}
          {cloneElement(option.iconProduct, {
            className: cx(
              classes.iconCard,
              checked && classes.iconCardChecked,
              disabled && classes.iconCardDisabled,
            ),
          })}
          {showAddIcon && (
            <div className={cx(classes.iconFixedToCorner, classes.iconCircleBackground)}>
              <IconUIPlus className={classes.iconSelectable} />
            </div>
          )}
          {checked && (
            <div className={classes.iconFixedToCorner}>
              <IconUICheckCircleSolid className={classes.iconCardChecked} />
            </div>
          )}
          {disabled && (
            <div className={cx(classes.iconFixedToCorner, classes.iconCircleBackground)}>
              <Tooltip title={disabledOptionTip || ''} open={showInfo}>
                <IconUIInfo className={classes.iconCardInfo} />
              </Tooltip>
            </div>
          )}
          <p
            className={cx(
              classes.iconCardLabel,
              checked && classes.iconCardLabelChecked,
              disabled && classes.iconCardLabelDisabled,
            )}
          >
            {option.label}
            {option.subLabel && <span className={classes.subLabel}> {option.subLabel}</span>}
          </p>
        </>
      );
    }
    if (option.icon) {
      if (variant === 'horizontalCardCuztomizedLabel') {
        const tooltipEl = showTooltip && (
          <Tooltip title={option.helpText || ''}>
            <button
              aria-label={`${option.label}-tooltip`}
              className={cx(classes.help, isHorizontal && propClasses?.help)}
              onClick={handleTooltipClick}
              onMouseDown={handleTooltipClick}
              onMouseUp={handleTooltipClick}
              type='button'
            >
              <IconUITooltip
                className={cx(
                  classes.helpIcon,
                  isHorizontal && classes.helpIcon,
                  isHorizontal && propClasses?.helpIcon,
                )}
              />
            </button>
          </Tooltip>
        );

        return (
          <>
            {divider && !isHorizontal && (
              <Divider aria-hidden='true' className={classes.horizontalDivider} />
            )}
            <p
              className={cx(
                classes.label,
                isHorizontal && classes.horizontalLabel,
                isHorizontal && propClasses?.horizontalLabel,
              )}
            >
              <span className={classes.cardTitleAndIcon}>
                {option.label}
                {tooltipEl}
              </span>
              {option.subLabel && (
                <span className={(classes.subLabel, propClasses?.subLabel)}>
                  {' '}
                  {option.subLabel}
                </span>
              )}
            </p>
            <div
              className={cx(
                classes.icon,
                isHorizontal ? classes.svgHorizontal : classes.svg,
                disabled && !checked && classes.iconDisabled,
                isHorizontal && propClasses?.svgHorizontal,
              )}
            >
              {option.icon}
            </div>
          </>
        );
      }

      return (
        <>
          {tooltipEl}
          <div
            className={cx(
              classes.icon,
              isHorizontal ? classes.svgHorizontal : classes.svg,
              isButtonCardVariant && classes.svgRadioButtonCard,
              disabled && !checked && classes.iconDisabled,
            )}
          >
            {option.icon}
          </div>
          {divider && !isHorizontal && (
            <Divider aria-hidden='true' className={classes.horizontalDivider} />
          )}
          <p
            className={cx(
              classes.label,
              isHorizontal && classes.horizontalLabel,
              isHorizontal && propClasses?.horizontalLabel,
              isButtonCardVariant && classes.radioButtonCardLabel,
            )}
          >
            {option.label}
            {option.subLabel && <span className={classes.subLabel}> {option.subLabel}</span>}
          </p>
        </>
      );
    }
    if (option.image) {
      const image =
        typeof option.image === 'string' ? (
          <img src={option.image} alt={option.alt || `${option.label}`} />
        ) : (
          option.image
        );

      return (
        <>
          {tooltipEl}
          {cloneElement(image, {
            className: cx(
              classes.image,
              profileCard && classes.profileImage,
              image.props.className,
            ),
          })}
          <p
            className={cx(
              classes.label,
              profileCard && classes.profileLabel,
              isHorizontal && (isPadded ? classes.paddedHorizontalLabel : classes.horizontalLabel),
              isHorizontal && propClasses?.horizontalLabel,
            )}
          >
            {option.label}
            {option.subLabel && <span className={classes.subLabel}> {option.subLabel}</span>}
          </p>
          {option.completeIcon && (
            <div className={classes.labelIcon}>
              <Tooltip
                title={
                  option.completeValue ? 'Profile complete' : 'Profile incomplete. Edit required.'
                }
                aria-hidden='true'
              >
                <div
                  className={cx(
                    classes.svg,
                    option.completeValue ? classes.completeIcon : classes.incompleteIcon,
                  )}
                >
                  {option.completeIcon}
                </div>
              </Tooltip>
            </div>
          )}
        </>
      );
    }

    if (option.noIcon) {
      return (
        <>
          {tooltipEl}
          {divider && <Divider aria-hidden='true' className={classes.horizontalDivider} />}
          <p
            className={cx(
              classes.label,
              isHorizontal && (isPadded ? classes.paddedHorizontalLabel : classes.horizontalLabel),
              isHorizontal && propClasses?.horizontalLabel,
              // noIconLabelWithShowMoreOrLessProps is needed so that text is center aligned when there are no images or icons
              cardWithShowMoreOrLessProps && classes.noIconLabelWithShowMoreOrLessProps,
            )}
          >
            {option.label}
            {option.subLabel && <span className={classes.subLabel}> {option.subLabel}</span>}
          </p>
        </>
      );
    }

    throw Error(
      `Cannot show CardFormControlLabel without either an image or icon option prop for [${
        name || id
      }]`,
    );
  };

  return (
    <ButtonBase
      classes={{
        root: cx(
          propClasses?.root ?? classes.root,
          isIconCard && classes.iconCardRoot,
          checked && classes.checked,
          checked && isIconCard && classes.iconCardRootChecked,
          (option.icon || option.iconProduct) && classes.hasIcon,
          option.image && classes.hasImage,
          showTooltip && classes.hasTooltip,
          classes[size],
          propClasses && propClasses[size],
          profileCard && classes.profileLabelButton,
          isButtonCardVariant && classes.radioButtonCardRoot,
          disabled && (propClasses?.rootDisabled ?? classes.rootDisabled),
          // classes.noIconWithShowMoreOrLessProps is to set a default height of the tiles if there
          // are no images (option.noIcon). Default height is same as `Show More Options` styles.
          cardWithShowMoreOrLessProps && option.noIcon && classes.noIconWithShowMoreOrLessProps,
        ),
      }}
      component='div'
      // if input is radio a parent element will have radiogroup role
      role={controlType === 'radio' ? 'none' : 'group'}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
      onKeyDown={hanldeKeyDown}
      tabIndex={tabIndex}
    >
      <FormControlLabel
        checked={checked}
        label={getLabel()}
        value={option.value}
        {...rest}
        classes={{
          root: profileCard
            ? classes.profileLabelRoot
            : isHorizontal
            ? cx(
                isPadded ? classes.paddedHorizontalLabelRoot : classes.horizontalLabelRoot,
                propClasses?.horizontalLabelRoot,
              )
            : isIconCard
            ? classes.iconCardLabelRoot
            : isButtonCardVariant
            ? cx(classes.labelRoot, classes.radioButtonCardLabelRoot)
            : classes.labelRoot,
          label: isIconCard
            ? classes.iconCardLabelWrapper
            : isHorizontal
            ? classes.horizontalLabelWrapper
            : isButtonCardVariant
            ? cx(classes.labelWrapper, classes.radioButtonCardLabelWrapper)
            : classes.labelWrapper,
        }}
        control={cloneElement(control, {
          className:
            control.props.className ??
            cx(
              classes.control,
              isHorizontal && classes.horizontalControl,
              isHorizontal && propClasses?.horizontalControl,
              isButtonCardVariant && classes.radioButtonCardControl,
            ),
        })}
        disabled={disabled}
        id={id}
        key={option.value}
        name={name}
      />
    </ButtonBase>
  );
};
