import { useMemo } from 'react';

import { Divider, Stack } from '@mui/material';

import { isOlderThan } from '@ecp/utils/date';
import { useEvent } from '@ecp/utils/react';

import { Alert, Button } from '@ecp/components';
import { PaperlessModal } from '@ecp/features/servicing/preferences';
import {
  ContactUsLink,
  ContactUsMicro,
  DashboardCard,
  DocumentTable,
  LoadingOverlay,
} from '@ecp/features/servicing/shared/components';
import { MODAL_STEP, useModalPath } from '@ecp/features/servicing/shared/routing';
import type { Document } from '@ecp/features/servicing/shared/state';
import {
  getAddressString,
  getPoiDocument,
  getPolicyAddress,
  getPolicyDisplayType,
  getProductLineFromPolicyResponse,
  getVehicleDescription,
  isLegacyPolicy,
  isPolicyAllowedToSetPaperless,
  useBillingPolicyDocuments,
  usePaperless,
  usePolicy,
  usePreferences,
} from '@ecp/features/servicing/shared/state';
import { useDecodedParams } from '@ecp/features/servicing/shared/util';
import { IconMaterialDesignActionSettings, useIsMobile } from '@ecp/themes/base';

import { DocumentAccordionGroup } from '../../components';
import { isClassicPoiDocument } from '../../util';
import { MobileMenuItem } from './MobileMenuItem';
import { useStyles } from './PolicyDocumentsPage.styles';

export const PolicyDocumentsPage: React.FC = () => {
  const { classes, cx } = useStyles();
  const { policyNumber = '' } = useDecodedParams();
  const modalPath = useModalPath();

  const {
    policyData,
    isLoading: isLoadingPolicy,
    isError: isErrorPolicy,
  } = usePolicy(policyNumber, {
    throwOnError: false,
  });
  const policyType = getPolicyDisplayType(policyData);

  const {
    isAnyActivePolicyNotAllowingPaperless,
    isError: isErrorPaperless,
    isLoading: isLoadingPaperless,
  } = usePaperless({
    throwOnError: false,
  });

  const {
    isPaperlessOn,
    isError: isErrorPreferences,
    isLoading: isLoadingPreferences,
  } = usePreferences(policyNumber, {
    throwOnError: false,
  });

  const {
    isError: isErrorDocuments,
    isLoading: isLoadingDocuments,
    documents,
  } = useBillingPolicyDocuments({
    policyNumbers: policyNumber ? [policyNumber] : undefined,
    bypassPolicyFilter: true,
  });

  const isHomeProduct = getProductLineFromPolicyResponse(policyData) === 'HOME';
  const isLegacy = isLegacyPolicy(
    getProductLineFromPolicyResponse(policyData),
    policyData?.policy.sourceSystemName,
  );

  const newDocuments: Document[] = useMemo(() => {
    const sortedAndFilteredDocs =
      documents.documentDataList
        ?.sort(
          (a, b) =>
            new Date(b.documentCreationDate).getTime() - new Date(a.documentCreationDate).getTime(),
        )
        ?.filter(
          (doc) =>
            doc.documentTitle.toLowerCase().indexOf('identification') < 0 && // Filter all auto ID cards, since they are being added below to newDocuments
            !isClassicPoiDocument(doc) && // Filter classic home POI, since they are being added below to newDocuments
            !isOlderThan(30, 'days', doc.documentCreationDate),
        ) ?? [];

    // In case of Legacy policy, we need to add the ID cards for Auto Policy or POI in case of home
    if (isLegacy) {
      if (isHomeProduct) {
        const pois = documents.documentDataList?.filter((doc) => isClassicPoiDocument(doc));
        if (pois?.length) sortedAndFilteredDocs?.push(pois?.[0]); // Push only the latest POI
      } else {
        policyData?.vehicles?.forEach((vehicle) => {
          const poiDoc = getPoiDocument(vehicle, documents);
          if (poiDoc) sortedAndFilteredDocs?.push(poiDoc);
        });
      }
    }

    // In case of Auto policies and HS policies, we are receiving Declaration document(s),
    // hence checking if they were generated in last 30days and adding them back to new document section
    if ((isLegacy && !isHomeProduct) || (!isLegacy && isHomeProduct)) {
      const declarationDocs = documents.documentDataList?.filter((doc) =>
        isClassicPoiDocument(doc),
      );
      declarationDocs?.forEach((decDoc) => {
        if (!isOlderThan(30, 'days', decDoc.documentCreationDate))
          sortedAndFilteredDocs.push(decDoc);
      });
    }

    return sortedAndFilteredDocs;
  }, [documents, isHomeProduct, isLegacy, policyData?.vehicles]);

  const allDocuments = documents.documentDataList;

  const isError = isErrorPolicy || isErrorDocuments || isErrorPreferences || isErrorPaperless;
  const isLoading =
    isLoadingPolicy || isLoadingDocuments || isLoadingPreferences || isLoadingPaperless;

  const { policyAllowedToSetPaperless, policyAllowedToUnsetPaperless } =
    isPolicyAllowedToSetPaperless(policyData);
  const isSetPaperlessAllowed =
    !isAnyActivePolicyNotAllowingPaperless && policyAllowedToSetPaperless;
  const isUnsetPaperlessAllowed = policyAllowedToUnsetPaperless && isPaperlessOn;

  const handlePaperlessLinkClick = useEvent(() => {
    isPaperlessOn
      ? modalPath.init(policyNumber, 'paperlessunenroll', MODAL_STEP.MODAL_LANDING)
      : modalPath.init(policyNumber, 'paperlessenroll', MODAL_STEP.MODAL_LANDING);
  });

  const contactUsLink = (
    <ContactUsLink
      className={classes.contactUsLinkText}
      trackingName='document_list_contact_us_button'
      trackingLabel='document_list_contact_continue'
    />
  );

  const policyAddress = getAddressString(getPolicyAddress(policyData));

  const isMobile = useIsMobile();
  const autoPolicies = isMobile ? (
    <Stack spacing={0.5}>
      {policyData?.vehicles?.map((vehicle, index) => (
        <div key={index}>{getVehicleDescription(vehicle)}</div>
      ))}
    </Stack>
  ) : (
    <Stack
      direction='row'
      className={classes.autos}
      divider={<Divider orientation='vertical' className={classes.divider} />}
    >
      {policyData?.vehicles?.map(getVehicleDescription)}
    </Stack>
  );

  // POI is not a part of document list, hence manually incrementing the doc count
  const newDocumentCount = isLegacy ? newDocuments.length : newDocuments.length + 1;

  return (
    <DashboardCard>
      <Stack spacing={{ xs: 4, md: 5 }}>
        <Stack direction='column' spacing={1}>
          <Stack direction='row' justifyContent='space-between'>
            <h1>Your {`${policyType?.toLowerCase() ?? ''}`} policy documents</h1>
            {(isSetPaperlessAllowed || isUnsetPaperlessAllowed) && !isErrorPreferences && (
              <Button
                className={classes.docDeliveryPrefTop}
                variant='iconTextMedium'
                startIcon
                icon={<IconMaterialDesignActionSettings />}
                onClick={handlePaperlessLinkClick}
                trackingLabel='document_delivery_preference'
                trackingName='document_delivery_preference_link'
                objectType='link'
              >
                Document delivery preferences
              </Button>
            )}
          </Stack>
          {isHomeProduct ? (
            <span className={classes.subHeader}>{policyAddress}</span>
          ) : (
            autoPolicies
          )}
        </Stack>

        {/* If the all Document API calls fail. */}
        {isError && documents?.documentDataList?.length === 0 && (
          <Alert withIcon type='error' withAction hideOnClose>
            We're sorry. We are unable to access documents at this time. Please {contactUsLink} or
            try again later.
          </Alert>
        )}
        {/* If one of the Document API call fails. */}
        {isError && documents?.documentDataList?.length !== 0 && (
          <Alert withIcon type='error' withAction hideOnClose>
            We're sorry. We can only access some of your documents at this time. If you wish to
            review any documents not listed, please {contactUsLink} or try again later.
          </Alert>
        )}
        {isLoading && <LoadingOverlay />}

        {!isLoading && (
          <Stack
            spacing={{ xs: 4, md: 4 }}
            direction={{ xs: 'column', md: 'row' }}
            className={classes.container}
          >
            <Stack spacing={1.5}>
              <h2>
                New documents <span className={classes.heading}>({newDocumentCount})</span>
              </h2>
              <DocumentTable
                classes={{
                  root: cx(
                    classes.docTable,
                    newDocuments.length > 0 && classes.docTableWithDocuments,
                  ),
                }}
                documentDataList={newDocuments}
                hideTableHeadings
                addPOIForNewDocumentSection
                policyNumber={policyNumber}
                addDivider
                idPostfix='-newDoc'
              />
            </Stack>

            <Stack spacing={1.5}>
              <h2>All documents</h2>
              <DocumentAccordionGroup
                allDocuments={allDocuments ?? []}
                isHomeProduct={isHomeProduct}
                isLegacy={isLegacy}
              />
            </Stack>
          </Stack>
        )}
        {(isSetPaperlessAllowed || isUnsetPaperlessAllowed) && !isErrorPreferences && (
          // once we have more than one item, will need wrapper to manage styling
          <Stack spacing={1.5}>
            {isMobile && <h2>Manage documents</h2>}
            <MobileMenuItem
              classes={{ root: classes.mobileMenuItem }}
              title='Document delivery preferences'
              icon={<IconMaterialDesignActionSettings />}
              onClick={handlePaperlessLinkClick}
              trackingLabel='document_delivery_preference'
              trackingName='document_delivery_preference_link'
              objectType='link'
            />
          </Stack>
        )}
        <ContactUsMicro />
        <PaperlessModal />
      </Stack>
    </DashboardCard>
  );
};
