// NOTE: this file is only used for partner that doesn't have proper EBS data generated yet
import type { GeneratedBrandData } from '../brand.types';

export const brand: GeneratedBrandData = {
  auto: {
    partnerCompanyName: '[autoPartnerCompanyName]',
    mainOperationHours: ['[autoMainOperationHours1]', '[autoMainOperationHours2]'],
    mainCustServPhoneNum: '[autoMainCustServPhoneNumber]',
    mainBrandNameShort: '[autoBrandName]',
    brandingType: '[autoBrandingType]',
    salesOperationHours: ['[autoSalesOperationHours1]', '[autoSalesOperationHours2]'],
    salesPhoneNum: '[autoSalesPhoneNumber]',
    salesChatHours: ['[autoSalesChatHours1]', '[autoSalesChatHours2]'],
    serviceChatHours: ['[autoServiceChatHours1]', '[autoServiceChatHours2]'],
    claimPhoneNum: '[claimsPhoneNumber]',
    glassClaimPhoneNum: '[glassClaimPhoneNum]',
    homeLockoutPhoneNum: '[homeLockoutPhoneNum]',
    inboundServiceEmail: '[autoInboundServiceEmail]',
    roadsideAssistancePhoneNum: '[roadsideAssistancePhoneNum]',
    serviceAddress: {
      addressLine1: '[autoServiceAddressLine1]',
      city: '[autoServiceCity]',
      state: '[autoServiceState]',
      zip: '[autoServiceZip]',
      type: '[autoServiceAddresType]',
      zipPlus4: '[autoServiceZipPlus4]',
    },
    paymentsAddress: {
      addressLine1: '[autoPaymentsAddressLine1]',
      city: '[autoPaymentsCity]',
      state: '[autoPaymentsState]',
      zip: '[autoPaymentsZip]',
      type: '[autoPaymentsAddresType]',
      zipPlus4: '[autoPaymentsZipPlus4]',
      attention: '[autoAttn]',
    },
    uwCompany: '[autoUnderwritingCompany]',
    uwCompanyCopyright: '[autoUnderwritingCompanyCopyright]',
  },
  home: {
    partnerCompanyName: '[homePartnerCompanyName]',
    mainOperationHours: ['[homeMainOperationHours1]', '[homeMainOperationHours2]'],
    mainCustServPhoneNum: '[homeMainCustServPhoneNumber]',
    mainBrandNameShort: '[homeBrandName]',
    brandingType: '[homeBrandingType]',
    salesOperationHours: ['[homeSalesOperationHours1]', '[homeSalesOperationHours2]'],
    salesPhoneNum: '[homeSalesPhoneNumber]',
    salesChatHours: ['[homeSalesChatHours1]', '[homeSalesChatHours2]'],
    serviceChatHours: ['[homeServiceChatHours1]', '[homeServiceChatHours2]'],
    servicingUrl: '[servicingUrl]',
    claimPhoneNum: '[claimsPhoneNumber]',
    glassClaimPhoneNum: '[glassClaimPhoneNum]',
    homeLockoutPhoneNum: '[homeLockoutPhoneNum]',
    inboundServiceEmail: '[homeInboundServiceEmail]',
    roadsideAssistancePhoneNum: '[roadsideAssistancePhoneNum]',
    serviceAddress: {
      addressLine1: '[homeServiceAddressLine1]',
      city: '[homeServiceCity]',
      state: '[homeServiceState]',
      zip: '[homeServiceZip]',
      type: '[homeServiceAddresType]',
      zipPlus4: '[homeServiceZipPlus4]',
    },
    paymentsAddress: {
      addressLine1: '[homePaymentsAddressLine1]',
      city: '[homePaymentsCity]',
      state: '[homePaymentsState]',
      zip: '[homePaymentsZip]',
      type: '[homePaymentsAddresType]',
      zipPlus4: '[homePaymentsZipPlus4]',
    },
    uwCompany: '[homeUnderwritingCompany]',
    uwCompanyCopyright: '[homeUnderwritingCompanyCopyright]',
  },
};
