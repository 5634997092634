import { Navigate, Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { userAuth } from '@ecp/utils/auth';

import { navState, PAGE_PATH } from '@ecp/features/servicing/shared/routing';

export const AuthenticatedUserRedirect: React.FC = () => {
  const { pathname } = useLocation();
  if (userAuth.isAuth)
    return (
      <Navigate
        to={PAGE_PATH.DASHBOARD_INDEX}
        replace
        state={pathname === PAGE_PATH.CREATE_ACCOUNT_CREATE_PASSWORD ? navState.from : null}
      />
    );
  else return <Outlet />;
};
