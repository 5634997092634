export type DocumentCategory = 'Policy' | 'Billing' | 'Renewal' | 'Other';

// HS policies
export const billingDocuments = ['NONPAY CANCEL', 'Invoice'];
export const policyDocuments = [
  'Additional Interests - Residence Premises',
  'Amendatory Endorsement - Pennsylvania',
  'Amendatory Endorsement - Pollution Exclusion',
  'AMENDMENT',
  'Animal Liability Exclusion Endorsement',
  'Conditions Applicable to Windstorm Or Hail Loss to Exterior Or Roof Surfacing',
  'Consumer Contacts',
  'Contents Broad Form - Pennsylvania (HO 37 04 0811)',
  'CW QEH FORM - HHI276',
  'Declarations Page',
  'Diminishing Deductible Credit',
  'Earthquake',
  'Earthquake - Virginia',
  'Equipment Breakdown Coverage',
  'Equipment Breakdown Coverage Endorsement',
  'Flood Coverage Disclosure Notice',
  'ImportantNoticeaboutDwellingCoverageLimits',
  'Interested Party Letter',
  'Limited Fungi, Wet or Dry Rot, or Bacteria Coverage',
  'Limited Water Back-Up and Sump Discharge or Overflow Coverage',
  'MenuofOptionalEndorsements',
  'NB Cover Letter - Older Version',
  'NB Cover Letter - Value Page',
  'NEW POLICY',
  'No Section II Coverage - Home Day Care Business',
  'Notice of Information Practices',
  'Ordinance or Law Increased Amount of Coverage',
  'Pennsylvania Notice',
  'Personal Injury',
  'Personal Injury Coverage (Aggregate Limit of Liability),', // There is a comma in the title
  'Personal Property Replacement Cost',
  'Personal Property Replacement Cost Loss Settlement',
  'Personal Property Replacement Cost Loss Settlement  - Pennsylvania',
  'Policy Change Cover Letter',
  'Policy Contract Pack',
  'Scheduled Personal Property Endorsement',
  'Secure Home Discount',
  'Service Line Coverage',
  'Service Line Coverage Endorsment',
  'Special Computer Coverage',
  'Special Form (HO 00 03 0511)',
  'Special Personal Property Coverage',
  'Special Provisions - Indiana',
  'Special Provisions - Pennsylvania',
  'Specified Additional Amount of Insurance for Coverage A - Dwelling',
  'Unit-Owners Form (HO 00 06 0491)',
  'Water Back Up Of Sewers Or Drains - Virginia',
  'Wind and Hail Losses to Exterior Dwelling Property',
  'Windstorm or Hail Fixed-Dollar Deductible',
  'Windstorm or Hail Percentage Deductible',
];
export const renewalDocuments = ['RENEWAL', 'RENEWAL EXPIRATION CONFIRMATION'];
export const otherDocuments = ['MenuofOptionalEndorsements - HO4 Apex', 'Property Loss Report'];

// Classic - Auto
export const classicAutoBillingDocuments = [
  'Bill-Credit/Debit Card Credit Notice ',
  'Bill-Credit/Debit Card Full Charge Notice',
  'Bill-Credit/Debit Card Monthly Charge Notice',
  'Bill-Direct Full Bill Notice',
  'Bill-Direct Full Reminder Notice',
  'Bill-Direct Monthly Bill Notice',
  'Bill-Outstanding Balance Notice',
  'Bill-Pre-Authorized Withdrawal Charge Notice',
  'Bill-Pre-Authorized Withdrawal Credit Notice',
  'Letter-Billing',
  'Letter-Nonpayment',
  'Letter-Pending Cancel Nonpayment',
];
export const classicAutoPolicyDocuments = [
  'Declaration',
  'Endorsement-Additional Insured Lessor',
  'Endorsement-Additional Personal Injury Protection Schedule',
  'Endorsement-Agreed Amount Coverage',
  'Endorsement-Customized Vans and Pickups',
  'Endorsement-Customizing Equipment',
  'Endorsement-Death and Disability',
  'Endorsement-Delivery Exclusion',
  'Endorsement-Driver Exclusion',
  'Endorsement-Excess Electronic Equipment',
  'Endorsement-Extended Transportation Expenses',
  'Endorsement-Glass Deductible',
  'Endorsement-Government Business',
  'Endorsement-Government Business-DC',
  'Endorsement-Government Business-Federal',
  'Endorsement-Government Business-VA',
  'Endorsement-Loss Payable',
  'Endorsement-Medical Expense',
  'Endorsement-Miscellaneous Type Vehicle',
  'Endorsement-Named Driver Exclusion',
  'Endorsement-New Car Replacement/Gap',
  'Endorsement-Optonal Basic Economic Loss',
  'Endorsement-Personal Injury Protection',
  'Endorsement-Personal Injury Protection Coverage',
  'Endorsement-Personal Injury Protection Increased',
  'Endorsement-Personal Injury Protection Schedule',
  'Endorsement-Rental Reimbursement',
  'Endorsement-Rental Vehicle',
  'Endorsement-Supplementary Death Benefit',
  'Endorsement-Tort',
  'Endorsement-Towing',
  'Endorsement-Towing and Labor',
  'Endorsement-Trailer, Camper, Mobile Home',
  'Endorsement-Transportation Expense',
  'Endorsement-Trip Interruption',
  'Endorsement-Uninsured Motorist',
  'Endorsement-Uninsured Motorists',
  'Endorsement-Uninsured Motorists Property Damage',
  'Endorsement-Uninsured/Underinsured Motorist Split Limits',
  'Endorsement-Vehicle Sharing Exclsuion',
  'Endorsement-Waiver of Deductible',
  'Endorsement-Wind/Hail Deductible',
  'Identification Card',
  'Policy Book-Amendment',
  'Policy Book',
];
export const classicAutoRenewalDocuments = [
  'Cover Letter-Amended/Revised Renewal',
  'Cover Letter-Amended/Revised Renewal-URI Survey',
  'Cover Letter-Renewal',
  'Cover Letter-Renewal Costco',
  'Letter-Mileage Verification Pre Renewal',
];
export const classicAutoOtherDocuments = [
  'Application-Agreement',
  'Application-N/A',
  'Cover Letter-New Business',
  'Cover Letter-Quote',
  'Cover Letter-Reinstatement',
  'Form-Available Coverage',
  'Form-Bodily Injury ',
  'Form-Certified Mechanic Statement',
  'Form-Community Service Statement',
  'Form-Credit Review Request',
  'Form-Delaware Motorists Protection Act ',
  'Form-Driver Exclusion ',
  'Form-Driver Exclusion Acknowledgement',
  'Form-Good Student',
  'Form-Medical Expense',
  'Form-Mileage Verification',
  'Form-Mileage Verification Pre Renewal',
  'Form-Missing Information',
  'Form-No Fault Collision',
  'Form-No Fault Rejection',
  'Form-Personal Injury Protection',
  'Form-Renewal Form',
  'Form-Spousal Liability Coverage',
  'Form-Third Party Designation',
  'Form-Third Party Designee',
  'Form-Tort',
  'Form-Underinsured Motorist Reject ',
  'Form-Underinsured Motorist Select',
  'Form-Uninsured and/or Underinsured Motorist',
  'Form-Uninsured Motorist',
  'Form-Uninsured Motorist Nonstacked',
  'Form-Uninsured Motorist Property Damage',
  'Form-Uninsured Motorist Reject',
  'Form-Uninsured Motorist Select',
  'Form-Uninsured/Underinsured Motorist',
  'Form-Work Loss',
  'Form-Optional Basic Economic Loss',
  'Information-Accident Prevention Course Discount',
  'Information-Application Acknowledgement',
  'Information-Application Instructions',
  'Information-Application Instructions and Payment Option Disclosures',
  'Information-Auto Repair Consumer Bills of Rights',
  'Information-Bodily Injury Limits',
  'Information-Buyers Guide',
  'Information-Collision Coverage Options',
  'Information-Consumer Bill of Rights',
  'Information-Consumer Contacts',
  'Information-Consumer Information',
  'Information-Consumer Right to Information',
  'Information-Consumer Rights and Responsibilities',
  'Information-Coverage Options',
  'Information-Coverage Summary',
  'Information-Credit Report',
  'Information-Credit Use Disclosure and Consumer Bill of Rights',
  'Information-Delaware Motorists Protection Act',
  'Information-Discounts',
  'Information-Discounts and Surcharges',
  'Information-Domestic Violence',
  'Information-Electronic Consent',
  'Information-Eligibility Statement',
  'Information-Expenses for Medical Services Coverage',
  'Information-Extended Collision Coverage',
  'Information-Extraordinary Life Circumstances',
  'Information-Fair Credit Reporting Act Notice',
  'Information-Fraud',
  'Information-Identification Card',
  'Information-Important Information About Your Insurance Coverage',
  'Information-Information-Application Instructions and Payment Option Disclosures',
  'Information-Inspection',
  'Information-Instructions and Disclosures',
  'Information-Instuctions for Enclosed Form',
  'Information-Insurance Credit Score',
  'Information-Mass Marketing Disclosure',
  'Information-Mileage Verification',
  'Information-Minimum Coverage',
  'Information-Multi-Tier Rating',
  'Information-Notice of Info Practices',
  'Information-Notice Section of Important Provisions',
  'Information-Operating a Motor Vehicle Under the Influence of Alcohol or Drugs ',
  'Information-Personal Injury Protection Claims',
  'Information-Policy Changes',
  'Information-Policyholder Insolvency Rights',
  'Information-Premium Increase Notice',
  'Information-Privacy Statement',
  'Information-Rate Change',
  'Information-Rating Information',
  'Information-Rating Mileage Disclosure',
  'Information-Renewal Premium',
  'Information-Spousal Liability Coverage',
  'Information-Supplementary Uninsured/Underinsured Motorist',
  'Information-Surcharge',
  'Information-Surcharge Disclosure ',
  'Information-Tips to Avoid Loss',
  'Information-Uninsured Motorist Coverage',
  'Information-Uninsured/Underinsured Motorist',
  'Information-Uninsured/Underinsured Motorists',
  'Information-Vehicle Sharing Exclusion',
  'Information-Vehicle Theft',
  'Letter-Cancellation',
  'Letter-Cancellation Rescission',
  'Letter-Decline',
  'Letter-Driver Review',
  'Letter-Inspection Notice',
  'Letter-Mileage Verification',
  'Letter-Pending Cancel',
  'Letter-Reduction',
  'Quote-N/A',
];

// Classic - Home
export const classicHomeBillingDocuments = [
  'Bill-Credit/Debit Card Credit Notice',
  'Bill-Credit/Debit Card Full Charge Notice',
  'Bill-Credit/Debit Card Monthly Charge Notice',
  'Bill-Direct Full Bill Notice',
  'Bill-Direct Full Reminder Notice',
  'Mortgage Reminder Notice-Mortgagee Copy',
  'Bill-Direct Monthly Bill Notice',
  'Bill-Mortgage Bill Notice',
  'Bill-Outstanding Balance Notice',
  'Bill-Pre-Authorized Withdrawal Charge Notice',
  'Bill-Pre-Authorized Withdrawal Credit Notice',
  'Letter-Billing',
  'Letter-Nonpayment',
  'Letter-Pending Cancel Nonpayment',
];
export const classicHomePolicyDocuments = [
  'Declaration',
  'Endorsement-Actual Cash Value Loss Settlement',
  'Endorsement-Additional Interests',
  'Endorsement-Additional Residence Rented to Others',
  'Endorsement-Adventure Package',
  'Endorsement-Aircraft Liability Exclusion',
  'Endorsement-Aircraft Personal Injury Exclusion',
  'Endorsement-Builders Risk',
  'Endorsement-Business Pursuits',
  'Endorsement-Earthquake',
  'Endorsement-Extended Dwelling',
  'Endorsement-Fortified Roof',
  'Endorsement-Home Business',
  'Endorsement-Home Day Care',
  'Endorsement-Home Media',
  'Endorsement-Home-Sharing',
  'Endorsement-Hurricane Deductible',
  'Endorsement-Identity Protection',
  'Endorsement-Increased Limits-Business Property',
  'Endorsement-Increased Special Limits',
  'Endorsement-Loss Assessment',
  'Endorsement-Matching of Undamaged Siding/Roofing',
  'Endorsement-Mine Subsidence',
  'Endorsement-Office, Professional, Private School',
  'Endorsement-Other Structures',
  'Endorsement-Other Structures Rented to Others',
  'Endorsement-Permitted Incidental Occupancies-Residence Premises ',
  'Endorsement-Personal Computer',
  'Endorsement-Personal Injury',
  'Endorsement-Personal Property Replacement Cost',
  'Endorsement-Recreational Vehicle',
  'Endorsement-Recreational Vehicle 1',
  'Endorsement-Recreational Vehicle Information',
  'Endorsement-Refrigerated Property',
  'Endorsement-Scheduled Personal Property',
  'Endorsement-Screened Enclosure and Carport ',
  'Endorsement-Sinkhole',
  'Endorsement-Treasure Package',
  'Endorsement-Waterbed Liability',
  'Endorsement-Watercraft',
  'Endorsement-Watercraft 1',
  'Endorsement-Watercraft Information',
  'Endorsement-Watercraft Schedule',
  'Endorsement-Wildfire Defense Services',
  'Endorsement-Wind/Hail Deductible',
  'Endorsement-Wind/Hail Exclusion',
  'Endorsement-Windstorm Exterior Paint/Waterproofing Exclusion',
  'Endorsement',
  'Policy Book-Amendment',
  'Policy Book',
];
export const classicHomeRenewalDocuments = [
  'Cover Letter-Amended/Revised Renewal',
  'Cover Letter-Renewal',
  'Cover Letter-Renewal Costco',
  'Letter-Renewal',
];
export const classicHomeOtherDocuments = [
  'Application-Agreement',
  'Application',
  'Cover Letter-New Business',
  'Cover Letter-Quote',
  'Cover Letter-Reinstate',
  'Cover Letter-Water Backup and Sump Overflow',
  'Form-Community Service Survey',
  'Form-Credit Review request',
  'Form-Earthquake',
  'Form-Extended Dwelling',
  'Form-Law and Ordinance',
  'Form-Mine Subsidence',
  'Form-Protective Device Certification',
  'Form-Residential Property Insurance Disclosure Acknowledgement',
  'Form-Roof Condition Certification',
  'Form-Third Party Designation',
  'Form-Water Backup and Sump Overflow',
  'Form-Wildfire Mitigation Credit',
  'Form-Wind/Hail Exclusion',
  'Information-Application Acknowledgement',
  'Information-Application Instructions',
  'Information-Assessments',
  'Information-Bill of Rights',
  'Information-Billing',
  'Information-Consumer Bill of Rights',
  'Information-Consumer Contacts',
  'Information-Consumer Information',
  'Information-Consumer Information Brochure ',
  'Information-Coverage Options',
  'Information-Coverage Summary',
  'Information-Discounts',
  'Information-Domestic Violence',
  'Information-Dwelling Value and Home Inspection',
  'Information-Earthquake',
  'Information-Earthquake and Flood Exclusion',
  'Information-Electronic Consent',
  'Information-Eligibility',
  'Information-Excluded Perils',
  'Information-Fair Credit Reporting Act Notice',
  'Information-Flood',
  'Information-Flood and/or Water Backup',
  'Information-Fortified',
  'Information-Fraud',
  'Information-Hurricane Deductible',
  'Information-Important Information About Your Insurance Coverage',
  'Information-Insurance Credit Score',
  'Information-Law and Ordinance',
  'Information-Loss History',
  'Information-Mandated Consumer Information',
  'Information-Mass Marketing',
  'Information-Matching of Undamaged Siding/Roofing',
  'Information-Mine Subsidence',
  'Information-Notice of Change in Policy Terms',
  'Information-Notice Section of Important Provisions',
  'Information-Optional Coverages',
  'Information-Outline of Coverage',
  'Information-Policy Changes',
  'Information-Policy Finalization and Payment Disclosures',
  'Information-Policyholder Insolvency Rights',
  'Information-Privacy Statement',
  'Information-Proof of Insurance',
  'Information-Rate Change',
  'Information-Reconstruction Cost Estimate ',
  'Information-Residential Property Insurance Disclosure  ',
  'Information-Scheduled Personal Property',
  'Information-Territory Listing for Wind/Hail Premium ',
  'Information-Third Party Designation',
  'Information-Wildfire Defense Services',
  'Information-Wind/Hail Deductible',
  'Information-Wind/Hail Exclusion',
  'Letter-Cancellation',
  'Letter-Cancellation Rescission',
  'Letter-Conversion',
  'Letter-Progressive Policy Validation',
  'Quote',
];
