import { emptyArray } from '@ecp/utils/common';

import type { UiPolicy } from '@ecp/features/servicing/shared/types';

import { getProductLineFromPolicyResponse } from './getProductLineFromPolicyResponse';

/**
 * Check if there is another active policy for same product.
 * Used when policyToCheck is cancelled.
 */
export const hasAnotherActivePolicy = (
  policyToCheck?: UiPolicy,
  allPolicies: UiPolicy[] = emptyArray as unknown as UiPolicy[],
): boolean => {
  return allPolicies.some(
    (policyData) =>
      policyToCheck?.policy.policyNumber !== policyData.policy.policyNumber &&
      getProductLineFromPolicyResponse(policyToCheck) ===
        getProductLineFromPolicyResponse(policyData) &&
      !policyData.isInactive,
  );
};
