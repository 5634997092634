import * as yup from 'yup';

import { DateConstants } from '@ecp/utils/date';
import { getAge, isDate, isFutureOrTodayDate } from '@ecp/utils/date';
import type { YupSchemaMap } from '@ecp/utils/form';

export interface DateOfBirthSchema {
  dateOfBirth?: string;
}

export const getDateOfBirthSchema: YupSchemaMap<DateOfBirthSchema> = {
  dateOfBirth: yup
    .string()
    .nullable()
    .test(
      'validDate',
      `Date of Birth invalid format, please provide as ${DateConstants.DISPLAY_FORMAT}`,
      (value: string | undefined | null) =>
        value ? isDate(value, DateConstants.ANSWERS_FORMAT) : true,
    )
    .test('futureDate', 'Date of Birth cannot be in future', (value) =>
      value ? !isFutureOrTodayDate(value) : true,
    )
    .test('accurateDate', 'Date of Birth may not be accurate', (value) => {
      if (value && !isFutureOrTodayDate(value)) {
        const age = getAge(value) || 0;

        return age >= 14 && age <= 120;
      } else return true;
    })
    .required(`Date of Birth required, please provide ${DateConstants.DISPLAY_FORMAT}`),
};
