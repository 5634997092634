import { isTruthy } from '@ecp/utils/common';

import type { UiPolicy } from '@ecp/features/servicing/shared/types';

import type { GetPaymentInfoReturn } from '../payment';
import { getProductLineFromPolicyResponse } from './getProductLineFromPolicyResponse';
import { isBookRolledPolicy } from './isBookRolledPolicy';
import { isInPendingCancelStatus } from './isInPendingCancelStatus';

export const compareProductLine = (a: UiPolicy | undefined, b: UiPolicy | undefined): number => {
  const aProductLine = getProductLineFromPolicyResponse(a);
  const bProductLine = getProductLineFromPolicyResponse(b);
  if (aProductLine && bProductLine) {
    return aProductLine.localeCompare(bProductLine);
  } else return 0;
};

export const sortPolicies = (paymentInfos: GetPaymentInfoReturn[] | undefined): UiPolicy[] => {
  return (
    (paymentInfos ?? [])
      // order of sort functions matters here
      // first, sort by eff date (newest first)
      .sort((a, b) =>
        (b.policyData?.policy.periodStartDate ?? '').localeCompare(
          a.policyData?.policy.periodStartDate ?? '',
        ),
      )
      // second, sort by product
      .sort((a, b) => compareProductLine(a.policyData, b.policyData))
      // Sort by BookRolled status - bookrolled policies are going in the first place but after pending cancel
      .sort((a, b) => {
        const isBookRolledA = isBookRolledPolicy(a.policyData);
        const isBookRolledB = isBookRolledPolicy(b.policyData);
        if (isBookRolledA === isBookRolledB) return 0;

        return isBookRolledB ? 1 : -1;
      })
      // forth, sort by pending cancel - all pending cancel policies go first - auto still in front of home
      .sort((a, b) => {
        const pendingCancelA = isInPendingCancelStatus({
          billingResponse: a.billingAccount,
          policySource: a.policyData?.policy.sourceSystemName,
          productLine: getProductLineFromPolicyResponse(a.policyData),
        });

        const pendingCancelB = isInPendingCancelStatus({
          billingResponse: b.billingAccount,
          policySource: b.policyData?.policy.sourceSystemName,
          productLine: getProductLineFromPolicyResponse(b.policyData),
        });

        if (pendingCancelA === pendingCancelB) return 0;

        return pendingCancelB ? 1 : -1;
      })
      .map((pi) => pi.policyData)
      .filter(isTruthy)
  );
};
