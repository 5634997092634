import { memo } from 'react';

import { flagValues } from '@ecp/utils/flags';

import { getAddressByRefId, getVehicleDescription } from '@ecp/features/servicing/shared/state';
import type { UiPolicy } from '@ecp/features/servicing/shared/types';
import {
  buildCoverageItems,
  CarrierAutoPolicyMetadata,
  CarrierVehicleCoverageMetadata,
  CarrierVehicleDeductibleMetadata,
} from '@ecp/features/shared/coverages';

import { buildFields, getMetadataProductKey } from '../../util/coveragesUtil';
import { CoveragesSubSection } from '../CoveragesSubSection';
import {
  autoPolicyCoveragesGroupList,
  autoPolicyCoveragesMap,
  groupAutoPolicyCoveragesMap,
  groupVehicleCoveragesMap,
  vehicleCoveragesGroupList,
  vehicleCoveragesMap,
  vehicleDeductiblesOrder,
} from './autoCoverageMap';
import { CoveragesSectionCard } from './CoveragesSectionCard';
import { groupAndSortCoverages, sortDeductibles } from './utils';

interface Props {
  policyData: UiPolicy;
}

export const AutoCoverages: React.FC<Props> = memo((props) => {
  if (!flagValues.CLASSIC_COVERAGES_AUTO) return null;
  const { policyData } = props;

  const productKey = getMetadataProductKey(policyData);

  // policy coverages
  const policyState = getAddressByRefId(policyData, policyData.policy.primaryAddressRefId)?.state;
  const policyMetaData = CarrierAutoPolicyMetadata[productKey];

  const coveragesDataPolicy = policyData.coverageOptions.at(0)?.policyCoverages;

  const policyCoverageFields = buildFields(
    coveragesDataPolicy ?? {},
    autoPolicyCoveragesMap,
    false,
    true,
    groupAutoPolicyCoveragesMap,
  );
  const policyCoverages = buildCoverageItems(policyMetaData, policyCoverageFields, policyState);
  const groupedCoverages = groupAndSortCoverages(policyCoverages, groupAutoPolicyCoveragesMap);

  // vehicle coverages
  const vehiclesCoveragesData = policyData.coverageOptions.at(0)?.vehicles;
  const vehicleDeductibleMetadata = CarrierVehicleDeductibleMetadata[productKey];
  const vehicleCoverageMetadata = CarrierVehicleCoverageMetadata[productKey];

  return (
    <>
      <CoveragesSectionCard
        title='For everyone on your policy'
        children={autoPolicyCoveragesGroupList.map((group) =>
          groupedCoverages?.[group] ? (
            <CoveragesSubSection
              key={group}
              coverageItems={groupedCoverages[group]}
              title={group}
              showTitleInScrollspy={false}
            />
          ) : null,
        )}
      />

      {(policyData.vehicles ?? []).map((vehicle) => {
        const vehicleState = getAddressByRefId(policyData, vehicle.garagingAddressRefId)?.state;
        const vehicleCoveragesData =
          vehiclesCoveragesData?.find(
            (vehicleCoverages) => vehicle.vehicleId === vehicleCoverages.vehicleId,
          )?.vehicleCoverages ?? {};
        const deductibleFields = buildFields(vehicleCoveragesData, vehicleCoveragesMap, true, true);
        const limitFields = buildFields(
          vehicleCoveragesData,
          vehicleCoveragesMap,
          false,
          true,
          groupVehicleCoveragesMap,
        );
        const vehicleDeductibles = buildCoverageItems(
          vehicleDeductibleMetadata,
          deductibleFields,
          vehicleState,
        );
        const vehicleCoveragesLimits = buildCoverageItems(
          vehicleCoverageMetadata,
          limitFields,
          vehicleState,
        );

        if (!vehicleDeductibles.length && !vehicleCoveragesLimits.length) return null;

        const sortedDeductibles = sortDeductibles(vehicleDeductibles, vehicleDeductiblesOrder);

        const groupedVehicleCoverages = groupAndSortCoverages(
          vehicleCoveragesLimits,
          groupVehicleCoveragesMap,
        );
        const fragmentTitle = `For your ${getVehicleDescription(vehicle)}`;

        return (
          <CoveragesSectionCard
            title={fragmentTitle}
            key={vehicle.vehicleId}
            children={
              <>
                {!!sortedDeductibles.length && (
                  <CoveragesSubSection
                    key={`${vehicle.vehicleId}-deductibles`}
                    title='Deductibles'
                    coverageItems={sortedDeductibles}
                    vehicleId={vehicle.vehicleId}
                    showTitleInScrollspy={false}
                  />
                )}
                {vehicleCoveragesGroupList.map((group) =>
                  groupedVehicleCoverages?.[group] ? (
                    <CoveragesSubSection
                      key={`${vehicle.vehicleId}-${group}`}
                      coverageItems={groupedVehicleCoverages[group]}
                      title={group}
                      vehicleId={vehicle.vehicleId}
                      showTitleInScrollspy={false}
                    />
                  ) : null,
                )}
              </>
            }
          />
        );
      })}
    </>
  );
});
