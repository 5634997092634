import { isTruthy } from '@ecp/utils/common';

import type { UiPolicy } from '@ecp/features/servicing/shared/types';

import type { GetPaymentInfoReturn } from '../payment';

export const sortCanceledPolicies = (
  paymentInfos: GetPaymentInfoReturn[] | undefined,
): UiPolicy[] => {
  return (
    (paymentInfos ?? [])
      // sort by eff end date
      .sort((a, b) =>
        (b.policyData?.policy.periodEndDate ?? '').localeCompare(
          a.policyData?.policy.periodEndDate ?? '',
        ),
      )
      .map((pi) => pi.policyData)
      .filter(isTruthy)
  );
};
