/* eslint-disable @typescript-eslint/no-explicit-any */

export interface Events<T extends string, Listener extends (...args: any[]) => any> {
  /**
   * Appends an event listener for events whose type attribute value is type.
   * The callback argument sets the callback that will be invoked when the event is dispatched.
   * @param type - Event type.
   * @param listener - Listener to be notified on the event of specified type.
   */
  addEventListener: (type: T, listener: Listener) => void;
  /**
   * Removes the event listener in target's event listener list with the same type and callback.
   * @param type - Event type.
   * @param listener - Listener to be removed from the list of specified event type.
   */
  removeEventListener: (type: T, listener: Listener) => void;
  /**
   * Notifies all event listeners from the list of specified event type.
   * @param type Event type.
   */
  notifyEventListeners: (type: T) => void;
}

/**
 * Extend a class or a utility with custom events functionality.
 *
 * @param types Event types.
 */
export const registerEvents = <T extends string, Listener extends (...args: any[]) => any>(
  ...types: T[]
): Events<T, Listener> => {
  /** Event listener list to be notified on the specific event. */
  const listeners = {} as Record<T, Set<Listener>>;
  types.forEach((type) => (listeners[type] = new Set()));

  return {
    addEventListener: (type, listener) => {
      if (type in listeners) listeners[type].add(listener);
    },
    removeEventListener: (type, listener) => {
      if (type in listeners) listeners[type].delete(listener);
    },
    notifyEventListeners: (type) => {
      if (type in listeners) listeners[type].forEach((listener) => listener());
    },
  };
};
