import { useCallback, useMemo, useState } from 'react';

import { datadogLog } from '@ecp/utils/logger';
import { scrollToTop } from '@ecp/utils/web';

import { env } from '@ecp/env';
import type {
  ClaimsCallingContext,
  ClaimsCallingSubContext,
} from '@ecp/features/servicing/shared/state';
import { useResetClaimsContext } from '@ecp/features/servicing/shared/state';

enum UserMessageName {
  DIGITAL_CLAIM_V2_LAUNCH_SAFELITE = 'DIGITAL_CLAIM_V2_LAUNCH_SAFELITE',
  DIGITAL_CLAIM_V2_RELOAD_BUTTON_CLICK = 'DIGITAL_CLAIM_V2_RELOAD_BUTTON_CLICK',
  DIGITAL_CLAIM_V2_SCROLL = 'DIGITAL_CLAIM_V2_SCROLL',
}

export enum ErrorMessageName {
  DIGITAL_CLAIM_V2_ALL_CLAIMS_RETRIEVAL_ERROR = 'DIGITAL_CLAIM_V2_ALL_CLAIMS_RETRIEVAL_ERROR',
  DIGITAL_CLAIM_V2_AUTO_CLAIM_SUBMIT_TIMEOUT_ERROR = 'DIGITAL_CLAIM_V2_AUTO_CLAIM_SUBMIT_TIMEOUT_ERROR',
  DIGITAL_CLAIM_V2_AUTO_ELIGIBLE_SERVICES_ERROR = 'DIGITAL_CLAIM_V2_AUTO_ELIGIBLE_SERVICES_ERROR',
  DIGITAL_CLAIM_V2_CLAIM_DETAIL_FAIL_ERROR = 'DIGITAL_CLAIM_V2_CLAIM_DETAIL_FAIL_ERROR',
  DIGITAL_CLAIM_V2_CONVERSE_BACK_ERROR = 'DIGITAL_CLAIM_V2_CONVERSE_BACK_ERROR',
  DIGITAL_CLAIM_V2_CONVERSE_EDIT_ERROR = 'DIGITAL_CLAIM_V2_CONVERSE_EDIT_ERROR',
  DIGITAL_CLAIM_V2_CONVERSE_NEXT_ERROR = 'DIGITAL_CLAIM_V2_CONVERSE_NEXT_ERROR',
  DIGITAL_CLAIM_V2_CONVERSE_SUBMIT_ERROR = 'DIGITAL_CLAIM_V2_CONVERSE_SUBMIT_ERROR',
  DIGITAL_CLAIM_V2_DRAFT_CLAIM_CREATE_ERROR = 'DIGITAL_CLAIM_V2_DRAFT_CLAIM_CREATE_ERROR',
  DIGITAL_CLAIM_V2_DRAFT_CLAIM_DELETE_ERROR = 'DIGITAL_CLAIM_V2_DRAFT_CLAIM_DELETE_ERROR',
  DIGITAL_CLAIM_V2_POLICY_RETRIEVAL_ERROR = 'DIGITAL_CLAIM_V2_POLICY_RETRIEVAL_ERROR',
  DIGITAL_CLAIM_V2_SAFE_LITE_PAYLOAD_FAIL_ERROR = 'DIGITAL_CLAIM_V2_SAFE_LITE_PAYLOAD_FAIL_ERROR',
  DIGITAL_CLAIM_V2_SESSION_TIMEOUT_ERROR = 'DIGITAL_CLAIM_V2_SESSION_TIMEOUT_ERROR',
  DIGITAL_CLAIM_V2_USER_SESSION_VALIDATE_FAIL_ERROR = 'DIGITAL_CLAIM_V2_USER_SESSION_VALIDATE_FAIL_ERROR',
}

export const messageNames = { ...UserMessageName, ...ErrorMessageName };
export type MessageName = keyof typeof messageNames;

interface MiniContext {
  claimNumber?: string;
  context: ClaimsCallingContext;
  subContext: ClaimsCallingSubContext;
}

export interface MessageData {
  messageName: MessageName;
  claimNumber?: string;
  htmlContent?: string;
}
export interface UseOnMessageReturn extends MiniContext {
  launchSafelite: () => Promise<void>;
  loaded: boolean;
  onMessage: (event: Pick<MessageEvent<MessageData | string>, 'data' | 'origin'>) => Promise<void>;
  setLoaded: (loaded: boolean) => void;
  shouldLaunchSafelite: boolean;
  isIframeLoadError: boolean;
}

const logError = (data: MessageData): void =>
  datadogLog({
    logType: data.messageName === 'DIGITAL_CLAIM_V2_SESSION_TIMEOUT_ERROR' ? 'info' : 'error',
    message: `Claims failure - ${JSON.stringify(data)}`,
    context: {
      logOrigin: 'libs/features/servicing/claims/src/components/ClaimsIframe/useOnMessage.ts',
      functionOrigin: 'handleMessage',
    },
  });

export const useOnMessage = (): UseOnMessageReturn => {
  const [isIframeLoadError, setIsIframeLoadError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [safeliteHtml, setSafeliteHtml] = useState<string | undefined>();
  const { reset: resetClaimsContext } = useResetClaimsContext();

  const reloadIframe = useCallback(async () => {
    scrollToTop();
    await resetClaimsContext();
    setLoaded(false);
    setSafeliteHtml(undefined);
  }, [resetClaimsContext]);

  const launchSafelite = useCallback(
    async (htmlProp?: string) => {
      const html = safeliteHtml ?? htmlProp;
      if (!html) {
        throw Error('Cannot launch Safelite without htmlContent passed from iframe');
      }
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      const newWindow = window.open();
      newWindow?.document.write(doc.body.innerHTML);
      newWindow?.document.forms[0].submit();
      newWindow?.document.close();
      await reloadIframe();
    },
    [reloadIframe, safeliteHtml],
  );

  const onMessage = useCallback(
    async (event: Parameters<UseOnMessageReturn['onMessage']>[0]) => {
      const { data, origin } = event;
      if (typeof data === 'string') return;
      const { htmlContent, messageName } = data;

      if (origin.endsWith('amfam.com')) {
        env.static.nodeEnv === 'development' &&
          console.log('claims postMessage - event.data', JSON.stringify(event.data, null, 2));

        if (Object.values(ErrorMessageName).includes(messageName)) {
          logError(data);
          if (messageName === 'DIGITAL_CLAIM_V2_USER_SESSION_VALIDATE_FAIL_ERROR')
            setIsIframeLoadError(true);
        } else {
          switch (messageName) {
            case 'DIGITAL_CLAIM_V2_SCROLL':
              scrollToTop();
              break;
            case 'DIGITAL_CLAIM_V2_LAUNCH_SAFELITE':
              setSafeliteHtml(htmlContent);
              break;
            case 'DIGITAL_CLAIM_V2_RELOAD_BUTTON_CLICK':
              await reloadIframe();
              break;
            default:
              console.warn('unknown event - event.data', JSON.stringify(event.data, null, 2));
          }
        }
      }
    },
    [reloadIframe],
  );

  return useMemo(
    () => ({
      context: 'POST_FNOL_V2',
      isIframeLoadError,
      launchSafelite,
      loaded,
      onMessage,
      setLoaded,
      shouldLaunchSafelite: !!safeliteHtml,
      subContext: 'claims-overview',
    }),
    [launchSafelite, loaded, onMessage, safeliteHtml, isIframeLoadError],
  );
};
