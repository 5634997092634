import type { Theme } from '@mui/material';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { CSSObject } from 'tss-react';

import { env } from '@ecp/env';

import type { AppVars } from '../appVars';
import type { Breakpoints } from '../breakpoints';
import type { Palette } from '../palette';
import type { Typography } from '../typography';
import type { MixinsOverrides } from './mixins.types';

export const createMixins = (
  breakpoints: Omit<Breakpoints, 'width'>,
  palette: Palette,
  typography: Typography,
  appVars: AppVars,
  zIndex: Theme['zIndex'],
): MixinsOverrides => {
  const getColumnMixin = (maxWidth: string): CSSObject => ({
    '& [class*="MuiFormControl-root"]': {
      [breakpoints.up('md')]: { maxWidth: `${maxWidth} !important` },
    },
  });

  /** Helper function to make sure there is no margin on the last row of cards */
  const lastRowMargin = (cardCount: number, columnCount: number): CSSObject => {
    const lastRowCount = cardCount % columnCount;
    const result: CSSObject = {};

    for (let i = 1; i <= columnCount; i++) {
      if (lastRowCount >= i || lastRowCount === 0) {
        result[`&:nth-last-child(${i})`] = {
          marginBottom: 0,
          marginRight: i === 1 ? 0 : 12,
        };
      }
    }

    if (lastRowCount === 0) {
      result[`&:nth-last-child(${columnCount})`] = {
        marginBottom: 0,
      };
    }

    result[`&:nth-child(${columnCount}n)`] = {
      marginRight: 0,
    };

    return result;
  };

  const cardSelectionGroup: MixinsOverrides['cardSelectionGroup'] = (
    root,
    card,
    cardSmall,
    cardMedium,
    cardCount,
  ) => {
    return {
      '& > *': {
        [`.${root}.${card} &`]: {
          // Show more/less button declares its own margin
          '&:not([aria-expanded])': {
            margin: 0,
            [breakpoints.up('md')]: {
              marginRight: 12,
            },
          },
          [`.${cardSmall}&`]: {
            marginBottom: 12,
            [breakpoints.between('md', 'xl')]: lastRowMargin(cardCount, 4),
            [breakpoints.up('xl')]: lastRowMargin(cardCount, 5),
          },
          [`.${cardMedium}&`]: {
            marginBottom: 12,
            [breakpoints.up('md')]: lastRowMargin(cardCount, 4),
          },
          [breakpoints.down('md')]: {
            '&:nth-last-child(1)': {
              marginBottom: 0,
            },
          },
        },
      },
    };
  };

  const formAll: MixinsOverrides['formAll'] = {
    root: {
      marginTop: 40,
      padding: '0 30px',
      [breakpoints.down('md')]: {
        padding: '0 15px',
      },
    },
    columnLeft: getColumnMixin('calc(100% - 15px)'),
    columnLeftWithMiddle: getColumnMixin('calc(100% - 20px)'),
    columnMiddle: {
      ...getColumnMixin('calc(100% - 20px)'),
      textAlign: 'center',
    },
    columnRightWithMiddle: {
      ...getColumnMixin('calc(100% - 20px)'),
      textAlign: 'right',
    },
    columnRight: {
      ...getColumnMixin('calc(100% - 15px)'),
      textAlign: 'right',
    },
    next: {
      marginTop: 20,
      [breakpoints.down('md')]: {
        width: '100%',
      },
    },
    underlinedLink: {
      color: palette.text.link,
      textDecoration: 'underline',
      cursor: 'pointer',
      '&:hover': {
        color: palette.text.hover,
        textDecoration: 'underline',
      },
    },
  };

  const tableBody = {
    '& tr td': {
      ...typography.body1,
      borderRadius: 8,
      padding: 10,
    },
    '& tr td:not(:first-child)': {
      borderLeft: `1px solid ${palette.other.border}`,
    },
    '& tr:not(:last-child) td': {
      borderBottom: `1px solid ${palette.other.border}`,
    },
    '& tr:last-child': {
      borderBottom: 0,
    },
  };

  const withRightSidebar: MixinsOverrides['withRightSidebar'] = {
    contentContainer: {
      display: 'grid',
      ...(env.static.isAgent
        ? {
            [breakpoints.up('lg')]: {
              gridTemplateColumns: `auto ${appVars.baseSidebarContainerWidth}px`,
            },
            [breakpoints.up('xl')]: {
              gridTemplateColumns: `auto ${appVars.maxSidebarContainerWidth}px`,
            },
          }
        : {
            [breakpoints.up('lg')]: {
              gridTemplateColumns: `${appVars.baseContentContainerWidth}px ${appVars.baseSidebarContainerWidth}px`,
            },
            [breakpoints.up('xl')]: {
              gridTemplateColumns: `${appVars.maxContentContainerWidth}px ${appVars.maxSidebarContainerWidth}px`,
            },
          }),
    },
    formContainer: {
      minWidth: 230,
      paddingRight: 30,
      [breakpoints.down('md')]: {
        padding: '0px 15px',
      },
      [breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    sidebarContainer: {
      position: 'fixed', // Keep fixed postion as a fallback for unsupported sticky postion
      zIndex: 1211, // Make the sidebar cover the footer
      width: appVars.baseSidebarContainerWidth,
      left: `calc(50% + (${appVars.baseContentContainerWidth}px + ${appVars.baseSidebarContainerWidth}px) / 2 - ${appVars.baseSidebarContainerWidth}px)`,
      [breakpoints.up('xl')]: {
        width: appVars.maxSidebarContainerWidth,
        left: `calc(50% + (${appVars.maxContentContainerWidth}px + ${appVars.maxSidebarContainerWidth}px) / 2 - ${appVars.maxSidebarContainerWidth}px)`,
      },

      '@supports (position: sticky)': {
        alignSelf: 'start',
        position: 'sticky',
        flexDirection: 'row',
        top: 70,
        zIndex: 'unset',
        left: 'unset',
        [breakpoints.up('xl')]: {
          left: `unset`,
        },
      },
    },
  };

  return {
    addSpacing: {
      [breakpoints.down('md')]: {
        paddingLeft: 15,
        paddingRight: 15,
      },
    },
    form: formAll.root,
    formAll,
    cardSelectionGroup,
    columnLeft: formAll.columnLeft,
    columnMiddle: formAll.columnMiddle,
    columnRight: formAll.columnRight,
    complexTable: {
      ...tableBody,
      '& tbody:nth-child(odd) tr td': {
        backgroundColor: palette.grey[100],
      },
    },
    footerRoot: {
      marginTop: 'auto',
      width: '100%',
      position: 'relative',
      backgroundColor: palette.partner.footerBackground,
    },
    headerRoot: {
      ...typography.body3,
      backgroundColor: palette.other.body,
      zIndex: zIndex.drawer + 11,
    },
    linkButton: {
      fontFamily: 'inherit',
      fontSize: 'inherit',
      fontWeight: 'inherit',
      verticalAlign: 'inherit',
    },
    nextButton: formAll.next,
    pageRoot: {
      // This would make each page cover the global spinner (which is defined in index.html)
      // when the page is ready to render some content
      backgroundColor: palette.other.body,
    },
    sidebar: {
      divider: {
        marginBottom: 10,
        marginTop: 10,
      },
      drawerHeader: {
        paddingBottom: 10,
      },
      sectionItem: {
        ...typography.body2,
      },
    },
    simpleTableBody: {
      ...tableBody,
      '& tr:nth-child(even) td': {
        backgroundColor: palette.grey[100],
      },
      '& th, td': {
        borderBottom: 0,
      },
    },
    /**
     * SVG Class Format. Make sure to have a `checked` class name in your local stylesheet
     * and assign it to any ancestor node when icon needs to have `active` state.
     */
    svg: (checked) => ({
      fill: 'transparent',
      '&, & svg': {
        position: 'relative',
        zIndex: 0,
      },
      // NOTE - explicitly referencing `.zone01` instead of `$zone01` because these are static class names used by asset svgs, not components that use Mui/JSS
      '& .zone01': {
        fill: palette.icon.stroke,
        transition: '.5s',
      },
      '& .zone02': {
        fill: palette.grey[50],
        transition: '.5s',
      },
      ...(checked && {
        [`.${checked} & .zone01`]: {
          fill: palette.icon.hover,
        },
      }),
    }),
    setColorSvg: (color) => ({
      '& path': {
        fill: color,
      },
    }),
    tableContainer: {
      border: `1px solid ${palette.other.border}`,
      borderRadius: 8,
      marginTop: 16,
      maxWidth: 1000,
      padding: '0 !important',
    },
    tableFirstRow: {
      '& th': {
        ...typography.body1Bold,
        borderBottom: `1px solid ${palette.other.border}`,
        padding: 10,
        backgroundColor: palette.primary.light,
      },
      '& th:first-child': {
        borderTopLeftRadius: 7,
      },
      '& th:last-child': {
        borderTopRightRadius: 7,
      },
      '& th:not(:first-child)': {
        borderLeft: `1px solid ${palette.other.border}`,
      },
    },
    tableSubHeader: {
      '& th': {
        borderBottom: `1px solid ${palette.other.border}`,
        padding: 10,
        backgroundColor: palette.primary.light,
        borderLeft: `1px solid ${palette.other.border}`,
      },
    },
    underlinedLink: formAll.underlinedLink,
    visuallyHidden: {
      position: 'absolute',
      left: '-9999px !important',
      top: '-9999px !important',
    },
    withRightSidebar,
    shadow1: {
      boxShadow: `0px 2px 5px 0px ${palette.shadow.primary}`,
    },
    shadow2: {
      boxShadow: `0px 0px 20px 0px ${palette.shadow.primary}`,
    },
  };
};
