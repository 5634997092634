import { useCallback, useEffect, useMemo, useState } from 'react';

import { Stack } from '@mui/material';

import { maskEmail } from '@ecp/utils/common';
import { Form, useForm } from '@ecp/utils/form';

import { Alert } from '@ecp/components';
import {
  AuthenticationCard,
  LoadingButton,
  NavLink,
} from '@ecp/features/servicing/shared/components';
import {
  PAGE_PATH,
  QUERY_PARAMS_AND_VALUE,
  usePageFlow,
} from '@ecp/features/servicing/shared/routing';
import {
  useSharedState,
  useValidateUser,
  useVerifyUserIdentity,
} from '@ecp/features/servicing/shared/state';
import type { VerifyUserIdentityRequest } from '@ecp/features/servicing/shared/state';
import type { EmailOption } from '@ecp/features/servicing/shared/types';
import { IconUIMail } from '@ecp/themes/base';
import type { CardOption } from '@ecp/types';

import type { VerifyIdentityFormInputs } from './VerifyIdentity.schema';
import { verifyIdentitySchema } from './VerifyIdentity.schema';
import { useStyles } from './VerifyIdentityPage.styles';

export const VerifyIdentityPage: React.FC = () => {
  const { validateData } = useValidateUser();

  // Throw a full page error when no emails are available
  if (validateData && validateData.emails.length === 0) throw new Error('No emails were returned');

  const pageFlow = usePageFlow();
  const { classes } = useStyles();
  const formContext = useForm({
    validations: verifyIdentitySchema,
  });

  const [, setSharedStateEmail] = useSharedState<EmailOption>('SelectedEmail', {
    name: '',
    value: '',
  });
  const { verifyUserIdentity } = useVerifyUserIdentity();
  const { handleSubmit, setValue } = formContext;

  const noAccessText = "I don't have access to this method",
    trackingLabel = 'i_dont_have_access_to_this_method',
    trackingName = 'i_dont_have_access_to_this_method_link';

  const validationOptions: CardOption[] = useMemo(
    () =>
      validateData?.emails
        ? validateData.emails.map((email) => ({
            label: `Email a one-time code to ${email.value}`,
            value: email.code,
            icon: <IconUIMail />,
          }))
        : [],
    [validateData],
  );

  useEffect(() => {
    // If user refresh the page and validateData is undefined user redirect to createAccount page
    if (!validateData) pageFlow.goBack();
  }, [validateData?.emails, validateData, pageFlow]);
  useEffect(() => {
    if (validationOptions?.length === 1) {
      setValue('verificationOption', validationOptions[0].value);
    }
  }, [validationOptions, setValue]);

  const [errorAlert, setErrorAlert] = useState('');
  const handleErrorAlertClose = useCallback(() => {
    setErrorAlert('');
  }, []);

  const onSubmit = useCallback(() => {
    handleSubmit(async (data) => {
      const { verificationOption } = data as Required<VerifyIdentityFormInputs>;
      if (validateData) {
        if (validateData.emails.length > 1) {
          const selectedOption = validateData.emails.find((a) => a.code === verificationOption);
          setSharedStateEmail({ name: selectedOption?.value, value: selectedOption?.code });
        } else
          setSharedStateEmail({
            name: validateData.emails[0].value,
            value: validateData.emails[0].code,
          });
        const verifyUserIdentityRequest: VerifyUserIdentityRequest = {
          userInfo: validateData?.userInfo.payload,
          factor: 'email',
          value: verificationOption,
        };
        const { error, success } = await verifyUserIdentity(verifyUserIdentityRequest);
        if (error) {
          if (error === 'EXPIRED_USER_TOKEN')
            pageFlow.navigate({
              pathname: PAGE_PATH.LOGIN,
              search: QUERY_PARAMS_AND_VALUE.TIMEOUT_TRUE,
            });
          setErrorAlert(error);
        } else if (success?.otpSend) {
          pageFlow.goForward();
        }
      }
    })();
  }, [validateData, pageFlow, handleSubmit, verifyUserIdentity, setSharedStateEmail]);

  const handleOnBack = useCallback(() => {
    pageFlow.goBack();
  }, [pageFlow]);

  const errorMessage =
    'We apologize, but we encountered an error. Please try again now, or wait a few minutes and try again later.';

  const body = (
    <>
      {errorAlert && (
        <Alert withIcon type='error' withAction onClose={handleErrorAlertClose}>
          {errorMessage}
        </Alert>
      )}
      <Form
        formProviderProps={formContext}
        onSubmit={onSubmit}
        name='verifyIdentityForm'
        className={classes.root}
      >
        <Stack spacing={5}>
          <Stack spacing={4}>
            <>
              <h1>We've located your account</h1>
              <p>
                For security, a one-time code will be emailed to{' '}
                <span className={classes.emailLabel}>
                  {maskEmail(validateData?.emails[0].value)}
                </span>
                , which you can use to access your account.
              </p>
            </>

            <p className={classes.expiryLabel}>This code will expire in 5 minutes.</p>
            <div className={classes.buttonWrapper}>
              <LoadingButton
                type='submit'
                className={classes.confirmButton}
                trackingName='email_me_button'
                trackingLabel='email_me'
              >
                SEND CODE
              </LoadingButton>
            </div>
            <NavLink
              to={PAGE_PATH.CONTACTUS}
              className={classes.secondaryLink}
              replace
              trackingLabel={trackingLabel}
              trackingName={trackingName}
              objectType='link'
            >
              {noAccessText}
            </NavLink>
          </Stack>
        </Stack>
      </Form>
    </>
  );

  return <AuthenticationCard onBack={handleOnBack}>{body}</AuthenticationCard>;
};
