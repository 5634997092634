import type { OptionData } from '../../../types';
import type { useStyles } from './CoverageCardItems.styles';

type Props = Pick<OptionData, 'field' | 'subCoverages' | 'title'> & {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
};

export const CoverageCardItems: React.FC<Props> = (props) => {
  // no implementation until sales migrates to use or servicing supports editable coverages
  return null;

  // const { field, subCoverages, title } = props;
  // const { classes, cx } = useStyles(undefined, { props });

  // const pips: string[] = [];
  // const excludeDriversKeys: string[] = [];
  // if (subCoverages) {
  //   Object.keys(subCoverages).forEach((key) => {
  //     if (subCoverages[key].isPipSubCoverage) pips.push(key);
  //     if (subCoverages[key].isExcludeDriver) excludeDriversKeys.push(key);
  //   });
  // }

  // /**
  //  * A generic function/template to display subcoverage field
  //  * @param key subcoverage key
  //  */
  // const displaySubCoverage = useCallback(
  //   (
  //     key: string,
  //     subCoverageField: Field | undefined,
  //     subCoverageTitle: string,
  //     subCoverageFieldId: string,
  //     primaryText: string | undefined,
  //     trackingName = `Change_${subCoverageTitle}_${title}_Amount`,
  //     trackingLabel: string = subCoverageField?.props.value,
  //   ): React.ReactElement => {
  //     return (
  //       <>
  //         {subCoverageField?.props.options ? (
  //           <Select
  //             {...subCoverageField.props}
  //             className={classes.subCoveragesField}
  //             options={subCoverageField.props.options}
  //             label={subCoverageTitle}
  //             id={subCoverageFieldId}
  //             value={subCoverageField.props.value}
  //             actionOnChange={subCoverageField.props.actionOnComplete}
  //             trackingName={trackingName}
  //           />
  //         ) : (
  //           <TextField
  //             key={key}
  //             {...subCoverageField?.props}
  //             className={classes.subCoveragesField}
  //             label={subCoverageTitle}
  //             id={subCoverageFieldId}
  //             ariaLabel={subCoverageTitle}
  //             trackingName={trackingName}
  //             trackingLabel={trackingLabel}
  //           />
  //         )}
  //         {primaryText && (
  //           <TooltipWithIcon className={classes.subCoveragesTooltip} title={primaryText} />
  //         )}
  //       </>
  //     );
  //   },
  //   [classes.subCoveragesField, classes.subCoveragesTooltip, title],
  // );

  // if (!field?.props) return null;

  // // For ADA compliance test id 244, aria-labelledby, aria-describedby cannnot have space(' '), period('.') delimiters
  // const coverageFieldId = title.replace(/ /g, '');
  // if (subCoverages) {
  //   const otherSubcoverages = omit(subCoverages, [...pips, ...excludeDriversKeys]); // omit pip subcoverages since we handle them separately

  //   return (
  //     <div className={classes.subCoveragesRoot}>
  //       <Grid container direction='column'>
  //         <div className={classes.subCoveragesRow}>
  //           {field.props.options ? (
  //             <Select
  //               {...field.props}
  //               className={classes.subCoveragesField}
  //               options={field.props.options}
  //               label={title}
  //               id={coverageFieldId}
  //               value={field.props.value}
  //               actionOnChange={field.props.actionOnComplete}
  //               actionOnComplete={undefined}
  //               trackingName={`Change_${title}_Amount`}
  //             />
  //           ) : (
  //             <TextField
  //               {...field.props}
  //               className={classes.subCoveragesField}
  //               label={title}
  //               id={coverageFieldId}
  //               ariaLabel={title}
  //               trackingName={`Change_${title}_Amount`}
  //               trackingLabel={field.props.value}
  //             />
  //           )}
  //         </div>
  //         {excludeDriversKeys.length > 0 && (
  //           <>
  //             <div className={classes.subCoveragesRow}>
  //               <TooltipWithIcon
  //                 className={cx(
  //                   classes.subCoveragesTooltip,
  //                   field.props.error && classes.subCoveragesTooltipError,
  //                 )}
  //                 title={subCoverages[excludeDriversKeys[0]].primaryText || ''}
  //               />
  //             </div>
  //             <div className={classes.subCoveragesRow}>
  //               {/* TODO: FormControl, FormGroup, FormLabel should be switched to use CheckboxGroup
  //                instead https://bitbucket.org/p20/customersales-ui/commits/2e0ba757ec5e986c4167f40dc369aea8c85660f7 */}
  //               <FormControl className={classes.checkBoxGroup} component='fieldset'>
  //                 <FormLabel component='legend'>
  //                   <div className={classes.checkBoxGroupLabel}>
  //                     {subCoverages[excludeDriversKeys[0]].title}
  //                   </div>
  //                 </FormLabel>

  //                 <FormGroup>
  //                   {excludeDriversKeys.map((key) => {
  //                     const { field: subCoverageField, title } = subCoverages[key];
  //                     // For ADA compliance test id 244, aria-labelledby, aria-describedby cannnot have space(' '), period('.') delimiters
  //                     const subCoverageFieldId = title.replace(/ /g, '');

  //                     return (
  //                       <FormControlLabel
  //                         control={
  //                           <Checkbox
  //                             className={classes.formControlCheckBox}
  //                             id={subCoverageFieldId}
  //                             checked={subCoverageField?.props.value === 'true'}
  //                             // eslint-disable-next-line react/jsx-no-bind
  //                             onChange={(_, checked) => {
  //                               subCoverageField?.props.actionOnComplete(
  //                                 checked ? 'true' : 'false',
  //                               );
  //                             }}
  //                           />
  //                         }
  //                         label={title}
  //                         className={classes.formControlLabel}
  //                       />
  //                     );
  //                   })}
  //                 </FormGroup>
  //               </FormControl>
  //             </div>
  //           </>
  //         )}
  //         <Divider aria-hidden='true' className={classes.divider} />

  //         {Object.keys(otherSubcoverages).map((key) => {
  //           const {
  //             field: subCoverageField,
  //             title: subCoverageTitle,
  //             primaryText,
  //           } = subCoverages[key];
  //           // For ADA compliance test id 244, aria-labelledby, aria-describedby cannnot have space(' '), period('.') delimiters
  //           const subCoverageFieldId = subCoverageTitle.replace(/ /g, '');

  //           return (
  //             <div key={key} className={classes.subCoveragesRow}>
  //               {displaySubCoverage(
  //                 key,
  //                 subCoverageField,
  //                 subCoverageTitle,
  //                 subCoverageFieldId,
  //                 primaryText,
  //               )}
  //             </div>
  //           );
  //         })}
  //       </Grid>
  //     </div>
  //   );
  // }

  // return (
  //   <div className={classes.root}>
  //     {field.props.options ? (
  //       <RadioGroupWithOptions
  //         {...field.props}
  //         className={classes.hideError}
  //         id={field.props.name}
  //         dataTestId={title}
  //         variant='roundButton'
  //         trackingName={`Change_${title}_Amount`}
  //         adjustableGrid
  //       />
  //     ) : (
  //       <TextField
  //         {...field.props}
  //         label='Coverage limit'
  //         id={coverageFieldId}
  //         ariaLabel='Coverage limit'
  //         trackingName={`Change_${title}_Amount`}
  //         trackingLabel={field.props.value}
  //       />
  //     )}
  //   </div>
  // );
};
