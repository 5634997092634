import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ContactUs' })((theme) => ({
  root: {},
  subTitle: theme.typography.body1Bold,
  links: {
    ...theme.typography.body1Underline,
    textAlign: 'left',
    color: theme.palette.primary.main,
  },
  padding: { justifyContent: 'space-around' },
  chatNowButton: {
    paddingBottom: 11,
    paddingTop: 11,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
    },
  },
  gridSection: {
    display: 'grid',
    columnGap: theme.spacing(3),
    rowGap: theme.spacing(6),
    overflow: 'hidden',
    gridTemplateColumns: 'repeat(3, 1fr)',
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
      rowGap: theme.spacing(3),
    },
  },
  gridItem: {
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        backgroundColor: theme.palette.other.divider,
        inlineSize: '100vw',
        blockSize: 1,
        insetInlineStart: 0,
        insetBlockStart: `calc(100% + ${theme.spacing(3)})`,
      },
    },
  },
}));
