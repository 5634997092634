import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutopayStatusIndicator' })((theme) => ({
  root: {
    alignItems: 'center',
  },
  status: theme.typography.body1Bold,
  statusIndicator: {
    backgroundColor: theme.palette.grey[400],
    borderRadius: '50%',
    height: 16,
    marginRight: 8,
    width: 16,
  },
  statusIndicatorOn: {
    backgroundColor: theme.palette.success.main,
  },
  statusLabel: {
    color: theme.palette.text.secondary,
  },
}));
