export const uppercase = <T extends string = string>(string: T): Uppercase<T> =>
  string.toUpperCase() as Uppercase<T>;

/** Replacement for lodash upperFirst method */
export const upperFirst = (string: string | null | undefined): string => {
  if (!string) return '';

  return string.charAt(0).toUpperCase() + string.slice(1);
};

/** Replacement for lodash lowerFirst method */
export const lowerFirst = (string: string | null | undefined): string => {
  if (!string) return '';

  return string.charAt(0).toLowerCase() + string.slice(1);
};

/**
 * Build up a sentence-style list from an array of strings
 *
 * * ['auto'] -> 'auto'
 * * ['auto', 'home'] -> 'auto and home'
 * * ['auto', 'home', 'renters'] -> 'auto, home, and renters'
 *
 */
export const reduceSentenceList =
  (listLength: number) =>
  (acc: string, name: string, index: number): string => {
    if (listLength === 1) return name;

    if (index !== listLength - 1) {
      if (listLength === 2) return `${acc}${name} `;

      return `${acc}${name}, `;
    }

    return `${acc}and ${name}`;
  };
