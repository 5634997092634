import { IconUIMinus, IconUIPlus, useIsMobile } from '@ecp/themes/base';

import { Button } from '../Button';
import { useStyles } from './ShowMoreOrLess.styles';
export interface ShowMoreOrLessProps {
  showMore: boolean;
  cardSize?: 'small' | 'medium';
  value?: unknown;
  groupTestId?: string;
  onClick?: React.HTMLAttributes<HTMLButtonElement>['onClick'];
  notShow?: boolean;
}

export const ShowMoreOrLess: React.FC<ShowMoreOrLessProps> = (props) => {
  const { showMore, cardSize = 'small', groupTestId, onClick, notShow = false } = props;
  const { classes, cx } = useStyles();
  const isMobile = useIsMobile();
  const buttonText = showMore ? 'See less choices' : 'See more choices';

  // This is for situations when number of options
  // is less and we don't need the "See more choices"
  if (notShow) {
    return null;
  }

  // TODO Replace data-testid with the proper expanded/collapsed state handling from a11y standpoint
  // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-expanded#buttons
  return isMobile ? (
    <Button
      data-testid={groupTestId}
      aria-expanded={showMore}
      // aria-controls="widget1"
      className={cx(classes.root, classes.button)}
      onClick={onClick}
      variant='outlinePrimary'
    >
      {buttonText}
    </Button>
  ) : (
    <button
      data-testid={groupTestId}
      aria-expanded={showMore}
      // aria-controls="widget1"
      aria-label={buttonText}
      className={cx(classes.root, classes.card, classes[cardSize])}
      onClick={onClick}
      type='button'
    >
      {showMore ? (
        <IconUIMinus fontSize='large' className={classes.addButton} />
      ) : (
        <IconUIPlus fontSize='large' className={classes.addButton} />
      )}
      <div className={classes.text} data-testid={showMore ? 'seeLessChoices' : 'seeMoreChoices'}>
        {buttonText}
      </div>
    </button>
  );
};
