import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PolicyDetailsPage' })((theme) => ({
  root: {},
  unavailableSection: {
    backgroundColor: theme.palette.primary.background,
    padding: '20px 24px',
  },
  button: { ...theme.typography.button, paddingTop: 16 },
  buttonWrapper: { width: 'fit-content' },
  paymentTitle: theme.typography.body3,
}));
