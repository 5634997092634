import { Stack } from '@mui/material';

import type { ServicingModalProps } from '../ServicingModal';
import { ServicingModal } from '../ServicingModal';
import { useStyles } from './ContactUsModal.styles';

interface Props extends Omit<ServicingModalProps, 'body' | 'title'> {
  helpText?: string;
  title?: string; // title is optional due to default
  body?: React.ReactElement | string; // body is optional for contact modal due to default
}

export const ContactUsModal: React.FC<Props> = (props) => {
  const {
    title = 'Contact us',
    helpText = 'Our licensed advisors are here to help you',
    body,
    ...rest
  } = props;
  const { classes } = useStyles();

  const defaultBody = (
    <>
      <Stack spacing={4}>
        <p className={classes.title}>{helpText}</p>
        <p className={classes.subtitle}>
          <a href='tel:+18009999999' className={classes.link}>
            1-800-999-9999
          </a>
        </p>
      </Stack>
      <p className={classes.body}>
        Mon-Thu: 8:00am – 11:00pm ET
        <br />
        Fri: 8:00am – 10:00pm ET <br />
        Sat: 10:00am – 4:00pm ET <br />
        Sun: CLOSED
      </p>
      <p className={classes.subtitle}>Live chat</p>
      <p className={classes.body}>
        Mon-Fri: 9:00am to 10:00pm ET <br />
        Sat-Sun: CLOSED
      </p>
    </>
  );

  return <ServicingModal {...rest} body={body || defaultBody} title={title} />;
};
