import { Stack } from '@mui/material';

import { useStyles } from './InfoCard.styles';

type Props = {
  title: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
  body?: React.ReactElement;
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  iconTitle?: string;
};

export const InfoCard: React.FC<Props> = (props) => {
  const { title, Icon, iconTitle = 'icon', body } = props;

  const { classes, cx } = useStyles(undefined, { props });

  return (
    <Stack className={classes.root}>
      <div className={cx(classes.cardHeader, !body && classes.cardHeaderWithoutBody)}>
        <Stack direction='row' spacing={2} alignItems='center'>
          <Icon className={classes.icon} title={iconTitle} />
          <span className={classes.title}>{title}</span>
        </Stack>
      </div>
      {body && <div className={classes.body}>{body}</div>}
    </Stack>
  );
};
