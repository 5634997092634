import type { CSSObject } from '@ecp/themes/base';
import { makeStyles } from '@ecp/themes/base';

type ClassName = 'disabled' | 'icon' | 'processing' | 'processingIcon';

const getDisabledProcessingStyles = (
  backgroundColor: string,
  color: string,
  classes: Record<ClassName, string>,
  borderColor?: string,
): CSSObject => ({
  // UX wants to keep visual the same even when disabled & processing
  // special case to keep same look since assumed page is not interactive
  [`&.${classes.disabled}.${classes.processing}`]: {
    backgroundColor,
    borderColor,
    color,

    '&:hover': {
      backgroundColor,
      borderColor,
    },
  },
});

export const useStyles = makeStyles<void, ClassName>({
  name: 'Button',
})((...[theme, , classes]) => ({
  root: {
    ...theme.typography.button,
    borderRadius: 25,
    height: 50,
    minWidth: 'unset',
    padding: '15px 30px',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    '&:disabled': {
      ...theme.mixins.setColorSvg(theme.palette.action.disabled),
      color: theme.palette.actions.disabledText,
      backgroundColor: theme.palette.actions.disabledBg,
    },
  },
  primary: {
    ...getDisabledProcessingStyles(
      theme.palette.actions.disabledBg,
      theme.palette.actions.disabledText,
      classes,
    ),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    // !TODO Refactor this one
    [`& .${classes.icon}`]: {
      marginRight: -20,
      marginLeft: 10,
    },
  },
  secondary: {
    ...getDisabledProcessingStyles(
      theme.palette.actions.disabledBg,
      theme.palette.actions.disabledText,
      classes,
    ),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  iconTextExtraLarge: {
    ...theme.typography.subtitle,
    [`& .${classes.icon}`]: {
      height: 30,
      width: 30,
    },
  },
  iconTextLarge: {
    ...theme.typography.body4,
    [`& .${classes.icon}`]: {
      height: 26,
      width: 26,
    },
  },
  iconText: {
    ...theme.typography.button2Medium,
    [`& .${classes.icon}`]: {
      height: 24,
      width: 24,
    },
  },
  iconTextBase: {
    ...getDisabledProcessingStyles('transparent', theme.palette.actions.disabledText, classes),
    color: theme.palette.text.link,
    height: 'auto',
    padding: 0,
    textTransform: 'none',
    backgroundColor: 'transparent',
    '&:disabled': {
      backgroundColor: 'transparent',
    },
    '&:hover': {
      ...theme.mixins.setColorSvg(theme.palette.text.hover),
      color: theme.palette.text.hover,
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
    [`& .${classes.processingIcon}`]: {
      marginLeft: -4,
      marginRight: 4,
    },
  },
  iconTextMedium: {
    ...theme.typography.button2,
    [`& .${classes.icon}`]: {
      height: 22,
      width: 22,
    },
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'inherit',
    },
  },
  iconTextSmall: {
    ...theme.typography.button2Small,
    [`& .${classes.icon}`]: {
      height: 18,
      width: 18,
    },
  },
  text: {
    ...theme.typography.button2Underline,
    display: 'inline',
  },
  textExtraLarge: {
    ...theme.typography.subtitleUnderline,
    display: 'inline',
  },
  textLarge: {
    ...theme.typography.body4,
    textDecoration: 'underline',
    display: 'inline',
  },
  textMedium: {
    ...theme.typography.button2MediumUnderline,
    display: 'inline',
  },
  textSmall: {
    ...theme.typography.button2SmallUnderline,
    display: 'inline',
  },
  outlinePrimary: {
    ...getDisabledProcessingStyles(
      theme.palette.actions.disabledBg,
      theme.palette.actions.disabledText,
      classes,
    ),
    borderColor: theme.palette.primary.border,
    backgroundColor: theme.palette.grey[50],
    '&:hover': {
      backgroundColor: theme.palette.primary.background,
      borderColor: theme.palette.primary.border,
    },
  },
  outlineSecondary: {
    border: `1px solid ${theme.palette.primary.border}`,
    color: theme.palette.primary.darkText,
    backgroundColor: theme.palette.grey[50],
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  success: {
    ...getDisabledProcessingStyles(
      theme.palette.actions.disabledBg,
      theme.palette.actions.disabledText,
      classes,
    ),
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  danger: {
    ...getDisabledProcessingStyles(theme.palette.error.main, theme.palette.other.body, classes),
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  disabled: {},
  icon: {
    ...theme.mixins.setColorSvg(theme.palette.primary.main),
    height: '1em',
  },
  processing: {},
  processingIcon: {
    marginLeft: -10,
    marginRight: 10,
    '&.processing-transition-enter-active, &.processing-transition-exit-active': {
      transition: '.3s ease-in-out',
    },
    '&.processing-transition-enter': {
      width: '0 !important',
      marginLeft: 0,
      marginRight: 0,
    },
    '&.processing-transition-enter-active': {
      width: '20px !important',
      marginLeft: -10,
      marginRight: 10,
    },
    '&.processing-transition-exit': {
      width: '20px !important',
      marginLeft: -10,
      marginRight: 10,
    },
    '&.processing-transition-exit-active': {
      width: '0 !important',
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));
