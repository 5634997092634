import type { UsePaymentInfoReturn } from '../payment';
import { getProductLineFromPolicyResponse } from './getProductLineFromPolicyResponse';
import { isInPendingCancelStatus } from './isInPendingCancelStatus';
import { compareProductLine } from './sortPolicies';

export const sortPoliciesInPaymentSection = (
  paymentInfos: UsePaymentInfoReturn[] | undefined,
): UsePaymentInfoReturn[] | undefined => {
  const paymentInfosPendingCanceled: UsePaymentInfoReturn[] | undefined = paymentInfos?.filter(
    (paymentInfo) =>
      !paymentInfo.paymentInfo.isClassicBillingAccountStatusInPendingCancel &&
      isInPendingCancelStatus({
        billingResponse: paymentInfo.paymentInfo.billingAccount,
        policySource: paymentInfo.paymentInfo.policyData?.policy.sourceSystemName,
        productLine: getProductLineFromPolicyResponse(paymentInfo.paymentInfo.policyData),
      }) &&
      !paymentInfo.isError,
  );
  const sortedPaymentInfosPendingCanceled = (paymentInfosPendingCanceled ?? []).sort((a, b) =>
    compareProductLine(a.paymentInfo.policyData, b.paymentInfo.policyData),
  );

  const paymentInfosPendingCanceledWithUndefinedMinDue: UsePaymentInfoReturn[] | undefined =
    paymentInfos?.filter(
      (paymentInfo) =>
        paymentInfo.paymentInfo.isClassicBillingAccountStatusInPendingCancel &&
        !paymentInfo.isError,
    );
  const sortedPaymentInfosPendingCanceledWithUndefinedMinDue = (
    paymentInfosPendingCanceledWithUndefinedMinDue ?? []
  ).sort((a, b) => compareProductLine(a.paymentInfo.policyData, b.paymentInfo.policyData));

  const paymentInfosNonPendingCanceled: UsePaymentInfoReturn[] | undefined = paymentInfos?.filter(
    (paymentInfo) =>
      !paymentInfo.paymentInfo.isClassicBillingAccountStatusInPendingCancel &&
      !isInPendingCancelStatus({
        billingResponse: paymentInfo.paymentInfo.billingAccount,
        policySource: paymentInfo.paymentInfo.policyData?.policy.sourceSystemName,
        productLine: getProductLineFromPolicyResponse(paymentInfo.paymentInfo.policyData),
      }) &&
      !paymentInfo.isError,
  );
  const sortedPaymentInfosNonPendingCanceled = (paymentInfosNonPendingCanceled ?? []).sort((a, b) =>
    compareProductLine(a.paymentInfo.policyData, b.paymentInfo.policyData),
  );

  const paymentInfosError: UsePaymentInfoReturn[] | undefined = paymentInfos?.filter(
    (paymentInfo) => paymentInfo.isError,
  );
  const sortedPaymentInfosError = (paymentInfosError ?? []).sort((a, b) =>
    compareProductLine(a.paymentInfo.policyData, b.paymentInfo.policyData),
  );

  return [
    ...sortedPaymentInfosPendingCanceledWithUndefinedMinDue,
    ...sortedPaymentInfosPendingCanceled,
    ...sortedPaymentInfosNonPendingCanceled,
    ...sortedPaymentInfosError,
  ];
};
