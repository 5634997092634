import { useCallback, useState } from 'react';

import { Divider, Stack, Table } from '@mui/material';

import { Form, useField, useForm, useFormContext } from '@ecp/utils/form';

import { Button, NumberFormat } from '@ecp/components';
import {
  Card,
  ElectronicSignatureField,
  LoadingButton,
  LoadingOverlay,
  ModalError,
} from '@ecp/features/servicing/shared/components';
import type { UseModalPathReturn as CaMileageEditProps } from '@ecp/features/servicing/shared/routing';
import type { ErrorResponse } from '@ecp/features/servicing/shared/state';
import { usePolicy, useSharedState } from '@ecp/features/servicing/shared/state';
import type { CaMileageSubmit } from '@ecp/features/servicing/shared/types';
import { useIsMobile } from '@ecp/themes/base';

import type { CaMileageEditFormInputs } from './CaMileageEdit.schema';
import { caMileageEditSchema } from './CaMileageEdit.schema';
import { useStyles } from './CaMileageEdit.styles';

interface VehicleFieldProps extends React.ComponentProps<typeof NumberFormat> {
  index: number;
  fieldName: keyof CaMileageEditFormInputs['vehicles'][number];
}
const VehicleField: React.FC<VehicleFieldProps> = (props) => {
  const { classes } = useStyles();
  const { index, fieldName, ...rest } = props;
  const { control } = useFormContext<CaMileageEditFormInputs>();
  const field = useField({ control, name: `vehicles.${index}.${fieldName}` });

  return (
    <NumberFormat
      {...field}
      {...rest}
      className={classes.vehicleField}
      pattern='\d*'
      inputMode='numeric'
    />
  );
};

export const CaMileageEdit: React.FC<CaMileageEditProps> = (props) => {
  const { goForward, policyNumber, reset, sharedStateKey } = props;
  const { classes, cx } = useStyles();
  const [submitError] = useState<ErrorResponse>();
  const { policyData, isLoading } = usePolicy(policyNumber);
  const [sharedState, setSharedState] = useSharedState<CaMileageSubmit>(sharedStateKey);
  const isMobile = useIsMobile();
  const formContext = useForm({
    validations: caMileageEditSchema,
    defaultValues: {
      electronicSignature: sharedState?.acknowledgement,
      vehicles: (policyData?.vehicles ?? []).map((vehicle, index) => ({
        currentOdometer: sharedState?.vehicleInputs[index].currentOdometer,
        existingCurrentOdometer: vehicle.mockOdometerReading,
        totalAnnualMileage: sharedState?.vehicleInputs[index].totalAnnualMileage,
        vin: vehicle.vin,
      })),
    },
  });
  const { handleSubmit } = formContext;

  const onSubmit = useCallback(() => {
    handleSubmit(async (data) => {
      setSharedState({
        vehicleInputs: data.vehicles,
        acknowledgement: data.electronicSignature ?? '',
      });
      goForward();
    })();
  }, [goForward, handleSubmit, setSharedState]);

  if (submitError) {
    return (
      <ModalError
        errorTitle='update the mileage for your policy'
        errorDescription='updating the mileage for your policy'
      />
    );
  }

  const summary = `Please fill out the Current Odometer Reading and Total Annual Mileage column for each vehicle listed below.
     You may use the Estimated Mileage as a guide to help you determine your actual mileage.`;

  const esig = (
    <>
      <Divider />
      <Stack spacing={{ xs: 2, md: 3 }}>
        <p className={classes.legal}>
          I certify that the information provided to validate my mileage is accurate to the best of
          my knowledge. If I do not provide the requested information <b>within 30 days</b>, my
          mileage may default, which may result in an increase in premium.
        </p>
        <p className={classes.legal}>
          I understand that I may be asked for additional information to substantiate my mileage
          estimate and that I may be charged additional premium for a policy term if a vehicle's
          actual annual mileage is greater than what I listed. I understand that I will need to
          update my estimated annual mileage upon subsequent renewals.
        </p>
        <p className={classes.legal}>
          Please type your full name. This serves as your legal signature and acknowledges the
          information provided on this page.
        </p>
        <ElectronicSignatureField classes={{ root: classes.signature }} />
        <p className={classes.legal}>This form is not a renewal offer.</p>
        <p className={classes.formCode}>afca05 (005)</p>
      </Stack>
    </>
  );

  return isLoading ? (
    <LoadingOverlay />
  ) : (
    <Form onSubmit={onSubmit} formProviderProps={formContext}>
      <Stack spacing={{ xs: 3, md: 5 }} classes={classes.root}>
        <Card
          body={
            <Stack spacing={{ xs: 4, md: 7 }}>
              <p className={classes.legal}>Policy Number: {policyNumber}</p>
              <Stack spacing={1}>
                <h2>California Mileage Verification</h2>
                <p className={classes.codeOfReg}>
                  In accordance with Section 2632.5 (c)(2)(B)(i) of the California Code of
                  Regulations
                </p>
              </Stack>
              <Stack spacing={{ xs: 3, md: 7 }}>
                <p className={classes.subtitle}>{summary}</p>
                {isMobile ? (
                  <>
                    <Stack
                      component={Table}
                      summary={summary}
                      spacing={3}
                      className={classes.table}
                    >
                      {policyData?.vehicles?.map((vehicle, index) => (
                        <tbody key={vehicle.vin}>
                          <tr>
                            <th
                              scope='row'
                              className={cx(classes.cell, classes.cellHeader, classes.cellTextOnly)}
                            >
                              Vehicle Year/
                              <wbr />
                              Make/Model
                            </th>
                            <td
                              id={`${vehicle.vin}-ymm`}
                              className={(classes.cell, classes.cellTextOnly, classes.cellValue)}
                            >
                              {vehicle.year}/ {vehicle.make}/ {vehicle.model}
                            </td>
                          </tr>
                          <tr>
                            <th
                              scope='row'
                              className={cx(classes.cell, classes.cellHeader, classes.cellTextOnly)}
                            >
                              VIN
                            </th>
                            <td
                              id={`${vehicle.vin}-vin`}
                              className={(classes.cell, classes.cellTextOnly, classes.cellValue)}
                            >
                              {vehicle.vin}
                            </td>
                          </tr>
                          <tr>
                            <th
                              scope='row'
                              className={cx(classes.cell, classes.cellHeader, classes.cellTextOnly)}
                            >
                              Estimated Mileage
                            </th>

                            <td className={(classes.cell, classes.cellTextOnly, classes.cellValue)}>
                              {vehicle.annualMileageEstimate?.toLocaleString()}
                            </td>
                          </tr>
                          <tr>
                            <th
                              id={`${vehicle.vin}-odometer`}
                              scope='row'
                              className={cx(classes.cell, classes.cellHeader)}
                            >
                              Current Odometer Reading
                            </th>
                            <td
                              className={cx(classes.cell, classes.cellValue)}
                              headers={`${vehicle.vin}-ymm ${vehicle.vin}-vin ${vehicle.vin}-odometer`}
                            >
                              <VehicleField
                                index={index}
                                fieldName='currentOdometer'
                                maxLength={6}
                                ariaLabel={`Current Odometer Reading for ${vehicle.year}/ ${vehicle.make}/ ${vehicle.model} - ${vehicle.vin}`}
                              />
                            </td>
                          </tr>
                          <tr>
                            <th
                              id={`${vehicle.vin}-annualMileage`}
                              scope='row'
                              className={cx(classes.cell, classes.cellHeader)}
                            >
                              Total Annual Mileage
                            </th>
                            <td
                              className={cx(classes.cell, classes.cellValue)}
                              headers={`${vehicle.vin}-ymm ${vehicle.vin}-vin ${vehicle.vin}-annualMileage`}
                            >
                              <VehicleField
                                index={index}
                                fieldName='totalAnnualMileage'
                                maxLength={5}
                                ariaLabel={`Total Annual Mileage for ${vehicle.year}/ ${vehicle.make}/ ${vehicle.model} - ${vehicle.vin}`}
                              />
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </Stack>
                    {esig}
                  </>
                ) : (
                  <>
                    <Table summary={summary} className={classes.table}>
                      <thead>
                        <tr>
                          <th scope='col' className={cx(classes.cell, classes.cellHeader)}>
                            Vehicle Year/Make/Model
                          </th>
                          <th scope='col' className={cx(classes.cell, classes.cellHeader)}>
                            VIN
                          </th>
                          <th scope='col' className={cx(classes.cell, classes.cellHeader)}>
                            Estimated Mileage
                          </th>
                          <th scope='col' className={cx(classes.cell, classes.cellHeader)}>
                            Current Odometer Reading
                          </th>
                          <th scope='col' className={cx(classes.cell, classes.cellHeader)}>
                            Total Annual Mileage
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {policyData?.vehicles?.map((vehicle, index) => (
                          <tr key={vehicle.vin}>
                            <td className={cx(classes.cell, classes.cellValue)}>
                              {vehicle.year}/ {vehicle.make}/ {vehicle.model}
                            </td>
                            <td className={cx(classes.cell, classes.cellValue)}>{vehicle.vin}</td>
                            <td className={cx(classes.cell, classes.cellValue)}>
                              {vehicle.annualMileageEstimate?.toLocaleString()}
                            </td>
                            <td className={cx(classes.cell, classes.cellValue)}>
                              <VehicleField
                                index={index}
                                fieldName='currentOdometer'
                                maxLength={6}
                                ariaLabel={`Current Odometer Reading for ${vehicle.year}/ ${vehicle.make}/ ${vehicle.model} - ${vehicle.vin}`}
                              />
                            </td>
                            <td className={cx(classes.cell, classes.cellValue)}>
                              <VehicleField
                                index={index}
                                fieldName='totalAnnualMileage'
                                maxLength={5}
                                ariaLabel={`Total Annual Mileage for ${vehicle.year}/ ${vehicle.make}/ ${vehicle.model} - ${vehicle.vin}`}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {esig}
                  </>
                )}
              </Stack>
            </Stack>
          }
        />
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
          <LoadingButton type='submit' variant='primary' className={classes.button}>
            Agree & Continue
          </LoadingButton>
          <Button variant='iconTextMedium' className={classes.button} onClick={reset}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Form>
  );
};
