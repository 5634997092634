import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'InfoCard' })((theme) => ({
  root: {},
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '4px 4px 0 0',
    padding: '8px 16px',
    width: '100%',
  },
  cardHeaderWithoutBody: {
    borderRadius: 4,
  },
  title: {
    ...theme.typography.body1Bold,
    color: theme.palette.primary.contrastText,
  },
  icon: {
    ...theme.mixins.setColorSvg(theme.palette.grey[50]),
    height: 36,
    width: 36,
    flexShrink: 0,
  },
  body: {
    border: `1px solid ${theme.palette.other.border}`,
    borderRadius: '0 0 4px 4px',
    padding: 16,
  },
}));
