import { memo, useCallback, useMemo } from 'react';

import { Stack } from '@mui/material';

import { parseDollar } from '@ecp/utils/common';
import { DateConstants, formatDate } from '@ecp/utils/date';
import { useField, useFormContext } from '@ecp/utils/form';

import type { RadioGroupOption } from '@ecp/components';
import { Alert, Card, RadioGroupWithOptions } from '@ecp/components';
import { AccountSelect } from '@ecp/features/servicing/shared/components';
import { QUERY_PARAMS, type UseModalPathReturn } from '@ecp/features/servicing/shared/routing';
import type { PolicyBillingDetails } from '@ecp/features/servicing/shared/state';
import { useSharedState } from '@ecp/features/servicing/shared/state';
import type {
  AmountSelected,
  MakePaymentInformation,
  PaymentMethod,
} from '@ecp/features/servicing/shared/types';
import { getBrandData } from '@ecp/features/servicing/shared/util';
import type { MakePaymentFormInputs } from '@ecp/features/servicing/shared/util';

import { PolicyPaymentDisplay } from '../../components';
import { EnrollAutopay } from '../EnrollAutopay';
import { useStyles } from './MakePaymentSetup.styles';

export interface MakePaymentSetupProps extends UseModalPathReturn {
  policyBillingDetailsBeingPayed: PolicyBillingDetails;
  paymentMethod?: PaymentMethod;
  isAutopayEnabled?: boolean;
  isEscrow?: boolean;
  isClassic?: boolean;
  showErrorAutopay?: boolean;
}

interface PaymentAmountOption extends Omit<RadioGroupOption, 'description'> {
  description: AmountSelected;
}

export const MakePaymentSetup: React.FC<MakePaymentSetupProps> = memo((props) => {
  const {
    policyBillingDetailsBeingPayed,
    paymentMethod,
    sharedStateKey,
    isAutopayEnabled,
    isEscrow,
    isClassic,
    showErrorAutopay,
    getSearchParam,
  } = props;
  const policyInfo = policyBillingDetailsBeingPayed.policyInfo;
  const policyNumber = policyInfo?.policy.policyNumber;
  const { minimumDue, fullPayBalance } = policyBillingDetailsBeingPayed.billingInfo ?? {
    minimumDue: 0,
    nextDeductAmount: 0,
    fullPayBalance: 0,
  };

  const { classes } = useStyles();

  const paymentAmountOptions: PaymentAmountOption[] = useMemo(
    () => [
      ...(minimumDue !== undefined &&
      minimumDue > 0 &&
      minimumDue !== fullPayBalance &&
      !isAutopayEnabled
        ? [
            {
              label: parseDollar(minimumDue),
              value: String(minimumDue),
              description: 'Amount due' as const,
            },
          ]
        : []),
      {
        label: parseDollar(fullPayBalance),
        value: String(fullPayBalance),
        description: 'Pay in full',
      },
    ],
    [fullPayBalance, minimumDue, isAutopayEnabled],
  );

  const todaysDate = formatDate(new Date().toString(), DateConstants.MONTH_FULL_NAME_LONG_FORMAT);
  const { control } = useFormContext<MakePaymentFormInputs>();

  const paymentOnCardBody = <span className={classes.todayDate}>{todaysDate}</span>;

  const [sharedState, setSharedState] = useSharedState<MakePaymentInformation>(sharedStateKey);
  const brandData = getBrandData(policyInfo);
  const mainCustomerPhoneNumber = getBrandData(policyInfo).mainCustServPhoneNum;

  const paymentAmountField = useField({
    name: 'paymentAmount',
    defaultValue:
      paymentAmountOptions.find((option) => sharedState?.dueOrFull === option.description)?.value ??
      paymentAmountOptions.at(0)?.value,
    control,
  });

  const handleChangeAmount = useCallback(
    (value: string) => {
      setSharedState({
        ...(sharedState as MakePaymentInformation),
        dueOrFull:
          paymentAmountOptions.find((option) => option.value === value)?.description ===
          'Pay in full'
            ? 'Pay in full'
            : 'Amount due',
      });
    },
    [paymentAmountOptions, setSharedState, sharedState],
  );

  const isAutopayEnrolledMessage = `You are currently enrolled in AutoPay. Paying your policy in full today will delay your next automatic payment withdrawal until your policy renewal date on ${formatDate(
    policyInfo?.policy.periodEndDate,
    DateConstants.MONTH_FULL_NAME_LONG_FORMAT,
  )}`;

  const isAutopayEnroll = getSearchParam(QUERY_PARAMS.AUTOPAY_ENROLL);

  return (
    <div className={classes.root}>
      <Stack spacing={3}>
        {isAutopayEnabled && (
          <Alert type='info' withIcon>
            {isAutopayEnrolledMessage}
          </Alert>
        )}
        {isEscrow && (
          <Alert type='info' withIcon>
            Your policy is paid through an escrow. You can still make a payment, but, depending on
            the arrangement with your lender, you may be required to pay the balance in full.
          </Alert>
        )}
        <PolicyPaymentDisplay policyBillingDetails={policyBillingDetailsBeingPayed} />
        {sharedState?.isError && (
          <Alert type='error' withIcon>
            <>
              <b>Your payment did not process.</b>
              <p>
                An error occurred while attempting to make payment, we suggest waiting for a few
                minutes and retrying the transaction
              </p>
              <b>{mainCustomerPhoneNumber}</b>
              {brandData.mainOperationHours.map((hours) => (
                <p key={hours}>{hours}</p>
              ))}
            </>
          </Alert>
        )}
        {!isClassic && (
          <Stack spacing={1.5}>
            <AccountSelect {...props} customerServicePhone={mainCustomerPhoneNumber} />

            {showErrorAutopay && isAutopayEnroll && (
              <Alert withIcon type='info'>
                <p>
                  Enrollment in AutoPay requires a bank account be selected as your payment method.
                </p>
              </Alert>
            )}
          </Stack>
        )}
        <div>
          <RadioGroupWithOptions
            {...paymentAmountField}
            label='Payment amount'
            variant='largeRadioCard'
            options={paymentAmountOptions}
            trackingName='payment_amount_type_selection'
            className={classes.radioLabel}
            actionOnComplete={handleChangeAmount}
          />
        </div>
        <div>
          <p className={classes.dateHeading}>Payment date</p>
          <Card
            classes={{ content: classes.cardContent, root: classes.paymentBorder }}
            divider={false}
            body={paymentOnCardBody}
          />
        </div>
        {paymentMethod && (
          <EnrollAutopay
            policyNumber={policyNumber}
            paymentMethod={paymentMethod}
            amountSelected={sharedState?.dueOrFull || paymentAmountOptions[0].description}
          />
        )}
      </Stack>
    </div>
  );
});
