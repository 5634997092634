import { subscribeToDatabaseReadyEvent } from '../subscriptions/subscriptions';
import { type CustomAttributes, getDatabase } from './util/database';
import { setDatabase } from './util/database';

/**
 * Sets attributes for a Genesys chat conversation in the in-memory database.
 * Caution: The new customAttributes object will replace the existing one due to a shallow merge at the top level in setDatabase.
 *
 * This function does no-op when chat is not enabled.
 *
 * @returns The CustomAttributes object as stored in the in-memory database after the set operation.
 *
 * Note: This function must only be called after prepareBootstrapScriptConfig() has been executed. This prerequisite function sets up
 * the Genesys queue on the window object, which temporarily stores the queued calls.
 */
const setCustomAttributes = async (
  customAttributes: CustomAttributes,
): Promise<CustomAttributes> => {
  const database = await setDatabase({ messaging: { customAttributes } });

  return database.messaging.customAttributes;
};

/**
 * @see {@link file://./../../README.md} `Cache Solution` section for more information.
 */
let customAttributesCache: CustomAttributes = {};

/**
 * Add customAttributes value to customAttributesCache and call Database.set with the entire cache.
 *
 * This function can be invoked before Genesys is fully initialized. If the Genesys bootstrap script is still downloading or executing,
 * the call will be queued and executed once Genesys is ready.
 *
 * This function does no-op when chat is not enabled.
 *
 * @returns The CustomAttributes object as stored in the in-memory database after the update operation.
 *
 * Note: Ensure this function is called only after prepareBootstrapScriptConfig() has been executed, as this initial function
 * prepares the window object to store queued calls effectively.
 */
export const addCustomAttributes = async (
  customAttributes: CustomAttributes,
): Promise<CustomAttributes> => {
  return new Promise((resolve) => {
    /**
     * Subscribe to the database ready event then set the database
     * So that if Genesys is not ready, the calls are queued and executed when Genesys is ready
     * @see https://developer.genesys.cloud/commdigital/digital/webmessaging/messengersdk/SDKCommandsEvents/databasePlugin
     */
    subscribeToDatabaseReadyEvent(async () => {
      customAttributesCache = { ...customAttributesCache, ...customAttributes };
      resolve(await setCustomAttributes(customAttributesCache));
    });
  });
};

/**
 * Retrieves custom attributes from the Genesys "database".
 * This function does no-op when chat is not enabled.
 */
export const getCustomAttributes = async (): Promise<CustomAttributes> => {
  const database = await getDatabase();

  return database.messaging.customAttributes;
};
