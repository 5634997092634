import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ServicingFooter' })((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  contentWrapper: {
    padding: '32px 24px',
    maxWidth: 1072,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: '32px 16px',
    },
  },
  divider: {
    maxWidth: 1024,
    width: '100%',
  },
}));
