import * as yup from 'yup';

import { isFutureDate, isOlderThan } from '@ecp/utils/date';
import type { YupSchemaMap } from '@ecp/utils/form';

import { getBrandDataByType } from '@ecp/features/servicing/shared/util';

export interface VinUpdateFormInputs {
  vinNumber: string;
  effectiveDate: string;
}
const vinPattern = /^[A-HJ-NPR-Z0-9]*$/;
const brandData = getBrandDataByType('AUTO');

export const vinUpdateSchema: YupSchemaMap<VinUpdateFormInputs> = {
  vinNumber: yup
    .string()
    .required('Vehicle identification number required')
    .length(
      17,
      `Invalid VIN entered. A VIN contains 17 characters. If your VIN does not, call ${brandData?.mainCustServPhoneNum}`,
    )
    .matches(
      vinPattern,
      'Invalid VIN entered. A VIN cannot contain the letters I, O, and Q. Please recheck your entry',
    ),
  effectiveDate: yup
    .string()
    .nullable()
    .required('Effective date of change required')
    .test('Invalid date', 'Invalid date entered', (value) =>
      value ? value !== 'Invalid Date' : true,
    )
    .test('futureDate', 'Invalid date entered', (value) => (value ? !isFutureDate(value) : true))
    .test('PastMoreThanMonth', 'Invalid date entered', (value) =>
      value ? !isOlderThan(30, 'day', value) : true,
    ),
};
