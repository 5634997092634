import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PolicyDocumentsPage' })((theme) => ({
  root: {},
  docDeliveryPrefTop: {
    [theme.breakpoints.down('md')]: { display: 'none' },
    alignItems: 'normal',
  },
  mobileMenuItem: {
    [theme.breakpoints.up('md')]: { display: 'none' },
  },
  contactUsLinkText: theme.typography.body1Underline,
  subHeader: theme.typography.body1,
  container: {
    [theme.breakpoints.up('md')]: {
      '& > *': {
        width: '50%',
      },
    },
  },
  docTable: {
    '& td': {
      backgroundColor: theme.palette.primary.contrastText,
      padding: 16,
    },
    '& td:last-child': { padding: '0px 16px', textAlign: 'right' },
  },
  docTableWithDocuments: { '& tr:last-child': { display: 'none' } },
  autos: {
    alignSelf: 'flex-start',
    alignItems: 'center',
  },
  divider: {
    height: 24,
    margin: '0px 12px 0px 12px',
  },
  heading: { color: theme.palette.text.tertiary },
}));
