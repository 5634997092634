import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PartnerLogo' })((theme) => ({
  gridLogo: {
    display: 'flex',
    maxHeight: 75,
    maxWidth: 375,
    [theme.breakpoints.down('xl')]: {
      maxWidth: 210,
    },
    [theme.breakpoints.down('md')]: {
      maxHeight: 50,
      maxWidth: 210,
    },
  },
  divider: {
    margin: '0 20px',
    [theme.breakpoints.down('xl')]: {
      margin: '0 15px',
    },
    backgroundColor: theme.palette.grey[700],
    border: 'none',
    width: 1,
    height: 'auto',
  },
  cobrandedLogo: {
    display: 'flex',
    maxHeight: 75,
    maxWidth: 166,
    [theme.breakpoints.down('xl')]: {
      maxWidth: 90,
    },
    [theme.breakpoints.down('md')]: {
      maxHeight: 50,
      maxWidth: 90,
    },
  },
  image: {
    maxHeight: 75,
    maxWidth: 166,
    [theme.breakpoints.down('xl')]: {
      maxWidth: 166,
    },
    [theme.breakpoints.down('lg')]: {
      maxHeight: 50,
      maxWidth: 107,
    },
  },
}));
