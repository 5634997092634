import { useCallback, useMemo } from 'react';

import { Divider, Stack } from '@mui/material';
import dayjs from 'dayjs';

import { isTruthy, maskEmail, parseDollar } from '@ecp/utils/common';
import { DateConstants, formatDate } from '@ecp/utils/date';

import { Link } from '@ecp/components';
import type { OtherPoliciesEligibleProps } from '@ecp/features/servicing/shared/components';
import {
  AddressDisplay,
  CheckIcon,
  CloseButtonNAgentHelp,
  LoadingOverlay,
  OtherPoliciesEligible,
  PaymentDetails,
  PolicyCard,
} from '@ecp/features/servicing/shared/components';
import type { UseModalPathReturn } from '@ecp/features/servicing/shared/routing';
import { MODAL_FLOW, MODAL_STEP } from '@ecp/features/servicing/shared/routing';
import type { BillingResponse } from '@ecp/features/servicing/shared/state';
import {
  getBillingPlanDescription,
  getInsuredAddress,
  getPersonByRefId,
  useAmountDueNDueDateInfo,
  useBillingDetails,
  usePaymentInfoForAllPolicies,
  usePolicy,
  useSharedState,
} from '@ecp/features/servicing/shared/state';
import type { AutopayInformation } from '@ecp/features/servicing/shared/types';
import { IconUIMap } from '@ecp/themes/base';

import { useStyles } from './AutopayConfirmation.styles';

interface AutopayConfirmationProps extends UseModalPathReturn {
  payEnrollConfirm?: boolean;
}

export const AutopayConfirmation: React.FC<AutopayConfirmationProps> = (props) => {
  const { currentFlow, currentStep, payEnrollConfirm, policyNumber, sharedStateKey, init } = props;
  const { classes } = useStyles();

  const {
    paymentAmount: paymentAmountFromHook,
    paymentAmountDescription,
    dueDate,
    dueDateDescription,
    isLoading: isLoadingAmountDueNDueDateInfo,
    isFetching: isFetchingAmountDueNDueDateInfo,
  } = useAmountDueNDueDateInfo({
    policyNumber,
    modalFlow: currentFlow,
    modalStep: currentStep,
  });

  const { isLoading: isPolicyLoading, policyData: policyResponse } = usePolicy(policyNumber);
  const { paymentInfos, isLoading: isPaymentInfosLoading } = usePaymentInfoForAllPolicies({
    throwOnError: false,
  });
  const [sharedState] = useSharedState<AutopayInformation>(sharedStateKey);

  const policyBeingEnrolled = policyResponse?.policy;

  const billingAccountNumber = policyBeingEnrolled?.billingAccountNumber;

  const { accounts, isLoading: isBillingLoading } = useBillingDetails({
    billingAccountIds: billingAccountNumber ? [billingAccountNumber] : undefined,
  });
  const accountBeingEnrolled: BillingResponse | undefined = useMemo(() => {
    return accounts.find(
      (account) => account.billingAccountId === policyBeingEnrolled?.billingAccountNumber,
    );
  }, [accounts, policyBeingEnrolled?.billingAccountNumber]);

  const paymentAmount = paymentAmountFromHook
    ? parseDollar(paymentAmountFromHook)
    : paymentAmountDescription;

  const nextPaymentDate = dueDate
    ? formatDate(dueDate, DateConstants.MONTH_FULL_NAME_LONG_FORMAT)
    : '';

  const handleManageClick = useCallback(() => {
    policyNumber && init(policyNumber, MODAL_FLOW.AUTOPAY_MANAGE, MODAL_STEP.MODAL_LANDING);
  }, [policyNumber, init]);
  const isOtherPoliciesAutopayEligible = paymentInfos?.some(
    (paymentInfo) =>
      (paymentInfo.isManageAutopayEligible || paymentInfo.isAutopayEligible) &&
      paymentInfo.policyData?.policy.policyNumber !== policyNumber,
  );

  const pni = getPersonByRefId(policyResponse, policyBeingEnrolled?.primaryInsuredRefId);

  const title =
    currentFlow === 'autopayunenroll'
      ? 'You are no longer enrolled in AutoPay for the following policy'
      : `You've successfully enrolled the following policy in AutoPay`;

  const isLoading =
    isFetchingAmountDueNDueDateInfo ||
    isPolicyLoading ||
    isBillingLoading ||
    isPaymentInfosLoading ||
    isLoadingAmountDueNDueDateInfo;

  const showAmountDate =
    accountBeingEnrolled?.minimumDue &&
    accountBeingEnrolled?.minimumDue > 0 &&
    dayjs(accountBeingEnrolled?.dueDate).year() > 2000;

  const otherPolicies = useMemo(
    () =>
      paymentInfos
        ?.filter(
          ({ isAutopayEligible, policyData }) =>
            isAutopayEligible && policyData?.policy.policyNumber !== policyNumber,
        )
        ?.map((policyInfo) => policyInfo?.policyData)
        .filter(isTruthy),
    [paymentInfos, policyNumber],
  );

  const handleOtherClick = useCallback<NonNullable<OtherPoliciesEligibleProps['onClick']>>(
    (policyNumber) => init(policyNumber, MODAL_FLOW.AUTOPAY_ENROLL, MODAL_STEP.MODAL_EDIT),
    [init],
  );
  const confirmationMessage = (
    <p>
      {showAmountDate ? (
        <span>
          Your next payment of <b>{parseDollar(accountBeingEnrolled?.minimumDue)}</b> will be due{' '}
          <b>
            {formatDate(accountBeingEnrolled?.dueDate, DateConstants.MONTH_FULL_NAME_LONG_FORMAT)}
          </b>
          .{' '}
        </span>
      ) : (
        ''
      )}
      You can re-enroll your policy in AutoPay at any time.
    </p>
  );

  if (isLoading) return <LoadingOverlay />;

  return (
    <div className={classes.root}>
      <Stack spacing={5}>
        <Stack spacing={3}>
          {!payEnrollConfirm && <CheckIcon />}
          <Stack spacing={2}>
            <p className={classes.title}>{title}</p>
            {currentFlow !== 'autopayunenroll' ? (
              <PaymentDetails
                paymentAmount={paymentAmount}
                billingPlan={getBillingPlanDescription(accountBeingEnrolled?.billingPlan)}
                nextPaymentDate={nextPaymentDate}
                nextPaymentDateDescription={dueDateDescription}
                policyResponse={policyResponse}
                payEnrollConfirm
                paymentAccountMethod={sharedState?.paymentAccountMethod}
                footer={
                  payEnrollConfirm ? (
                    <p>
                      Notifications for upcoming automatic payments will be sent to{' '}
                      {maskEmail(pni?.email)}.
                    </p>
                  ) : (
                    ''
                  )
                }
                isLoading={isLoading}
              />
            ) : (
              <PolicyCard
                policyDetails={policyResponse}
                body={
                  <Stack spacing={2} className={classes.policyCard}>
                    <Divider />
                    <Stack direction='row' justifyItems='center' justifyContent='left'>
                      <IconUIMap className={classes.mapIcon} />
                      <div>
                        <AddressDisplay policyAddress={getInsuredAddress(policyResponse)} />
                      </div>
                    </Stack>
                  </Stack>
                }
              />
            )}
          </Stack>
        </Stack>
        {!payEnrollConfirm && (
          <>
            {currentFlow !== 'autopayunenroll' ? (
              <OtherPoliciesEligible
                otherPolicies={otherPolicies}
                onClick={handleOtherClick}
                title='Other policies that can be enrolled in AutoPay'
                buttonText='Enroll in AutoPay'
                showDivider
              />
            ) : (
              <Stack spacing={4}>
                {confirmationMessage}
                {isOtherPoliciesAutopayEligible && (
                  <p>
                    <Link component='button' onClick={handleManageClick}>
                      Manage your other policies here
                    </Link>{' '}
                    to unenroll or edit your AutoPay details.{' '}
                  </p>
                )}
              </Stack>
            )}
            <CloseButtonNAgentHelp />
          </>
        )}
      </Stack>
    </div>
  );
};
