import { apiRoot, buildRequestHeaders } from '../servicingApiConfig';
import type { ServicingResponse } from '../servicingRequest';
import { servicingRequest } from '../servicingRequest';
import type { PreferencesResponse, UpdatePreferencesRequest } from './types';

export const getPreferences = async (
  policyNumber: string,
): Promise<ServicingResponse<PreferencesResponse>> => {
  const headers = await buildRequestHeaders({ withUserToken: true });

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-policy/v1/policies/${policyNumber}/preferences`,
    options: {
      headers: headers,
      method: 'GET',
    },
  });
};

export const updatePreferenceByLabel = async (
  updatePreferencesRequest: UpdatePreferencesRequest,
): Promise<ServicingResponse<void>> => {
  const { policyNumber, preferenceLabel, ...body } = updatePreferencesRequest;

  const headers = await buildRequestHeaders({ withUserToken: true });

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-policy/v1/policies/${policyNumber}/preferences/${preferenceLabel}`,
    options: {
      headers: headers,
      method: 'PUT',
      body: JSON.stringify(body),
    },
  });
};
