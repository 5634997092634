import { forwardRef } from 'react';

import { useEvent } from '@ecp/utils/react';

// import from '.' is necessary for libs/utils/analytics/tracking/test-utils/src/mocks.ts to work
import { trackClick } from '.';
import type { AnalyticsRequest, ObjectType } from './types';

export interface TrackingProps {
  objectType?: ObjectType;
  trackingName?: AnalyticsRequest['action'];
  trackingLabel?: AnalyticsRequest['label'];
  trackingDimensions?: AnalyticsRequest['dimensions'];
}

interface CommonProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (...args: any[]) => any;
}

export const withAnalytics = <T extends CommonProps = CommonProps>(
  Component: React.ComponentType<T>,
  objectType?: ObjectType,
): React.ForwardRefExoticComponent<
  React.PropsWithoutRef<T & TrackingProps> & React.RefAttributes<T & TrackingProps>
> => {
  return forwardRef((props: T & TrackingProps, ref) => {
    const {
      onClick,
      objectType: objectTypeProp,
      trackingName,
      trackingLabel,
      trackingDimensions,
      ...rest
    } = props;

    const handleClick: T['onClick'] = useEvent((...args) => {
      trackClick({
        action: trackingName,
        label: trackingLabel,
        ...(trackingDimensions && { dimensions: trackingDimensions }),
        objectType: objectTypeProp ?? objectType,
      });
      if (onClick) return onClick(...args);
    });

    return <Component {...(rest as T)} ref={ref} onClick={handleClick} />;
  });
};
