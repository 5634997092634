import { useCallback, useMemo, useState } from 'react';

import { Divider, Stack } from '@mui/material';

import { DateConstants, formatDate } from '@ecp/utils/date';
import { Form, useField, useForm } from '@ecp/utils/form';

import { PhoneLink } from '@ecp/components';
import {
  CheckboxField,
  ElectronicSignatureField,
  LoadingButton,
  LoadingOverlay,
  ModalError,
} from '@ecp/features/servicing/shared/components';
import type { UseModalPathReturn as PaperlessEnrollReviewProps } from '@ecp/features/servicing/shared/routing';
import type { ErrorResponse } from '@ecp/features/servicing/shared/state';
import {
  getPolicyAddress,
  getPolicyDisplayType,
  useUpdatePaperless,
  useUserPolicies,
} from '@ecp/features/servicing/shared/state';
import { getBrandData } from '@ecp/features/servicing/shared/util';

import { LegalCopy } from './LegalCopy';
import { useStyles } from './PaperlessEnrollReview.styles';
import type {
  PaperlessEnrollReviewFormInputs,
  PaperlessEnrollReviewFormInputsCa,
} from './PaperlessEnrollReviewInputs.schema';
import {
  caPaperlessEnrollReviewSchema,
  paperlessEnrollReviewSchema,
} from './PaperlessEnrollReviewInputs.schema';

export const PaperlessEnrollReview: React.FC<PaperlessEnrollReviewProps> = (props) => {
  const { goForward, goBack, policyNumber } = props;
  const { classes } = useStyles();
  if (!policyNumber) {
    throw new Error('Cannot call preferences api without policyNumber');
  }
  const { updatePaperless, isLoading: isUpdatePaperlessLoading } = useUpdatePaperless(policyNumber);
  const {
    user,
    policies,
    isLoading: isPoliciesLoading,
  } = useUserPolicies({ throwOnPolicyError: false });
  const policyResponse = policies.find((policy) => policy.policy.policyNumber === policyNumber);

  const isLoading = isUpdatePaperlessLoading || isPoliciesLoading;
  const isCaliforniaState = policies.some((policy) => getPolicyAddress(policy)?.state === 'CA');
  const [submitError, setSubmitError] = useState<ErrorResponse>();

  const formContext = useForm({
    validations: isCaliforniaState ? caPaperlessEnrollReviewSchema : paperlessEnrollReviewSchema,
  });
  const consentField = useField<
    PaperlessEnrollReviewFormInputsCa | PaperlessEnrollReviewFormInputs
  >({
    formContext,
    name: 'consent',
  });
  const { handleSubmit } = formContext;

  const reviewPageLoadTime = useMemo(() => new Date().toISOString(), []);
  const brandData = getBrandData(policyResponse);

  const title = isCaliforniaState
    ? 'Voluntary Electronic Opt-in Consent and Disclosure'
    : 'Electronic Signature and Communications Disclosure and Consent';
  const helperText =
    'Please type your full name. This information serves as your legal signature and acknowledges the information provided on this page.';
  const onSubmit = useCallback(() => {
    try {
      handleSubmit(async (data) => {
        if (!data.electronicSignature) throw Error('Electronic signature is undefined');
        const todaysDateAndTime = new Date();
        const response = await updatePaperless({
          preferenceOption: 'EMAIL',
          acknowledgement: data.electronicSignature,
          requestedDateTime: reviewPageLoadTime,
          whenAccepted: formatDate(todaysDateAndTime.toString(), DateConstants.ANSWERS_FORMAT),
          consumerStep: 'Enroll in Paperless - Paperless Consent',
        });
        if (response.success) {
          goForward();
        } else if (response.error) {
          setSubmitError(response.error);
        }
      })();
    } catch (error) {
      goBack();
    }
  }, [goForward, goBack, handleSubmit, reviewPageLoadTime, updatePaperless]);

  const policyType = getPolicyDisplayType(policyResponse);
  const errorDesc = (
    <span>
      enrolling your{' '}
      <b>
        {policyType} policy {policyNumber}
      </b>{' '}
      in paperless delivery
    </span>
  );

  if (submitError)
    return (
      <ModalError
        errorTitle='enroll your policy in paperless delivery'
        errorDescription={errorDesc}
      />
    );

  return isLoading ? (
    <LoadingOverlay />
  ) : (
    <Form onSubmit={onSubmit} formProviderProps={formContext}>
      <Stack spacing={3}>
        <Stack className={classes.formBody} spacing={2}>
          <Stack spacing={1}>
            <span className={classes.title}>{title}</span>
            <Divider />
          </Stack>
          <p>
            To choose paperless enrollment and sign documents electronically you must read and
            consent to the following terms and conditions:
          </p>
          <LegalCopy policyResponse={policyResponse} isCaliforniaState={isCaliforniaState} />
          <Stack spacing={isCaliforniaState ? 3 : 2}>
            {isCaliforniaState ? (
              <Stack spacing={2}>
                <span>
                  For purposes of receiving electronic transmission of documents from{' '}
                  {brandData.uwCompanyCopyright}, as set forth above, my email address is:{' '}
                  <b>{user?.email}</b>
                </span>
                <span>
                  This is the email currently associated with your policy. To update it, call
                  <PhoneLink number={brandData.mainCustServPhoneNum} withUnderlinedLinkStyle />.
                </span>
                <CheckboxField
                  {...consentField}
                  checked={!!consentField.value}
                  label='I consent to receive electronic transmission of documents.'
                />
              </Stack>
            ) : (
              <span className={classes.signText}>
                I consent to the terms and conditions of this disclosure to receive Communications
                and sign documents electronically.
              </span>
            )}
            <ElectronicSignatureField helperText={isCaliforniaState && helperText} />
          </Stack>
        </Stack>
        <LoadingButton type='submit' className={classes.submitButtton}>
          Enroll in paperless
        </LoadingButton>
      </Stack>
    </Form>
  );
};
