import { memo } from 'react';

import { CircularProgress } from '@mui/material';

import { Card } from '@ecp/features/servicing/shared/components';
import { getProductLineFromPolicyResponse, usePolicy } from '@ecp/features/servicing/shared/state';
import type { UiPolicy } from '@ecp/features/servicing/shared/types';

import { useStyles } from './DiscountsSection.styles';
import { HomeDiscounts } from './HomeDiscounts';

interface Props {
  policyNumber: string;
}

// swap with real AutoDiscounts once we get proper requirements
const AutoDiscounts: React.FC<{ policyData: UiPolicy }> = () => (
  <div>Auto discounts not implemented yet</div>
);

export const DiscountsSection: React.FC<Props> = memo((props) => {
  const { policyNumber } = props;
  const { classes } = useStyles();

  const { policyData } = usePolicy(policyNumber);

  const productLine = getProductLineFromPolicyResponse(policyData);
  const isHome = productLine === 'HOME';
  const isAuto = productLine === 'AUTO';

  if (productLine && !isHome && !isAuto) {
    // shouldn't be called
    throw new Error(
      `Umbrella/unknown discounts not supported. Don't call this component for [${productLine}] productLine`,
    );
  }

  const Discounts = isHome ? HomeDiscounts : AutoDiscounts;

  return (
    <div>
      {!policyData ? (
        <CircularProgress aria-label='Loading...' />
      ) : (
        <Card className={classes.root} body={<Discounts policyData={policyData} />} />
      )}
    </div>
  );
});
