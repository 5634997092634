import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'MakePaymentEditPaymentClassic' })((theme) => ({
  root: {},
  backButton: {
    justifyContent: 'left',
  },
  groupLabelStack: { alignItems: 'center', paddingBottom: 0 },
  iconsSection: {
    background: theme.palette.other.backgroundContrastText,
    padding: 16,
    justifyContent: 'space-between',
  },
  iconTitle: {
    ...theme.typography.body1,
    alignSelf: 'center',
  },
  image: {
    maxHeight: 50,
  },
  imageWithPadding: {
    maxHeight: 50,
    [theme.breakpoints.up('md')]: {
      paddingRight: 25,
    },
  },
  spinner: { display: 'flex', justifyContent: 'center' },
}));
