import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'EnrollAutopay' })((theme) => ({
  root: {},
  cardContent: {
    padding: 16,
    '&:hover': {
      background: theme.palette.grey['200'],
    },
  },
  paymentBorder: { borderRadius: 8, border: `2px solid ${theme.palette.grey[600]}` },
  addBorder: { border: `2px solid ${theme.palette.primary.main}` },
  enrollAutopayLabel: theme.typography.h4,
}));
