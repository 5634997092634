import type { FocusEventHandler } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { Stack } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { addHyphensToCreditCardNumber, maskPaymentAccountNumber } from '@ecp/utils/common';
import { useField, useFormContext } from '@ecp/utils/form';
import { invalidCreditCardInputRegex } from '@ecp/utils/payment';

import { DatePicker, TextField } from '@ecp/components';
import {
  GraphicPaymentAmexImageUrl,
  GraphicPaymentDiscoverImageUrl,
  GraphicPaymentMastercardImageUrl,
  GraphicPaymentVisaImageUrl,
  IconUISecure,
} from '@ecp/themes/base';
import type { AnswerValue } from '@ecp/types';

import type { AddPaymentMethodInputs } from './AddCcPaymentMethod.schema';
import { useStyles } from './CardPaymentMethodFields.style';

const cardFormats = {
  default: {
    name: 'default',
    numberFormat: '####-####-####-####',
    securityCodeFormat: '###',
    securityCodePlaceholder: 'XXX',
  },
  amex: {
    name: 'amex',
    numberFormat: '####-######-#####',
    securityCodeFormat: '####',
    securityCodePlaceholder: 'XXXX',
  },
};

export const CardPaymentMethodFields: React.FC = () => {
  const { classes } = useStyles();
  const { control, resetField } = useFormContext<AddPaymentMethodInputs>();
  const [cardNumberInput, setCardNumberInput] = useState<AnswerValue>('');
  const [cardFormat, setCardFormat] = useState(cardFormats.default);

  // Removing "-" characters from input value
  const ccFormat = (value: string): string => {
    return value?.replaceAll('-', '').trim();
  };

  const expirationDateField = useField({
    name: 'expirationDate',
    control,
  });
  const nameField = useField({
    name: 'name',
    control,
  });
  const cardNumberField = useField({
    name: 'cardNumber',
    control,
    value: ccFormat(cardNumberInput as string),
  });

  useEffect(() => {
    // Check for Amex credit card and update format on the fly
    ['34', '37'].some((number) => cardNumberField?.value?.startsWith(number))
      ? setCardFormat(cardFormats.amex)
      : setCardFormat(cardFormats.default);
  }, [cardNumberField.value, resetField]);

  const verifyAccountNumberValue = cardNumberField.value;
  const [cardNumberDisplayValue, setCardNumberDisplayValue] = useState<string | undefined>(
    verifyAccountNumberValue ? maskPaymentAccountNumber(verifyAccountNumberValue) : undefined,
  );

  const handleCardNumberChange = useCallback<
    NonNullable<React.ComponentProps<typeof TextField>['actionOnChange']>
  >(
    (value) => {
      let numValueTrimmed = value.trim();

      if (invalidCreditCardInputRegex.test(numValueTrimmed)) {
        numValueTrimmed = numValueTrimmed.replaceAll(invalidCreditCardInputRegex, '');
      }
      let parsedValue = addHyphensToCreditCardNumber(numValueTrimmed);

      const maxLength = cardFormat.name === 'default' ? 19 : 17;
      parsedValue = parsedValue.substring(0, maxLength);

      setCardNumberDisplayValue(parsedValue);
      setCardNumberInput(parsedValue);
    },
    [cardFormat.name],
  );
  const handleBlurCreditCardNumber = useCallback<
    NonNullable<FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>>
  >((e) => {
    setCardNumberDisplayValue(maskPaymentAccountNumber(e.target.value));
  }, []);

  const handleFocusCreditCardNumber = useCallback<
    NonNullable<FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>>
  >(() => {
    setCardNumberDisplayValue(cardNumberInput?.toString());
  }, [cardNumberInput]);

  return (
    <Stack spacing={2} className={classes.root}>
      <Stack direction='row' className={classes.groupLabelStack} spacing={1}>
        <IconUISecure />
        <h2>Add a credit/debit card</h2>
      </Stack>
      <Stack spacing={1} className={classes.iconsSection} direction={{ sm: 'column', md: 'row' }}>
        <p className={classes.iconTitle}>Accepted Cards</p>
        <Stack direction='row' spacing={2}>
          <img className={classes.image} src={GraphicPaymentVisaImageUrl} alt='visa-card' />
          <img className={classes.image} src={GraphicPaymentMastercardImageUrl} alt='master-card' />
          <img className={classes.image} src={GraphicPaymentAmexImageUrl} alt='amex-card' />
          <img className={classes.image} src={GraphicPaymentDiscoverImageUrl} alt='discover-card' />
        </Stack>
      </Stack>
      <Stack spacing={3} className={classes.fields}>
        <TextField
          {...cardNumberField}
          placeholder='XXXX-XXXX-XXXX-XXXX'
          label='Card number'
          onBlur={handleBlurCreditCardNumber}
          onFocus={handleFocusCreditCardNumber}
          value={cardNumberDisplayValue ?? cardNumberField.value}
          actionOnChange={handleCardNumberChange}
          trackingName='card_number'
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
          type='tel'
          autoComplete='off'
        />
        <DatePicker
          {...expirationDateField}
          fullWidth
          hidePicker
          label='Expiration date'
          className={classes.label}
          format='MM/YY'
          placeholder='MM/YY'
          autoComplete='off'
        />
        {/* <NumberFormat
          // Hide CVV field as Payeezy not using it currently for Home, we will need to have it for E1P Auto
          {...securityCodeField}
          groupLabel='Security Code'
          placeholder={cardFormat.securityCodePlaceholder}
          type='tel'
          format={cardFormat.securityCodeFormat}
          autoComplete='off'
          trackingName='security_code'
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
        /> */}
        <TextField
          {...nameField}
          label='Name on card'
          placeholder='First and last name'
          autoComplete='off'
          trackingName='name_on_card'
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
        />
      </Stack>
    </Stack>
  );
};
