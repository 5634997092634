import type { Vehicle } from '@ecp/features/servicing/shared/types';

export const poiDocKeys = ['proof of insurance', 'proof of insurance cards'];

export const isPoiDoc = (title: string, vehicle: Vehicle): boolean => {
  const vinDigits = vehicle.vin.slice(-4);
  const titlePrefix = `Identification Card-${vehicle.year} ${vehicle.make}`;

  return (
    title === `${titlePrefix} ${vehicle.model} ${vinDigits}` ||
    (title.startsWith(titlePrefix) && title.endsWith(vinDigits)) ||
    poiDocKeys.includes(title.toLowerCase())
  );
};
