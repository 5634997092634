import { useCallback, useState } from 'react';

import { Stack } from '@mui/material';

import { Form, useForm } from '@ecp/utils/form';
import { getCreditCardType } from '@ecp/utils/payment';

import { Alert } from '@ecp/components';
import type { UseModalPathReturn as AddCcPaymentMethodProps } from '@ecp/features/servicing/shared/routing';
import {
  PAYMENT_TYPE,
  useCreatePaymentMethod,
  usePaymentOptions,
  usePolicy,
  useSharedState,
  useUser,
} from '@ecp/features/servicing/shared/state';
import type { CreatePaymentMethodError } from '@ecp/features/servicing/shared/state';
import type { BasePaymentInfo } from '@ecp/features/servicing/shared/types';
import { getBrandData } from '@ecp/features/servicing/shared/util';

import { LoadingOverlay } from '../LoadingOverlay';
import { PaymentsFormFooter } from '../PaymentsFormFooter';
import { AddCardPaymentMethodSchema } from './AddCcPaymentMethod.schema';
import { useStyles } from './AddCcPaymentMethod.styles';
import { CardPaymentMethodFields } from './CardPaymentMethodFields';

export const AddCcPaymentMethod: React.FC<AddCcPaymentMethodProps> = (props) => {
  const { goForward, sharedStateKey, policyNumber } = props;
  const [errorAlert, setErrorAlert] = useState<CreatePaymentMethodError>();
  const { createPaymentMethod } = useCreatePaymentMethod();
  const formContext = useForm({
    validations: AddCardPaymentMethodSchema,
  });
  const { user, isLoading: isUserLoading } = useUser();
  const { policyData: policyBeingPayed, isLoading: isPolicyLoading } = usePolicy(policyNumber);
  const {
    paymentOptions,
    clearCache: clearPaymentOptionsCache,
    isLoading: isPaymentOptionsLoading,
  } = usePaymentOptions({
    billingAccountNumber: policyBeingPayed?.policy.billingAccountNumber,
    policySource: policyBeingPayed?.policy.sourceSystemName,
  });

  const [sharedState, setSharedState] = useSharedState<BasePaymentInfo>(sharedStateKey);

  const { handleSubmit } = formContext;
  const onSubmit = useCallback(() => {
    handleSubmit(async (data) => {
      const cardType = getCreditCardType(data.cardNumber);
      const email = user?.email;

      if (policyNumber) {
        if (
          cardType &&
          email &&
          policyNumber &&
          policyBeingPayed?.policy.sourceSystemName &&
          paymentOptions
        ) {
          const { error, success } = await createPaymentMethod({
            paymentType: PAYMENT_TYPE.CC,
            fullName: data.name,
            cardExpirationDate: data.expirationDate,
            policyNumber,
            email,
            cardType,
            policySource: policyBeingPayed?.policy.sourceSystemName,
            paymentOptions,
            ...data,
          });
          if (error) {
            setErrorAlert(error);
          } else if (success) {
            clearPaymentOptionsCache?.();
            goForward();
            setSharedState({ ...(sharedState as BasePaymentInfo), isPaymentAdded: true });
          }
        }
      }
    })();
  }, [
    handleSubmit,
    createPaymentMethod,
    goForward,
    clearPaymentOptionsCache,
    setSharedState,
    paymentOptions,
    policyNumber,
    policyBeingPayed?.policy.sourceSystemName,
    sharedState,
    user?.email,
  ]);

  const { classes } = useStyles();
  const handleErrorAlertClose = useCallback(() => {
    setErrorAlert(undefined);
  }, []);
  const brandData = getBrandData(policyBeingPayed);

  let alertBody;
  if (errorAlert === 'Error fetching Payeezy CreditCardToken')
    alertBody = `We're sorry. We were unable to validate your card. Please review your information and
  try again.`;
  else
    alertBody = (
      <>
        <b>We were unable to set up your payment method.</b>
        <p>Please try entering the payment information again or contact us for assistance.</p>
        <b>{brandData.mainCustServPhoneNum}</b>
        {brandData.mainOperationHours.map((hours) => (
          <p key={hours}>{hours}</p>
        ))}
      </>
    );

  const isLoading = isUserLoading || isPolicyLoading || isPaymentOptionsLoading;
  if (isLoading) return <LoadingOverlay />;

  return (
    <div className={classes.root}>
      <Form onSubmit={onSubmit} formProviderProps={formContext} name='addPaymentMethodModal'>
        {errorAlert && (
          <Alert withIcon type='error' withAction onClose={handleErrorAlertClose}>
            {alertBody}
          </Alert>
        )}
        <Stack spacing={3}>
          <CardPaymentMethodFields />
          <PaymentsFormFooter
            buttonText='ADD CARD'
            trackingName='add_credit_card_button'
            trackingLabel='pay_continue'
          />
        </Stack>
      </Form>
    </div>
  );
};
