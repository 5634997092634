import type { LinkProps as MuiLinkProps } from '@mui/material';
import { Link as MuiLink } from '@mui/material';

import { withAnalytics } from '@ecp/utils/analytics/tracking';

import { useStyles } from './Link.styles';

type Props = Omit<MuiLinkProps, 'variant'> & {
  component?: React.ElementType;
};

export type LinkProps = React.ComponentPropsWithRef<typeof Link>;

export const Link = withAnalytics<Props>((props) => {
  const { onClick, className, component = 'a', title, ...rest } = props;
  const { classes, cx } = useStyles();

  return (
    <MuiLink
      aria-label={title}
      className={cx(classes.root, className)}
      onClick={onClick}
      component={component}
      title={title}
      tabIndex={0}
      {...rest}
    />
  );
}, 'link');
