export interface QueryStringParams {
  [key: string]: string;
}

export const makeUrl = ({
  url,
  params = {},
  method = 'GET',
  withCacheBuster = true,
}: {
  url: string;
  params?: QueryStringParams;
  method?: RequestInit['method'];
  withCacheBuster?: boolean;
}): string => {
  const getRequest = method === 'GET';

  // endpoint might have a query string or not
  let pathAndQuery = url;

  const hasQueryString = /\?/.test(url);
  pathAndQuery += hasQueryString ? '&' : '?';

  pathAndQuery = Object.entries(params).reduce((result, [key, value]) => {
    // TODO: this part will need to be enhanced to
    // support richer values, and if we need duplicate keys
    return `${result}${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
  }, pathAndQuery);

  if (getRequest && withCacheBuster) {
    // ensures browser will not cache the GET request
    const cacheBuster = +new Date();
    pathAndQuery += cacheBuster;

    return `${pathAndQuery}`;
  }

  // remove trailing & or ? since we did not add a cache buster
  return `${pathAndQuery.slice(0, -1)}`;
};
