import type { CoverageOptionData } from '@ecp/features/shared/coverages';

import type { AutoPolicyCoveragesGroup, VehicleCoveragesGroup } from './autoCoverageMap';
import type { HomeCoveragesGroup, RentersCoveragesGroup } from './homeCoverageMap';

export const compareByOrder = (list: string[], keyA: string, keyB: string): number => {
  return list.indexOf(keyA) - list.indexOf(keyB);
};

export const sortDeductibles = (
  coveragesList: CoverageOptionData[],
  orderList: string[],
): CoverageOptionData[] => {
  return coveragesList
    .filter((coverage) => orderList.includes(coverage.field.key))
    .sort((a, b) => compareByOrder(orderList, a.field.key, b.field.key));
};

export type CoveragesGroup =
  | AutoPolicyCoveragesGroup
  | VehicleCoveragesGroup
  | HomeCoveragesGroup
  | RentersCoveragesGroup;

export const groupAndSortCoverages = (
  coverageItems: CoverageOptionData[],
  orderMap:
    | Record<AutoPolicyCoveragesGroup, string[]>
    | Record<VehicleCoveragesGroup, string[]>
    | Record<HomeCoveragesGroup, string[]>
    | Record<RentersCoveragesGroup, string[]>,
): Record<CoveragesGroup, CoverageOptionData[]> => {
  const groupedCoverages = coverageItems?.reduce((acc, currentValue) => {
    const groupKey = currentValue.group as CoveragesGroup;
    if (!acc[groupKey]) {
      acc[groupKey] = [];
    }
    acc[groupKey].push(currentValue);

    return acc;
  }, {} as Record<CoveragesGroup, CoverageOptionData[]>);
  // Sort coverages inside groups by order mapping list
  Object.entries(groupedCoverages).map(([key, coverages]) =>
    coverages.sort((a, b) =>
      compareByOrder((orderMap as Record<CoveragesGroup, string[]>)[key], a.field.key, b.field.key),
    ),
  );

  return groupedCoverages;
};
