import { useParams } from 'react-router-dom';

import { TermsAndConditionsHomesite } from './homesite';

export const TermsAndConditionsPage: React.FC = () => {
  const { opco } = useParams();

  let termsAndConditions;
  switch (opco) {
    case 'homesite':
      termsAndConditions = <TermsAndConditionsHomesite />;
      break;
    default:
      termsAndConditions = <p>Partner Specific Terms & Conditions.</p>;
  }

  return termsAndConditions;
};
