export const QUERY_KEYS = {
  API_KEY: 'apiKey',
  AGENT_SUPPORT_USER_SEARCH: 'agentSupportUserSearch',
  AGENT_SUPPORT_ASSOCIATED_POLICY: 'agentSupportAssociatedPolicy',
  AGENT_SUPPORT_ACCOUNT_STATUS: 'agentSupportAccountStatus',
  BILLING_DETAILS: 'billingDetails',
  BILLINGACCOUNT_DOCUMENT: 'billingAccountDocument',
  BILLINGACCOUNT_DOCUMENTS: 'billingAccountDocuments',
  BILLINGACCOUNTS_DOCUMENTS: 'billingAccountsDocuments',
  CLAIMS: 'claims',
  ENV_CONFIG: 'envConfig',
  FAQ: 'faq',
  MAKE_PAYMENT_CLASSIC: 'makePaymentClassic',
  PAYMENT_OPTIONS: 'paymentOptions',
  PAYMENT_URL: 'paymentUrl',
  POLICY: 'policy',
  POLICY_DOCUMENT: 'policyDocument',
  POLICY_DOCUMENTS: 'policyDocuments',
  POLICIES_DOCUMENTS: 'policiesDocuments',
  POLICY_PAYMENTS: 'policyPayments',
  PREFERENCES: 'preferences',
  PREFERENCE: 'preference',
  SHARED: 'SHARED',
  USER: 'user',
  USER_POLICIES: 'userPolicies',
  VALIDATE_USER: 'validateUser',
};

export const localStorageKeys = {
  accountExistKey: 'account_exist',
  emailStorageKey: 'login/email',
};

export const errorCodeToRetry = new Set([
  408, // Request Timeout
  500, // Internal Server Error
  502, // Bad Gateway
  503, // Service Unavailable
  504, // Gateway Timeout
]);
