import * as yup from 'yup';

import type { YupSchemaMap } from '@ecp/utils/form';

import type { DateOfBirthSchema } from '@ecp/features/servicing/shared/util';
import { getDateOfBirthSchema } from '@ecp/features/servicing/shared/util';

export interface CreateAccountFormInputs extends DateOfBirthSchema {
  lastName?: string;
  policyNumber: string;
  zipCode?: string;
}

const zipCodePattern = /^\d{5}$/;
/* Regex to match only alphabetical characters and "'-." and white space characters.  Can not start with NLN
 and can't contain only special characters "'-.". Can't contain only white space characters.
Flags: global case insensitive */
const lastNamePattern = /^(?=^(?!nln)[A-Za-z'.-\s]*$)(?=.*[A-Za-z]).*$/i;
const banned = ['lastname'];

export const createAccountFormSchema: YupSchemaMap<CreateAccountFormInputs> = {
  lastName: yup
    .string()
    .required('Last name of the primary insured is required')
    .matches(lastNamePattern, 'Invalid Last Name provided')
    .test('lastname-banned', 'Invalid Last Name provided', (value) =>
      value ? !banned.includes(value.toLowerCase()) : true,
    )
    .max(30, 'Last name entry cannot exceed 30 characters'),
  ...getDateOfBirthSchema,
  policyNumber: yup.string().required('Policy number is required'),
  zipCode: yup
    .string()
    .required(`Mailing ZIP code is required`)
    .matches(zipCodePattern, 'ZIP code should contain 5 digits'),
};
