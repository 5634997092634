const addTransformPrefix = (property: string, value: string): string =>
  ['-webkit-', '-moz-', '-ms-', ''].map((prefix) => `${prefix}${property}: ${value}`).join(';');
const addAnimationPrefix = (property: string, value: string): string =>
  ['-webkit-', ''].map((prefix) => `${prefix}${property}: ${value}`).join(';');
const addKeyframes = (keyframeName: string, content: string): string =>
  ['@-webkit-keyframes', '@keyframes']
    .map((keyframesKeyword) => `${keyframesKeyword} ${keyframeName} ${content}`)
    .join('\n');

const rotateAnimationName = 'animation-61bdi0';
const strokeAnimationName = 'animation-1p2h4ri';

/**
 * When loading a document, we need to open a window, and show something indicating the app is loading/processing. Since we don't want to load
 * the entire application just for a loading spinner, this is a super lightweight way to achieve the desired spinner.
 *
 * This essentially emulates what we would get by rendering a <CircularProgress /> tag.
 * @see https://mui.com/material-ui/react-progress/#circular-indeterminate
 */
export const getDocLoadingHtml = (docTitle: string): string => {
  const css = `
    ${addKeyframes(
      rotateAnimationName,
      `{
        0%{${addTransformPrefix('transform', 'rotate(0deg)')}}
        100%{${addTransformPrefix('transform', 'rotate(360deg)')}}
      }`,
    )}
    ${addKeyframes(
      strokeAnimationName,
      `{
        0%{stroke-dasharray:1px,200px;stroke-dashoffset:0;}
        50%{stroke-dasharray:100px,200px;stroke-dashoffset:-15px;}
        100%{stroke-dasharray:100px,200px;stroke-dashoffset:-125px;}
      }`,
    )}`;

  const svgAnimationCss = addAnimationPrefix(
    'animation',
    `${strokeAnimationName} 1.4s ease-in-out infinite`,
  );
  const wrapperAnimationCss = addAnimationPrefix(
    'animation',
    `${rotateAnimationName} 1.4s linear infinite`,
  );
  const size = '128px';

  return `<html/>
            <title>Loading ${docTitle}</title>
            <style>${css}</style>
            <body>
              <div style='display: flex; justify-content: center; align-items: center; height: 100%'>
                <span style='${wrapperAnimationCss}; width: ${size}; height: ${size}; color: #222'>
                <svg viewBox="22 22 44 44"><circle style="${svgAnimationCss}; stroke: currentColor" cx="44" cy="44" r="20.2" fill="none" stroke-width="3.6"></circle></svg>
                </span>
              </div>
            </body>
          </html>`;
};
