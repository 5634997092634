/**
 * Any form element will have a property 'value' of type string,
 * some other elements (such as <li>) have a value property that is type number or other values
 * we'll ignore those cases for simplicity sake.
 */
export const isHTMLElementWithValue = (
  element: HTMLElement,
): element is HTMLElement & { value: string } => 'value' in element;

export const mergeRefs = <T = unknown>(
  ...refs: Array<React.MutableRefObject<T> | React.LegacyRef<T> | undefined>
): React.RefCallback<T> => {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
};

export function restrictSpaceChars(event: React.KeyboardEvent<HTMLInputElement>): void {
  if (event.key === ' ') {
    event.preventDefault();
  }
}
