import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ProfilePage' })((theme) => ({
  root: {},
  message: {
    position: 'fixed',
    top: 127,
    minWidth: 345,
    padding: '5px 10px',
    [theme.breakpoints.down('md')]: {
      top: 78,
      width: '100%',
    },
    '& > .MuiSnackbarContent-message': {
      margin: 'auto',
      padding: 0,
    },
  },
  messageText: theme.typography.body1,
  label: theme.typography.body1Bold,
  info: theme.typography.body3,
  cards: {
    listStyle: 'none',
    padding: 0,
    display: 'grid',
    gridAutoRows: 'auto',
    rowGap: 24,
    columnGap: 24,
    gridTemplateColumns: 'repeat(2, 1fr)',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  spinner: { display: 'flex', justifyContent: 'center' },
  editButton: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));
