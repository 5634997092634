import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'Section' })((theme) => ({
  root: {},

  sectionDivider: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));
