import { CircularProgress, Stack } from '@mui/material';

import { isOlderThan } from '@ecp/utils/date';

import { PhoneLink } from '@ecp/components';
import { Card, Section } from '@ecp/features/servicing/shared/components';
import {
  sortCanceledPolicies,
  sortPolicies,
  usePaymentInfoForAllPolicies,
  useUserPolicies,
} from '@ecp/features/servicing/shared/state';
import { getBrandDataWithFallback } from '@ecp/features/servicing/shared/util';

import { PolicyIconSummaryCard } from '../PolicyIconSummaryCard';
import { useStyles } from './PoliciesSection.styles';
import { PolicyErrorCard } from './PolicyErrorCard';

export const PoliciesSection: React.FC = () => {
  const { classes } = useStyles();

  const { paymentInfos, isLoading: isLoadingPaymentInfoForAllPolicies } =
    usePaymentInfoForAllPolicies({
      throwOnError: false,
    });
  const {
    user,
    policies,
    isLoading: isLoadingUserPolicies,
  } = useUserPolicies({ throwOnPolicyError: false });

  const isLoading = isLoadingPaymentInfoForAllPolicies || isLoadingUserPolicies;

  const sortedPolicies = sortPolicies(
    paymentInfos?.filter((pi) => !!pi.policyData).filter((pi) => !pi.policyData?.isInactive),
  );
  const sortedCanceledPolicies = sortCanceledPolicies(
    paymentInfos?.filter((pi) => pi.policyData?.isInactive),
  );

  const failedPolicies = isLoading
    ? []
    : user?.policyNumbers
        .filter((userPolicyNumber) =>
          sortedPolicies.every((policyInfo) => policyInfo.policy.policyNumber !== userPolicyNumber),
        )
        .filter((userPolicyNumber) =>
          sortedCanceledPolicies.every(
            (policyInfo) => policyInfo.policy.policyNumber !== userPolicyNumber,
          ),
        );

  const filterSortedCanceledPolicies = sortedCanceledPolicies.filter((sortedCanceledPolicy) =>
    policies.find(
      (policy) =>
        policy?.policy.policyNumber === sortedCanceledPolicy.policy.policyNumber &&
        !isOlderThan(18, 'months', policy?.policy.periodEndDate ?? ''),
    ),
  );

  return (
    <>
      <Section title='Your policies'>
        {isLoading && <CircularProgress aria-label='Loading...' />}
        <ul className={classes.policyCards}>
          {sortedPolicies.map((policyInfo) => (
            <li key={policyInfo?.policy.policyNumber}>
              <PolicyIconSummaryCard policyDetails={policyInfo} />
            </li>
          ))}
          {failedPolicies?.map((policyNumber) => (
            <li key={policyNumber}>
              <PolicyErrorCard policyNumber={policyNumber} key={policyNumber} />
            </li>
          ))}
          <li>
            <Card
              className={classes.noPoliciesCard}
              body={
                <Stack
                  spacing={1}
                  direction={{ xs: 'column', lg: 'row' }}
                  justifyContent='space-between'
                >
                  <p className={classes.noPoliciesCardTitle}>Not seeing your policy?</p>
                  <p>
                    Give us a call at{' '}
                    <PhoneLink
                      number={getBrandDataWithFallback().mainCustServPhoneNum}
                      withLinkStyle
                    />
                  </p>
                </Stack>
              }
            />
          </li>
        </ul>
      </Section>
      {!!filterSortedCanceledPolicies.length && (
        <Section title='Previous policies'>
          <ul className={classes.policyCards}>
            {filterSortedCanceledPolicies.map((policyInfo) => (
              <li key={policyInfo?.policy.policyNumber}>
                <PolicyIconSummaryCard policyDetails={policyInfo} />
              </li>
            ))}
          </ul>
        </Section>
      )}
    </>
  );
};
