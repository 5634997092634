import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CreatePasswordPage' })((theme) => ({
  root: {},
  emailLabel: theme.typography.body3,
  emailText: theme.typography.body4,
  button: {
    width: 'fit-content',
    [theme.breakpoints.down('md')]: { width: '100%' },
  },
  helpLink: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    fontWeight: 'bold',
  },
  navlink: {
    color: theme.palette.primary.dark,
  },
  textDecorationNone: {
    textDecoration: 'none',
  },
  createAccountLink: {
    paddingBottom: theme.spacing(5),
  },
  inputField: {
    maxWidth: 360,
  },
}));
