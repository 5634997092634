import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AuthenticationCard' })((theme) => ({
  body: {
    paddingTop: 40,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0px 24px 24px 24px',
    marginBottom: 'auto',
    '&:last-child': {
      paddingBottom: 24,
    },
  },
  linkContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backIcon: theme.mixins.setColorSvg(theme.palette.secondary.main),
  logo: {
    display: 'flex',
    justifyContent: 'center',
  },
  logoImage: {
    maxHeight: 50,
    maxWidth: 210,
  },
  banner: { padding: '16px 24px' },
}));
