import type {
  ClaimsPaths,
  DashboardPaths,
  DisclosurePaths,
  DisclosuresPaths,
  FaqPaths,
  GuestDisclosuresPaths,
  GuestTermsAndConditionsPaths,
  LogoutPaths,
  PageKey,
  PolicyPaths,
  ProfilePaths,
  TermsAndConditionsPaths,
} from '@ecp/features/servicing/shared/types';
export const URL_PARAM_PLACEHOLDER = '/:';

export const flowUrlPath = ':flow/:step';

export const PAGE_TEMPLATE = {
  AUTH: 'auth',
  DASHBOARD: 'dashboard',
  ERROR: 'error',
  GUEST: 'guest',
  MAINTENANCE: 'maintenance',
} as const;

export const PAGE_FLOW = {
  CREATE_ACCOUNT: 'createaccount',
  RECOVER_EMAIL: 'recoveremail',
  RECOVER_PASSWORD: 'recoverpassword',
  LOGIN: 'login',
} as const;

export const DASHBOARD_PATH: Record<DashboardPaths, string> = {
  DASHBOARD_INDEX: `/${PAGE_TEMPLATE.DASHBOARD}`,
  DASHBOARD_FLOW: `/${PAGE_TEMPLATE.DASHBOARD}/${flowUrlPath}`,
} as const;

export const POLICY_PATH: Record<PolicyPaths, string> = {
  DASHBOARD_POLICYDETAILS: `/${PAGE_TEMPLATE.DASHBOARD}/policy/:policyNumber`,
  DASHBOARD_POLICYDETAILS_FLOW: `/${PAGE_TEMPLATE.DASHBOARD}/policy/:policyNumber/${flowUrlPath}`,
  DASHBOARD_POLICY_DOCUMENTS: `/${PAGE_TEMPLATE.DASHBOARD}/policy/:policyNumber/documents`,
  DASHBOARD_POLICY_DOCUMENTS_FLOW: `/${PAGE_TEMPLATE.DASHBOARD}/policy/:policyNumber/documents/${flowUrlPath}`,
} as const;

export const CLAIMS_PATH: Record<ClaimsPaths, string> = {
  DASHBOARD_CLAIMS_OVERVIEW: `/${PAGE_TEMPLATE.DASHBOARD}/claims`,
} as const;

export const DISCLOSURES_PATH: Record<DisclosuresPaths, string> = {
  DISCLOSURES_EMAIL_FRAUD: `/${PAGE_TEMPLATE.DASHBOARD}/disclosures/emailfraud/:opco`,
  DISCLOSURES_LEGALNOTICES: `/${PAGE_TEMPLATE.DASHBOARD}/disclosures/legalnotices/:opco`,
  DISCLOSURES_PRIVACYPOLICY: `/${PAGE_TEMPLATE.DASHBOARD}/disclosures/privacypolicy/:opco`,
  DISCLOSURES_PROTECTYOURINFORMATION: `/${PAGE_TEMPLATE.DASHBOARD}/disclosures/protect/:opco`,
  DISCLOSURES_PROTECTYOURSELF: `/${PAGE_TEMPLATE.DASHBOARD}/disclosures/protectyourself/:opco`,
  DISCLOSURES_TERMSANDCONDITIONS: `/${PAGE_TEMPLATE.DASHBOARD}/disclosures/terms/:opco`,
  DISCLOSURES_CLAIMSTEXTING: `/${PAGE_TEMPLATE.DASHBOARD}/disclosures/claimstexting/:opco`,
  DISCLOSURES_TERMSOFUSE: `/${PAGE_TEMPLATE.DASHBOARD}/disclosures/termsofuse/:opco`,
  DISCLOSURES_UNDERWRITING: `/${PAGE_TEMPLATE.DASHBOARD}/disclosures/underwriting/:opco`,
} as const;

export const TERMSANDCONDITIONS_PATH: Record<TermsAndConditionsPaths, string> = {
  TERMSANDCONDITIONS_CLAIMSTEXTING: `${DISCLOSURES_PATH.DISCLOSURES_TERMSANDCONDITIONS}/claimstexting`,
  TERMSANDCONDITIONS_BILLINGTEXTING: `${DISCLOSURES_PATH.DISCLOSURES_TERMSANDCONDITIONS}/billingtexting`,
  TERMSANDCONDITIONS_OFFERINGTEXTING: `${DISCLOSURES_PATH.DISCLOSURES_TERMSANDCONDITIONS}/offeringtexting`,
} as const;
export const GUEST_DISCLOSURES_PATH: Record<GuestDisclosuresPaths, string> = {
  GUEST_DISCLOSURES_EMAIL_FRAUD: `/${PAGE_TEMPLATE.GUEST}/disclosures/emailfraud/:opco`,
  GUEST_DISCLOSURES_LEGALNOTICES: `/${PAGE_TEMPLATE.GUEST}/disclosures/legalnotices/:opco`,
  GUEST_DISCLOSURES_PRIVACYPOLICY: `/${PAGE_TEMPLATE.GUEST}/disclosures/privacypolicy/:opco`,
  GUEST_DISCLOSURES_PROTECTYOURINFORMATION: `/${PAGE_TEMPLATE.GUEST}/disclosures/protect/:opco`,
  GUEST_DISCLOSURES_PROTECTYOURSELF: `/${PAGE_TEMPLATE.GUEST}/disclosures/protectyourself/:opco`,
  GUEST_DISCLOSURES_CLAIMSTEXTING: `/${PAGE_TEMPLATE.GUEST}/disclosures/claimstexting/:opco`,
  GUEST_DISCLOSURES_TERMSANDCONDITIONS: `/${PAGE_TEMPLATE.GUEST}/disclosures/terms/:opco`,
  GUEST_DISCLOSURES_TERMSOFUSE: `/${PAGE_TEMPLATE.GUEST}/disclosures/termsofuse/:opco`,
  GUEST_DISCLOSURES_UNDERWRITING: `/${PAGE_TEMPLATE.GUEST}/disclosures/underwriting/:opco`,
} as const;

export const GUEST_TERMSANDCONDITIONS_PATH: Record<GuestTermsAndConditionsPaths, string> = {
  GUEST_TERMSANDCONDITIONS_CLAIMSTEXTING: `${GUEST_DISCLOSURES_PATH.GUEST_DISCLOSURES_TERMSANDCONDITIONS}/claimstexting`,
  GUEST_TERMSANDCONDITIONS_BILLINGTEXTING: `${GUEST_DISCLOSURES_PATH.GUEST_DISCLOSURES_TERMSANDCONDITIONS}/billingtexting`,
  GUEST_TERMSANDCONDITIONS_OFFERINGTEXTING: `${GUEST_DISCLOSURES_PATH.GUEST_DISCLOSURES_TERMSANDCONDITIONS}/offeringtexting`,
} as const;

export const FAQ_PATH: Record<FaqPaths, string> = {
  FAQ: `/${PAGE_TEMPLATE.DASHBOARD}/faq`,
} as const;

const DISCLOSURE_PATHS: Record<DisclosurePaths, string> = {
  DISCLOSURES: `/${PAGE_TEMPLATE.DASHBOARD}/disclosures`,
  GUEST_DISCLOSURES: `/${PAGE_TEMPLATE.GUEST}/disclosures`,
} as const;

const LOGOUT_PATH: Record<LogoutPaths, string> = {
  LOGOUT: `/logout`,
} as const;

export const PROFILE_PATH: Record<ProfilePaths, string> = {
  PROFILE: `/${PAGE_TEMPLATE.DASHBOARD}/profile`,
  PROFILE_FLOW: `/${PAGE_TEMPLATE.DASHBOARD}/profile/${flowUrlPath}`,
} as const;

export const QUERY_PARAMS = {
  CONTACT_US: 'contactus',
  OKTA_LOGIN: 'oktaLoginId',
  POLICY_NUMBER: 'policyNumber',
  PREFERENCES: 'preferences',
  SEARCH_FOR: 'searchFor',
  TIMEOUT: 'timeout',
  TYPE: 'type',
  VEHICLE_ID: 'vehicleId',
  AUTOPAY_ENROLL: 'autopayEnroll',
  THIRD_PARTY_INTEREST_ROLE: 'thirdPartyInterestRole',
} as const;

export const QUERY_PARAMS_AND_VALUE = {
  CONTACT_US_TRUE: `${QUERY_PARAMS.CONTACT_US}=true`,
  PREFERENCES_TRUE: `${QUERY_PARAMS.PREFERENCES}=true`,
  TIMEOUT_TRUE: `${QUERY_PARAMS.TIMEOUT}=true`,
};

export const PAGE_PATH: Record<PageKey, string> = {
  INDEX: '/',
  // maintenance path
  MAINTENANCE: `${PAGE_TEMPLATE.MAINTENANCE}`,
  // auth template
  AUTH_INDEX: `/${PAGE_TEMPLATE.AUTH}/`,
  GUEST_INDEX: `/${PAGE_TEMPLATE.GUEST}/`,
  CONTACT_SUPPORT: `/${PAGE_TEMPLATE.AUTH}/contactsupport`, // TODO: Contact Support is supposed to point to Help page and would need to be updated once available.
  CONTACTUS: `/${PAGE_TEMPLATE.AUTH}/contactus`,
  CALLBACK_WORLDPAY: `/callback/worldpay`,
  // create account paths
  LOGIN: `/${PAGE_TEMPLATE.AUTH}/${PAGE_FLOW.LOGIN}`,
  CREATE_ACCOUNT: `/${PAGE_TEMPLATE.AUTH}/${PAGE_FLOW.CREATE_ACCOUNT}`,
  CREATE_ACCOUNT_CREATE_PASSWORD: `/${PAGE_TEMPLATE.AUTH}/${PAGE_FLOW.CREATE_ACCOUNT}/createpassword`,
  CREATE_ACCOUNT_ONE_TIME_CODE: `/${PAGE_TEMPLATE.AUTH}/${PAGE_FLOW.CREATE_ACCOUNT}/onetimecode`,
  CREATE_ACCOUNT_VERIFY_IDENTITY: `/${PAGE_TEMPLATE.AUTH}/${PAGE_FLOW.CREATE_ACCOUNT}/verifyidentity`,
  // recover email paths
  RECOVER_EMAIL: `/${PAGE_TEMPLATE.AUTH}/${PAGE_FLOW.RECOVER_EMAIL}`,
  RECOVER_EMAIL_ONE_TIME_CODE: `/${PAGE_TEMPLATE.AUTH}/${PAGE_FLOW.RECOVER_EMAIL}/onetimecode`,
  RECOVER_EMAIL_VERIFY_IDENTITY: `/${PAGE_TEMPLATE.AUTH}/${PAGE_FLOW.RECOVER_EMAIL}/verifyidentity`,
  RECOVER_EMAIL_ENTER_PASSWORD: `/${PAGE_TEMPLATE.AUTH}/${PAGE_FLOW.RECOVER_EMAIL}/enterpassword`,
  RECOVER_EMAIL_RESET_PASSWORD: `/${PAGE_TEMPLATE.AUTH}/${PAGE_FLOW.RECOVER_EMAIL}/updatepassword`,
  // recover password paths
  RECOVER_PASSWORD: `/${PAGE_TEMPLATE.AUTH}/${PAGE_FLOW.RECOVER_PASSWORD}`,
  RECOVER_PASSWORD_ONE_TIME_CODE: `/${PAGE_TEMPLATE.AUTH}/${PAGE_FLOW.RECOVER_PASSWORD}/onetimecode`,
  RECOVER_PASSWORD_VERIFY_IDENTITY: `/${PAGE_TEMPLATE.AUTH}/${PAGE_FLOW.RECOVER_PASSWORD}/verifyidentity`,
  RECOVER_PASSWORD_RESET_PASSWORD: `/${PAGE_TEMPLATE.AUTH}/${PAGE_FLOW.RECOVER_PASSWORD}/updatepassword`,
  ...POLICY_PATH,
  // DASHBOARD_PATH has placeholders/params, so needs to be after POLICY_PATH since the patterns collide
  ...DASHBOARD_PATH,
  ...CLAIMS_PATH,
  ...DISCLOSURES_PATH,
  ...DISCLOSURE_PATHS,
  ...FAQ_PATH,
  ...LOGOUT_PATH,
  ...PROFILE_PATH,
  ...GUEST_DISCLOSURES_PATH,
  ...GUEST_TERMSANDCONDITIONS_PATH,
  ...TERMSANDCONDITIONS_PATH,
};

export const PAGE_MAP = {
  Claims: CLAIMS_PATH,
  'Help & support': FAQ_PATH,
  'Log out': LOGOUT_PATH,
  Policies: POLICY_PATH,
  'My profile': PROFILE_PATH,
  Overview: DASHBOARD_PATH,
} as const;

// this is used to look up page path keys by compiled pathnames
export const INVERTED_PAGE_PATH = Object.entries(PAGE_PATH).reduce(
  (pathObject, [key, value]) => ({
    ...pathObject,
    [value]: key,
  }),
  {} as Record<(typeof PAGE_PATH)[PageKey], PageKey>,
);
