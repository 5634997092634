import { memo } from 'react';

import { Stack } from '@mui/material';

import { PhoneLink } from '@ecp/components';
import type { BrandProduct } from '@ecp/features/servicing/shared/brand-data';
import { Card } from '@ecp/features/servicing/shared/components';
import { getBrandDataByType } from '@ecp/features/servicing/shared/util';
import { IconCardAutomaticPayments } from '@ecp/themes/base';

import { useStyles } from './ManageAutopayCard.styles';

export const ManageAutopayCard: React.FC = memo(() => {
  const { classes } = useStyles();
  const customerServicePhone = (getBrandDataByType('AUTO') as BrandProduct).mainCustServPhoneNum;
  const body = (
    <Stack spacing={2}>
      <Stack spacing={1} direction='row' alignItems='center'>
        <div>
          <IconCardAutomaticPayments className={classes.icon} />
        </div>
        <h3>Sign up for AutoPay</h3>
      </Stack>
      <p>
        Make life a little easier. Never miss a payment or risk a lapse in coverage. To enroll, call{' '}
        <PhoneLink withUnderlinedLinkStyle number={customerServicePhone} />.
      </p>
    </Stack>
  );

  return <Card className={classes.root} body={body} divider={false} />;
});
