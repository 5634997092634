import type { PaletteOverrides } from '@ecp/themes/base';

/**
 *  Temporary workaround for: https://github.com/microsoft/TypeScript/issues/41247.
 * Remove after TS 4.7.0 upgrade:
 * https://github.com/microsoft/TypeScript/issues/47920
 * https://www.staging-typescript.org/play?ts=4.7.0-pr-46827-30#code/C4TwDgpgBA0hIGcoF4oHICGaoB90CNs80BjI9AEzQG4AoWgegagGEALCEgaymDY2BQMAG2FQwAJwD2kCaChd4SElIB2AMykSAtrylQEU7dFCRaK1QkGHjABWlgEAeXVxEKKAG9aUX0IBcUAAMADQ+fviBAEQcolJRYX5QAB6BABxQTFAAoskkEAhIkjIQciBQAJZI6sIYAOZ1EBS0AL4GAlXqFQVQthhyFSIAPABKnFoUQ24IIVAArqpcqlIA7qoAfOt0jMwAKuDQFRpa2h1qUFLqUBAYJGziDqXyVVCnR8AYR020NhD2Ms5XEoAHQYYHAKQAMQqySaAAoAJR0X7-RwuabA-DAhBzfBWCRHOpwgCMSJ2UAAysAKqIhKprhJpBJZmgqJUkNoqghCeY1IZhBBgcIpESUQ5ARiKGTyexODw+AIhLTirJ5Ip3P1oJIChBVMBeZZBCJhKiJUoPN4khhAqFwr5IlAYhA4gk7VASIFgBI5hBWu1qQguj0xioJJNprMFktVhtqJlmBR2a8uTysvtIJVjjozvSJBAPl9mgKjR5jab0SCwRDobCKIi6MWoPhS6Jy0DEJjsbj8STpVlZdxePwjcrHgMegA3EQ+91qTQ6PQGIwmA60UzQSG3YBIVCeKAAbXVgXxhIAuoF8FIpAKMPSWnQKJxanmoOoFiRqec7nLdt6+Ag4VSKBNw-BAEUCCcpAqCgHyfTVX3fT96W-bgAEkEAAQRWDBFEAwI9yqLCcIgC8rxvO9wKgSDoLoCwrBSC03UI7DFE9b1fTaBAOkDbokBA7c6CyAAhKQ+CgJwYHMDhuF-OZ-0AskUK4dCiNw5IySybJGS0WYjkfZIDAqOpVAEOYXwoKQClUNBBBIPMBGgfp8AqL1+nKFUnl4g1+UFYUiWSYEAC8NOYABZKpTmAb9Ew8soqOnQ5qlqBpvjowRkgAJkYpJtDYn1Ej8BBonUEQEAgKI-S4gMgz4rcEG2LIMOWPhSmuZIMG0MABVoCBkjALRBHXVhry0C0oAkQJVDmbR8FKWY6km6bZuZJtFpm1r73JDDaUuIcdQMNgpDmYRE1m4bhQkGYmzkqBUIuVRhHKOYyqXYwLiuFrdA4PNgR6vqBtnQ1ehEfNgGgXc3RWNgXJIrxxsCDKAFZEfmhHkdmB0kcRqAWgK+1am4fD4eCVGSagCgbRx2YsiGABaC4AQAQkobAjnEAnoF2tBCDdfBhB9ImJrJhaycx5Gqcq7iaqgEMJiGE9VDqWYWBGiQtm869fJFOE+mLMHMX5iAQuGxlOGAR7X2SxpEwwJBSiZDWBSFbXddB3yjLYYAAH0zK6o3tnJVC9VKEr8kqTqBWMPUczXA4oFCqQpz58GvDdbRE4gOEKCqD5VHyNblso6iYNaWi+Vs-pssKj45EROHcbTjOs4RVOkiSLJEwQQ7jtO6ApvWiQ3QbpIrBkOu9xaSXqt4+PE4wZOoAAMhl8Yw3lr1CUjRZljWdWsjbg+D4APRP0+z-Pi+z-JQ+b98KJtuFFY2vyQoHhKccEAqmVpJ4GQkJEN+qpyjriQFDGkWo8xlQkBOQkDwdR50OMcWOGZbBQT1BlRMu4UgFzmlAEAOCJA4zLkDbQIBUHvDGkBYkQQcb+k6DPPoAxhjkPQRQdWaVXhkLQcAISGBMFwyAlEahURaFVXoT0Rh1JmHcIwXvZgElZjLCgMzVQEAmhNCgN9X0dFNbOyJKQlhwBgQBWrDCeECJjYUm7idBkDtGxgA8AY7hwIQANWYCrYOyRgBzAAaYHkQ17BNAqCQByO44b7iKgYDeitzxQDhKoAhLdkDrCbGRG4d5tgcO1BQSJgTs4hLBmEy0fgqjZAnLqSaKAUn0gAKRQCysgRpJMmLOAoBTKA9JkkdKgHUhpTTiRT3EUgPJwTQl0CAA
 */
const satisfies = <T extends PaletteOverrides>(p: T): T => p;

export const palette = satisfies({
  actions: {
    disabledBg: '#eaeaea',
    disabledText: '#78777b',
  },
  common: {
    black: '#000000',
    shim: '#22222266',
    white: '#ffffff',
    white15: '#ffffff26',
  },
  error: {
    contrastText: '#ffffff',
    dark: '#9e181f',
    darkText: '#222222',
    light: '#fee0e0',
    main: '#d32029',
  },
  grey: {
    '50': '#ffffff',
    '100': '#fafafa',
    '200': '#f2f2f2',
    '300': '#eaeaea',
    '400': '#d4d4d4',
    '500': '#cccccc',
    '600': '#afaeb0',
    '700': '#78777b',
    '800': '#4c4c4c',
    '900': '#222222',
  },
  icon: {
    dark: '#222222',
    default: '#ffffff',
    hover: '#2d5ebb',
    stroke: '#78777b',
  },
  info: {
    contrastText: '#ffffff',
    dark: '#2853a4',
    darkText: '#222222',
    light: '#e1e8f5',
    main: '#2d5ebb',
  },
  other: {
    backdrop: '#22222266',
    background: '#2d5ebb',
    backgroundContrastText: '#ffffff',
    body: '#ffffff',
    border: '#d4d4d4',
    caution: '#a9670c',
    cookieBanner: '#ffffff',
    divider: '#d4d4d4',
    genesysChatBg: '#2853a4',
    genesysChatIcon: '#ffffff',
    snackbar: '#4c4c4c',
    tooltip: '#4c4c4c',
  },
  partner: {
    footerBackground: '#eaeaea',
    footerTextLink: '#2d5ebb',
    footerTextPrimary: '#222222',
    footerTextSecondary: '#4c4c4c',
    headerBackground: '#ffffff',
  },
  primary: {
    background: '#f7f9fc',
    border: '#96aedd',
    contrastText: '#ffffff',
    dark: '#2853a4',
    darkText: '#2853a4',
    light: '#ebf0f9',
    main: '#2d5ebb',
  },
  secondary: {
    background: '#f0f4f7',
    border: '#89aaaa',
    contrastText: '#ffffff',
    dark: '#0f4243',
    darkText: '#0f4243',
    light: '#f0f4f7',
    main: '#145556',
  },
  shadow: {
    primary: '#00000033',
  },
  showcase: {
    color: '#44444426',
  },
  success: {
    complete: '#ffffff',
    contrastText: '#ffffff',
    dark: '#006a00',
    darkText: '#222222',
    light: '#dff0d8',
    main: '#008a00',
  },
  text: {
    disabled: '#78777b',
    hover: '#2853a4',
    link: '#2d5ebb',
    primary: '#222222',
    secondary: '#4c4c4c',
    tertiary: '#6f6e72',
  },
  warning: {
    contrastText: '#ffffff',
    dark: '#a36200',
    darkText: '#222222',
    light: '#fdf1d9',
    main: '#ff9800',
  },
} as const);
