import { useCallback, useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import type {
  ArrayPath,
  Control,
  FieldArray,
  FieldValues,
  Path,
  PathValue,
  RefCallBack,
  Resolver,
  UseFieldArrayProps,
  UseFieldArrayReturn,
  UseFormClearErrors,
  UseFormProps,
  UseFormReturn,
} from 'react-hook-form';
import {
  useController,
  useFieldArray as useRhfFieldArray,
  useForm as useRhfForm,
  useFormContext as useRhfFormContext,
} from 'react-hook-form';
import * as yup from 'yup';

import { useEvent } from '@ecp/utils/react';

import type { AnswerValue } from '@ecp/types';

export type YupSchemaMap<TFieldValues> = Record<keyof TFieldValues, yup.AnySchema>;

export type ValidationContext<TFieldValues extends FieldValues> = {
  formValues: TFieldValues;
};

export interface UseFieldOptions<TFieldValues extends FieldValues, AnswerType> {
  actionOnChange?: (value: AnswerType) => void;
  actionOnComplete?: (value: AnswerType) => void;
  control?: Control<TFieldValues>;
  defaultValue?: AnswerType;
  name: Path<TFieldValues>;
  value?: AnswerType;
  formContext?: UseFormReturn<TFieldValues, ValidationContext<FieldValues>, TFieldValues>;
}

interface UseFieldReturn<AnswerType> {
  actionOnChange?(value: AnswerType): void;
  actionOnComplete?(value: AnswerType): void;
  error?: string;
  name: string;
  ref: RefCallBack;
  value: AnswerType;
}

export function useFormContext<TFieldValues extends FieldValues>(): UseFormReturn<TFieldValues> {
  return useRhfFormContext<TFieldValues>();
}

export type FormInputs = Record<string, yup.AnySchema>;

/** Small helper method for consistency in order to get Name for register form
 when policy yup.number is used as key */
export const getFieldName = (...fieldParts: string[]): string => {
  const fieldPartsString = fieldParts.map((fieldPart) => {
    return /^\d+$/.test(fieldPart) ? `_${fieldPart}` : fieldPart;
  });

  return fieldPartsString.join('.');
};

export const createNestedSchema = (
  path: string,
  yupObject: yup.AnySchema,
): YupSchemaMap<FormInputs> => {
  const reversePath = path.split('.').reverse();
  const currentNestedObject = reversePath.slice(1).reduce(
    (yupObj, path) => {
      return { [path]: yup.object().shape(yupObj) };
    },
    { [reversePath[0]]: yupObject },
  );

  return currentNestedObject;
};

/**
 * name - Input's name being registered and this name should be the same as in the validation shema.
 * The way React-hook-form register names is:
 * ***Input Name*************SubmitResult*********************
 *
 *    input:                 Submit result:
 *    "firstName"	           {firstName: 'value'}
 *    "name.firstName"	     {name: { firstName: 'value' }}
 *    "name.firstName.0"	   {name: { firstName: [ 'value' ] }}
 *
 * According to this logic if one of the keys is numeric valule then react-hook-form will create
 * an array instead of object and can cause a potential bug, let's follow the rule for naming convetion
 * and avoid using numbers as keys when register input fieds.
 */

export const useField = <
  TFieldValues extends FieldValues = FieldValues,
  AnswerType extends AnswerValue = string,
>({
  actionOnChange: actionOnChangeProp,
  actionOnComplete: actionOnCompleteProp,
  control: controlProp,
  defaultValue: defaultValueProp,
  name,
  value: savedValue,
  formContext: contextProp,
}: UseFieldOptions<TFieldValues, AnswerType>): UseFieldReturn<AnswerType> => {
  const rhfContext = useRhfFormContext<TFieldValues>();
  const context = contextProp ?? rhfContext;
  if (!context) {
    throw Error(
      'Cannot use `useField` without a context. Either pass in context or hook must be nested inside of a `Form`',
    );
  }
  const control = controlProp ?? context.control;

  if (!name) throw new Error('"name" cannot be undefined!');

  const defaultValue = (savedValue ?? defaultValueProp) as PathValue<
    TFieldValues,
    Path<TFieldValues>
  >;
  const {
    field: { onChange: onRhfChange, onBlur: onRhfBlur, ref, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
  });

  useEffect(() => {
    // "savedValue" or "defaultValue" could be asynchronous fetched, such as on page refresh
    if (defaultValue) {
      context.resetField(name, { defaultValue });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  // combine handlers so we can have our own, plus RHF
  const actionOnComplete = useEvent((value: AnswerType) => {
    const submitCount = context.formState.submitCount;
    //* * Added timeout to be able click on links outside of form without validation errors https://theexperimentationlab.atlassian.net/browse/POL-2883 */
    setTimeout(() => {
      // since we are delaying execution of the blur/complete event which fixes issues of screen shift when errors show/hide, but causes other issues we need to address here
      // execution order matters and since blur validation is now not guaranteed to be the same as submit validation, we need to "not valid on blur" if the user has directly clicked submit button
      // this "defers" to the submit validation and won't undue/clear validations
      const hasSubmittedSinceBlur = context.formState.submitCount !== submitCount;
      if (!hasSubmittedSinceBlur) {
        value || context.getFieldState(name).error ? onRhfBlur() : context.clearErrors(name);
      }
      actionOnCompleteProp?.((value ?? '') as AnswerType);
    }, 50);
  });
  const actionOnChange = useEvent((value: AnswerType) => {
    onRhfChange(value);
    actionOnChangeProp?.((value ?? '') as AnswerType);
  });

  const errorText = error?.message;

  // reference stable
  return useMemo(
    () => ({
      actionOnChange,
      actionOnComplete,
      error: errorText,
      name,
      ref,
      value,
    }),
    [errorText, name, actionOnComplete, actionOnChange, ref, value],
  );
};

export const useValidationContext = <TFieldValues extends FieldValues>(
  formValues: TFieldValues,
): ValidationContext<TFieldValues> => {
  return {
    formValues,
  };
};

/**
 * wrap `useFieldArray` to use formcontext's control
 */
export function useFieldArray<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends ArrayPath<TFieldValues> = ArrayPath<TFieldValues>,
>(
  props: UseFieldArrayProps<TFieldValues, TFieldArrayName> & {
    savedValue?:
      | FieldArray<TFieldValues, TFieldArrayName>
      | FieldArray<TFieldValues, TFieldArrayName>[];
  },
): UseFieldArrayReturn<TFieldValues, TFieldArrayName> {
  const { control: controlProp, savedValue } = props;
  const context = useRhfFormContext<TFieldValues>();
  const control = controlProp ?? context.control;

  const rhfFieldArray = useRhfFieldArray<TFieldValues, TFieldArrayName>({
    ...props,
    control,
  });

  useEffect(() => {
    // "initialValue" could be asynchronous fetched, such as on page refresh
    if (savedValue) {
      rhfFieldArray.replace(savedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedValue]);

  return rhfFieldArray;
}

export type UseFormOptions<TFieldValues extends FieldValues> = UseFormProps<
  TFieldValues,
  object
> & {
  resolver?: Resolver;
  validations?: YupSchemaMap<TFieldValues>;
};

/**
 * wrap `useForm` to set defaults and simplify adding validations
 */
export function useForm<TFieldValues extends FieldValues = FieldValues>(
  // providing more strict/narrowed typing for useForm at each caller is recommended
  config: UseFormOptions<TFieldValues> = {},
): UseFormReturn<TFieldValues, ValidationContext<FieldValues>, TFieldValues> {
  const resolver =
    config.resolver || (config.validations && yupResolver(yup.object().shape(config.validations)));

  // This is a custom validation context that will be passed on to yup resolver
  const [formValues, setFormValues] = useState<TFieldValues>({} as TFieldValues);

  // Allows our yup context to have access to the current form values, quote context, etc..
  const validationContext = useValidationContext<TFieldValues>(formValues);

  // IMP: Even though there is no context being passed, it is important
  // to spread config.context so that typescript can identify the right types
  const context: ValidationContext<FieldValues> = { ...config.context, ...validationContext };

  // Note: defaultValues useful for setting up useFieldArray e.g. DriverHistory
  const useFormReturn = useRhfForm<TFieldValues, ValidationContext<FieldValues>, TFieldValues>({
    // some forms might want the validation mode to be
    // something else other than onBlur e.g. DriverHitory
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    delayError: 1,
    resolver,
    ...config,
    context,
  });

  // Subscribe to RHF values, so we can inject them into context.
  // This is generally used for validations that require current form values.
  useEffect(() => {
    const { unsubscribe } = useFormReturn.watch((values) => {
      setFormValues(values as TFieldValues);
    });

    return unsubscribe; // this automatically unsubscribes after the form component is unmounted.
  }, [useFormReturn]);

  return useFormReturn;
}

// RHF creates nested values for fields with name having '.' in them. So, fn to work around
export const getUnNestedValues = (fieldValues: FieldValues): FieldValues => {
  const unNest = (result: FieldValues, fieldValues: FieldValues, prefix: string[] = []): void => {
    Object.entries(fieldValues).forEach(([key, val]) => {
      if (typeof val === 'object' && !Array.isArray(val)) unNest(result, val, [...prefix, key]);
      else result[[...prefix, key].join('.')] = val;
    });
  };
  const result = {};
  unNest(result, fieldValues);

  return result;
};

/**
 * similar to RHF reset, except it just sets the values and conditionally clears errors
 */
interface UseFormValuesReturn<TFieldValues> {
  setFormValues: (
    fieldValues: Partial<TFieldValues>,
    options?: { shouldClearErrors?: boolean },
  ) => void;
}

export const useFormValues = <
  TFieldValues extends FieldValues = FieldValues,
>(): UseFormValuesReturn<TFieldValues> => {
  const { clearErrors, setValue } = useRhfFormContext<TFieldValues>();

  return {
    setFormValues: useCallback(
      (fieldValues, { shouldClearErrors } = { shouldClearErrors: true }) =>
        (
          Object.entries(fieldValues) as [keyof TFieldValues, TFieldValues[keyof TFieldValues]][]
        ).forEach(([key, value]) => {
          setValue(key as Path<TFieldValues>, value);
          shouldClearErrors && clearErrors(key as Path<TFieldValues>);
        }),
      [clearErrors, setValue],
    ),
  };
};

interface UseFormErrorsReturn<TFieldValues extends FieldValues> {
  clearErrors: UseFormClearErrors<TFieldValues>;
  setFormErrors: (fieldErrors: Partial<TFieldValues>) => void;
}

export const useFormErrors = <
  TFieldValues extends FieldValues = FieldValues,
>(): UseFormErrorsReturn<TFieldValues> => {
  const { clearErrors, setError } = useRhfFormContext<TFieldValues>();

  return {
    clearErrors,
    setFormErrors: useCallback(
      (fieldErrors) => {
        Object.keys(fieldErrors).forEach((key) => {
          if (fieldErrors[key].trim()) {
            setError(key as Path<TFieldValues>, {
              type: 'manual',
              message: fieldErrors[key],
            });
          }
        });
      },
      [setError],
    ),
  };
};
