import { Stack } from '@mui/material';

import { NavLink } from '@ecp/features/servicing/shared/components';
import { generateHelpPath, PAGE_PATH } from '@ecp/features/servicing/shared/routing';

import { DisclosureWrapper } from '../../../components';
import { useStyles } from './BillingTextingHomesite.styles';
export const BillingTextingHomesite: React.FC = () => {
  const { classes } = useStyles();

  return (
    <DisclosureWrapper
      title='Homesite Group Incorporated'
      breadcrumbTitleOverrides={{
        terms: 'Terms & Conditions',
        billingtexting: 'Insurance Update Texting',
      }}
    >
      <Stack spacing={4} className={classes.root}>
        <Stack spacing={1}>
          <h2>Insurance Update Texting Terms and Conditions</h2>
          <Stack spacing={1}>
            <h3>GENERAL INFORMATION</h3>
            <p>
              Homesite Group Incorporated, including its subsidiaries and affiliates, (collectively,
              "Homesite") would like to welcome you to our text messaging program. Homesite
              Insurance Update is an ongoing text messaging program that will provide you with
              information regarding your Homesite policy account such as billing, service alerts,
              marketing and other periodic account information. The message frequency may vary.
            </p>
            <p>
              Homesite does not have a separate charge for this service, but{' '}
              <b>message and data rates may apply</b> from your mobile carrier. By providing your
              consent to participate in this program, the account holder approves any such charges
              from their mobile carrier.
            </p>
            <p>
              By participating in this program, you specifically authorize Homesite to send
              telemarketing communications including mobile text alerts and automated texts using an
              automatic telephone dialing system or an artificial or prerecorded voice system.{' '}
              <b>
                You are not required to consent to the agreement as a condition of purchasing any
                property, goods or services.
              </b>{' '}
              The person consenting to receive telemarketing communications also provides
              authorization for the specific telephone number that may be contacted via this
              program.
            </p>
          </Stack>
        </Stack>
        <Stack spacing={1}>
          <h3>HOW TO OPT-IN</h3>
          <Stack spacing={1}>
            <p>
              You may sign up for the Homesite text messaging program by accessing your online
              account through our website.
            </p>
            <p>
              After signing up, you will receive an invitation text message asking you to confirm
              your enrollment request. The message states: "Homesite Insurance Update: To confirm
              receiving SMS alerts, Reply JOIN. Up to 10msg/mo. Msg&Data rates may apply. Reply HELP
              for help. Reply STOP to cancel." This message is sent in order to confirm that the
              cell phone number you provided belongs to you.
            </p>
            <p>
              <b>Reply YES to the invitation text message to complete your enrollment.</b> If you do
              not wish to complete your enrollment, do not respond to the invitation text message.
              If no affirmative response is received, you will be automatically un-enrolled.
            </p>
          </Stack>
        </Stack>
        <Stack spacing={1}>
          <h3>HOW TO OPT-OUT</h3>
          <p>
            To stop receiving text messages reply <b>STOP, END, QUIT, CANCEL or UNSUBSCRIBE.</b>
          </p>
          <p>
            By enrolling in this subscription program, you consent that following such a request to
            unsubscribe, you will receive a standard rate message stating: "Homesite Insurance
            Update: You have been unsubscribed and will no longer receive text messages from us."
          </p>
          <p>
            We reserve the right to terminate this text messaging service, in whole or in part, at
            any time without notice. The information in any text message may be subject to certain
            time lags and/or delays. You may stop receiving text messages at any time by sending{' '}
            <b>STOP, END, QUIT, CANCEL or UNSUBSCRIBE</b> to short code "70699". You may also
            opt-out by logging into your online account through our website.
          </p>
        </Stack>
        <Stack spacing={1}>
          <h3>HOW TO GET HELP</h3>
          <p>
            For additional information, text <b>HELP</b> to short code "70699". You may also receive{' '}
            <b>HELP</b> by calling 1-833-517-0583, or through your online account through our
            website.
          </p>
        </Stack>
        <Stack spacing={1}>
          <h3>SUPPORTED CARRIERS</h3>
          <p>
            Carriers are not liable for delayed or undelivered messages. Compatible carriers
            include:
          </p>
          <p>
            AT&T, Sprint, T-Mobile®, Verizon Wireless, Boost, Cricket, MetroPCS, U.S. Cellular,
            Virgin Mobile, ACS Wireless, Appalachian Wireless, Bluegrass Cellular, Carolina West
            Wireless, Cellcom, C-Spire Wireless (formerly Cellsouth), Cellular One of East Central
            Illinois, Cincinnati Bell Wireless, Cross (dba Sprocket), Duet IP, Element Mobile,
            EpicTouch, GCI Communications, Golden State, Hawkeye (Chat Mobility), Hawkeye (NW
            Missouri Cellular), Illinois Valley Cellular, Immix (Keystone Wireless / PC Management),
            Inland Cellular, iWireless, Mobi PCS (Coral Wireless LLC), Mosaic, MTPCS / Cellular One
            (Cellone Nation), Nex-Tech Wireless, nTelos, Panhandle Telecommunications, Peoples
            Wireless, Pioneer, Plateau, Revol Wireless, Rina - Custer, Rina - All West, Rina -
            Cambridge Telecom Coop, Rina - Eagle Valley Comm, Rina - Farmers Mutual Telephone Co,
            Rina - Nucla Nutria Telephone Co, Rina - Silver Star, Rina - South Central Comm, Rina -
            Syringa, Rina - UBET, Rina - Manti, South Canaan / CellularOne of NEPA, Thumb Cellular,
            Union Wireless, United, Viaero Wireless, West Central Wireless, Leaco, Nemont/Sagebrush.
          </p>
          <p>T-Mobile® is not liable for delayed or undelivered messages</p>
        </Stack>
        <Stack spacing={1}>
          <h3>PRIVACY</h3>
          <p>
            All information collected from users of our sites is subject to our Privacy Policy,
            which is incorporated by reference into this agreement. For more information, visit our{' '}
            <NavLink to={generateHelpPath(PAGE_PATH.DISCLOSURES_PRIVACYPOLICY, 'homesite')}>
              Privacy Policy
            </NavLink>
          </p>
        </Stack>
      </Stack>
    </DisclosureWrapper>
  );
};
