import { emptyObject } from '@ecp/utils/common';

import type { Person, Vehicle } from '@ecp/features/servicing/shared/types';

export const getVehicleDescription = (
  { year, make, model }: Vehicle = emptyObject as Vehicle,
): string => [year, make, model].filter((a) => a).join(' ');

export const getDriverDescription = ({ firstName, lastName }: Person): string =>
  [firstName, lastName].filter((a) => a).join(' ');
