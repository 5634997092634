import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutoAdjustableGrid' })((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(var(--max-items-per-row), var(--item-width))',
    columnGap: `var(--spacing)`,
    rowGap: `var(--spacing)`,
    '& > *': {
      marginRight: 0,
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));
