import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CoveragesSection' })((theme) => ({
  root: {},
  classicPolicyTitle: {
    paddingBottom: 10,
    borderBottom: `4px solid ${theme.palette.primary.main}`,
  },
  classicPolicySection: {
    backgroundColor: theme.palette.primary.background,
    padding: '20px 24px',
  },
  link: { whiteSpace: 'nowrap' },
  coveragesSection: {
    backgroundColor: theme.palette.grey[100],
    padding: '24px 20px',
    borderTop: `4px solid ${theme.palette.primary.main}`,
  },
}));
