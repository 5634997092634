import { useEffect } from 'react';

import { Stack } from '@mui/material';

import {
  MakePaymentButton,
  PaymentDue,
  PolicyCard,
} from '@ecp/features/servicing/shared/components';
import type { UseModalPathReturn } from '@ecp/features/servicing/shared/routing';
import type { BillingResponse } from '@ecp/features/servicing/shared/state';
import { useBillingDetails, usePolicy } from '@ecp/features/servicing/shared/state';

import { useStyles } from './AutopayEditEnrollPaymentDue.styles';

export const AutopayEditEnrollPaymentDue: React.FC<UseModalPathReturn> = (props) => {
  const { policyNumber } = props;
  const { classes } = useStyles();

  const { policyData: policyResponse } = usePolicy(policyNumber);
  const policyBeingEnrolled = policyResponse?.policy;

  const billingAccountNumber = policyBeingEnrolled?.billingAccountNumber;

  const { accounts, refetch: refetchBilling } = useBillingDetails({
    billingAccountIds: billingAccountNumber ? [billingAccountNumber] : undefined,
  });

  useEffect(() => {
    refetchBilling();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const accountBeingEnrolled: BillingResponse | undefined = accounts.find(
    (account) => account.billingAccountId === policyBeingEnrolled?.billingAccountNumber,
  );

  return (
    <Stack spacing={3}>
      <p className={classes.text}>
        To successfully enroll in AutoPay, you cannot have a current balance due.
      </p>
      <p className={classes.text}>
        You'll be asked to make a payment before finalizing your AutoPay enrollment.
      </p>
      <PolicyCard
        policyDetails={policyResponse}
        body={
          <div className={classes.bodyWrapper}>
            <Stack
              spacing={3}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent='space-between'
              align-items='center'
              className={classes.paymentBorder}
            >
              <PaymentDue billingAccount={accountBeingEnrolled} />

              {policyNumber && (
                <MakePaymentButton
                  policyNumber={policyNumber}
                  buttonName='Pay and Enroll'
                  classes={{ button: classes.button }}
                  redirectFromAutopayEnroll
                />
              )}
            </Stack>
          </div>
        }
      />
    </Stack>
  );
};
