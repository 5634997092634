import type { Address, UiPolicy } from '@ecp/features/servicing/shared/types';

import { getDwellingAddress } from './getDwellingAddress';

export const getInsuredAddress = (policyResponse: UiPolicy | undefined): Address | undefined => {
  if (!policyResponse) return undefined;
  const insuredAddress = policyResponse.addresses.find((address) =>
    address.addressType?.includes('INSURED'),
  );

  return insuredAddress || getDwellingAddress(policyResponse);
};
