import { CircularProgress, Divider, Stack } from '@mui/material';

import { PhoneLink } from '@ecp/components';
import { Card } from '@ecp/features/servicing/shared/components';
import { useUserPolicies } from '@ecp/features/servicing/shared/state';
import { getBrandDataWithFallback } from '@ecp/features/servicing/shared/util';

import { useStyles } from './RightRail.styles';

interface Props {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
}

export const RightRail: React.FC<Props> = (props) => {
  const { classes } = useStyles(undefined, { props });
  const brandData = getBrandDataWithFallback('AUTO');
  const claimPhoneNum = brandData.claimPhoneNum;
  const glassClaimPhoneNum = brandData.glassClaimPhoneNum;
  const homeLockoutPhoneNum = brandData.homeLockoutPhoneNum;
  const roadsideAssistancePhoneNum = brandData.roadsideAssistancePhoneNum;
  const { policies, isLoading } = useUserPolicies({ throwOnPolicyError: false });
  const isCostcoExecutiveMember = policies.some(
    (p) => p.policy.isCostcoMember && p.policy.costcoMembershipTier === 'EXECUTIVE',
  );
  const hasAutoPolicies = policies.some(
    (policy) => policy.coverageOptions[0].productLine === 'AUTO',
  );

  if (isLoading) return <CircularProgress aria-label='Loading...' />;

  return (
    <Stack spacing={2} className={classes.root}>
      <h2>Contact a claims agent</h2>
      <Card
        body={
          <Stack spacing={1} divider={<Divider />}>
            {hasAutoPolicies && glassClaimPhoneNum && (
              <div>
                <div className={classes.itemTitle}>Glass Claims</div>
                <PhoneLink withUnderlinedLinkStyle number={glassClaimPhoneNum} />
              </div>
            )}
            {hasAutoPolicies && roadsideAssistancePhoneNum && (
              <div>
                <div className={classes.itemTitle}>Roadside</div>
                <PhoneLink withUnderlinedLinkStyle number={roadsideAssistancePhoneNum} />
              </div>
            )}
            <div>
              <div className={classes.itemTitle}>Claims</div>
              <PhoneLink withUnderlinedLinkStyle number={claimPhoneNum} />
            </div>
            {homeLockoutPhoneNum && isCostcoExecutiveMember && (
              <div>
                <div className={classes.itemTitle}>Home Lockout</div>
                <PhoneLink withUnderlinedLinkStyle number={homeLockoutPhoneNum} />
              </div>
            )}
          </Stack>
        }
      />
    </Stack>
  );
};
