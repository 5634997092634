import { useMemo } from 'react';

import type { PathMatch } from 'react-router-dom';
import { matchPath, useLocation } from 'react-router-dom';

import { isTruthy } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';

import { PAGE_PATH } from './constants';

export const useCurrentRoute = (): PathMatch => {
  const { pathname } = useLocation();

  const currentRoute = useMemo(
    () =>
      Object.values(PAGE_PATH)
        .map((route) => matchPath(route, pathname))
        .find(isTruthy),
    [pathname],
  );

  if (!currentRoute?.pathname) {
    datadogLog({
      logType: 'info',
      message: 'Invalid url path',
      context: {
        logOrigin: 'libs/features/servicing/shared/routing/src/useCurrentRoute.ts',
        functionOrigin: 'useCurrentRoute',
      },
    });

    return {
      params: {},
      pathname: PAGE_PATH.DASHBOARD_INDEX,
      pathnameBase: PAGE_PATH.DASHBOARD_INDEX,
      pattern: {
        path: PAGE_PATH.DASHBOARD_INDEX,
      },
    };
  }

  return currentRoute;
};
