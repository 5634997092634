import { useState } from 'react';

import { useEvent } from '@ecp/utils/react';

import {
  IconUICheckCircleSolid,
  IconUIClose,
  IconUIExclaimTriangle,
  IconUIInfoCircle,
} from '@ecp/themes/base';

import { Button } from '../Button';
import { useStyles } from './Alert.styles';

export type AlertType = 'default' | 'error' | 'info' | 'success' | 'warning';

interface Props {
  className?: string;
  hideOnClose?: boolean;
  icon?: React.ReactElement;
  id?: string;
  onClose?: () => unknown;
  type?: AlertType;
  withAction?: boolean;
  withIcon?: boolean;
}

export const Alert: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    children,
    className,
    hideOnClose,
    id,
    icon,
    onClose,
    type = 'default',
    withAction,
    withIcon,
  } = props;
  const { classes, cx } = useStyles();

  const [internalOpen, setInternalOpen] = useState(true);
  const alertOpen = !hideOnClose || internalOpen;

  const handleOnClose = useEvent(() => {
    setInternalOpen(false);
    onClose?.();
  });

  if (!alertOpen) return null;

  let iconAlert: React.ReactElement;
  switch (type) {
    case 'error':
    case 'warning':
      iconAlert = <IconUIExclaimTriangle className={classes.icon} title='Exclaim triangle icon' />;
      break;
    case 'info':
    case 'default':
      iconAlert = <IconUIInfoCircle className={classes.icon} title='Info circle icon' />;
      break;
    case 'success':
      iconAlert = <IconUICheckCircleSolid className={classes.icon} title='Check circle icon' />;
      break;
  }

  const AlertIcon: React.FC = () => icon || iconAlert;

  return (
    <div
      className={cx(classes.root, classes[type], className)}
      {...(type === 'error' ? { role: 'alert' } : { 'aria-live': 'polite' })}
    >
      {withIcon && <AlertIcon />}
      <div className={classes.message}>{children}</div>
      {withAction && (
        <Button
          variant='iconText'
          className={classes.closeButton}
          onClick={handleOnClose}
          aria-label='Close alert'
          id={id}
        >
          <IconUIClose className={classes.closeIcon} />
        </Button>
      )}
    </div>
  );
};
