import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'StartYourQoute' })((theme) => ({
  root: {},
  titleLine: {
    color: theme.palette.primary.contrastText,
    '&.MuiDivider-root': { backgroundColor: 'inherit' },
    '&.MuiDivider-root::before, &.MuiDivider-root::after': {
      borderTop: `1px solid ${theme.palette.primary.contrastText}`,
      width: 75,
    },
  },
  cardContainer: {
    display: 'flex',
    width: '100%',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    paddingTop: 120,
    alignSelf: 'center',
  },
  contactUs: {
    ...theme.typography.body2,
    paddingTop: 10,
    color: theme.palette.primary.contrastText,
    zIndex: 100,
  },
  contactUsLink: {
    ...theme.typography.body2,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      color: theme.palette.primary.contrastText,
    },
    textDecoration: 'underline',
    textAlign: 'center',
    zIndex: 100,
  },
}));
