import { isPastDate } from '@ecp/utils/date';
import { datadogLog } from '@ecp/utils/logger';

import type { BillingResponse } from '@ecp/features/servicing/shared/state';
import type { PaymentStatus } from '@ecp/features/servicing/shared/types';

export const getPaymentStatus = (
  billingInfo: BillingResponse | undefined,
  isPendingCancellation?: boolean,
  isPaymentSubmitted?: boolean,
): PaymentStatus | undefined => {
  let paymentStatus: PaymentStatus | undefined;
  if (!billingInfo) return paymentStatus;

  const { minimumDue, fullPayBalance, dueDate } = billingInfo;

  if (
    (minimumDue !== undefined && minimumDue < 0) ||
    (fullPayBalance !== undefined && fullPayBalance < 0)
  ) {
    paymentStatus = 'NegativePaymentBalance';
  } else if (fullPayBalance === 0 && !isPaymentSubmitted) {
    // If payment is submitted it doesn't set PaidInFull status for current session
    paymentStatus = 'PaidInFull';
  } else if (minimumDue === 0) {
    paymentStatus = 'NoPaymentDue';
  } else if (isPendingCancellation) {
    paymentStatus = 'PendingCancellation';
  } else if (
    dueDate !== undefined &&
    isPastDate(dueDate) &&
    minimumDue !== undefined &&
    minimumDue > 0
  ) {
    paymentStatus = 'PastDue';
  } else if (minimumDue !== undefined && minimumDue > 0) {
    paymentStatus = 'Due';
  }

  if (!paymentStatus) {
    datadogLog({
      logType: 'error',
      message: 'Billing information is not enough to get payment status',
      context: {
        logOrigin: 'libs/features/servicing/shared/state/src/util/getPaymentStatus.ts',
        functionOrigin: 'getPaymentStatus',
        fullPayBalance,
        minimumDue,
        dueDate,
      },
    });
  }

  return paymentStatus;
};
