import { Stack } from '@mui/material';

import { NavLink } from '@ecp/features/servicing/shared/components';
import { generateHelpPath, PAGE_PATH } from '@ecp/features/servicing/shared/routing';

import { DisclosureWrapper } from '../../../components';
import { useStyles } from './EmailFraudConnect.styles';

export const EmailFraudConnect: React.FC = () => {
  const { classes } = useStyles();

  return (
    <DisclosureWrapper
      title='About Email Fraud'
      breadcrumbTitleOverrides={{ emailfraud: 'Email Fraud' }}
    >
      <Stack spacing={4}>
        <Stack spacing={1}>
          <h2>Our email security practices</h2>
          <p>
            Being aware of the email security practices used by CONNECT can help you distinguish a
            legitimate email message from a fraudulent one.
          </p>
        </Stack>

        <Stack spacing={2}>
          <Stack spacing={1}>
            <h3>What we do</h3>
            <p>
              Send you emails with links to connectbyamfam.com or the service site to access certain
              features and information. These links are provided for your convenience, but you can
              also type the URL directly into your browser.
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>What we won't do</h3>
            <p>
              Ask you to send personal information (e.g. password, Social Security number, account
              numbers, mother's maiden name, etc.) through email.
            </p>
          </Stack>
        </Stack>
        <Stack spacing={1}>
          <h2>What is email fraud?</h2>
          <p>
            Email fraud – or phishing – refers to phony emails that appear to be from well-known
            companies.
          </p>
          <p>Phishing emails:</p>
          <ul>
            <li>
              Appear to come from legitimate businesses such as financial institutions, insurance
              companies or retailers and often include seemingly authentic logos, links or graphics
              to make them look legitimate.
            </li>
            <li>
              Are designed to deceive you into providing personal, financial or account information
              – such as account user names, passwords, credit card information and Social Security
              numbers.
            </li>
            <li>
              May attempt to deceive you into downloading an attachment or clicking on a link that
              will download malware onto your computer or device to illicitly obtain personal and
              financial information.
            </li>
          </ul>
        </Stack>
        <Stack spacing={1}>
          <h2>How to spot email fraud</h2>
          <ul>
            <li>
              <span className={classes.bodyBold}>Sense of urgency: </span>Messages often try to
              'bait' you by stating there is an urgent situation concerning your account and
              instructing you to take immediate action – such as clicking a link to go to a fake
              website to 'update' or 'validate' personal information.
            </li>
            <li>
              <span className={classes.bodyBold}>Spelling or grammatical errors: </span>You may see
              obvious spelling errors that are purposely included to help avoid spam filters and
              deliver the fraudulent email to your inbox.
            </li>
            <li>
              <span className={classes.bodyBold}>Fraudulent email addresses: </span>The name of the
              sender will appear to be legitimate – such as the name of a prominent business – but
              the email address will not be associated with that business or will contain additional
              or missing letters/numbers.
            </li>
          </ul>
        </Stack>
        <Stack spacing={1}>
          <h2>How to report email fraud</h2>
          <p>If you suspect you've received a fraudulent email, please:</p>
          <ul>
            <li>Forward it to us immediately at: anti.fraud@connectbyamfam.com.</li>
            <li>
              Do not remove the original subject line or change the email in any way when
              forwarding.
            </li>
          </ul>
          <p>If we confirm the email is fraudulent, we will take appropriate action immediately.</p>
        </Stack>
        <Stack spacing={1}>
          <h2>Think you've responded to a phishing email by mistake?</h2>
          <p>
            If you provided your account information to a request you suspect may have been
            fraudulent, call us immediately at{' '}
            <span className={classes.bodyBold}>1-800-535-2001</span>.
          </p>
        </Stack>
        <Stack spacing={1}>
          <h2>How to protect yourself</h2>
          <p className={classes.bodyBold}>Before clicking on a link in an email:</p>
          <ul>
            <li>
              Think twice. If you suspect the email might be a phishing attempt, delete it and
              follow up with the company directly.
            </li>
            <li>
              Roll over the link with your mouse to display the URL. If the URL looks suspicious, do
              not click on it.
            </li>
            <li>
              Type the business's legitimate website address into your browser instead of clicking
              on an email link.
            </li>
          </ul>
          <p className={classes.bodyBold}>Before you log in to access your accounts:</p>
          <ul>
            <li>
              Check to make sure the URL address of the page begins with "https" instead of just
              "http." The "s" indicates it is a secure page.
            </li>
            <li>
              Look for an image of a closed padlock near the URL address in your browser. You can
              click on the padlock to confirm the identity of the site you are visiting.
            </li>
          </ul>
          <p className={classes.bodyBold}>When visiting sites in general:</p>
          <ul>
            <li>
              Go to them directly. The safest way to get to any site is to type the URL address into
              your browser directly. You can then bookmark it for quicker access on future visits.
            </li>
            <li>
              Use the "Remember my User ID" feature (but never from a public or shared computer).
              This feature lets your computer or device remember your ID, so when you return to the
              site from an email to log in, your User ID will automatically display in the log in
              box. A fake site will not be able to display your User ID.
            </li>
          </ul>
          <p>
            For more information on how to protect yourself,{' '}
            <NavLink to={generateHelpPath(PAGE_PATH.DISCLOSURES, 'connect')}>
              visit our Security section
            </NavLink>
            .
          </p>
        </Stack>
      </Stack>
    </DisclosureWrapper>
  );
};
