import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AccountPaymentMethodFields' })((theme) => ({
  root: { maxWidth: 360 },
  label: {
    '& .MuiFormLabel-root': {
      paddingBottom: 3,
      marginLeft: 0,
    },
  },
  groupLabelStack: { alignItems: 'center', paddingBottom: 0 },
}));
