import { Stack } from '@mui/material';

import { DisclosureWrapper } from '../../../components';
import { useStyles } from './LegalNoticesHomesite.styles';

export const LegalNoticesHomesite: React.FC = () => {
  const { classes } = useStyles();

  return (
    <DisclosureWrapper
      title='Legal Notice'
      breadcrumbTitleOverrides={{ legalnotices: 'Legal Notices' }}
    >
      <Stack spacing={4}>
        <Stack spacing={1}>
          <h2>Information for California Applicants or Policyholders:</h2>
          <p>
            If you wish to discuss mitigation discounts and efforts you could take to possibly
            reduce your premium, such as installation of automatic sprinkler systems in all rooms of
            your dwelling, addition of a central station monitored fire and burglary alarm, a new
            roof or change to roofing material, or if a water supply or fire station is now closer
            to your home, please contact us at 1-800-466-3748. These discounts range from 5-35% of
            applicable peril premium.
          </p>
        </Stack>

        <Stack spacing={1}>
          <h2>NY Domestic Violence Regulation</h2>
          <p>
            New York Insurance Law, section 2612, prohibits insurers from discrimination based on
            being a victim of domestic violence. This law states that no insurer, solely because a
            person is or has been a victim of domestic violence, may:
          </p>
          <ul className={classes.listStyle}>
            <li>refuse to issue or renew, deny or cancel any insurance policy;</li>
            <li>demand or require a greater premium or payment from any person; or</li>
            <li>
              designate domestic violence as a preexisting condition, for which coverage will be
              denied/reduced.
            </li>
          </ul>
          <p>
            The fact that a person is or has been a victim of domestic violence is not a permitted
            underwriting criterion. This law also contains protections for victims of domestic
            violence regarding health insurers who receive a valid order of protection against the
            policyholder. The insurer is prohibited for the duration of the order from disclosing to
            the policyholder the address and telephone number of the insured, or of any person or
            entity providing covered services to the insured. The insurer should also honor a
            reasonable request by a person covered by an insurance policy to receive communications
            of claim related information by alternative means or at alternative locations if the
            person clearly states that disclosure of all or part of the information could endanger
            the person.
          </p>
          <p>
            If any person covered by a Homesite insurance policy, issued to another person as the
            policyholder, delivers to Homesite a valid order of protection in the State of New York,
            against the policyholder, Homesite shall be prohibited for the duration of the order
            from disclosing to the policyholder the address and telephone number of the insured, or
            of any person or entity providing covered services to the insured. If a child is the
            covered person, the right established by this provision may be asserted by, and shall
            also extend to, the parent or guardian of the child.
          </p>
          <p className={classes.bodyBold}>
            Below are the applicable Protection Protocols Request Procedures for a person who is or
            has been a victim of domestic violence:
          </p>
          <ul className={classes.listStyle}>
            <li>
              Any covered person, or the parent/legal guardian of a covered child, or a legal
              representative of such covered person or child, may submit in writing a reasonable
              request that the covered person's/child's address, telephone number, email addresses,
              other designated personally identifying information, the nature of the health care
              services provided, and the name, address, and telephone number of providers of the
              health care services, should not be provided to the policyholder or other insureds
              covered by the policy.
            </li>
            <li>
              The request should be made by faxing a copy of the order of protection to Homesite at
              1-877-273-2984. If you are unable to fax a copy of the order, please email the request
              to customerservice@homesite.com or mail it to us at Homesite Insurance, P.O. Box 5300,
              Binghamton, NY 13902-9953.
            </li>
            <li>
              Once a valid request has been received and implemented, a requestor may only revoke
              the prior request by submitting to us a written sworn statement revoking the request.
            </li>
          </ul>
          <p>
            For additional assistance, please contact the New York State Domestic and Sexual
            Violence Hotline.
          </p>
          <p>NYS Domestic and Sexual Violence Hotline: 1-800-924-6906</p>
          <p>Spanish language: 1-800-942-6908</p>
          <p>In NYC: 1-800-621-HOPE or dial 311</p>
          <p>TTY: 1-866-604-5350</p>
          <p className={classes.bodyBold}>
            Please be advised that the employees at Homesite have up to three business days to
            implement the necessary protocol to secure your contact information and other
            confidential identifying information.
          </p>
        </Stack>

        <Stack spacing={1}>
          <h2>Insurance Fraud</h2>
          <p>
            Insurance fraud is an act committed with the intent to deceive an insurer for the
            purpose of profit or gain. Insurance fraud could involve making false representations on
            an insurance application, inflating an insurance claim, and billing for services never
            rendered.
          </p>
          <p>Email: askSIU@afics.com</p>
          <p>Phone: 608-242-4100 x29510</p>
          <p>
            NICB: https://www.nicb.org/speak_up/speak-up
            <br />
            1-800-835-6422
          </p>
        </Stack>

        <Stack spacing={1}>
          <h2>Code of Alabama Currentness</h2>
          <p>Title 27. Insurance. (Refs & Annos)</p>
          <p>Chapter 22. Property Insurance Contracts. (Refs & Annos)</p>
          <p>Article 3. Alabama Homeowners Bill of Rights Act. (Refs & Annos)</p>
          <p className={classes.bodyBold}>§ 27-22-42. Policyholders bill of rights.</p>
          <p>
            The following shall serve as the minimum standards to be followed by the Alabama
            Department of Insurance in exercising the department's powers and duties in regulating
            insurance companies pursuant to Chapter 12. The Department of Insurance and insurance
            companies shall post this list or an electronic link of this list on their respective
            websites. These standards include the following:
          </p>
          <Stack component='ul' spacing={1} className={classes.listStyle}>
            <li>
              Policyholders shall have the right to competitive pricing practices of insurers as
              prescribed by applicable federal or state insurance law and regulation.
            </li>
            <li>
              Policyholders shall have the right to insurance advertising and sales approaches that
              provide representative information on the policy in accordance with Chapter 12.
            </li>
            <li>
              Policyholders shall have the right to assurance that the insurance market in general
              and their insurance company in particular are financially stable as provided in
              Section 27-12-7.
            </li>
            <li>
              Policyholders shall have the right to receive service from licensed producers in
              accordance with Chapter 7, and to request the license status of an insurance company
              or producer.
            </li>
            <li>
              Policyholders shall have the right to a policy as prescribed in Chapter 14, to receive
              a complete policy, and to request a duplicate or replacement policy, if needed.
            </li>
            <li>
              Policyholders shall have the right to receive in writing from their insurance company
              the reason for any cancellation of coverage and a minimum number of days' notice of
              cancellation of coverage, subject to applicable federal or state insurance law and
              regulation.
            </li>
            <li>
              Policyholders shall have the right to cancel their policy and receive a refund of any
              unearned premium. If a policy was funded by a premium finance company, the unearned
              premium will be returned to the premium finance company to pay toward the
              policyholder's financing loan.
            </li>
            <li>
              Policyholders shall have the right to a written notification, at renewal, describing
              changes in their insurance contract language that are applicable to the renewal
              period.
            </li>
            <li>
              Policyholders shall have the right, in the event of a claim, to reject any settlement
              amount offered by the insurance company.
            </li>
            <li>
              Policyholders shall have the right to select their licensed contractor or vendor to
              repair, replace, or rebuild damaged property covered by the insurance policy.
            </li>
            <li>
              Policyholders shall have the right to file a written complaint against any insurance
              company with the Department of Insurance and to have that complaint reviewed by the
              Department of Insurance.
            </li>
            <li>
              Policyholders shall have the right to file a written complaint against any insurance
              producer with the Department of Insurance and to have that complaint reviewed by the
              Department of Insurance.
            </li>
          </Stack>
          <p>CREDIT(S)</p>
          <p> (Act 2012-510, p. 1521, § 3.) </p>
          <p>HISTORY</p>
          <p className={classes.bodyBold}> Effective date: </p>
          <p>
            The act which added this section was approved by the Governor on May 22, 2012. For
            effective date, see the Code Commissioner's Notes preceding § 27-2-40.{' '}
          </p>
          <p>Ala. Code 1975 § 27-22-42, AL ST § 27-22-42</p>
          <p>Current through Act 2014-457 of the 2014 Regular Session.</p>
        </Stack>
      </Stack>
    </DisclosureWrapper>
  );
};
