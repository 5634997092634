import type { Address, UiPolicy } from '@ecp/features/servicing/shared/types';

import { getAddressByRefId } from './getAddressByRefId';
import { getDwellingAddress } from './getDwellingAddress';
import { getProductLineFromPolicyResponse } from './getProductLineFromPolicyResponse';

export const getPolicyAddress = (policyResponse: UiPolicy | undefined): Address | undefined => {
  const productLine = getProductLineFromPolicyResponse(policyResponse);

  if (productLine === 'HOME') return getDwellingAddress(policyResponse);
  else if (productLine === 'AUTO')
    return getAddressByRefId(policyResponse, policyResponse?.vehicles?.at(0)?.garagingAddressRefId);

  return undefined;
};
