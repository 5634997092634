import { useEffect } from 'react';

import { useIsAuthenticated } from '@azure/msal-react';

import * as interactionId from '@ecp/utils/analytics/interaction-id';
import { initialize, setDimension, TrackingDimensions } from '@ecp/utils/analytics/tracking';
import { azureAuth } from '@ecp/utils/auth';

import { env } from '@ecp/env';
import { usePolicyNumberFromUrl } from '@ecp/features/servicing/shared/util';

import { useUserPolicies } from '../policy';
import { getProductLineFromPolicyResponse } from './getProductLineFromPolicyResponse';
import { compareProductLine } from './sortPolicies';

export const useCommonAnalyticsInitialization = (): void => {
  useEffect(() => {
    initialize(env.gtmId, env.runtimeEnv);
    setDimension(TrackingDimensions.SESSION_ID, interactionId.get());
    setDimension(TrackingDimensions.PARTNER_ID, env.static.partnerId);
    setDimension(
      TrackingDimensions.ENV_AND_PARTNER_ID,
      `${env.runtimeEnv}-${env.static.partnerId}`,
    );
  }, []);
};

export const useAnalyticsConsumer = (): void => {
  useCommonAnalyticsInitialization();
  const { user, policies } = useUserPolicies({
    ignoreUserAuth: true,
    throwOnPolicyError: false,
    throwOnUserError: false,
  });
  const sortedPolicies = policies.sort(compareProductLine);
  const { customerId, userId } = user ?? {};
  const activePolicyNumber = usePolicyNumberFromUrl();

  useEffect(() => {
    setDimension(TrackingDimensions.ACTIVE_POLICY_NUMBER, activePolicyNumber);
    if (customerId) {
      setDimension(TrackingDimensions.ECID, customerId);
    }
    if (userId) {
      setDimension(TrackingDimensions.USER_ID, userId);
    }
  }, [activePolicyNumber, customerId, userId]);

  useEffect(() => {
    const autoPolicyNumbers: string[] = [];
    const autoStatuses: string[] = [];
    const autoPeriodStartDates: string[] = [];
    const autoPeriodEndDates: string[] = [];

    const propertyPolicyNumbers: string[] = [];
    const propertyStatuses: string[] = [];
    const propertyPeriodStartDates: string[] = [];
    const propertyPeriodEndDates: string[] = [];
    sortedPolicies.forEach((policyInfo) => {
      const {
        policyNumber = '',
        status,
        periodStartDate,
        periodEndDate,
      } = policyInfo?.policy ?? {};
      const productLine = getProductLineFromPolicyResponse(policyInfo);
      if (productLine) {
        const reducedProductName = productLine === 'AUTO' ? productLine : 'PROPERTY';
        setDimension(TrackingDimensions.PRODUCT[reducedProductName], productLine);

        if (reducedProductName === 'AUTO') {
          autoPolicyNumbers.push(policyNumber);
          autoStatuses.push(status);
          autoPeriodStartDates.push(periodStartDate);
          autoPeriodEndDates.push(periodEndDate);
        } else {
          propertyPolicyNumbers.push(policyNumber);
          propertyStatuses.push(status);
          propertyPeriodStartDates.push(periodStartDate);
          propertyPeriodEndDates.push(periodEndDate);
        }
      }
    });

    if (autoPolicyNumbers.length > 0) {
      setDimension(TrackingDimensions.POLICY_NUMBER.AUTO, autoPolicyNumbers);
      setDimension(TrackingDimensions.STATUS.AUTO, autoStatuses);
      setDimension(TrackingDimensions.EFFECTIVE_DATE.AUTO, autoPeriodStartDates);
      setDimension(TrackingDimensions.EXPIRATION_DATE.AUTO, autoPeriodEndDates);
    }

    if (propertyPolicyNumbers.length > 0) {
      setDimension(TrackingDimensions.POLICY_NUMBER.PROPERTY, propertyPolicyNumbers);
      setDimension(TrackingDimensions.STATUS.PROPERTY, propertyStatuses);
      setDimension(TrackingDimensions.EFFECTIVE_DATE.PROPERTY, propertyPeriodStartDates);
      setDimension(TrackingDimensions.EXPIRATION_DATE.PROPERTY, propertyPeriodEndDates);
    }
  }, [sortedPolicies]);
};

export const useAnalyticsAgent = (): void => {
  useCommonAnalyticsInitialization();

  const isAuthenticated = useIsAuthenticated();
  useEffect(() => {
    if (isAuthenticated) {
      azureAuth.authResult.then((result) => {
        if (result) {
          setDimension(TrackingDimensions.USER_ID, result.account?.username ?? '');
        }
      });
    } else {
      setDimension(TrackingDimensions.USER_ID, '');
    }
  }, [isAuthenticated]);
};
