import { Table as MuiTable, Stack, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { Link } from '@ecp/components';

import { DisclosureWrapper } from '../../../components';
import { useStyles } from './UnderwritingHomesite.styles';

export const UnderwritingHomesite: React.FC = () => {
  const { classes } = useStyles();

  const californiaTableProps = {
    tableDataHeaders: [
      'Company Name',
      'State of Domicile',
      'Principal Place of Business',
      'California Certificate of Authority No.',
    ],
    tableData: [
      {
        companyName: 'Homesite Insurance Company of California',
        stateOfDomicile: 'California',
        ppob: 'Boston, MA',
        ccoan: '4620-1',
      },
      {
        companyName: 'Homesite Insurance Company of the Midwest',
        stateOfDomicile: 'Wisconsin',
        ppob: 'Boston, MA',
        ccoan: '5045-0',
      },
      {
        companyName: 'Midvale Indemnity Company',
        stateOfDomicile: 'Wisconsin',
        ppob: 'Madison, WI',
        ccoan: '2224-4',
      },
      {
        companyName: 'American Family Life Insurance Company',
        stateOfDomicile: 'Wisconsin',
        ppob: 'Madison, WI',
        ccoan: '2435',
      },
    ],
  };

  return (
    <DisclosureWrapper
      title='Underwriting Companies'
      breadcrumbTitleOverrides={{ underwriting: 'Underwriting Companies' }}
    >
      <Stack spacing={4}>
        <Stack spacing={1}>
          <p>
            Homesite Group Incorporated is an affiliate of{' '}
            <Link
              target='_blank'
              href='https://www.amfam.com/'
              rel='noreferrer'
              trackingName='american_family_insurance_link'
              trackingLabel='american_family_insurance'
            >
              American Family Insurance
            </Link>
            .*
          </p>
          <p>
            Policies are underwritten and issued by member companies and affiliates of Homesite
            Group Incorporated.
          </p>
          <p>
            Property & Casualty business is underwritten by: Homesite Insurance Company of
            California, Homesite Indemnity Company, Homesite Insurance Company of Illinois, Homesite
            Insurance Company of New York, Homesite Insurance Company of Georgia, Homesite Insurance
            Company of the Midwest, Homesite Lloyds of Texas, Homesite Insurance Company of Florida,
            Homesite Insurance Company and Midvale Indemnity Company.
          </p>
          <p>
            In Texas, some policies are underwritten by Homesite Insurance Company or Homesite
            Indemnity Company. Alternatively, certain property coverages may be written by Ranchers
            & Farmers Mutual Insurance Company and certain liability coverages may be written by
            Homesite Lloyd's of Texas. Each insurer has sole financial responsibility for its own
            products.
          </p>
          <p>
            Life Insurance policies are underwritten by member companies of American Family
            Insurance.* Member companies include: American Family Life Insurance Company and Midvale
            Life Insurance Company of New York.
          </p>
        </Stack>

        <Stack spacing={1}>
          <h2>California Residents</h2>

          <div>
            <p>
              The following information, regarding Homesite affiliates transacting insurance on the
              Internet, is provided for residents of the State of California:
            </p>
            <MuiTable>
              <TableHead>
                <TableRow className={classes.tableHeaderRow}>
                  {californiaTableProps.tableDataHeaders.map((header) => (
                    <TableCell key={header}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {californiaTableProps.tableData.map((row) => (
                  <TableRow key={row.ccoan} className={classes.tableBodyRow}>
                    <TableCell>{row.companyName}</TableCell>
                    <TableCell>{row.stateOfDomicile}</TableCell>
                    <TableCell>{row.ppob}</TableCell>
                    <TableCell>{row.ccoan}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </MuiTable>
          </div>
          <p>
            *American Family Insurance Mutual Holding Company, 6000 American Parkway, Madison, WI
            53783.
          </p>
        </Stack>

        <Stack spacing={2}>
          <Stack spacing={1}>
            <h2>List of Homesite Underwriting Companies</h2>
            <h3>Homesite Indemnity Company</h3>
            <p>Principal Place of Business: 1 Federal Street, Suite 400, Boston, MA 02110.</p>

            <div>
              <p className={classes.italicText}>Holds Foreign Certificate of Authority</p>
              <p>
                Arizona, Colorado, Kansas, Missouri, Nebraska, Nevada, New Mexico, North Dakota,
                Oklahoma, South Dakota, Texas, Utah, Wyoming
              </p>
            </div>

            <div>
              <p className={classes.italicText}>Holds Domestic Certificate Authority</p>
              <p>Wisconsin</p>
            </div>
          </Stack>

          <Stack spacing={1}>
            <h3>Homesite Insurance Company</h3>
            <p>Principal Place of Business: 1 Federal Street, Suite 400, Boston, MA 02110.</p>

            <div>
              <p className={classes.italicText}>Holds Foreign Certificate of Authority</p>
              <p>
                Alabama, Alaska, Arizona, Arkansas, Colorado, Connecticut, Delaware, District of
                Columbia, Florida, Georgia, Hawaii, Idaho, Indiana, Iowa, Kentucky, Louisiana,
                Maine, Maryland, Massachusetts, Michigan, Minnesota, Missouri, Mississippi, Montana,
                Nebraska, Nevada, New Hampshire, New Jersey, New Mexico, North Carolina, North
                Dakota, Ohio, Oklahoma, Oregon, Pennsylvania, Rhode Island, South Carolina, South
                Dakota, Tennessee, Texas, Utah, Vermont, Virginia, Washington, West Virginia,
                Wyoming
              </p>
            </div>

            <div>
              <p className={classes.italicText}>Holds Domestic Certificate Authority</p>
              <p>Wisconsin</p>
            </div>
          </Stack>

          <Stack spacing={1}>
            <h3>Homesite Insurance Company of California</h3>
            <p>Principal Place of Business: 1 Federal Street, Suite 400, Boston, MA 02110.</p>
            <div>
              <p className={classes.italicText}>Holds Domestic Certificate Authority</p>
              <p>California</p>
            </div>
          </Stack>

          <Stack spacing={1}>
            <h3>Homesite Insurance Company of Florida</h3>
            <p>Principal Place of Business: 1 Federal Street, Suite 400, Boston, MA 02110.</p>
            <div>
              <p className={classes.italicText}>Holds Domestic Certificate Authority</p>
              <p>Illinois</p>
            </div>
          </Stack>

          <Stack spacing={1}>
            <h3>Homesite Insurance Company of Georgia</h3>
            <p>Principal Place of Business: 1 Federal Street, Suite 400, Boston, MA 02110.</p>
            <div>
              <p className={classes.italicText}>Holds Foreign Certificate of Authority</p>
              <p>Georgia</p>
            </div>
            <div>
              <p className={classes.italicText}>Holds Domestic Certificate Authority</p>
              <p>Pennsylvania</p>
            </div>
          </Stack>

          <Stack spacing={1}>
            <h3>Homesite Insurance Company of Illinois</h3>
            <p>Principal Place of Business: 1 Federal Street, Suite 400, Boston, MA 02110.</p>
            <div>
              <p className={classes.italicText}>Holds Domestic Certificate Authority</p>
              <p>Illinois</p>
            </div>
          </Stack>

          <Stack spacing={1}>
            <h3>Homesite Insurance Company of New York</h3>
            <p>Principal Place of Business: 1 Federal Street, Suite 400, Boston, MA 02110.</p>
            <div>
              <p className={classes.italicText}>Holds Domestic Certificate Authority</p>
              <p>New York</p>
            </div>
          </Stack>

          <Stack spacing={1}>
            <h3>Homesite Insurance Company of the Midwest</h3>
            <p>Principal Place of Business: 1 Federal Street, Suite 400, Boston, MA 02110.</p>
            <div>
              <p className={classes.italicText}>Holds Foreign Certificate of Authority</p>
              <p>
                Alabama, Alaska, Arkansas, Delaware, District of Columbia, Florida, Georgia, Hawaii,
                Idaho, Indiana, Iowa, Kentucky, Louisiana, Maine, Maryland, Massachusetts, Michigan,
                Minnesota, Mississippi, Montana, New Jersey, New Mexico, North Carolina, North
                Dakota, Ohio, Oregon, Pennsylvania, Rhode Island, South Carolina, South Dakota,
                Tennessee, Utah, Vermont, Virginia, Washington, West Virginia
              </p>
            </div>

            <div>
              <p className={classes.italicText}>Holds Domestic Certificate Authority</p>
              <p>Wisconsin</p>
            </div>

            <div>
              <p className={classes.italicText}>Accredited Reinsurer</p>
              <p>Texas, Florida, Illinois, New York</p>
            </div>
          </Stack>

          <Stack spacing={1}>
            <h3>Homesite Lloyd's of Texas</h3>
            <p>Principal Place of Business: 1 Federal Street, Suite 400, Boston, MA 02110.</p>
            <div>
              <p className={classes.italicText}>Holds Domestic Certificate Authority</p>
              <p>Texas</p>
            </div>
          </Stack>

          <Stack spacing={1}>
            <h3>Midvale Indemnity Company</h3>
            <p>Principal Place of Business: 6000 American Parkway, Madison, WI 53783.</p>
            <div>
              <p className={classes.italicText}>Holds Foreign Certificate of Authority</p>
              <p>
                Alabama, Alaska, Arizona, Arkansas, California, Colorado, Connecticut, Delaware,
                District of Columbia, Florida, Georgia, Hawaii, Idaho, Illinois, Indiana, Iowa,
                Kansas, Kentucky, Louisiana, Maine, Maryland, Massachusetts, Michigan, Minnesota,
                Mississippi, Missouri, Montana, Nebraska, Nevada, New Hampshire, New Jersey, New
                Mexico, New York, North Carolina, North Dakota, Ohio, Oklahoma, Oregon, Pennsylvania
                , Rhode Island, South Carolina, South Dakota, Tennessee, Texas, Utah, Vermont,
                Virginia, Washington, West Virginia, Wyoming
              </p>
            </div>

            <div>
              <p className={classes.italicText}>Holds Domestic Certificate Authority</p>
              <p>Wisconsin</p>
            </div>
          </Stack>
        </Stack>
      </Stack>
    </DisclosureWrapper>
  );
};
