import { useCallback, useEffect } from 'react';

import { Grid, Stack } from '@mui/material';
import type { FallbackProps } from 'react-error-boundary';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';

import { userAuth } from '@ecp/utils/auth';

import type { LinkProps } from '@ecp/components';
import { Button, GridItem, PhoneLink } from '@ecp/components';
import { env } from '@ecp/env';
import {
  DashboardCardContent,
  ServicingFooter,
  ServicingHeader,
} from '@ecp/features/servicing/shared/components';
import { PAGE_PATH, useCurrentRoute, usePageFlow } from '@ecp/features/servicing/shared/routing';
import type {
  ValidateUserRequest,
  VerifyOneTimeCodeSuccess,
} from '@ecp/features/servicing/shared/state';
import { queryClient, useSharedState } from '@ecp/features/servicing/shared/state';
import type { EmailOption } from '@ecp/features/servicing/shared/types';
import { getBrandDataWithFallback } from '@ecp/features/servicing/shared/util';
import { GraphicErrorGenericErrorLandscape } from '@ecp/themes/base';

import { useStyles } from './AppErrorBoundary.styles';
import { DevDiagnostic } from './DevDiagnostic';
import { useIsErrorState } from './useIsErrorState';
const showDevDiagnostic = ['development', 'test'].includes(env.static.nodeEnv);

const FooterErrorFallback: React.FC = () =>
  showDevDiagnostic ? (
    <p>
      Footer module failed to load. This indicates an error in the <strong>ServicingFooter</strong>{' '}
      component.
    </p>
  ) : null;

export const ErrorFallback: React.ComponentType<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  const { classes } = useStyles();

  const { errorHashName, isErrorState, setIsErrorState } = useIsErrorState();
  const { navigate } = usePageFlow();
  const { hash, pathname, search } = useLocation();
  const currentRoute = useCurrentRoute();

  const [, setOtpVerifiedData] = useSharedState<VerifyOneTimeCodeSuccess>('OtpVerifiedData');
  const [, setSelectedEmail] = useSharedState<EmailOption>('SelectedEmail');
  const [, setCreateAccount] = useSharedState<Partial<ValidateUserRequest>>('CREATE_ACCOUNT');
  const [, setRecoverEmail] = useSharedState<Partial<ValidateUserRequest>>('RECOVER_EMAIL');
  const [, setRecoverPassword] = useSharedState<Partial<ValidateUserRequest>>('RECOVER_PASSWORD');

  const resetAll = useCallback(() => {
    setOtpVerifiedData(null);
    setSelectedEmail(null);
    setCreateAccount(null);
    setRecoverEmail(null);
    setRecoverPassword(null);
    queryClient.setQueryData(['validateUser'], null);
  }, [setOtpVerifiedData, setSelectedEmail, setRecoverEmail, setRecoverPassword, setCreateAccount]);

  const resetAfterUrlChange = useCallback((): void => {
    if (isErrorState && hash !== errorHashName) {
      setIsErrorState(false);
      resetErrorBoundary();
    }
  }, [errorHashName, isErrorState, hash, resetErrorBoundary, setIsErrorState]);

  // auto-reset error boundary when click link or browser forward/back buttons used
  useEffect(resetAfterUrlChange, [hash, pathname, search, resetAfterUrlChange]);

  const handleGoBackClick = useCallback<NonNullable<LinkProps['onClick']>>(() => {
    resetErrorBoundary();

    // During enrollment/recovery, if page errors out on password screen,
    // go back button should redirect user to beginning of the flow.
    let pathToNavigate = pathname;
    if (currentRoute.pathname === PAGE_PATH.CREATE_ACCOUNT_CREATE_PASSWORD) {
      pathToNavigate = PAGE_PATH.CREATE_ACCOUNT;
      resetAll();
    } else if (
      currentRoute.pathname === PAGE_PATH.RECOVER_EMAIL_ENTER_PASSWORD ||
      currentRoute.pathname === PAGE_PATH.RECOVER_EMAIL_RESET_PASSWORD
    ) {
      pathToNavigate = PAGE_PATH.RECOVER_EMAIL;
      resetAll();
    } else if (currentRoute.pathname === PAGE_PATH.RECOVER_PASSWORD_RESET_PASSWORD) {
      pathToNavigate = PAGE_PATH.RECOVER_PASSWORD;
      resetAll();
    }

    navigate(pathToNavigate);
  }, [navigate, resetErrorBoundary, pathname, currentRoute.pathname, resetAll]);

  return (
    <div className={classes.root}>
      <ServicingHeader />
      <DashboardCardContent classes={{ root: classes.body }}>
        <Grid
          spacing={{ sm: 4, md: 5 }}
          container
          direction={{ md: 'column', lg: 'row' }}
          justifyContent='center'
        >
          <GridItem md={12} lg={7} order={{ lg: 1, md: 2, sm: 2 }}>
            <Stack spacing={5}>
              <Stack spacing={1.5}>
                <h2>Something went wrong</h2>
                <p>It's probably nothing so you can go back and try again.</p>
                <p>
                  If the problem continues, we're aware and are quickly working to fix the issue.
                </p>
                <p>
                  For help, please call{' '}
                  <PhoneLink
                    withUnderlinedLinkStyle
                    number={getBrandDataWithFallback().mainCustServPhoneNum}
                  />
                  .
                </p>
              </Stack>
              <Button
                onClick={handleGoBackClick}
                variant='primary'
                className={classes.backButton}
                trackingName='go_back_button'
                trackingLabel='go_back_continue'
              >
                GO BACK
              </Button>
            </Stack>
          </GridItem>
          <GridItem
            md={12}
            lg={5}
            className={classes.imageContainer}
            order={{ sm: 1, md: 1, lg: 2 }}
          >
            <GraphicErrorGenericErrorLandscape className={classes.image} />
          </GridItem>
          <GridItem lg={12} order={3}>
            {showDevDiagnostic && <DevDiagnostic error={error} />}
          </GridItem>
        </Grid>
      </DashboardCardContent>
      <ErrorBoundary FallbackComponent={FooterErrorFallback}>
        <ServicingFooter isLoggedIn={userAuth.isAuth} />
      </ErrorBoundary>
    </div>
  );
};
