import { datadogLog } from '@ecp/utils/logger';

import type { GetPaymentOptionsSuccess } from '@ecp/features/servicing/shared/state';
import type { ServicingCardType } from '@ecp/features/servicing/shared/types';

/** useGetInfoFromPaymentOptions hook returns params used for PaymentStatusDisplay */
export const useInfoFromPaymentOptions = (
  paymentOptions: GetPaymentOptionsSuccess | undefined,
): {
  autoPayEnabled: boolean;
  autoPayLastFour: string;
  autoPaySource?: 'credit card' | 'bank account';
  isPaymentOptionsTypeUnknown: boolean;
  cardType?: ServicingCardType;
} => {
  let autoPayEnabled = false;
  let autoPaySource;
  let autoPayLastFour = '';
  let isPaymentOptionsTypeUnknown = false;
  let cardType = undefined;

  if (paymentOptions?.length) {
    paymentOptions.forEach((paymentOption) => {
      if (paymentOption.type === 'CC') {
        if (paymentOption.autopayEnrolled) {
          autoPayEnabled = true;
          autoPaySource = 'credit card';
          autoPayLastFour = paymentOption.ccToken;
          cardType = paymentOption.cardType;
        }
      } else if (paymentOption.type === 'EFT') {
        if (paymentOption.autopayEnrolled) {
          autoPayEnabled = true;
          autoPaySource = 'bank account';
          autoPayLastFour = paymentOption.accountNumber;
        }
        // if payment option type is unknown
      } else {
        isPaymentOptionsTypeUnknown = true;
        datadogLog({
          logType: 'error',
          message: 'Unknown type of payment option',
          context: {
            logOrigin:
              'libs/features/servicing/shared/components/src/PaymentStatusDisplay/useInfoFromPaymentOptions.ts',
            functionOrigin: 'useInfoFromPaymentOptions',
          },
        });
      }
    });
  }

  return {
    autoPayEnabled,
    autoPayLastFour,
    autoPaySource,
    isPaymentOptionsTypeUnknown,
    cardType,
  };
};
