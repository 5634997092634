import { ButtonBase, Stack } from '@mui/material';

import { withAnalytics } from '@ecp/utils/analytics/tracking';

import { IconUIArrowRight } from '@ecp/themes/base';

import { useStyles } from './MobileMenuItem.styles';

interface Props {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  icon: React.ReactNode;
  onClick: () => unknown;
  title: string;
}

export const MobileMenuItem = withAnalytics<Props>((props) => {
  const { classes } = useStyles(undefined, { props });
  const { icon, onClick, title } = props;

  return (
    <div className={classes.root}>
      <ButtonBase className={classes.button} onClick={onClick}>
        <Stack direction='row' justifyContent='space-between'>
          <Stack direction='row' spacing={1} alignItems='center'>
            {icon}
            <p className={classes.title}>{title}</p>
          </Stack>
          <IconUIArrowRight className={classes.arrowRight} />
        </Stack>
      </ButtonBase>
    </div>
  );
}, 'button');
