import { Divider, Stack } from '@mui/material';

import { Alert } from '@ecp/components';
import { AddressDisplay, PolicyCard } from '@ecp/features/servicing/shared/components';
import type { UseModalPathReturn } from '@ecp/features/servicing/shared/routing';
import { usePolicy } from '@ecp/features/servicing/shared/state';
import { getInsuredAddress } from '@ecp/features/servicing/shared/state';
import { IconUIMap } from '@ecp/themes/base';

import { useStyles } from './AutopayEditUnenroll.styles';

export const AutopayEditUnenroll: React.FC<UseModalPathReturn> = (props) => {
  const { policyNumber } = props;
  const { classes } = useStyles();

  const { policyData: policyResponse } = usePolicy(policyNumber);

  return (
    <Stack spacing={5}>
      <PolicyCard
        policyDetails={policyResponse}
        body={
          <Stack spacing={2} className={classes.policyCard}>
            <Divider />
            <Stack direction='row' justifyItems='center' justifyContent='left'>
              <IconUIMap className={classes.mapIcon} />
              <div>
                <AddressDisplay policyAddress={getInsuredAddress(policyResponse)} />
              </div>
            </Stack>
          </Stack>
        }
      />
      <Alert withIcon type='info'>
        Stopping automatic payments may increase your installment fees.
      </Alert>
    </Stack>
  );
};
