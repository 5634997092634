import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'EnrollPaperlessCard' })((theme) => ({
  root: { maxWidth: 498 },
  description: theme.typography.body1,
  icon: {
    ...theme.mixins.setColorSvg(theme.palette.grey[900]),
    width: 36,
    height: 'auto',
  },
  enrollButton: {
    height: 'fit-content',
    paddingBottom: 12,
    paddingTop: 12,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'fit-content',
    },
  },
}));
