import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { userAuth } from '@ecp/utils/auth';

import { LoadingOverlay } from '@ecp/features/servicing/shared/components';
import { usePageFlow } from '@ecp/features/servicing/shared/routing';

import { useStyles } from './LogoutPage.styles';

export const LogoutPage: React.FC = () => {
  const { classes } = useStyles();
  const pageFlow = usePageFlow();
  const { state } = useLocation();

  useEffect(() => {
    userAuth.logout().then(() => {
      pageFlow.goForward({ replace: true, state });
    });
  });

  return (
    <div className={classes.root}>
      <p className={classes.message}>You are being logged out</p>
      <LoadingOverlay />
    </div>
  );
};
