import * as interactionId from '@ecp/utils/analytics/interaction-id';
import { userAuth } from '@ecp/utils/auth';
import { Headers } from '@ecp/utils/network';

import { env } from '@ecp/env';

export const apiRoot = env.servicingApiRoot;

export const buildRequestHeaders = async ({
  withUserToken = false,
}: {
  withUserToken?: boolean;
}): Promise<HeadersInit> => {
  const userTokens = withUserToken && (await userAuth.token);

  return {
    'Content-Type': 'application/json',
    [Headers.API_KEY]: env.afeApiKey,
    ...(env.static.partnerId && { [Headers.PARTNER_ID]: env.static.partnerId }),
    [Headers.SESSION_ID]: interactionId.get(),
    [Headers.SOURCE_ID]: env.static.sourceId,
    ...(userTokens && {
      [Headers.USER_TOKEN]: userTokens.idToken,
      Authorization: `Bearer ${userTokens.accessToken}`,
    }),
  };
};
