import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'DocumentSection' })((theme) => ({
  root: {},
  link: { ...theme.typography.button2, textDecoration: 'none' },
  docTable: {
    '& td, & th': { backgroundColor: theme.palette.primary.background },
    '& td': { backgroundColor: theme.palette.grey[50], padding: 10 },
    '& td:last-child': { backgroundColor: theme.palette.grey[50], padding: 10 },
    '& tr:nth-child(even) td': { backgroundColor: theme.palette.primary.background },
  },
}));
