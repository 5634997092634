import { memo, useCallback, useMemo } from 'react';

import { Stack } from '@mui/material';

import { useField, useFormContext } from '@ecp/utils/form';

import type { RadioGroupOption } from '@ecp/components';
import { Alert, RadioGroupWithOptions } from '@ecp/components';
import type { UseModalPathReturn as AutopaySetupClassicProps } from '@ecp/features/servicing/shared/routing';
import { useSharedState } from '@ecp/features/servicing/shared/state';
import type { MakePaymentInformation } from '@ecp/features/servicing/shared/types';
import type { AutopayEnrollPaymentFrequencyFormInputs } from '@ecp/features/servicing/shared/util';

import { useStyles } from './AutopaySetup.styles';

export const AutopaySetupClassic: React.FC<AutopaySetupClassicProps> = memo((props) => {
  const { sharedStateKey } = props;
  const [sharedState, setSharedState] = useSharedState<MakePaymentInformation>(sharedStateKey);
  const { classes } = useStyles();

  const { control } = useFormContext<AutopayEnrollPaymentFrequencyFormInputs>();
  const paymentFrequencyField = useField({
    name: 'paymentFrequency',
    defaultValue: sharedState?.paymentFrequency,
    control,
  });

  const paymentFrequencyOptions: RadioGroupOption[] = useMemo(
    () => [
      {
        description: 'Monthly',
        label: '',
        value: 'Monthly',
      },
      {
        description: 'Pay in full',
        label: '',
        value: 'Pay in full',
      },
    ],
    [],
  );

  const handleChangeFrequency = useCallback(
    (value: string) => {
      setSharedState({
        ...(sharedState as MakePaymentInformation),
        paymentFrequency:
          paymentFrequencyOptions.find((option) => option.value === value)?.description ===
          'Pay in full'
            ? 'Pay in full'
            : 'Monthly',
      });
    },
    [setSharedState, sharedState, paymentFrequencyOptions],
  );

  return (
    <Stack className={classes.root} spacing={3}>
      <Stack spacing={1}>
        <Stack direction='row' spacing={1.5}>
          <span className={classes.paymentMethodHeading}>Payment method</span>{' '}
          <span className={classes.paymentMethod}>Credit card</span>
        </Stack>
        <span className={classes.paymentSubtext}>
          AutoPay is currently available only with a credit card.
        </span>
      </Stack>
      <Stack spacing={3}>
        <Stack spacing={1.5}>
          <p className={classes.dateHeading}>Payment frequency</p>
          <div>
            <RadioGroupWithOptions
              {...paymentFrequencyField}
              variant='largeRadioCard'
              options={paymentFrequencyOptions}
              trackingName='payment_frequency_type_selection'
              className={classes.radioLabel}
              actionOnComplete={handleChangeFrequency}
            />
          </div>
        </Stack>
        <Alert withIcon type='info'>
          <p>AutoPay will begin on the next payment due date.</p>
        </Alert>
      </Stack>
      <span className={classes.paymentDetails}>Next, we'll collect your payment information.</span>
    </Stack>
  );
});
