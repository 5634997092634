import type { PersistentStorage } from './storage';

/**
 * Saves wrapped object into storage each time any of the object property value gets set.
 *
 * @param obj Object on which to set the trap.
 * @param storage Storage into which to save updated object.
 * @param moduleName Key by which to save updated object into storage.
 */
export const proxy = <T extends ConstructorParameters<typeof Proxy>[0]>(
  obj: T,
  storage: PersistentStorage,
  moduleName: string,
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
) =>
  new Proxy<T>(obj, {
    set(target, prop, value) {
      target[prop as keyof T] = value;
      storage.setItem(moduleName, target);

      return true;
    },
  });
