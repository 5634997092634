import { forwardRef, memo, useCallback } from 'react';

import { FormControl, FormControlLabel, FormHelperText } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';

import { Checkbox } from '@ecp/components';
import type { CheckboxProps } from '@ecp/components';

import { useStyles } from './CheckboxField.styles';

interface CheckboxFieldProps extends Omit<CheckboxProps, 'value'> {
  checked: boolean;
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  error?: string;
  label: React.ReactNode;
  trackingName?: string;
}

export const CheckboxField: React.FC<CheckboxFieldProps> = memo(
  forwardRef((props, ref) => {
    const { classes } = useStyles(undefined, { props });
    const { error, label, onChange, trackingName, ...rest } = props;

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => {
        onChange?.(event, newChecked);
        if (trackingName) {
          trackClick({ action: trackingName, label: newChecked ? 'Yes' : 'No' });
        }
      },
      [onChange, trackingName],
    );

    return (
      <FormControl className={classes.root} error={!!error}>
        <FormControlLabel
          control={<Checkbox {...rest} ref={ref} value='on' onChange={handleChange} />}
          label={<div className={classes.label}>{label}</div>}
        />
        {!!error && (
          <FormHelperText role='alert' title={error} classes={{ root: classes.error }}>
            {error}
          </FormHelperText>
        )}
      </FormControl>
    );
  }),
);
