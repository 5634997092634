import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'DashboardPage' })((theme) => ({
  root: {},
  contactUsBody: {
    [theme.breakpoints.up('lg')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
  contactUsButton: { [theme.breakpoints.up('lg')]: { margin: '16px 0 0' } },
  contactUsDivider: { [theme.breakpoints.up('lg')]: { display: 'none' } },
  icon: theme.mixins.setColorSvg(theme.palette.grey[900]),
  secondaryContent: { [theme.breakpoints.up('lg')]: { minWidth: 332 } },
}));
