import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'GuestLayout' })((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[200],
    flexGrow: 1,
    borderBottom: `1px solid ${theme.palette.other.border}`,
  },
  main: {
    width: '100%',
  },
}));
