import { flagValues } from '@ecp/utils/flags';

import type { UiPolicy } from '@ecp/features/servicing/shared/types';

import type { BillingResponse } from '../billing';
import { getAddressByRefId } from './getAddressByRefId';
import { getProductLineFromPolicyResponse } from './getProductLineFromPolicyResponse';
import { isInPendingCancelStatus } from './isInPendingCancelStatus';

export const getMaxVehicles = (riskState: string): number =>
  ['ID', 'KS'].includes(riskState) ? 4 : 6;

export const showAddVehicle = (
  billingInfo: BillingResponse | undefined,
  policyData: UiPolicy | undefined,
): { showAddVehicleLink: boolean; showVehicleAlert: boolean } => {
  let showAddVehicleLink = false,
    showVehicleAlert = false;
  const vehicleCount = policyData?.vehicles?.length;
  const riskState = getAddressByRefId(policyData, policyData?.policy.primaryAddressRefId)?.state;
  if (!vehicleCount || !billingInfo || !policyData || !riskState)
    return { showAddVehicleLink, showVehicleAlert };

  const isPendingCancellation = isInPendingCancelStatus({
    billingResponse: billingInfo,
    productLine: getProductLineFromPolicyResponse(policyData),
    policySource: policyData.policy.sourceSystemName,
    policyData: policyData,
  });

  const canAddMoreVehicles = vehicleCount < getMaxVehicles(riskState);

  showAddVehicleLink = !isPendingCancellation && flagValues.ADD_VEHICLE && canAddMoreVehicles;
  showVehicleAlert = !isPendingCancellation && flagValues.ADD_VEHICLE && !canAddMoreVehicles;

  return { showAddVehicleLink, showVehicleAlert };
};
