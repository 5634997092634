import { useEffect } from 'react';

import { useInteractionId } from '@ecp/utils/analytics/interaction-id';
import { userAuth } from '@ecp/utils/auth';
import { setGlobalContext } from '@ecp/utils/logger';

import { useDecodedParams } from '@ecp/features/servicing/shared/util';

import { useUser } from '../users';

export const useLogger = (): void => {
  const interactionId = useInteractionId().get();
  useEffect(() => {
    if (interactionId) setGlobalContext('interactionId', interactionId);
  }, [interactionId]);

  const { user } = useUser({ ignoreUserAuth: true, throwOnError: false });
  useEffect(() => {
    setGlobalContext('sessionOktaId', userAuth.userId);
    setGlobalContext('policyNumbers', user?.policyNumbers);
  }, [user]);
};

export const useLoggerWithAuth = (): void => {
  const { policyNumber } = useDecodedParams();
  useEffect(() => {
    setGlobalContext('policyNumber', policyNumber);

    return () => {
      setGlobalContext('policyNumber', undefined);
    };
  }, [policyNumber]);
};
