import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ModalError' })((theme) => ({
  root: {},
  errorIconWrapper: {
    textAlign: 'center',
  },
  text: theme.typography.body1,
  closeButton: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 'fit-content',
    },
  },
  icon: { ...theme.mixins.setColorSvg(theme.palette.error.main), width: 64, height: 64 },
}));
