import * as yup from 'yup';

import type { YupSchemaMap } from '@ecp/utils/form';

import { PasswordSchema } from '@ecp/features/servicing/shared/components';

export interface PaswordChangeFormInputs {
  password?: string;
  newPassword?: string;
  confirmNewPassword?: string;
}

export const passwordChangeSchema: YupSchemaMap<PaswordChangeFormInputs> = {
  password: yup.string().required(),
  newPassword: PasswordSchema.getPasswordSchema(),
  confirmNewPassword: yup
    .string()
    .required('Confirm new password is a required field')
    .oneOf([yup.ref('newPassword'), null], 'Passwords do not match'),
};
