import { useEffect, useState } from 'react';

import { useFormContext } from '@ecp/utils/form';

import type { ButtonProps, ButtonVariant } from '@ecp/components';
import { Button } from '@ecp/components';

import { LoadingOverlay } from '../LoadingOverlay';

interface Props extends Omit<ButtonProps, 'variant'> {
  variant?: ButtonVariant; // allow for nullable variant since we provide default
}

const delayInSeconds = 3;

export const LoadingButton: React.FC<Props> = (props) => {
  const { disabled, children, variant, isProcessing: isProcessingProp, ...rest } = props;
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);
  const formContext = useFormContext();
  const [isProcessing, setIsProcessing] = useState(false);

  // use useState to manage isProcessing to to smooth out super quick changes so we don't get a flash of the loading/processing icon
  useEffect(() => {
    setIsProcessing(isProcessingProp ?? formContext?.formState.isSubmitting);
  }, [isProcessingProp, formContext?.formState.isSubmitting]);

  // logic to show the loading overlay
  useEffect(() => {
    const loadingOverlayTimer = isProcessing
      ? setTimeout(() => setShowLoadingOverlay(true), delayInSeconds * 1000)
      : setShowLoadingOverlay(false); // always set to false to ensure overlay is hidden

    // this will clear the setTimeout when component is destroyed like in willComponentUnmount
    return () => {
      loadingOverlayTimer && clearTimeout(loadingOverlayTimer);
    };
  }, [isProcessing]);
  const content = isProcessing ? 'Loading' : children;
  const isDisabled = disabled || isProcessing;
  const buttonVariant = variant || 'primary'; // default to primary
  const ariaLabel = typeof children === 'string' ? children : 'Loading Button';

  return (
    <>
      <Button
        variant={buttonVariant}
        disabled={isDisabled}
        isProcessing={isProcessing}
        aria-label={ariaLabel}
        {...rest}
      >
        {content}
      </Button>
      {showLoadingOverlay && <LoadingOverlay />}
    </>
  );
};
