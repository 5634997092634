import { useCallback } from 'react';

import { ServicingModal } from '@ecp/features/servicing/shared/components';

import type { UseOnMessageReturn } from './useOnMessage';

type RedirectToSafeliteModalProps = Pick<
  UseOnMessageReturn,
  'launchSafelite' | 'shouldLaunchSafelite'
>;
export const RedirectToSafeliteModal: React.FC<RedirectToSafeliteModalProps> = (props) => {
  const { launchSafelite, shouldLaunchSafelite } = props;

  const handleGoToSafelite = useCallback(() => {
    launchSafelite();
  }, [launchSafelite]);

  return (
    <ServicingModal
      title='Redirecting to Safelite'
      body={
        <p>To file your glass claim, we will direct you to our partner Safelite in a new window.</p>
      }
      buttonText='Go to Safelite'
      onButtonClick={handleGoToSafelite}
      open={shouldLaunchSafelite}
      onClose={handleGoToSafelite}
    />
  );
};
