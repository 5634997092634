import { datadogLog } from '@ecp/utils/logger';
import type { PersistentStorage } from '@ecp/utils/storage';

import type { Token } from '../token';
import { AuthError } from '../util';
import type {
  FetchAuthResponseBody,
  FetchTokensFromRefreshTokenResponse,
  FetchTokensFromRefreshTokenResponseBody,
  FetchTokensResponseBody,
  UserTokens,
} from './types';

type UserAuthUtilEvent = 'login' | 'logout';
export const tokenSessionName = 'userAuth.token';

export interface UserTokensStorageObj {
  authToken: UserTokens;
  expAt: number;
  userId: string;
}

// TODO Restructure auth library, extract common logic and leave it in utils/auth:
// https://theexperimentationlab.atlassian.net/browse/ECP-2182
export interface AuthUtil {
  /**
   * Whether the current session is authenticated or not.
   *
   * @readonly
   * @default false
   */
  isAuth: boolean;
  /**
   * Whether the current session is token is expired. User could be authenticated, but have an expired token.
   *
   * @readonly
   * @default true
   */
  isTokenExpired: boolean;
  /**
   * The current userId.
   *
   * @readonly
   */
  userId: string | null;
  /**
   * Returns token value if it's not expired, else fetches the new token and returns it.
   */
  token: Promise<UserTokens | null>;
  /**
   * Appends an event listener for the specified event type.
   * The event listener is appended to the event listener list only if the list of specified event type doesn't have such listener reference already.
   *
   * @readonly
   * @param type - Event type.
   * @param listener - Listener to be notified on the event of specified type.
   */
  addEventListener: (type: UserAuthUtilEvent, listener: () => unknown) => void;
  /**
   * Removes an event listener from the list of specified event type.
   *
   * @readonly
   * @param type - Event type.
   * @param listener - Listener to be removed from the list of specified event type.
   */
  removeEventListener: (type: UserAuthUtilEvent, listener: () => unknown) => void;
  /**
   * Logs in the user. Emits `login` event.
   *
   * @readonly
   * @param username - username.
   * @param password - password.
   * @throws {AuthError}
   */
  login: (username: string, password: string) => Promise<void>;
  /**
   * Logs out the user. Emits `logout` event.
   */
  logout: () => void;
}

/**
 * User authentication utility.
 */
export class UserAuthUtil implements AuthUtil {
  /**
   * Whether the current session is authenticated or not.
   *
   * @default false
   */
  private _isAuth = false;

  get isAuth(): boolean {
    return this._isAuth;
  }

  get isTokenExpired(): boolean {
    return this._token.isExpired;
  }

  /**
   * The current logged in userId.
   *
   * @default false
   */
  private _userId: string | null = null;

  get userId(): string | null {
    return this._userId;
  }

  /**
   * Event listener list to be notified on the specific event.
   */
  private listeners: Record<UserAuthUtilEvent, Array<() => unknown>> = {
    login: [],
    logout: [],
  };

  constructor(
    /**
     * User token(s).
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    private _token: Token<UserTokens>,
    /**
     * Fetch tokens.
     */
    private fetchTokens: (username: string, password: string) => Promise<FetchAuthResponseBody>,
    /**
     * Logout end okta server session.
     */
    private endToken: () => Promise<void>,
    /**
     * Fetch refresh tokens.
     */
    private fetchTokensFromRefreshToken: () => Promise<FetchTokensFromRefreshTokenResponse>,
    /**
     * Reference to `session` PersistentStorage instance.
     */
    private sessionStorage: PersistentStorage, // !TODO add localStorage impl to have an option for token to persist between the browser tabs // private localStorage?: PersistentStorage,
  ) {
    const accessTokenObj = this.sessionStorage.getItem(
      tokenSessionName,
    ) as unknown as UserTokensStorageObj;
    if (accessTokenObj) {
      this.loginSuccess(accessTokenObj);
      if (this._token.isExpired) this.refreshTokens();
    }
  }

  readonly addEventListener = (type: UserAuthUtilEvent, listener: () => unknown): void => {
    if (type in this.listeners) {
      if (!this.listeners[type].includes(listener)) this.listeners[type].push(listener);
    }
  };

  readonly removeEventListener = (type: UserAuthUtilEvent, listener: () => unknown): void => {
    if (type in this.listeners) {
      this.listeners[type] = this.listeners[type].filter(
        (nextListener) => nextListener !== listener,
      );
    }
  };

  /**
   * Handles fetch errors.
   *
   * @param res - Response object.
   * @returns Promise of Response JSON object.
   * @throws {AuthError}
   */
  private handleErrors = async (
    res: Response,
  ): Promise<FetchTokensResponseBody | FetchTokensFromRefreshTokenResponseBody> => {
    if (!res.ok) {
      let text = '';
      if (res.body) text = await res.text();
      throw new AuthError(text, res.status);
    }

    return res.json();
  };

  /**
   * Notifies all event listeneres from the list of specified event type.
   *
   * @param type - Event type.
   */
  private notifyListeners = (type: UserAuthUtilEvent): void =>
    this.listeners[type].forEach((listener) => listener());

  /**
   * Sets new token value and expiration time. Sets authenticated flag to `true`. Notifies all listeners of successful `login` event.
   *
   * @param tokenObj - UserTokensStorageObj with authToken, expAt, and userId.
   */
  private loginSuccess = ({ authToken, expAt, userId }: UserTokensStorageObj): void => {
    this._token.set(authToken, expAt);
    this.sessionStorage.setItem(tokenSessionName, { authToken, expAt, userId });
    this._isAuth = true;
    this._userId = userId;
    this.notifyListeners('login');
  };

  /**
   * Clears the token and removes token from `sessionStorage`.
   */
  private clear = (): void => {
    this._token.reset();
    this._isAuth = false;
    this._userId = null;
    this.sessionStorage.removeItem(tokenSessionName);
  };

  readonly login = (username: string, password: string): Promise<void> => {
    if (!username || !password) throw new AuthError('Username and password are required');

    return this.fetchTokens(username, password)
      .then(({ access_token: accessToken, id_token: idToken, expires_in = 3600, userId }) => {
        const authToken = { accessToken, idToken };
        const expAt = this._token.getTime() + expires_in;
        this.loginSuccess({ authToken, expAt, userId });
      })
      .catch((error) => {
        datadogLog({
          logType: 'error',
          message: `Login failure - ${error?.message}`,
          context: {
            logOrigin: 'libs/utils/auth/src/userAuth/util.ts',
            functionOrigin: 'login',
          },
          error,
        });

        this.clear();
        throw error;
      });
  };

  readonly logout = async (): Promise<void> => {
    if (!this.isAuth) throw new AuthError('Error to attempt logout not authenticated user');

    return this.endToken()
      .then(() => {
        this.clear();
        this.notifyListeners('logout');
      })
      .catch((error) => {
        datadogLog({
          logType: 'error',
          message: `Logout failure - ${error?.message}`,
          context: {
            logOrigin: 'libs/utils/auth/src/userAuth/util.ts',
            functionOrigin: 'logout',
          },
          error,
        });

        this.clear();
        this.notifyListeners('logout');
        throw error;
      });
  };

  /**
   * Fetches new access and refresh tokens
   */
  private refreshTokens = (): Promise<void> => {
    return this.fetchTokensFromRefreshToken()
      .then(this.handleErrors)
      .then(({ access_token: accessToken, id_token: idToken, expires_in }) => {
        const authToken = { accessToken, idToken };
        const expAt = this._token.getTime() + expires_in;
        this.loginSuccess({ authToken, expAt, userId: this._userId as string });
      })
      .catch((error) => {
        datadogLog({
          logType: 'error',
          message: `Refresh token failure - ${error?.message}`,
          context: {
            logOrigin: 'libs/utils/auth/src/userAuth/util.ts',
            functionOrigin: 'refreshTokens',
          },
          error,
        });
      });
  };

  get token(): Promise<UserTokens | null> {
    if (!this.isAuth) throw new AuthError('No user token is present');
    if (this._token.isExpired) {
      return this.refreshTokens().then(() => this._token.value);
    }

    return Promise.resolve(this._token.value);
  }
}
