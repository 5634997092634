import type { ReactNode } from 'react';

import type { CardOption } from '@ecp/types';

export const formatLabel = (label: ReactNode, classes: string): ReactNode => {
  const optional = ' (optional)';
  const hasOptional = String(label).includes(optional);
  if (hasOptional) {
    return (
      <>
        {String(label).replace(optional, '')}
        <span className={classes}>{optional}</span>
      </>
    );
  }

  return label;
};

// This function tested as part of RadioGroupWithOptions.test.tsx and UseShowMoreOrLess.test.tsx
export function getSortedOptions<T extends CardOption = CardOption>(optionsProp: T[]): T[] {
  // Create a brand new array. This is important for show more or less options
  const sortedOptionsProps = optionsProp.slice();

  // sort the input based on sortingKey
  sortedOptionsProps.sort((a, b) => {
    if (a.sortingKey && b.sortingKey) {
      return a.sortingKey < b.sortingKey ? -1 : a.sortingKey > b.sortingKey ? 1 : 0;
    } else {
      return 1;
    }
  });

  return sortedOptionsProps;
}
