import type { NavigateOptions, To } from 'react-router-dom';

import { useEvent } from '@ecp/utils/react';

import type { LinkProps } from '@ecp/components';
import { Link as LinkBase } from '@ecp/components';
import { usePageFlow } from '@ecp/features/servicing/shared/routing';

type Props = Omit<LinkProps, 'href'> &
  (
    | {
        to: To;
        options?: NavigateOptions;
        onClick?: NonNullable<LinkProps['onClick']>;
        delta?: never;
        href?: never;
      }
    | {
        delta: number;
        onClick?: NonNullable<LinkProps['onClick']>;
        to?: never;
        options?: never;
        href?: never;
      }
    | {
        href: string;
        onClick?: NonNullable<LinkProps['onClick']>;
        to?: never;
        options?: never;
        delta?: never;
      }
    | {
        href?: never;
        to?: never;
        options?: never;
        delta?: never;
        onClick: NonNullable<LinkProps['onClick']>;
      }
  );

/**
 * Routing-aware Link component.
 * The following mutually exclusive navigation-specific prop combinations are supported:
 * - `to` and (optionally) `options` - calls `navigate(to, options)`
 * - `delta` - calls `navigate(delta)`, you would normally pass `-1` to go back or `1` to go forward
 * - `href` - HTML `a` tag default navigation via href
 * - `onClick` - imperative navigation via callback
 *
 * `onClick` can also be passed in addition to any of the above combinations to run some logic prior to navigation.
 */
export const Link: React.FC<Props> = (props) => {
  const { onClick, ...rest } = props;
  const pageFlow = usePageFlow();

  const handleClick: NonNullable<Props['onClick']> = useEvent((event) => {
    if (onClick) onClick(event);

    if ('href' in props && props.href) return;

    if ('to' in props && props.to) {
      if (props.options) pageFlow.navigate(props.to, props.options);
      else pageFlow.navigate(props.to);
    } else if ('delta' in props && props.delta) {
      pageFlow.navigate(props.delta);
    }
  });

  if ('to' in rest) {
    const { to, options, ...newProps } = rest;
    // To aid ADA compliance, we can compute `href` prop based on `to` accounting for its type (string or Partial<Path>)

    return <LinkBase {...newProps} onClick={handleClick} />;
  }
  if ('delta' in rest) {
    const { delta, ...newProps } = rest;
    // To aid ADA compliance, we can compute `href` prop based on `to` accounting for its type (string or Partial<Path>)

    return <LinkBase {...newProps} onClick={handleClick} />;
  }

  return <LinkBase {...rest} onClick={handleClick} />;
};
