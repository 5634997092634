import { memo, useCallback, useEffect, useRef, useState } from 'react';

import type { TooltipProps } from '@mui/material';
import { Tooltip as MuiTooltip } from '@mui/material';

import { trackHover } from '@ecp/utils/analytics/tracking';

import { useStyles } from './Tooltip.styles';

// !TODO Revisit this implementation. Handlers need to take consumer handlers from props,
// run them in local handlers and pass local handlers to MuiTooltip and exclude them from props
// See https://github.com/mui-org/material-ui/pull/22376/files
// https://github.com/mui-org/material-ui/issues/17279
// https://github.com/eps1lon/material-ui/tree/master/packages/mui-material/src/Tooltip
/**
 * Wrapper around MUI Tooltip with an additional capability to close on ESC keypress.
 * Note: All tooltip usage should be pointing to this component until MUI adds ESC capability.
 */
export const Tooltip: React.FC<TooltipProps & { trackingName?: string }> = memo((props) => {
  const { trackingName, onClose, onOpen, ...rest } = props;
  const [open, setOpen] = useState(false);
  const { classes, cx } = useStyles();
  const hoverStartTime = useRef<number | null>(null);

  const handleOpen: NonNullable<React.ComponentProps<typeof MuiTooltip>['onOpen']> = useCallback(
    (event) => {
      if (trackingName) {
        hoverStartTime.current = Date.now();
      }
      onOpen?.(event);
      setOpen(true);
    },
    [onOpen, setOpen, trackingName],
  );

  const handleClose: NonNullable<React.ComponentProps<typeof MuiTooltip>['onClose']> = useCallback(
    (event) => {
      if (trackingName && hoverStartTime.current !== null) {
        const hoverTime = Date.now() - hoverStartTime.current;
        trackHover({ action: trackingName, label: `${hoverTime}ms`, objectType: 'tooltip' });
        hoverStartTime.current = null;
      }
      onClose?.(event);
      setOpen(false);
    },
    [onClose, setOpen, trackingName],
  );

  useEffect(() => {
    if (!open) return () => null;

    const handleKeyDown = (nativeEvent: KeyboardEvent): void => {
      if (nativeEvent.key === 'Escape') {
        handleClose(nativeEvent);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleClose, open]);

  return (
    <MuiTooltip
      classes={{ tooltip: cx(props.classes?.tooltip, classes.tooltip) }}
      disableInteractive={false}
      leaveTouchDelay={Infinity} // after open on mobile, keeping display till users touch close.
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      {...rest}
    />
  );
});
