import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import type { UiPolicy } from '@ecp/features/servicing/shared/types';

import { getProductLineFromPolicyResponse } from './getProductLineFromPolicyResponse';
import { isLegacyPolicy } from './isLegacyPolicy';

export const shouldMakeBillingCall = (policyResponse: UiPolicy | undefined): boolean => {
  const paymentsClassicFlag = flagValues[FeatureFlags.PAYMENTS_CLASSIC];
  if (!policyResponse) return false;

  return paymentsClassicFlag
    ? !policyResponse.isInactive
    : !policyResponse.isInactive &&
        !isLegacyPolicy(
          getProductLineFromPolicyResponse(policyResponse),
          policyResponse.policy.sourceSystemName,
        );
};
