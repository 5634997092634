import { userAuth } from '@ecp/utils/auth';

import { ServicingFooter } from '../ServicingFooter';
import { useStyles } from './PageLayout.styles';

export const PageLayout: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      {children}
      <ServicingFooter isLoggedIn={userAuth.isAuth} />
    </div>
  );
};
