import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'VehicleDriverSummaryCard' })((theme) => ({
  root: {},
  button: {
    width: 'max-content',
  },
  icon: {
    width: 18,
    height: 18,
    ...theme.mixins.setColorSvg(theme.palette.secondary.main),
  },
  deleteMessage: { ...theme.typography.body1, color: theme.palette.text.tertiary },
}));
