import { isType, merge } from '@ecp/utils/common';
import { location } from '@ecp/utils/routing';
import { sessionStorage } from '@ecp/utils/storage';

import type { EnvironmentAnyAppShared } from '@ecp/env';
import { env } from '@ecp/env';

import { moduleName } from './constants';

const extractEnvKeyFromSearchParamKey = (searchParamKey: string): string | null =>
  searchParamKey.startsWith('env.') ? searchParamKey.substring(4) : null;

const isValidEnvObject = (obj: unknown): obj is EnvironmentAnyAppShared =>
  !Array.isArray(obj) && isType(obj, Object) && Object.keys(obj).length > 0;

/**
 * Handles environment object overrides.
 *
 * Reads env object overrides from the URL query string params,
 * saves them into sessionStorage so overrides persist across page reloads and are scoped to a current tab and exp id.
 * Merges env objects in the following order:
 * `query string params -> sessionStorage -> @ecp/env`,
 * where objects to the left have higher precedence than objects to the right.
 *
 * Query string params overriding env object will look like: `?env.foo=%22foo%22&env.bar=true&env.fiz=[1,2,3,%22buz%22,{%22a%22:4}]`
 * which translates into an object `{ foo: "foo", bar: true, fiz: [1, 2, 3, "buz", { a: 4}] }`.
 *
 * NOTE: only serializable values can be overridden.
 */
export const handleEnvOverrides = (): void => {
  const envOverridesFromQuery = Object.entries(location.search).reduce((acc, [key, param]) => {
    const envKey = extractEnvKeyFromSearchParamKey(key);
    if (envKey && param) acc[envKey as keyof EnvironmentAnyAppShared] = JSON.parse(param) as never;

    return acc;
  }, {} as EnvironmentAnyAppShared);

  let savedEnvOverrides = sessionStorage.getItem(moduleName);
  if (!isValidEnvObject(savedEnvOverrides)) savedEnvOverrides = null;

  const envOverrides = merge({}, savedEnvOverrides, envOverridesFromQuery);
  if (!isValidEnvObject(envOverrides)) return;

  sessionStorage.setItem(moduleName, envOverrides);
  merge(env, envOverrides);
};
