import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'DocumentDeliveryCard' })((theme) => ({
  root: {
    height: 'auto',
    minWidth: '100%',
  },
  card: {
    borderRadius: 4,
    border: `1px solid ${theme.palette.other.border}`,
    boxShadow: 'none',
    paddingBottom: 24,
  },
  body: {
    paddingTop: 8,
  },
  button: {
    width: 'fit-content',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  callUsText: { paddingTop: 8 },
  deliveryTarget: theme.typography.body1Bold,
  spinner: { display: 'flex', justifyContent: 'center' },
}));
