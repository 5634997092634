import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'DashboardLayout' })((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[200],
    flexGrow: 1,
  },
  main: {
    width: '100%',
  },
}));
