import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { env } from '@ecp/env';

import { queryClient } from './reactQuery';

const reactDevTools =
  env.static.nodeEnv === 'development' ? <ReactQueryDevtools initialIsOpen={false} /> : undefined;

export const ReactQueryProvider: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;

  return (
    <QueryClientProvider client={queryClient}>
      {reactDevTools}
      {children}
    </QueryClientProvider>
  );
};
