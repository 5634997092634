import { forwardRef, memo } from 'react';

import { FormLabel } from '@mui/material';
import type { NumberFormatProps } from 'react-number-format';
import ReactNumberFormat from 'react-number-format';

import type { TrackingProps } from '@ecp/utils/analytics/tracking';
import {
  GoogleAnalyticsLabels,
  trackEntryComplete,
  trackEntryStart,
} from '@ecp/utils/analytics/tracking';
import { emptyObject } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';
import { useEvent } from '@ecp/utils/react';
import { isHTMLElementWithValue } from '@ecp/utils/web';

import type { AnswerValue } from '@ecp/types';

import { TextField } from '../TextField';
import { formatLabel } from '../utils';
import { useStyles } from './NumberFormat.styles';

type Classes = Partial<Record<'root' | 'input', string>>;

interface Props extends Omit<NumberFormatProps, 'size' | 'color'>, TrackingProps {
  actionOnChange?(value: AnswerValue): void;
  actionOnComplete?(value: AnswerValue): void;
  ariaLabel?: string;
  fullWidth?: boolean;
  groupLabel?: React.ReactNode;
  helperText?: React.ReactElement | string;
  label?: React.ReactNode;
  error?: string;
  formatType?: 'phone' | 'zipcode' | 'costcoMembershipNumber';
  classes?: Classes;
}

interface CommonProps {
  format: string;
  placeholder: string;
}

const PHONE_FORMAT = '###-###-####';
const ZIP_FORMAT = '#####';
const COSTCO_MEMBERSHIP_NUMBER = '############';

const getCommonProps = (formatType: Props['formatType']): Partial<CommonProps> => {
  switch (formatType) {
    case 'phone': {
      return { format: PHONE_FORMAT, placeholder: PHONE_FORMAT };
    }
    case 'zipcode': {
      return { format: ZIP_FORMAT };
    }
    case 'costcoMembershipNumber': {
      return { format: COSTCO_MEMBERSHIP_NUMBER };
    }
    default:
      return {};
  }
};

const normalizeValue = ({
  value,
  prefix,
  thousandSeparator,
}: {
  value: string;
  prefix: Props['prefix'];
  thousandSeparator: Props['thousandSeparator'];
}): string => {
  let result = value;
  if (prefix) result = result.substring(prefix.length);
  if (thousandSeparator) result = result.replace(/,/g, '');

  return result;
};

const INVALID_EVENT_TARGET_ERROR = 'Not a valid event target for NumberFormat';

/** Simple wrapper to allow our custom props to be passed through */
export const NumberFormat: React.FC<Props> = memo(
  forwardRef((props, ref) => {
    const {
      actionOnChange,
      actionOnComplete,
      className,
      classes: classesProp = emptyObject as unknown as Classes,
      formatType,
      groupLabel,
      onBlur,
      onChange,
      onFocus,
      prefix,
      thousandSeparator,
      trackingLabel,
      trackingName,
      value = '',
      label,
      ...rest
    } = props;
    const { classes, cx } = useStyles();
    const commonProps = getCommonProps(formatType);

    const handleChange: NonNullable<Props['onChange']> = useEvent((event) => {
      if (actionOnChange) {
        const value = normalizeValue({ value: event.target.value, prefix, thousandSeparator });
        actionOnChange(value);
        onChange?.(event);
      }
    });

    const handleFocus: NonNullable<Props['onFocus']> = useEvent((event) => {
      if (!isHTMLElementWithValue(event.target)) {
        datadogLog({
          logType: 'error',
          message: INVALID_EVENT_TARGET_ERROR,
          context: {
            logOrigin: 'libs/components/src/NumberFormat/NumberFormat.tsx',
            functionOrigin: 'handleOnFocus',
          },
        });
        throw new Error(INVALID_EVENT_TARGET_ERROR);
      }
      if (trackingName) {
        if (trackingName.toLowerCase().includes('phonenumber')) {
          trackEntryStart({ action: trackingName, label: GoogleAnalyticsLabels.REDACTED });
        } else {
          const value = normalizeValue({ value: event.target.value, prefix, thousandSeparator });
          trackEntryStart({ action: trackingName, label: trackingLabel || value });
        }
      }
      onFocus?.(event);
    });

    const handleBlur: NonNullable<Props['onBlur']> = useEvent((event) => {
      if (!isHTMLElementWithValue(event.target)) {
        datadogLog({
          logType: 'error',
          message: INVALID_EVENT_TARGET_ERROR,
          context: {
            logOrigin: 'libs/components/src/NumberFormat/NumberFormat.tsx',
            functionOrigin: 'handleOnBlur',
          },
        });
        throw new Error(INVALID_EVENT_TARGET_ERROR);
      }
      const value = normalizeValue({ value: event.target.value, prefix, thousandSeparator });
      if (actionOnComplete) actionOnComplete(value);
      if (trackingName) {
        const label = trackingName.toLowerCase().includes('phonenumber')
          ? GoogleAnalyticsLabels.REDACTED
          : trackingLabel || value;
        trackEntryComplete({
          action: trackingName,
          label,
        });
      }
      onBlur?.(event);
    });

    return (
      <div className={cx(classes.root, classesProp.root || className)}>
        {groupLabel && (
          <FormLabel
            component='label'
            error={false}
            focused={false}
            classes={{
              root: classes.label,
            }}
            id={props.name && `${props.name}-label`}
            htmlFor={props.id}
          >
            {groupLabel}
          </FormLabel>
        )}
        <ReactNumberFormat
          {...rest}
          {...commonProps}
          className={classesProp.input}
          customInput={TextField}
          prefix={prefix}
          value={value}
          inputRef={ref}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          thousandSeparator={thousandSeparator}
          label={formatLabel(label, classes.textTertiary)}
        />
      </div>
    );
  }),
);
