import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'MakePaymentSetup' })((theme) => ({
  root: {},
  paymentBorder: { borderRadius: 8 },
  dateHeading: { ...theme.typography.body3, paddingBottom: 4 },
  todayDate: theme.typography.h4,
  cardContent: { padding: 15 },
  radioLabel: {
    '& .MuiFormLabel-root': {
      paddingBottom: 12,
    },
  },
}));
