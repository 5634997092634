import { Stack } from '@mui/material';

import { AuthenticationCard } from '@ecp/features/servicing/shared/components';
import { getBrandDataWithFallback } from '@ecp/features/servicing/shared/util';
import { GraphicUIAgentAvatar } from '@ecp/themes/base';

import { useStyles } from './ContactUsPage.styles';
export const ContactUsPage: React.FC = () => {
  const brandData = getBrandDataWithFallback();
  const { classes } = useStyles();
  const body = (
    <Stack spacing={4} className={classes.root}>
      <Stack direction='row' spacing={1}>
        <div className={classes.iconWrapper}>
          <GraphicUIAgentAvatar />
        </div>
        <h1 className={classes.header}>Contact us for help</h1>
      </Stack>
      <Stack spacing={1.5}>
        <p className={classes.phone}>{brandData.mainCustServPhoneNum}</p>
        <div>
          {brandData.mainOperationHours.map((hours) => (
            <p key={hours} className={classes.hours}>
              {hours}
            </p>
          ))}
        </div>
      </Stack>
    </Stack>
  );

  return <AuthenticationCard>{body}</AuthenticationCard>;
};
