import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<void, 'iconSelected'>({ name: 'Select' })(
  (...[theme, , classes]) => ({
    root: {},
    control: {
      backgroundColor: '#fff',
      [`& > .${classes.iconSelected}`]: {
        color: 'transparent',
      },
    },
    input: {
      ...theme.typography.body1,
      backgroundColor: theme.palette.other.body,
    },
    formControl: {
      textAlign: 'left',
    },
    icon: {
      marginRight: 10,
      ...theme.mixins.setColorSvg(theme.palette.text.primary),
    },
    iconSelected: {
      height: 0,
      width: 0,
      ...theme.mixins.setColorSvg(theme.palette.text.primary),
    },
    fieldDisabled: {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.grey[200],
    },
    label: {
      paddingBottom: 15,
    },
    textTertiary: {
      color: theme.palette.text.tertiary,
    },
    subText: {
      marginBottom: 13,
    },
    list: {
      border: '1px solid #00000026',
      borderRadius: 4,
    },
    option: {
      ...theme.typography.body1,
      color: theme.palette.text.primary,
      paddingBottom: 8,
      paddingTop: 8,
      whiteSpace: 'break-spaces',
      [`& .${classes.iconSelected}`]: {
        height: 24,
        marginRight: 10,
        width: 24,
      },
      '&:hover, &.Mui-selected:hover': {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        '*, *::before, *::after': {
          fill: theme.palette.primary.contrastText,
        },
      },
      '&.Mui-selected': {
        color: 'inherit',
        backgroundColor: theme.palette.grey[50],
      },
    },
    popover: {
      ...theme.mixins.shadow1,
      borderRadius: 4,
    },
    selectMenu: {
      borderRadius: 4,
      padding: 10,
      height: 'auto',
    },
  }),
);
