import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'OtherPolicyLink' })((theme) => ({
  root: {
    ...theme.typography.button2,
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    maxWidth: 'fit-content',
  },
  arrowIcon: theme.mixins.setColorSvg(theme.palette.secondary.main),
}));
