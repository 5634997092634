import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ModalErrorFallback' })((theme) => ({
  root: {},
  image: {
    height: 'auto',
    maxWidth: 280,
    margin: 'auto',
  },
  text: theme.typography.body3,
  button: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 'fit-content',
    },
  },
}));
