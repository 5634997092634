import { useCallback } from 'react';
import type { MouseEventHandler } from 'react';

import { CircularProgress, Stack } from '@mui/material';

import { maskEmail } from '@ecp/utils/common';

import { Button, GridItem } from '@ecp/components';
import { useModalPath } from '@ecp/features/servicing/shared/routing';
import { useUser } from '@ecp/features/servicing/shared/state';
import { getMailingAddress } from '@ecp/features/servicing/shared/state';
import type { UiPolicy } from '@ecp/features/servicing/shared/types';

import { AddressDisplay } from '../AddressDisplay';
import { ApiAlert } from '../ApiAlert';
import { PolicyCard } from '../PolicyCard';
import { useStyles } from './DocumentDeliveryCard.styles';

export interface DocumentDeliveryCardType {
  policy: UiPolicy;
  isPaperlessOn?: boolean;
  isError?: boolean;
}

export const DocumentDeliveryCard: React.FC<DocumentDeliveryCardType> = (props) => {
  const { policy, isPaperlessOn, isError } = props;

  const { user, isLoading: isUserLoading } = useUser();
  const isLoading = isUserLoading;
  const modalPath = useModalPath();

  const { classes } = useStyles();
  const handleClick = useCallback<NonNullable<MouseEventHandler<HTMLButtonElement>>>(() => {
    modalPath.init(
      policy.policy.policyNumber,
      isPaperlessOn ? 'paperlessunenroll' : 'paperlessenroll',
    );
  }, [policy.policy.policyNumber, isPaperlessOn, modalPath]);
  const body = isError ? (
    <ApiAlert apiDescription='document delivery information' />
  ) : isLoading ? (
    <div className={classes.spinner}>
      <CircularProgress aria-label='Loading...' />
    </div>
  ) : (
    <Stack spacing={1.5}>
      <div className={classes.body}>
        <Stack spacing={0.5}>
          <p>
            {isPaperlessOn ? (
              <>Notifications about new documents will be sent by email to:</>
            ) : (
              <>Documents will be sent by mail to:</>
            )}
          </p>
          {isPaperlessOn ? (
            <p className={classes.deliveryTarget}>{maskEmail(user?.email)}</p>
          ) : (
            <div>
              <AddressDisplay
                policyAddress={getMailingAddress(policy)}
                classes={{ text: classes.deliveryTarget }}
              />
            </div>
          )}
        </Stack>
      </div>
      <Button
        variant='outlinePrimary'
        onClick={handleClick}
        className={classes.button}
        trackingLabel='edit_continue'
        trackingName='edit_button'
        trackingDimensions={{ active_policy_number: policy.policy.policyNumber }}
      >
        EDIT
      </Button>
    </Stack>
  );

  if (isLoading) return <CircularProgress aria-label='Loading...' />;

  return (
    <GridItem md={6} sm={12} topSpacing={2} className={classes.root}>
      <PolicyCard className={classes.card} policyDetails={policy} headerDivider body={body} />
    </GridItem>
  );
};
