export interface Token<T = string> {
  /**
   * Token value.
   */
  value: T | null;
  /**
   * Returns current time in seconds as UNIX timestamp (UTC).
   */
  getTime: () => number;
  /**
   * Whether the token is expired accounting for deviation.
   * So we make sure, that the token we're passing in the requests won't expire in transmittion and during token validation on the back-end.
   */
  isExpired: boolean;
  /**
   * Sets new token properties' values.
   */
  set(value?: T, expAt?: number, deviation?: number): void;
  /**
   * Resets token properties.
   */
  reset(): void;
}

/**
 * Token utility.
 */
export class TokenUtil<T = string> implements Token<T> {
  /**
   * Token value.
   *
   * @default null
   */
  value: T | null = null;

  /**
   * Returns current time in seconds as UNIX timestamp (UTC).
   */
  getTime: () => number;

  /**
   * Expiration time in seconds as UNIX timestamp (UTC).
   *
   * @default 0
   */
  private expAt = 0;

  /**
   * Time in seconds. Used in `isExpired` method where is substracted from the current time.
   * So we make sure, that the token we're passing in the requests won't expire in transmittion and during token validation on the back-end.
   *
   * @default 60
   */
  private deviation = 60;

  /**
   * @param getTime - Returns current time in seconds as UNIX timestamp (UTC).
   */
  constructor(getTime: () => number) {
    this.getTime = getTime;
  }

  /**
   * Sets new token properties' values.
   *
   * @param value - New token value.
   * @param expAt - New expiration time in seconds.
   * @param deviation - New deviation value in seconds.
   */
  set(value?: T, expAt?: number, deviation?: number): void {
    this.value = value ?? this.value;
    this.expAt = expAt !== null && expAt !== undefined && expAt >= 0 ? expAt : this.expAt;
    this.deviation =
      deviation !== null && deviation !== undefined && deviation >= 0 ? deviation : this.deviation;
  }

  /**
   * Resets token properties.
   */
  reset(): void {
    this.value = null;
    this.expAt = 0;
    this.deviation = 60;
  }

  /**
   * Whether the token is expired accounting for deviation.
   * So we make sure, that the token we're passing in the requests won't expire in transmittion and during token validation on the back-end.
   */
  get isExpired(): boolean {
    if (!this.value) return true;

    return !(this.expAt - this.deviation > this.getTime());
  }
}
