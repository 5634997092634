import { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { logPageView } from '@ecp/utils/analytics/tracking';
import { userAuth, useUserAuth } from '@ecp/utils/auth';
import { flagValues } from '@ecp/utils/flags';
import { useHotjar } from '@ecp/utils/hotjar';
import { scrollToTop } from '@ecp/utils/web';

import { env } from '@ecp/env';
import { LogoutPage } from '@ecp/features/servicing/authentication';
import { CallbackHandler } from '@ecp/features/servicing/payments';
import { PageLayout } from '@ecp/features/servicing/shared/components';
import {
  AuthenticationLayout,
  DashboardLayout,
  GuestLayout,
  SetupWrapper,
} from '@ecp/features/servicing/shared/layouts';
import { PAGE_PATH, useModalPath, usePageFlow } from '@ecp/features/servicing/shared/routing';
import {
  clearAllItemsFromLoggedInSessionStorage,
  useAnalyticsConsumer,
  useChat,
  useLogger,
} from '@ecp/features/servicing/shared/state';
import { getChatEnabled } from '@ecp/features/shared/chat';

import { AppErrorBoundary } from '../components/AppErrorBoundary';
import { AuthenticatedUserRedirect } from '../components/AuthenticatedUserRedirect';
import { RequiredAuth } from '../components/RequiredAuth';
import { MaintenancePage } from '../pages/MaintenacePage';

/** Ensures page is scrolled to the top when navigating. */
const useScrollTopOnNavigate = (): void => {
  const { pathname } = useLocation();

  useEffect(() => scrollToTop(), [pathname]);
};

/** Clear react query cache when user logs out */
const useResetReactQueryOnLogout = (): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    const handleLogout = async (): Promise<void> => {
      await queryClient.cancelQueries();
      queryClient.clear();
    };
    userAuth.addEventListener('logout', handleLogout);

    return () => userAuth.removeEventListener('logout', handleLogout);
  }, [queryClient]);
};

/**  remove information saved in session storage */
const useClearSessionStorage = (): void => {
  useEffect(() => {
    userAuth.addEventListener('logout', clearAllItemsFromLoggedInSessionStorage);

    return () => userAuth.removeEventListener('logout', clearAllItemsFromLoggedInSessionStorage);
  }, []);
};

/**
 * Custom hook for tracking page navigation and analytics.
 */
const usePageTracking = (): void => {
  const { pathname } = useLocation();
  const { currentPage } = usePageFlow();
  const { currentFlow } = useModalPath();
  useEffect(
    () => logPageView(pathname, currentFlow === 'pay' ? 'MAKE_PAYMENT' : currentPage),
    [currentPage, pathname, currentFlow],
  );
};
const LayoutWrapper: React.FC = () => (
  <PageLayout>
    <SetupWrapper />
  </PageLayout>
);

const AppWrapper: React.FC<React.PropsWithChildren> = () => {
  useUserAuth();
  useScrollTopOnNavigate();
  useResetReactQueryOnLogout();
  useLogger();
  useClearSessionStorage();
  useAnalyticsConsumer();
  usePageTracking();
  if (env.hotjarEnabled)
    /* eslint-disable react-hooks/rules-of-hooks */
    useHotjar({
      trackingId: env.hotjarTrackingId as string,
      snippetVersion: env.hotjarSnippetVersion,
    });

  if (getChatEnabled()) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useChat();
  }

  return (
    <Routes>
      <Route path='/claims/mock' element={<div style={{ height: '400px' }}>claims mock</div>} />
      <Route
        path='/callback/worldpay/mock'
        element={<div style={{ height: '400px' }}>callback worldpay mock</div>}
      />

      <Route
        path={PAGE_PATH.CALLBACK_WORLDPAY}
        element={<CallbackHandler messageName='WORLDPAY_TRANSACTION' />}
      />
      <Route path={PAGE_PATH.LOGOUT} element={<LogoutPage />} />

      <Route element={<AuthenticatedUserRedirect />}>
        {/* LayoutWrapper wrapping each layout individually to avoid a footer-only screen when transitioning from one layout to another */}
        <Route element={<LayoutWrapper />}>
          <Route path={`${PAGE_PATH.AUTH_INDEX}/*`} element={<AuthenticationLayout />} />
        </Route>
      </Route>
      <Route element={<LayoutWrapper />}>
        <Route path={`${PAGE_PATH.GUEST_INDEX}/*`} element={<GuestLayout />} />
      </Route>
      <Route element={<RequiredAuth />}>
        <Route element={<LayoutWrapper />}>
          <Route path={`${PAGE_PATH.DASHBOARD_INDEX}/*`} element={<DashboardLayout />} />
        </Route>
      </Route>
      <Route path='*' element={<Navigate replace to={PAGE_PATH.AUTH_INDEX} />} />
    </Routes>
  );
};

const MaintenanceLayout: React.FC = () => {
  // If user lands on this layout when authenticated need to logout in order to show logged out header
  if (userAuth.isAuth) userAuth.logout();

  return (
    <Routes>
      <Route path={PAGE_PATH.MAINTENANCE} element={<MaintenancePage />} />
      <Route path={`${PAGE_PATH.GUEST_INDEX}/*`} element={<GuestLayout />} />
      <Route path='*' element={<Navigate replace to={PAGE_PATH.MAINTENANCE} />} />
    </Routes>
  );
};

export const App: React.FC = () => (
  <AppErrorBoundary>
    {flagValues.DISABLE_SERVICING_APP ? <MaintenanceLayout /> : <AppWrapper />}
  </AppErrorBoundary>
);
