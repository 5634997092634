import { merge } from '@ecp/utils/common';

import type { PaletteOverrides } from '@ecp/themes/base';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { palette as basePalette } from '../../../../base/src';

const paletteOverrides: PaletteOverrides = {
  actions: {
    disabledBg: '#eaeaea',
    disabledText: '#78777b',
  },
  error: {
    contrastText: '#ffffff',
    dark: '#7a1810',
    darkText: '#3d3936',
    light: '#fbe9e5',
    main: '#a52116',
  },
  grey: {
    '50': '#ffffff',
    '100': '#fafafa',
    '200': '#f2f2f2',
    '300': '#eaeaea',
    '400': '#d4d4d4',
    '500': '#cccccc',
    '600': '#afaeb0',
    '700': '#78777b',
    '800': '#4c4c4c',
    '900': '#3d3935',
  },
  icon: {
    dark: '#3d3936',
    default: '#ffffff',
    hover: '#0072ce',
    stroke: '#78777b',
  },
  info: {
    contrastText: '#ffffff',
    dark: '#3d3936',
    darkText: '#3d3936',
    light: '#e9f9fe',
    main: '#3070c7',
  },
  other: {
    backdrop: '#00000066',
    background: '#1c4e75',
    backgroundContrastText: '#ffffff',
    body: '#ffffff',
    border: '#c3c3c3',
    caution: '#a26401',
    cookieBanner: '#ffffff',
    divider: '#c3c3c3',
    snackbar: '#4c4c4c',
    tooltip: '#4c4c4c',
  },
  partner: {
    footerBackground: '#ffffff',
    footerTextLink: '#0072ce',
    footerTextPrimary: '#4c4c4c',
    footerTextSecondary: '#ffffff',
    headerBackground: '#ffffff',
  },
  primary: {
    background: '#f2f2f2',
    border: '#0072ce',
    contrastText: '#ffffff',
    dark: '#19345d',
    darkText: '#19345d',
    light: '#f4f8ff',
    main: '#0072ce',
  },
  secondary: {
    background: '#f2f2f2',
    border: '#05c3dd',
    contrastText: '#ffffff',
    dark: '#01272c',
    darkText: '#024e58',
    light: '#d1f9fe',
    main: '#0072CE',
  },
  shadow: {
    primary: '#00000033',
  },
  success: {
    complete: '#ffffff',
    contrastText: '#ffffff',
    dark: '#3a542b',
    darkText: '#3d3936',
    light: '#fafff5',
    main: '#52773d',
  },
  text: {
    disabled: '#78777b',
    hover: '#1c4e75',
    link: '#0072ce',
    primary: '#3d3935',
    secondary: '#4c4c4c',
    tertiary: '#6f6e72',
  },
  warning: {
    contrastText: '#ffffff',
    dark: '#644d1c',
    darkText: '#3d3936',
    light: '#fef8e7',
    main: '#b28932',
  },
};

export const palette = merge({}, basePalette, paletteOverrides);
