import { CircularProgress, Divider, Stack } from '@mui/material';

import { isTruthy } from '@ecp/utils/common';

import { AutoSummaryCardList, Card } from '@ecp/features/servicing/shared/components';
import {
  getPersonByRefId,
  getProductLineFromPolicyResponse,
  usePolicy,
} from '@ecp/features/servicing/shared/state';

import { PolicyDetails } from './PolicyDetails';

interface Props {
  policyNumber: string;
}

export const DetailsSection: React.FC<Props> = (props) => {
  const { policyNumber } = props;
  const { policyData, isLoading } = usePolicy(policyNumber);

  if (isLoading) return <CircularProgress aria-label='Loading...' />;

  return (
    <Card
      body={
        <Stack spacing={4}>
          <PolicyDetails policyNumber={policyNumber} />
          {getProductLineFromPolicyResponse(policyData) === 'AUTO' && (
            <>
              <Divider />
              <AutoSummaryCardList
                policyNumber={policyNumber}
                vehicles={policyData?.vehicles}
                drivers={policyData?.drivers
                  ?.filter((driver) => driver.driverStatus === 'INCLUDED')
                  ?.map((driver) => getPersonByRefId(policyData, driver.personRefId))
                  .filter(isTruthy)}
                policySource={policyData?.policy.sourceSystemName}
              />
            </>
          )}
        </Stack>
      }
    />
  );
};
