import { useCallback } from 'react';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { Button } from '@ecp/components';

import type { EmailSection } from '../../pages/ProfilePage';
import { useStyles } from './DisplayEmail.styles';

export interface DisplayEmailProps {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  setEmailSection: (emailSection: EmailSection) => void;
}

export const DisplayEmail: React.FC<DisplayEmailProps> = (props) => {
  const { setEmailSection } = props;
  const { classes } = useStyles(undefined, { props });

  const onEditClick = useCallback((): void => {
    setEmailSection('Update Email');
  }, [setEmailSection]);

  return (
    <div>
      <Button
        onClick={onEditClick}
        variant='outlinePrimary'
        data-testid='EditEmail'
        className={classes.root}
        trackingName='edit_email_button'
        trackingLabel={GoogleAnalyticsLabels.CONTINUE}
      >
        Edit
      </Button>
    </div>
  );
};
