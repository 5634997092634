import * as yup from 'yup';

import type { YupSchemaMap } from '@ecp/utils/form';

export interface UpdateEmailFormInput {
  email?: string;
  confirmEmail?: string;
}

export const updateEmailSchema: YupSchemaMap<UpdateEmailFormInput> = {
  email: yup.string().email().required('New email is a required field'),
  confirmEmail: yup
    .string()
    .email('Confirm new email must be a valid email')
    .required('Confirm new email is a required field')
    .oneOf([yup.ref('email'), null], 'Emails do not match'),
};
