import { CircularProgress, Typography } from '@mui/material';

import { Alert } from '@ecp/components';
import { PolicyCard } from '@ecp/features/servicing/shared/components';
import type { UsePaymentInfoReturn } from '@ecp/features/servicing/shared/state';

import { useStyles } from './PaymentsDueCard.styles';
import { PaymentsDueCardBody } from './PaymentsDueCardBody';

const ApiError: React.FC = () => (
  <Alert type='error' withIcon>
    <Typography variant='body1Bold'>Your payment cannot be made online at this time.</Typography>
    <p>Please try again later.</p>
  </Alert>
);

interface Props {
  paymentInfoResult: UsePaymentInfoReturn;
}

export const PaymentsDueCard: React.FC<Props> = (props) => {
  const {
    paymentInfoResult: { paymentInfo, isError, isLoading },
  } = props;
  const { classes } = useStyles();

  const policyNumber = paymentInfo.policyNumber;

  if (isLoading || !policyNumber) {
    return <CircularProgress aria-label='Loading...' />;
  }

  const headerProps = paymentInfo.policyData
    ? { policyDetails: paymentInfo.policyData }
    : {
        policyNumber,
        policyDisplayType: 'Policy',
      };

  return (
    <PolicyCard
      className={classes.root}
      {...headerProps}
      body={
        <div className={classes.body}>
          {isError ? <ApiError /> : <PaymentsDueCardBody paymentInfo={paymentInfo} />}
        </div>
      }
    />
  );
};
