import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AlertsSection' })((theme) => ({
  root: {},
  paymentsDueSection: { textAlign: 'center' },
  paymentsDueText: {
    ...theme.typography.body3Bold,
    color: theme.palette.text.disabled,
  },
}));
