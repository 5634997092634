import { forwardRef, memo, useCallback, useState } from 'react';

import * as yup from 'yup';

import { omit } from '@ecp/utils/common';

import type { TextFieldProps } from '@ecp/components';
import { TextField } from '@ecp/components';
import { IconMaterialDesignActionDone, IconMaterialDesignNavigationEast } from '@ecp/themes/base';

import {
  getPasswordLowercaseSchema,
  getPasswordMaximumLengthSchema,
  getPasswordMinimumLengthSchema,
  getPasswordNumberSchema,
  getPasswordSpecialCharacterSchema,
  getPasswordUppercaseSchema,
} from './PasswordField.schema';
import { useStyles } from './PasswordField.styles';

export interface PasswordFieldProps extends TextFieldProps {
  passwordRequirements?: {
    showRequirements?: boolean;
    minLength?: number;
    maxLength?: number;
  };
}

interface PasswordRequirementChecks {
  minLength?: string;
  maxLength?: string;
  hasLowercase?: string;
  hasUppercase?: string;
  hasNumber?: string;
  hasSpecialCharacter?: string;
}

export const PasswordField: React.FC<PasswordFieldProps> = memo(
  forwardRef((props, ref) => {
    const {
      actionOnComplete,
      onBlur,
      value = '',
      actionOnChange,
      onChange,
      passwordRequirements: { showRequirements, minLength = 8, maxLength = 16 } = {},
      error,
      groupLabel = 'Password',
      fullWidth,
    } = props;
    const { classes, cx } = useStyles();

    // password requirements logic
    const [passwordRequirementChecks, setPasswordRequirementChecks] =
      useState<PasswordRequirementChecks>({});

    const validatePassword = useCallback<NonNullable<TextFieldProps['onChange']>>(
      async (event): Promise<void> => {
        try {
          const passwordValue = event.target.value;
          const passwordPiecesSchema = yup.object().shape({
            minLength: getPasswordMinimumLengthSchema(minLength),
            maxLength: getPasswordMaximumLengthSchema(maxLength),
            hasLowercase: getPasswordLowercaseSchema(),
            hasUppercase: getPasswordUppercaseSchema(),
            hasNumber: getPasswordNumberSchema(),
            hasSpecialCharacter: getPasswordSpecialCharacterSchema(),
          });
          const validationObject: PasswordRequirementChecks = {
            minLength: passwordValue,
            maxLength: passwordValue,
            hasLowercase: passwordValue,
            hasUppercase: passwordValue,
            hasNumber: passwordValue,
            hasSpecialCharacter: passwordValue,
          };
          await passwordPiecesSchema.validate(validationObject, {
            abortEarly: false, // validate all checks, do not abort on first failure
          });
          setPasswordRequirementChecks({});
        } catch (validateError) {
          if (validateError instanceof yup.ValidationError) {
            // expected yup validation error
            let passwordRequirement: PasswordRequirementChecks = {};
            validateError.inner.forEach((validateEachError: yup.ValidationError) => {
              passwordRequirement = {
                ...passwordRequirement,
                [String(validateEachError.path)]: validateEachError.message,
              };
            });
            setPasswordRequirementChecks(passwordRequirement);
          } else {
            // unexpected failure
            console.warn(validateError);
          }
        }
      },
      [maxLength, minLength],
    );

    // event handlers
    const [hasBlurred, setHasBlurred] = useState(false);
    const handleBlur = useCallback<NonNullable<TextFieldProps['onBlur']>>(
      (event) => {
        // intercept onBlur
        const inputValue = event.target.value;
        if (actionOnComplete) {
          actionOnComplete(inputValue);
        }
        if (onBlur) {
          onBlur(event);
        }
        // only set has blurred if the requirements are visible and if user has already typed
        if (showRequirements && inputValue.length > 0) {
          setHasBlurred(true);
        }
      },
      [actionOnComplete, onBlur, showRequirements],
    );

    const handleChange = useCallback<NonNullable<TextFieldProps['onChange']>>(
      (event) => {
        // intercept onChange
        const inputValue = event.target.value;
        if (actionOnChange) {
          actionOnChange(inputValue);
        }
        if (onChange) {
          onChange(event);
        }
        // only validate password if the requirements are visible
        if (showRequirements) {
          setHasBlurred(false);
          validatePassword(event);
        }
      },
      [actionOnChange, onChange, showRequirements, validatePassword],
    );

    const propsToPassToTextField = { ...omit(props, ['passwordRequirements']) }; // always exclude password requirements
    const hasError =
      error || (!!value && hasBlurred && Object.keys(passwordRequirementChecks).length > 0);

    const renderRequirementIcon = (isSuccessful: boolean): React.ReactNode => {
      // showRequirements icon if there's an input value and the check is successful
      const hasValue = !!value;
      const showSuccess = hasValue && isSuccessful;
      const showError = hasValue && hasBlurred;

      return showSuccess ? (
        <IconMaterialDesignActionDone className={classes.requirementIcon} role='presentation' />
      ) : (
        <IconMaterialDesignNavigationEast
          className={cx(
            classes.requirementIcon,
            classes.requirementArrow,
            showError && classes.requirementError,
          )}
          role='presentation'
        />
      );
    };

    return (
      <div className={classes.passwordFieldContainer}>
        <TextField
          {...propsToPassToTextField}
          groupLabel={groupLabel}
          onChange={handleChange}
          onBlur={handleBlur}
          type='password'
          error={hasError ? 'Enter a password with all the requirements' : ''}
          inputRef={ref}
          fullWidth={fullWidth}
        />
        {showRequirements && (
          <div className={classes.body2}>
            <p>
              {renderRequirementIcon(
                !passwordRequirementChecks.minLength && !passwordRequirementChecks.maxLength,
              )}{' '}
              <span className={classes.requirementText}>
                Minimum of {minLength} characters (maximum of {maxLength})
              </span>
            </p>
            <p>
              {renderRequirementIcon(!passwordRequirementChecks.hasLowercase)}{' '}
              <span className={classes.requirementText}>1 lowercase letter</span>
            </p>
            <p>
              {renderRequirementIcon(!passwordRequirementChecks.hasUppercase)}{' '}
              <span className={classes.requirementText}>1 uppercase letter</span>
            </p>
            <p>
              {renderRequirementIcon(!passwordRequirementChecks.hasNumber)}{' '}
              <span className={classes.requirementText}>1 number</span>
            </p>
            <p>
              {renderRequirementIcon(!passwordRequirementChecks.hasSpecialCharacter)}{' '}
              <span className={classes.requirementText}>1 special character</span>
            </p>
          </div>
        )}
      </div>
    );
  }),
);
