import * as yup from 'yup';

import type { YupSchemaMap } from '@ecp/utils/form';

import { PasswordSchema } from '@ecp/features/servicing/shared/components';

export interface CreatePasswordFormInputs {
  email: string;
  password: string;
  confirmPassword: string;
}

export const createPasswordSchema: YupSchemaMap<CreatePasswordFormInputs> = {
  email: yup.string(),
  password: PasswordSchema.getPasswordSchema(),
  confirmPassword: yup
    .string()
    .required('Confirm password is a required field')
    .oneOf([yup.ref('password'), null], 'Passwords do not match'),
};
