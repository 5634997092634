import { useCallback, useMemo } from 'react';

import { Stack } from '@mui/material';

import { parseDollar } from '@ecp/utils/common';
import { formatDate } from '@ecp/utils/date';

import { Alert, Button, Card } from '@ecp/components';
import { LoadingOverlay } from '@ecp/features/servicing/shared/components';
import { useModalPath } from '@ecp/features/servicing/shared/routing';
import { usePaymentInfo } from '@ecp/features/servicing/shared/state';
import type { GetPaymentOption } from '@ecp/features/servicing/shared/types';

import { PolicyPaymentDisplay, type PolicyPaymentDisplayProps } from '../PolicyPaymentDisplay';
import { useStyles } from './AutopayEnrolledPendingDraft.styles';

export interface AutopayEnrolledPendingDraftProps {
  policyPaymentDisplayProps: PolicyPaymentDisplayProps;
}

export const AutopayEnrolledPendingDraft: React.FC<AutopayEnrolledPendingDraftProps> = (props) => {
  const { policyPaymentDisplayProps } = props;
  const { classes } = useStyles();
  const modalPath = useModalPath();

  const {
    paymentInfo: { billingAccount, paymentOptions },
    isLoading: isPaymentInfosLoading,
  } = usePaymentInfo({ policyNumber: modalPath.policyNumber });

  const enrolledPaymentOption: GetPaymentOption | undefined = paymentOptions?.find(
    (paymentOption) => paymentOption.autopayEnrolled,
  );
  const handleClose = useCallback(() => {
    modalPath.reset();
  }, [modalPath]);

  const minimumDue = billingAccount?.minimumDue ?? 0;
  const minimumDueAmount = parseDollar(minimumDue);
  const fullPayBalance = billingAccount?.fullPayBalance ?? 0;

  const paymentOnCardBody = (
    <span className={classes.balanceAmount}>{parseDollar(fullPayBalance - minimumDue)}</span>
  );
  const paymentMethodDetail = useMemo(() => {
    if (enrolledPaymentOption?.type === 'CC') {
      const ccDetails = enrolledPaymentOption;

      return `${ccDetails?.cardType} (...${ccDetails?.ccToken} Exp. ${formatDate(
        ccDetails?.ccExpirationDate,
        'MM/YY',
      )})`;
    } else if (enrolledPaymentOption?.type === 'EFT') {
      const bankDetails = enrolledPaymentOption;

      return `Bank account (...${bankDetails?.accountNumber})`;
    } else return '';
  }, [enrolledPaymentOption]);

  if (isPaymentInfosLoading) return <LoadingOverlay />;

  return (
    <div className={classes.root}>
      <Stack spacing={3}>
        <Alert type='info' withIcon>
          A payment of {minimumDueAmount} is scheduled to withdraw from {paymentMethodDetail}. You
          will be able to make a payment the day after your due date.
        </Alert>
        <PolicyPaymentDisplay {...policyPaymentDisplayProps} />
        <div>
          <p className={classes.dateHeading}>Remaining balance</p>
          <Card
            classes={{ content: classes.cardContent, root: classes.paymentBorder }}
            divider={false}
            body={paymentOnCardBody}
          />
        </div>
        <Button className={classes.closeButton} variant='primary' onClick={handleClose}>
          CLOSE
        </Button>
      </Stack>
    </div>
  );
};
