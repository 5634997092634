import { Route, Routes } from 'react-router-dom';

import {
  BillingTextingPage,
  ClaimsTextingPage,
  DisclosurePage,
  EmailFraudPage,
  LegalNoticesPage,
  OfferingTextingPage,
  PrivacyPolicyPage,
  ProtectYourInformationPage,
  ProtectYourselfPage,
  TermsAndConditionsPage,
  TermsOfUsePage,
  UnderwritingPage,
} from '@ecp/features/servicing/disclosures';
import { ServicingHeader } from '@ecp/features/servicing/shared/components';
import { PAGE_PATH, removePageFlowPrefixFromPaths } from '@ecp/features/servicing/shared/routing';

import { useStyles } from './GuestLayout.styles';

const routerPath = removePageFlowPrefixFromPaths(PAGE_PATH.GUEST_INDEX);

const Layout: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;
  const { classes } = useStyles(undefined, { props });

  return (
    <div className={classes.root}>
      <ServicingHeader />
      <main className={classes.main}>{children}</main>
    </div>
  );
};

export const GuestLayout: React.FC = () => {
  return (
    <Layout>
      <Routes>
        <Route index element={<DisclosurePage />} />
        <Route path={routerPath.GUEST_DISCLOSURES} element={<DisclosurePage />} />
        <Route path={routerPath.GUEST_DISCLOSURES_EMAIL_FRAUD} element={<EmailFraudPage />} />
        <Route path={routerPath.GUEST_DISCLOSURES_LEGALNOTICES} element={<LegalNoticesPage />} />
        <Route path={routerPath.GUEST_DISCLOSURES_PRIVACYPOLICY} element={<PrivacyPolicyPage />} />
        <Route path={routerPath.GUEST_DISCLOSURES_CLAIMSTEXTING} element={<ClaimsTextingPage />} />
        <Route
          path={routerPath.GUEST_DISCLOSURES_TERMSANDCONDITIONS}
          element={<TermsAndConditionsPage />}
        />
        <Route path={routerPath.GUEST_DISCLOSURES_TERMSOFUSE} element={<TermsOfUsePage />} />
        <Route path={routerPath.GUEST_DISCLOSURES_UNDERWRITING} element={<UnderwritingPage />} />
        <Route
          path={routerPath.GUEST_TERMSANDCONDITIONS_OFFERINGTEXTING}
          element={<OfferingTextingPage />}
        />
        <Route
          path={routerPath.GUEST_TERMSANDCONDITIONS_CLAIMSTEXTING}
          element={<ClaimsTextingPage />}
        />
        <Route
          path={routerPath.GUEST_TERMSANDCONDITIONS_BILLINGTEXTING}
          element={<BillingTextingPage />}
        />
        <Route
          path={routerPath.GUEST_DISCLOSURES_PROTECTYOURINFORMATION}
          element={<ProtectYourInformationPage />}
        />
        <Route
          path={routerPath.GUEST_DISCLOSURES_PROTECTYOURSELF}
          element={<ProtectYourselfPage />}
        />
      </Routes>
    </Layout>
  );
};
