import { flagValues } from '@ecp/utils/flags';

import type { PolicyResponse } from '@ecp/features/servicing/shared/types';

export const isBookRolledPolicy = (policyResponse: PolicyResponse | undefined): boolean => {
  const isBookrollEnabled = flagValues.BOOKROLL;

  if (isBookrollEnabled && policyResponse) {
    if (
      policyResponse.policy.transition?.type === 'Conversion' &&
      (policyResponse.policy.transition?.status === 'Complete' ||
        policyResponse.policy.transition?.status === 'InProgress')
    )
      return true;
  }

  return false;
};
