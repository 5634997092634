import { memo, useCallback, useEffect, useState } from 'react';

import { Drawer, IconButton, List, Stack } from '@mui/material';

import { debounce } from '@ecp/utils/common';

import { useStyles as useHeaderStyles } from '../ServicingHeader/ServicingHeader.styles';
import { MobileMenuIcon } from './MobileMenuIcon';
import { useStyles } from './MobileNav.styles';
import { MobileNavItem } from './MobileNavItem';
import { useMenuItems } from './useMenuItems';
interface Props {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
}

export const MobileNav: React.FC<Props> = memo((props) => {
  const { classes, cx } = useStyles(undefined, { props });
  const { classes: headerClasses } = useHeaderStyles();
  const [isSideMenuOpened, setIsSideMenuOpened] = useState(false);

  const handleDrawerToggle = useCallback(() => setIsSideMenuOpened((open) => !open), []);
  const { activeItem, activeSubItem, primaryItems, secondaryItems } = useMenuItems();

  // simple listener to close drawer when resized so mobile header doesn't show along with desktop header
  useEffect(() => {
    const debouncedCloseDrawer = debounce(() => setIsSideMenuOpened(false), 500);
    window.addEventListener('resize', debouncedCloseDrawer);

    return () => window.removeEventListener('resize', debouncedCloseDrawer);
  });

  return (
    <div className={classes.root}>
      <IconButton
        aria-label={`${isSideMenuOpened ? 'close' : 'open'} navigation menu`}
        onClick={handleDrawerToggle}
        className={classes.iconButton}
      >
        <MobileMenuIcon active={isSideMenuOpened} />
      </IconButton>
      <Drawer
        anchor='right'
        variant='temporary'
        open={isSideMenuOpened}
        onClose={handleDrawerToggle}
        classes={{ paper: classes.drawerPaper }}
        SlideProps={{ timeout: 400 }}
      >
        <div>
          <div className={headerClasses.placeholder} />
          <Stack spacing={1}>
            <nav>
              <List className={classes.list}>
                {primaryItems.map((item, index) => (
                  <MobileNavItem
                    key={item.url}
                    activeItem={activeItem}
                    activeSubItem={activeSubItem}
                    item={item}
                    onClick={handleDrawerToggle}
                    classes={{ item: cx(index === primaryItems.length - 1 && classes.itemLast) }}
                  />
                ))}
                <li />
                {secondaryItems.map((item, index) => (
                  <MobileNavItem
                    key={item.url}
                    activeItem={activeItem}
                    activeSubItem={activeSubItem}
                    item={item}
                    onClick={handleDrawerToggle}
                    classes={{
                      item: classes.secondaryItem,
                      itemActive: classes.secondaryActiveItem,
                    }}
                  />
                ))}
              </List>
            </nav>
          </Stack>
        </div>
      </Drawer>
    </div>
  );
});
