import type { RadioProps } from '@mui/material';

import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<RadioProps>({ name: 'Radio' })((...[theme, { size }]) => {
  const smallIcon = size === 'small';

  return {
    // Unchecked => Enabled and Disabled
    icon: {
      display: 'flex',
      borderRadius: '50%',
      borderWidth: 2,
      borderStyle: 'solid',
      backgroundColor: theme.palette.grey[50],
      borderColor: theme.palette.grey[400],
      justifyContent: 'center',
      alignItems: 'center',
      'input:disabled ~ &': {},
      width: smallIcon ? 16 : 22,
      height: smallIcon ? 16 : 22,
      // For Checked state
      '&:before': {
        display: 'block',
        borderRadius: '50%',
        width: smallIcon ? 10 : 16,
        height: smallIcon ? 10 : 16,
        content: '""',
        backgroundColor: theme.palette.primary.main,
        transform: 'scale(0)',
        transition: '.28s ease',
      },
    },
    iconChecked: {
      borderColor: theme.palette.grey[400],
      '&:before': {
        transform: 'scale(1)',
      },
    },
    iconDisabledChecked: {
      borderColor: theme.palette.grey[50],
      '&:before': {
        backgroundColor: theme.palette.actions.disabledBg,
        transform: 'scale(1)',
      },
    },
    iconDisabledUnchecked: {
      borderColor: theme.palette.actions.disabledBg,
    },
  };
});
