import type { Document } from '@ecp/features/servicing/shared/state';

interface DocConfig {
  keys?: string[];
  desc: string;
  priority: number;
}

const vehicleIdPrefix = 'Identification Card-';
const poiDocKeys = ['Declarations Page', 'Declaration'];

export const eoiDocConfig: DocConfig = {
  keys: ['evidence of insurance', 'Declarations Page', 'Declaration'],
  desc: 'Proof of Insurance',
  priority: 1,
};

export const poiDocConfig: DocConfig = {
  desc: 'ID Cards',
  priority: 1,
};

export const declarationDocConfig: DocConfig = {
  keys: poiDocKeys,
  desc: 'Declaration page',
  priority: 2,
};

export const invoiceDocConfig: DocConfig = {
  keys: ['INVOICE'],
  desc: 'Billing invoice',
  priority: 3,
};

export const isVehicleIdDoc = (document: Document): boolean =>
  document.documentTitle.startsWith(vehicleIdPrefix);

export const isClassicPoiDocument = (document: Document): boolean =>
  poiDocKeys.includes(document.documentTitle);
