import { useCallback, useMemo } from 'react';

import { Stack } from '@mui/material';

import { maskEmail } from '@ecp/utils/common';

import { Button } from '@ecp/components';
import {
  CheckIcon,
  LoadingOverlay,
  OtherPolicyLink,
  PolicyCard,
} from '@ecp/features/servicing/shared/components';
import type { OtherPolicyLinkProps } from '@ecp/features/servicing/shared/components';
import type { UseModalPathReturn as PaperlessConfirmationProps } from '@ecp/features/servicing/shared/routing';
import { MODAL_FLOW, MODAL_STEP } from '@ecp/features/servicing/shared/routing';
import {
  getPersonByRefId,
  getPolicyDisplayType,
  getProductLineFromPolicyResponse,
  isLegacyPolicy,
  useLinkedPolicies,
  usePaperless,
  usePolicy,
  usePreferencesForPolicyNumbers,
} from '@ecp/features/servicing/shared/state';

import { useStyles } from './PaperlessConfirmation.styles';

export const PaperlessConfirmation: React.FC<PaperlessConfirmationProps> = (props) => {
  const { currentFlow, policyNumber, init, reset } = props;
  const { classes } = useStyles();
  const { isLoading: isLoadingPolicy, policyData: policyResponse } = usePolicy(policyNumber);
  const {
    preferencesInfo,
    isAnyActivePolicyNotAllowingPaperless,
    isLoading: isLoadingPaperless,
  } = usePaperless({
    throwOnError: false,
  });
  const { linkedPolicies, isLoading: isLoadingLinkedPolicies } = useLinkedPolicies(policyNumber, {
    unenrollingPaperless: currentFlow === 'paperlessunenroll',
  });

  const linkedPoliciesPolicyNumbers = linkedPolicies.map((policy) => {
    return policy.policy.policyNumber;
  });

  const { preferenceResults, isLoading: isLoadingPreferences } = usePreferencesForPolicyNumbers(
    linkedPoliciesPolicyNumbers,
  );

  const linkedPoliciesWithPaperless = linkedPolicies.filter((policy) => {
    return preferenceResults.find((result) => result.policyNumber === policy.policy.policyNumber)
      ?.isPaperlessOn;
  });

  const isLoading =
    isLoadingPaperless || isLoadingPolicy || isLoadingPreferences || isLoadingLinkedPolicies;
  const productLine = getProductLineFromPolicyResponse(policyResponse);
  const primaryInsuredRefId = policyResponse?.policy.primaryInsuredRefId;

  // PreferencesInfos for all policies except the current one for which paperless has unenrolled.
  let preferencesInfosNext = useMemo(
    () =>
      preferencesInfo
        ?.filter((info) => info.policyNumber !== policyNumber)
        .filter((info) => !info.isError)
        .filter(
          (info) =>
            !linkedPoliciesWithPaperless.some(
              (linkedPolicy) => linkedPolicy.policy.policyNumber === info.policyNumber,
            ),
        )
        .filter((info) => info.policyAllowedToSetPaperless),
    [linkedPoliciesWithPaperless, policyNumber, preferencesInfo],
  );

  if (isAnyActivePolicyNotAllowingPaperless)
    preferencesInfosNext = preferencesInfosNext.filter(
      (info) => info.isPaperlessOn === true && isAnyActivePolicyNotAllowingPaperless,
    );

  const primaryNamedInsured = getPersonByRefId(policyResponse, primaryInsuredRefId);

  const otherPoliciesWithPaperlessOff = preferencesInfo
    ?.filter((info) => info.policyNumber !== policyNumber)
    .filter((info) => !info.isError)
    .filter((info) => !info.isPaperlessOn)
    .filter((info) => info.policyAllowedToSetPaperless);

  const handleClickLink = useCallback<NonNullable<OtherPolicyLinkProps['actionOnClick']>>(
    (policyNumber) => {
      const isPaperlessOn = preferencesInfosNext.some((prefrence) =>
        prefrence.policyNumber === policyNumber ? prefrence.isPaperlessOn : false,
      );

      init(
        policyNumber,
        isPaperlessOn ? MODAL_FLOW.PAPERLESS_UNENROLL : MODAL_FLOW.PAPERLESS_ENROLL,
        MODAL_STEP.MODAL_LANDING,
      );
    },
    [init, preferencesInfosNext],
  );
  const handleClose = useCallback(() => {
    reset();
  }, [reset]);

  const isPaperlessEnroll = currentFlow === 'paperlessenroll';

  const title = isPaperlessEnroll
    ? 'You are now enrolled in paperless delivery!'
    : 'You are no longer enrolled in paperless delivery';

  const isLegacy = isLegacyPolicy(productLine, policyResponse?.policy.sourceSystemName);
  const legacyPolicies = [policyResponse, ...linkedPolicies];

  const showLinkedPolicies = isLegacy && !!linkedPolicies.length;

  const linkedPoliciesSection = (
    <Stack component='ul' spacing={1.5} className={classes.linkedPoliciesList}>
      {legacyPolicies.map((policyDetails, index) => (
        <li key={`${policyDetails?.policy.policyNumber}-${index}`}>
          <PolicyCard
            className={classes.linkedPolicyCard}
            policyDetails={policyDetails}
            body={null}
          />
        </li>
      ))}
    </Stack>
  );

  const notificationsText = showLinkedPolicies ? (
    ' the following policies '
  ) : (
    <span>
      your{' '}
      <b>
        {getPolicyDisplayType(policyResponse)} policy {policyNumber}{' '}
      </b>
    </span>
  );
  const confirmationText = isPaperlessEnroll ? (
    <Stack spacing={2}>
      <div>
        <p className={classes.text}>
          Notifications about new documents for {notificationsText}
          will be sent to <b>{maskEmail(primaryNamedInsured?.email)}.</b>
        </p>
        {showLinkedPolicies && linkedPoliciesSection}
      </div>
      <p className={classes.text}>
        Manage your paperless settings by visiting your profile. Documents will be available to view
        and download online.
      </p>
    </Stack>
  ) : (
    <Stack spacing={2}>
      <div className={classes.text}>
        Documents for {notificationsText} are now being sent by mail.
      </div>
      {showLinkedPolicies && linkedPoliciesSection}
    </Stack>
  );

  if (isLoading) return <LoadingOverlay />;

  return (
    <Stack spacing={3}>
      <CheckIcon />
      <h2>{title}</h2>
      {confirmationText}
      <Stack spacing={2}>
        {isPaperlessEnroll
          ? !!otherPoliciesWithPaperlessOff?.length && (
              <h3>Enroll your other policies in paperless</h3>
            )
          : !!preferencesInfosNext?.length && (
              <h3>Update delivery preferences for your other policies</h3>
            )}
        {isPaperlessEnroll
          ? otherPoliciesWithPaperlessOff?.map((otherPolicy) => (
              <OtherPolicyLink
                key={otherPolicy.policyNumber}
                linkText={`Go paperless for ${getPolicyDisplayType(otherPolicy.policyResponse)}`}
                actionOnClick={handleClickLink}
                policyNumber={otherPolicy.policyNumber}
              />
            ))
          : preferencesInfosNext?.map((preference) => (
              <OtherPolicyLink
                key={preference.policyNumber}
                linkText={`Delivery preferences for ${getPolicyDisplayType(
                  preference.policyResponse,
                )}`}
                actionOnClick={handleClickLink}
                policyNumber={preference.policyNumber}
              />
            ))}
      </Stack>
      <Button className={classes.closeButton} variant='primary' onClick={handleClose}>
        CLOSE
      </Button>
    </Stack>
  );
};
