import { useCallback } from 'react';

import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { Button } from '@ecp/components';
import { env } from '@ecp/env';
import { MODAL_STEP, useModalPath } from '@ecp/features/servicing/shared/routing';
import { getItemFromLoggedInSessionStorage } from '@ecp/features/servicing/shared/state';
import type { ProductLine } from '@ecp/features/servicing/shared/types';

import { useStyles } from './MakePaymentButton.styles';

interface Props {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  policyNumber: string;
  buttonName?: 'Pay and Enroll' | 'Make payment';
  isClassic?: boolean;
  productLine?: ProductLine;
  redirectFromAutopayEnroll?: boolean;
}

export const MakePaymentButton: React.FC<Props> = (props) => {
  const {
    policyNumber,
    buttonName,
    isClassic = false,
    productLine,
    redirectFromAutopayEnroll = false,
  } = props;
  const modalPath = useModalPath();
  const { classes } = useStyles(undefined, { props });

  const paymentSubmitted = getItemFromLoggedInSessionStorage('payment', policyNumber);
  const isPaymentSubmitted = !!paymentSubmitted;

  const paymentsClassicFlag = flagValues[FeatureFlags.PAYMENTS_CLASSIC];

  const handleMakeAPaymentClick = useCallback(() => {
    const flow = isClassic ? 'payclassic' : 'pay';

    if (!paymentsClassicFlag && isClassic)
      window.open(
        productLine === 'HOME' ? `${env.legacyPolicyServiceUrl}home` : env.legacyPolicyServiceUrl,
        '_blank',
      );
    else {
      // Should show "Enrollment in Autopay requires a bank account..." alert only if user came from enroll flow
      // and other criteria is met
      if (redirectFromAutopayEnroll) {
        const autopayEnroll = 'true';
        modalPath.init(policyNumber, flow, MODAL_STEP.MODAL_EDIT, { autopayEnroll });
      } else modalPath.init(policyNumber, flow, MODAL_STEP.MODAL_EDIT);
    }
  }, [
    isClassic,
    modalPath,
    policyNumber,
    productLine,
    paymentsClassicFlag,
    redirectFromAutopayEnroll,
  ]);

  return (
    <div className={classes.root}>
      {!isPaymentSubmitted && (
        <Button
          variant='primary'
          className={classes.button}
          onClick={handleMakeAPaymentClick}
          disabled={isPaymentSubmitted}
          trackingName={
            buttonName === 'Pay and Enroll' ? 'pay_and_enroll_button' : 'make_payment_button'
          }
          trackingLabel='make_payment_continue'
          trackingDimensions={{ active_policy_number: policyNumber }}
        >
          {buttonName ?? 'Make payment'}
        </Button>
      )}
    </div>
  );
};
