import type { Command, CommandName, CommandOptions } from '../../types';
import { genesys } from '../../util';

/**
 * A promise wrapper for genesys "Command"
 * This function does no-op when chat is not enabled.
 * @see https://developer.genesys.cloud/commdigital/digital/webmessaging/messengersdk/SDKCommandsEvents/
 *
 * @param commandName - the command name to be executed, such as 'Messenger.open'
 * @param commandOptions - options to be passed to the command. Defaults to {}
 */
export const executeCommand = <T>(
  commandName: CommandName,
  commandOptions: CommandOptions = {},
): Promise<T> => {
  return new Promise((resolve, reject) => {
    (genesys as Command)(
      'command',
      commandName,
      commandOptions,
      (data: T) => {
        resolve(data);
      },
      () => {
        reject(new Error(`Genesys command failed. Command name: ${commandName}`));
      },
    );
  });
};
