import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PaymentDetails' })((theme) => ({
  root: { padding: '15px 20px' },
  gridItem: {
    justifyContent: 'space-between',
  },
  info: { ...theme.typography.body1, color: theme.palette.text.tertiary, width: 294 },
  data: { ...theme.typography.body3Bold, color: theme.palette.text.primary, width: 160 },
  method: { ...theme.typography.body3Bold, color: theme.palette.text.primary },
  account: { ...theme.typography.body2, color: theme.palette.text.primary },
  cardBody: { paddingTop: 12 },
  footer: { ...theme.typography.body1, padding: '8px 0px 0px 0px' },
}));
