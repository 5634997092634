import type { PropsWithChildren } from 'react';

import { Stack } from '@mui/material';

import { ScrollSpyMarkerClasses } from '@ecp/components';

import { useStyles } from './CoveragesSection.styles';

interface Props {
  title: string;
  showTitleInScrollspy?: boolean;
}

export const CoveragesSectionCard: React.FC<PropsWithChildren & Props> = (props) => {
  const { children, title, showTitleInScrollspy = true } = props;
  const { classes } = useStyles();

  // Need to replace spcial characters from id as we use id as a 'querySelector' in DOM and browser throws an exception
  const titleId = title.toLowerCase().replace(/[&/\\#, +()$~%.'":*?<>{}]/g, '_');

  return (
    <Stack className={classes.root} spacing={1.5}>
      <h3
        id={showTitleInScrollspy ? titleId : ''}
        className={showTitleInScrollspy ? ScrollSpyMarkerClasses.LEVEL_TWO : ''}
      >
        {title}
      </h3>
      <Stack spacing={2.5} className={classes.coveragesSection}>
        {children}
      </Stack>
    </Stack>
  );
};
