import { useParams } from 'react-router-dom';

import { LegalNoticesHomesite } from './homesite';

export const LegalNoticesPage: React.FC = () => {
  const { opco } = useParams();

  let legalNotices;
  switch (opco) {
    case 'homesite':
      legalNotices = <LegalNoticesHomesite />;
      break;
    default:
      legalNotices = <p>Partner Specific Legal Notices.</p>;
  }

  return legalNotices;
};
