import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutopayEditUnenroll' })((theme) => ({
  root: {},
  submitButtton: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: 'fit-content',
    },
  },
  icon: {
    ...theme.mixins.setColorSvg(theme.palette.grey[900]),
    width: 50,
    height: 50,
    alignSelf: 'center',
  },
  mapIcon: {
    height: 24,
    width: 24,
    marginRight: 10,
  },
  policyCard: {
    paddingTop: 12,
  },
  title: theme.typography.h2,
  backButton: {
    marginLeft: 32,
  },
}));
