import { Stack } from '@mui/material';

import { Link } from '@ecp/components';
import { env } from '@ecp/env';
import { NavLink } from '@ecp/features/servicing/shared/components';
import { generateHelpPath, PAGE_PATH } from '@ecp/features/servicing/shared/routing';

import { DisclosureWrapper } from '../../../components';
import { useStyles } from './PrivacyPolicyConnect.styles';

export const PrivacyPolicyConnect: React.FC = () => {
  const { classes } = useStyles();

  return (
    <DisclosureWrapper
      title='CONNECT Online Privacy Policy'
      subtitle={<i>Last revised July 1, 2020</i>}
      breadcrumbTitleOverrides={{ privacypolicy: 'Privacy Policy' }}
    >
      <Stack spacing={4} className={classes.root}>
        <Stack spacing={2}>
          <h2>Introduction</h2>
          <p>
            CONNECT, powered by American Family Insurance ("CONNECT," "we," "our," or "us"),
            respects the privacy of your information. This Privacy Policy is designed to assist you
            in understanding how we collect, use and safeguard the information you provide to us in
            using our website (the "Site"), the products and services provided through our Site
            (collectively, the "Services").
          </p>
          <p>
            If you have a consumer financial product or service with us, we will use and share any
            information that we collect from or about you in accordance with our{' '}
            <NavLink
              to={generateHelpPath(PAGE_PATH.DISCLOSURES, 'connect')}
              trackingName='consumer_privacy_notices_link'
              trackingLabel='consumer_privacy_notices'
            >
              Consumer Privacy Notices.
            </NavLink>{' '}
            Those notices offer you certain choices with respect to the use and sharing of your
            personal information.
          </p>
          <p>
            From time to time, we may change this Privacy Policy. If we do, we will post an amended
            version on this webpage. Please review this Privacy Policy periodically. Your continued
            use of the Site and Services after any such amendment signifies your acceptance thereof.
          </p>
        </Stack>
        <Stack spacing={2}>
          <h2>Collecting and Using Information</h2>
          <Stack spacing={4}>
            <h3>Personally Identifiable Information We Collect Online</h3>
            <p>
              Through your use of the Site and Services, we may collect Personally Identifiable
              Information from you. "Personally Identifiable Information" is individually
              identifiable information about an individual consumer that we collect online and that
              we maintain in an accessible form. We collect the following types of Personally
              Identifiable Information:
            </p>
            <Stack spacing={2}>
              <h4>Information You Provide</h4>
              <p>
                We may collect the following Personally Identifiable Information that you
                voluntarily provide to us:
              </p>
              <ul>
                <li>
                  <i>Request a Quote.</i> If you request a quote online for one of our Services, we
                  will collect your first and last name, email address, and postal address. We will
                  also collect other information, including date of birth, marital status, and
                  details pertaining to your home and vehicle, as applicable. To retrieve your
                  quote, you will need to provide your last name, email address, zip code, and date
                  of birth.
                </li>
                <li>
                  <i>Apply for or Purchase Services.</i> If you apply for or purchase property or
                  casualty insurance on our Site, we will collect your first and last name,
                  telephone number, and email address. We will also collect details pertaining to
                  your home and vehicle, as applicable. In some instances, we will need to
                  supplement the information you provided with certain Personally Identifiable
                  Information about you obtained from third parties to process your online
                  transaction, such as a credit check when applying for a brokerage margin account.
                </li>
                <li>
                  <i>Quick Services.</i> You may access certain services without having to login to
                  your online account, including to update a credit card, make a payment, update
                  your bank account, and request a declaration page, auto ID card, or policy book.
                  To proceed to these services, we will first verify your identity by collecting
                  your zip code together with either your policy number or your first and last name
                  and date of birth.
                </li>
                <li>
                  <i>Online Account.</i> If you register for online Services, we will collect your
                  last name, policy number, email address, telephone number, and your date of birth.
                  To access policy details and transactions on our Site, you will need to login to
                  your account by providing your user ID and password.
                </li>
                <li>
                  <i>File a Claim Online.</i> To file an auto or home insurance claim on our Site,
                  you will need to login to your online account with your user ID and password. We
                  will collect your policy number and details pertaining to your home or vehicle and
                  accident or loss, including supporting documentation that may include other
                  Personally Identifiable Information.
                </li>
              </ul>
            </Stack>
            <Stack spacing={2}>
              <h4>Information as You Navigate Our Site</h4>
              <p>
                We automatically collect certain Personally Identifiable Information through your
                use of the Site and Services, such as the following:
              </p>
              <ul>
                <li>
                  <i>Usage Information.</i> We collect information such as which of the pages on the
                  Site you access, the frequency of access, and what you click on while on the Site.
                </li>
                <li>
                  <i>Device Information.</i> We collect information about the device you are using,
                  such as hardware model, operating system, application version number, and browser.
                </li>
                <li>
                  <i>Mobile Device Information.</i> In addition to the Device Information listed
                  above, when you access our Site via a browser on your mobile device, we collect
                  and aggregate information about whether you are accessing the Site via a mobile
                  device or tablet, device type, and carrier.
                </li>
                <li>
                  <i>Location Information.</i> We collect location information from Site visitors on
                  a city-regional basis.
                </li>
              </ul>
            </Stack>
            <Stack spacing={2}>
              <h4>Third Party Information</h4>
              <p>
                In some cases, we may receive certain Personally Identifiable Information from you
                about a third party. For example, by completing our webform to receive a quote, you
                may provide the name, email address, and other information of a third party, such as
                a spouse or driver. If you submit any Personally Identifiable Information about
                another individual to us, you are responsible for making sure that you have the
                authority to do so and to allow us to use their Personally Identifiable Information
                in accordance with this Privacy Policy.
              </p>
            </Stack>
            <Stack spacing={2}>
              <h3>How We Use Your Personally Identifiable Information</h3>
              <p>
                We use the Personally Identifiable Information we collect to provide the Services to
                you, to improve our Services and Site, and to protect our legal rights. In addition,
                we may use the Personally Identifiable Information we collect to:
              </p>
              <ul>
                <li>Process your account registration;</li>
                <li>Process and fulfill the transactions you initiate or request;</li>
                <li>
                  Contact you regarding our products and services that we feel may be of interest to
                  you;
                </li>
                <li>
                  Communicate with you about our Site or Services or to inform you of any changes to
                  our Site or Services;
                </li>
                <li>Provide support;</li>
                <li>Maintain and improve our Site and Services;</li>
                <li>Defend our legal rights and the rights of others;</li>
                <li>Efficiently maintain our business; and</li>
                <li>Comply with applicable law.</li>
              </ul>
            </Stack>
            <Stack spacing={2}>
              <h3>How We Share Your Personally Identifiable Information</h3>
              <p>We may share the information that we collect about you in the following ways:</p>
              <ul>
                <li>
                  With service providers who perform data services on our behalf (e.g., email,
                  hosting, maintenance, backup, analysis, etc.). Any such service providers will be
                  under an obligation to us to maintain the confidentiality of your Personally
                  Identifiable Information;
                </li>
                <li>To service providers to prepare, deploy and analyze advertising content;</li>
                <li>
                  To service providers to assist us in processing your online transactions or to
                  deliver Services you request;
                </li>
                <li>
                  To affiliates that we believe can offer complimentary services or products to you;
                </li>
                <li>To the extent that we are required to do so by law;</li>
                <li>In connection with any legal proceedings or prospective legal proceedings;</li>
                <li>
                  To establish, exercise, or defend our legal rights, including providing
                  information to others for the purposes of fraud prevention;
                </li>
                <li>
                  To any person who we reasonably believe may apply to a court or other competent
                  authority for disclosure of that Personally Identifiable Information where, in our
                  reasonable opinion, such court or authority would be reasonably likely to order
                  disclosure of that Personally Identifiable Information;
                </li>
                <li>To any other person or entity as part of any business or asset sale; and</li>
                <li>To any other person or entity where you consent to the disclosure.</li>
              </ul>
            </Stack>
          </Stack>
        </Stack>
        <Stack spacing={4}>
          <h2>Cookies and Other Tracking Technologies</h2>
          <Stack spacing={2}>
            <h3>How We Use Cookies</h3>
            <p>
              Like many other companies, we use cookies and other tracking technologies (such as
              pixels and web beacons) (collectively, "Cookies"). "Cookies" are small files of
              information that are stored by your web browser software on your computer hard drive,
              mobile or other devices (e.g., smartphones or tablets).
            </p>
            <p>We use Cookies to:</p>
            <ul>
              <li>
                Develop a better understanding of use activity on our Site to improve our Site and
                offerings;
              </li>
              <li>
                Resolve issues you may encounter in using our Site and Services, including the
                submission and completion of online transactions;
              </li>
              <li>Monitor against fraudulent activities;</li>
              <li>
                Understand and save your preferences for future visits, allowing us to customize the
                Site and Services to your individual needs;
              </li>
              <li>Provide you with customized and relevant information and offers;</li>
              <li>Keep track of advertisements and search engine results;</li>
              <li>
                Compile aggregate data about site traffic and site interactions to resolve issues
                and offer better site experiences and tools in the future; and
              </li>
              <li>Recognize when you return to the Site.</li>
            </ul>
            <p>
              We set some Cookies ourselves and others are set by other entities. We use Cookies set
              by other entities to provide us with useful information to help us improve our Site
              and Services, to conduct advertising, and to analyze the effectiveness of advertising.
              For example, we use Cookies from Google and other similar companies.
            </p>
            <p>
              California law may consider the use of advertising Cookies to be "sales" of personal
              information. Please see <strong>"Notice to California Residents"</strong> below for
              more information. By using the Site, you affirmatively authorize us to use Cookies,
              including advertising Cookies.
            </p>
          </Stack>
          <Stack spacing={2}>
            <h3>How You Can Opt-Out of Cookies</h3>
            <h4>Browser Settings</h4>
            <p>
              Cookies can be blocked by changing your Internet browser settings to refuse all or
              some Cookies. If you choose to block all Cookies (including essential Cookies) you may
              not be able to access all or parts of the Site.
            </p>
            <p>
              You can find out more about Cookies and how to manage Cookies by visiting
              www.AboutCookies.org.
            </p>
            <h4>Platform Controls</h4>
            <p>
              You can opt out of Cookies set by specific entities by following the instructions
              found at these links:
            </p>
            <p>Google: https://adssettings.google.com</p>
            <p>Adobe: https://www.adobe.com/privacy/opt-out.html</p>
            <h4>Advertising Industry Resources</h4>
            <p>
              You can understand which entities have currently enabled Cookies for your browser or
              mobile device and how to opt-out of some of those Cookies by accessing the Network
              Advertising Initiative's website or the Digital Advertising Alliance's website. For
              more information on mobile specific opt-out choices, visit the Network Advertising
              Initiative's Mobile Choices website.
            </p>
            <p>
              Please note that these opt-out mechanisms are specific to the device or browser on
              which they are exercised. Therefore, you will need to opt out on every browser and
              device that you use.
            </p>
          </Stack>
        </Stack>
        <Stack spacing={2}>
          <h2>Third Party Processors</h2>
          <p>
            To ensure that your Personally Identifiable Information receives an adequate level of
            protection, we have put in place appropriate procedures with the service providers we
            share it with to ensure that it is treated consistent with applicable data security and
            privacy laws. For example, we use Epsilon Data Management, LLC for email marketing
            services.
          </p>
        </Stack>
        <Stack spacing={2}>
          <h2>"Do Not Track" Signals</h2>
          <p>
            Some internet browsers incorporate a "Do Not Track" feature that signals to websites you
            visit that you do not want to have your online activity tracked. Given that there is not
            a uniform way that browsers communicate the "Do Not Track" signal, the Site does not
            currently interpret, respond to or alter its practices when it receives "Do Not Track"
            signals.
          </p>
        </Stack>
        <Stack spacing={2}>
          <h2>Choices About Your Personally Identifiable Information</h2>
          <h3>Review and Request Changes to Your Personally Identifiable Information</h3>
          <p>
            You may use your online account to access, correct, or view certain Personally
            Identifiable Information we have collected and which is associated with your account. To
            review or request changes to any of your Personally Identifiable Information, please
            contact us at servicepromise@connectbyamfam.com.
          </p>
          <h3>Marketing Communications</h3>
          <p>We may use your email address to send you email messages, including the following:</p>
          <ul>
            <li>Service notifications related to your account(s);</li>
            <li>
              Regularly scheduled email newsletters related to CONNECT and American Family and its
              affiliates;
            </li>
            <li>Services for which you are enrolled;</li>
            <li>
              Requests for names and contact information of individuals you think could benefit from
              our Services;
            </li>
            <li>
              Optional email communications for which you have registered (e.g., newsletters on a
              selected financial topic);
            </li>
            <li>
              Occasional updates about our Services as well as American Family and its affiliates'
              marketing offers; and
            </li>
            <li>
              Valuable offers from non-affiliated companies that we send on such companies' behalf.
            </li>
          </ul>
          <p>
            We will never ask you to send personal information such as account or Social Security
            numbers by non-secure email.
          </p>
          <p>
            To unsubscribe from our marketing emails, please opt out at
            connectbyamfam.com/unsubscribe or click the unsubscribe link included in the footer of
            our emails. Keep in mind that you may continue to receive newsletters pertaining to
            Services that you are enrolled in automatically when you register for those Services. In
            those cases, you must reply to and decline the newsletters individually.
          </p>
        </Stack>
        <Stack spacing={2}>
          <h2>Security</h2>
          <p>
            We maintain commercially reasonable security measures to protect the Personally
            Identifiable Information we collect and store from loss, misuse, destruction, or
            unauthorized access. However, no security measure or modality of data transmission over
            the Internet is 100% secure. Although we strive to use commercially acceptable means to
            protect your Personally Identifiable Information, we cannot guarantee absolute security.
          </p>
        </Stack>
        <Stack spacing={2}>
          <h2>Third Party Links</h2>
          <p>
            The Site and Services, including our marketing communications, may contain links that
            will let you leave the Site and Services and access another website. Linked websites are
            not under our control. Except as stated below, this Privacy Policy applies solely to
            Personally Identifiable Information that is acquired by us on our Site and through our
            Services. We accept no responsibility or liability for these other websites.
          </p>
        </Stack>
        <Stack spacing={2}>
          <h2>Children's Privacy</h2>
          <p>
            The Site and Services are not intended for children under 13 years of age. We do not
            knowingly collect, use, or disclose personal information from children under 13.
          </p>
        </Stack>
        <Stack spacing={2}>
          <h2>Notice to California Residents</h2>
          <p>
            The California Consumer Privacy Act (CCPA) requires that we provide California residents
            with a privacy policy that contains a comprehensive description of our online and
            offline practices regarding the collection, use, disclosure, and sale of personal
            information and of the rights of California residents regarding their personal
            information.
          </p>
          <p>
            Our <strong>Notice to California Residents</strong>,{' '}
            <Link
              href={env.ccpaUrl}
              target='_blank'
              rel='noreferrer'
              trackingName='california_consumer_privacy_act_link'
              trackingLabel='california_consumer_privacy_act'
            >
              which is available here
            </Link>
            , supplements the information contained in this Privacy Policy and applies solely to
            clients, users, and others who reside in the State of California. If you are not a
            California resident, such notice does not apply to you and you should not rely on it.
          </p>
          <p>
            The CCPA's privacy rights described in that notice do not apply to personal information
            (as such term is defined under the CCPA) that is collected, processed, sold, or
            disclosed pursuant to the federal Gramm-Leach-Bliley Act and its implementing
            regulations or the California Financial Information Privacy Act. Because we are subject
            to those laws and regulations, much of the personal information that we collect is
            exempt from the CCPA. Your choices and rights with respect to our use and sharing of
            that information are subject to our{' '}
            <NavLink
              to={generateHelpPath(PAGE_PATH.DISCLOSURES, 'connect')}
              trackingName='consumer_privacy_notices_link'
              trackingLabel='consumer_privacy_notices'
            >
              Consumer Privacy Notices
            </NavLink>
            .
          </p>
          <p>
            The CCPA's privacy-related rights also do not apply to certain types of personal
            information that is subject to the Federal Credit Reporting Act (FCRA). Some of our
            services are subject to the FCRA and, therefore, exempt from the CCPA on that basis.
          </p>
          <Stack spacing={2}>
            <h3>Shine the Light Law</h3>
            <p>
              We do not disclose personal information obtained through our Site or Services to third
              parties for their direct marketing purposes. Accordingly, we have no obligations under
              California Civil Code § 1798.83.
            </p>
          </Stack>
        </Stack>
        <Stack spacing={2}>
          <h2>Accessibility</h2>
          <p>
            We are committed to ensuring this Privacy Policy is accessible to individuals with
            disabilities. If you wish to access this Privacy Policy in an alternative format, please
            contact us as described below.
          </p>
        </Stack>
        <Stack spacing={2}>
          <h2>How to Contact Us</h2>
          <p>
            For questions or concerns about our privacy policies or practices, please do not
            hesitate to contact us online at connectbyamfam.com/privacyrequest or by phone at
            1-888-239-9953.
          </p>
        </Stack>
      </Stack>
    </DisclosureWrapper>
  );
};
