import { Stack } from '@mui/material';

import { userAuth } from '@ecp/utils/auth';

import { DashboardCard } from '@ecp/features/servicing/shared/components';
import {
  getProductLineFromPolicyResponse,
  useUserPolicies,
} from '@ecp/features/servicing/shared/state';
import { getBrandDataByType } from '@ecp/features/servicing/shared/util';

interface Props {
  autoLinks?: React.ReactElement;
  homeLinks?: React.ReactElement;
}

const DisclosureLayout: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { children } = props;

  return (
    <DashboardCard>
      <Stack spacing={{ xs: 7, md: 5 }}>
        <h1>Disclosures</h1>
        {children}
      </Stack>
    </DashboardCard>
  );
};

const DisclosureLinkWrapperBase: React.FC<Props> = (props) => {
  const { autoLinks, homeLinks } = props;

  const autoHeader = getBrandDataByType('AUTO')?.uwCompanyCopyright ?? '';
  const homeHeader = getBrandDataByType('HOME')?.uwCompanyCopyright ?? '';
  const isAutoLinksFirst = autoHeader.toLowerCase().localeCompare(homeHeader.toLowerCase()) === -1;

  const autoSection = autoLinks && (
    <Stack spacing={2}>
      <h2>{autoHeader}</h2>
      {autoLinks}
    </Stack>
  );
  const homeSection = homeLinks && (
    <Stack spacing={2}>
      <h2>{homeHeader}</h2>
      {homeLinks}
    </Stack>
  );

  return (
    <DisclosureLayout>
      <Stack spacing={4}>
        {isAutoLinksFirst ? (
          <>
            {autoSection}
            {homeSection}
          </>
        ) : (
          <>
            {homeSection}
            {autoSection}
          </>
        )}
      </Stack>
    </DisclosureLayout>
  );
};

const DisclosureLinksWrapperWithAuth: React.FC<Props> = (props) => {
  const { autoLinks, homeLinks } = props;
  const { policies } = useUserPolicies({ throwOnUserError: false });

  const hasAutoPolicy = policies.some(
    (policyData) => getProductLineFromPolicyResponse(policyData) === 'AUTO',
  );
  const hasHomePolicy = policies.some(
    (policyData) => getProductLineFromPolicyResponse(policyData) === 'HOME',
  );

  return (
    <DisclosureLinkWrapperBase
      autoLinks={hasAutoPolicy ? autoLinks : undefined}
      homeLinks={hasHomePolicy ? homeLinks : undefined}
    />
  );
};

export const DisclosureLinksWrapper: React.FC<Props> = (props) =>
  userAuth.isAuth ? (
    <DisclosureLinksWrapperWithAuth {...props} />
  ) : (
    <DisclosureLinkWrapperBase {...props} />
  );
