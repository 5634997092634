import { CircularProgress, Stack } from '@mui/material';

import { Alert } from '@ecp/components';
import { ReviewDocumentsLink } from '@ecp/features/servicing/shared/components';
import { getProductLineFromPolicyResponse, usePolicy } from '@ecp/features/servicing/shared/state';

import { AutoCoverages } from './AutoCoverages';
import { useStyles } from './CoveragesSection.styles';
import { CoveragesSectionCard } from './CoveragesSectionCard';
import { HomeCoverages } from './HomeCoverages';

interface Props {
  policyNumber: string;
}

export const CoveragesSection: React.FC<Props> = (props) => {
  const { policyNumber } = props;
  const { classes } = useStyles();

  const { policyData } = usePolicy(policyNumber);

  const productLine = getProductLineFromPolicyResponse(policyData);
  const isHome = productLine === 'HOME';
  const isAuto = productLine === 'AUTO';
  const isCondo = policyData?.coverageOptions[0].policyTypeCode === 'HO6';
  const isRenters = policyData?.coverageOptions[0].policyTypeCode === 'HO4';
  if (productLine && !isHome && !isAuto) {
    // shouldn't be called
    throw new Error(
      `Umbrella/unknown coverages not supported. Don't call this component for [${productLine}] productLine`,
    );
  }

  let title = 'For your home';
  if (isCondo || isRenters) title = 'Coverages section';

  const alert = (
    <Alert withIcon type='info'>
      For a complete list of coverages and deductibles in your policy, please{' '}
      <span className={classes.link}>
        <ReviewDocumentsLink
          policyNumber={policyNumber}
          trackingLabel='review_documents_continue'
          trackingName='review_documents_link'
        />
        .
      </span>
    </Alert>
  );
  if (!policyData) return <CircularProgress aria-label='Loading...' />;

  return (
    <Stack className={classes.root} spacing={3}>
      {alert}
      {isHome ? (
        <CoveragesSectionCard
          title={title}
          showTitleInScrollspy={false}
          children={<HomeCoverages policyData={policyData} />}
        />
      ) : (
        <AutoCoverages policyData={policyData} />
      )}
    </Stack>
  );
};
