import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'FullScreenModal' })((theme) => ({
  root: {
    '.MuiDialog-paper': {
      background: theme.palette.grey[200],
    },
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.dark,
  },
  headerContent: { padding: 24, maxWidth: 740 },
  headerTitle: {
    color: theme.palette.info.contrastText,
  },
  button: {
    '&:hover': theme.mixins.setColorSvg(theme.palette.info.contrastText),
  },
  icon: theme.mixins.setColorSvg(theme.palette.info.contrastText),
  content: {
    maxWidth: 534, // 32px padding = 502
    width: '100%',
    justifyContent: 'space-between',
    margin: '0 auto',
  },
  body: {
    padding: '24px 16px',
    [theme.breakpoints.up('md')]: {
      padding: '32px 16px',
    },
  },
  paper: { outline: 'none' },
}));
