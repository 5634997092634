// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { /* MakeStyles, */ makeStyles as makeStylesBase } from '../../../../base/src';
// import { Theme } from '../theme';

/**
 * Adapter for MUI makeStyles conveniently typed with a custom theme.
 * As theme is static, it doesn't make sense to pass Theme generic in every makeStyles call.
 * Components have different props so if your styles depend on any props from the component,
 * you would need to pass this component props type as generic in makeStyles call.
 *
 * So, instead of `makeStyles<Theme, Props>(..args)` you call:
 *  - `makeStyles<Props>(..args)` - when styles depend on component props
 *  - `makeStyles(..args)` - when styles don't depend on component props
 *
 * @see [Original MUI makeStyles type](https://github.com/mui-org/material-ui/blob/v4.x/packages/material-ui/src/styles/makeStyles.d.ts)
 */
export const makeStyles = makeStylesBase; // as unknown as MakeStyles<Theme>;
