import dayjs from 'dayjs';
import * as yup from 'yup';

import type { YupSchemaMap } from '@ecp/utils/form';
import {
  amexCardRegex,
  discoverCardRegex,
  masterCardRegex,
  nameOnCardRegex,
  visaCardRegex,
} from '@ecp/utils/payment';

export interface AddPaymentMethodInputs {
  name: string;
  cardNumber: string;
  expirationDate: string;
}

export const AddCardPaymentMethodSchema: YupSchemaMap<AddPaymentMethodInputs> = {
  cardNumber: yup
    .string()
    .required('Card number is a required field')
    .test('credit-card', 'Card number is invalid', (value) => {
      const isVisaCard = visaCardRegex.test(value as string);
      const isMasterCard = masterCardRegex.test(value as string);
      const isAmexCard = amexCardRegex.test(value as string);
      const isDiscoveryCard = discoverCardRegex.test(value as string);

      return isVisaCard || isMasterCard || isAmexCard || isDiscoveryCard;
    }),
  expirationDate: yup
    .string()
    .nullable()
    .required('Expiraton date is a required field')
    .test('expirationDate', 'Invalid Expiration Date', (value) => {
      return !!value && value !== 'Invalid Date';
    })
    .test('expirationDate', 'Invalid Expiration Date has passed', (value) => {
      const endOfMonth = dayjs(value).endOf('month');
      const isBefore = dayjs().isBefore(endOfMonth);

      return !!value && isBefore;
    }),
  // securityCode: yup
  //   .string()
  //   .nullable()
  //   .required('Security code is a required field')
  //   .when('cardNumber', {
  //     is: (val: string) => amexCardRegex.test(val),
  //     then: (schema) => schema.matches(securityCodeAmexCardRegex, 'Security code must be 4 digits'),

  //     otherwise: (schema) =>
  //       schema.matches(securityCodeCardRegex, 'Security code must be 3 digits'),
  //   }),
  name: yup
    .string()
    .required()
    .min(3)
    .matches(nameOnCardRegex, 'Name cannot contain special characters'),
};
