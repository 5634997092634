import * as interactionId from '@ecp/utils/analytics/interaction-id';
import { datadogLog } from '@ecp/utils/logger';
import { Headers, typedFetch } from '@ecp/utils/network';

import { env } from '@ecp/env';

import type { DalAnalyticsRequest } from './types';

export const trackDalAnalytics = async (
  request: DalAnalyticsRequest,
  traceId: string,
): Promise<Response> => {
  // DAL doesn't need afe-sapi-target anymore.
  const headers = {
    'Content-Type': 'application/json',
    ...(env.static.expId && { [Headers.EXP_ID]: env.static.expId }),
    ...(env.static.partnerId && { [Headers.PARTNER_ID]: env.static.partnerId }),
    [Headers.SESSION_ID]: interactionId.get(),
    [Headers.SOURCE_ID]: env.static.applicationName,
    [Headers.TRACE_ID]: traceId,
    [Headers.AUTH_KEY]: env.static.authKey ?? '',
    'polaris-analytics-beacon': 'true', // temporary until sales stops using v1
  };

  const requestUrl = `${env.ecpDalRoot}/events/action`;
  const res = await typedFetch<void>(requestUrl, {
    headers,
    method: 'POST',
    body: JSON.stringify(request),
  });

  if (!res.ok) {
    datadogLog({
      logType: 'warn',
      message: `API (response) has bad error status [${res.status}] from ${requestUrl}`,
      context: {
        logOrigin: 'libs/utils/analytics/tracking/lib/src/dalApi.ts',
        functionOrigin: `trackDalAnalytics`,
        requestUrl,
        requestId: traceId,
        requestHeaders: JSON.stringify(headers),
        responseHeaders: headers,
        responseStatus: res.status,
        statusText: res.statusText,
        severity: 'low',
      },
    });
    // do not throw since analytics is not critical
  }

  return res;
};
