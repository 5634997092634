import { useCallback } from 'react';

import { Divider, Stack } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { useField, useFormContext } from '@ecp/utils/form';

import { Card } from '@ecp/components';
import { CheckboxField } from '@ecp/features/servicing/shared/components';
import { useIsAutopayEligibleForPaymentMethod } from '@ecp/features/servicing/shared/state';
import type { AmountSelected, PaymentMethod } from '@ecp/features/servicing/shared/types';
import type { MakePaymentFormInputs } from '@ecp/features/servicing/shared/util';

import { useStyles } from './EnrollAutopay.styles';

export interface EnrollAutopayProps {
  amountSelected: AmountSelected;
  paymentMethod?: PaymentMethod;
  policyNumber?: string;
}

export const EnrollAutopay: React.FC<EnrollAutopayProps> = (props) => {
  const { amountSelected, paymentMethod, policyNumber } = props;
  const { classes, cx } = useStyles();
  const { control } = useFormContext<MakePaymentFormInputs>();
  const enrollAutopayField = useField({
    name: 'enrollAutopay',
    control,
  });

  const { isAutopayEligible } = useIsAutopayEligibleForPaymentMethod(policyNumber, paymentMethod);

  const autopayMessage =
    amountSelected === 'Pay in full'
      ? 'Automatic payments will begin on the next policy renewal date.'
      : 'Automatic payments will begin on the next payment due date.';

  const handleCheckBoxChange = useCallback(() => {
    trackClick({
      action: 'authorize_enroll_autopay_checkbox',
      label: !enrollAutopayField.value ? 'Yes' : 'No',
    });
  }, [enrollAutopayField.value]);

  const autoEnrollCardBody = (
    <Stack divider={<Divider />} spacing={1.5}>
      <CheckboxField
        {...enrollAutopayField}
        onChange={handleCheckBoxChange}
        checked={!!enrollAutopayField.value}
        label='Enroll in AutoPay'
        classes={{ label: classes.enrollAutopayLabel }}
      />
      {!!enrollAutopayField.value && <div>{autopayMessage}</div>}
    </Stack>
  );

  return isAutopayEligible ? (
    <Card
      classes={{
        content: classes.cardContent,
        root: cx(classes.paymentBorder, !!enrollAutopayField.value && classes.addBorder),
      }}
      divider={false}
      body={autoEnrollCardBody}
    />
  ) : null;
};
