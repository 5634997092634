import { useCallback } from 'react';

import type { ChipProps } from '@mui/material';
import { Chip, Stack } from '@mui/material';
import type { LinkProps } from 'react-router-dom';

import { Link } from '@ecp/components';
import type { PolicyTypeCode, ProductLine } from '@ecp/features/servicing/shared/types';

import { PolicyIcon } from '../PolicyIcon';
import { useStyles } from './PolicyCardHeader.styles';

interface PolicyCardHeaderProps {
  buttonText?: string;
  chipProps?: ChipProps;
  onButtonClick?: (policyNumber: string) => void;
  policyDisplayType: string | undefined;
  policyNumber: string | undefined;
  policyType: PolicyTypeCode | undefined;
  productLine: ProductLine | undefined;
}

export const PolicyCardHeader: React.FC<PolicyCardHeaderProps> = (props) => {
  const {
    buttonText,
    chipProps,
    onButtonClick,
    policyDisplayType,
    productLine,
    policyNumber,
    policyType,
  } = props;

  const { classes } = useStyles();

  const handleButtonClick = useCallback<NonNullable<LinkProps['onClick']>>(() => {
    if (!policyNumber) {
      throw Error('policyNumber is undefined');
    }
    onButtonClick?.(policyNumber);
  }, [policyNumber, onButtonClick]);

  if (!policyNumber) return null;

  return (
    <Stack direction='row' className={classes.outerStack}>
      <Stack spacing={1} direction='row'>
        {!!productLine && <PolicyIcon productLine={productLine} policyTypeCode={policyType} />}
        <span className={classes.policyType}>{policyDisplayType}</span>{' '}
        <span className={classes.policyNumber}>{policyNumber}</span>
      </Stack>
      {buttonText && (
        <Link
          key={policyNumber}
          component='button'
          className={classes.linkCta}
          onClick={handleButtonClick}
        >
          {buttonText}
        </Link>
      )}
      {chipProps && <Chip {...chipProps} />}
    </Stack>
  );
};
