import { makeStyles } from '@ecp/themes/base';

const borderRadius = 8;

export const useStyles = makeStyles<
  void,
  'hasTooltip' | 'large' | 'medium' | 'small' | 'checked' | 'icon' | 'label'
>({
  name: 'CardFormControlLabel',
})((...[theme, , classes]) => {
  return {
    root: {
      background: theme.palette.grey['50'],
      borderRadius,
      border: `1px solid ${theme.palette.grey['600']}`,
      '&:hover': {
        background: theme.palette.grey['200'],
      },
      [theme.breakpoints.down('md')]: {
        width: '100% !important',
        height: 62,
      },
      [theme.breakpoints.up('md')]: {
        marginRight: 12,
      },
    },
    rootDisabled: {
      borderColor: theme.palette.grey[300],
      '&:hover': {},
    },
    iconCardRoot: {
      '&:hover': {
        background: theme.palette.grey['50'],
      },
      marginRight: 0,
    },
    radioButtonCardRoot: {
      borderRadius,
      border: 0,
      '&:hover': {
        background: theme.palette.grey['200'],
      },
      width: 'inherit',
      marginRight: 0,
      minHeight: 112,
      [theme.breakpoints.down('lg')]: {
        marginRight: 12,
        border: `2px solid ${theme.palette.grey['600']}`,
      },
    },
    horizontalDivider: {
      background: theme.palette.secondary.dark,
      marginTop: 11,
    },
    horizontalCard: {},
    checked: {
      borderColor: theme.palette.primary.main,
      // Use pseudo element for border so cards don't jump, since border adds to component height/width
      '&:after': {
        content: '""',
        position: 'absolute',
        border: `2px solid ${theme.palette.primary.main}`,
        // When browser zoom isn't set to 100%, a gap is created, so Set all position properties
        // to -1 and border to 2px to overlay the existing border
        top: -1,
        left: -1,
        right: -1,
        bottom: -1,
        borderRadius: borderRadius - 1,
        zIndex: 1,
      },
      [`& .${classes.icon} > svg > path`]: {
        fill: theme.palette.icon.hover,
      },
      [`& .${classes.label}`]: {
        ...theme.typography.body1Bold,
        [theme.breakpoints.up('md')]: {
          textAlign: 'center',
        },
      },
      '&:hover': {
        background: theme.palette.grey['50'],
      },
    },
    iconCardRootChecked: {
      borderColor: theme.palette.grey[600],
      background: theme.palette.primary.main,
      '&:hover': {
        background: theme.palette.primary.main,
      },
    },
    control: {
      margin: '8px 0 25px',
      padding: 0,
      [theme.breakpoints.down('md')]: {
        margin: 20,
      },
    },
    horizontalControl: {
      margin: 0,
      padding: 12,
      [theme.breakpoints.down('md')]: {
        margin: 0,
      },
    },
    radioButtonCardControl: {
      margin: '8px 0 18px',
      padding: 0,
      [theme.breakpoints.down('md')]: {
        margin: 0,
      },
    },
    hasIcon: {},
    hasImage: {},
    hasTooltip: {},
    help: {
      cursor: 'pointer',
      height: 44,
      width: 44,
      borderRadius,
      border: 'none',
      alignItems: 'center',
      backgroundColor: theme.palette.grey[50],
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      padding: 0,
      marginRight: 10,
      right: 1,
      [theme.breakpoints.up('md')]: {
        marginTop: 4,
        marginRight: 4,
        top: 1,
      },
    },
    helpIcon: theme.mixins.setColorSvg(theme.palette.grey[700]),
    icon: {
      margin: '30px auto auto auto',
      [theme.breakpoints.down('md')]: {
        margin: 0,
      },
    },
    iconFixedToCorner: {
      margin: 0,
      right: 0,
      top: 0,
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    iconCircleBackground: {
      right: 5,
      top: 5,
      width: 14,
      height: 14,
      borderRadius: '50%',
      backgroundColor: theme.palette.success.light,
    },
    iconSelectable: theme.mixins.setColorSvg(theme.palette.success.main),
    iconCardLabel: {
      textAlign: 'center',
      wordBreak: 'keep-all',
      wordWrap: 'normal',
      marginTop: 5,
    },
    iconCardLabelChecked: {
      ...theme.typography.body1Bold,
      color: theme.palette.primary.contrastText,
    },
    iconCardLabelDisabled: {
      color: theme.palette.text.disabled,
    },
    image: {
      height: 'auto',
      width: '100%',
      // This has to be set to parent border radius minus parent border
      // width (i.e. 8px - 1px) so the image is tight to the parent border
      borderTopLeftRadius: 7,
      borderTopRightRadius: 7,
      color: theme.palette.icon.stroke,
      marginTop: 2,
      [theme.breakpoints.down('md')]: {
        margin: '7px 0',
        width: 72,
        height: 'auto',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
      [`.${classes.large} &`]: {
        margin: '0',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
      },
    },
    label: {
      ...theme.typography.body1,
      // Double line height
      minHeight: 40,
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      margin: '10px 10px 0',
      textAlign: 'center',
      wordBreak: 'keep-all',
      wordWrap: 'normal',
      [theme.breakpoints.down('md')]: {
        textAlign: 'left',
        marginLeft: 10,
        marginTop: 0,
        alignItems: 'center',
        [`.${classes.hasTooltip} &`]: {
          marginRight: 54,
        },
      },
    },
    horizontalLabel: {
      alignItems: 'center',
      margin: '0 10px',
    },
    noIconWithShowMoreOrLessProps: {
      height: 190,
    },
    noIconLabelWithShowMoreOrLessProps: {
      margin: 'auto',
      paddingLeft: 5,
    },
    radioButtonCardLabel: {
      minHeight: 20,
    },
    subLabel: theme.typography.body1Italics,
    labelRoot: {
      flexDirection: 'column-reverse',
      margin: 0,
      height: 'calc(100% - 2px)',
      width: 'calc(100% - 2px)',
      zIndex: 2,
      borderRadius: borderRadius,
      '&.Mui-disabled': {
        backgroundColor: theme.palette.grey[50],
        borderRadius,
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'row',
      },
      [`.${classes.large} &`]: {
        ...theme.mixins.shadow2,
        alignItems: 'normal',
        backgroundColor: theme.palette.grey[50],
        borderRadius,
      },
    },
    iconCardLabelRoot: {
      padding: '10px 30px 15px',
      flexDirection: 'column-reverse',
      margin: 0,
      height: 'calc(100% - 2px)',
      width: 'calc(100% - 2px)',
      zIndex: 2,
      borderRadius: borderRadius,
      '&.Mui-disabled': {
        backgroundColor: theme.palette.grey[50],
        borderRadius,
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'row',
      },
      [`.${classes.large} &`]: {
        ...theme.mixins.shadow2,
        alignItems: 'normal',
        backgroundColor: theme.palette.grey[50],
        borderRadius,
      },
    },
    horizontalLabelRoot: {
      flexDirection: 'row-reverse',
      padding: '0 8px 0 20px',
      height: 'calc(100% - 2px)',
      width: 'calc(100% - 2px)',
      zIndex: 2,
      borderRadius: 3,
      '&.Mui-disabled': {
        backgroundColor: theme.palette.grey[50],
        borderRadius,
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'row',
      },
      [`.${classes.large} &`]: {
        ...theme.mixins.shadow2,
        alignItems: 'normal',
        backgroundColor: theme.palette.grey[50],
        borderRadius,
      },
    },
    radioButtonCardLabelRoot: {
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse',
        marginBottom: 20,
      },
    },
    paddedHorizontalLabelRoot: {
      flexDirection: 'row',
      padding: '0 5px',
      width: '100%',
      p: {
        fontSize: theme.typography.body4,
      },
    },
    paddedHorizontalLabel: {
      fontSize: theme.typography.body4,
      textAlign: 'left',
      minHeight: 'auto',
      margin: '10px',
    },
    sizePaddedHorizontal: {
      maxHeight: '60px',
    },
    profileLabel: {
      ...theme.typography.body1Bold,
      textAlign: 'left',
      textOverflow: 'ellipsis',
      overflowWrap: 'normal',
      // Bottom margin of 40 to account for edit/delete controls
      margin: '10px 10px 40px 15px',
      display: 'revert',
      maxWidth: 190,
      minHeight: 'auto',
      [theme.breakpoints.down('md')]: { alignSelf: 'normal' },
      [theme.breakpoints.only('lg')]: { maxWidth: 169 },
    },
    profileImage: theme.mixins.setColorSvg(theme.palette.grey[50]),
    profileLabelButton: {
      border: 'none',
      height: 'auto',
    },
    profileLabelRoot: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
      height: '100%',
      marginLeft: 0,
      width: '100%',
      position: 'relative',
      overflow: 'hidden',
      [`.${classes.large} &`]: {
        ...theme.mixins.shadow2,
        alignItems: 'normal',
        backgroundColor: theme.palette.grey[50],
        borderRadius,
      },
    },
    labelWrapper: {
      flexDirection: 'column',
      display: 'flex',
      margin: 0,
      alignItems: 'inherit',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'space-between',
      },
      flexGrow: 1,
      // don't allow ourselves to be pushed wide
      // by our children elements
      maxWidth: '100%',
      [theme.breakpoints.down('md')]: {
        alignItems: 'center',
        flexDirection: 'row',
      },
      [`.${classes.large} &`]: {
        flexDirection: 'row',
        height: '100%',
      },
    },
    horizontalLabelWrapper: {
      flexDirection: 'row',
      display: 'flex',
      margin: '10px 0 10px',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-start',
      },
      flexGrow: 1,
      // don't allow ourselves to be pushed wide
      // by our children elements
      maxWidth: '100%',
      [theme.breakpoints.down('md')]: {
        alignItems: 'center',
        flexDirection: 'row',
      },
      [`.${classes.large} &`]: {
        flexDirection: 'row',
        height: '100%',
      },
    },
    iconCardLabelWrapper: {
      flexDirection: 'column',
      display: 'flex',
      margin: 0,
      alignItems: 'center',
      flexGrow: 1,
      maxWidth: '100%',
    },
    radioButtonCardLabelWrapper: {
      flexDirection: 'column',
    },
    iconCard: {
      ...theme.mixins.setColorSvg(theme.palette.grey[900]),
      height: 35,
    },
    iconCardChecked: theme.mixins.setColorSvg(theme.palette.primary.contrastText),
    iconCardDisabled: theme.mixins.setColorSvg(theme.palette.text.disabled),
    iconCardInfo: theme.mixins.setColorSvg(theme.palette.text.tertiary),
    large: {
      borderRadius,
    },
    medium: {
      width: 178,
      [theme.breakpoints.only('lg')]: {
        width: 149,
      },
      [theme.breakpoints.only('md')]: {
        width: 168,
      },
    },
    small: {
      width: 140,
      [theme.breakpoints.only('lg')]: {
        width: 149,
      },
      [theme.breakpoints.only('md')]: {
        width: 168,
      },
    },
    sizeHorizontal: {
      minWidth: 180,
      maxHeight: 40,
      borderRadius,
      marginBottom: 0,
      [theme.breakpoints.only('sm')]: {
        marginBottom: 12,
      },
    },
    sizeIconCard: {
      height: 85,
      borderRadius,
      marginBottom: 0,
    },
    iconDisabled: {
      opacity: 0.3,
    },
    labelIcon: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginLeft: 'auto',
      alignItems: 'center',
      '& img': {
        marginRight: 15,
        height: 36,
      },
    },
    svg: {
      ...theme.mixins.svg(),
      '& svg, &': {
        height: 42,
        width: 42,
      },
    },
    svgHorizontal: {
      ...theme.mixins.svg(),
      margin: 0,
      '& svg, &': {
        height: 20,
        width: 19,
      },
    },
    svgRadioButtonCard: {
      ...theme.mixins.svg(),
      marginTop: 18,
      '& svg, &': {
        height: 32,
        width: 32,
      },
    },
    completeIcon: {
      ...theme.mixins.setColorSvg(theme.palette.success.main),
      marginRight: 3,
    },
    incompleteIcon: {
      ...theme.mixins.setColorSvg(theme.palette.warning.main),
      marginRight: 3,
    },
    cardTitleAndIcon: {
      display: 'flex',
    },
  };
});
