import { emptyObject } from '@ecp/utils/common';

import { buildLocationFromTo } from './buildLocationFromTo';
import { globalBasename } from './history';
import { matcher } from './matchers';
import { parseSearchParams, stringifySearchParams } from './searchParams';
import type { Location, To } from './types';
import { useRoute } from './wouter';

type EnhancedLocation = Omit<typeof window.location, keyof Location> & {
  base: string;
  parameters: typeof matcher;
} & Location;

/**
 * Location object which accounts for the global (document) base path.
 * This object has stable identity, all entries are updated just in time.
 * Updates are made via navigate function (preferred) or useNavigate hook.
 * Neither the object nor its entries can be used as dependencies in React hooks.
 *
 * NOTE: All dynamic window.location properties must be (and already are) exposed via getters in this object,
 * or they won't reflect the reality. If it's too cumbersome, make this object a window.location Proxy.
 */
export const location = new Proxy(window.location, {
  get(target, prop, receiver) {
    switch (prop) {
      case 'base':
        return globalBasename;
      case 'hash':
        return target.hash.substring(1);
      case 'pathname':
        return ('/' + target.pathname).replace(globalBasename, '').replace(/^\/*/, '/');
      case 'search':
        return parseSearchParams(target.search.substring(1));
      case 'parameters':
        return matcher;
      default:
        return Reflect.get(target, prop, receiver);
    }
  },
}) as unknown as EnhancedLocation;

export const stringifyLocation = (location: To): string => {
  const searchParams = stringifySearchParams(location.search);

  return `${location.pathname}${searchParams}${location.hash ? `#${location.hash}` : ''}`;
};

/** Returns location relative to the closest Router base path. */
export const useLocation = (patternToMatch = ''): Location => {
  const [, parameters] = useRoute(patternToMatch);
  const parsedLocation = buildLocationFromTo(location);
  // TODO Add base entry

  return {
    ...parsedLocation,
    parameters: parameters || emptyObject,
  };
};
