import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AccordionTitle' })((theme) => ({
  root: {
    borderRadius: 0,
    boxShadow: 'none',
    width: '100%',
    '& .MuiAccordionSummary-expandIconWrapper': theme.mixins.setColorSvg(
      theme.palette.action.active,
    ),
    '&::before': {
      display: 'block',
    },
  },
  icon: {
    ...theme.mixins.setColorSvg(theme.palette.secondary.main),
    width: 24,
    height: 24,
  },
  title: { ...theme.typography.body1Bold, verticalAlign: 'super' },
  titleParentheses: { ...theme.typography.body1, color: theme.palette.text.tertiary },
  iconDisabled: { ...theme.mixins.setColorSvg(theme.palette.text.disabled) },
}));
