import { Stack } from '@mui/material';

import { PAGE_PATH } from '@ecp/features/servicing/shared/routing';

import { ContactUsLink } from '../../ContactUsLink';
import { Link } from '../../Link';
import { PartnerLogo } from '../../PartnerLogo';
import { useStyles } from './SiteWideLinks.styles';

interface Props {
  logo?: React.ReactElement;
}

export const SiteWideLinks: React.FC<Props> = (props) => {
  const { logo } = props;
  const { classes } = useStyles();

  return (
    <Stack
      direction={{ xs: 'column', sm: 'column', md: 'row' }}
      className={classes.root}
      spacing={4}
    >
      <Stack>{logo || <PartnerLogo classes={{ root: classes.logo }} />}</Stack>
      <Stack spacing={1}>
        <h3 className={classes.header}>Site Links</h3>
        <Link
          className={classes.links}
          component='button'
          to={PAGE_PATH.DASHBOARD_INDEX}
          trackingLabel='overview_footer'
          trackingName='overview_footer_link'
        >
          Overview
        </Link>
        <Link
          className={classes.links}
          component='button'
          to={PAGE_PATH.DASHBOARD_CLAIMS_OVERVIEW}
          trackingLabel='claims_footer'
          trackingName='claims_footer_link'
        >
          Claims
        </Link>
        <Link
          className={classes.links}
          component='button'
          to={PAGE_PATH.FAQ}
          trackingLabel='help_support_footer'
          trackingName='help_and_support_footer_link'
        >
          Help & support
        </Link>
      </Stack>
      <Stack spacing={1}>
        <h3 className={classes.header}>Need help with something?</h3>
        <ContactUsLink
          classes={{ root: classes.links }}
          trackingName='contact_us_footer_link'
          trackingLabel='contact_us_footer'
        >
          Contact us
        </ContactUsLink>
      </Stack>
    </Stack>
  );
};
