import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ScrollSpyItem' })((theme) => ({
  root: {},
  list: {
    listStyle: 'none',
    paddingLeft: 0,
  },
  listItem: {
    margin: 0,
  },
  link: {
    ...theme.typography.body1,
    userSelect: 'none',
    display: 'block',
    color: theme.palette.text.primary,
    textDecoration: 'none',
    padding: `6px 8px`,
    borderLeft: `4px solid ${theme.palette.grey[400]}`,
  },
  substepLink: {
    paddingLeft: 33,
  },
  linkActive: theme.typography.body1Bold,
  borderActive: {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
  },
  icon: theme.mixins.setColorSvg(theme.palette.action.active),
  stepItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  disabledLink: {
    pointerEvents: 'none',
  },
}));
