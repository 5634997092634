import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PaperlessConfirmation' })((theme) => ({
  root: {},
  text: theme.typography.body3,
  closeButton: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 'fit-content',
    },
  },
  linkedPoliciesList: { listStyle: 'none', padding: 0, marginBottom: 0 },
  linkedPolicyCard: { boxShadow: 'none' },
}));
