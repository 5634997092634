import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutopaySetup' })((theme) => ({
  root: {
    maxWidth: 360,
  },
  paymentBorder: { borderRadius: 8 },
  dateHeading: { ...theme.typography.body3, paddingBottom: 4 },
  amountTitle: theme.typography.h4,
  todayDate: theme.typography.h4,
  amount: theme.typography.body3Bold,
  cardContent: { padding: 15 },
  radioLabel: {
    '& .MuiFormLabel-root': {
      paddingBottom: 12,
    },
  },
  amountStack: { justifyContent: 'space-between' },
  spinner: { paddingTop: 20, display: 'flex', justifyContent: 'center' },
  paymentMethodHeading: theme.typography.body3,
  paymentMethod: theme.typography.body3Bold,
  paymentSubtext: { ...theme.typography.body1, color: theme.palette.text.tertiary },
  paymentDetails: { ...theme.typography.h3, color: theme.palette.text.primary },
}));
