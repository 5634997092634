import { Stack } from '@mui/material';

import { NavLink } from '@ecp/features/servicing/shared/components';
import { generateHelpPath, PAGE_PATH } from '@ecp/features/servicing/shared/routing';

import { DisclosureWrapper } from '../../../components';
import { useStyles } from './TermsOfUseConnect.styles';

export const TermsOfUseConnect: React.FC = () => {
  const { classes } = useStyles();

  return (
    <DisclosureWrapper
      title='CONNECT Terms of Use'
      breadcrumbTitleOverrides={{ termsofuse: 'Terms of Use' }}
    >
      <Stack spacing={6} className={classes.root}>
        <Stack spacing={2}>
          <p>
            <span className={classes.spanItalics}>Last revised October 26, 2021</span>
          </p>
          <p>
            This website ("Site") is owned and operated by CONNECT, powered by American Family
            Insurance ("CONNECT," "we," "our," or "us"). The Site provides information about our
            products and services (collectively, "Services").
          </p>
          <p>
            THIS TERMS OF USE ("TERMS") CONSTITUTE A BINDING AGREEMENT BETWEEN YOU AND US. PLEASE
            READ CAREFULLY THROUGH ALL SECTIONS OF THESE TERMS. YOUR ACCESS TO AND USE OF THE SITE
            IS SUBJECT TO THESE TERMS AND ALL APPLICABLE LAWS AND WE RESERVE THE RIGHT TO TERMINATE
            YOUR ACCESS TO THE SITE IF YOU VIOLATE THESE TERMS. CLICKING ON LINKS WITHIN THE SITE OR
            WEBPAGES BEYOND THE SITE'S HOMEPAGE CONSTITUTES YOUR ACCEPTANCE OF AND AGREEMENT WITH
            THESE TERMS WHETHER OR NOT YOU COMPLETE A TRANSACTION WITH US AND WHETHER OR NOT YOU
            COMPLETE YOUR TRANSACTION ON THE SITE OR THROUGH OTHER CHANNELS, SUCH AS BY TELEPHONE,
            EMAIL, FACSIMILE OR OTHERWISE. IF YOU DO NOT AGREE WITH THESE TERMS, DO NOT ACCESS OR
            OTHERWISE USE THE SITE, ANY SERVICES AVAILABLE THROUGH THIS SITE, OR ANY INFORMATION
            CONTAINED ON THIS SITE.
          </p>
          <p>
            ARBITRATION NOTICE AND CLASS ACTION AND JURY TRIAL WAIVER. These Terms contain a binding
            arbitration agreement and class action and jury trial waiver clauses. Except for certain
            types of disputes described in the arbitration section below or where prohibited by
            applicable law, you agree that disputes between you and us will be resolved by binding,
            individual arbitration and you waive your right to participate in a class action lawsuit
            or class-wide arbitration.
          </p>
          <p>
            We may make changes to the content available on the Site at any time. We can change,
            update, add, or remove provisions of these Terms at any time by posting the updated
            Terms on the Site. By using the Site after we have updated the Terms, you are agreeing
            to the then-current Terms.
          </p>
          <p>
            In addition to these Terms, your use of certain Services may be governed by additional
            agreements.
          </p>
          <p>
            To learn more about how we use, share and protect the privacy of CONNECT customers and
            users of the Site, please{' '}
            <NavLink
              to={generateHelpPath(PAGE_PATH.DISCLOSURES, 'connect')}
              trackingName='consumer_privacy_notices_link'
              trackingLabel='consumer_privacy_notices'
            >
              view our privacy policies and notices
            </NavLink>
            .
          </p>
        </Stack>
        <Stack spacing={2}>
          <h2>Accessing the Site</h2>
          <p>
            We reserve the right to withdraw or amend this Site, and any Services or Materials
            (defined below) we provide on the Site, in our sole discretion without notice. We will
            not be liable if for any reason all or any part of the Site is unavailable at any time
            or for any period. From time to time, in our sole discretion and without notice, we may
            restrict access to some parts of the Site, or the entire Site, to users, including
            registered users.
          </p>
          <div>
            You are responsible for both:
            <Stack spacing={1}>
              <ul>
                <li>Making all arrangements necessary for you to have access to the Site.</li>
                <li>
                  Ensuring that all persons who access the Site through your internet connection are
                  aware of these Terms and comply with them.
                </li>
              </ul>
            </Stack>
          </div>
          <p>
            To access the Site or some of the resources it offers, you may be asked to provide
            certain registration details or other information. It is a condition of your use of the
            Site that all the information you provide on the Site is correct, current, and complete.
          </p>
        </Stack>
        <Stack spacing={2}>
          <h2>Access to Password Protected/Secure Areas</h2>
          <p>
            Access to and use of password protected and secure areas of the Site are restricted to
            authorized users only. Unauthorized individuals attempting to access these areas of the
            Site may be subject to prosecution.
          </p>
          <p>
            If you choose, or are provided with, a user ID, password, or any other piece of
            information as part of our security procedures, you must treat such information as
            confidential, and you must not disclose it to any other person or entity. You also
            acknowledge that your account is personal to you and agree not to provide any other
            person with access to this Site or portions of it using your user ID, password, or other
            security information. You agree to notify us immediately of any unauthorized access to
            or use of your user ID or password or any other breach of security. You also agree to
            ensure that you exit from your account at the end of each session. You should use
            particular caution when accessing your account from a public or shared computer so that
            others are not able to view or record your password or other personal information.
          </p>
          <p>
            We have the right to disable any user ID, password, or other identifier, whether chosen
            by you or provided by us, at any time in our sole discretion for any reason, including
            if, in our opinion, you have violated any provision of these Terms.
          </p>
        </Stack>
        <Stack spacing={2}>
          <h2>Proprietary Rights and Your Use of the Site</h2>
          <p>
            Unless otherwise specified in these Terms, all information and screens appearing on this
            Site are the sole property of CONNECT or our subsidiaries and affiliates, and other
            parties. We provide content through the Site that is copyrighted and/or trademarked work
            of CONNECT or our third-party licensors and suppliers (collectively, the "Materials").
            Materials may include documents, services, software, site design, text, graphics, logos,
            video, images, icons, and other content, as well as the arrangement thereof.
          </p>
          <p>
            Subject to these Terms, we hereby grant you a limited, personal, non-exclusive and
            non-transferable license to use, view, print, display and download the Materials for the
            sole purpose of viewing them on a stand-alone personal computer or mobile device and to
            use this Site solely for your personal use. Except for the foregoing license and as
            otherwise required or limited by applicable law, you have no other rights in the Site or
            any Materials and you may not modify, edit, copy, reproduce, create derivative works of,
            reverse engineer, alter, enhance or in any way exploit any of the Site or Materials in
            any manner or for any purpose that would constitute infringement of our, our licensors',
            or the Site's other user's intellectual property rights. All rights not expressly
            granted herein are reserved.
          </p>
          <p>
            If you breach any of these Terms, the above license will terminate automatically and you
            must immediately destroy any downloaded or printed Materials.
          </p>
        </Stack>
        <Stack spacing={2}>
          <h2>Your Communications to the Site</h2>
          <p>
            Except as required by law and in accordance with our privacy policies and notices (which
            are available here), CONNECT will maintain the confidentiality of all user
            communications that contain personal user information and that are transmitted directly
            to us.
          </p>
          <p>
            Notwithstanding the foregoing, by forwarding any content or communications to us through
            the Site or by other electronic means, you thereby grant us a perpetual, royalty-free,
            world-wide, irrevocable, nonexclusive license to use, reproduce, modify, adapt, publish,
            translate, create derivative works from, redistribute, and display such content and
            communications in any form for the purposes of providing the Services and any purpose
            tangentially related to the Services. No compensation will be paid to you with respect
            to our or our sublicensees' use of your communications. By providing or submitting
            content, you represent and warrant that you own or otherwise control all of the rights
            to your submitted content and communications as described in this section including,
            without limitation, all the rights necessary for you to submit the content and
            communications and grant the license above.
          </p>
        </Stack>
        <Stack spacing={2}>
          <h2>Electronic Communications</h2>
          <p>
            By using the Site and/or the Services, you consent to receiving electronic
            communications, including electronic notices, from us. These electronic communications
            may include notices about applicable fees and charges, transactional information and
            other information concerning or related to the Site, Services, and/or Materials. These
            electronic communications are part of your relationship with us. You agree that any
            notices, agreements, disclosures or other communications that we send you electronically
            will satisfy any legal communication requirements, including that such communications be
            in writing.
          </p>
        </Stack>
        <Stack spacing={2}>
          <h2>Permitted Uses</h2>
          <p>By accessing or using the Site, you agree that:</p>
          <ul>
            <li>Your use of the Site is subject to and governed by these Terms;</li>
            <li>
              You will only access or use the Site and transact business with us if you are at least
              16 years old;
            </li>
            <li>
              You will use the Site solely for its Services offered in the normal course of
              business;
            </li>
            <li>You will always act in accordance with the law, custom, and in good faith;</li>
            <li>
              You will comply with and be bound by these Terms as they appear on the Site each time
              you access and use the Site;
            </li>
            <li>
              Each use of the Site by you indicates and confirms your agreement to be bound by these
              Terms; and
            </li>
            <li>
              These Terms are a legally binding agreement between you and us that will be
              enforceable against you.
            </li>
          </ul>
          <p>You further agree to not use the Site in any way that:</p>
          <ul>
            <li>Changes or alters the Site, content or Services that may appear on the Site;</li>
            <li>Impairs in any way the integrity or operation of the Site;</li>
            <li>
              Interferes with or induces a breach of the contractual relationships between us and
              our employees;
            </li>
            <li>
              Is in any way unlawful or prohibited, or that is harmful or destructive to anyone or
              their property;
            </li>
            <li>
              Transmits any advertisements, solicitations, schemes, spam, flooding, or other
              unsolicited email and commercial communications;
            </li>
            <li>Transmits any harmful or disabling computer codes or viruses;</li>
            <li>Harvests email addresses from the Site;</li>
            <li>
              Transmits unsolicited email to the Site or to anyone whose email address includes the
              domain name of the Site;
            </li>
            <li>Interferes with our network services;</li>
            <li>Attempts to gain unauthorized access to our network services;</li>
            <li>
              Suggests an express or implied affiliation or relationship with us without our express
              written permission;
            </li>
            <li>
              Impairs or limits our ability to operate the Site or any other person's ability to
              access and use the Site;
            </li>
            <li>
              Unlawfully impersonates or otherwise misrepresents your affiliation with any person or
              entity;
            </li>
            <li>
              Transmits or uploads violent, obscene, sexually explicit, discriminatory, hateful,
              threatening, abusive, defamatory, offensive, harassing, or otherwise objectionable
              content or images;
            </li>
            <li>Dilutes or depreciates our or any of our affiliates' name and reputation;</li>
            <li>
              Transmits or uploads content or images that infringe upon any third party's
              intellectual property rights or right to privacy; or
            </li>
            <li>
              Unlawfully transmits or uploads any confidential, proprietary or trade secret
              information.
            </li>
          </ul>
          <p>
            This list of prohibited activities provides examples and is not complete or exclusive.
            We reserve the right to terminate access to your account and your ability to use this
            Site (or the Materials) with or without cause and with or without notice, for any reason
            or no reason, or for any action that we determine is inappropriate or disruptive to this
            Site or to any other user of this Site and/or Materials. We may report to law
            enforcement authorities any actions that may be illegal, and any reports it receives of
            such conduct. When legally required or at our discretion, we will cooperate with law
            enforcement agencies in any investigation of alleged illegal activity on this Site or on
            the Internet.
          </p>
        </Stack>
        <Stack spacing={2}>
          <h2>Reliance on Information Posted</h2>
          <p>
            The information presented on or through the Site is made available solely for general
            information purposes. We do not warrant the accuracy, completeness, or usefulness of
            this information. Any reliance you place on such information is strictly at your own
            risk. We disclaim all liability and responsibility arising from any reliance placed on
            such materials by you or any other visitor to the Site, or by anyone who may be informed
            of any of its contents.
          </p>
          <p>
            All press releases and other materials presented or released to the financial community
            that are contained on the Site were, to the best of our knowledge, timely and accurate
            when issued. However, the passage of time can render information stale, and you should
            not rely on the continued accuracy of any such material beyond the date of issuance.
            CONNECT has no responsibility to update any information contained in any such material.
            All viewers should carefully check the dates of issuance of the material contained in
            the Site.
          </p>
          <p>
            This Site may include content provided by third parties, including materials provided by
            other users, and third-party licensors, syndicators, aggregators, and/or reporting
            services. All statements and/or opinions expressed in these materials, and all articles
            and responses to questions and other content, other than the content provided by us, are
            solely the opinions and the responsibility of the person or entity providing those
            materials. These materials do not necessarily reflect the opinion of CONNECT. We are not
            responsible, or liable to you or any third party, for the content or accuracy of any
            materials provided by any third parties.
          </p>
        </Stack>
        <Stack spacing={2}>
          <h2>Forward looking information</h2>
          <p>
            Certain information on this Site contains certain forward-looking statements, which are
            subject to risks and uncertainties and speak only to circumstances as of the date on
            which they are made. The words "believe", "expect", "anticipate", "optimistic",
            "intend", "aim", "will" or similar expressions are intended to identify forward-looking
            statements. Readers are cautioned not to place undue reliance on these forward-looking
            statements, which speak only to circumstances as of the date on which they are made.
            CONNECT undertakes no obligation to update publicly or revise any forward-looking
            statements. Important factors that could cause actual results to differ materially from
            the company's forward-looking statements are described in CONNECT's periodic reports.
          </p>
        </Stack>
        <Stack spacing={2}>
          <h2>Third-Party Links</h2>
          <p>
            This Site may link to other websites that are not sites controlled or operated by us
            (collectively, "Third-Party Sites"). Certain areas of the Site may allow you to interact
            and/or conduct transactions with such Third-Party Sites and, if applicable, allow you to
            configure your privacy settings in your Third-Party Site account to permit your
            activities on this Site to be shared with your contacts in your Third-Party Site account
            and, in certain situations, you may be transferred to a Third-Party Site through a link
            but it may appear that you are still on this Site. In any case, you acknowledge and
            agree that the Third-Party Sites may have different privacy policies and terms and
            conditions and/or user guides and business practices than CONNECT, and you further
            acknowledge and agree that your use of such Third-Party Sites is governed by the
            respective Third-Party Site privacy policy and terms and conditions and/or user guides.
            We provide links to the Third-Party Sites to you as a convenience, and we do not verify,
            make any representations or take responsibility for such Third-Party Sites, including,
            without limitation, the truthfulness, accuracy, quality or completeness of the content,
            services, links displayed and/or any other activities conducted on or through such
            Third-Party Sites. YOU AGREE THAT WE WILL NOT, UNDER ANY CIRCUMSTANCES, BE RESPONSIBLE
            OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY GOODS, SERVICES, INFORMATION, RESOURCES
            AND/OR CONTENT AVAILABLE ON OR THROUGH ANY THIRD-PARTY SITES AND/OR THIRD-PARTY DEALINGS
            OR COMMUNICATIONS, OR FOR ANY HARM RELATED THERETO, OR FOR ANY DAMAGES OR LOSS CAUSED OR
            ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH YOUR USE OR RELIANCE ON THE CONTENT OR
            BUSINESS PRACTICES OF ANY THIRD-PARTY. Any reference on the Site to any product,
            service, publication, institution, organization of any third-party entity or individual
            does not constitute or imply our endorsement or recommendation.
          </p>
        </Stack>
        <Stack spacing={2}>
          <h2>Linking to the Site and Social Media Features</h2>
          <p>
            You may link to our homepage, provided you do so in a way that is fair and legal and
            does not damage our reputation or take advantage of it, but you must not establish a
            link in such a way as to suggest any form of association, approval, or endorsement on
            our part without our express written consent.
          </p>
          <p>This Site may provide certain social media features that enable you to:</p>
          <ul>
            <li>
              Link from your own or certain third-party websites to certain content on this Site.
            </li>
            <li>
              Send emails or other communications with certain content, or links to certain content,
              on this Site.
            </li>
            <li>
              Cause limited portions of content on this Site to be displayed or appear to be
              displayed on your own or certain third-party websites.
            </li>
          </ul>
          <p>
            You may use these features solely as they are provided by us and otherwise in accordance
            with any additional terms and conditions we provide with respect to such features.
            <br />
            &nbsp;Subject to the foregoing, you must not:
          </p>
          <ul>
            <li>Establish a link from any website that is not owned by you.</li>
            <li>
              Cause the Site or portions of it to be displayed on, or appear to be displayed by, any
              other site, for example, framing, deep linking, or in-line linking.
            </li>
            <li>
              Otherwise take any action with respect to the materials on this Site that is
              inconsistent with any other provision of these Terms.
            </li>
          </ul>
          <p>
            You agree to cooperate with us in causing any unauthorized framing or linking
            immediately to stop. We reserve the right to withdraw linking permission without notice.
          </p>
          <p>
            We may disable all or any social media features and any links at any time without notice
            in our discretion.
          </p>
        </Stack>
        <Stack spacing={2}>
          <h2>Federal and State Laws</h2>
          <p>
            The Site and Services are designed for residents of the United States only and should
            not be considered a solicitation to buy or an offer to sell financial products and
            services in any other jurisdiction. The Site is operated from the United States and is
            not approved for distribution outside of the U.S. and non-U.S. residents should not rely
            or act upon the information contained within. When using the Site, on the Site, or when
            using any content provided by us, you must obey all applicable U.S. federal, state and
            local laws and regulations.
          </p>
        </Stack>
        <Stack spacing={2}>
          <h2>Minimum Age</h2>
          <p>
            The Site and Services are not intended for persons under the age of sixteen (16). By
            using the Site, you represent and warrant that you are sixteen (16) years of age or
            over.
          </p>
        </Stack>
        <Stack spacing={2}>
          <h2>Disclaimer of Warranties</h2>
          <p>
            Your use of this Site is at your own risk. The Materials have not been verified or
            authenticated in whole or in part by us, and they may include inaccuracies or
            typographical or other errors. We do not warrant the accuracy or timeliness of the
            Materials contained on this Site. We have no liability for any errors or omissions in
            the Materials, whether provided by us, our licensors or suppliers or other users.
          </p>
          <p>
            TO THE FULLEST EXTENT PROVIDED BY LAW AND EXCEPT AS OTHERWISE PROVIDED HEREIN OR ON THE
            SITE, THE INFORMATION AND SERVICES OFFERED ON OR THROUGH THE SITE AND ANY REFERENCED
            THIRD-PARTY SITE ARE PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS
            OR IMPLIED. ANY THIRD-PARTY GOODS OR SERVICE PROVIDERS ARE SUPPLIED AS A CONVENIENCE TO
            YOU AND LISTING DOES NOT CONSTITUTE SPONSORSHIP, AFFILIATION, PARTNERSHIP, OR
            ENDORSEMENT. TO THE FULLEST EXTENT ALLOWED BY LAW, WE DISCLAIM ALL EXPRESS AND IMPLIED
            WARRANTIES, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS
            FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, SECURITY OR ACCURACY.
          </p>
          <p>
            TO THE FULLEST EXTENT ALLOWED BY LAW, WE DO NOT WARRANT OR MAKE ANY REPRESENTATIONS
            REGARDING THE USE OR THE RESULTS OF THE USE OF THE SITE, CONTENT, OR OTHER POSTED
            MATERIALS ON THE SITE IN TERMS OF ITS CORRECTNESS, ACCURACY, TIMELINESS, RELIABILITY OR
            OTHERWISE.
          </p>
          <p>
            BY PROVIDING THE SERVICES ON THE SITE, WE DO NOT IN ANY WAY PROMISE THAT THE SERVICES
            WILL REMAIN AVAILABLE TO YOU. WE ARE ENTITLED TO TERMINATE ALL OR PART OF ANY OF THE
            SITE AT ANY TIME, IN OUR SOLE DISCRETION WITHOUT NOTICE TO YOU.
          </p>
        </Stack>
        <Stack spacing={2}>
          <h2>Limitation of Liability</h2>
          <p>
            YOU AGREE THAT NEITHER WE NOR OUR AFFILIATES, EMPLOYEES, AGENTS, REPRESENTATIVES AND
            THIRD-PARTY SERVICE PROVIDERS WILL BE LIABLE FOR ANY DIRECT, PUNITIVE, SPECIAL,
            CONSEQUENTIAL, INCIDENTAL OR INDIRECT DAMAGES (INCLUDING WITHOUT LIMITATION LOST
            PROFITS, BUSINESS INTERRUPTION, LOSS OF PROGRAMS OR OTHER DATA, COST OF PROCURING
            SUBSTITUTE SERVICE OR LOST OPPORTUNITY) ARISING OUT OF OR IN CONNECTION WITH THE USE OF
            THE SITE OR A HYPERLINKED SITE, THE MATERIALS, THE CONTENT OR SERVICES OBTAINED THROUGH
            THE SITE, OR WITH THE DELAY OR INABILITY TO USE THE SITE OR A HYPERLINKED SITE WHETHER
            BASED ON WARRANTY, CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE EVEN IF WE ARE
            EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </p>
        </Stack>
        <Stack spacing={2}>
          <h2>Indemnification</h2>
          <p>
            You agree to indemnify, defend and hold harmless us and our affiliates, employees,
            agents, representatives and third-party service providers, for any and all claims,
            demands, actions, liability, fines, penalties and expenses that may arise from any of
            your acts through the use of the Site. Such acts may include but are not limited to:
            providing content to or communicating with us or our affiliates, unauthorized use of
            material obtained through the Site, engaging in a prohibited activity, or any other
            action that breaches these Terms.
          </p>
        </Stack>
        <Stack spacing={2}>
          <h2>Copyright Complaints</h2>
          <p>
            All Contents ©2021 American Family Connect Property and Casualty Insurance Company, Inc.
            All Rights Reserved. The contents of all material available on this Internet site are
            copyrighted by American Family Connect Property and Casualty Insurance Company, Inc.
            unless otherwise indicated. All rights are reserved by American Family Connect Property
            and Casualty Insurance Company, Inc., and content may not be reproduced, downloaded,
            disseminated, published, or transferred in any form or by any means, except with the
            prior written permission of American Family Connect Property and Casualty Insurance
            Company, Inc. Copyright infringement is a violation of federal law subject to criminal
            and civil penalties.
          </p>
          <p>
            We respect the intellectual property of others. If you believe that your work has been
            copied in a way that constitutes copyright infringement, please provide our copyright
            agent with the following information.
          </p>
          <ul>
            <li>
              An electronic or physical signature of the person authorized to act on behalf of the
              owner of the copyright interest;
            </li>
            <li>Description of the copyrighted work that you claim has been infringed;</li>
            <li>The location on the Site of the material that you claim is infringing;</li>
            <li>Your address, telephone number and e-mail address;</li>
            <li>
              A statement that your claim of infringement is based on a good faith belief; and
            </li>
            <li>
              A statement made under penalty of perjury that the information you have provided is
              accurate and that you are the copyright owner or authorized to act on the copyright
              owner's behalf.
            </li>
          </ul>
          <p>
            Our agent for notice of claims of copyright infringement on the Site can be reached as
            follows:
          </p>
          <p>
            <span className={classes.spanBold}>CONNECT, powered by American Family Insurance</span>
            <br />
            Attn: Legal Department
            <br />
            6000 American Parkway
            <br />
            Madison, WI 53783-0001
          </p>
        </Stack>
        <Stack spacing={2}>
          <h2>Miscellaneous Provisions</h2>
          <p>
            &nbsp;
            <span className={classes.spanBoldItalics}> Arbitration. </span>
            YOU AGREE THAT ALL DISPUTES BETWEEN YOU AND US (WHETHER OR NOT SUCH DISPUTE INVOLVES A
            THIRD PARTY) WITH REGARD TO YOUR RELATIONSHIP WITH US, INCLUDING WITHOUT LIMITATION
            DISPUTES ARISING OUT OF, RELATED TO, OR CONNECTED WITH THESE TERMS, YOUR USE OF THE SITE
            AND/OR SERVICES, AND/OR RIGHTS OF PRIVACY AND/OR PUBLICITY, WILL BE RESOLVED BY BINDING,
            INDIVIDUAL ARBITRATION UNDER THE AMERICAN ARBITRATION ASSOCIATION'S ("AAA") RULES FOR
            ARBITRATION OF CONSUMER-RELATED DISPUTES AND YOU AND WE HEREBY EXPRESSLY WAIVE TRIAL BY
            JURY.
          </p>
          <p>
            This dispute resolution provision will be governed by the Federal Arbitration Act and
            not by any state law concerning arbitration. The arbitrator shall not conduct any form
            of class or collective arbitration nor join or consolidate claims by or for individuals.
            To the extent allowed by applicable law, the Arbitrator, and not any federal, state, or
            local court or agency, shall have exclusive authority to resolve any dispute relating to
            the interpretation, applicability, enforceability or formation of these Terms including,
            but not limited to, any claim that all or any part of these Terms is void or voidable.
            Judgment on the award rendered by the arbitrator may be entered in any court having
            competent jurisdiction. Any provision of applicable law notwithstanding, the arbitrator
            will not have authority to award damages, remedies or awards that conflict with these
            Terms.
          </p>
          <p>
            There are only two exceptions to this agreement to arbitrate. First, if we reasonably
            believe that you have in any manner violated or threatened to violate our intellectual
            property rights, we may seek injunctive or other appropriate relief in any court of
            competent jurisdiction Second, any claim of $500 or less may, at the option of the
            claiming party, be resolved in small claims court in Brown County, Wisconsin, if the
            claim and the parties are within the jurisdiction of the small claims court. For these
            two exceptions, you agree to submit to the personal jurisdiction of the courts located
            within Brown County, Wisconsin for the purpose of litigating such claims or disputes.
          </p>
          <p>
            <span className={classes.spanBoldItalics}>
              {' '}
              &nbsp;&nbsp;&nbsp;Waiver of Right to Jury; Class Action Waiver
            </span>
            &nbsp;&nbsp;TO THE EXTENT ALLOWED BY LAW, YOU AGREE TO IRREVOCABLY WAIVE ANY RIGHT YOU
            MAY HAVE TO A TRIAL BY JURY OR OTHER COURT TRIAL (OTHER THAN SMALL CLAIMS COURT) OR TO
            SERVE AS A CLASS REPRESENTATIVE, AS A PRIVATE ATTORNEY GENERAL, OR IN ANY OTHER
            REPRESENTATIVE CAPACITY, OR TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS, IN ANY
            LAWSUIT, CLASS ARBITRATION, CONSOLIDATION OF INDIVIDUAL ARBITRATIONS, OR OTHER
            PROCEEDING FILED AGAINST US AND/OR RELATED THIRD PARTIES.
          </p>
          <p>
            <span className={classes.spanBoldItalics}>&nbsp;&nbsp;&nbsp;Severability</span>
            &nbsp;&nbsp;If any term or provision in these Terms is found to be void, against public
            policy, or unenforceable by a court of competent jurisdiction and such finding or order
            becomes final with all appeals exhausted, then the offending provision shall be deemed
            modified to the extent necessary to make it valid and enforceable. If the offending
            provision cannot be so modified, then the same shall be deemed stricken from these Terms
            in its entirety and the remainder of these Terms shall survive with the said offending
            provision eliminated.
          </p>
          <p>
            <span className={classes.spanBoldItalics}>
              &nbsp;&nbsp;&nbsp;Governing Law and Venue
            </span>
            &nbsp;&nbsp;These Terms shall be governed by and construed in accordance with the laws
            of the State of Wisconsin, excluding its conflicts of law rules, and the United States
            of America. Without waiving the foregoing arbitration clause, you agree that any dispute
            arising from or relating to the subject matter of these Terms shall be governed by the
            exclusive jurisdiction and venue of the state and Federal courts of Green Bay,
            Wisconsin, except where the jurisdiction and venue are mandated by applicable
            Assignment. We may freely assign our obligations and rights under these Terms, including
            all personal information in our possession which we have collected during your use of
            the Site.
          </p>
          <p>
            <span className={classes.spanBoldItalics}>&nbsp;&nbsp;&nbsp;Website Availability</span>
            &nbsp;&nbsp;WE CANNOT GUARANTEE THE SITE WILL BE AVAILABLE 100% OF THE TIME BECAUSE
            PUBLIC NETWORKS, SUCH AS THE INTERNET, OCCASIONALLY EXPERIENCE DISRUPTIONS. ALTHOUGH WE
            STRIVE TO PROVIDE THE MOST RELIABLE WEBSITE REASONABLY POSSIBLE, INTERRUPTIONS AND
            DELAYS IN ACCESSING THE SITE ARE UNAVOIDABLE AND WE DISCLAIM ANY LIABILITY FOR DAMAGES
            RESULTING FROM SUCH PROBLEMS.
          </p>
          <p>
            <span className={classes.spanBoldItalics}>&nbsp;&nbsp;&nbsp;Headings</span>
            &nbsp;&nbsp;Section headings are for convenience of reference only and shall not affect
            the interpretation of these Terms.
          </p>
          <p>
            <span className={classes.spanBoldItalics}>&nbsp;&nbsp;&nbsp;Typographical Errors</span>
            &nbsp;&nbsp;Information on the Site may contain technical inaccuracies or typographical
            errors. We attempt to make the Site's postings as accurate as possible, but we do not
            warrant the content of the Site is accurate, complete, reliable, current, or error-free.
          </p>
        </Stack>
      </Stack>
    </DisclosureWrapper>
  );
};
