import { useEffect } from 'react';

import * as interactionId from '@ecp/utils/analytics/interaction-id';
import { isTruthy } from '@ecp/utils/common';

import { useUserPolicies } from '@ecp/features/servicing/shared/state';
import type { CustomAttributes } from '@ecp/features/shared/chat';
import { addCustomAttributes } from '@ecp/features/shared/chat';

/**
 * To update Genesys custom attributes, when values of which are available or changed.
 */
export const useAttributes = (): void => {
  const { user, policies } = useUserPolicies({
    throwOnPolicyError: false,
    throwOnUserError: false,
  });

  const productLine = policies.map((policy) => policy.coverageOptions[0].productLine).join(',');
  const quoteOrPolicyNumber = policies.map((policy) => policy.policy.policyNumber).join(',');

  const pathName = globalThis.window.location.pathname;
  const afeInteractionId = interactionId.get();

  useEffect(() => {
    const newAttributes: CustomAttributes = {
      customerInfo: [pathName, productLine].filter(isTruthy).join('~'),
      customerReference: interactionId.get(),
      firstName: user?.firstName,
      lastName: user?.lastName,
      afeInteractionId,
      quoteOrPolicyNumber,
      /*  email: user?.email,
        phone: primaryNamedInsured?.phoneNumbers?.[0].phoneNumber, */
    };

    addCustomAttributes(newAttributes);
  }, [
    user?.firstName,
    user?.lastName,
    productLine,
    pathName,
    afeInteractionId,
    quoteOrPolicyNumber,
  ]);
};
