import type { HomeCoverage } from '@ecp/features/servicing/shared/types';

const baseCoveragesGroupList = [
  'For possessions and ID theft',
  'For temporary relocation',
  'For injury and damage to others',
] as const;

export const homeCoveragesGroupList = [
  'For structural rebuild and repair',
  'For natural and environmental damage',
  ...baseCoveragesGroupList,
] as const;

export const rentersCoveragesGroupList = [
  'For structural and environmental damage',
  ...baseCoveragesGroupList,
] as const;

export type BaseCoveragesGroup = (typeof baseCoveragesGroupList)[number];
export type HomeCoveragesGroup = (typeof homeCoveragesGroupList)[number];
export type RentersCoveragesGroup = (typeof rentersCoveragesGroupList)[number];

export const groupBaseCoveragesMap: Record<BaseCoveragesGroup, string[]> = {
  'For possessions and ID theft': [
    'coverages.increasedCoverageC',
    'coverages.personalProperty',
    'coverages.includeComputers',
    'coverages.includeEquipmentBreakdown',
    'coverages.includeIdentityFraudExpenseCoverage',
    'coverages.personalPropertyPerilsNew',
    'coverages.refrigeratedCoverage',
  ],
  'For temporary relocation': ['coverages.lostOfUse'],
  'For injury and damage to others': [
    'coverages.personalLiability',
    'coverages.medicalPaymentsToOthers',
    'coverages.libel',
  ],
};
export const groupHomeCoveragesMap: Record<HomeCoveragesGroup, string[]> = {
  'For structural rebuild and repair': [
    'coverages.dwelling',
    'coverages.additionalDwelling',
    'coverages.otherStructures',
    'coverages.acvRoofCoverage',
    'coverages.extendedReplacementCost',
    'coverages.includeServiceLine',
    'coverages.includeWaterBackup',
    'coverages.lossAssessment',
    'coverages.ordinanceOrLaw',
  ],
  'For natural and environmental damage': [
    'coverages.includeEarthquake',
    'coverages.mineSubsidence',
  ],
  ...groupBaseCoveragesMap,
};

export const groupRentersCoveragesMap: Record<RentersCoveragesGroup, string[]> = {
  'For structural and environmental damage': ['coverages.includeWaterBackup'],
  ...groupBaseCoveragesMap,
};

export const homeCoveragesMap: Record<string, HomeCoverage[]> = {
  'coverages.acvRoofCoverage': ['acvRoof'],
  'coverages.dwelling': ['dwelling'],
  'coverages.extendedReplacementCost': ['extendedReplacementCost'],
  'coverages.additionalDwelling': ['additionalDwelling'],
  'coverages.includeComputers': ['computer'],
  'coverages.includeEarthquake': ['earthquake'],
  'coverages.includeEquipmentBreakdown': ['equipmentBreakdown'],
  'coverages.includeIdentityFraudExpenseCoverage': ['identityFraudExpense', 'identityTheftExpense'],
  'coverages.includeServiceLine': ['serviceLine'],
  'coverages.includeWaterBackup': ['waterBackup'],
  'coverages.increasedCoverageC': ['personalPossessions'],
  'coverages.libel': ['libel', 'personalInjury'],
  'coverages.lossAssessment': ['lossAssessment'],
  'coverages.lostOfUse': ['lossOfUse', 'ale'],
  'coverages.medicalPaymentsToOthers': ['medical'],
  'coverages.mineSubsidence': ['mineSubsidence'],
  'coverages.moldPropertyProtection': ['fungi'],
  'coverages.otherStructures': ['otherStructures'],
  'coverages.ordinanceOrLaw': ['ordinanceOrLaw'],
  'coverages.personalLiability': ['personalLiability'],
  'coverages.personalPropertyPerilsNew': ['personalPropertyPerils'],
  'coverages.refrigeratedCoverage': ['refrigeratedProperty'],
  'deductibles.hurricane': ['hurricane'],
  'deductibles.windStorm': ['windHail'],
  'deductibles.deductible': ['aop', 'deductible'],
};

export const rentersCoveragesMap: Record<string, HomeCoverage[]> = {
  'coverages.includeComputers': ['computer'],
  'coverages.includeIdentityFraudExpenseCoverage': ['identityFraudExpense'],
  'coverages.includeWaterBackup': ['waterBackup'],
  'coverages.lostOfUse': ['lossOfUse', 'ale'],
  'coverages.medicalPaymentsToOthers': ['medical'],
  'coverages.moldPropertyProtection': ['fungi'],
  'coverages.personalLiability': ['personalLiability'],
  'coverages.refrigeratedCoverage': ['refrigeratedProperty'],
  'deductibles.deductible': ['aop', 'deductible'],
  'coverages.personalProperty': ['personalPossessions'],
};
