import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ServicingModal' })((theme) => ({
  modal: {
    margin: '15vh auto',
    width: 'calc(100% - 30px)',
  },
  defaultWidth: {
    maxWidth: 450, // to match authentication card
  },
  container: {
    padding: '30px 0',
  },
  title: theme.typography.subtitle,
  body: theme.typography.body1,
  link: {
    ...theme.typography.button,
    color: theme.palette.primary.darkText,
    textDecoration: 'none',
    textTransform: 'capitalize',
    textAlign: 'right',
    whiteSpace: 'inherit',
  },
  button: {},
  footer: {},
}));
