import * as yup from 'yup';

import type { YupSchemaMap } from '@ecp/utils/form';

export interface OneTimeCodeFormInputs {
  otp: string;
}

export const oneTimeCodeSchema: YupSchemaMap<OneTimeCodeFormInputs> = {
  otp: yup
    .string()
    .required('Incorrect code, please try again')
    .matches(/^[0-9]{6}$/, 'Enter all six digits of your code'),
};
