import { useCallback, useMemo, useState } from 'react';

import { Stack } from '@mui/material';
import dayjs from 'dayjs';

import { LoadingOverlay, ModalError } from '@ecp/features/servicing/shared/components';
import { QUERY_PARAMS } from '@ecp/features/servicing/shared/routing';
import type { UseModalPathReturn as UpdateAdditionalInterestReturn } from '@ecp/features/servicing/shared/routing';
import type { AdditionalInterest, ErrorResponse } from '@ecp/features/servicing/shared/state';
import {
  getAddressByRefId,
  usePolicy,
  useSharedState,
  useUpdateAdditionalInterest,
} from '@ecp/features/servicing/shared/state';

import type { AdditionalInterestInputs } from '../AdditionalInterestForm';
import { AdditionalInterestForm } from '../AdditionalInterestForm';
import { useStyles } from './UpdateAdditionalInterest.styles';

export const UpdateAdditionalInterest: React.FC<UpdateAdditionalInterestReturn> = (props) => {
  const { policyNumber, reset, getSearchParam } = props;
  const {
    policyData,
    isLoading: isPolicyLoading,
    refetch,
    isFetching: isPolicyFetching,
  } = usePolicy(policyNumber);

  const policySourceSystemName = policyData?.policy.sourceSystemName;
  const additionalInterests = policyData?.thirdPartyInterests;
  const { updateAdditionalInterest, isSubmitting } = useUpdateAdditionalInterest();
  const [submitError, setSubmitError] = useState<ErrorResponse>();
  const [, setSharedState] = useSharedState<string>('snackBar');
  const thirdPartyInterestRole = getSearchParam(QUERY_PARAMS.THIRD_PARTY_INTEREST_ROLE);
  const currentAdditionalInterest = useMemo(() => {
    return additionalInterests?.find(
      (interest) => interest.thirdPartyInterestRole === thirdPartyInterestRole,
    );
  }, [additionalInterests, thirdPartyInterestRole]);
  const address = getAddressByRefId(policyData, currentAdditionalInterest?.addressRefId);

  if (!policyNumber) {
    throw new Error('Cannot call additional interest api without policyNumber');
  }

  const { classes } = useStyles();

  const handleSubmit = useCallback(
    async (data: AdditionalInterestInputs) => {
      if (!policySourceSystemName) throw Error('policySourceSystemName is requred');
      const additionalInterest = {
        sendEmail: false,
        effectiveDate: dayjs().format('YYYY-MM-DD'),
        primary: !!(currentAdditionalInterest?.thirdPartyInterestRole === 'PRIMARY'),
        ...data,
      };
      // Need to filter empty string properties from body
      const filteredAdditionalInterest = Object.fromEntries(
        Object.entries(additionalInterest).filter(([_, value]) => value !== ''),
      ) as Omit<AdditionalInterest, 'email' | 'phone'>;
      const { success, error } = await updateAdditionalInterest({
        policyNumber,
        policySourceSystemName,
        additionalInterests: [filteredAdditionalInterest],
      });
      if (success) {
        await refetch();
        setSharedState('You have successfully edited your landlord.');
        reset();
      } else if (error) {
        setSubmitError(error);
      }
    },
    [
      currentAdditionalInterest?.thirdPartyInterestRole,
      policyNumber,
      policySourceSystemName,
      refetch,
      reset,
      setSharedState,
      updateAdditionalInterest,
    ],
  );

  if (submitError)
    return (
      <ModalError
        errorTitle='process your request at this time'
        errorDescription='processing your request'
      />
    );

  if (isPolicyLoading) return <LoadingOverlay />;

  return (
    <Stack spacing={3} classes={classes.root}>
      <AdditionalInterestForm
        onSubmitAction={handleSubmit}
        onReset={reset}
        formName='editAdditionalInterest'
        isSubmitProcessing={isSubmitting || isPolicyFetching}
        defaultValues={{
          name: currentAdditionalInterest?.name || '',
          emailAddress: currentAdditionalInterest?.emailAddress || '',
          address1: address?.line1 || '',
          address2: address?.line2,
          state: address?.state || '',
          city: address?.city || '',
          zipCode: address?.zip || '',
        }}
      />
      <p>We may notify your landlord or property manager if you cancel your policy.</p>
    </Stack>
  );
};
