import { Stack } from '@mui/material';

import { NavLink } from '@ecp/features/servicing/shared/components';
import { PAGE_PATH } from '@ecp/features/servicing/shared/routing';
import { generateHelpPath } from '@ecp/features/servicing/shared/routing';

import { DisclosureWrapper } from '../../../components';
import { useStyles } from './OfferingTextingHomesite.styles';

export const OfferingTextingHomesite: React.FC = () => {
  const { classes } = useStyles();

  return (
    <DisclosureWrapper
      title='Homesite Group Incorporated'
      breadcrumbTitleOverrides={{
        terms: 'Terms & Conditions',
        offeringtexting: 'Offerings & Quoting',
      }}
    >
      <Stack spacing={4}>
        <Stack spacing={1}>
          <h2>Offerings & Quoting Texting Terms and Conditions</h2>
          <h3>GENERAL INFORMATION</h3>
          <p>
            Homesite Group Incorporated, including its subsidiaries and affiliates, (collectively,
            "Homesite") would like to welcome you to our text messaging program. Homesite Quote
            Update is an ongoing text messaging program that will provide you with information
            regarding your Homesite product offerings, quote information, marketing and other
            periodic information. The message frequency may vary.
          </p>
          <p>
            Homesite does not have a separate charge for this service, but{' '}
            <b className={classes.boldText}>message and data rates may apply</b> from your mobile
            carrier. By providing your consent to participate in this program, the customer approves
            any such charges from their mobile carrier.
          </p>
          <p>
            By participating in this program, you specifically authorize Homesite to send
            telemarketing communications including mobile text alerts and automated texts using an
            automatic telephone dialing system or an artificial or prerecorded voice system.{' '}
            <b className={classes.boldText}>
              You are not required to consent to the agreement as a condition of purchasing any
              property, goods or services.
            </b>{' '}
            The person consenting to receive telemarketing communications also provides
            authorization for the specific telephone number that may be contacted via this program.
          </p>
        </Stack>

        <Stack spacing={1}>
          <h3>HOW TO OPT-IN</h3>
          <p>
            You may sign up for the Homesite text messaging program through our quote process on our
            website
          </p>
          <p>
            Text QUOTE to 99613 to receive text messages from Homesite Quote Update. Up to
            10msgs/mo. Reply <b className={classes.boldText}>HELP</b> for help,{' '}
            <b className={classes.boldText}>STOP</b> to cancel. Msg & Data Rates May Apply.
          </p>
        </Stack>

        <Stack spacing={1}>
          <h3>HOW TO OPT-OUT</h3>
          <p>
            To stop receiving text messages reply{' '}
            <b className={classes.boldText}>STOP, END, QUIT, CANCEL or UNSUBSCRIBE.</b>
          </p>
          <p>
            By enrolling in this subscription program, you consent that following such a request to
            unsubscribe, you will receive a standard rate message stating: "Homesite Quote Update:
            You have been unsubscribed and will no longer receive text messages from us."
          </p>
          <p>
            We reserve the right to terminate this text messaging service, in whole or in part, at
            any time without notice. The information in any text message may be subject to certain
            time lags and/or delays. You may stop receiving text messages at any time by sending{' '}
            <b className={classes.boldText}>STOP, END, QUIT, CANCEL or UNSUBSCRIBE</b> to short code
            "99613". You may also opt-out through our website.
          </p>
        </Stack>

        <Stack spacing={1}>
          <h3>HOW TO GET HELP</h3>
          <p>
            For additional information, text <b className={classes.boldText}>HELP</b> to short code
            "99613". You may also receive <b className={classes.boldText}>HELP</b> by calling
            1-844-803-9290, or by going to the "contact us" section on our website, which can be
            accessed throughout the quote process.
          </p>
        </Stack>

        <Stack spacing={1}>
          <h3>SUPPORTED CARRIERS</h3>
          <p>
            Carriers are not liable for delayed or undelivered messages. Some wireless carriers do
            not participate in the program.
            <br /> * Compatible carriers include:
          </p>
          <p>
            AT&T, T-Mobile®, Sprint, Boost, U.S. Cellular, Cellular One, MetroPCS, ACS/Alaska,
            Bluegrass Cellular, Cellular One of East Central Illinois, Centennial Wireless, Cox
            Communications, EKN/Appalachian Wireless, GCI, Illinois Valley Cellular, Immix/Keystone
            Wireless, Inland Cellular, Nex-Tech Wireless, Rural Cellular Corporation, Thumb
            Cellular, United Wireless, West Central (WCC), Cellcom, Cellsouth, Cricket, Cincinnati
            Bell and Virgin Mobile.
          </p>
          <p>T-Mobile® is not liable for delayed or undelivered messages</p>
          <p>
            *Customers of the wireless carrier Verizon Wireless will be excluded from participating
            in this program.
          </p>
        </Stack>

        <Stack spacing={1}>
          <h3>PRIVACY</h3>
          <p>
            All information collected from users of our sites is subject to our Privacy Policy,
            which is incorporated by reference into this agreement. For more information, visit our{' '}
            <NavLink to={generateHelpPath(PAGE_PATH.DISCLOSURES_PRIVACYPOLICY, 'homesite')}>
              Privacy Policy
            </NavLink>
            .
          </p>
        </Stack>
      </Stack>
    </DisclosureWrapper>
  );
};
