import { addItemToObject, getItemFromObject } from '@ecp/utils/storage';

import { loggedInSessionStorage } from '@ecp/features/servicing/shared/util';

type Key = 'payment' | 'caMileageForm';

export const saveInLoggedInSessionStorage = (
  key: Key,
  policyNumber: string | undefined,
  val: number | string,
): void => {
  addItemToObject(key, { [`${policyNumber}`]: val.toString() }, loggedInSessionStorage);
};

export const getItemFromLoggedInSessionStorage = (
  key: Key,
  policyNumber: string | undefined,
): string | null => {
  if (!policyNumber) return null;

  return getItemFromObject(key, policyNumber, loggedInSessionStorage);
};

export const removeItemFromLoggedInSessionStorage = (key: Key): void => {
  return loggedInSessionStorage.removeItem(key);
};

export const clearAllItemsFromLoggedInSessionStorage = (): void => {
  return loggedInSessionStorage.clearStorage();
};
