import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutopayReview' })((theme) => ({
  root: {},
  title: theme.typography.h2,
  payInfoSection: {
    backgroundColor: theme.palette.grey[50],
    padding: '12px 0',
  },
  legalText: {
    [theme.breakpoints.down('md')]: {
      ...theme.typography.body1,
      border: `1px solid ${theme.palette.grey[600]}`,
      borderRadius: 4,
      overflowY: 'scroll',
      height: 120,
      padding: '15px 10px',
      backgroundColor: theme.palette.grey[50],
    },
  },
  signText: theme.typography.body1,
  buttonGroup: {
    justifyContent: 'left',
  },
  backButton: {
    [theme.breakpoints.down('md')]: {
      minWidth: 80,
    },
  },
  submitButtton: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  groupLabelStack: { alignItems: 'center', paddingBottom: 0 },
  iconTitle: {
    ...theme.typography.body1,
    alignContent: 'center',
    color: theme.palette.text.tertiary,
  },
  image: {
    maxHeight: 50,
  },
  imageWithPadding: {
    maxHeight: 50,
    [theme.breakpoints.up('md')]: {
      paddingRight: 25,
    },
  },
}));
