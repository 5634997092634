import type { BreakpointsOverrides } from './breakpoints.types';

export const breakpoints: BreakpointsOverrides = {
  values: {
    xs: 0,
    sm: 375,
    md: 768, // ipad/tablet portrait
    lg: 1024, // ipad/tablet landscape
    xl: 1366, // desktop
  },
};
