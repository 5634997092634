import { cloneElement, useCallback, useState } from 'react';

import { Collapse, List, ListItem, Stack } from '@mui/material';

import { IconUIExpandMore } from '@ecp/themes/base';

import { NavLink } from '../NavLink';
import { useStyles } from './MobileNavItem.styles';
import type { MenuItemConfig } from './useMenuItems';

interface Props {
  activeSubItem?: MenuItemConfig | undefined;
  activeItem?: MenuItemConfig | undefined;
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  item: MenuItemConfig;
  onClick?: () => unknown;
}

export const MobileNavItem: React.FC<Props> = (props) => {
  const { classes, cx } = useStyles(undefined, { props });
  const { activeItem, activeSubItem, item, onClick } = props;

  const isActive = activeItem?.url === item.url;
  const isExpandable = !!item.items?.length;
  const isActiveSubmenu = isExpandable && isActive;
  const [subnavOpen, setSubnavOpen] = useState(isActiveSubmenu);

  const toggleSubnav = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setSubnavOpen((existing) => !existing);
  }, []);

  const submenuItems = isExpandable && (
    <Collapse in={subnavOpen} className={classes.submenu} unmountOnExit>
      <nav aria-label={`submenu navigation for ${item.title}`}>
        <List disablePadding className={classes.submenuList}>
          {item.items?.map((item, key) => (
            <MobileNavItem
              {...item}
              key={key}
              activeItem={activeSubItem}
              item={item}
              onClick={onClick}
              classes={{
                item: cx(classes.item, activeSubItem?.url === item.url && classes.itemActive),
              }}
            />
          ))}
        </List>
      </nav>
    </Collapse>
  );

  let ariaLabel = item.title;
  if (isExpandable) {
    ariaLabel = `${subnavOpen ? 'Close' : 'Open'} policies subnav`;
  }

  return (
    <ListItem
      key={item.url}
      className={cx(
        classes.root,
        isExpandable && classes.expandable,
        subnavOpen && classes.submenuOpen,
      )}
    >
      <Stack
        direction='row'
        component={NavLink}
        spacing={0.5}
        to={item.url}
        className={cx(classes.item, isActive && classes.itemActive)}
        onClick={isExpandable ? toggleSubnav : onClick}
        aria-current={isActive ? 'page' : undefined}
        aria-expanded={subnavOpen}
        aria-label={ariaLabel}
        trackingName={item.trackingName}
        trackingLabel={item.trackingLabel}
        trackingDimensions={item.trackingDimensions}
      >
        <Stack spacing={0.5} direction='row' alignItems='center'>
          {item.icon && cloneElement(item.icon, { className: classes.icon })}
          {item.title}
        </Stack>
        {isExpandable && (
          <Stack alignItems='center'>
            {subnavOpen ? (
              <IconUIExpandMore className={classes.expandIcon} />
            ) : (
              <IconUIExpandMore className={cx(classes.expandIcon, classes.expandIconMore)} />
            )}
          </Stack>
        )}
      </Stack>
      {submenuItems}
    </ListItem>
  );
};
