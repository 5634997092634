import { emptyObject, isEqual, isMatch } from '@ecp/utils/common';

import type { Location } from './types';
import { matcherWithCache } from './wouter';

type LocationMatcher = (
  l1: Omit<Location, 'parameters'>,
  l2: Omit<Location, 'parameters'>,
) => boolean;

// eslint-disable-next-line eqeqeq
const arePathnamesEqual: LocationMatcher = (l1, l2) => l1.pathname == l2.pathname;
// eslint-disable-next-line eqeqeq
const areHashesEqual: LocationMatcher = (l1, l2) => l1.hash == l2.hash;
const areSearchParamsEqual: LocationMatcher = (l1, l2) => isEqual(l1.search, l2.search);
const areSearchParamsMatching: LocationMatcher = (l1, l2) => isMatch(l1.search, l2.search);

/** Performs a deep comparison between two locations to determine if location1 contains equivalent key-values from location2 (if location1 is a subset of location2). */
export const areLocationsMatching: LocationMatcher = (l1, l2) =>
  arePathnamesEqual(l1, l2) && areHashesEqual(l1, l2) && areSearchParamsMatching(l1, l2);

/** Performs a deep comparison between two locations to determine if location1 exactly equals location2. */
export const areLocationsEqual: LocationMatcher = (l1, l2) =>
  arePathnamesEqual(l1, l2) && areHashesEqual(l1, l2) && areSearchParamsEqual(l1, l2);

/** Returns pathname parameters object based on path pattern (it's what we usually use as RouteProps.path). */
export const matcher = (pattern: string, path: string): Record<string, string | undefined> => {
  const [match, params] = matcherWithCache(pattern, path);

  return match ? params : (emptyObject as ReturnType<typeof matcher>);
};
