import { useLayoutEffect, useRef } from 'react';

// Replace with react official version of useInsertionEffect upon v18 migration
const useInsertionEffect = useLayoutEffect;

/**
 * Userland polyfill while we wait for the forthcoming
 * https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
 *
 * NOTE: "A high-fidelity polyfill for useEvent is not possible because
 * there is no lifecycle or Hook in React that we can use to switch
 * .current at the right timing."
 * So we will have to make do with this "close enough" approach for now.
 * Alternative implementation: https://github.com/Volune/use-event-callback/blob/master/src/index.ts
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useEvent = <T extends (...args: any[]) => any>(fn: T): T => {
  const ref: [T, T] = useRef<[T, T]>([fn, ((...args) => ref[0](...args)) as T]).current;
  // Per Dan Abramov: useInsertionEffect executes marginally closer to the
  // correct timing for ref synchronization than useLayoutEffect on React 18.
  // See: https://github.com/facebook/react/pull/25881#issuecomment-1356244360
  useInsertionEffect(() => {
    ref[0] = fn;
  });

  return ref[1];
};
