import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'Card' })(() => ({
  root: {
    borderRadius: 12.5,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  body: {},
  content: {
    padding: '10px 15px 10px 15px',
    marginBottom: 'auto',
  },
  horizontalDivider: {
    margin: '0 20px',
  },
  headerDivider: {
    paddingTop: 10,
  },
  footer: {
    padding: '5px 15px 5px 8px',
  },
}));
