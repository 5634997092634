import { useEffect, useRef } from 'react';

import { Dialog, Stack } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { Button } from '@ecp/components';
import { LoadingOverlay } from '@ecp/features/servicing/shared/components';
import {
  IconMaterialDesignNavigationArrowBackIos,
  IconMaterialDesignNavigationClose,
} from '@ecp/themes/base';

import { useStyles } from './FullScreenModal.styles';
export interface FullScreenModalProps {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  open?: boolean;
  title: string;
  isBackButton?: boolean;
  showLoadingOverlay?: boolean;
  onClickBack?: () => void;
  onCloseModal: () => void;
}

export const FullScreenModal: React.FC<React.PropsWithChildren<FullScreenModalProps>> = (props) => {
  const {
    open = true,
    title,
    children,
    isBackButton,
    showLoadingOverlay = false,
    onClickBack,
    onCloseModal,
  } = props;
  const { classes, cx } = useStyles(undefined, { props });
  const ref = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    let initialized = false;

    // it takes some time for the ref to get attached, so using setInterval to wait for it
    const initialize = setInterval(() => {
      if (open && !initialized && ref.current) {
        ref.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        // sets focus automatically so it can be scrolled with keyboard
        ref.current.focus();
        initialized = true;
      }
    }, 100);

    return () => clearInterval(initialize);
  }, [open, pathname]);

  const header = (
    <Stack direction='row' className={cx(classes.content, classes.headerContent)}>
      <Stack direction='row' spacing={2.5}>
        {isBackButton && !!onClickBack && (
          <Button
            className={classes.button}
            variant='iconText'
            aria-label='Back'
            onClick={onClickBack}
            data-testid='backArowModal'
            trackingName='modal_backarrow_link' // TODO update trackingName and trackingLabel if needed !!
            trackingLabel='modal_continue'
          >
            <IconMaterialDesignNavigationArrowBackIos className={classes.icon} />
          </Button>
        )}
        <h3 className={classes.headerTitle} id='fullscreen-modal-title'>
          {title}
        </h3>
      </Stack>
      <Button
        className={classes.button}
        variant='iconText'
        aria-label='Close'
        onClick={onCloseModal}
        data-testid='closeButonModal'
        trackingName='modal_x_button'
        trackingLabel='modal_continue'
      >
        <IconMaterialDesignNavigationClose className={classes.icon} />
      </Button>
    </Stack>
  );

  return (
    <Dialog
      open={open}
      fullScreen
      className={classes.root}
      aria-labelledby='fullscreen-modal-title'
      aria-describedby='fullscreen-modal-body'
      PaperProps={{ ref, tabIndex: -1, className: classes.paper }}
      // disableEnforceFocus - the modal will not prevent focus from leaving the modal while open, need for BoldChat to work on top of modal backdrop
      disableEnforceFocus
    >
      <div className={classes.cardHeader}>{header}</div>
      <div className={cx(classes.content, classes.body)} id='fullscreen-modal-body'>
        {children}
      </div>
      {showLoadingOverlay && <LoadingOverlay />}
    </Dialog>
  );
};
