import {
  GraphicAddPersonalPossessionsReplacementCost1024X512BImageUrl,
  GraphicCoverageDefaultImageUrl,
  GraphicHomeCoverageAdditionalLivingExpenseImageUrl,
  GraphicHomeCoverageComputersImageUrl,
  GraphicHomeCoverageDeductibleImageUrl,
  GraphicHomeCoverageDetachedStructureImageUrl,
  GraphicHomeCoverageDwellingImageUrl,
  GraphicHomeCoverageEarthquakeImageUrl,
  GraphicHomeCoverageEquipmentBreakdownImageUrl,
  GraphicHomeCoverageHomeWaterBackupImageUrl,
  GraphicHomeCoverageHurricaneLossImageUrl,
  GraphicHomeCoverageIdentityProtectionImageUrl,
  GraphicHomeCoverageIncreasedDwellingImageUrl,
  GraphicHomeCoverageLossAssessmentImageUrl,
  GraphicHomeCoverageMedicalExpenseImageUrl,
  GraphicHomeCoverageMineSubsidenceImageUrl,
  GraphicHomeCoverageMoldProtectionCoverageImageUrl,
  GraphicHomeCoverageOrdinanceLawImageUrl,
  GraphicHomeCoveragePersonalInjuryImageUrl,
  GraphicHomeCoveragePersonalLiabilityImageUrl,
  GraphicHomeCoveragePersonalPropertyImageUrl,
  GraphicHomeCoveragePoolLiabilityImageUrl,
  GraphicHomeCoverageServiceLinesCoverageImageUrl,
  GraphicHomeCoverageTemporaryHousingImageUrl,
  GraphicHomeCoverageWaterDeductibleImageUrl,
  GraphicHomeCoverageWindHailImageUrl,
  GraphicRentersCoverageAnimalLiabilityImageUrl,
  GraphicRentersCoverageFraudCoverageImageUrl,
  GraphicRentersCoverageRefrigeratedPropertyImageUrl,
  GraphicRentersCoverageSelfStorageImageUrl,
  GraphicRentersCoverageSpecialPossessionsImageUrl,
} from '@ecp/themes/base';
import type { OptionsMetadata } from '@ecp/types';

// metadata for home coverages
export const HomeCoverageMetadata: { [productKey: string]: OptionsMetadata } = {
  'homesite.home': {
    'unknown.coverage': {
      primaryImage: GraphicCoverageDefaultImageUrl,
      title: 'Unknown Coverage',
    },
    'deductibles.deductible': {
      primaryImage: GraphicHomeCoverageDeductibleImageUrl,
      title: 'Standard Deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a covered loss caused by an incident other than hurricane, earthquake, or wind/hail.`,
      secondaryText: `This amount will be deducted from the total payout on your claim, up to your coverage limit. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
      stateOptions: {
        TN: {
          title: `Standard AOP deductible`,
        },
      },
    },
    'coverages.includeEquipmentBreakdown': {
      primaryImage: GraphicHomeCoverageEquipmentBreakdownImageUrl,
      title: 'Equipment Breakdown',
      primaryText: `Equipment Breakdown provides coverage for direct physical loss to covered household appliances caused by the perils of mechanical, electrical, and pressure system breakdown.`,
      secondaryText: `This coverage allows you to be reimbursed for repair or replacement of damaged appliances and/or systems due to mechanical, electrical, or pressure system breakdown.`,
    },
    'coverages.moldPropertyProtection': {
      primaryImage: GraphicHomeCoverageMoldProtectionCoverageImageUrl,
      title: 'Mold Protection Coverage',
      primaryText: `Mold Protection provides coverage for the testing and removal of wet or dry rot, fungi, or bacteria in your home.`,
      secondaryText: `Mold Protection covers the cost to test and remove mold if it exists. It also pays to repair any damage that was caused by the mold-removal process, up to your coverage limit.`,
    },
    'coverages.refrigeratedCoverage': {
      primaryImage: GraphicRentersCoverageRefrigeratedPropertyImageUrl,
      title: 'Refrigerated Property',
      primaryText: `This coverage pays to replace items stored in your refrigerator or freezer caused by loss of power.`,
      secondaryText: `This coverage pays to replace items stored in your refrigerator or freezer unit caused by loss of power to the refrigeration or freezer unit.`,
    },
    'coverages.includeServiceLine': {
      primaryImage: GraphicHomeCoverageServiceLinesCoverageImageUrl,
      title: 'Service Lines Coverage',
      primaryText: `Service Lines provides coverage for repair or replacement of underground piping or wiring that provides electrical power, heating, natural gas, waste disposal, or water to their homes and that are damaged by a covered loss.`,
      secondaryText: `If, for example, a storm uproots a tree on your property damaging the underground power line in the process. Selecting this coverage would allow you to be reimbursed for repair or replacement of the damaged power line.`,
    },
    'deductibles.waterDeductible': {
      primaryImage: GraphicHomeCoverageWaterDeductibleImageUrl,
      title: 'Water Deductible',
      primaryText: `If there has been a paid water loss within the past five years, the policy is subject to a deductible that applies to loss caused by any non-weather events.`,
      secondaryText: `A “paid water loss” includes losses sustained by the insured and/or their spouse/domestic partner at the insured location for which a payment was previously issued by the Company or was reported as having been issued by one of the insured’s prior insurance carriers.`,
    },
    'deductibles.windStorm': {
      primaryImage: GraphicHomeCoverageWindHailImageUrl,
      title: 'Wind/Hail Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a windstorm or hail.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
    'deductibles.WindstormDed_TX_WindHail': {
      primaryImage: GraphicHomeCoverageWindHailImageUrl,
      title: 'Wind/Hail Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a windstorm or hail.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
    'deductibles.WindstormDed_NJ_WindHail': {
      primaryImage: GraphicHomeCoverageWindHailImageUrl,
      title: 'Wind/Hail Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a windstorm or hail.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
    // Hurricane not copied directly from sales meta data; modified to have a state option for CT; all other states (TX, NY, AL, SC) merged into this object since their content was the same
    'deductibles.hurricane': {
      primaryImage: GraphicHomeCoverageHurricaneLossImageUrl,
      title: 'Hurricane Loss Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a hurricane.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
      stateOptions: {
        CT: {
          primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a hurricane.`,
          secondaryText: `This is applicable to losses due to a hurricane where a "hurricane" warning is issued or declared by the National Hurricane Center of the National Weather Service and ending 24 hours following the termination of the hurricane. Your deductible for a covered loss will be determined by multiplying the dollar amount of your Coverage A - Dwelling Limit of Liability by this percentage.`,
        },
      },
    },
    'deductibles.WindstormDed_AL_WindHail': {
      primaryImage: GraphicHomeCoverageWindHailImageUrl,
      title: 'Wind/Hail Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a windstorm or hail.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
    'deductibles.WindstormDed_SC_WindHail': {
      primaryImage: GraphicHomeCoverageWindHailImageUrl,
      title: 'Wind/Hail Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a windstorm or hail.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
    'coverages.includeWaterBackup': {
      primaryImage: GraphicHomeCoverageHomeWaterBackupImageUrl,
      title: 'Water Back Up Coverage',
      primaryText: `Pays to repair damage caused by a sump pump malfunction or a backup in a sewer pipe or drain that occurred off premises. Losses resulting from a flood are excluded.`,
      secondaryText: `This covers damage which is excluded from the standard homeowners policy.`,
      stateOptions: {
        MD: {
          secondaryText: `With the “policy limits” option, you get the benefit of the full limit of liability applicable to the damaged property in the event of a covered loss.`,
        },
        VA: {
          secondaryText: `With the "policy limits" option, you get the benefit of the full limit of liability applicable to the damaged property in the event of a covered loss.`,
        },
      },
    },
    'coverages.dwelling': {
      primaryImage: GraphicHomeCoverageDwellingImageUrl,
      title: 'Dwelling Coverage',
      primaryText: `This amount is the minimum coverage needed to adequately insure your residence. It is an estimate based on the current cost to rebuild your home, not including the value of the land.`,
      secondaryText: `Covers expenses related to damage or loss resulting from a covered incident including, but not limited to:`,
      listItems: ['Fire or lightning', 'Windstorm or hail', 'Vandalism', 'Glass breakage', 'Smoke'],
    },
    'coverages.extendedReplacementCost': {
      primaryImage: GraphicHomeCoverageIncreasedDwellingImageUrl,
      title: 'Increased Dwelling Coverage',
      primaryText: `Increased Dwelling Coverage gives you the option of adding coverage for your Dwelling.`,
      secondaryText: `Increased Dwelling Protection is important in the event there is a covered loss to your home that exceeds your dwelling limit.`,
    },
    'coverages.additionalDwelling': {
      primaryImage: GraphicHomeCoverageIncreasedDwellingImageUrl,
      title: 'Increased Dwelling Coverage',
      primaryText: `Increased Dwelling Coverage gives you the option of adding coverage for your Dwelling.`,
      secondaryText: `Increased Dwelling Protection is important in the event there is a covered loss to your home that exceeds your dwelling limit.`,
    },
    'coverages.lossAssessment': {
      primaryImage: GraphicHomeCoverageLossAssessmentImageUrl,
      title: `Loss Assessment`,
      primaryText: `Loss Assessment increases the amount of coverage provided to pay for a loss assessment due to a covered Property or Liability loss. Loss assessment is the amount charged to all property owners by an association or condominium complex.`,
    },
    'coverages.otherStructures': {
      primaryImage: GraphicHomeCoverageDetachedStructureImageUrl,
      title: 'Detached Structures',
      primaryText: `Detached or Other Structures may provide coverage for additional structures on your property, not attached to the main dwelling, such as garages, pools, storage sheds, and more.`,
      secondaryText: `Damage caused to an external structure can be costly. Detached Structures coverage can reduce your out of pocket expenses if a covered incident damages your property.`,
    },
    'coverages.ordinanceOrLaw': {
      primaryImage: GraphicHomeCoverageOrdinanceLawImageUrl,
      title: `Ordinance Or Law`,
      primaryText: `Raises your base coverage limit included with your policy when new repairs and rebuilds require an additional cost to be brought up to present-day, local building codes.`,
    },
    'coverages.increasedCoverageC': {
      primaryImage: GraphicHomeCoveragePersonalPropertyImageUrl,
      title: 'Personal Property',
      primaryText: `Personal Property or Possessions coverage covers your belongings - like clothing, TVs, appliances - if they're stolen or damaged in the event of a covered loss.`,
      secondaryText: `Your policy includes replacement costs for personal property. This means that if your personal property is stolen or damaged, you’ll be paid enough to replace your items with versions of like, kind and quality. This coverage applies whether or not your possessions are located in your home at the time of loss.`,
    },
    'coverages.lostOfUse': {
      primaryImage: GraphicHomeCoverageAdditionalLivingExpenseImageUrl,
      title: 'Additional Living Expenses',
      primaryText: `Covers the cost of living expenses, over and above your normal expenses, if you are unable to live in your home due to a covered loss.`,
      secondaryText: `This coverage will pay for items including (but not limited to) a hotel stay, additional food costs, and other expenses that exceed what you normally spend.`,
      stateOptions: { TN: { title: `Temporary Housing Expense` } },
    },
    'coverages.personalLiability': {
      primaryImage: GraphicHomeCoveragePersonalLiabilityImageUrl,
      title: 'Personal Liability',
      primaryText: `This offers protection against property damage or bodily injury to others that you or a member of your household accidentally caused.`,
      secondaryText: `Covers your obligation to pay if you are sued for someone else’s injury or property damage. Applies whether the injury or damage takes place on your property or while you’re away from your home.`,
    },
    'coverages.medicalPaymentsToOthers': {
      primaryImage: GraphicHomeCoverageMedicalExpenseImageUrl,
      title: 'Medical Expense',
      primaryText: `Pays for medical expenses to others or to those injured on the insured property or by an animal owned by or in the care of the insured.`,
      secondaryText: `Medical Expense coverage is designed to help prevent a lawsuit if injuries are not extensive. If a lawsuit is filed, your personal liability coverage would take over.`,
    },
    'coverages.acvRoofCoverage': {
      primaryImage: GraphicHomeCoverageWindHailImageUrl,
      title: 'Wind/Hail Roof Coverage',
      primaryText: `Wind/Hail Roof losses may be adjusted based on Actual Cash Value - the depreciated value of your roof, or Replacement Cost - what it would cost to replace it.`,
      secondaryText:
        'Based on the age of your roof and the state your property is in, you may receive Actual Cash Value or Replacement Cost coverage which helps repair your roof in the event of a covered loss. In most states, Actual Cash Value is required when the age of your roof is 10 years and older.',
    },
    'coverages.includeComputers': {
      primaryImage: GraphicHomeCoverageComputersImageUrl,
      title: 'Covered Losses - Personal Computer',
      primaryText: `This coverage provides reimbursement for damage done to your personal computer, up to your coverage limit.`,
      secondaryText: `Choose Extended Incidents to add more protection for your computer and cover all losses unless specifically excluded.`,
    },
    'coverages.includeEarthquake': {
      primaryImage: GraphicHomeCoverageEarthquakeImageUrl,
      title: 'Earthquake',
      primaryText: `Earthquake protection covers your property for damage caused by an earthquake.`,
      secondaryText: `This covers earthquake-related direct physical loss including land shock waves or tremors before, during, or after volcanic eruptions.`,
      stateOptions: {
        OK: {
          primaryText: `The deductible for earthquake coverage is 5%. The deductible is applied separately to dwelling, other structures and personal property coverage. Coverage will not be covered for instances related to fracking (the process that injects water, sand or chemicals under high pressure into wells).`,
          secondaryText: `Coverage would be excluded for any of the following: extracting of oil/gas from below the earth’s surface by any process of injecting, inserting or storing any substance below the earth’s surface.`,
        },
      },
    },
    'coverages.includeIdentityFraudExpenseCoverage': {
      primaryImage: GraphicHomeCoverageIdentityProtectionImageUrl,
      title: 'Identity Theft Expense Protection',
      primaryText: `Identity Theft Expense Protection provides coverage for costs associated with resolving issues related to a stolen identity.`,
      secondaryText: `This coverage reimburses you for legal fees and time you had to take off work – up to your coverage limit.`,
    },
    'coverages.libel': {
      primaryImage: GraphicHomeCoveragePersonalInjuryImageUrl,
      title: 'Personal Injury',
      primaryText: `Personal Injury coverage pays for loss settlement and legal fees if someone sues you for acts or statements that defame their character.`,
      secondaryText: `Examples include: false arrest, detention, imprisonment, malicious prosecution, libel, slander, defamation of character, invasion of privacy, wrongful eviction, wrongful entry.`,
    },
    'coverages.mineSubsidence': {
      primaryImage: GraphicHomeCoverageMineSubsidenceImageUrl,
      title: 'Mine Subsidence',
      primaryText: `Mine Subsidence provides coverage for damages due to lateral or vertical ground movement resulting from the accidental collapse of man-made, underground mines.`,
      secondaryText: `This does not cover damages due to ground movement from earthquakes, landslides, or volcanic eruptions.`,
    },
    'coverages.personalPropertyPerilsNew': {
      primaryImage: GraphicRentersCoverageSpecialPossessionsImageUrl,
      title: 'Covered Losses - Personal Possessions',
      primaryText: `Protect your possessions with additional coverage for the things that are most important to you.`,
      secondaryText: `Choose Extended Incidents to add more protection for your stuff and cover all losses unless specifically excluded.`,
    },
    'coverages.includeAnimalLiabilityCoverage': {
      primaryImage: GraphicRentersCoverageAnimalLiabilityImageUrl,
      title: 'Animal Liability',
      primaryText:
        'If your dog bites or injures a neighbor, your legal fees, and their medical bills could be covered up to your liability limit.',
      secondaryText:
        'By selecting this coverage, I agree that I don’t have an exotic animal or a pet with a bite history. High-risk Dog Breeds: Akita, Alaskan Malamute, Chow Chow, Doberman, Pit Bull, Rottweiler, Staffordshire Terrier. Exotic Animals: Alligators, Crocodiles, Snakes, Primates, Large Cats, Non-domesticated.',
    },
    'coverages.includePoolLiability': {
      primaryImage: GraphicHomeCoveragePoolLiabilityImageUrl,
      title: 'Pool Liability',
      primaryText:
        'If an incident arises where a guest has an accident in, at, or around your swimming pool, your policy may cover their medical bills and any associated legal fees up to the coverage amount.',
      secondaryText: `You are not eligible if you have a pool with a diving board or slide, an in-ground pool not enclosed by a locked fence at least 4 feet high or screened enclosure, or an above-ground pool not enclosed by a locked fence at least 4 feet high or screened enclosure, unless the ladder is removable and stairs lock in an upright position when not in use.`,
    },
  },
  'amfam-adv.home': {},
  'homesite.renters': {
    'unknown.coverage': {
      primaryImage: GraphicCoverageDefaultImageUrl,
      title: 'Unknown Coverage',
    },
    'coverages.personalProperty': {
      primaryImage: GraphicHomeCoveragePersonalPropertyImageUrl,
      title: 'Personal Possessions',
      primaryText: `Personal Property or Possessions coverage covers your belongings - like clothing, TVs, appliances - if they're stolen or damaged in the event of a covered loss.`,
      secondaryText: `Your policy includes replacement costs for personal property. This means that if your personal property is stolen or damaged, you’ll be paid enough to replace your items with versions of like, kind and quality. This coverage applies whether or not your possessions are located in your home at the time of loss.`,
    },
    'coverages.personalPropertyPerilsNew': {
      primaryImage: GraphicRentersCoverageSpecialPossessionsImageUrl,
      title: 'Special Personal Possessions',
      primaryText: `Your policy covers your personal property from a finite list of “perils” or causes of loss.`,
      secondaryText: `While policy exclusions still apply, adding this endorsement expands what types of losses are covered by eliminating the list of standard perils.`,
    },
    'coverages.replacementCostOnPersonalProperty': {
      primaryImage: GraphicAddPersonalPossessionsReplacementCost1024X512BImageUrl,
      title: 'Personal Possessions Replacement Cost',
      primaryText:
        'Your policy can be endorsed to include replacement costs for personal property.',
      secondaryText:
        'This means that if your personal property is stolen or damaged, you’ll be paid enough to replace your items with versions of like, kind and quality. This coverage applies whether or not your possessions are located in your home at the time of loss.',
    },
    'coverages.lostOfUse': {
      primaryImage: GraphicHomeCoverageTemporaryHousingImageUrl,
      title: 'Temporary Housing Expense',
      primaryText: `Covers the cost of living expenses, over and above your normal expenses, if you are unable to live in your home due to a covered loss.`,
      secondaryText: `This coverage will pay for items including (but not limited to) a hotel stay, additional food costs, and other expenses that exceed what you normally spend.`,
    },
    'coverages.personalLiability': {
      primaryImage: GraphicHomeCoveragePersonalLiabilityImageUrl,
      title: 'Personal Liability',
      primaryText: `This offers protection against property damage or bodily injury to others that you or a member of your household accidentally caused.`,
      secondaryText: `Covers your obligation to pay if you are sued for someone else’s injury or property damage. Applies whether the injury or damage takes place on your property or while you’re away from your home.`,
    },
    'coverages.medicalPaymentsToOthers': {
      primaryImage: GraphicHomeCoverageMedicalExpenseImageUrl,
      title: 'Medical Payments',
      primaryText: `Pays for medical expenses to others or to those injured on the insured property or by an animal owned by or in the care of the insured.`,
      secondaryText: `Medical Expense coverage is designed to help prevent a lawsuit if injuries are not extensive. If a lawsuit is filed, your personal liability coverage would take over.`,
    },
    'coverages.includeComputers': {
      primaryImage: GraphicHomeCoverageComputersImageUrl,
      title: 'Computers',
      primaryText: `This coverage provides reimbursement for damage done to your personal computer, up to your coverage limit.`,
      secondaryText: `Choose Extended Incidents to add more protection for your computer and cover all losses unless specifically excluded.`,
    },
    'coverages.includeEarthquake': {
      primaryImage: GraphicHomeCoverageEarthquakeImageUrl,
      title: 'Earthquake',
      primaryText: `Earthquake protection covers your property for damage caused by an earthquake.`,
      secondaryText: `This covers earthquake-related direct physical loss including land shock waves or tremors before, during, or after volcanic eruptions.`,
      stateOptions: {
        OK: {
          primaryText: `The deductible for earthquake coverage is 5%. The deductible is applied separately to dwelling, other structures and personal property coverage. Coverage will not be covered for instances related to fracking (the process that injects water, sand or chemicals under high pressure into wells).`,
          secondaryText: `Coverage would be excluded for any of the following: extracting of oil/gas from below the earth’s surface by any process of injecting, inserting or storing any substance below the earth’s surface.`,
        },
      },
    },
    'coverages.includeIdentityFraudExpenseCoverage': {
      primaryImage: GraphicHomeCoverageIdentityProtectionImageUrl,
      title: 'Identity Theft',
      primaryText: `Identity Theft Expense Protection provides coverage for costs associated with resolving issues related to a stolen identity.`,
      secondaryText: `This coverage reimburses you for legal fees and time you had to take off work – up to your coverage limit.`,
    },
    'coverages.refrigeratedCoverage': {
      primaryImage: GraphicRentersCoverageRefrigeratedPropertyImageUrl,
      title: 'Refrigerated Property',
      primaryText: `This coverage pays to replace items stored in your refrigerator or freezer caused by loss of power.`,
      secondaryText: `This coverage pays to replace items stored in your refrigerator or freezer unit caused by loss of power to the refrigeration or freezer unit.`,
    },
    'coverages.includeWaterBackup': {
      primaryImage: GraphicHomeCoverageHomeWaterBackupImageUrl,
      title: 'Water Backup / Sump Overflow',
      primaryText: `Pays to repair damage caused by a sump pump malfunction or a backup in a sewer pipe or drain that occurred off premises. Losses resulting from a flood are excluded.`,
      secondaryText: `This covers damage which is excluded from the standard renters policy.`,
    },
    'deductibles.deductible': {
      primaryImage: GraphicHomeCoverageDeductibleImageUrl,
      title: 'Standard Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a covered loss caused by an incident other than hurricane, earthquake, or wind/hail.`,
      secondaryText: `This amount will be deducted from the total payout on your claim, up to your coverage limit. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
    'coverages.moldPropertyProtection': {
      primaryImage: GraphicHomeCoverageMoldProtectionCoverageImageUrl,
      title: 'Mold Protection',
      primaryText: `Provides coverage for the testing and removal of wet or dry rot, fungi, or bacteria in your home.`,
      secondaryText: `Let’s say you suspect your home is infested with mold. Mold Protection covers the cost to test and remove mold if it exists. It also pays to repair any damage that was caused by the mold-removal process, up to your coverage limit.`,
    },
    'coverages.personalPropertySelfStorageCoverage': {
      primaryImage: GraphicRentersCoverageSelfStorageImageUrl,
      title: 'Increased Personal Possessions at Self-Storage Facility',
      primaryText: `It is important you have your possessions covered when it is in storage. This increase is calculated as a percentage of your 'Personal possessions' coverage limit.`,
      secondaryText:
        'This endorsement can help make sure you have the right amount of coverage for all of your possessions.',
    },
    'coverages.electronicPaymentsForgeryCoverage': {
      primaryImage: GraphicRentersCoverageFraudCoverageImageUrl,
      title: 'Increased Credit EFT Access Card Counterfeit Money',
      primaryText:
        'Your policy provides some coverage due to theft or unauthorized use of a credit card, bank card or the acceptance of counterfeit money.',
      secondaryText:
        'Fraud like this can be costly, by adding this endorsement you can increase the amount of coverage for these kinds of losses.',
    },
  },
  'amfam-adv.renters': {},
};
