import { useCallback, useMemo, useState } from 'react';

import { useQueries, useQuery, useQueryClient } from '@tanstack/react-query';

import { isTruthy } from '@ecp/utils/common';
import { getFileUrl } from '@ecp/utils/web';

import { getEOIDocument } from '../agentSupport/api';
import { usePoliciesByPolicyNumber } from '../policy';
import type { ServicingRequestError, ServicingResponse } from '../servicingRequest';
import { getBillingDocument, getPolicyDocument, getPolicyEOIDocument } from './api';
import { getDocLoadingHtml } from './documentLoadingHtml';
import { getDocErrorHtml } from './loadingErrorHtml';
import {
  getBillingDocumentKey,
  getBillingDocumentsKey,
  getBillingDocumentsQuery,
} from './state.billing';
import {
  getPolicyDocumentKey,
  getPolicyDocumentsKey,
  getPolicyDocumentsQuery,
} from './state.policy';
import type {
  DocumentClass,
  DocumentData,
  DocumentDownload,
  Documents,
  DownloadDoc,
  GetDocumentsRequests,
  GetDocumentsResponse,
  UseRequestDocumentsRequest,
} from './types';

const openDocumentWindow = (docTitle: string): Window => {
  const docWindow = window.open('', docTitle) as Window;
  docWindow?.document.write(getDocLoadingHtml(docTitle));

  return docWindow;
};

const loadDocInWindow = (data = '', docWindow: Window, error: boolean): void => {
  if (error) {
    while (docWindow.document.firstChild) {
      docWindow.document.removeChild(docWindow.document.firstChild);
    }
    docWindow.document.open();
    docWindow.document.write(getDocErrorHtml());

    return;
  }
  const url = getFileUrl(data);
  docWindow.location.replace(url);
};

const getDocumentByType = async (
  documentClass?: string,
  documentId?: string,
  policyNumber?: string, // This is the policy number pulled from metadata for other documents
  policyNumberEOI?: string, // This is the policy number for generating EOI
  isAgent?: boolean,
): Promise<ServicingResponse<DocumentData>> => {
  if (isAgent && policyNumberEOI) {
    return getEOIDocument({ policyNumber: policyNumberEOI });
  } else if (documentId === '') {
    return getPolicyEOIDocument({ requestNumber: policyNumberEOI ?? '' });
  } else if (documentClass === 'Billing') {
    return getBillingDocument({ documentId, requestNumber: policyNumber ?? '' });
  } else {
    return getPolicyDocument({ documentId, requestNumber: policyNumber ?? '' });
  }
};

export const useDownloadDocument = (downloadDoc: DownloadDoc): DocumentDownload => {
  const { policyDoc, policyNumber: policyNumberEOI, isAgent = false } = downloadDoc;
  const [enabled, setEnabled] = useState(false);
  const policyNumber = policyDoc?.metaData.find((meta) => meta.name === 'Policy Number')?.value;

  const queryClient = useQueryClient();
  const documentId = policyDoc?.documentId ?? '';
  const docTitle = `${policyDoc?.documentTitle ?? 'doc'}.pdf`;
  const queryKey =
    documentId && policyDoc?.documentClass === 'Billing'
      ? getBillingDocumentKey(policyNumber, documentId)
      : getPolicyDocumentKey(policyNumberEOI ?? policyNumber, documentId);

  const { isLoading, isError } = useQuery({
    queryKey,
    queryFn: async () => {
      const docWindow = openDocumentWindow(docTitle);
      if (!policyNumberEOI && !policyNumber) throw new Error('no policyNumber found for doc');

      const { payload } = await getDocumentByType(
        policyDoc?.documentClass,
        documentId,
        policyNumber,
        policyNumberEOI,
        isAgent,
      ).catch((e) => {
        setEnabled(false); // needed to trigger new api called when re-enabled
        loadDocInWindow(undefined, docWindow, true);
        throw e;
      });

      loadDocInWindow(payload.data, docWindow, false);

      return payload;
    },
    enabled,
    throwOnError: false,
  });
  const downloadDocument = useCallback(() => {
    if (!policyDoc?.documentId && !policyNumberEOI) {
      throw new Error('cannot download document without proper policy document');
    }

    if (policyNumberEOI) {
      // ensure we always get fresh EOI
      queryClient.removeQueries({ queryKey });
    }

    const data = queryClient.getQueryData<DocumentData>(queryKey);
    if (!data) {
      // it might be already enabled, so we want to ensure it rerenders to trigger react query to refetch
      setEnabled(false);
      setEnabled(true);

      return;
    }

    const docWindow = openDocumentWindow(docTitle);
    loadDocInWindow(data?.data, docWindow, false);
  }, [docTitle, policyDoc, policyNumberEOI, queryClient, queryKey]);

  return {
    downloadDocument,
    isError,
    isLoading: isLoading && enabled,
  };
};

const getDocumentsQuery =
  (documentClass: DocumentClass, requestNumber: string) => async (): Promise<Documents> => {
    const expectedCodes = [403008] as const;
    const payload = await (documentClass === 'Billing'
      ? getBillingDocumentsQuery(requestNumber, {
          dontLogErrorsForErrorCode: expectedCodes,
        })
      : getPolicyDocumentsQuery(requestNumber, {
          dontLogErrorsForErrorCode: expectedCodes,
        })
    )
      .then((response) => response)
      .catch((error: ServicingRequestError) => {
        const errorCode = error.errorStack?.status.messages[0]
          .code as (typeof expectedCodes)[number];
        if (errorCode === 403008) {
          return [] as Documents;
        }
        throw error;
      });

    return payload;
  };

export const useRequestDocuments = (request: UseRequestDocumentsRequest): GetDocumentsResponse => {
  const { bypassPolicyFilter, documentClass, policyNumber } = request;

  return useRequestsDocuments(documentClass, { policyNumbers: [policyNumber], bypassPolicyFilter });
};

export const useRequestsDocuments = (
  documentClass: DocumentClass,
  request: GetDocumentsRequests,
): GetDocumentsResponse => {
  const { bypassPolicyFilter, policyNumbers } = request;

  const {
    policies,
    isLoading: isLoadingPolicies,
    isError: isErrorPolicies,
  } = usePoliciesByPolicyNumber({ policyNumbers }, { throwOnError: false });
  const requestNumbers = !bypassPolicyFilter
    ? policies
        .filter((policyData) => !policyData.isInactive)
        .map((policyData) =>
          documentClass === 'Billing'
            ? policyData.policy.billingAccountNumber
            : policyData.policy.policyNumber,
        )
    : policyNumbers;

  const queries = (requestNumbers ?? []).map((requestNumber) => ({
    queryKey:
      documentClass === 'Billing'
        ? getBillingDocumentsKey(requestNumber)
        : getPolicyDocumentsKey(requestNumber),
    queryFn: getDocumentsQuery(documentClass, requestNumber),
    throwOnError: false,
  }));

  const results = useQueries({ queries });

  const documents: Documents = useMemo(() => {
    const resultsWithData = results.map((result) => result.data?.documentDataList).filter(isTruthy);
    if (!resultsWithData.length) {
      return {};
    }

    return {
      documentDataList: resultsWithData.reduce((previous, docs) => {
        return [...previous, ...docs];
      }, []),
    };
  }, [results]);

  return {
    documents,
    isLoading:
      (!bypassPolicyFilter && isLoadingPolicies) || results.some((result) => result.isLoading),
    isError: (!bypassPolicyFilter && isErrorPolicies) || results.some((result) => result.isError),
  };
};

export const useBillingPolicyDocuments = (request: GetDocumentsRequests): GetDocumentsResponse => {
  const {
    isLoading: isLoadingPolicyDocs,
    documents: policyDocs,
    isError: isPolicyError,
  } = useRequestsDocuments('Policy', request);

  const {
    isLoading: isLoadingBillingDocs,
    documents: billingDocs,
    isError: isBillingError,
  } = useRequestsDocuments('Billing', request);

  const documents: Documents = useMemo(() => {
    if (!billingDocs.documentDataList && !policyDocs.documentDataList) {
      return {};
    }

    return {
      documentDataList: [
        ...(billingDocs.documentDataList || []),
        ...(policyDocs.documentDataList || []),
      ],
    };
  }, [billingDocs.documentDataList, policyDocs.documentDataList]);

  return {
    documents,
    isLoading: isLoadingPolicyDocs || isLoadingBillingDocs,
    isError: isBillingError || isPolicyError,
  };
};
