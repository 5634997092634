import { Stack } from '@mui/material';

import { DisclosureWrapper } from '../../../components';

export const ClaimsTextingHomesite: React.FC = () => {
  return (
    <DisclosureWrapper
      title='Homesite Group Incorporated'
      breadcrumbTitleOverrides={{ terms: 'Terms & Conditions', claimstexting: 'Claims Texting' }}
    >
      <Stack spacing={4}>
        <Stack spacing={1}>
          <h2>Claims Texting Terms and Conditions</h2>
          <h3>GENERAL INFORMATION</h3>
          <p>
            Homesite Group Incorporated, including its subsidiaries and affiliates, (collectively,
            "Homesite") would like to welcome you to our text messaging program for claims services
            performed by AFICS, Inc. (AFICS). Hi Marley is a third-party vendor that will provide
            you with information regarding your claim via text messaging. This has no impact on your
            policy or coverage as it may relate to a claim.
          </p>
          <p>
            Hi Marley does not have a separate charge for this service, but{' '}
            <strong>message and data rates may apply</strong> from your mobile carrier. By providing
            your consent to participate in this program, the account holder approves any such
            charges from their mobile carrier. You may contact us at any time to change these
            preferences.
          </p>
          <p>
            If you have provided us with your mobile number, we have your consent to send you
            automated texts to service your claim. Your consent allows us to use text messaging for
            information and claim servicing. You are not required to consent to the agreement as a
            condition of purchasing any property, goods or services.
          </p>
        </Stack>
        <Stack spacing={1}>
          <h3>HOW TO OPT-IN</h3>
          <p>
            You may sign up for the Hi Marley text messaging program by responding 'Yes' to the
            welcome message that will be sent from your claims adjuster.
          </p>
          <p>
            By opting-in to this service, the number of text messages received and sent may vary
            depending on account activity and your participation in this text messaging program.
          </p>
          <p>
            After signing up, you will receive an invitation text message from Hi Marley asking you
            to confirm your enrollment request. The message states: "Hi &#123;FirstName&#125;, I'm
            Marley, your virtual assistant on behalf of Homesite. To text with your claim
            representative, please review and agree to the terms/privacy policy (himarley.com/tp) by
            respond 'Yes.' Msg and data rates may apply. Msg freq varies. Reply HELP for help or
            STOP to opt out."
          </p>
        </Stack>
        <Stack spacing={1}>
          <h3>HOW TO OPT-OUT</h3>
          <p>
            To stop receiving text messages reply <strong>STOP</strong>. You can also notify your
            claims adjuster directly, and they will remove you from texting. You may receive a
            confirmation of your request.
          </p>
          <p>
            <strong>
              By enrolling in this subscription program, you consent that following such a request
              to unsubscribe,
            </strong>{' '}
            you may receive a standard rate message stating: "First Name, you successfully
            unsubscribed from Marley Texting. If you change your mind, simply text 'YES' to
            reactivate at any time."
          </p>
          <p>
            We reserve the right to terminate this text messaging service, in whole or in part, at
            any time without notice. The information in any text message may be subject to certain
            time lags and/or delays.
          </p>
        </Stack>
        <Stack spacing={1}>
          <h3>SUPPORTED CARRIERS</h3>
          <p>Hi Marley will support all mobile carriers based on their ability to support SMS.</p>
        </Stack>
        <Stack spacing={1}>
          <h3>INTERRUPTION</h3>
          <p>
            Delivery of information and content to your equipment/mobile device may fail due to a
            variety of circumstances or conditions. The Hi Marley program is subject to transmission
            limitation or interruption. You understand and acknowledge that mobile network services
            are outside of Homesite for AFICS's control, and they are not responsible or liable for
            issues arising therefrom, or the failure thereof, including, without limitation,
            technical, hardware, software, electronic, network, telephone or other communications
            malfunctions, errors or failures of any kind, errors in transmission, traffic
            congestion, lost or unavailable network connections, telephone connections, wireless
            phone connections, website, Internet, or ISP availability, unauthorized human
            intervention, traffic congestion, incomplete or inaccurate capture of entry information
            (regardless of cause) or failed, incomplete, garbled, jumbled or delayed transmissions
            which may limit or restrict your ability to receive or send a message, including any
            injury or damage to your or any other person's equipment/wireless device relating to or
            resulting from participating in or using the Hi Marley program. If the Hi Marley program
            is not available within your intended location, you agree that your sole remedy is to
            cease using the Hi Marley program.
          </p>
        </Stack>
        <Stack spacing={1}>
          <h3>REVISIONS</h3>
          <p>
            Homesite may revise, modify, or amend these Terms & Conditions at any time. Any such
            revision, modification, or amendment shall take effect when it is posted to Homesite's
            website. You agree to review these Terms & Conditions periodically to ensure that you
            are aware of any changes. Your continued consent to receive text messages will indicate
            your acceptance of those changes.
          </p>
        </Stack>
        <Stack spacing={1}>
          <h3>PRIVACY</h3>
          <p>
            All information collected from users of our sites is subject to our Privacy Policy,
            which is incorporated by reference into this agreement. For more information, visit
            go.homesite.com to review our policy.
          </p>
        </Stack>
        <Stack spacing={1}>
          <h3>APPLICABLE LAW</h3>
          <p>
            The terms of use shall be governed in accordance with the laws of the Commonwealth of
            Massachusetts.
          </p>
        </Stack>
        <Stack spacing={1}>
          <h3>LIMITATION OF LIABILITY</h3>
          <p>
            By agreeing to these Terms and Conditions, you acknowledge and understand that Homesite
            has engaged a third-party vendor to administer the texting program contemplated by these
            Terms and Conditions. Homesite is not responsible for, and has no control over, any
            third-party vendor actions or omissions, intentional or unintentional.
          </p>
        </Stack>
      </Stack>
    </DisclosureWrapper>
  );
};
