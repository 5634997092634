import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'DiscountsBlock' })((theme) => ({
  root: {
    height: 'auto',
    borderRadius: 4,
    background: 'transparent',
    marginBottom: 0,
    boxShadow: 'none',

    '& .MuiCardContent-root:last-child': {
      padding: 0,
    },

    '& > .MuiDivider-fullWidth': {
      margin: '20px 0px',
    },

    '& > div': {
      padding: 0,
    },
  },
  content: {
    padding: 0,
    marginBottom: 'auto',
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  item: {
    width: '33.33%',
    marginRight: 0,
    padding: 0,
    [theme.breakpoints.down('md')]: {
      width: '50%',
      padding: '6px 5px',
    },
  },
  horizontalDivider: {},
  list: { padding: 0 },
  svg: {},
}));
