import { Divider, Grid, Stack } from '@mui/material';

import {
  AutopayButton,
  MakePaymentButton,
  PaymentAlert,
  PaymentStatusDisplay,
} from '@ecp/features/servicing/shared/components';
import type { GetPaymentInfoReturn } from '@ecp/features/servicing/shared/state';
import {
  getClassicBillingAccountStatusInPendingCancel,
  getItemFromLoggedInSessionStorage,
  getPaymentStatus,
  getProductLineFromPolicyResponse,
  isInPendingCancelStatus,
} from '@ecp/features/servicing/shared/state';
import { getBrandData } from '@ecp/features/servicing/shared/util';

import { useStyles } from './PaymentsDueCardBody.styles';

interface PaymentsDueCardBodyProps {
  paymentInfo: GetPaymentInfoReturn;
}

const escrowPaymentInfo = (
  <Stack spacing={2}>
    <Divider />
    <p>
      Your policy is paid through an escrow. You can still make a payment, but you may be required
      to pay the balance in full. Contact your mortgage company with questions.
    </p>
  </Stack>
);

export const PaymentsDueCardBody: React.FC<PaymentsDueCardBodyProps> = (props) => {
  const { paymentInfo } = props;
  const {
    isClassicPolicy: isClassic = false,
    policyNumber = '',
    canMakePayment,
    isEscrow,
    billingAccount: billingInfo,
    policyData,
  } = paymentInfo;
  const { classes } = useStyles();

  const isPendingCancellation = isInPendingCancelStatus({
    billingResponse: billingInfo,
    productLine: getProductLineFromPolicyResponse(policyData),
    policySource: policyData?.policy.sourceSystemName,
    policyData: policyData,
  });
  const isPaymentSubmitted = !!getItemFromLoggedInSessionStorage('payment', policyNumber);
  const customerServicePhone = getBrandData(paymentInfo.policyData).mainCustServPhoneNum;
  const paymentStatus = getPaymentStatus(billingInfo, isPendingCancellation, isPaymentSubmitted);
  const shouldShowAlert =
    (paymentStatus === 'PastDue' || paymentStatus === 'PendingCancellation') && !isPaymentSubmitted;
  const shouldShowEscrowInfo = isEscrow && !isPaymentSubmitted;
  const shouldShowMakePaymentButton = canMakePayment && !isPaymentSubmitted;
  const shouldShowButtons =
    shouldShowMakePaymentButton ||
    paymentInfo.isAutopayEligible ||
    paymentInfo.isManageAutopayEligible;

  const isClassicBillingAccountStatusInPendingCancel =
    getClassicBillingAccountStatusInPendingCancel(policyData, billingInfo);

  return (
    <Stack spacing={2} className={isClassicBillingAccountStatusInPendingCancel ? '' : classes.root}>
      {shouldShowAlert && (
        <PaymentAlert
          isEscrow={isEscrow}
          isPendingCancellation={isPendingCancellation}
          helpNumber={customerServicePhone}
          isClassicBillingAccountStatusInPendingCancel={
            isClassicBillingAccountStatusInPendingCancel
          }
        />
      )}
      {!isClassicBillingAccountStatusInPendingCancel && (
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={isEscrow && isPaymentSubmitted ? 12 : 7}>
              <Stack spacing={1}>
                <PaymentStatusDisplay paymentInfo={paymentInfo} />
                {shouldShowEscrowInfo && (
                  <Stack spacing={2}>
                    <p>Billed to mortgage company</p>
                  </Stack>
                )}
              </Stack>
            </Grid>

            {shouldShowButtons && (
              <Grid item xs={12} lg={5} alignSelf='center'>
                <Grid
                  container
                  spacing={{ lg: !isPaymentSubmitted ? 1 : 0, xs: 1 }}
                  direction={{ xs: 'column', lg: 'row' }}
                >
                  {shouldShowMakePaymentButton && (
                    <Grid
                      item
                      xs={isEscrow || isClassic ? 12 : 6}
                      lg={12}
                      className={classes.buttonWrapper}
                    >
                      <MakePaymentButton
                        policyNumber={policyNumber}
                        classes={{ button: classes.borderedButton }}
                        isClassic={isClassic}
                      />
                    </Grid>
                  )}
                  {(paymentInfo.isAutopayEligible || paymentInfo.isManageAutopayEligible) && (
                    <Grid
                      item
                      xs={isPaymentSubmitted ? 12 : 6}
                      lg={12}
                      className={classes.buttonWrapper}
                    >
                      <AutopayButton
                        policyNumber={policyNumber}
                        className={classes.borderedButton}
                        isManageFlow={paymentInfo.isManageAutopayEligible}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </div>
      )}
      {shouldShowEscrowInfo && !shouldShowAlert && escrowPaymentInfo}
    </Stack>
  );
};
