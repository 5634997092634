import { useCallback } from 'react';

import { CircularProgress, Stack } from '@mui/material';

import { GoogleAnalyticsLabels, trackClick } from '@ecp/utils/analytics/tracking';
import { Form, useField, useForm } from '@ecp/utils/form';

import { Button, TextField } from '@ecp/components';
import { LoadingButton, PasswordField } from '@ecp/features/servicing/shared/components';
import type { ChangePasswordRequest } from '@ecp/features/servicing/shared/state';
import { useChangePassword, useUser } from '@ecp/features/servicing/shared/state';

import type { PaswordChangeFormInputs } from './PasswordChange.schema';
import { passwordChangeSchema } from './PasswordChange.schema';
import { useStyles } from './PasswordChange.styles';

interface Props {
  onSuccess?(value: string): void;
  onError?(value: string): void;
}

export const PasswordChange: React.FC<Props> = (props) => {
  const { onSuccess, onError } = props;
  const { user, isLoading: isUserLoading } = useUser();
  const { classes } = useStyles();
  const formContext = useForm({
    validations: passwordChangeSchema,
  });
  const { handleSubmit, trigger } = formContext;
  const passwordField = useField({
    formContext,
    name: 'password',
  });
  const newPasswordField = useField({
    formContext,
    name: 'newPassword',
  });
  const confirmPasswordField = useField({
    formContext,
    name: 'confirmNewPassword',
  });

  const { changePassword } = useChangePassword();
  const onSubmit = useCallback(() => {
    handleSubmit(async (data) => {
      const { password, newPassword } = data as Required<PaswordChangeFormInputs>;
      const changePasswordRequest: ChangePasswordRequest = {
        password,
        newPassword,
        userId: user?.userId,
      };
      const { error, success } = await changePassword(changePasswordRequest);
      if (error) {
        trackClick({ action: 'save_password_button', label: error });
        if (error === 'Invalid Credentials')
          formContext.setError('password', {
            message: 'Current password entered is incorrect, please try again.',
          });
        else
          onError?.(
            'We apologize, but we encountered an error while updating your password. Please try again now, or wait a few minutes and try again later.',
          );
      } else if (success) {
        trackClick({ action: 'save_password_button', label: 'Success' });
        onSuccess?.('Successfully updated account password.');
      }
    })();
  }, [onSuccess, onError, handleSubmit, user?.userId, changePassword, formContext]);

  const handleCancelClick = useCallback(async () => {
    onSuccess?.('');
  }, [onSuccess]);

  const handleBlur = useCallback(() => {
    // checks for match if passwordField was edited after confirmPasswordField if filled out
    if (confirmPasswordField.value) {
      trigger('confirmNewPassword');
    }
  }, [trigger, confirmPasswordField]);

  if (isUserLoading) return <CircularProgress aria-label='Loading...' />;

  return (
    <Form
      formProviderProps={formContext}
      onSubmit={onSubmit}
      name='changePasswordForm'
      className={classes.root}
    >
      <Stack spacing={3}>
        <TextField
          type='password'
          {...passwordField}
          label='Current password'
          trackingName='current_password'
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
        />
        <PasswordField
          {...newPasswordField}
          label='New password'
          groupLabel=''
          passwordRequirements={{ showRequirements: true }}
          trackingName='new_password'
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
          onBlur={handleBlur}
        />
        <TextField
          type='password'
          {...confirmPasswordField}
          label='Confirm password'
          trackingName='confirm_password'
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
        />
        <Stack spacing={3} direction='row'>
          <LoadingButton type='submit'>SAVE</LoadingButton>
          <Button
            variant='iconText'
            onClick={handleCancelClick}
            trackingName='cancel_edit_password_link'
            trackingLabel='cancel_edit_password_continue'
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Form>
  );
};
