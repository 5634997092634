import type { QueryStringParams } from './makeUrl';
import { makeUrl } from './makeUrl';

export interface RequestInitExtended extends RequestInit {
  /** An object to be converted into query string params and appended to the URL */
  params?: QueryStringParams;
}

export interface TypedResponse<T> extends Response {
  json<U = T>(): Promise<U>;
}

export function typedFetch<T>(
  url: string,
  init: RequestInitExtended = { params: {} },
): Promise<TypedResponse<T>> {
  const urlWithExtraQueryStringParams = makeUrl({ url, params: init.params });

  return fetch(urlWithExtraQueryStringParams, init);
}
