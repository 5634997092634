import { tooltipClasses } from '@mui/material';

import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'LabelWithTooltip' })((theme) => ({
  root: {},
  groupLabelStack: { alignItems: 'center', paddingBottom: 0 },
  popper: {
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  },
  tooltipWithIconButton: {
    position: 'relative',
    pointerEvents: 'auto', // needed if used inside Mui input label
  },
  tooltipText: { ...theme.typography.body1, color: theme.palette.grey[50], width: 211 },
  icon: {},
}));
