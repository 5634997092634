import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CloseButtonNAgentHelp' })((theme) => ({
  root: {},
  closeButton: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 'fit-content',
    },
  },
}));
