import { useCallback, useState } from 'react';

import { Stack } from '@mui/material';

import { Form, useField, useForm } from '@ecp/utils/form';

import { DatePicker, HelpBox, NumberFormat, TextField } from '@ecp/components';
import {
  AuthenticationCard,
  LabelWithTooltip,
  LoadingButton,
  NavLink,
  PolicyNumberField,
} from '@ecp/features/servicing/shared/components';
import { PAGE_PATH, usePageFlow } from '@ecp/features/servicing/shared/routing';
import { useSharedState, useValidateUser } from '@ecp/features/servicing/shared/state';
import type { ValidateUserRequest } from '@ecp/features/servicing/shared/state';
import { GraphicUIAgentAvatarImageUrl } from '@ecp/themes/base';

import { createAccountFormSchema } from './CreateAccountPage.schema';
import { useStyles } from './CreateAccountPage.styles';
import { helpTextMetadata } from './metadata';

export type CreateAccountProps = {
  recoverEmail?: boolean;
  recoverPassword?: boolean;
};

export const CreateAccountPage: React.FC<CreateAccountProps> = (props) => {
  const { classes } = useStyles();
  const { recoverEmail = false, recoverPassword = false } = props;

  const formContext = useForm({
    validations: createAccountFormSchema,
  });
  const { handleSubmit } = formContext;

  const lastNameField = useField({
    formContext,
    name: 'lastName',
  });
  const birthDateField = useField({
    formContext,
    name: 'dateOfBirth',
  });
  const zipCodeField = useField({ formContext, name: 'zipCode' });
  const policyNumberField = useField({
    formContext,
    name: 'policyNumber',
  });

  const pageFlow = usePageFlow();
  const [sharedState, setSharedState] = useSharedState<Partial<ValidateUserRequest>>(
    pageFlow.currentPage,
  );

  const [attemptsErrorCounter, setAttemptsErrorCounter] = useState(0);

  const title =
    recoverEmail || recoverPassword
      ? "Let's look up your account"
      : 'Our site was recently upgraded to improve your experience';

  const subTitle = 'A new account will be created. Please enter your details to get started.';

  // On 5th incorrect attempt redirect user to ContactUs page - POL-1097
  if (attemptsErrorCounter >= 5) pageFlow.navigate(PAGE_PATH.CONTACTUS);

  const { validateUserAccount } = useValidateUser();

  const handleBack = useCallback(() => {
    setAttemptsErrorCounter(0);

    pageFlow.navigate(PAGE_PATH.LOGIN);
  }, [pageFlow]);

  const onSubmit = useCallback(async () => {
    handleSubmit(async (identityData) => {
      setSharedState({ ...sharedState, ...identityData });
      const { zipCode, lastName, dateOfBirth, policyNumber } = identityData as ValidateUserRequest;
      const validateUserRequest: ValidateUserRequest = {
        zipCode,
        lastName,
        dateOfBirth,
        policyNumber: policyNumber.toUpperCase(),
      };

      const { error, success } = await validateUserAccount(validateUserRequest);
      if (error) {
        if (error === 'USER_NOT_FOUND' || error === 'POLICY_NOT_FOUND')
          setAttemptsErrorCounter((prev) => prev + 1);
      } else if (success) {
        // 'LOCKED_USER' - 401011
        // 'SUSPENDED_USER' - 401013
        // 'USER_NOT_REGISTERED' - 404003 | 404010

        // Recover user flow
        if (recoverEmail || recoverPassword) {
          if (success.accountActive) pageFlow.goForward();
          else {
            if (success.errorCode === 401011)
              pageFlow.navigate(PAGE_PATH.RECOVER_PASSWORD_VERIFY_IDENTITY);
            else if (
              success.errorCode === 401013 ||
              success.errorCode === 404003 ||
              success.errorCode === 404010
            )
              pageFlow.navigate(PAGE_PATH.CONTACTUS);
          }
        }
        // Create user flow
        else {
          if (success.accountActive) pageFlow.navigate(PAGE_PATH.RECOVER_EMAIL_VERIFY_IDENTITY);
          else {
            if (success.errorCode === 401011)
              pageFlow.navigate(PAGE_PATH.RECOVER_PASSWORD_VERIFY_IDENTITY);
            else if (success.errorCode === 401013) pageFlow.navigate(PAGE_PATH.CONTACTUS);
            else if (success.errorCode === 404003) pageFlow.goForward();
          }
        }
      }
    })();
  }, [
    validateUserAccount,
    pageFlow,
    sharedState,
    setSharedState,
    setAttemptsErrorCounter,
    recoverEmail,
    recoverPassword,
    handleSubmit,
  ]);

  let onBack;
  if (!recoverEmail && !recoverPassword) onBack = undefined;
  else if (attemptsErrorCounter < 5 || recoverEmail || recoverPassword) onBack = handleBack;

  const body = (
    <Stack spacing={4}>
      <h1>{title}</h1>
      {!recoverEmail && !recoverPassword && <span className={classes.subtitle}>{subTitle}</span>}
      {attemptsErrorCounter > 0 && (
        <HelpBox
          image={GraphicUIAgentAvatarImageUrl}
          content={
            <div>
              <p className={classes.errorTitle}>Having issues creating an account?</p>
              <p>
                We couldn't find your policy with the details provided. Please{' '}
                <NavLink
                  to={PAGE_PATH.CONTACTUS}
                  className={classes.navlink}
                  trackingName='contact_us_link'
                  trackingLabel='Please contact us for assistance.'
                  objectType='link'
                >
                  contact us
                </NavLink>{' '}
                for assistance.
              </p>
            </div>
          }
        />
      )}
      <Form
        formProviderProps={formContext}
        className={classes.root}
        onSubmit={onSubmit}
        name='identityForm'
      >
        <Stack spacing={4}>
          <Stack spacing={0.5}>
            <PolicyNumberField
              {...policyNumberField}
              label={
                <LabelWithTooltip
                  label='Policy number'
                  title={helpTextMetadata.policyNumber}
                  trackingName='policy_number_hover'
                />
              }
            />
            {!recoverEmail && !recoverPassword && (
              <p className={classes.infoText}>
                If you have multiple policies with us, you may use any of them to set up your
                account.
              </p>
            )}
          </Stack>
          <TextField
            {...lastNameField}
            label={
              <LabelWithTooltip
                label='Last name'
                title={helpTextMetadata.lastName}
                trackingName='last_name_hover'
              />
            }
            role='group'
            autoCapitalize='words'
          />
          <DatePicker
            {...birthDateField}
            label={
              <LabelWithTooltip
                label='Date of birth'
                title={helpTextMetadata.dob}
                trackingName='date_of_birth_hover'
              />
            }
            fullWidth
            hidePicker
            placeholder='MM/DD/YYYY'
            autoComplete='off'
            aria-label='Date of birth in two digit month, two digit day, four digit year. Your input will be formatted after entry.'
          />
          <NumberFormat
            {...zipCodeField}
            label={
              <LabelWithTooltip
                label='Mailing ZIP code'
                title='The ZIP code of the mailing address for the policy.'
                trackingName='zip_code_hover'
              />
            }
            formatType='zipcode'
            // type 'tel' to open numeric keypad on mobile device
            type='tel'
          />
          <LoadingButton
            className={classes.submitButton}
            type='submit'
            trackingLabel='policynumber_name_dob_zip_continue'
            trackingName='policynumber_name_dob_zip_button'
          >
            CONTINUE
          </LoadingButton>
          {!recoverEmail && !recoverPassword && (
            <p>
              Already created your new account?{' '}
              <NavLink
                to={PAGE_PATH.LOGIN}
                className={classes.navlink}
                trackingName='login_link'
                trackingLabel='login_continue'
              >
                Log in
              </NavLink>
            </p>
          )}
        </Stack>
      </Form>
    </Stack>
  );

  return <AuthenticationCard onBack={onBack}>{body}</AuthenticationCard>;
};
