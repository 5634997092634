// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import createCache from '@emotion/cache';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CacheProvider } from '@emotion/react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { TssCacheProvider } from 'tss-react';

import type { Theme } from '../theme/theme.types';

// style order needs to have mui5 first, then ecp5
// order of below nodes determines the order (last created is first)
const head = document.head;

const ecp5Node = document.createElement('style');
ecp5Node.setAttribute('data-emotion-category', 'ecp');
head.insertBefore(ecp5Node, head.firstChild);

const mui5Node = document.createElement('style');
mui5Node.setAttribute('data-emotion-category', 'mui');
head.insertBefore(mui5Node, head.firstChild);

const tssCache = createCache({ key: 'ecp', insertionPoint: ecp5Node });
const muiCache = createCache({ key: 'mui', insertionPoint: mui5Node });

// Get rid of nonsensical `":first-child" is potentially unsafe` warnings
tssCache.compat = true;
muiCache.compat = true;

export const ThemeProvider: React.FC<React.PropsWithChildren<{ theme: Theme }>> = ({
  children,
  theme,
}) => (
  <TssCacheProvider value={tssCache}>
    <CacheProvider value={muiCache}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </CacheProvider>
  </TssCacheProvider>
);
