import { useCallback, useMemo } from 'react';

import { Stack } from '@mui/material';

import { isTruthy } from '@ecp/utils/common';

import { Alert, PhoneLink } from '@ecp/components';
import type { OtherPoliciesEligibleProps } from '@ecp/features/servicing/shared/components';
import {
  CheckIcon,
  LoadingOverlay,
  OtherPoliciesEligible,
  PaymentDetails,
} from '@ecp/features/servicing/shared/components';
import type { UseModalPathReturn } from '@ecp/features/servicing/shared/routing';
import { MODAL_FLOW, MODAL_STEP } from '@ecp/features/servicing/shared/routing';
import {
  useAmountDueNDueDateInfo,
  usePaymentInfoForAllPolicies,
  usePolicy,
  useSharedState,
} from '@ecp/features/servicing/shared/state';
import type { AutopayInformation } from '@ecp/features/servicing/shared/types';
import { getBrandData } from '@ecp/features/servicing/shared/util';

import { useStyles } from '../AutopayConfirmation/AutopayConfirmation.styles';

export const AutopayClassicConfirmation: React.FC<UseModalPathReturn> = (props) => {
  const { currentFlow, currentStep, policyNumber, sharedStateKey, init } = props;
  const { classes } = useStyles();

  const { isLoading: isLoadingAmountDueNDueDateInfo, isFetching: isFetchingAmountDueNDueDateInfo } =
    useAmountDueNDueDateInfo({
      policyNumber,
      modalFlow: currentFlow,
      modalStep: currentStep,
    });

  const { isLoading: isPolicyLoading, policyData: policyResponse } = usePolicy(policyNumber);
  const { paymentInfos, isLoading: isPaymentInfosLoading } = usePaymentInfoForAllPolicies({
    throwOnError: false,
  });
  const [sharedState] = useSharedState<AutopayInformation>(sharedStateKey);

  const isLoading =
    isFetchingAmountDueNDueDateInfo ||
    isPolicyLoading ||
    isPaymentInfosLoading ||
    isLoadingAmountDueNDueDateInfo;

  const otherPolicies = useMemo(
    () =>
      paymentInfos
        ?.filter(
          ({ isAutopayEligible, policyData }) =>
            isAutopayEligible && policyData?.policy.policyNumber !== policyNumber,
        )
        ?.map((policyInfo) => policyInfo?.policyData)
        .filter(isTruthy),
    [paymentInfos, policyNumber],
  );

  const handleOtherClick = useCallback<NonNullable<OtherPoliciesEligibleProps['onClick']>>(
    (policyNumber) => init(policyNumber, MODAL_FLOW.AUTOPAY_ENROLL, MODAL_STEP.MODAL_EDIT),
    [init],
  );
  const customerServicePhone = getBrandData(policyResponse).mainCustServPhoneNum;
  if (isLoading) return <LoadingOverlay />;

  return (
    <div className={classes.root}>
      <Stack spacing={5}>
        <Alert type='info' withIcon>
          <Stack spacing={3}>
            <p>This change can take up to three business days to appear online.</p>
            <p>If you do not see the change reflected after this period, please contact us.</p>
          </Stack>
          <PhoneLink number={customerServicePhone} />.
        </Alert>
        <Stack spacing={3}>
          <CheckIcon />
          <Stack spacing={2}>
            <p className={classes.title}>
              Your request to enroll in AutoPay was successfully submitted!
            </p>

            <PaymentDetails
              billingPlan={sharedState?.paymentFrequency}
              policyResponse={policyResponse}
              paymentAccountMethod={sharedState?.paymentAccountMethod} // TODO: Verify payment method when we integrate with Iframe and set it in the shared state
              isLoading={isLoading}
            />
          </Stack>
        </Stack>
        <OtherPoliciesEligible
          otherPolicies={otherPolicies}
          onClick={handleOtherClick}
          title='Other policies that can be enrolled in Autopay'
          buttonText='Enroll in Autopay'
          showDivider
        />
      </Stack>
    </div>
  );
};
