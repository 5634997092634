import { isTruthy } from '@ecp/utils/common';

import type { Address } from '@ecp/features/servicing/shared/types';

export const getAddressString = (address: Address | undefined): string | undefined => {
  if (!address) return undefined;
  const addressString = [
    address.line1.trim(),
    address.line2?.trim(),
    address.city.trim(),
    [address.state.trim(), address.zip.trim()].filter(isTruthy).join(' '),
  ]
    .filter(isTruthy)
    .join(', ');

  return addressString;
};
