/**
 * This should be shown if loading a document is failed, and shown in the same new tab after spinner.
 *
 * This essentially emulates error <Alert/> message.
 */
export const getDocErrorHtml = (): string => {
  const messageBoxStyle = `
    background-color: #f9edf0;
    border: 2px solid #a10025;
    border-radius: 3px;
    display: flex;
    align-items: center;
    padding: 10px;
    margin-top: 15%;
  `;

  const messageStyle = `
    font-size: 14px;
    font-weight: 400;
    font-family: Open Sans,Roboto;
  `;

  const closeButtonStyle = `
    background-color: transparent;
    cursor: pointer;
    border: none;
  `;

  const iconStyle = `
    height: 24px;
    min-width: 24px;
    `;
  const icon = `<svg style='${iconStyle}' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.032 15.634H10.968C10.638 15.634 10.372 15.367 10.372 15.038C10.372 14.709 10.638 14.443 10.968 14.443H11.38V11.704H10.968C10.638 11.704 10.372 11.437 10.372 11.109C10.372 10.78 10.638 10.512 10.968 10.512H11.975C12.304 10.512 12.571 10.78 12.571 11.109V14.443H13.032C13.36 14.443 13.628 14.709 13.628 15.038C13.628 15.367 13.36 15.634 13.032 15.634ZM11.809 8.211C12.218 8.211 12.549 8.542 12.549 8.951C12.549 9.36 12.218 9.691 11.809 9.691C11.401 9.691 11.07 9.36 11.07 8.951C11.07 8.542 11.401 8.211 11.809 8.211ZM12 5C8.134 5 5 8.134 5 12C5 15.866 8.134 19 12 19C15.866 19 19 15.866 19 12C19 8.134 15.866 5 12 5Z" fill="#a10025"></path></svg>`;
  const closeIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="ecp-9lwpei-Alert-closeIcon ecp-24-Alert-closeIcon-ref"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.04 12L18.577 7.463C19.141 6.899 19.141 5.985 18.577 5.423C18.014 4.859 17.101 4.859 16.537 5.423L12 9.96L7.463 5.423C6.899 4.859 5.986 4.859 5.423 5.423C4.859 5.985 4.859 6.899 5.423 7.463L9.96 12L5.423 16.537C4.859 17.101 4.859 18.015 5.423 18.577C5.704 18.859 6.073 19 6.442 19C6.811 19 7.181 18.859 7.463 18.577L12 14.04L16.537 18.577C16.819 18.859 17.188 19 17.558 19C17.927 19 18.296 18.859 18.577 18.577C19.141 18.015 19.141 17.101 18.577 16.537L14.04 12Z" fill="#2D5EBB"></path></svg>`;

  return `<html/>
            <head>
              <meta name="viewport" content="width=device-width, initial-scale=1">
            </head>
            <title>Loading error</title>
            <style></style>
            <body>
              <div style='display: flex; justify-content: center; align-items: flex-start; height: 100%'>
                <div style='${messageBoxStyle}'>
                  ${icon}
                  <div style='${messageStyle}'>
                    We're sorry. We are unable to access the document at this time. Please contact us or try again later.
                  </div>
                  <button style='${closeButtonStyle}' type="button" onclick="window.close();">
                    ${closeIcon}
                  </button>
                </div>
                </span>
              </div>
            </body>
          </html>`;
};
