import { executeCommand } from './util/executeCommand';

/**
 * Opens the Messenger programatically.
 * This function does no-op when chat is not enabled.
 * @see https://developer.genesys.cloud/commdigital/digital/webmessaging/messengersdk/SDKCommandsEvents/messengerPlugin#messenger-open
 */
export const openMessenger = (): Promise<unknown> => {
  return executeCommand('Messenger.open');
};
