import type { PartialDeep } from 'type-fest';

import type { CommandOptions } from '../../types';
import { executeCommand } from './executeCommand';

/**
 * Attributes that can be set for Genesys chat
 */
export type CustomAttributes = {
  /**
   * Trace id of the analytics call when the user starts a chat conversation
   */
  actionTraceId?: string;
  /**
   * ECP Interaction id (not the Genesys interaction or conversation id).
   * Important for analytics to track all conversations made in a session.
   */
  afeInteractionId?: string;
  /** (geo) state + page + product. Set if available. */
  customerInfo?: string;
  /** DAL session id (Liftoff). Set if available. */
  customerReference?: string;
  /** Set if available. */
  firstName?: string;
  /** Set if available. */
  lastName?: string;
  /**
   * Required parameter for the chat to function.
   * @see {@link file://./../../../README.md} `Configuration Values` section for more information.
   */
  queueName?: string;
  /** Policy numbers from user response. */
  quoteOrPolicyNumber?: string;
};

/**
 * An in-memory key-value store in Genesys chat SDK.
 */
export interface Database {
  messaging: {
    customAttributes: CustomAttributes;
  };
}
/**
 * A Genesys "Database.set" wrapper.
 * New values are shallow merged with the existing attributes in the in-memory database.
 * This function does no-op when chat is not enabled.
 *
 * @see https://developer.genesys.cloud/commdigital/digital/webmessaging/messengersdk/SDKCommandsEvents/databasePlugin#database-set
 *
 * @returns full database object stored in the in-memory database.
 */
export const setDatabase = async (database: Partial<Database>): Promise<Database> => {
  return await executeCommand('Database.set', database as unknown as CommandOptions);
};

/**
 * A Genesys "Database.update" wrapper.
 * New values are deep merged with the existing attributes in the in-memory database.
 * This function does no-op when chat is not enabled.
 *
 * @see https://developer.genesys.cloud/commdigital/digital/webmessaging/messengersdk/SDKCommandsEvents/databasePlugin#database-update
 *
 * @returns full database object stored in the in-memory database.
 */
export const updateDatabase = async (database: PartialDeep<Database>): Promise<Database> => {
  return await executeCommand('Database.update', database as unknown as CommandOptions);
};

/**
 * A Genesys "Database.get" wrapper.
 * This function does no-op when chat is not enabled.
 * @see https://developer.genesys.cloud/commdigital/digital/webmessaging/messengersdk/SDKCommandsEvents/databasePlugin#database-get
 *
 * @returns full database object stored in the in-memory database.
 */
export const getDatabase = async (): Promise<Database> => {
  return await executeCommand('Database.get');
};
