import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutopayEnrolledPolicyCard' })((theme) => ({
  root: {},
  link: {
    ...theme.typography.button2,
    textDecoration: 'none',
    textAlign: 'left',
    width: 'fit-content',
  },
  unenrollText: {
    ...theme.typography.body1,
    color: theme.palette.text.tertiary,
  },
  cardFooter: {
    paddingTop: 14,
  },
  alert: {
    paddingTop: 12,
  },
  body: { paddingTop: 16 },
}));
