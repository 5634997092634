import { noop } from '@ecp/utils/common';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class QueueItem<T = any> {
  public done: Promise<T>;

  public key?: string;

  public work: () => Promise<T>;

  public res: (value: T) => void;

  public rej: (error: string) => void;

  public constructor({ work, key }: { work: () => Promise<T>; key?: string }) {
    this.key = key;
    this.work = work;

    // Typescript says that res and rej are not definitely initalized,
    // but Promise's specification reads:
    // | The executor function is executed immediately by
    // | the Promise implementation, passing resolve and reject functions
    // | (the executor is called before the Promise constructor even
    // | returns the created object)
    // To make typescript happy, we set these dummy values which will
    // never be needed
    this.res = noop;
    this.rej = noop;
    this.done = new Promise<T>((res, rej) => {
      this.res = res;
      this.rej = rej;
    });
  }

  public doWork(): Promise<void> {
    return this.work().then(this.res, this.rej);
  }
}

export const rejectMessage = 'no problem, rejected by duplicate';

export const rejectDuplicates = <T>(queue: QueueItem<T>[], key: string): QueueItem<T>[] => {
  return queue.reduce((result: QueueItem<T>[], item) => {
    if (item.key !== key) {
      result.push(item);
    } else {
      item.rej(rejectMessage);
    }

    return result;
  }, []);
};

// ensures work placed here happens in order
// a work is a function that returns a promise
// e.g.
// const q = new Queue();
// const a = q.add(() => fetch('/a'));
// const b = q.add(() => fetch('/b')); // will wait for 'a' to finish
// console.log(`A = ${await a} B = ${await b}`);
export class Queue {
  public active?: QueueItem;

  public queue: QueueItem[] = [];

  public async add<T>({ work, key }: { work: () => Promise<T>; key?: string }): Promise<T> {
    const q = new QueueItem({ work, key });

    if (key) {
      this.rejectDuplicates(key);
    }

    this.queue.push(q);

    if (!this.active) {
      this.processQueue();
    }

    return q.done;
  }

  // This is the implementation we should be using, but IE11
  // causes us to use the implementation below because the
  // polyfill for promises causes this to be an infinite loop.
  // There is no context switch when the await keyword is used.
  // This is effectively what we're doing with the timeout below.
  /*
  // returns when queue has emptied
  async empty() {
    // eslint-disable-next-line no-await-in-loop
    while (this.active && await this.active.done);
  }
  */

  // returns when queue has emptied
  public async empty(): Promise<void> {
    if (!this.active) return;

    await new Promise<void>((res) => {
      setTimeout(async () => {
        if (!this.active) {
          res();

          return;
        }
        await this.active.done;
        await this.empty();
        res();
      }, 0);
    });
  }

  private processQueue(): void {
    this.active = this.queue.shift();
    if (!this.active) return;

    this.active.doWork().then(
      () => this.processQueue(),
      () => this.processQueue(),
    );
  }

  // reject any queue items immediately that are equal to key
  private rejectDuplicates(key: string): void {
    this.queue = rejectDuplicates(this.queue, key);
  }
}
