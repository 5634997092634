import { createTheme as createMuiTheme } from '@mui/material';

type CreateThemeParams = Parameters<typeof createMuiTheme>;

type CreateTheme = <T extends CreateThemeParams[1]>(
  options: CreateThemeParams[0],
  extra?: T,
) => ReturnType<typeof createMuiTheme> & (T extends CreateThemeParams[1] ? T : undefined);

/**
 * Correctly typed MUI createTheme function.
 *
 * Any custom props in `breakpoints`, `mixins`, `palette`, `spacing`, `typography`
 * from options we're passing to the original createTheme function are removed
 * from the resulting theme if we pass only one options argument.
 * But the function accepts additional arguments which it deeply merges with the resulting theme.
 * So we get what we want. The only downside is absence of type inference.
 * This infers extra argument type and adds it to the result.
 */
export const createTheme: CreateTheme = createMuiTheme as CreateTheme;
