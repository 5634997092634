import { useCallback, useMemo, useState } from 'react';

import { Stack } from '@mui/material';

import { maskEmail } from '@ecp/utils/common';
import { DateConstants, formatDate } from '@ecp/utils/date';
import { Form, useField, useForm } from '@ecp/utils/form';

import type { RadioGroupOption } from '@ecp/components';
import { RadioGroupWithOptions } from '@ecp/components';
import {
  AddressDisplay,
  LoadingButton,
  LoadingOverlay,
  ModalError,
} from '@ecp/features/servicing/shared/components';
import type { UseModalPathReturn as PaperlessUnenrollEditProps } from '@ecp/features/servicing/shared/routing';
import type { ErrorResponse } from '@ecp/features/servicing/shared/state';
import {
  getMailingAddress,
  getPersonByRefId,
  getPolicyDisplayType,
  usePolicy,
  useUpdatePaperless,
} from '@ecp/features/servicing/shared/state';

import { paperlessUnenrollEditSchema } from './PaperlessUnenrollEdit.schema';
import { useStyles } from './PaperlessUnenrollEdit.styles';

export const PaperlessUnenrollEdit: React.FC<PaperlessUnenrollEditProps> = (props) => {
  const { goForward, policyNumber, reset } = props;

  if (!policyNumber) {
    throw new Error('Cannot call preferences api without policyNumber');
  }

  const { classes } = useStyles();
  const { isLoading: isLoadingPolicy, policyData } = usePolicy(policyNumber);
  const policyType = getPolicyDisplayType(policyData);
  const { updatePaperless, isLoading: isLoadingUpdatePaperless } = useUpdatePaperless(
    policyNumber,
    true,
  );
  const pni = getPersonByRefId(policyData, policyData?.policy.primaryInsuredRefId);
  const [submitError, setSubmitError] = useState<ErrorResponse>();
  const isLoading = isLoadingUpdatePaperless || isLoadingPolicy;

  const formContext = useForm({
    validations: paperlessUnenrollEditSchema,
  });
  const { handleSubmit } = formContext;

  const deliveryOptionField = useField({
    formContext,
    name: 'deliveryOption',
  });

  const pageLoadTime = useMemo(() => new Date().toISOString(), []);

  const onSubmit = useCallback(() => {
    handleSubmit(async (data) => {
      if (data.deliveryOption === 'EMAIL') {
        // no change (continue enrolled in paperless), close modal
        reset();

        return;
      }

      const acceptDateAndTime = new Date();
      const { success, error } = await updatePaperless({
        preferenceOption: 'PAPER',
        requestedDateTime: pageLoadTime,
        whenAccepted: formatDate(acceptDateAndTime.toString(), DateConstants.ANSWERS_FORMAT),
        acknowledgement: 'Mailing address option selected',
        consumerStep: 'Unenroll in Paperless - Change document delivery settings',
      });

      if (success) {
        goForward();
      } else if (error) {
        setSubmitError(error);
      }
    })();
  }, [goForward, reset, handleSubmit, updatePaperless, pageLoadTime]);

  const options: RadioGroupOption[] = useMemo(
    () => [
      {
        value: 'EMAIL',
        label: (
          <p>
            I would like to access my documents digitally. Notify me at{' '}
            <b>{maskEmail(pni?.email)}</b> when new documents are available.
          </p>
        ),
      },
      {
        value: 'PAPER',
        label: (
          <>
            <p>I would like my documents mailed to</p>
            <div>
              <AddressDisplay
                policyAddress={getMailingAddress(policyData)}
                classes={{ text: classes.destinationValue }}
              />
            </div>
          </>
        ),
      },
    ],
    [classes.destinationValue, pni?.email, policyData],
  );
  if (submitError) {
    const errorDesc = (
      <span>
        unenrolling your{' '}
        <b>
          {policyType} policy {policyNumber}
        </b>{' '}
        from paperless delivery
      </span>
    );

    return (
      <ModalError
        errorTitle='unenroll your policy from paperless delivery'
        errorDescription={errorDesc}
      />
    );
  }

  return isLoading ? (
    <LoadingOverlay />
  ) : (
    <Stack spacing={3} classes={classes.root}>
      <h2 id='paperless-unenroll-edit-title'>How would you like to receive documents?</h2>
      <Form onSubmit={onSubmit} formProviderProps={formContext}>
        <Stack spacing={3}>
          <RadioGroupWithOptions
            {...deliveryOptionField}
            options={options}
            variant='radioCard'
            aria-labelledby='paperless-unenroll-edit-title'
          />
          <LoadingButton type='submit' variant='primary' className={classes.button}>
            Save
          </LoadingButton>
        </Stack>
      </Form>
    </Stack>
  );
};
