import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'FaqSection' })((theme) => ({
  root: {},
  title: { ...theme.typography.body1Bold, verticalAlign: 'super' },
  accordion: {
    '&::before': {
      display: 'block',
    },
  },
}));
