import { Stack } from '@mui/material';

import { NavLink } from '@ecp/features/servicing/shared/components';
import { generateHelpPath, PAGE_PATH } from '@ecp/features/servicing/shared/routing';

export const DisclosureLinksHomesite: React.FC = () => {
  return (
    <Stack spacing={1}>
      <NavLink
        to={generateHelpPath(PAGE_PATH.DISCLOSURES_LEGALNOTICES, 'homesite')}
        trackingName='legal_notices_link'
        trackingLabel='legal_notices'
      >
        Legal Notices
      </NavLink>
      <NavLink
        to={generateHelpPath(PAGE_PATH.DISCLOSURES_UNDERWRITING, 'homesite')}
        trackingName='underwriting_companies_link'
        trackingLabel='underwriting_companies'
      >
        Underwriting Companies
      </NavLink>
      <NavLink
        to={generateHelpPath(PAGE_PATH.DISCLOSURES_TERMSANDCONDITIONS, 'homesite')}
        trackingName='terms_and_conditions_link'
        trackingLabel='terms_and_conditions'
      >
        Terms and Conditions
      </NavLink>
      <NavLink
        to={generateHelpPath(PAGE_PATH.DISCLOSURES_PRIVACYPOLICY, 'homesite')}
        trackingName='privacy_policy_link'
        trackingLabel='privacy_policy'
      >
        Privacy Policy
      </NavLink>
    </Stack>
  );
};
