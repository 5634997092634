import type { PolicySource, ProductLine } from '@ecp/features/servicing/shared/types';

export const isLegacyAutoPolicy = (
  productLine?: ProductLine,
  policySource?: PolicySource,
): boolean => productLine === 'AUTO' && policySource === 'CAHP_Connect_Phoenix';

export const isLegacyHomePolicy = (
  productLine?: ProductLine,
  policySource?: PolicySource,
): boolean => productLine === 'HOME' && policySource === 'CAHP_Connect_Phoenix';

export const isLegacyPolicy = (
  productLine: ProductLine | undefined,
  policySource: PolicySource | undefined,
): boolean =>
  isLegacyAutoPolicy(productLine, policySource) || isLegacyHomePolicy(productLine, policySource);
