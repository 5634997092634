import { useCallback } from 'react';

import { Grid, Stack } from '@mui/material';

import { Form, useField, useForm } from '@ecp/utils/form';

import { Button, NumberFormat, Select, TextField } from '@ecp/components';
import { Card, LoadingButton } from '@ecp/features/servicing/shared/components';
import { stateOptions } from '@ecp/features/servicing/shared/util';

import { useStyles } from './AdditionalInterestForm.styles';
import type { AdditionalInterestInputs } from './additionalInterestSchema.schema';
import { additionalInterestSchema } from './additionalInterestSchema.schema';

export interface AdditionalInterestFormProps {
  onSubmitAction: (data: AdditionalInterestInputs) => void;
  onReset: () => void;
  formName: string;
  isSubmitProcessing: boolean;
  defaultValues?: AdditionalInterestInputs;
  contentBefore?: React.ReactElement;
}

export const AdditionalInterestForm: React.FC<AdditionalInterestFormProps> = (props) => {
  const { contentBefore, defaultValues, formName, onSubmitAction, onReset, isSubmitProcessing } =
    props;
  const defaultState = defaultValues?.state ?? '';

  const { classes } = useStyles();

  const formContext = useForm({
    validations: additionalInterestSchema,
  });
  const { handleSubmit } = formContext;

  const nameField = useField({ name: 'name', formContext, defaultValue: defaultValues?.name });
  const emailField = useField({
    name: 'emailAddress',
    formContext,
    defaultValue: defaultValues?.emailAddress,
  });
  const addressLine1Field = useField({
    name: 'address1',
    formContext,
    defaultValue: defaultValues?.address1,
  });
  const addressLine2Field = useField({
    name: 'address2',
    formContext,
    defaultValue: defaultValues?.address2,
  });
  const cityField = useField({ name: 'city', formContext, defaultValue: defaultValues?.city });
  const stateField = useField({ name: 'state', formContext, defaultValue: defaultState });
  const zipCodeField = useField({
    name: 'zipCode',
    formContext,
    defaultValue: defaultValues?.zipCode,
  });

  const onSubmit = useCallback(() => {
    handleSubmit(async (data) => {
      onSubmitAction(data);
    })();
  }, [handleSubmit, onSubmitAction]);

  return (
    <Form
      onSubmit={onSubmit}
      formProviderProps={formContext}
      name={formName}
      className={classes.root}
    >
      <Stack spacing={3}>
        <Card
          body={
            <Stack spacing={4}>
              {contentBefore}
              <TextField
                {...nameField}
                className={classes.formField}
                label='Landlord or company name'
              />
              <TextField {...emailField} className={classes.formField} label='Email (optional)' />
              <TextField
                {...addressLine1Field}
                className={classes.formField}
                label='Address line 1'
              />
              <TextField
                {...addressLine2Field}
                className={classes.formField}
                label='Address line 2 (optional)'
              />
              <TextField {...cityField} className={classes.formField} label='City' />
              <div className={classes.formField}>
                <Grid container spacing={2}>
                  <Grid xs={12} md={6} item>
                    <Select
                      id={stateField.name}
                      {...stateField}
                      options={stateOptions}
                      label='State'
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <NumberFormat {...zipCodeField} label='ZIP code' type='tel' format='#####' />
                  </Grid>
                </Grid>
              </div>
            </Stack>
          }
        />
        <Stack direction={{ xs: 'column', md: 'row' }}>
          <LoadingButton
            variant='primary'
            type='submit'
            className={classes.button}
            trackingLabel='save_landlord'
            trackingName='save_landlord_button'
            isProcessing={isSubmitProcessing}
          >
            SAVE LANDLORD
          </LoadingButton>
          <Button
            variant='iconTextMedium'
            onClick={onReset}
            trackingName='add_landlord_cancel_link'
            trackingLabel='cancel'
            objectType='link'
            className={classes.cancelButton}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Form>
  );
};
