import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'MobileMenuItem' })((theme) => ({
  root: { width: '100%' },
  arrowRight: theme.mixins.setColorSvg(theme.palette.primary.main),
  button: {
    backgroundColor: theme.palette.grey[50],
    borderRadius: 4,
    display: 'block',
    padding: 16,
    width: '100%',
  },
  title: theme.typography.body1Bold,
}));
