import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import type { FallbackProps } from 'react-error-boundary';
import { ErrorBoundary } from 'react-error-boundary';

import { useInteractionId } from '@ecp/utils/analytics/interaction-id';
import { userAuth } from '@ecp/utils/auth';

import { ServicingRequestError, useUser } from '@ecp/features/servicing/shared/state';
import { IconUIExpandMore } from '@ecp/themes/base';

import { useStyles } from './DevDiagnostic.styles';

const Row: React.FC<React.PropsWithChildren<{ label: string }>> = ({ children, label }) => (
  <p>
    <strong>{label}: </strong>
    {typeof children === 'object' ? JSON.stringify(children) : String(children)}
  </p>
);

const Fallback: React.FC<FallbackProps> = ({ error }) => (
  <p>
    DevDiagnostics module failed to load. This indicates an error in the{' '}
    <strong>DevDiagnostic</strong> component. <br />
    <br />
    <Row label='Error message'>{error.message}</Row>
  </p>
);

// split out into own component to properly invoke ErrorBoundary if something were to error out
const ErrorMessage: React.FC<Pick<FallbackProps, 'error'>> = ({ error }) => {
  const { user } = useUser({ ignoreUserAuth: true, throwOnError: false });
  const interactionId = useInteractionId().get();

  const baseErrorMsg = (
    <div>
      <Row label='message'>{error.message}</Row>
      {!!user?.email && <Row label='user.email'>{user?.email}</Row>}
      <Row label='user.isAuth'>{userAuth.isAuth}</Row>
      {!!userAuth.userId && <Row label='user.userId'>{userAuth.userId}</Row>}
      <Row label='interactionId'>{interactionId}</Row>
    </div>
  );

  return error instanceof ServicingRequestError ? (
    <div>
      {baseErrorMsg}
      <Row label='requestId'>{error.requestId}</Row>
      <Row label='requestUrl'>{error.requestUrl}</Row>
      {/* @ts-ignore Casey to check */}
      <Row label='errorStack'>{error.errorStack}</Row>
    </div>
  ) : (
    baseErrorMsg
  );
};

export const DevDiagnostic: React.FC<Pick<FallbackProps, 'error'>> = (props) => {
  const { classes } = useStyles();

  return (
    <Accordion className={classes.root}>
      <AccordionSummary expandIcon={<IconUIExpandMore className={classes.expandIcon} />}>
        <p>Diagnostics:</p>
      </AccordionSummary>
      <AccordionDetails>
        <ErrorBoundary FallbackComponent={Fallback}>
          <ErrorMessage {...props} />
        </ErrorBoundary>
      </AccordionDetails>
    </Accordion>
  );
};
