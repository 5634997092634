import { DateConstants, formatDate, isOlderThan } from '@ecp/utils/date';

import type { Alert } from '@ecp/components';
import { PhoneLink } from '@ecp/components';
import type { BookRolledPolicy } from '@ecp/features/servicing/shared/state';
import { getPolicyDisplayType } from '@ecp/features/servicing/shared/state';
import type { UiPolicy } from '@ecp/features/servicing/shared/types';
import { getBrandData } from '@ecp/features/servicing/shared/util';

import type { useStyles } from './PolicyIconSummaryCard.styles';
interface Props {
  classes: Partial<ReturnType<typeof useStyles>['classes']>;
  convertedFromClassic: BookRolledPolicy | undefined;
  policyDetails: UiPolicy | undefined;
  showInactiveAlert: boolean;
}

export const getAlertProps = (props: Props): React.ComponentProps<typeof Alert>[] => {
  const { classes, convertedFromClassic, policyDetails, showInactiveAlert } = props;
  const customerServicePhone = getBrandData(policyDetails).mainCustServPhoneNum;
  const policyType = getPolicyDisplayType(policyDetails);

  const alertProps: React.ComponentProps<typeof Alert>[] = [];

  if (showInactiveAlert) {
    alertProps.push({
      children: (
        <p>
          <span className={classes.inactiveAlert}>This policy is inactive.</span> For help, or to
          explore your options, call <PhoneLink number={customerServicePhone} />.
        </p>
      ),
      type: 'error',
      withIcon: true,
    });
  }
  if (policyDetails?.isInBookRoll && !policyDetails?.isInactive) {
    alertProps.push({
      children: (
        <p>
          Certain changes to your policy cannot be made right now. For help, call{' '}
          <PhoneLink number={customerServicePhone} />.
        </p>
      ),
      type: 'warning',
      withIcon: true,
    });
  }

  if (convertedFromClassic) {
    if (
      policyDetails?.policy.status === 'In Force' &&
      !isOlderThan(30, 'day', policyDetails?.policy.periodStartDate)
    )
      alertProps.push({
        children: (
          <p>
            This is your new policy. It has replaced your previous {policyType} policy{' '}
            {convertedFromClassic.replaces}.
          </p>
        ),
        type: 'info',
        withIcon: true,
        withAction: true,
        hideOnClose: true,
      });
    if (policyDetails?.policy.status === 'Scheduled')
      alertProps.push({
        children: (
          <p>
            Effective{' '}
            {formatDate(
              convertedFromClassic.policyData.policy.periodStartDate,
              DateConstants.MONTH_FULL_NAME_LONG_FORMAT,
            )}
            , this will be your new policy.
          </p>
        ),
        type: 'info',
        withIcon: true,
        withAction: true,
        hideOnClose: true,
      });
  }

  return alertProps;
};
