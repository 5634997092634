import { Divider, Stack } from '@mui/material';

import { getPolicyDisplayType } from '@ecp/features/servicing/shared/state';
import type { UiPolicy } from '@ecp/features/servicing/shared/types';

import { OtherPolicyLink } from '../OtherPolicyLink';
import { PolicyCard } from '../PolicyCard';
import { useStyles } from './OtherPoliciesEligible.styles';

export interface OtherPoliciesEligibleProps {
  otherPolicies?: UiPolicy[];
  showDivider?: boolean;
  title?: string;
  isLinkClassic?: boolean;
  buttonText?: string;
  onClick?: (policyNumber: string) => void;
}

export const OtherPoliciesEligible: React.FC<OtherPoliciesEligibleProps> = (props) => {
  const {
    otherPolicies = [],
    showDivider = false,
    title,
    isLinkClassic = false,
    buttonText,
    onClick,
  } = props;
  const { classes } = useStyles();

  return otherPolicies !== undefined && otherPolicies?.length > 0 ? (
    <Stack spacing={5} className={classes.root}>
      {showDivider && <Divider />}
      <Stack spacing={1.5}>
        {title && <h3>{title}</h3>}
        {otherPolicies?.map((otherPolicy, index) => {
          return isLinkClassic ? (
            <OtherPolicyLink
              key={index}
              actionOnClick={onClick}
              linkText={`Pay ${getPolicyDisplayType(otherPolicy)}`}
              policyNumber={otherPolicy.policy.policyNumber}
            />
          ) : (
            <PolicyCard
              policyDetails={otherPolicy}
              buttonText={buttonText}
              onClick={onClick}
              body={<div />}
              key={index}
            />
          );
        })}
      </Stack>
    </Stack>
  ) : null;
};
