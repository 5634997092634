// @ts-nocheck -spinner file
import { env } from '@ecp/env';
import { LogoMoonshotAbbrvImageUrl, LogoPrimaryAbbrvImageUrl } from '@ecp/themes/base';

import { useStyles } from './LogoSpinner.styles';

/** to follow a similar format and to reuse the styles of <!-- Loading spinner --> in index.html, the styles for this component are in apps/sales/agent/src/index */

export const LogoSpinner: React.FC<{
  loaderPositionStyle?: 'absolute' | 'fixed';
  preparingText?: 'quote' | 'session';
  logo?: React.ReactElement;
}> = ({ loaderPositionStyle = 'fixed', preparingText, logo }) => {
  const { classes } = useStyles({ loaderPositionStyle });

  const getSpinnerText = (preparingText): string => {
    switch (preparingText) {
      case 'quote':
        return 'Preparing your quote';
      case 'session':
        return 'Preparing session...';
    }
  };

  return (
    <div
      id='loader'
      className={classes.loader}
      aria-live='polite'
      role='status'
      aria-label='loading'
    >
      <span className='c' />
      <span className='d' />
      <span className='e' />
      <span className='f' />
      <span className='g' />
      <span className='h' />
      <span className='i' />
      <span className='j' />
      <span className='k' />
      <span className='l' />
      <span className='m' />
      <span className='n' />
      {logo || (
        <img
          src={env.static.isAgent ? LogoMoonshotAbbrvImageUrl : LogoPrimaryAbbrvImageUrl}
          alt='app logo'
        />
      )}
      {preparingText && <p className={classes.spinnerText}>{getSpinnerText(preparingText)}</p>}
    </div>
  );
};
