import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'LegalTerms' })((theme) => ({
  root: {
    ...theme.typography.body1,
    width: '100%',
  },
  links: {
    ...theme.typography.body1Underline,
    textAlign: 'left',
  },
  text: {},
}));
