/* eslint-disable no-underscore-dangle */

export class StorageError extends Error {
  public constructor(message?: string) {
    super(message);

    // Set the prototype explicitly to work properly with es5 - https://github.com/Microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
    Object.setPrototypeOf(this, StorageError.prototype);

    this.name = 'StorageError';
  }
}

/**
 * Storage helper class.
 *
 * Implements utility methods for objects with same public interface as Web Storage API.
 * Pass an instance of the Storage class to the constructor.
 *
 * Namespacing is designed for cases when several apps are running within the same domain.
 * So one app won't override another app's keys-values in Storage instance.
 * Pass namespace to the constructor.
 */
export class PersistentStorage {
  /**
   * Reference to Storage instance.
   *
   * @private
   */
  private _storage: Storage;

  /**
   * Namespace to avoid Storage keys clashes. Will be conditionally prepended to the Storage keys.
   *
   * @private
   * @default ''
   */
  private _namespace = '';

  /**
   * @param storage - Reference to Storage instance.
   * @param namespace - Namespace to avoid Storage keys clashes.
   */
  constructor(storage: Storage, namespace?: string) {
    this._storage = storage;
    if (namespace) this._namespace = `${namespace}/`;
  }

  /**
   * Serializes value into a string.
   *
   * @private
   * @param val - Value to serialize.
   */
  private serialize = (val: unknown): string => JSON.stringify(val);

  /**
   * Converts from JSON to an object or a primitive value and returns it.
   *
   * @private
   * @param val - Value to deserialize.
   */
  private deserialize = (
    val: string,
  ): Record<string, unknown> | unknown[] | string | number | boolean | null => JSON.parse(val);

  /**
   * Delayed namespace initialization. Use it if you don't know the namespace at the time of instantiation.
   *
   * @param namespace - Namespace to avoid Storage keys clashes.
   */
  setNamespace = (namespace?: string): void => {
    if (namespace) this._namespace = `${namespace}/`;
    else this._namespace = '';
  };

  /**
   * Sets the value of the pair identified by key to value, creating a new key/value pair if none existed for key previously.
   * Namespaces key if `global` flag is omitted and key doesn't start with namespace.
   *
   * @param key - Key.
   * @param val - Value. Should be serializable and follow these rules https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#description
   * @param isGlobal - Flag whether set the key as global without namespacing or as non-global with namespacing.
   */
  setItem = (key: string, val: unknown, isGlobal = false): void => {
    const v = this.serialize(val);
    if (!isGlobal && !key.startsWith(this._namespace)) key = `${this._namespace}${key}`;
    this._storage.setItem(key, v as string);
  };

  /**
   * Returns the current value associated with the given key, or null if the given key does not exist.
   * Gets the value by namespaced key, if `isGlobal` flag is omitted (by default). Otherwise gets the value by key without namespacing.
   *
   * @public
   * @param key - Key. For the simplicity pass non-namespaced key as namespace will be automatically added.
   * @param isGlobal - Flag whether get the key as global without namespacing or as non-global with namespacing.
   */
  getItem = (key: string, isGlobal = false): ReturnType<typeof this.deserialize> => {
    const retrieveByKey = isGlobal ? key : `${this._namespace}${key}`;
    const val = this._storage.getItem(retrieveByKey);
    if (val === null || val === undefined) return null;

    return this.deserialize(val);
  };

  /**
   * Removes the key/value pairs with the given keys from the Storage.
   * Removes the items by namespaced key, if `isGlobal` flag is omitted (by default). Otherwise removes the items by keys without namespacing.
   *
   * @param key - Key. For the simplicity pass non-namespaced keys as namespace will be automatically added.
   * @param isGlobal - Flag whether get the key as global without namespacing or as non-global with namespacing.
   */
  removeItem = (key: string, isGlobal = false): void => {
    const removeByKey = isGlobal ? key : `${this._namespace}${key}`;
    this._storage.removeItem(removeByKey);
  };

  /**
   * Removes all key/value pairs from the Storage.
   * Removes only namespaced items, if `isGlobal` flag is omitted (by default). Otherwise removes all items.
   *
   * @param isGlobal - Flag whether to remove all or only namespaced key/value pairs.
   */
  clearStorage = (isGlobal = false): void =>
    isGlobal
      ? Object.keys(this._storage).forEach((key) => this._storage.removeItem(key))
      : Object.keys(this._storage).forEach(
          (key) => key.startsWith(this._namespace) && this._storage.removeItem(key),
        );
}

/**
 * `window.sessionStorage` wrapped with PersistentStorage helper class.
 * This is for app-wide usage, import it in all modules that depend on Web Storage API.
 * No need to instantiate PersistentStorage yourself and re-export your instance or pass it around.
 *
 * Notes:
 * - If you want to use namespace, make sure to call `setNamespace` before you call any methods on this instance.
 * - If your app needs multiple namespaces at the same time, you can use this one globally and create your own instances as you need.
 */
export const sessionStorage = new PersistentStorage(window.sessionStorage);
/**
 * `window.localStorage` wrapped with PersistentStorage helper class.
 * This is for app-wide usage, import it in all modules that depend on Web Storage API.
 * No need to instantiate PersistentStorage yourself and re-export your instance or pass it around.
 *
 * Notes:
 * - If you want to use namespace, make sure to call `setNamespace` before you call any methods on this instance.
 * - If your app needs multiple namespaces at the same time, you can use this one globally and create your own instances as you need.
 */
export const localStorage = new PersistentStorage(window.localStorage);
