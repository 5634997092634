import * as interactionId from '@ecp/utils/analytics/interaction-id';
import { azureAuth } from '@ecp/utils/auth';
import { Headers } from '@ecp/utils/network';

import { env } from '@ecp/env';

export const apiRoot = env.agentToolingApiRoot;

export const buildRequestHeaders = async ({
  withUserToken = false,
  partnerId,
}: {
  withUserToken?: boolean;
  partnerId?: string;
}): Promise<HeadersInit> => {
  const userTokens = withUserToken && (await azureAuth.authResult);

  return {
    'Content-Type': 'application/json',
    [Headers.API_KEY]: env.agentToolingAfeApiKey,
    ...(env.static.expId && { [Headers.EXP_ID]: env.static.expId }),
    ...(partnerId && { [Headers.PARTNER_ID]: partnerId }),
    [Headers.SESSION_ID]: interactionId.get(),
    [Headers.SOURCE_ID]: env.static.sourceId,
    ...(userTokens && {
      [Headers.USER_TOKEN]: userTokens.idToken,
      Authorization: `Bearer ${userTokens.accessToken}`,
    }),
  };
};
