import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CheckIcon' })((theme) => ({
  root: {
    alignSelf: 'center',
  },
  circle: {
    borderRadius: '50%',
    backgroundColor: `${theme.palette.success.main}3F`,
    height: 42,
    width: 42,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    ...theme.mixins.setColorSvg(theme.palette.success.main),
    height: 30,
    width: 30,
  },
}));
