import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AdditionalInterestConfirmation' })((theme) => ({
  root: {},
  closeButton: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 'fit-content',
    },
  },
  linkButton: { ...theme.typography.body1Underline, width: 'fit-content' },
  downloadIcon: { marginRight: 12 },
}));
