import { Card } from '@ecp/components';
import { PaymentDue, PolicyCard } from '@ecp/features/servicing/shared/components';
import type { PolicyBillingDetails } from '@ecp/features/servicing/shared/state';

import { useStyles } from './PolicyPaymentDisplay.styles';

export interface PolicyPaymentDisplayProps {
  policyBillingDetails: PolicyBillingDetails;
}

export const PolicyPaymentDisplay: React.FC<PolicyPaymentDisplayProps> = (props) => {
  const { policyBillingDetails } = props;
  const { classes } = useStyles();

  const paymentDetails = (
    <div className={classes.paymentBorder}>
      <PaymentDue billingAccount={policyBillingDetails.billingInfo} />
    </div>
  );
  const body = (
    <Card
      classes={{ root: classes.paymentDetails, content: classes.paymentContent }}
      body={paymentDetails}
      divider={false}
    />
  );

  return (
    <PolicyCard
      classes={{ root: classes.root }}
      policyDetails={policyBillingDetails.policyInfo}
      body={body}
    />
  );
};
