import { IconUICheck } from '@ecp/themes/base';

import { useStyles } from './CheckIcon.styles';

export const CheckIcon: React.FC = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.circle}>
        <IconUICheck className={classes.icon} />
      </div>
    </div>
  );
};
