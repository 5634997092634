import { Fade } from '@mui/material';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { CSSObject } from 'tss-react';

import { IconUIArrowCircleRightImageUrl } from '../assets';
import type { Breakpoints } from '../breakpoints';
import type { MixinsOverrides } from '../mixins';
import type { Palette } from '../palette';
import type { Typography } from '../typography';
import type { ComponentsOptions } from './components.types';

export const createComponents = (
  breakpoints: Omit<Breakpoints, 'not'>,
  mixins: MixinsOverrides,
  palette: Omit<Palette, 'mode'>,
  typography: Typography,
): ComponentsOptions => {
  /** Disable zoom-in on input focus for iPhones by setting font-size 16px. */
  const iPhoneZoomFix: CSSObject = {
    '@supports (-webkit-touch-callout: none)': {
      [`@media only screen
              and (max-width: 428px)
              and (orientation: portrait)`]: {
        fontSize: 16,
      },
      [`@media only screen
              and (max-width: 896px)
              and (orientation: landscape)`]: {
        fontSize: 16,
      },
    },
  };

  const components: ComponentsOptions = {
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          '&::before': {
            display: 'none',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&.Mui-focusVisible': {
            outline: 'auto',
            backgroundColor: 'transparent',
          },
        },
        content: {
          '&.Mui-expanded': {
            margin: '12px 0',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&:active, &.Mui-focusVisible': {
            outline: 'revert',
          },
        },
        endIcon: {
          marginLeft: 2,
        },
        startIcon: {
          marginRight: 2,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          // Allow majority Mui elements to use browser default outline
          outline: '',
          '&.Mui-focusVisible': {
            outline: 'revert',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          boxShadow: 'none',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: 2,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: 15,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 10,
          '&.Mui-focusVisible': {
            outlineOffset: 5,
            outlineWidth: 2,
            outlineStyle: 'solid',
            outlineColor: palette.primary.main,
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          '@font-face': {
            ...typography.body1,
            fontFamily: typography.fontFamily,
          },
        },
        'p, h1, h2, h3, h4, h5, h6': {
          margin: 0,
          color: palette.text.primary,
        },
        h1: typography.h1,
        h2: typography.h2,
        h3: typography.h3,
        h4: typography.h4,
        h5: typography.h5,
        h6: typography.h6,
        'p, li': typography.body1,
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          [breakpoints.up('lg')]: {
            minWidth: 600,
          },
          [breakpoints.down('md')]: {
            top: '0',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: palette.other.divider,
          '&:before, &:after': {
            borderColor: 'inherit',
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          textTransform: 'none',
          '&:active, &.Mui-focusVisible': {
            outline: 'revert',
          },
        },
        extended: {
          height: 'auto',
          padding: '12px 40px',
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          width: '100%',
          '[class*="MuiTextField-root"]&, [class*="TextField-root"] &, [class*="Select-root"] &': {
            [breakpoints.up('md')]: {
              maxWidth: 'calc(50% - 15px)',
              '&.MuiFormControl-fullWidth': {
                maxWidth: '100%',
              },
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: palette.text.primary,
          marginLeft: 0,
          marginRight: 0,
          '& .Mui-disabled > *': {
            color: palette.actions.disabledText,
          },
        },
        label: {
          ...typography.body1,
          color: palette.text.secondary,
          lineHeight: '16px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          ...typography.body2,
          minHeight: 17,
          '&.Mui-disabled': {
            color: palette.text.secondary,
          },
          '&.MuiFormHelperText-root.Mui-error': {
            display: 'flex',
            alignItems: 'start',
            '&:before': {
              content: '""',
              display: 'block',
              maskImage: `url(${IconUIArrowCircleRightImageUrl})`,
              height: 15,
              width: 15,
              paddingRight: 14,
              margin: '2px 4px 0 0',
              backgroundColor: palette.error.main,
            },
          },
        },
        contained: {
          marginLeft: 0,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: palette.grey[300],
          color: palette.error.darkText,
          height: 'fit-content',
          padding: '2px 0 2px',
          width: 'fit-content',
        },
        label: {
          ...typography.body2Bold,
        },
        colorError: {
          backgroundColor: palette.error.light,
        },
        colorSuccess: {
          backgroundColor: palette.success.main,
          color: palette.common.white,
        },
        colorInfo: {
          backgroundColor: palette.info.light,
        },
        colorWarning: {
          color: palette.error.darkText,
          backgroundColor: palette.warning.light,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          ...typography.body4,
          fontWeight: 'normal',
          color: palette.text.primary,
          display: 'block',
          paddingBottom: 3,
          '&.Mui-focused': {
            color: palette.text.primary,
          },
          '&.Mui-error': {
            color: palette.error.main,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 13,
          '&:hover': {
            // Take care of Radio, Checkbox, DatePicker, "?" Tooltip
            backgroundColor: 'transparent !important',
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          marginLeft: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        animated: {
          transition: 'none',
        },
        formControl: {
          ...typography.body3,
          position: 'relative',
          transform: 'none',
          overflow: 'visible',
          whiteSpace: 'pre-line',
        },
        outlined: {
          color: palette.text.primary,
          transform: 'none',
          '&.MuiInputLabel-shrink': {
            transform: 'none',
          },
          '&.Mui-error': {
            color: palette.text.primary,
          },
          '&.Mui-disabled': {
            color: palette.text.primary,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: palette.text.link,
          textDecorationColor: palette.text.link,
          '&:hover': {
            color: palette.text.hover,
            cursor: 'pointer',
          },
          '&.Mui-focusVisible': {
            outline: 'revert',
          },
        },
      },
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: palette.primary.main,
            color: palette.primary.contrastText,
            '&:hover': {
              backgroundColor: palette.primary.main,
            },
          },
        },
        gutters: {
          paddingLeft: 11,
          paddingRight: 11,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          marginBottom: 0,
          marginTop: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            color: palette.text.tertiary,
            borderColor: palette.grey[600],
          },
          '&:hover:not(&.Mui-focused):not(&.Mui-disabled):not(&.Mui-error) .MuiOutlinedInput-notchedOutline':
            {
              borderColor: palette.grey[600],
            },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderWidth: 2,
          },
          height: 50,
        },
        input: {
          ...typography.body4,
          padding: '12px 10px 14px',
          color: palette.text.primary,
          height: 'auto',
          ...iPhoneZoomFix,
          '&.Mui-disabled': {
            textFillColor: palette.text.primary,
          },
          '&:-internal-autofill-selected': {
            boxShadow: `0 0 0 50px ${palette.grey[50]} inset`,
          },
        },
        notchedOutline: {
          borderColor: palette.grey[600],
          borderRadius: 4,
          '&:hover': {
            borderColor: palette.text.primary,
          },
        },
        adornedEnd: {
          paddingRight: 0,
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        root: {
          padding: 0,
          '&.Mui-focusVisible': {
            outlineOffset: 4,
            outlineWidth: 2,
            outlineStyle: 'solid',
            outlineColor: palette.primary.main,
          },
          '&:hover': {
            background: 'none',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          '*, *::before, *::after': {
            fill: palette.primary.main,
          },
          right: 14,
          top: 'inherit',
        },
        iconOutlined: {
          marginTop: 2,
        },
        select: {
          paddingLeft: 0,
          boxSizing: 'border-box',
          '&:focus': {
            borderRadius: 4,
            backgroundColor: palette.other.body,
          },
          ...iPhoneZoomFix,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        thumb: {
          '&.Mui-focusVisible': {
            outline: 'auto',
            boxShadow: 'none',
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          pointerEvents: 'none',
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        placement: 'top',
        TransitionComponent: Fade,
        TransitionProps: { timeout: 600 },
        enterTouchDelay: 0,
      },
      styleOverrides: {
        arrow: {
          color: palette.text.tertiary,
        },
        tooltip: {
          ...typography.body1,
          backgroundColor: palette.text.tertiary,
          borderRadius: 3,
          color: palette.grey[50],
          lineHeight: '22px',
          maxWidth: 415,
          padding: '10px 15px',
        },
        tooltipPlacementTop: {
          margin: '11px 0 !important',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: palette.text.primary,
        },
      },
    },
  };

  return components;
};
