import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

import { useStyles } from './MobileMenuIcon.styles';

interface Props extends SvgIconProps {
  active: boolean;
}

export const MobileMenuIcon: React.FC<Props> = (props) => {
  const { className, active, ...rest } = props;
  const { classes, cx } = useStyles();

  return (
    <SvgIcon
      viewBox='0 0 27 22'
      className={cx(classes.root, active && classes.active, className)}
      {...rest}
    >
      <rect className={classes.rect1} y='0.5' width='27' height='3.33333' />
      <rect className={classes.rect2} y='8.83398' width='27' height='3.33333' />
      <rect className={classes.rect3} y='17.166' width='27' height='3.33333' />
    </SvgIcon>
  );
};
