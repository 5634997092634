import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<void, 'cell' | 'row'>({ name: 'CaMileageEdit' })(
  (...[theme, , classes]) => ({
    root: {},
    button: {
      [theme.breakpoints.up('md')]: {
        maxWidth: 'fit-content',
      },
    },
    cell: {
      border: `1px solid ${theme.palette.text.primary}`,
      padding: '8px 32px',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: { verticalAlign: 'top' },
    },
    cellHeader: {
      ...theme.typography.body1Bold,
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        height: 70,
        minWidth: 140,
        paddingRight: 0,
        textAlign: 'left',
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: 'unset',
      },
    },
    cellTextOnly: {
      verticalAlign: 'middle',
    },
    cellValue: {
      [theme.breakpoints.down('md')]: {
        paddingLeft: 8,
        width: '56%',
      },
    },
    codeOfReg: theme.typography.body3Italics,
    legal: theme.typography.body3,
    formCode: theme.typography.body2,
    row: {},
    signature: { maxWidth: 360 },
    subtitle: theme.typography.body3Bold,
    table: {
      [theme.breakpoints.down('md')]: {
        [`& .${classes.cell}`]: {
          border: 'none',
          padding: '8px 8px 8px 12px',
          textAlign: 'left',
        },
        [`& tr`]: { border: `1px solid ${theme.palette.text.primary}` },
      },
    },
    vehicleField: { ...theme.typography.body1, maxWidth: 210 },
  }),
);
