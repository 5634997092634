const addCommas = (value: number, precision: number): string => {
  const rawString = value.toFixed(precision);

  return rawString.replace(/\B(?=(\d\d\d)+(\D|$))/g, ',');
};

export const parseDollar = (
  value: string | number | null | undefined,
  centsHint = true,
): string => {
  // if we have no value, return $0 or $0.00
  if (!value) {
    return centsHint ? '$0.00' : '$0';
  }

  if (typeof value === 'string') {
    // Remove $ from the value
    if (value.indexOf('$') === 0) value = value.substring(1);
    // Remove all grouping delimiters from the value
    value = +value.replace(/,/g, '');

    if (Number.isNaN(value)) {
      return centsHint ? '$0.00' : '$0';
    }
  }

  return `$${addCommas(value, centsHint ? 2 : 0)}`;
};

export const normalizeValue = ({ value }: { value: string }): string => {
  return value.replace(/\$|,|\s/g, '');
};
