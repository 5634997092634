import { Divider, Grid } from '@mui/material';

import { Button, Card } from '@ecp/components';
import { IconMaterialDesignNavigationWest } from '@ecp/themes/base';

import { PartnerLogo } from '../PartnerLogo';
import { useStyles } from './AuthenticationCard.styles';

interface Props {
  onBack?: () => void;
}

export const AuthenticationCard: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { children, onBack } = props;
  const { classes } = useStyles();

  const banner = (
    <>
      <Grid container direction='row' className={classes.banner}>
        {onBack && (
          <Grid item xs={1} className={classes.linkContent}>
            <Button
              variant='iconText'
              onClick={onBack}
              aria-label='Go Back'
              trackingName='back_arrow_button'
              trackingLabel='back_arrow'
            >
              <IconMaterialDesignNavigationWest role='presentation' className={classes.backIcon} />
            </Button>
          </Grid>
        )}
        <Grid item xs={onBack ? 10 : 12} className={classes.logo}>
          <PartnerLogo classes={{ image: classes.logoImage }} />
        </Grid>
      </Grid>
      <Divider />
    </>
  );

  return (
    <Card
      banner={banner}
      classes={{ body: classes.body, content: classes.cardContent }}
      divider={false}
      body={children}
    />
  );
};
