export const deductiblesOrder = [
  'deductibles.deductible',
  'coverages.includeEarthquake',
  'coverages.includeEquipmentBreakdown',
  'coverages.moldPropertyProtection',
  'deductibles.hurricane',
  'coverages.refrigeratedCoverage',
  'coverages.includeServiceLine',
  'coverages.includeWaterBackup',
  'deductibles.windStorm',
];
