import { Link } from '@ecp/components';
import { env } from '@ecp/env';

import { useStyles } from '../PrivacyPolicyHomesite.styles';

export const DoNotSellPersonalInfoLink: React.FC = () => {
  const { classes } = useStyles();

  return (
    <Link
      className={classes.links}
      href={env.ccpaUrl}
      target='_blank'
      rel='noreferrer'
      trackingName='california_consumer_privacy_act_link'
      trackingLabel='california_consumer_privacy_act'
    >
      DO NOT SELL MY PERSONAL INFORMATION
    </Link>
  );
};
