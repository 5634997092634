import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<void, 'closeButton' | 'closeIcon' | 'icon'>({
  name: 'Alert',
})((...[theme, , classes]) => ({
  root: {
    ...theme.typography.body1,
    borderRadius: 3,
    borderStyle: 'solid',
    borderWidth: 2,
    color: theme.palette.text.primary,
    display: 'flex',
    margin: 0,
    padding: 10,
    [`& .${classes.closeButton}`]: {
      height: 24,
      marginLeft: 'auto',
      width: 24,
    },
    [`& .${classes.closeIcon}`]: theme.mixins.setColorSvg(theme.palette.text.primary),
    '& a': {
      ...theme.mixins.underlinedLink,
      '&:hover': {
        color: theme.palette.text.hover,
        textDecoration: 'underline',
      },
    },
  },
  default: {
    backgroundColor: theme.palette.grey[300],
    borderColor: theme.palette.grey[700],
  },
  error: {
    color: theme.palette.error.darkText,
    backgroundColor: theme.palette.error.light,
    borderColor: theme.palette.error.main,
    [`& .${classes.icon}`]: theme.mixins.setColorSvg(theme.palette.error.dark),
  },
  info: {
    color: theme.palette.warning.darkText,
    backgroundColor: theme.palette.info.light,
    borderColor: theme.palette.info.main,
    [`& .${classes.icon}`]: theme.mixins.setColorSvg(theme.palette.info.main),
  },
  success: {
    color: theme.palette.success.darkText,
    backgroundColor: theme.palette.success.light,
    borderColor: theme.palette.success.main,
    [`& .${classes.icon}`]: theme.mixins.setColorSvg(theme.palette.success.main),
  },
  warning: {
    color: theme.palette.warning.darkText,
    backgroundColor: theme.palette.warning.light,
    borderColor: theme.palette.warning.main,
    [`& .${classes.icon}`]: theme.mixins.setColorSvg(theme.palette.warning.dark),
  },
  icon: {
    ...theme.mixins.setColorSvg(theme.palette.text.primary),
    height: 24,
    minWidth: 24,
  },
  message: {
    alignSelf: 'center',
    margin: '0 8px',
    '&:first-child': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  closeButton: {},
  closeIcon: {
    ...theme.mixins.setColorSvg(theme.palette.text.primary),
    width: 20,
  },
}));
