import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<void, 'link'>({ name: 'AccountSelect' })(
  (...[theme, , classes]) => ({
    root: {},
    link: {
      color: theme.palette.secondary.main,
      '&:hover': {
        color: theme.palette.primary.contrastText,
      },
    },
    placeholder: {
      padding: '8px 16px',
    },
    selectOption: {
      '&:hover, &.Mui-selected:hover': {
        [`& > .${classes.link}`]: {
          color: theme.palette.primary.contrastText,
        },
      },
    },
    selectLabel: {
      '& .MuiFormLabel-root': {
        paddingBottom: 4,
      },
    },
    addMethodBtn: {
      backgroundColor: theme.palette.grey[50],
      width: 280,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    addMethodHeader: theme.typography.body3,
  }),
);
