import { useCallback } from 'react';

import { Button } from '@ecp/components';
import { MODAL_FLOW, MODAL_STEP, useModalPath } from '@ecp/features/servicing/shared/routing';

import { useStyles } from './AutopayButton.styles';

interface Props {
  policyNumber: string;
  className?: string;
  isManageFlow?: boolean;
}

export const AutopayButton: React.FC<Props> = (props) => {
  const { className, policyNumber, isManageFlow } = props;
  const { classes, cx } = useStyles();
  const modalPath = useModalPath();
  const handleAutoPayClick = useCallback(() => {
    modalPath.init(
      policyNumber,
      isManageFlow ? MODAL_FLOW.AUTOPAY_MANAGE : MODAL_FLOW.AUTOPAY_ENROLL,
      isManageFlow ? MODAL_STEP.MODAL_LANDING : MODAL_STEP.MODAL_EDIT,
    );
  }, [modalPath, policyNumber, isManageFlow]);

  return (
    <Button
      variant='outlinePrimary'
      className={cx(classes.root, className)}
      onClick={handleAutoPayClick}
      trackingName={isManageFlow ? 'manage_autopay_button' : 'enroll_autopay_button'}
      trackingLabel={isManageFlow ? 'manage_autopay_continue' : 'enroll_autopay_continue'}
      trackingDimensions={{ active_policy_number: policyNumber }}
    >
      {isManageFlow ? 'Manage autopay' : 'Enroll in autopay'}
    </Button>
  );
};
