import type { AppVars } from './appVars.types';

export const appVars: AppVars = {
  appHeaderHeight: 85,
  appHeaderHeightAgent: 64,
  maxPageWidth: 1142,
  baseContentContainerWidth: 700,
  maxContentContainerWidth: 760,
  baseSidebarContainerWidth: 260,
  maxSidebarContainerWidth: 330,
};
