import { memo, useMemo } from 'react';

import type { ListItemsProps } from '@ecp/components';
import { ListItems } from '@ecp/components';

import { useStyles } from './DiscountsBlock.styles';

export interface DiscountsBlockProps {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  footer?: ListItemsProps['footer'];
  items?: ListItemsProps['items'];
}

export const DiscountsBlock: React.FC<DiscountsBlockProps> = memo((props) => {
  const { items, footer } = props;
  const { classes } = useStyles(undefined, { props });
  const cardClasses: ListItemsProps['cardClasses'] = useMemo(
    () => ({
      root: classes.root,
      content: classes.content,
      horizontalDivider: classes.horizontalDivider,
    }),
    [classes.content, classes.horizontalDivider, classes.root],
  );

  const listItemClasses: ListItemsProps['classes'] = useMemo(
    () => ({
      item: classes.item,
      svg: classes.svg,
      root: classes.list,
    }),
    [classes.item, classes.list, classes.svg],
  );

  if (!items) return null;

  return (
    <ListItems
      classes={listItemClasses}
      cardClasses={cardClasses}
      items={items}
      footer={footer}
      bannerItem='discounts'
    />
  );
});
