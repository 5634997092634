import * as yup from 'yup';

import type { YupSchemaMap } from '@ecp/utils/form';

export interface VerifyIdentityFormInputs {
  verificationOption?: string;
}

export const verifyIdentitySchema: YupSchemaMap<VerifyIdentityFormInputs> = {
  verificationOption: yup.string().required('Please select a verification option'),
};
