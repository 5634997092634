export const routingRegex =
  /^((0[0-9])|(1[0-2])|(2[1-9])|(3[0-2])|(6[1-9])|(7[0-2])|80)([0-9]{7})$/;
export const accountRegex = /^[0-9]{5,17}$/;

// for AMEX cards we need the first character to be 3, a 4 or a 7
// and then 14 characters after that for a total of 15 characters total
export const amexCardRegex = /^3[47][0-9]{13}$/;

// VISA cards start with 4. New VISA cards will always have 16 digits
// but older ones can have 13 and this will match both cases
export const visaCardRegex = /^4[0-9]{12}(?:[0-9]{3})?$/;

// MasterCard numbers either start with the numbers 51 through 55
// or with the numbers 2221 through 2720. All have 16 digits.
export const masterCardRegex =
  /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/;

// Discover card numbers begin with 6011 or 65. All have 16 digits
export const discoverCardRegex = /^6(?:011|5[0-9]{2})[0-9]{12}$/;

// Regex for only alphabetical characters, hyphen, period and space allowed
export const nameOnCardRegex = /^[A-Za-z-. ]+$/;

// Only numeric value, 3 didits
export const securityCodeCardRegex = /^[0-9]{3}$/;

// Only numeric value, 4 didits
export const securityCodeAmexCardRegex = /^[0-9]{4}$/;

// Regex for invalid cc input
export const invalidCreditCardInputRegex = /\D/g;
