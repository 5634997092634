import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutopayUnenrollReview' })((theme) => ({
  root: {},
  backButton: {
    padding: '16px 32px',
  },
  explanation: {
    ...theme.typography.body1,
    backgroundColor: theme.palette.other.body,
    borderRadius: 4,
    padding: '24px 16px',
  },
  explanationNumbers: theme.typography.body2Bold,
  submitButtton: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));
