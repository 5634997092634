import type { VehicleCoverage } from '@ecp/features/servicing/shared/types';

export const autoPolicyCoveragesGroupList = [
  "For when you're at fault",
  'For you and your passengers',
] as const;
export const vehicleCoveragesGroupList = [
  'Onsite assistance and reimbursements',
  'Repairs, replacements, and loan assistance',
] as const;

export type AutoPolicyCoveragesGroup = (typeof autoPolicyCoveragesGroupList)[number];
export type VehicleCoveragesGroup = (typeof vehicleCoveragesGroupList)[number];

export const groupAutoPolicyCoveragesMap: Record<AutoPolicyCoveragesGroup, string[]> = {
  "For when you're at fault": ['BodilyInjury', 'PropertyDamage'],
  'For you and your passengers': [
    'MedicalExpense',
    'UninsuredMotorist',
    'UnderInsuredMotorist',
    'UninsuredMotoristPropertyDamageLimit',
    'UnderInsuredMotoristPropertyDamageLimit',
  ],
};
export const groupVehicleCoveragesMap: Record<VehicleCoveragesGroup, string[]> = {
  'Onsite assistance and reimbursements': ['RentalReimbursement', 'EmergencyRoadsideService'],
  'Repairs, replacements, and loan assistance': ['CollisionFullSafetyGlass', 'FullSafetyGlass'],
};

export const autoPolicyCoveragesMap: Record<string, VehicleCoverage[]> = {
  BodilyInjury: ['bodilyInjury'],
  PropertyDamage: ['propertyDamage'],
  MedicalExpense: ['medical'],
  UninsuredMotorist: ['uninsuredMotorist'],
  UnderInsuredMotorist: ['underinsuredMotorist'],
  UninsuredMotoristPropertyDamageLimit: ['uninsuredMotoristPD'],
  UnderInsuredMotoristPropertyDamageLimit: ['underinsuredMotoristPD'],
};

export const vehicleCoveragesMap: Record<string, VehicleCoverage[]> = {
  Comprehensive: ['comprehensive'],
  Collision: ['collision'],
  RentalReimbursement: ['rental'],
  EmergencyRoadsideService: ['roadsideService'],
  CollisionFullSafetyGlass: ['collisionFullSafetyGlass'],
  FullSafetyGlass: ['comprehensiveFullSafetyGlass'],
};

export const vehicleDeductiblesOrder = ['Comprehensive', 'Collision'];
