import type { Address } from '@ecp/features/servicing/shared/types';

import { useStyles } from './AddressDisplay.styles';

export interface AddressDisplayProps {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  policyAddress?: Omit<Address, 'addressId'>;
}

export const AddressDisplay: React.FC<AddressDisplayProps> = (props) => {
  const { policyAddress } = props;
  const { classes } = useStyles(undefined, { props });

  if (!policyAddress) return null;

  return (
    <>
      <p className={classes.text}>{policyAddress?.line1}</p>
      {policyAddress?.line2 && <p className={classes.text}>{policyAddress?.line2}</p>}
      <p className={classes.text} data-testid='cityStateZip'>
        {policyAddress?.city}, {policyAddress?.state}, {policyAddress?.zip}
      </p>
    </>
  );
};
