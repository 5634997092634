import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PaymentsDueSection' })((theme) => ({
  root: {
    listStyle: 'none',
    padding: 0,
  },
  listWrapper: {
    display: 'grid',
    gridAutoRows: 'auto',
    gridTemplateColumns: 'repeat(1, 1fr)',
    margin: 0,
    gap: 24,
    [theme.breakpoints.between('md', 'lg')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  paymentsDueSection: { textAlign: 'center' },
  paymentsDueText: {
    ...theme.typography.body3Bold,
    color: theme.palette.text.disabled,
  },
}));
