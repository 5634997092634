import { useStyles } from './CallbackHandler.styles';

interface CallbackHandlerProp {
  messageName: 'WORLDPAY_TRANSACTION';
}

export const CallbackHandler: React.FC<CallbackHandlerProp> = (prop) => {
  const { messageName } = prop;
  const { classes } = useStyles();

  const payload = {
    messageName,
    transactionInfo: window.location.href,
  };
  window.parent.postMessage(payload, window.location.origin);

  return <div className={classes.root} />;
};
