import type { BillingPlan } from '../billing';

export const getBillingPlanDescription = (
  billingPlan: BillingPlan | undefined,
): string | undefined => {
  switch (billingPlan) {
    case 'ONE-PAY PLAN':
      return 'Annually';

    case 'FOUR-PAY PLAN':
      return 'Every 3 months';

    case 'TEN-PAY PLAN':
      return '10 installments';

    case 'TWELVE-PAY PLAN':
      return 'Monthly';
    default:
      return undefined;
  }
};
