import { Grid, Modal as MuiModal } from '@mui/material';

import { Button } from '@ecp/components';
import { IconUIClose } from '@ecp/themes/base';

import { useStyles } from './Modal.styles';

export type ModalVariant = 'small' | 'default' | 'large';

export interface ModalProps {
  // required
  open: boolean;
  // optional
  footer?: React.ReactElement;
  disableTitleBarClose?: boolean;
  title?: string;
  size?: ModalVariant;
  backdrop?: boolean;
  className?: string;
  onClose?(): void;
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
}

export const Modal: React.FC<React.PropsWithChildren<ModalProps>> = (props) => {
  const {
    backdrop,
    size,
    onClose,
    open,
    className,
    children,
    footer,
    disableTitleBarClose,
    title,
  } = props;
  const { classes, cx } = useStyles(undefined, { props });
  const backdropProps = { invisible: !backdrop };

  return (
    <MuiModal data-testid='Modal' open={open} onClose={onClose} BackdropProps={backdropProps}>
      <aside
        className={cx(
          classes.modal,
          size === 'small' && classes.modalSmall,
          size === 'large' && classes.modalLarge,
          className,
        )}
      >
        <Grid container spacing={0} className={classes.titleBar}>
          <Grid item xs>
            <p className={classes.titleBarTitle}>{title}</p>
          </Grid>
          {!disableTitleBarClose && (
            <Grid item xs='auto'>
              <Button
                className={classes.titleBarCloseButton}
                variant='iconText'
                onClick={onClose}
                data-testid='closeIcon'
                aria-label='Close dialog'
                icon={<IconUIClose className={classes.titleBarCloseButtonIcon} />}
              />
            </Grid>
          )}
        </Grid>
        {children}
        {footer && (
          <Grid container spacing={0} justifyContent='flex-end' className={classes.footerBar}>
            {footer}
          </Grid>
        )}
      </aside>
    </MuiModal>
  );
};
