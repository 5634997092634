import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AddACard' })((theme) => ({
  root: {
    alignItems: 'center',
    borderRadius: 6,
    width: '100%',
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23${theme.palette.text.link.replace(
      '#',
      '',
    )}FF' stroke-width='2' stroke-dasharray='7%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
  },
  addLandlordCard: { padding: 1 },
  button: theme.typography.body1Bold,
  iconSection: {
    backgroundColor: theme.palette.primary.light,
    padding: '10px 15px',
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
  },
  icon: {
    height: 36,
    width: 36,
  },
  iconCircle: {
    height: 46,
    width: 46,
    backgroundColor: theme.palette.grey[50],
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
