import { flagValues } from '@ecp/utils/flags';

import type { UiPolicy } from '@ecp/features/servicing/shared/types';

import type { BillingResponse } from '../billing';
import { getAddressByRefId } from './getAddressByRefId';
import { getProductLineFromPolicyResponse } from './getProductLineFromPolicyResponse';
import { isInPendingCancelStatus } from './isInPendingCancelStatus';

export const getMaxDrivers = (riskState: string): number =>
  ['KY', 'MI'].includes(riskState) ? 9 : 6;

export const showAddDriver = (
  billingInfo: BillingResponse | undefined,
  policyData: UiPolicy | undefined,
): { showAddDriverLink: boolean; showDriverAlert: boolean } => {
  let showAddDriverLink = false,
    showDriverAlert = false;
  const personsCount = policyData?.persons?.length;
  const riskState = getAddressByRefId(policyData, policyData?.policy.primaryAddressRefId)?.state;

  if (!personsCount || !billingInfo || !policyData || !riskState)
    return { showAddDriverLink, showDriverAlert };

  const isPendingCancellation = isInPendingCancelStatus({
    billingResponse: billingInfo,
    productLine: getProductLineFromPolicyResponse(policyData),
    policySource: policyData?.policy.sourceSystemName,
    policyData: policyData,
  });

  const canAddMoreDrivers = personsCount < getMaxDrivers(riskState);

  showAddDriverLink = !isPendingCancellation && flagValues.ADD_DRIVER && canAddMoreDrivers;
  showDriverAlert = !isPendingCancellation && flagValues.ADD_DRIVER && !canAddMoreDrivers;

  return { showAddDriverLink, showDriverAlert };
};
