import { useCallback, useState } from 'react';

import { Stack } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { flagValues, flagVariables } from '@ecp/utils/flags';

import { Alert, Link } from '@ecp/components';
import { CaMileageAlert } from '@ecp/features/servicing/ca-mileage';
import { Card, Section } from '@ecp/features/servicing/shared/components';
import type { EnrollmentNavState } from '@ecp/features/servicing/shared/routing';
import { navState } from '@ecp/features/servicing/shared/routing';
import type { BookRolledPolicy } from '@ecp/features/servicing/shared/state';
import {
  getPolicyDisplayType,
  isCaMileageFormRequired,
} from '@ecp/features/servicing/shared/state';
import type { UiPolicy } from '@ecp/features/servicing/shared/types';

import { useStyles } from './AlertsSection.styles';

export interface AlertsSectionProps {
  policies: UiPolicy[] | undefined;
  bookRolledConnectPolicies: BookRolledPolicy[] | undefined;
  handleBookRollLearnMoreClick: () => void;
}

export const AlertsSection: React.FC<AlertsSectionProps> = (props) => {
  const { bookRolledConnectPolicies, policies, handleBookRollLearnMoreClick } = props;
  const { classes } = useStyles();
  const location = useLocation();

  const policyNeedingCaMileage = policies?.find(isCaMileageFormRequired);
  const enrollmentNavState = location.state as EnrollmentNavState;
  const fromEnrollment = enrollmentNavState?.from === navState.from;
  const outageNotice = flagValues.OUTAGE_NOTICE;
  const outageText = outageNotice ? flagVariables.OUTAGE_NOTICE.OUTAGE_TEXT : '';
  const inForceBookRolledPolicies = bookRolledConnectPolicies?.filter(
    (bookrolledPolicy) => bookrolledPolicy.policyData.policy.status === 'In Force',
  );
  const inForceBookRolledPoliciesText = inForceBookRolledPolicies
    ?.map(
      (inForcePolicy) =>
        `${getPolicyDisplayType(inForcePolicy.policyData)} policy ${
          inForcePolicy.policyData.policy.policyNumber
        }`,
    )
    .join(' and ');

  const [isFromEnrollmentAlertOpen, setIsFromEnrollmentAlertOpen] = useState(fromEnrollment);
  const [isBookRollAlertOpen, setIsBookRollAlertOpen] = useState(
    !!inForceBookRolledPolicies?.length,
  );
  const [isCaMileageAlertOpen, setIsCaMileageAlertOpen] = useState(!!policyNeedingCaMileage);

  const alertsSectionEnabled =
    fromEnrollment ||
    outageNotice ||
    !!policyNeedingCaMileage ||
    !!inForceBookRolledPolicies?.length;

  const handleFromEnrollAlertClose = useCallback(() => {
    setIsFromEnrollmentAlertOpen(false);
  }, []);
  const handleBookrollAlertClose = useCallback(() => {
    setIsBookRollAlertOpen(false);
  }, []);
  const handleCaMileageAlertClose = useCallback(() => {
    setIsCaMileageAlertOpen(false);
  }, []);

  const noMessagesSection = (
    <Card
      className={classes.paymentsDueSection}
      body={<span className={classes.paymentsDueText}>No alerts at this time</span>}
      divider={false}
    />
  );

  if (!alertsSectionEnabled) return null;

  return (
    <Section title='Alerts'>
      {!isBookRollAlertOpen &&
      !isFromEnrollmentAlertOpen &&
      !isCaMileageAlertOpen &&
      !outageNotice ? (
        noMessagesSection
      ) : (
        <Stack spacing={3}>
          {outageNotice && (
            <Alert withIcon type='warning'>
              {outageText}
            </Alert>
          )}
          {fromEnrollment && isFromEnrollmentAlertOpen && (
            <Alert withIcon withAction type='info' onClose={handleFromEnrollAlertClose}>
              Welcome to your new self-service dashboard! Here, you'll be able to make payments, get
              policy documents, view coverage information, and more.
            </Alert>
          )}
          {!!inForceBookRolledPolicies?.length && isBookRollAlertOpen && (
            <Alert withIcon withAction type='info' onClose={handleBookrollAlertClose}>
              Your {inForceBookRolledPoliciesText} will be changing with your upcoming renewal.{' '}
              <Link
                component='button'
                onClick={handleBookRollLearnMoreClick}
                trackingLabel='learn_more'
                trackingName='learn_more_link'
              >
                Learn more
              </Link>
            </Alert>
          )}
          {!!policyNeedingCaMileage && (
            <CaMileageAlert
              policyData={policyNeedingCaMileage}
              onClose={handleCaMileageAlertClose}
            />
          )}
        </Stack>
      )}
    </Section>
  );
};
