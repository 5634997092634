import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<void, 'rect1' | 'rect2' | 'rect3'>({ name: 'MobileMenuIcon' })(
  (...[theme, , classes]) => ({
    root: {
      maxWidth: 24,
      maxHeight: 24,
      rect: {
        fill: theme.palette.primary.main,
        transition: '.3s',
      },
    },
    active: {
      [`.${classes.rect1}`]: { transform: 'rotate(-45deg) translateX(-3.7px) translateY(-3.7px)' },
      [`.${classes.rect2}`]: { opacity: 0 },
      [`.${classes.rect3}`]: { transform: 'rotate(45deg) translateX(-3.7px) translateY(4.7px)' },
    },
    rect1: { transformOrigin: 'top right' },
    rect2: {},
    rect3: { transformOrigin: 'bottom right' },
  }),
);
