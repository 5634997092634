import { forwardRef, useCallback, useEffect, useState } from 'react';

import { TextField } from '@ecp/components';
import type { TextFieldProps } from '@ecp/components';

type Props = TextFieldProps;

export const PolicyNumberField: React.FC<Props> = forwardRef((props, ref) => {
  const { actionOnChange, onChange, ...rest } = props;
  const [displayedValue, setDisplayedValue] = useState<string | undefined>();

  useEffect(() => {
    if (!props.value) {
      setDisplayedValue('');
    }

    return () => setDisplayedValue('');
  }, [props.value]);

  const onChangeHandler = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (event) => {
      const trimedValue = event.target.value.replace(/[^a-zA-Z0-9 -]/g, ''); // accepts only alphanumeric spaces and dashes
      setDisplayedValue(trimedValue);
      actionOnChange?.(trimedValue);
      onChange?.(event);
    },
    [setDisplayedValue, actionOnChange, onChange],
  );

  return (
    <TextField
      {...rest}
      ref={ref}
      label={props.label ?? 'Policy number'}
      ariaLabel='Policy number'
      onChange={onChangeHandler}
      value={displayedValue || props.value}
      maxLength={20}
    />
  );
});
