import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'TooltipWithIcon' })((theme) => ({
  root: {},
  icon: {
    ...theme.mixins.setColorSvg(theme.palette.primary.main),
    height: 24,
    width: 24,
  },
  tooltipWithIconButton: {
    position: 'absolute',
    padding: 0,
  },
}));
