import { Stack } from '@mui/material';

import { Link } from '@ecp/components';

import { DisclosureWrapper } from '../../../components';

export const ProtectYourInformationConnect: React.FC = () => {
  return (
    <DisclosureWrapper
      title='How We Protect Your Information'
      subtitle='We are committed to protecting your privacy and security through a variety of safeguards.'
      breadcrumbTitleOverrides={{ protect: 'Protect Your Information' }}
    >
      <Stack spacing={4}>
        <Stack spacing={1}>
          <h2>Passwords</h2>
          <p>
            CONNECT requires you to choose a user ID and password to access your account information
            online or through a mobile device. This is one way we can verify your identity to
            prevent unauthorized account access.
          </p>
        </Stack>

        <Stack spacing={1}>
          <h2>Limited system and information access</h2>
          <p>
            CONNECT computer systems are protected by layered security devices that block
            unauthorized access. This means that no one without the proper web browser or mobile
            application configuration can view or modify information contained on our systems. Only
            authorized people can use CONNECT computer systems. And only people who need to know
            confidential client information have access to that information.{' '}
          </p>
        </Stack>

        <Stack spacing={1}>
          <h2>Secure sessions and encryption</h2>
          <p>
            We require that a secure session be established any time you supply or access personal
            or confidential information, such as when you log in to view your accounts. A secure
            session is established using Transport Layer Security (TLS) technology, which encrypts
            the information sent between your computer and us. Only computing devices with web
            browsers that are compatible with TLS Technology and support encryption can be used to
            access your account information online.
          </p>
          <p>
            If you are logged in to a secure area of our website, but are inactive for a certain
            period of time, we will automatically log you out to further protect your information.{' '}
          </p>
        </Stack>

        <Stack spacing={1}>
          <h2>Surveillance</h2>
          <p>
            CONNECT builds profiles for all of its users that document normal site usage patterns,
            including login frequency and transactions performed online. We use this information to
            detect unusual activity on your account, so that we can verify your identity and ensure
            that no one has unauthorized access to your accounts.
          </p>
        </Stack>

        <Stack spacing={1}>
          <h2>Email communications</h2>
          <p>CONNECT email communications fall into two categories:</p>
          <ul>
            <li>
              <strong>Client service email:</strong> May include information about your online
              registration, electronic delivery notification that a message or document is available
              for you to view online, information about products and services for which you are
              registered, and responses to client service emails you initiate, including emails
              relating to claims. You cannot opt out of client service emails and may continue to
              receive these emails even if you have requested to not receive email marketing offers
              from CONNECT.
            </li>
            <li>
              <strong>Marketing email:</strong> May include newsletters, information about products
              and services or special offers from CONNECT.{' '}
              <strong>
                You can{' '}
                <Link
                  target='_blank'
                  href='https://privacymanagement.connectbyamfam.com/optout/'
                  trackingName='unsubscribe_marketing_messages_link'
                  trackingLabel='unsubscribe_marketing_messages'
                >
                  opt out
                </Link>{' '}
                of marketing email messages at any time.
              </strong>
            </li>
          </ul>
          <p>
            To help ensure you receive email messages from us, we recommend that you add
            @mail.connectbyamfam.com email addresses to your email system's contact list or safe
            list. This will allow your email system to recognize our services as legitimate
            contacts.
          </p>
          <p>
            <strong>Tip:</strong> If you are unable to add the domain only, you may enter "someone"
            prior to the domain @. Example: someone@mail.connectbyamfam.com
          </p>
          <p>
            In addition, if your current email address ends in something other than ".com" (e.g.,
            .net, .edu, .info), you may want to consider using a Web-based email address instead
            (e.g., Yahoo, Gmail, etc.), as it may provide you with a better email receipt
            experience. If you make a change, please be sure to update your email address with us by
            logging into the service site on connectbyamfam.com.
          </p>
        </Stack>

        <Stack spacing={1}>
          <h2>Information we may request from you</h2>
          <p>
            When you initiate contact with CONNECT by phone, to safeguard accounts and verify your
            identity, we may ask you to confirm certain personal information. CONNECT will never
            call and ask for personal information on an unsolicited basis. Additionally, we will not
            request your personal information in an email and will never send emails requesting you
            to reply in the body of an email with personal information, such as password, Social
            Security number, account numbers, mother's maiden name, etc.{' '}
            <strong>
              Do not respond to, open an attachment in, or click on a link within an email if you
              suspect the message is fraudulent.
            </strong>
          </p>
          <p>
            If you are contacted otherwise or if you encounter a suspicious communication that you
            believe appears to be CONNECT, please contact us at anti.fraud@connectbyamfam.com. You
            may also forward fraudulent emails or report security concerns.
          </p>
        </Stack>
      </Stack>
    </DisclosureWrapper>
  );
};
