import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<void, 'activeItem' | 'linkWithOpenSubmenu'>({
  name: 'HeaderNav',
})((...[theme, , classes]) => ({
  root: {
    color: theme.palette.text.primary,
  },
  active: {
    ...theme.typography.body1,
    position: 'relative',
    '&, &:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  },
  link: {
    ...theme.typography.body1,
    borderBottom: `2px solid transparent`,
    color: theme.palette.text.primary,
    display: 'block',
    height: '100%',
    padding: '8px 0',
    '&, &:hover': { textDecoration: 'none' },
    [`.${classes.activeItem} &, &.${classes.linkWithOpenSubmenu}, &:hover`]: {
      ...theme.mixins.setColorSvg(theme.palette.primary.main),
      borderBottomColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
  linkWithOpenSubmenu: {},
  list: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: 0,
    position: 'relative',
    width: '100%',
    marginLeft: 24,
    '& li': {
      padding: '0 16px',
    },
  },
  menu: {
    [theme.breakpoints.down('md')]: { display: 'none' },
  },
  menuToolbar: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: 0,
  },
  mobileNav: {
    [theme.breakpoints.up('md')]: { display: 'none' },
  },
  activeItem: {},
  submenu: {
    backgroundColor: theme.palette.grey[100],
    minWidth: 167,
    boxShadow: `0px -1px 4px 0px ${theme.palette.common.black}26`,
  },
  submenuItem: {
    padding: '12px 24px',
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': { backgroundColor: theme.palette.primary.dark },
    },
  },
  submenuIcon: {
    ...theme.mixins.setColorSvg(theme.palette.text.primary),
    height: 16,
    width: 16,
  },
  submenuPaper: {
    margin: '-5px -25px',
  },
}));
