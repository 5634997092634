import { Alert } from '@ecp/components';

import { ContactUsLink } from '../ContactUsLink';
import { useStyles } from './ApiAlert.styles';

interface Props {
  apiDescription: string;
}

export const ApiAlert: React.FC<Props> = (props) => {
  const { apiDescription } = props;
  const { classes } = useStyles();

  return (
    <Alert className={classes.root} withAction withIcon type='error' hideOnClose>
      We're sorry. We are unable to access {apiDescription} at this time. Please{' '}
      <ContactUsLink className={classes.contactUsLink} /> or try again later.
    </Alert>
  );
};
