import { useState } from 'react';

import type { Metric } from 'web-vitals';
import { onFCP, onFID, onLCP, onTTFB } from 'web-vitals';

import { flagValues } from '@ecp/utils/flags';
import { trackDataDogUserAction } from '@ecp/utils/logger';
import { useEvent } from '@ecp/utils/react';

import { env } from '@ecp/env';

import { initializeDalAnalytics, logDalPageView, setDalDimension, trackDal } from './dalTracking';
import type { TrackingDimensionsNames } from './dimensions';
import { initializeGa, logGaPageView, setGaDimension, trackGa } from './gaTracking';
import type {
  AnalyticsErrorRequest,
  AnalyticsRequest,
  EventCategory,
  TrackingRequest,
} from './types';

interface MouseOptions {
  onMouseEnter(): void;
  onMouseLeave(): void;
}

export const setDimension = (dimension: TrackingDimensionsNames, value: unknown): void => {
  if (env.ecpDalAnalyticsEnabled) setDalDimension(dimension, value);
  setGaDimension(dimension, value);
};

export const track = (request: TrackingRequest): void => {
  if (env.ecpDalAnalyticsEnabled && !flagValues.DISABLE_DAL_ANALYTICS) {
    trackDal(request);
  }

  trackGa(request);

  // TODO Remove this, not quite sure why we double track
  const { action, category, label } = request;
  {
    const ddContext: { category: EventCategory; action: string; [action: string]: typeof label } = {
      category,
      action,
    };
    /**
     * Setting the value for 'action' as the label will help us redact the label
     * If we didn't do that we would get an object like this
     *
     * { action: 'ZipCodeEntry', label: '12345' }
     *
     * The PCI/PII scrubber wouldn't catch the actual PCI/PII data being sent. So
     * then we will change the object to be this
     *
     * { action: 'ZipCodeEntry', ZipCodeEntry: 12345 }
     *
     * Now we will still have the action associated with the PCI/PII data. This will
     * help us catch the PCI/PII data before it gets sent to Data Dog.
     */
    ddContext[action] = label;
    trackDataDogUserAction({ name: category, context: ddContext });
  }
};

export const trackRender = ({ action, label, dimensions, objectType }: AnalyticsRequest): void => {
  if (!action || !label) return;
  track({ category: 'Render', action, label, dimensions, objectType });
};

export const trackBeganQuestion = ({
  action,
  label = '',
  dimensions,
  objectType,
}: AnalyticsRequest): void => {
  if (!action) return;
  track({ category: 'Began Question', action, label, dimensions, objectType });
};

export const trackCompletedQuestion = ({
  action,
  label = '',
  dimensions,
  objectType,
}: AnalyticsRequest): void => {
  if (!action) return;
  track({ category: 'Completed Question', action, label, dimensions, objectType });
};

export const trackEntryStart = ({
  action,
  label = '',
  dimensions,
  objectType,
}: AnalyticsRequest): void => {
  if (!action) return;
  track({ category: 'Entry Start', action, label, dimensions, objectType });
};

export const trackEntryComplete = ({
  action,
  label,
  dimensions,
  objectType,
}: AnalyticsRequest): void => {
  if (!action) return;
  track({ category: 'Entry Complete', action, label, dimensions, objectType });
};

export const trackClick = ({
  action,
  label: labelProp,
  dimensions,
  objectType,
  traceId,
}: AnalyticsRequest): void => {
  if (!action || !labelProp) return;

  let label = labelProp;
  // We want to send 1/0 to analytics not true/false
  if (labelProp === 'true') {
    label = 1;
  } else if (labelProp === 'false') {
    label = 0;
  }

  track({ category: 'Click', action, label, dimensions, objectType, traceId });
};
export const trackHover = ({
  action,
  label = '',
  dimensions,
  objectType,
}: AnalyticsRequest): void => {
  if (!action || !label) return;
  track({ category: 'Hover', action, label, dimensions, objectType });
};

export const useTrackHover = (eventName: string): MouseOptions => {
  const [hoverTimer, setHoverTimer] = useState(Date.now());

  const handleMouseEnter = useEvent((): void => {
    setHoverTimer(Date.now());
  });

  const handleMouseLeave = useEvent((): void => {
    const duration = Math.floor((Date.now() - hoverTimer) / 1000);
    if (duration > 1) {
      trackHover({
        action: eventName,
        label: duration,
      });
    }
  });

  return {
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
  };
};

export const trackDefault = ({ action, label, dimensions, objectType }: AnalyticsRequest): void => {
  if (!action || !label) return;
  track({ category: 'Default', action, label, dimensions, objectType });
};

export const trackError = ({ action, label }: AnalyticsErrorRequest): void => {
  if (!action || !label) return;
  track({ category: 'Error', action, label });
};

export const trackErrorBoundary = ({ label }: AnalyticsRequest): void => {
  if (!label) return;
  track({ category: 'PageView', action: '', label });
};

export const logPageView = (path: string, title?: string): void => {
  if (env.ecpDalAnalyticsEnabled) {
    logDalPageView(path, title);
  }

  logGaPageView(path, title);
};

const metrics: Metric[] = [];
let hasTrackPageRenderSent = false;
export const trackPageRender = (request?: Omit<AnalyticsRequest, 'action'>): void => {
  if (hasTrackPageRenderSent) return;

  track({
    action: 'render',
    category: 'page_render',
    eventDetails: metrics.reduce((acc: Record<string, number>, current) => {
      acc[current.name.toLowerCase()] = Math.round(current.value);

      return acc;
    }, {}),
    objectType: 'page',
    ...request,
  });
  hasTrackPageRenderSent = true;
};

const renderMetricNames: Metric['name'][] = ['FCP', 'FID', 'LCP', 'TTFB'];
const handleRenderMetricEvent = (metric: Metric): void => {
  const metricExists = metrics.some((existingMetric) => metric?.name === existingMetric.name);
  if (metricExists) return;

  metrics.push(metric);

  const allMetricsReady = renderMetricNames.every((name) =>
    metrics.some((metric) => name === metric.name),
  );
  if (allMetricsReady) {
    trackPageRender();
  }
};

const initializePageRenderMetrics = (): void => {
  onFID(handleRenderMetricEvent);
  onLCP(handleRenderMetricEvent);
  onTTFB(handleRenderMetricEvent);

  // FCP we get on all browsers, so if we haven't gotten the rest of the metrics after 20s (user didn't interact, browser doesn't support, etc), send the event
  // no good way to detect proper browser support per metric - https://github.com/GoogleChrome/web-vitals/issues/37
  onFCP((metric) => {
    handleRenderMetricEvent(metric);
    setTimeout(trackPageRender, 20_000);
  });
};

let initialized = false;

export const initialize = (
  gtmId: string,
  runtimeEnv?: typeof env.runtimeEnv,
  expId?: string,
  applicationId?: string,
): void => {
  if (initialized) return;

  if (env.ecpDalAnalyticsEnabled) {
    initializeDalAnalytics(applicationId, runtimeEnv, expId);
  }

  initializeGa(gtmId, runtimeEnv, expId);
  initializePageRenderMetrics();
  initialized = true;
};
