import { datadogLog } from '@ecp/utils/logger';
import { useEffectOnce } from '@ecp/utils/react';

import { addConversationStartedListener } from '@ecp/features/shared/chat';

export const logToDatadog = async (): Promise<void> => {
  datadogLog({
    logType: 'info',
    message: 'New Conversation Started',
    context: {
      logOrigin: 'libs/features/servicing/shared/state/src/chat/useLog.ts',
      functionOrigin: 'logToDatadog',
      view: 'ChatView',
    },
  });
};

export const useLog = (): void => {
  useEffectOnce(() => {
    addConversationStartedListener(logToDatadog);
  });
};
