import { useCallback } from 'react';

import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import {
  FullScreenModal,
  LoadingOverlay,
  ModalInterrupter,
} from '@ecp/features/servicing/shared/components';
import {
  MODAL_FLOW,
  MODAL_STEP,
  QUERY_PARAMS,
  useModalPath,
} from '@ecp/features/servicing/shared/routing';
import type { UseUpdateVinReturn } from '@ecp/features/servicing/shared/state';
import {
  getProductLineFromPolicyResponse,
  isInPendingCancelStatus,
  isLegacyPolicy,
  usePaymentInfo,
  usePolicy,
  useSharedState,
} from '@ecp/features/servicing/shared/state';

import { EditVinConfirmation } from '../EditVinConfirmation';
import { EditVinEdit } from '../EditVinEdit';

const title = 'Edit VIN';

const EditVinModalDisabledContent: React.FC = () => {
  const modalPath = useModalPath();

  return (
    <FullScreenModal title={title} onCloseModal={modalPath.reset}>
      <p>Edit Vin is currently disabled</p>
    </FullScreenModal>
  );
};

const EditVinModalContent: React.FC = () => {
  const modalPath = useModalPath();

  const [sharedState] = useSharedState<UseUpdateVinReturn>(modalPath.sharedStateKey);
  const policyNumber = modalPath.policyNumber;
  const {
    paymentInfo: { billingAccount, policyData },
    isLoading: isLoadingPaymentInfo,
  } = usePaymentInfo({ policyNumber });
  const productLine = getProductLineFromPolicyResponse(policyData);

  const isPendingCancelled = isInPendingCancelStatus({
    billingResponse: billingAccount,
    productLine,
    policySource: policyData?.policy.sourceSystemName,
  });
  const showInterrupterScreen = policyData?.isInBookRoll || isPendingCancelled;
  let body = null;

  const ensureSharedState = useCallback(() => {
    // ensure if we are expecting shared state, but we don't have it, close the modal.
    if (!sharedState) {
      modalPath.reset();
    }

    return !!sharedState;
  }, [modalPath, sharedState]);

  if (showInterrupterScreen) body = <ModalInterrupter {...modalPath} />;
  else
    switch (modalPath.currentStep) {
      case MODAL_STEP.MODAL_EDIT:
        body = <EditVinEdit {...modalPath} />;
        break;
      case MODAL_STEP.MODAL_CONFIRMATION:
        body = ensureSharedState() ? <EditVinConfirmation {...modalPath} /> : null;
        break;
      default:
        body = <p>Default</p>;
    }

  if (isLoadingPaymentInfo) return <LoadingOverlay />;

  return (
    <FullScreenModal title={title} onCloseModal={modalPath.reset}>
      {body}
    </FullScreenModal>
  );
};

const EditVinModalIfEligible: React.FC = () => {
  const modalPath = useModalPath();
  const vehicleIdParam = modalPath.getSearchParam(QUERY_PARAMS.VEHICLE_ID);

  const policyResponse = usePolicy(modalPath.policyNumber, { throwOnError: false });
  const productLine = getProductLineFromPolicyResponse(policyResponse.policyData);
  const isClassic = isLegacyPolicy(productLine, policyResponse.policyData?.policy.sourceSystemName);

  if (
    !!vehicleIdParam &&
    !!modalPath.policyNumber &&
    flagValues[FeatureFlags.EDIT_VIN_CLASSIC] &&
    isClassic
  )
    return <EditVinModalContent />;
  else if (
    !!vehicleIdParam &&
    !!modalPath.policyNumber &&
    !flagValues[FeatureFlags.EDIT_VIN_CLASSIC] &&
    modalPath.currentFlow === MODAL_FLOW.EDIT_VIN &&
    isClassic
  )
    return <EditVinModalDisabledContent />;
  else return null;
};

export const EditVinModal: React.FC = () => {
  const modalPath = useModalPath();

  if (modalPath.currentFlow === MODAL_FLOW.EDIT_VIN) return <EditVinModalIfEligible />;
  else return null;
};
