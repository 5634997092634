import { useQuery, useQueryClient } from '@tanstack/react-query';

import type {
  SharedStateRequestInitialValue,
  SharedStateRequestKey,
  SharedStateResponse,
} from '@ecp/features/servicing/shared/types';

import { QUERY_KEYS } from '../constants';

export const useSharedState = <TData = never>(
  key: SharedStateRequestKey,
  initialValue?: SharedStateRequestInitialValue<TData>,
): SharedStateResponse<TData> => {
  const queryClient = useQueryClient();
  const sharedStateKey = [QUERY_KEYS.SHARED, key];
  const { data: state } = useQuery({
    queryKey: sharedStateKey,
    queryFn: () => queryClient.getQueryData(sharedStateKey),
    initialData: initialValue ?? null,
  });

  const setState = (value: TData | undefined | null): TData | undefined | null =>
    queryClient.setQueryData(sharedStateKey, value);

  return [state as TData, setState];
};
