import { useCallback, useRef, useState } from 'react';

import { CircularProgress, Stack } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { maskEmail } from '@ecp/utils/common';
import { scrollToTop } from '@ecp/utils/web';

import { Alert, Button } from '@ecp/components';
import { PaperlessModal } from '@ecp/features/servicing/preferences';
import {
  Card,
  ContactUsMicro,
  DashboardCard,
  DocumentDeliveryCard,
  Section,
} from '@ecp/features/servicing/shared/components';
import { QUERY_PARAMS } from '@ecp/features/servicing/shared/routing';
import {
  isPolicyAllowedToSetPaperless,
  usePaperless,
  usePreferencesForPolicyNumbers,
  useUserPolicies,
} from '@ecp/features/servicing/shared/state';
import type { UiPolicy } from '@ecp/features/servicing/shared/types';
import { useScrollToRef } from '@ecp/features/servicing/shared/util';

import { DisplayEmail, PasswordChange, UpdateEmail, VerifyCode } from '../components';
import { useStyles } from './ProfilePage.styles';

export type EmailSection = 'Current Email' | 'Update Email' | 'Verify Code';

export const ProfilePage: React.FC = () => {
  const [emailSection, setEmailSection] = useState<EmailSection>('Current Email');
  const [newEmail, setNewEmail] = useState('');
  const { classes } = useStyles();
  const {
    user,
    policies,
    isLoading: isLoadingUserPolicies,
  } = useUserPolicies({ throwOnPolicyError: false });
  const email = maskEmail(user?.email);
  const policiesAllowedToSetPaperless = policies.filter((policy) => {
    const { policyAllowedToSetPaperless } = isPolicyAllowedToSetPaperless(policy);

    return policyAllowedToSetPaperless;
  });

  const policiesAllowedToUnsetPaperless = policies.filter((policy) => {
    const { policyAllowedToUnsetPaperless } = isPolicyAllowedToSetPaperless(policy);

    return policyAllowedToUnsetPaperless;
  });

  const policNumbers = policiesAllowedToUnsetPaperless.map((policy) => {
    return policy.policy.policyNumber;
  });

  const { preferenceResults, isLoading: isLoadingPreferences } =
    usePreferencesForPolicyNumbers(policNumbers);

  const policiesAllowedToUnsetPaperlessAndIsPaperless = policiesAllowedToUnsetPaperless.filter(
    (policy) => {
      return preferenceResults.find((result) => result.policyNumber === policy.policy.policyNumber)
        ?.isPaperlessOn;
    },
  );

  const { isAnyActivePolicyNotAllowingPaperless, isLoading: isLoadingPaperless } = usePaperless({
    throwOnError: false,
  });

  const isLoading = isLoadingPaperless || isLoadingPreferences || isLoadingUserPolicies;

  const combinedList: UiPolicy[] = [];
  combinedList.push(...policiesAllowedToUnsetPaperlessAndIsPaperless);
  if (!isAnyActivePolicyNotAllowingPaperless) combinedList.push(...policiesAllowedToSetPaperless);

  const distinctList = [...new Set(combinedList)];

  const starsPlaceholder = '********';
  const [showAlert, setAlert] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState<React.ReactNode>(
    "You've successfully changed your email.",
  );

  const ref = useRef<HTMLDivElement>(null);
  useScrollToRef(isLoading, ref, QUERY_PARAMS.PREFERENCES);

  const passwordClickHandler = useCallback(async () => {
    setShowPassword(!showPassword);
  }, [setShowPassword, showPassword]);

  const handleAlert = (value: React.ReactNode | undefined): void => {
    setShowPassword(false);
    if (value) {
      setAlert(true);
      setMessage(value);
      setTimeout(() => {
        setAlert(false);
      }, 30000);
    } else setAlert(false);
  };

  const handleCloseAlert = useCallback<
    NonNullable<React.ComponentProps<typeof Alert>['onClose']>
  >(() => {
    setAlert(false);
  }, []);

  const handleSuccess = useCallback((value: React.ReactNode) => {
    handleAlert(value);
    setShowSuccess(true);
    scrollToTop();
  }, []);

  const handleError = useCallback((value: React.ReactNode) => {
    handleAlert(value);
    setShowSuccess(false);
    scrollToTop();
  }, []);

  const header = (
    <Stack spacing={{ xs: 1.75, sm: 1.75, md: 1.5 }}>
      {showAlert && (
        <Alert
          withIcon
          withAction
          type={showSuccess ? 'success' : 'error'}
          onClose={handleCloseAlert}
        >
          {message}
        </Alert>
      )}
      <h1>Manage profile</h1>
    </Stack>
  );

  let emailSectionToBeDisplayed;
  switch (emailSection) {
    case 'Current Email':
      emailSectionToBeDisplayed = <DisplayEmail setEmailSection={setEmailSection} />;
      break;
    case 'Update Email':
      emailSectionToBeDisplayed = (
        <UpdateEmail
          setEmailSection={setEmailSection}
          setNewEmail={setNewEmail}
          onSuccess={handleSuccess}
          onError={handleError}
        />
      );
      break;
    case 'Verify Code':
      emailSectionToBeDisplayed = (
        <VerifyCode
          newEmail={newEmail}
          setEmailSection={setEmailSection}
          onSuccess={handleSuccess}
          onError={handleError}
        />
      );
      break;
  }

  const body = (
    <Stack spacing={4}>
      <Section title='Personal information'>
        <div className={classes.cards}>
          <Card
            body={
              <Stack spacing={3}>
                <h3>User ID to sign-in</h3>
                <Stack spacing={0.5}>
                  <p className={classes.label}>Current email</p>
                  <p className={classes.info}>{email}</p>
                </Stack>
                {emailSectionToBeDisplayed}
              </Stack>
            }
          />

          <Card
            body={
              <Stack spacing={3}>
                <h3>Password</h3>
                {!showPassword && (
                  <>
                    <Stack spacing={0.5}>
                      <p className={classes.label}>Current password</p>
                      <p className={classes.info}>{starsPlaceholder}</p>
                    </Stack>
                    <div>
                      <Button
                        className={classes.editButton}
                        variant='outlinePrimary'
                        onClick={passwordClickHandler}
                        data-testid='EditPassword'
                        trackingName='password_edit_button'
                        trackingLabel={GoogleAnalyticsLabels.CONTINUE}
                      >
                        Edit
                      </Button>
                    </div>
                  </>
                )}
                {showPassword && <PasswordChange onSuccess={handleSuccess} onError={handleError} />}
              </Stack>
            }
          />
        </div>
      </Section>
      {((!isAnyActivePolicyNotAllowingPaperless && !!policiesAllowedToSetPaperless.length) ||
        !!policiesAllowedToUnsetPaperlessAndIsPaperless.length) && (
        <Section title='Account settings'>
          <Card
            body={
              <Stack spacing={3}>
                <h2>Document delivery</h2>
                {isLoading ? (
                  <div className={classes.spinner}>
                    <CircularProgress aria-label='Loading...' />
                  </div>
                ) : (
                  <ul className={classes.cards}>
                    {distinctList.map((policy) => {
                      const preferenceResult = preferenceResults.find(
                        (result) => result.policyNumber === policy.policy.policyNumber,
                      );

                      return (
                        <li key={policy?.policy.policyNumber}>
                          <DocumentDeliveryCard
                            policy={policy}
                            isPaperlessOn={preferenceResult?.isPaperlessOn}
                            isError={preferenceResult?.isError}
                          />
                        </li>
                      );
                    })}
                  </ul>
                )}
                <p>
                  When you opt into paperless, documents will be available to view and download
                  online. Notifications about new documents will be sent by email. By law, you may
                  still receive certain documents sent through the mail.
                </p>
              </Stack>
            }
          />
        </Section>
      )}
      <ContactUsMicro />
    </Stack>
  );

  return (
    <DashboardCard>
      <Stack spacing={{ sm: 3, md: 4 }}>
        {header}
        {body}
      </Stack>
      <PaperlessModal />
    </DashboardCard>
  );
};
