import { Stack, Typography } from '@mui/material';

import { Alert } from '@ecp/components';
import { NavLink } from '@ecp/features/servicing/shared/components';
import { PAGE_PATH } from '@ecp/features/servicing/shared/routing';

import { RecoveryLinks } from '../RecoverLinks';

export interface InvalidLoginProps {
  title: string;
}

export const InvalidLogin: React.FC<InvalidLoginProps> = (props) => {
  const { title } = props;

  return (
    <Alert type='error' withIcon>
      <Stack spacing={3}>
        <Stack>
          <Typography variant='body1Bold'>{title}</Typography>
          <span>Looks like you're having trouble logging in.</span>
          <RecoveryLinks />
        </Stack>
        <div>
          If this is your first time on our new site your old log in won't work and you'll need to{' '}
          <NavLink
            to={PAGE_PATH.CREATE_ACCOUNT}
            trackingName='create_account_link'
            trackingLabel='create_account'
          >
            create a new account
          </NavLink>
          .
        </div>
      </Stack>
    </Alert>
  );
};
