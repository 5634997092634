import { useMemo } from 'react';

import { CircularProgress, Divider, Stack } from '@mui/material';

import { DateConstants, formatDate } from '@ecp/utils/date';

import type { GetPaymentOption, UiPolicy } from '@ecp/features/servicing/shared/types';

import { PolicyCard } from '../PolicyCard';
import { useStyles } from './PaymentDetails.styles';

export interface PaymentDetailsProps {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  policyResponse?: UiPolicy;
  paymentAmount?: string;
  billingPlan?: string;
  nextPaymentDate?: string;
  nextPaymentDateDescription?: string;
  paymentAccountMethod?: GetPaymentOption;
  footer?: string | React.ReactElement;
  payEnrollConfirm?: boolean;
  isLoading?: boolean;
  isManageAutopay?: boolean;
}

export const PaymentDetails: React.FC<PaymentDetailsProps> = (props) => {
  const {
    policyResponse,
    paymentAmount,
    billingPlan,
    nextPaymentDate,
    nextPaymentDateDescription,
    paymentAccountMethod,
    footer,
    payEnrollConfirm,
    isLoading,
    isManageAutopay,
  } = props;
  const { classes } = useStyles(undefined, { props });

  const paymentMethodType =
    paymentAccountMethod?.type === 'EFT' ? 'Bank account' : paymentAccountMethod?.cardType;
  const paymentMethodNumber = useMemo(() => {
    if (paymentAccountMethod?.type === 'CC') return ` (...${paymentAccountMethod?.ccToken})`;
    else if (paymentAccountMethod?.type === 'EFT')
      return ` (...${paymentAccountMethod?.accountNumber})`;
    else return '';
  }, [paymentAccountMethod]);

  if (isLoading) return <CircularProgress aria-label='Loading...' />;

  return (
    <PolicyCard
      policyDetails={policyResponse}
      className={classes.root}
      body={
        <Stack spacing={1.5}>
          <Stack spacing={1} divider={<Divider />} className={classes.cardBody}>
            {paymentAmount && (
              <Stack spacing={1} className={classes.gridItem} direction='row'>
                <p className={classes.info}>Payment amount</p>
                <p className={classes.data}>{paymentAmount}</p>
              </Stack>
            )}
            <Stack spacing={1} className={classes.gridItem} direction='row'>
              <p className={classes.info}>Payment frequency</p>
              <p className={classes.data}>{billingPlan}</p>
            </Stack>
            {(nextPaymentDate || nextPaymentDateDescription) && (
              <Stack spacing={1} className={classes.gridItem} direction='row'>
                <p className={classes.info}>
                  {isManageAutopay ? 'Next payment date' : 'First payment date'}
                </p>
                <p className={classes.data}>
                  {nextPaymentDate
                    ? formatDate(nextPaymentDate, DateConstants.MONTH_FULL_NAME_LONG_FORMAT)
                    : nextPaymentDateDescription}
                </p>
              </Stack>
            )}
            <Stack spacing={1} className={classes.gridItem} direction='row'>
              <p className={classes.info}>Payment method</p>
              <p className={classes.data}>
                <span className={classes.method}>{paymentMethodType}</span>
                <span className={classes.account}>{paymentMethodNumber}</span>
              </p>
            </Stack>
            {!payEnrollConfirm && footer}
          </Stack>
          {payEnrollConfirm && <div className={classes.footer}>{footer}</div>}
        </Stack>
      }
    />
  );
};
