import * as yup from 'yup';

import type { YupSchemaMap } from '@ecp/utils/form';

export interface AutopayEnrollPaymentFrequencyFormInputs {
  paymentFrequency: string;
}

export const autopayEnrollPaymentFrequencySchema: YupSchemaMap<AutopayEnrollPaymentFrequencyFormInputs> =
  {
    paymentFrequency: yup.string().required('Payment frequency is required'),
  };
