import { useCallback } from 'react';

import { CircularProgress, Stack } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { maskEmail } from '@ecp/utils/common';
import { Form, useField, useForm } from '@ecp/utils/form';

import { Button, NumberFormat } from '@ecp/components';
import { LoadingButton } from '@ecp/features/servicing/shared/components';
import { useChangeEmail, useUser } from '@ecp/features/servicing/shared/state';
import type { ChangeEmailRequest } from '@ecp/features/servicing/shared/state';

import type { EmailSection } from '../../pages/ProfilePage';
import { verifyCodeSchema } from './VerifyCode.schema';
import { useStyles } from './VerifyCode.styles';

export interface VerifyCodeProps {
  setEmailSection: (emailSection: EmailSection) => void;
  onSuccess?(value: string): void;
  onError?(value: string): void;
  newEmail: string;
}

export const VerifyCode: React.FC<VerifyCodeProps> = (props) => {
  const { setEmailSection, onSuccess, onError, newEmail } = props;
  const { classes } = useStyles();
  const formContext = useForm({
    validations: verifyCodeSchema,
  });
  const { handleSubmit } = formContext;
  const codeField = useField({ name: 'code', formContext });
  const { changeEmail } = useChangeEmail();
  const { user, refetch, isLoading: isLoadingUser } = useUser();

  const onSubmit = useCallback(() => {
    handleSubmit(async () => {
      // TODO Update this call along with screens upade to hide security code.
      const changeEmailRequest: ChangeEmailRequest = {
        email: newEmail,
        userId: user?.userId || '',
      };
      const { error, success } = await changeEmail(changeEmailRequest);
      if (error) {
        onError?.(
          'We apologize, but we encountered an error while updating your user ID. Please try again now, or wait a few minutes and try again later.',
        );
      } else if (success) {
        refetch();
        onSuccess?.('Successfully updated the User ID for sign-in.');
        setEmailSection('Current Email');
      }
    })();
  }, [
    newEmail,
    user?.userId,
    onError,
    handleSubmit,
    setEmailSection,
    onSuccess,
    changeEmail,
    refetch,
  ]);

  const onCancelClick = useCallback((): void => {
    setEmailSection('Current Email');
  }, [setEmailSection]);

  if (isLoadingUser) return <CircularProgress aria-label='Loading...' />;

  return (
    <Form
      onSubmit={onSubmit}
      formProviderProps={formContext}
      className={classes.root}
      name='verifyCodeForm'
    >
      <Stack spacing={3}>
        <p className={classes.info}>
          A 6 digit code has been emailed to{' '}
          <strong>{maskEmail(newEmail)}. It will expire in 5 minutes.</strong>
        </p>
        <NumberFormat
          {...codeField}
          groupLabel='Enter code (6 digits)'
          ariaLabel='6 digits code'
          format='######'
          className={classes.textInput}
          trackingName='enter_code'
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
        />
        <Stack spacing={3} direction='row'>
          <LoadingButton
            type='submit'
            variant='primary'
            trackingName='verify_code_button'
            trackingLabel='verify_continue'
          >
            Verify the code
          </LoadingButton>
          <Button
            variant='iconText'
            onClick={onCancelClick}
            trackingName='cancel_verify_code_link'
            trackingLabel='cancel_verify_code_continue'
          >
            Cancel
          </Button>
        </Stack>
        <Stack spacing={0.5}>
          <p className={classes.infoTitle}>Important</p>
          <p className={classes.info}>
            This change will only update the email address used to sign-in or recovery of this
            account. The update will not change how you receive communications for policies. Please
            contact us to make updates to additional email addresses for your policies.
          </p>
        </Stack>
      </Stack>
    </Form>
  );
};
