import { useCallback, useEffect } from 'react';

import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';

import { usePageFlow } from '@ecp/features/servicing/shared/routing';

import { ErrorFallback } from './ErrorFallback';
import { useIsErrorState } from './useIsErrorState';

export const AppErrorBoundary: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;

  const { reset: resetReactQuery } = useQueryErrorResetBoundary();
  const { navigate } = usePageFlow();

  const { errorHashName, isErrorState, setIsErrorState } = useIsErrorState();

  const { hash, pathname, search } = useLocation();

  const removeHash = useCallback(() => {
    if (hash === errorHashName) {
      const newUrl = `${window.location.pathname}${window.location.search || ''}`;
      navigate(newUrl, { replace: true });
    }
  }, [errorHashName, hash, navigate]);

  // remove param on browser refresh or browser back/forward
  useEffect(() => {
    if (!isErrorState) {
      removeHash();
    }
  }, [isErrorState, removeHash]);

  const handleReset = useCallback(() => {
    resetReactQuery();
    removeHash();
    setIsErrorState(false);
  }, [removeHash, resetReactQuery, setIsErrorState]);

  const handleError = useCallback(() => {
    const newUrl = `${pathname}${search || ''}${errorHashName}`;
    navigate(newUrl, { replace: true });
    setIsErrorState(true);
  }, [errorHashName, navigate, pathname, search, setIsErrorState]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={handleReset} onError={handleError}>
      {children}
    </ErrorBoundary>
  );
};
