import { useEffect, useState } from 'react';

import * as interactionId from './interactionId';

/** Update a component whenever interaction id changes. */
export const useInteractionId = (): typeof import('./interactionId') => {
  const [, setForceUpdate] = useState(false);
  useEffect(() => {
    const listener = (): void => {
      setForceUpdate(true);
      setTimeout(() => setForceUpdate(false), 0);
    };
    interactionId.addEventListener('change', listener);

    return () => interactionId.removeEventListener('change', listener);
  }, []);

  return interactionId;
};
