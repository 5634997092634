import { cloneElement, forwardRef, useRef } from 'react';

import type { ButtonProps as MuiButtonProps } from '@mui/material';
import { CircularProgress, Fab, Button as MuiButton } from '@mui/material';
import { CSSTransition } from 'react-transition-group';

import { withAnalytics } from '@ecp/utils/analytics/tracking';

import { useStyles } from './Button.styles';

export type ButtonVariant =
  | 'primary'
  | 'success'
  | 'outlinePrimary'
  | 'outlineSecondary'
  | 'secondary'
  | 'iconText'
  | 'iconTextExtraLarge'
  | 'iconTextLarge'
  | 'iconTextMedium'
  | 'iconTextSmall'
  | 'danger'
  | 'text'
  | 'textExtraLarge'
  | 'textLarge'
  | 'textMedium'
  | 'textSmall';

type Props = React.PropsWithChildren<
  Omit<MuiButtonProps, 'variant'> & {
    icon?: React.ReactElement;
    iconLast?: boolean;
    isProcessing?: boolean;
    variant?: ButtonVariant;
    target?: string;
  }
>;

export type ButtonProps = React.ComponentPropsWithRef<typeof Button>;

const ICON_TEXT_VARIANTS = new Set<ButtonVariant>([
  'iconText',
  'iconTextExtraLarge',
  'iconTextLarge',
  'iconTextMedium',
  'iconTextSmall',
  'text',
  'textExtraLarge',
  'textLarge',
  'textMedium',
  'textSmall',
]);

export const Button = withAnalytics<Props>(
  forwardRef((props, ref) => {
    const {
      children,
      classes: muiClasses,
      className,
      disabled,
      fullWidth,
      icon,
      iconLast,
      isProcessing,
      variant,
      target,
      onClick,
      ...rest
    } = props;
    const { classes, cx } = useStyles();
    const processingRef = useRef();

    const iconEl =
      icon && cloneElement(icon, { className: cx(classes.icon, icon.props.className) });
    const iconProps = iconLast ? { endIcon: iconEl } : { startIcon: iconEl };

    const processingEl = (
      <CSSTransition
        in={isProcessing}
        timeout={300}
        unmountOnExit
        classNames='processing-transition'
        nodeRef={processingRef}
      >
        <CircularProgress
          color='inherit'
          size={20}
          className={classes.processingIcon}
          aria-label='Loading...'
          ref={processingRef}
        />
      </CSSTransition>
    );

    const commonProps: Pick<
      Props,
      'className' | 'classes' | 'color' | 'disabled' | 'ref' | 'onClick' | 'target'
    > = {
      className: cx(
        classes.root,
        variant && classes[variant],
        ICON_TEXT_VARIANTS.has(variant) && classes.iconTextBase,
        isProcessing && classes.processing,
        className,
      ),
      classes: { disabled: classes.disabled, ...muiClasses },
      color: 'primary',
      disabled: disabled || isProcessing,
      ref,
      target,
      onClick,
    };

    switch (variant) {
      case 'primary':
      case 'success':
      case 'danger':
      case 'secondary':
        return (
          <Fab {...rest} {...commonProps} variant='extended'>
            {processingEl}
            {children}
            {iconEl}
          </Fab>
        );
      case 'iconText':
      case 'iconTextExtraLarge':
      case 'iconTextLarge':
      case 'iconTextMedium':
      case 'iconTextSmall':
      case 'text':
      case 'textExtraLarge':
      case 'textLarge':
      case 'textMedium':
      case 'textSmall':
      case 'outlinePrimary':
      default:
        return (
          <MuiButton
            {...rest}
            {...commonProps}
            fullWidth={fullWidth}
            variant={variant === 'outlinePrimary' ? 'outlined' : undefined}
            {...iconProps}
          >
            {processingEl}
            {children}
          </MuiButton>
        );
    }
  }),
  'button',
);
