import { Stack } from '@mui/material';

import { DisclosureWrapper } from '../../../components';

export const ClaimsTextingConnect: React.FC = () => {
  return (
    <DisclosureWrapper
      title='Claims Texting Terms and Conditions'
      breadcrumbTitleOverrides={{ claimstexting: 'Claims Texting' }}
    >
      <Stack spacing={4}>
        <Stack spacing={1}>
          <h2>GENERAL INFORMATION</h2>
          <p>
            American Family Connect Property and Casualty Insurance Company and American Family
            Connect Insurance Company, (collectively, "CONNECT by American Family") would like to
            welcome you to our text messaging program for claims services performed by AFICS, Inc.
            (AFICS).&nbsp; Hi Marley is a third-party vendor that will provide you with information
            regarding your claim via text messaging.&nbsp; This has no impact on your policy or
            coverage as it may relate to a claim.&nbsp;
          </p>
          <p>
            Hi Marley does not have a separate charge for this service, but{' '}
            <strong>message and data rates may apply</strong> from your mobile carrier.&nbsp;By
            providing your consent to participate in this program, the account holder approves any
            such charges from their mobile carrier.&nbsp; You may contact us at any time to change
            these preferences.&nbsp;
          </p>
          <p>
            If you have provided us with your mobile number, we have your consent to send you
            automated texts to service your claim. Your consent allows us to use text messaging for
            information and claim servicing.&nbsp; You are not required to consent to the agreement
            as a condition of purchasing any property, goods, or services.&nbsp;
          </p>
        </Stack>
        <Stack spacing={1}>
          <h2>HOW TO OPT-IN</h2>
          <p>
            You may sign up for the Hi Marley text messaging program by responding 'Yes' to the
            welcome message that will be sent from your claims adjuster.
          </p>
          <p>
            By opting-in to this service, the number of text messages received and sent may vary
            depending on account activity and your participation in this text messaging program.
          </p>
          <p>
            After signing up, you will receive an invitation text message from Hi Marley asking you
            to confirm your enrollment request. The message states: "Hi {'{FirstName}'}, I'm Marley,
            your virtual assistant on behalf of CONNECT by American Family.&nbsp;To text with your
            claim representative, please review and agree to the terms/privacy policy
            (himarley.com/tp) by respond 'Yes.'&nbsp; Msg and data rates may apply. Msg freq
            varies.&nbsp; Reply HELP for help or STOP to opt out."
          </p>
        </Stack>
        <Stack spacing={1}>
          <h2>HOW TO OPT-OUT</h2>
          <p>
            To stop receiving text messages reply <strong>STOP</strong>. You can also notify your
            claims adjuster directly, and they will remove you from texting.&nbsp; You may receive a
            confirmation of your request.&nbsp;
          </p>
          <p>
            <strong>
              By enrolling in this subscription program, you consent that following such a request
              to unsubscribe,
            </strong>{' '}
            you may receive a standard rate message stating: "First Name, you successfully
            unsubscribed from Marley Texting.&nbsp; If you change your mind, simply text 'YES' to
            reactivate at any time."
          </p>
          <p>
            We reserve the right to terminate this text messaging service, in whole or in part, at
            any time without notice. The information in any text message may be subject to certain
            time lags and/or delays.&nbsp;
          </p>
          <h2>SUPPORTED CARRIERS&nbsp;</h2>
          <p>Hi Marley will support all mobile carriers based on their ability to support SMS.</p>
        </Stack>
        <Stack spacing={1}>
          <h2>INTERRUPTION</h2>
          <p>
            Delivery of information and content to your equipment/mobile device may fail due to a
            variety of circumstances or conditions. The Hi Marley program is subject to transmission
            limitation or interruption. You understand and acknowledge that mobile network services
            are outside of&nbsp;CONNECT by American Family or AFICS's control, and&nbsp;they are not
            responsible or liable for issues arising therefrom, or the failure thereof, including,
            without limitation, technical, hardware, software, electronic, network, telephone or
            other communications malfunctions, errors or failures of any kind, errors in
            transmission, traffic congestion, lost or unavailable network connections, telephone
            connections, wireless phone connections, website, Internet, or ISP availability,
            unauthorized human intervention, traffic congestion, incomplete or inaccurate capture of
            entry information (regardless of cause) or failed, incomplete, garbled, jumbled or
            delayed transmissions which may limit or restrict your ability to receive or send a
            message, including any injury or damage to your or any other person's equipment/wireless
            device relating to or resulting from participating in or using the Hi Marley program. If
            the Hi Marley program is not available within your intended location, you agree that
            your sole remedy is to cease using the Hi Marley program.
          </p>
        </Stack>
        <Stack spacing={1}>
          <h2>REVISIONS</h2>
          <p>
            CONNECT by American Family may revise, modify, or amend these Terms &amp; Conditions at
            any time. Any such revision, modification, or amendment shall take effect when it is
            posted to&nbsp;CONNECT by American Family's website. You agree to review these Terms
            &amp; Conditions periodically to ensure that you are aware of any changes. Your
            continued consent to receive text messages will indicate your acceptance of those
            changes.&nbsp;
          </p>
        </Stack>
        <Stack spacing={1}>
          <h2>PRIVACY</h2>
          <p>
            All information collected from users of our sites is subject to our Privacy Policy,
            which is incorporated by reference into this agreement. For more information, visit
            connectbyamfam.com/privacy to review our policy.&nbsp;
          </p>
        </Stack>
        <Stack spacing={1}>
          <h2>APPLICABLE LAW</h2>
          <p>The terms of use shall be governed in accordance with the laws of the Wisconsin.</p>
        </Stack>
        <Stack spacing={1}>
          <h2>LIMITATION OF LIABILITY</h2>
          <p>
            By agreeing to these Terms and Conditions, you acknowledge and understand
            that&nbsp;CONNECT by American Family has engaged a third-party vendor to administer the
            texting program contemplated by these Terms and Conditions.&nbsp; CONNECT by American
            Family is not responsible for, and has no control over, any third-party vendor actions
            or omissions, intentional or unintentional.
          </p>
        </Stack>
      </Stack>
    </DisclosureWrapper>
  );
};
