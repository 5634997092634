import { Divider, Stack } from '@mui/material';

import { Alert, PhoneLink } from '@ecp/components';
import type { GetPaymentInfoReturn } from '@ecp/features/servicing/shared/state';
import {
  getItemFromLoggedInSessionStorage,
  getPaymentStatus,
  getProductLineFromPolicyResponse,
  isInPendingCancelStatus,
} from '@ecp/features/servicing/shared/state';
import { getBrandData } from '@ecp/features/servicing/shared/util';

import { AutopayButton } from '../AutopayButton';
import { MakePaymentButton } from '../MakePaymentButton';
import { PaymentAlert } from '../PaymentAlert';
import { PaymentStatusDisplay } from '../PaymentStatusDisplay';
import { useStyles } from './PaymentStatusCardBody.styles';
interface PaymentStatusCardBodyProps {
  paymentInfo: GetPaymentInfoReturn;
  hasAnotherActivePolicy?: boolean;
}

export const PaymentStatusCardBody: React.FC<PaymentStatusCardBodyProps> = (props) => {
  const { paymentInfo, hasAnotherActivePolicy = true } = props;
  const {
    isClassicPolicy: isClassic = false,
    policyNumber = '',
    canMakePayment,
    isEscrow,
    billingAccount: billingInfo,
    policyData,
  } = paymentInfo;
  const { classes } = useStyles();

  const isPendingCancellation = isInPendingCancelStatus({
    billingResponse: billingInfo,
    productLine: getProductLineFromPolicyResponse(policyData),
    policySource: policyData?.policy.sourceSystemName,
  });
  const customerServicePhone = getBrandData(paymentInfo.policyData).mainCustServPhoneNum;
  const paymentSubmitted = getItemFromLoggedInSessionStorage('payment', policyNumber);
  const paymentStatus = getPaymentStatus(billingInfo, isPendingCancellation, !!paymentSubmitted);
  const shouldShowAlert =
    (paymentStatus === 'PastDue' || paymentStatus === 'PendingCancellation') && !paymentSubmitted;
  const shouldShowEscrowInfo =
    isEscrow && paymentStatus !== 'NoPaymentDue' && paymentStatus !== 'PaidInFull';
  const shouldShowMakePaymentButton = canMakePayment && !paymentSubmitted;
  const shouldShowButtons =
    shouldShowMakePaymentButton ||
    paymentInfo.isAutopayEligible ||
    paymentInfo.isManageAutopayEligible;

  const autoPayMessage = paymentInfo.isClassicPolicy ? (
    <>
      <Divider />
      <p>
        To {paymentInfo.isAutopayEnabled ? 'manage your' : 'enroll in'} AutoPay, contact us at{' '}
        <PhoneLink withUnderlinedLinkStyle number={customerServicePhone} />.
      </p>
    </>
  ) : (
    ''
  );

  return (
    <Stack className={classes.root} spacing={2}>
      {paymentStatus === 'PastDue' && paymentInfo.isAutopayEnabled && (
        <Alert type='error' withIcon>
          <b>Automatic payment couldn't be processed.</b> For help, please call{' '}
          <PhoneLink number={customerServicePhone} />.
        </Alert>
      )}
      {shouldShowAlert && (
        <PaymentAlert
          isEscrow={isEscrow}
          isPendingCancellation={isPendingCancellation}
          helpNumber={customerServicePhone}
        />
      )}
      <Stack spacing={1}>
        <PaymentStatusDisplay
          paymentInfo={paymentInfo}
          hasAnotherActivePolicy={hasAnotherActivePolicy}
        />
        {shouldShowEscrowInfo && <p>Billed to mortgage company</p>}
      </Stack>
      {shouldShowEscrowInfo && !shouldShowAlert && !paymentSubmitted && (
        <>
          <Divider />
          <p>
            Your policy is paid through an escrow. You can still make a payment, but you may be
            required to pay the balance in full. Contact your mortgage company with questions.
          </p>
        </>
      )}
      {shouldShowButtons && (
        <Stack spacing={1.5} direction={{ xs: 'column', md: 'row' }}>
          {shouldShowMakePaymentButton && (
            <MakePaymentButton
              policyNumber={policyNumber}
              classes={{ button: classes.borderedButton }}
              isClassic={isClassic}
              buttonName='Make payment'
            />
          )}
          {(paymentInfo.isAutopayEligible || paymentInfo.isManageAutopayEligible) && (
            <AutopayButton
              policyNumber={policyNumber}
              className={classes.borderedButton}
              isManageFlow={paymentInfo.isManageAutopayEligible}
            />
          )}
        </Stack>
      )}
      {autoPayMessage}
    </Stack>
  );
};
