import { apiRoot, buildRequestHeaders } from '../servicingApiConfig';
import type { ApiRequestOptions, ServicingResponse } from '../servicingRequest';
import { servicingRequest } from '../servicingRequest';
import type {
  ChangeEmailRequest,
  ChangeEmailSuccess,
  ChangePasswordRequest,
  ChangePasswordSuccess,
  CreatePasswordRequest,
  CreatePasswordSuccess,
  ForgotPasswordRequest,
  ForgotPasswordSuccess,
  GetUserRequest,
  GetUserSuccess,
  ValidateUserRequest,
  ValidateUserSuccess,
  VerifyOneTimeCodeRequest,
  VerifyOneTimeCodeSuccess,
  VerifyUserIdentityRequest,
  VerifyUserIdentitySuccess,
} from './types';

/** gets user's information**/
export const getUser = async (
  getUserRequest: GetUserRequest,
  apiRequestOptions?: ApiRequestOptions,
): Promise<ServicingResponse<GetUserSuccess>> => {
  const { id } = getUserRequest;
  const headers = await buildRequestHeaders({ withUserToken: true });

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-user/v1/users/${id}`,
    options: {
      headers: headers,
      method: 'GET',
    },
    ...apiRequestOptions,
  });
};

export const validateUser = async (
  validateUserRequest: ValidateUserRequest,
  apiRequestOptions?: ApiRequestOptions,
): Promise<ServicingResponse<ValidateUserSuccess>> => {
  const headers = await buildRequestHeaders({ withUserToken: false });

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-auth/v1/users/validate`,
    options: {
      headers: headers,
      method: 'POST',
      body: JSON.stringify(validateUserRequest),
    },
    ...apiRequestOptions,
  });
};

export const verifyUserIdentity = async (
  verifyUserIdentityRequest: VerifyUserIdentityRequest,
  apiRequestOptions?: ApiRequestOptions,
): Promise<ServicingResponse<VerifyUserIdentitySuccess>> => {
  const headers = await buildRequestHeaders({ withUserToken: false });

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-auth/v1/users/generateOtp`,
    options: {
      headers: headers,
      method: 'POST',
      body: JSON.stringify(verifyUserIdentityRequest),
    },
    ...apiRequestOptions,
  });
};

export const verifyOneTimeCode = async (
  verifyOneTimeCodeRequest: VerifyOneTimeCodeRequest,
  apiRequestOptions?: ApiRequestOptions,
): Promise<ServicingResponse<VerifyOneTimeCodeSuccess>> => {
  const headers = await buildRequestHeaders({ withUserToken: false });

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-auth/v1/users/verifyOTP`,
    options: {
      headers: headers,
      method: 'POST',
      body: JSON.stringify(verifyOneTimeCodeRequest),
    },
    ...apiRequestOptions,
  });
};

export const createPassword = async (
  createPasswordRequest: CreatePasswordRequest,
  apiRequestOptions?: ApiRequestOptions,
): Promise<ServicingResponse<CreatePasswordSuccess>> => {
  const headers = await buildRequestHeaders({ withUserToken: false });

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-auth/v1/users`,
    options: {
      headers: headers,
      method: 'POST',
      body: JSON.stringify(createPasswordRequest),
    },
    ...apiRequestOptions,
  });
};

export const forgotPassword = async (
  forgotPasswordRequest: ForgotPasswordRequest,
  apiRequestOptions?: ApiRequestOptions,
): Promise<ServicingResponse<ForgotPasswordSuccess>> => {
  const headers = await buildRequestHeaders({ withUserToken: false });

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-auth/v1/users/forgotPassword`,
    options: {
      headers: headers,
      method: 'POST',
      body: JSON.stringify(forgotPasswordRequest),
    },
    ...apiRequestOptions,
  });
};

export const changePassword = async (
  changePasswordRequest: ChangePasswordRequest,
  apiRequestOptions?: ApiRequestOptions,
): Promise<ServicingResponse<ChangePasswordSuccess>> => {
  const { password, newPassword, userId } = changePasswordRequest;
  const request = {
    password,
    newPassword,
  };
  const headers = await buildRequestHeaders({ withUserToken: true });

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-user/v1/users/${userId}/password`,
    options: {
      headers: headers,
      method: 'PUT',
      body: JSON.stringify(request),
    },
    ...apiRequestOptions,
  });
};

export const changeEmail = async (
  body: ChangeEmailRequest,
  apiRequestOptions?: ApiRequestOptions,
): Promise<ServicingResponse<ChangeEmailSuccess>> => {
  const { userId, email } = body;
  const headers = await buildRequestHeaders({ withUserToken: true });

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-user/v1/users/${userId}/email`,
    options: {
      headers: headers,
      method: 'PUT',
      body: JSON.stringify({ email }),
    },
    ...apiRequestOptions,
  });
};
