import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'Snackbar' })((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      top: 24,
      left: 16,
      right: 16,
      transform: 'none',
    },
  },
  content: {
    textAlign: 'center',
    minWidth: 'unset',
  },
}));
