import type { SetOptional } from 'type-fest';

import { addCustomAttributes } from './commands/customAttributes';
import type { Config } from './config';
import { getChatEnabled, getConfig, saveConfig } from './config';
import { DEFAULT_TIMEOUT, GENESYS_BOOTSTRAP_SCRIPT_URL } from './constants';
import {
  runConversationClearedListeners,
  runConversationResetListeners,
  runConversationStartedListeners,
  runMessengerOpenedListeners,
} from './listener';
import {
  subscribeToConversationClearedEvent,
  subscribeToConversationResetEvent,
  subscribeToConversationStartedEvent,
  subscribeToDatabaseReadyEvent,
  subscribeToMessengerOpenedEvent,
} from './subscriptions/subscriptions';
import type { GenesysBeforeInit } from './types';

type Params = SetOptional<Config, 'timeout'>;

/**
 * Validates input parameters and throws errors if any required parameters are missing.
 * This function is for debugging and ensuring data integrity throughout the application.
 */
const validateInputParams = (params: Params): void => {
  if (!params.deploymentId) {
    throw new Error('Chat Deployment ID is not set');
  }
  if (!params.queueName) {
    throw new Error('Chat Queue Name is not set');
  }
  if (!params.environment) {
    throw new Error('Chat Environment is not set');
  }
};

const prepareBootstrapScriptConfig = (): void => {
  const bootstrapConfig = {
    environment: getConfig().environment,
    deploymentId: getConfig().deploymentId,
    // you can also set "debug: true" to enable debug mode
    // it will log debug messages to the console including
    // the config it gets from the server, such as
    // launcher visibility
  };

  window.Genesys ||= function () {
    ((window.Genesys as GenesysBeforeInit).q =
      // Queue for temporarily storing calls until Genesys is fully initialized
      // eslint-disable-next-line prefer-rest-params
      (window.Genesys as GenesysBeforeInit).q || []).push(arguments);
  };

  (window.Genesys as GenesysBeforeInit).t = new Date().getTime();
  (window.Genesys as GenesysBeforeInit).c = bootstrapConfig;
};

const addQueueNameAttribute = (): void => {
  addCustomAttributes({
    queueName: getConfig().queueName,
  });
};

const subscribeToEvents = (): void => {
  subscribeToMessengerOpenedEvent(async () => {
    runMessengerOpenedListeners();
  });

  subscribeToConversationResetEvent(async () => {
    runConversationResetListeners();
  });

  subscribeToConversationClearedEvent(async () => {
    /**
     * Repopulate custom attributes from cache after conversation is cleared
     * Such that when next conversation is started, all attributes saved in the cache are sent to Genesys server.
     * This is important when a user closes the messenger and then reopens it immediately.
     * Since the conversation is cleared when the user closes the messenger, the custom attributes would be lost.
     */
    addCustomAttributes({});
    runConversationClearedListeners();
  });

  subscribeToConversationStartedEvent(async () => {
    runConversationStartedListeners();
  });
};

const appendScriptTag = (): HTMLScriptElement => {
  // create the script tag and add to DOM
  const script = document.createElement('script');
  script.id = 'genesys-chat';
  document.body.appendChild(script);

  return script;
};

/** Add the "src" attribute to it after a specified "timeout". */
/** This function completes its operation once the script is successfully downloaded and executed. */
const addSrcToScriptAfterTimeout = (scriptTag: HTMLScriptElement): Promise<void> => {
  return new Promise((resolve, reject) => {
    scriptTag.onload = () => {
      resolve(undefined);
    };
    scriptTag.onerror = reject;

    // add the "src" attribute with the genesys bootstrap script URL after the "timeout"
    const injectSrc = (): void => {
      setTimeout(() => {
        scriptTag.src = GENESYS_BOOTSTRAP_SCRIPT_URL;
      }, getConfig().timeout);
    };

    // if DOM is already loaded, inject src immediately
    // otherwise, inject src after the entire page loads
    if (document.readyState === 'complete') {
      injectSrc();
    } else {
      // Inject src after the entire page loads (including scripts, CSS, images for the current page)
      const handleLoad = (): void => {
        injectSrc();
        window.removeEventListener('load', handleLoad);
      };
      window.addEventListener('load', handleLoad);
    }
  });
};

const waitUntilDatabaseReady = async (): Promise<void> => {
  return new Promise((resolve) => {
    subscribeToDatabaseReadyEvent(async () => {
      resolve();
    });
  });
};

/**
 * Initializes the Genesys chat.
 * This function inserts a script tag into the DOM upon page load. It then assigns the "src" attribute with the Genesys bootstrap URL after a specified "timeout".
 *
 * @param params - Configuration parameters for Genesys chat initialization.
 * @param [params.deploymentId] - @see {@link file://./../README.md} `Configuration Values` section for more information.
 * @param [params.queueName] - @see {@link file://./../README.md} `Configuration Values` section for more information.
 * @param [params.environment] - @see {@link file://./../README.md} `Configuration Values` section for more information.
 * @param [params.timeout=6000] - The delay in milliseconds before loading the Genesys bootstrap script. Defaults to 6000 milliseconds (6 seconds).
 * @param [params.enabledCondition] - A condition that determines whether the chat should be enabled.
 * If true, the function will validate other parameters; if false, the chat initialization will be skipped, and other Genesys chat functions will become no-operations (no-op).
 */
export const initialize = async (params: Params): Promise<void> => {
  // save config to be used by other functions in this file and other files
  saveConfig({
    timeout: DEFAULT_TIMEOUT,
    ...params,
  });

  if (!getChatEnabled()) {
    return;
  }

  validateInputParams(params);

  prepareBootstrapScriptConfig();
  const tag = appendScriptTag();

  return addSrcToScriptAfterTimeout(tag)
    .then(waitUntilDatabaseReady)
    .then(subscribeToEvents)
    .then(addQueueNameAttribute);
};
