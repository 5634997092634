import type { PolicyTypeCode, ProductLine } from '@ecp/features/servicing/shared/types';
import {
  IconCardAuto,
  IconCardCondo,
  IconCardHome,
  IconCardRent,
  IconCardUmbrella,
} from '@ecp/themes/base';

import { useStyles } from './PolicyIcon.styles';
export interface PolicyIconProps {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  productLine: ProductLine;
  policyTypeCode?: PolicyTypeCode;
}

export const PolicyIcon: React.FC<PolicyIconProps> = (props) => {
  const { productLine, policyTypeCode } = props;
  const { classes } = useStyles(undefined, { props });

  const iconComponents: Record<ProductLine, React.ReactNode> = {
    AUTO: <IconCardAuto data-testid='icon-card-auto' className={classes.icon} />,

    HOME: <IconCardHome data-testid='icon-card-home' className={classes.icon} />,

    UMBRELLA: <IconCardUmbrella data-testid='icon-card-umbrella' className={classes.icon} />,
  };

  let iconComponent;
  switch (policyTypeCode) {
    case 'HO4':
      iconComponent = <IconCardRent data-testid='icon-card-rent' className={classes.icon} />;
      break;
    case 'HO6':
      iconComponent = <IconCardCondo data-testid='icon-card-condo' className={classes.icon} />;
      break;
    default:
      iconComponent = iconComponents[productLine];
      break;
  }

  return <>{iconComponent}</>;
};
