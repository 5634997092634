import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'MaintenancePage' })((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  body: {
    justifyContent: 'flex-start',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'center',
    },
  },
  textWrapper: {
    [theme.breakpoints.up('lg')]: {
      alignSelf: 'center',
    },
  },
  iconWrapper: {
    textAlign: 'center',
  },
  icon: { width: 320, height: 215 },
}));
