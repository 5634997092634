import { useCallback } from 'react';

import type { AccordionProps } from '@mui/material';
import { AccordionDetails, AccordionSummary, Accordion as MuiAccordion } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';

import { IconUIExpandMore } from '@ecp/themes/base';

import { useStyles } from './ServicingAccordion.styles';

interface ServicingAccordionProps {
  trackingName: string;
  id: string;
  accordionTitle: React.ReactElement;
  accordionDetails: React.ReactElement;
  hasDetailContent?: boolean;
  /** Accordion items expand-collapse event */
  onChange?: AccordionProps['onChange'];
  unmountOnExit?: boolean;
  expanded?: boolean;
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
}

export const ServicingAccordion: React.FC<ServicingAccordionProps> = (props) => {
  const {
    trackingName,
    id,
    accordionTitle,
    accordionDetails,
    hasDetailContent = true,
    onChange,
    unmountOnExit = false,
    expanded,
  } = props;
  const { classes } = useStyles(undefined, { props });

  const handleChange = useCallback(
    (event: React.SyntheticEvent<Element, Event>, expand: boolean) => {
      onChange?.(event, expand);
      trackClick({
        action: trackingName,
        label: expand ? 'Expand' : 'Collapse',
        objectType: 'accordion',
      });
    },
    [onChange, trackingName],
  );

  return (
    <MuiAccordion
      className={classes.root}
      expanded={expanded}
      onChange={handleChange}
      TransitionProps={{ unmountOnExit }}
    >
      <AccordionSummary
        expandIcon={hasDetailContent && <IconUIExpandMore />}
        id={`${id}_expandButton`}
        aria-controls={`${id}-content`}
        disabled={!hasDetailContent}
      >
        {accordionTitle}
      </AccordionSummary>
      {hasDetailContent && <AccordionDetails>{accordionDetails}</AccordionDetails>}
    </MuiAccordion>
  );
};
