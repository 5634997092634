import type { IPublicClientApplication, SilentRequest } from '@azure/msal-browser';

import { AuthError } from '../util';
import { type AuthUtil, AzureAuthUtil } from './util';

let instance: AzureAuthUtil;
let initialized = false;

interface AzureAuthConfig {
  msalInstance: IPublicClientApplication;
  loginRequest: SilentRequest;
}

/**
 * Initialize with config values and create AzureAuthUtil instance.
 *
 * @throws {AuthError} Calling this function more than once throws.
 */
const initialize = async ({ msalInstance, loginRequest }: AzureAuthConfig): Promise<AuthUtil> => {
  if (initialized) throw new AuthError(`${AzureAuthUtil.name} has already been intialized.`);

  await msalInstance.initialize();
  await msalInstance.handleRedirectPromise();

  instance = new AzureAuthUtil(msalInstance, loginRequest);

  initialized = true;

  return instance;
};

export { initialize, instance };
