import { Snackbar as MuiSnackbar, SnackbarContent } from '@mui/material';

import { useStyles } from './Snackbar.styles';

type VerticalPosition = 'top' | 'bottom';
type HorizontalPosition = 'left' | 'center' | 'right';

interface Props {
  message: React.ReactNode | string;
  autoHideDuration?: number;
  vertical: VerticalPosition;
  horizontal: HorizontalPosition;
  open: boolean;
  onClose?: () => void;
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
}

export const Snackbar: React.FC<Props> = (props) => {
  const { message, vertical, horizontal, open, onClose, autoHideDuration = 3000 } = props;
  const { classes } = useStyles(undefined, { props });

  return (
    <MuiSnackbar
      open={open}
      anchorOrigin={{ vertical, horizontal }}
      onClose={onClose}
      key={vertical + horizontal}
      autoHideDuration={autoHideDuration}
      className={classes.root}
    >
      <SnackbarContent className={classes.content} message={message} />
    </MuiSnackbar>
  );
};
