import { Stack } from '@mui/material';

import { Link } from '@ecp/components';
import { env } from '@ecp/env';

import { DisclosureWrapper } from '../../../components';
import { DoNotSellPersonalInfoLink } from './DoNotSellPersonalInfoLink';
import { useStyles } from './PrivacyPolicyHomesite.styles';

export const PrivacyPolicyHomesite: React.FC = () => {
  const { classes } = useStyles();

  return (
    <DisclosureWrapper
      title="Homesite's Privacy Policies"
      breadcrumbTitleOverrides={{ privacypolicy: 'Privacy Policy' }}
    >
      <Stack spacing={4} className={classes.root}>
        <Stack spacing={1}>
          <h2>Applicability</h2>
          <p>
            This Internet Privacy Policy applies to the following Homesite Group Incorporated
            Subsidiaries and Affiliates:
          </p>
          <p>
            Homesite Insurance Company of California, Homesite Indemnity Company, Homesite Insurance
            Company of Illinois, Homesite Insurance Company of New York, Homesite Insurance Company
            of Georgia, Homesite Insurance Company of the Midwest, Homesite Lloyds of Texas,
            Homesite Insurance Company of Florida, Homesite Insurance Company, and Midvale Indemnity
            Company.
          </p>
        </Stack>
        <Stack spacing={2}>
          <h2>Your Rights</h2>
          <Stack spacing={1}>
            <h3>Use of Consumer Reports</h3>
            <p>
              We use information from consumer reporting agencies such as your credit, claims, and
              insurance history. This helps us to fairly determine eligibility for our programs and
              ensure accurate rates for all policies.
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>Insurance Claims History</h3>
            <p>
              We obtain information about insurance claims history from A-PLUS. For information or
              to dispute any report regarding your insurance claims history, contact the A-PLUS
              Consumer Inquiry Center at 1-800-709-8842. Note: to protect your privacy, only you can
              discuss your claims history with A-PLUS (an agent won't be able to do this on your
              behalf).
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>Insurance Credit Score</h3>
            <div>
              <div className={classes.italicText}>
                All states (excluding California, Hawaii and Maryland)
              </div>
              We obtain information from TransUnion Corporation to determine your insurance credit
              score. This inquiry will not affect your credit score, as it is considered a "soft
              hit". TransUnion can provide a free copy of your credit report, and if you believe
              that report is inaccurate, you can request an investigation of information. Please
              note that TransUnion cannot comment on your insurance application. You can file a
              dispute online or by mail or phone:
              <br />
              <br />
              Trans Union LLC
              <br />
              Consumer Dispute Center <br />
              P.O. Box 2000 <br />
              Chester, PA 19016
              <br />
              <br />
              1-800-916-8800 <br />
              https://dispute.transunion.com
            </div>
          </Stack>
          <Stack spacing={1}>
            <h3>
              Extraordinary Life Circumstances{' '}
              <span className={classes.thinHeader}>
                (AK, CT, DE, FL, GA, IA, IL, KS, KY, LA, ME, MI, MN, MT, NH, NJ, NM, NV, OK, RI, SC,
                TX, VT) – Applicable to Homeowners policies only:
              </span>
            </h3>
            <p>
              If your credit information has been adversely impacted by an extraordinary life
              circumstance, you may request that we provide a reasonable exception to the manner in
              which we use this information. These extraordinary life circumstances include:
            </p>
            <ul>
              <li>
                Catastrophic event, as declared by the federal or any state government or the
                Commissioner of Insurance
              </li>
              <li>
                Serious or catastrophic illness or injury to the consumer or the consumer's
                immediate family
              </li>
              <li className={classes.withListInside}>
                <ul>
                  <li>
                    NM - this includes an acute or chronic medical condition, illness, injury or
                    disease
                  </li>
                  <li>LA - this includes a medical crisis</li>
                </ul>
              </li>
              <li>Death of a spouse, child or parent of the consumer</li>
              <li>Divorce or dissolution of marriage</li>
              <li>
                Divorce of involuntary interruption of spousal support or maintenance payments (AK
                ONLY)
              </li>
              <li>Identity theft</li>
              <li>Involuntary interruption of legally owed alimony or support payments</li>
              <li>Temporary loss of employment for a period of three or more consecutive months</li>
              <li>Military deployment overseas</li>
              <li>Total or other loss that makes your home uninhabitable (CT & NM ONLY)</li>
              <li>The personal guaranty of a business loan (LA ONLY)</li>
              <li>
                Predatory lending resulting in the foreclosure of, or commencement of proceedings or
                an action to foreclose, a mortgage of real property owned by the insured or
                insurance applicant (MI ONLY)
              </li>
              <li>
                Loss of employment for three months or more as a result of involuntary termination
                (AK & VT ONLY)
              </li>
            </ul>
            <p>
              If you believe any of these apply to you and has impacted your credit, you may request
              in writing that this be taken into consideration. We will require you to provide
              specific documentation of this circumstance and demonstrate how it has negatively
              affected your credit. The request must be made no more than 60 days from the date of
              the application for insurance or the policy renewal. Please submit your written
              request and all supporting documentation to Homesite Group, Inc., ATTN: Underwriting
              Department, PO Box 5300, Binghamton, NY 13902-9953.
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>Information We Collect from Third Parties</h3>
            <p>
              We collect information from affiliated and nonaffiliated third parties, including but
              not necessarily limited to insurance support organizations and consumer reporting
              agencies. It may include information about a home, property or homeowner from third
              party sources or public information. The information may be used to make underwriting
              and/or business decisions based on the data collected.
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>Use of Personal Information</h3>
            <p>
              We use information from many sources to fairly determine eligibility for our programs
              and ensure accurate rates for all policies. How we may collect, use and disclose this
              information is regulated by law. Following is a description of the kinds of
              information we may collect, how we may collect it, and what is done with the
              information once it has been collected. We also describe how you can find out what
              information we have about you in our records or files, and how you can correct
              inaccurate information. We follow these practices with your information whether you
              are a policyholder, claimant, former policyholder, or just an inquiring customer. Note
              to MN customers: your authorization for the collection, use and/or disclosure of your
              personal information remains in effect if you are continually insured with us unless
              revoked by you.
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>What kind of information do we collect about you?</h3>
            <p>
              We collect some information from you when you call us, complete an application, make a
              policy change or report a claim. This information includes identifying information
              such as name, address, date of birth, and social security number, as well as
              information regarding your home, business, and/or claims history.
            </p>
            <p>
              We may also obtain information such as credit reports, claims history, and
              investigative reports from other sources including an inspection of your property. The
              information we obtain about you may come from other insurance companies, insurance
              support organizations, credit bureaus, property data collection services, or other
              sources.
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>What do we do with the information collected about you?</h3>
            <p>
              We may, as permitted by law, disclose your identifying information in our records or
              files such as name, address, type of house, and claims history. Without your prior
              permission, we may disclose this information to certain persons or organizations
              including:
            </p>
            <ul>
              <li>
                Other insurance institutions, financial institutions, agents, or insurance support
                organizations.
              </li>
              <li>Persons who perform a business, professional, or insurance function for us.</li>
              <li>Businesses that conduct actuarial or research studies.</li>
              <li>Insurance regulatory authorities.</li>
              <li>Law enforcement or other governmental authorities.</li>
              <li>Our affiliated companies who assist our insurance business activities.</li>
            </ul>
            <p>
              Your information may be provided to others in the following circumstances, as
              permitted by law, for them to:
            </p>
            <ul>
              <li>Perform a business, professional, or insurance function for us.</li>
              <li>Provide information to us in order to</li>
              <li className={classes.withListInside}>
                <ol className={classes.orderedListAlphabetical}>
                  <li>Determine your eligibility for an insurance benefit or payment, or</li>
                  <li>
                    Detect or prevent criminal activity, fraud, material misrepresentation or
                    material nondisclosure in connection with an insurance transaction
                  </li>
                </ol>
              </li>
              <li>Perform a function in connection with an insurance transaction involving you.</li>
              <li>Conduct an audit of our operations or services.</li>
              <li>
                Conduct a joint marketing program with us pursuant to a joint marketing agreement.
              </li>
            </ul>
            <p>
              The information we obtain about you from a report prepared by an insurance support
              organization may be retained by the insurance support organization and disclosed to
              other sources.
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>How confidential and secure is the information we have about you?</h3>
            <p>
              We protect the confidentiality of the information that we have about you by
              restricting access to those employees who need to know that information to provide our
              products and services to you. We maintain physical electronic and procedural
              safeguards that comply with federal and state regulations to guard your information.
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>How can you find out what information we have about you?</h3>
            <p>
              You have the right to know what information we have about you in our insurance records
              or files. To obtain this information, provide to us in writing an identification of
              yourself and a reasonable explanation of the information you desire. If the
              information can be reasonably located and obtained, we will inform you of its nature
              and substance within 30 business days from the day we receive the request. You may
              personally see and obtain the information, or if you prefer, we will mail the
              information to you. We will also inform you who has received the information within
              the last 2 years, or, if not recorded, to whom such information is normally disclosed.
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>What can you do if you disagree with the information we have about you?</h3>
            <p>
              You have the right to make a written request that we correct, delete, or change any
              recorded information we have about you in our records or files. Send all written
              requests to:
            </p>
            <p>
              Homesite Group, Inc.
              <br />
              P.O. Box 5300
              <br />
              Binghamton, NY 13902-9953
            </p>
            <p>
              If we agree to comply with your request, we will notify you within 30 business days of
              receiving your request. We will then furnish the amended information to any person you
              designate, who may have received the information within the past 2 years, as well as
              to any person or organization who either supplied us with the information or to whom
              we disclosed it.
            </p>
            <p>
              If we are unable to comply with your request, we will notify you within 30 business
              days of receiving your written request with the reasons for our decision. If you
              disagree with the reasons for our decision, you have the right to file a concise
              statement of what you think is correct, relevant or fair information. Your statement
              will be filed with the disputed information and will be furnished to any person,
              insurance institution, agent or insurance support organization who either supplied us
              with information or to whom we disclosed it. Your statement will also be furnished to
              anyone reviewing the disputed information.
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>Your California Privacy Rights</h3>
            <p>
              Effective January 1, 2020, the California Consumer Privacy Act grants California
              residents (consumers) various rights with respect to personal information relating to
              that consumer that is held by a business. These rights include:
            </p>
            <ul>
              <li>
                The right to request the deletion of personal information about the consumer that
                has been collected from the consumer.
              </li>
              <li>
                The right to request a company's disclosures about the collection of personal
                information.
              </li>
              <li>
                The right to request that a business that collects personal information disclose the
                following to a consumer:
              </li>
              <li className={classes.withListInside}>
                <ul>
                  <li>
                    The categories of personal information that is has collected about the customer;
                  </li>
                  <li>
                    The categories of sources from which the personal information is collected;
                  </li>
                  <li>
                    The business or commercial purpose for collecting or selling personal
                    information;
                  </li>
                  <li>
                    The categories of third parties with whom the business shares personal
                    information; and
                  </li>
                  <li>
                    The specific pieces of personal information it has collected about the consumer.
                  </li>
                </ul>
              </li>
              <li>
                The right not to face discrimination for exercising any rights under the California
                Consumer Privacy Act
              </li>
              <li>The right to opt out of the sale of personal information.</li>
              <li>
                The right to opt-in to the sale of personal information for consumers under 16 years
                of age.
              </li>
            </ul>
            <p className={classes.paragraphWithPadding}>
              California consumers may submit a request for disclosure or deletion of personal
              information by completing the request form found{' '}
              <Link
                className={classes.links}
                href={env.ccpaUrl}
                target='_blank'
                rel='noreferrer'
                trackingName='california_consumer_privacy_act_link'
                trackingLabel='california_consumer_privacy_act'
              >
                here
              </Link>{' '}
              or by calling us toll-free at 1-800-466-3748. You will be required to verify your
              identity and status as a California resident. Upon verification, we will process your
              request within 45 days.
              <DoNotSellPersonalInfoLink />
            </p>
          </Stack>
          <Stack spacing={1}>
            <ul>
              <li>
                We retain personal information for the periods required by applicable laws and/or as
                specified in our records retention policies and procedures. The period chosen takes
                account of applicable legal requirements to retain data and specific business use
                cases. We maintain specific records management and retention policies and procedures
                so that, where required, personal information is disposed of after a reasonable time
                in consideration of the following retention criteria:
              </li>
              <li className={classes.withListInside}>
                <ul>
                  <li>
                    We retain your personal information for as long as needed to carry out the
                    business purposes set forth in this Privacy Policy, or to support our business
                    functions and operations.
                  </li>
                  <li>
                    We retain your personal information as long as we have an ongoing relationship
                    with you or for as long as needed to provide products or services to you.
                  </li>
                  <li>
                    {' '}
                    We retain your personal information for as long as needed to comply with our
                    legal and contractual obligations or as necessary to defend our rights.
                  </li>
                </ul>
              </li>
              <li>
                {' '}
                According to the CPRA, sensitive personal information includes any private
                information that divulges any of the following:
              </li>
              <li className={classes.withListInside}>
                <ul>
                  <li>
                    {' '}
                    Personal identification numbers, including social security, driver's license,
                    passport, or state ID card numbers
                  </li>
                  <li>
                    {' '}
                    Account or debit or credit card numbers combined with passwords or codes that
                    would enable access to the accounts
                  </li>
                  <li> A consumer's exact geolocation</li>
                  <li> A consumer's racial origin, religious beliefs, or union membership</li>
                  <li>
                    {' '}
                    A consumer's mail, email, or text message content unless the information was
                    intentionally sent to the business
                  </li>
                  <li> A consumer's genetic data, such as DNA samples</li>
                </ul>
              </li>
            </ul>
            <p className={classes.indentedParagraph}>
              Sensitive personal information includes the processing of any biometric data to
              identify a consumer, as well as personal information concerning a consumer's health or
              sexual orientation.
            </p>
            <p className={classes.indentedParagraph}>
              Note that any sensitive personal information that is publicly available is not
              considered sensitive personal information or personal information under this law.
            </p>
            <h4>
              Categories of Personal Information that we may have collected in the preceding 12
              months, as of January 1, 2020:
            </h4>
            <ul>
              <li>
                Identifying Information - name, address, date of birth, email address, phone
                numbers, bank account number, credit/debit card number
              </li>
              <li>Consumer report information, including claims history</li>
              <li>Names of family members or other residents of your household</li>
              <li>
                Information you provide about your dwelling as part of an application for insurance
              </li>
              <li>
                Information that you may have provided during the resolution of an insurance claim
              </li>
            </ul>
            <div className={classes.indentedParagraph}>
              We do not "sell" the personal information of our applicants, insureds, employees, or
              other individuals whose personal information we have obtained. We do disclose personal
              information to third parties and service providers, including:
              <ul>
                <li>
                  Other insurance institutions, financial institutions, agents, or insurance support
                  organizations.
                </li>
                <li>Persons who perform a business, professional, or insurance function for us.</li>
                <li>Businesses that conduct actuarial or research studies.</li>
                <li>Insurance regulatory authorities.</li>
                <li>Law enforcement or other governmental authorities.</li>
                <li>Our affiliated companies who assist our insurance business activities.</li>
              </ul>
            </div>

            <p>
              California Civil Code Section §1798.83 permits our website users who are California
              residents to request certain information regarding our disclosure of personal
              information to third parties. To make such a request, please write us at Homesite
              Group, Inc., P.O. Box 5300, Binghamton, NY 13902-9953.
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>Notification of Changes</h3>
            <p>
              We reserve the right to change this Privacy Policy from time to time, and such changes
              will be effective immediately upon posting to this web site. Your continued use of
              this web site after we make changes is deemed to be acceptance of those changes, so
              please check the policy periodically for updates. The date the Privacy Policy was last
              revised is identified at the bottom of the document. Under certain circumstances, we
              may also elect to notify you of changes or updates to our Privacy Policy, or to
              request your consent for such changes, by additional means, such as posting a notice
              on the front of our web site and sending you an email.
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>Non Homesite Web Sites</h3>
            <p>
              We are not responsible for the privacy practices or the content of any other websites
              on the internet that may be linked to our website. Please note that when you click on
              links to websites outside of www.homesite.com, you are transferred to another website
              that may not be under our control. We encourage you to read the privacy statements of
              these linked sites as their privacy policy may differ from ours.
            </p>
          </Stack>

          <Stack spacing={1}>
            <h3>Web Cookie Policy</h3>
            <p>
              We may place an electronic "cookie" in the browser files of a guest's computer.
              Cookies are pieces of information that a web site transfers to your browser, which can
              then be stored on an individual's computer hard drive for record-keeping purposes or
              to provide you with tailored information. Cookies do not enable us to access any
              personal information about our visitors. Cookies allow us to analyze how visitors use
              our site. This helps us better understand which areas of our site are most useful, so
              we can plan improvements and updates accordingly.
            </p>
            <p>
              We do not use cookies to retrieve information from a visitor's computer that was not
              originally sent in a cookie. We will never save passwords, credit card or other
              sensitive information in cookies. You may set your browser to notify you when you
              receive a cookie, giving you the opportunity to decide whether or not to accept it.
              For more information on cookies and their use, please refer to the online Help
              available through your browser.
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>Fraud Warning</h3>
            <p>
              Any person who, with intent to defraud or knowing that he is facilitating a fraud
              against an insurer, submits an application or files a claim containing a false or
              deceptive statement is guilty of insurance fraud.
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>Alaska Only</h3>
            <p>
              In connection with this application for insurance or notice of renewal, we will review
              your credit report or obtain or use a credit-based insurance score based on the
              information contained in your credit report. We may use a third party in connection
              with the development of your insurance score. We may use this information to decide
              whether to insure you or how much to charge.
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>Delaware Only</h3>
            <p>
              In connection with this application for insurance, we may obtain or use a credit based
              insurance score based on the information contained in your credit report. We may use a
              third party in connection with the development of your insurance score. If we do use a
              credit based score, you will have the right on an annual basis to request that we
              obtain your updated insurance score, based on a current credit report, to determine
              whether use of the new insurance score would result in a decrease in your insurance
              premium. If the new score would result in a decrease in your premium, we will make
              that reduction. If the new credit information would not reduce your premium, your
              credit report will not be used to impact your premium in any way.
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>Florida Only</h3>
            <p>
              Any person who knowingly and with intent to injure, defraud, or deceive any insurer
              files a statement of claim or an application containing any false, incomplete, or
              misleading information is guilty of a felony of the third degree.
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>Oregon Only</h3>
            <p>
              Any person who, with intent to defraud or knowing that he is facilitating a fraud
              against an insurer, submits an application or files a claim containing a false or
              deceptive statement may be guilty of insurance fraud.
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>Marijuana Items and Activities Coverage Notice</h3>
            <p>
              Under Section I – Property Coverages, marijuana items are covered under Coverage C –
              Personal Property, subject to any applicable policy sublimits. Examples of property
              sublimits that may apply include a limit for business property and limits on Trees,
              Shrubs and Other plants. Please see your policy contract for more information.
            </p>
            <p>
              Under Section II –Liability Coverages, Coverage E – Personal Liability and Coverage F
              do not apply to bodily injury or property damage arising out of the use, sale,
              manufacture, delivery, transfer or possession by any person of marijuana. The
              exclusion of these marijuana activities does not apply to the legitimate use of
              Marijuana by a person following the lawful orders of a licensed health care
              professional.
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>Alabama Only</h3>
            <h4>Policyholder Bill Of Rights</h4>
            <p>
              The purpose of this Act is to set forth the rights Alabama homeowners have with
              respect to their insurance policies and with insurance companies. Section 3 of the Act
              sets out the following minimum standards to be followed by the Alabama Department of
              Insurance in exercising the Department's powers and duties in regulating insurance
              companies pursuant to Title 27, Chapter 12, Code of Alabama 1975. These standards
              include the following.
            </p>
            <ol>
              <li>
                Policyholders shall have the right to competitive pricing practices of insurers as
                prescribed by applicable federal or state insurance law and regulation.
              </li>
              <li>
                Policyholders shall have the right to insurance advertising and sales approaches
                that provide representative information on the policy in accordance with Title 27,
                Chapter 12, Code of Alabama 1975.
              </li>
              <li>
                Policyholders shall have the right to assurance that the insurance market in general
                and their insurance company in particular are financially stable as provided in
                Section 27-12-7, Code of Alabama 1975.
              </li>
              <li>
                Policyholders shall have the right to receive service from licensed producers in
                accordance with Title 27, Chapter 7, Code of Alabama 1975, and to request the
                license status of an insurance company or producer.
              </li>
              <li>
                Policyholders shall have the right to a policy as prescribed in Title 27, Chapter
                14, Code of Alabama 1975, to receive a complete policy, and to request a duplicate
                or replacement policy, if needed.
              </li>
              <li>
                Policyholders shall have the right to receive in writing from their insurance
                company the reason for any cancellation of coverage and a minimum number of days'
                notice of cancellation of coverage, subject to applicable federal or state insurance
                law and regulation.
              </li>
              <li>
                Policyholders shall have the right to cancel their policy and receive a refund of
                any unearned premium. If a policy was funded by a premium finance company, the
                unearned premium will be returned to the premium finance company to pay toward the
                policyholder's financing loan.
              </li>
              <li>
                Policyholders shall have the right to a written notification, at renewal, describing
                changes in their insurance contract language that are applicable to the renewal
                period.
              </li>
              <li>
                Policyholders shall have the right, in the event of a claim, to reject any
                settlement amount offered by the insurance company.
              </li>
              <li>
                Policyholders shall have the right to select their licensed contractor or vendor to
                repair, replace, or rebuild damaged property covered by the insurance policy.
              </li>
              <li>
                Policyholders shall have the right to file a written complaint against any insurance
                company with the Department of Insurance and to have that complaint reviewed by the
                Department of Insurance.
              </li>
              <li>
                Policyholders shall have the right to file a written complaint against any insurance
                producer with the Department of Insurance and to have that complaint reviewed by the
                Department of Insurance
              </li>
            </ol>
          </Stack>
          <Stack spacing={1}>
            <h2>Flood Coverage Disclosure Notice</h2>
            <h3>All homeowner insurance policyholders are cautioned that:</h3>
            <p>
              Your policy excludes coverage for damage caused by flooding of all types. This
              exclusion may exclude any and all damages resulting from storm surge from a hurricane,
              surface water, flash floods, waves, tidal water, tidal waves, wind driven rain or
              water, or any other overflow of water, and spray from any of these events. For further
              details, please see the language of your policy. The language of your policy will
              control the obligations of the parties.
            </p>
            <p>
              You are further advised that to be covered for such a loss, you will have to obtain a
              separate flood insurance policy by applying to the National Flood Insurance Program
              (NFIP). Your need for flood coverage can be confirmed with the NFIP or your mortgage
              lender. If available in your location, the NFIP can provide both structure and
              contents coverage. A claim under a flood insurance policy may be adjusted and paid on
              a different basis than a claim under a homeowners policy.
            </p>
            <p>You can contact the National Flood Insurance Program for information at:</p>
            <p>
              National Flood Insurance Program
              <br />
              P.O. Box 619
              <br />
              Lanham, MD 20706
              <br />
              <br />
              https://www.floodsmart.gov
            </p>
          </Stack>

          <Stack spacing={1}>
            <h2>
              Statement of Additional Optional Coverages
              <br />
              Not included in the Standard Homeowners Insurance Policy
            </h2>
            <p>
              Your standard homeowners insurance policy does not cover all risks. You may need to
              obtain additional insurance to cover loss or damage to your home, property, and the
              contents of your home to cover the risks related to business or personal activities on
              your property. We offer the following additional coverages that you may choose to
              purchase. We urge you to review these optional endorsements and credits.
            </p>
          </Stack>
          <Stack spacing={1}>
            <span>
              <span className={classes.listItemTitle}>Extended Replacement Cost on Dwelling</span>{' '}
              This endorsement can be used to increase Coverage A (dwelling) by either 25% or 50% to
              better accommodate a customer's needs.
            </span>
          </Stack>
          <Stack spacing={1}>
            <span>
              <span className={classes.listItemTitle}>Earthquake</span> This covers against a direct
              physical loss caused by an earthquake or earth movement. You may add this additional
              coverage for an additional premium. It is subject to a percentage of Coverage A
              deductible.
            </span>
          </Stack>
          <Stack spacing={1}>
            <span>
              <span className={classes.listItemTitle}>Increase Deductibles</span> By increasing the
              deductible, the policyholder may lower the cost of annual insurance premium. For
              instance increasing a $500 deductible to a $1000, 1% or 2% may decrease the annual
              premium significantly.
            </span>
          </Stack>
          <Stack spacing={1}>
            <span>
              <span className={classes.listItemTitle}>Increase Limits on Other Structures</span>{' '}
              Provides additional limits for Coverage B if needed. Only structures on the insured
              premises apply. You may increase up to double the original Coverage B amount.
            </span>
          </Stack>
          <Stack spacing={1}>
            <span>
              <span className={classes.listItemTitle}>Water Back up and Sump Overflow</span>{' '}
              Provides coverage for direct physical loss due to water backing up through sewers or
              drains and water which overflows from a sump. This is not a flood coverage, which
              requires a separate policy.
            </span>
          </Stack>
          <Stack spacing={1}>
            <span>
              <span className={classes.listItemTitle}>Replacement Cost on Personal Property</span>{' '}
              This endorsement protects the customer from receiving the depreciated value for their
              personal property. Instead contents will be replaced with new items of like kind and
              quality.
            </span>
          </Stack>
          <Stack spacing={1}>
            <span>
              <span className={classes.listItemTitle}>Increased Limits on Personal Property</span>{' '}
              This endorsement offers the insured the ability to increase certain personal property
              coverages from the standard homeowners limit. For instance, the basic homeowners
              policy insures jewelry for up to $1000 for the named perils; a customer may choose to
              increase that coverage to $5000.
            </span>
          </Stack>
          <Stack spacing={1}>
            <span>
              <span className={classes.listItemTitle}>Increased Limits on Business Property</span>{' '}
              For additional premium, this endorsement may increase the coverage for "business"
              property on the "residence premises".
            </span>
          </Stack>
          <Stack spacing={1}>
            <span>
              <span className={classes.listItemTitle}>Scheduled Personal Property</span> For
              additional premium, we offer open perils coverage for items such as jewelry, furs,
              cameras, musical instruments, silverware, fine arts and golfer's equipment. Not
              subject to a deductible, these items would be covered for the appraised value.
            </span>
          </Stack>
          <Stack spacing={1}>
            <span>
              <span className={classes.listItemTitle}>Special Computer Coverage</span> For
              additional premium, this would broaden the coverage for your computer due to direct
              physical loss. For example, if the policyholder drops a glass of water on the
              computer, this would be covered. This endorsement is subject to the policy deductible.
            </span>
          </Stack>
          <Stack spacing={1}>
            <span>
              <span className={classes.listItemTitle}>Identity Theft</span> This endorsement covers
              up to $15,000 for expenses incurred by the insured as a direct result from identity
              theft. The deductible would only be $250.
            </span>
          </Stack>
          <Stack spacing={1}>
            <span>
              <span className={classes.listItemTitle}>Loss Assessment Coverage</span> Specially
              designed for condominium owners, this coverage provides protection for assessments
              made by the condominium association resulting from loss to the property. The
              policyholder can choose the limit of coverage they wish up to $25,000 if the loss is
              caused by an insured peril.
            </span>
          </Stack>
          <Stack spacing={1}>
            <span>
              <span className={classes.listItemTitle}>Watercraft</span> This endorsement extends the
              limit of liability on your homeowners policy to your watercraft. This does not cover
              for the physical loss of the watercraft.
            </span>
          </Stack>
          <Stack spacing={1}>
            <span>
              <span className={classes.listItemTitle}>Personal Injury</span> With this endorsement,
              you can extend the limit of liability on your homeowners policy to cover you against
              libel, slander, and invasion of privacy
            </span>
          </Stack>
          <Stack spacing={1}>
            <span>
              <span className={classes.listItemTitle}>Home Day Care Coverage</span> Registered
              family day care providers can purchase liability coverage in the amount of at least
              $300,000 to protect you against claims of bodily injury, property damage, or personal
              injury arising out of your activities as a day care provider.
            </span>
          </Stack>
          <Stack spacing={1}>
            <h3>
              ***Additional endorsements and credits are subject to availability and qualification.
            </h3>
            <h3>California Only</h3>
            <p>
              Increased Dwelling Protection is important in the event there is a covered loss to
              your home that exceeds your dwelling limit. This protection will provide up to an
              additional 25%, and in some areas 50% of your dwelling limit in coverage. For example,
              if you have dwelling protection with a dwelling limit of $200,000 on your policy, and
              you purchase Increased Dwelling Protection of 25%, your home is covered for an
              additional $50,000 for a total dwelling limit of $250,000. Your coverage limits,
              deductibles, and certain exclusions may apply. Please read your Homesite insurance
              policy for details once you receive it after purchasing.
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>Fraud Warning</h3>
            <p>
              For your protection California law requires the following to appear on this form. Any
              person who knowingly presents false or fraudulent information to obtain or amend
              insurance coverage or to make a claim for the payment of a loss is guilty of a crime
              and may be subject to fines and confinement in state prison.
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>Connecticut Only</h3>
            <p>
              Homesite considers lapses in coverage in determining the premium charged for your
              policy. We may be able to offer you an accommodation if you had a lapse in your
              previous coverage as the result of active military duty in the armed forces or as a
              member of a reserve component of the armed forces (including National Guard). Please
              contact our customer service department for more information.
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>Minnesota Only</h3>
            <p className={classes.boldText}>
              THE INSURER MAY ELECT TO CANCEL COVERAGE AT ANY TIME DURING THE FIRST 59 DAYS
              FOLLOWING ISSUANCE OF THE COVERAGE FOR ANY REASON WHICH IS NOT SPECIFICALLY PROHIBITED
              BY STATUTE.
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>New Jersey Only</h3>
            <h3>Mass Marketing Disclosure Notice</h3>
            <p>
              The following features apply to the mass marketing plan for which you may qualify:
            </p>
            <p>
              <strong>Special Discounts</strong>&nbsp;— Mass Marketing Account Deviation: If you are
              a member, employee or are associated with one of our sponsored groups, you may qualify
              for a discount off the base rates for Homeowners, Tenants, or Condo policies.
            </p>
            <p>
              <strong>Premium Rates and Coverages</strong>&nbsp;— Rates are based on numerous
              factors including location, coverages, limits, and deductibles selected.
            </p>
            <p>
              <strong>Descriptions of Coverages and Duration of Coverage</strong>&nbsp;— Information
              regarding the descriptions of the coverages selected, and the duration of coverage is
              included on the Declarations page of the policy.
            </p>
            <p>
              <strong>Association Termination</strong>&nbsp;— Should your membership, employment or
              association end during the policy period with the group sponsoring this program, your
              coverage will continue. However, on the renewal after you discontinue membership,
              employment or association, the mass marketing discount will be removed.
            </p>
            <p>
              <strong>Customer Service</strong>&nbsp;— Please contact our customer service
              department for more information.
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>New Mexico Only</h3>
            <p>
              We may collect information from sources other than you or someone else named in your
              application. This information may include confidential abuse information; that is,
              confidential information regarding acts of domestic abuse; the work, home, school, or
              other address or telephone number of a victim of domestic abuse; an applicant's or
              insured's status as a victim of domestic abuse; or their status as a family member,
              employer or associate of a victim of domestic abuse or a person with whom an applicant
              is known to have a direct, close personal, family, or abuse-related counseling
              relationship.
            </p>
            <div>
              <div className={classes.italicText}>Protected Persons</div>
              All insurance companies are prohibited by law from using anyone's confidential abuse
              status as a basis for denying coverage; refusing to issue, renew, or reissue a policy;
              canceling or otherwise terminating a policy; restricting or excluding policy coverage
              or benefits; or charging a higher premium for a policy.
            </div>
            <p>
              A protected person has a right to request access to any confidential abuse information
              we have about them in our records or files. A protected person also has a right to
              request that we correct, amend, or delete any such information we have about them in
              our records or files. A notice detailing our confidential abuse information practices
              is available on request.
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>North Dakota Only</h3>
            <p>
              We will consider the insured's claims history in determining whether to decline,
              cancel, nonrenew, or surcharge a policy and claims you incur will be reported to an
              insurance support organization.
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>South Carolina Only</h3>
            <p>
              THE INSURER CAN CANCEL THIS POLICY FOR WHICH YOU ARE APPLYING WITHOUT CAUSE DURING THE
              FIRST 120 DAYS. THAT IS THE INSURER'S CHOICE. AFTER THE FIRST 120 DAYS, THE INSURER
              CAN ONLY CANCEL THIS POLICY FOR REASONS STATED IN THE POLICY.
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>Vermont Only</h3>
            <p>
              We do not disclose, and do not wish to reserve the right to disclose, nonpublic
              personal information about customers or former customers to affiliates except as
              authorized under Sections 15, 16, and 17 of VT ADC 4-3-42.
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>Virginia Only</h3>
            <p>
              In connection with this application for insurance, we shall review your credit report
              or obtain or use an insurance credit score based on the information contained in that
              credit report. We may use a third party in connection with the development of your
              insurance credit score. You may request that your credit information be updated and if
              you question the accuracy of the credit information, we will, upon your request,
              reevaluate you based on corrected credit information from a consumer reporting agency.
            </p>
          </Stack>
          <Stack spacing={1}>
            <h3>West Virginia Only</h3>
            <p>
              We use your credit information to produce an insurance score. This insurance score has
              an effect on the premium that you pay for your insurance. We are required by the
              Insurance Commissioner to recheck your credit information no less than once every 36
              months for changes. You have the option to request that we recheck your insurance
              score more frequently than once every 36 months, but you can only make this request
              once during any 12-month period. If there has been a change in your insurance score,
              we will re-underwrite and re-rate the policy based upon the current credit report or
              insurance score. The change in your insurance score may result in an increase or a
              decrease in the premium that you pay for your insurance. Any changes in your premium
              will take place upon renewal if your request is made at least 45 days before your
              renewal. If the request is made less than 45 days before your renewal date, the
              insurer shall re-underwrite and re-rate the policy for the following renewal.
            </p>
            <p>
              Homesite considers lapses in coverage in determining the premium charged for your
              policy. We may be able to offer you an accommodation if you had a lapse in your
              previous coverage as the result of active military duty in the armed forces or as a
              member of a reserve component of the armed forces (including National Guard). Please
              contact our customer service department for more information.
            </p>
            <p>Last Modified On: December 30, 2022</p>
          </Stack>
        </Stack>
      </Stack>
    </DisclosureWrapper>
  );
};
