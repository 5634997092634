import type { Context, ContextValue } from '@datadog/browser-core';
import { SESSION_STORE_KEY } from '@datadog/browser-core';
import { datadogLogs } from '@datadog/browser-logs';
import type { RumEvent } from '@datadog/browser-rum';
import { datadogRum } from '@datadog/browser-rum';

import { unique } from '@ecp/utils/common';
import { removeCookie } from '@ecp/utils/web';

import type { DataDogLogContext, LogParams, UserActionParams, ViewOptions } from './types';

/** Create the regex required for scrubPCIPIIData. */
const makePIIPCIRegex = (): RegExp => {
  const keywordRegex = [
    'credit',
    'zipcode',
    'email',
    'address',
    'vin',
    'password',
    'phone',
    'name',
    'initial',
    'dob',
    'dateofbirth',
    'license',
    'account',
    'routing',
    'social',
    'socSec',
    'ssn',
    'operator assignment',
    'cardNumber',
    'username',
    'user',
  ].reduce((acc, curr, index) => {
    return `${acc}${index === 0 ? '' : '|'}(${curr})`;
  }, '');

  return new RegExp(keywordRegex, 'gi');
};

const keyWordMatcher = makePIIPCIRegex();

/**
 * This will go through the context about to be sent to DataDog
 * and scrub and PCI/PII data check it against the regex created
 * by makePIIPCIRegex
 */
export const scrubPCIPIIData = (
  context: DataDogLogContext | Context,
): { data: DataDogLogContext | Context; matches: string[] } => {
  /**
   * we will want to exclude the keys from DataDogLogContext. Consider this
   * example:
   *
   *{
   *  message: 'Invalid Zip Code',
   *  context: { logOrigin: 'ProductForm', functionOrigin: 'validateZipCode' },
   *}
   * We have a valid use case for wanting to include the key words zip code but this will all get
   * redacted from the logs if we don't exlude it.
   */
  const excludedKeys = [
    'logOrigin',
    'functionOrigin',
    'contextType',
    'severity',
    'category',
    'action',
    'errorData',
  ];

  return Object.keys(context).reduce<{ data: DataDogLogContext | Context; matches: string[] }>(
    (ctx, key) => {
      const stringValue = JSON.stringify(context[key] || '');
      /**
       * We will need to test the key as well as the value and here's why.
       * Let's assume we have an object like this
       *
       * { creditCardNumber: '1234 1234 1234 1234' }
       *
       * if we only check the value we will actually miss the data we want to
       * scrub.
       */
      if (
        !excludedKeys.includes(key) &&
        (keyWordMatcher.test(key) || keyWordMatcher.test(stringValue))
      ) {
        const valueMatches = stringValue.match(keyWordMatcher);
        const keyMatches = key.match(keyWordMatcher);

        /**
         * We will want to keep track of all of the matches we get to send
         * to DD in a log if we actually do get any. The matches will only be
         * the key that we are looking for. For example:
         *
         * 'myssn:123456789'.match(keyWordMatcher) => ['ssn'];
         *
         * We will want to normalize the matches to make sure they're all similar.
         * This is for faceting purposes in the DD dashboard. An array like ['ssn', 'SSN', 'ssN']
         * is not helpful and makes the logs cluttered.
         */
        if (valueMatches)
          ctx.matches.push(...valueMatches.map((value) => value.trim().toLowerCase()));
        if (keyMatches) ctx.matches.push(...keyMatches.map((value) => value.trim().toLowerCase()));

        /**
         * If one of our keys or values matches then we will replace the
         * value with [redacted]
         */
        ctx.data[key] = `[redacted - ${key}: ${ctx.matches.join(', ')}]`;
      } else {
        /**
         * Otherwise we can just assign the key without a problem
         */
        ctx.data[key] = context[key];
      }

      /**
       * I have made the PCI/PII field matches normalied but now
       * we want to make sure that the values are unique as well.
       *
       * The matches() function will match every instance so if we had
       *
       * {ssn: 12345678, userSSN: 12345678}
       *
       * It would return ['ssn', 'SSN'];
       *
       * We only really need one of those values in DD for faceting
       * and alerting.
       */
      if (ctx.matches.length > 0) {
        ctx.matches = unique(ctx.matches);
      }

      return ctx;
    },
    {
      data: {},
      matches: [],
    },
  );
};

/**
 * This is a generic logger function for DataDog Logs that
 * we can strictly control. Please find a list of disallowed
 * key words below (please note these are case insensitive):
 *
 * credit
 * zip
 * email
 * address
 * vin
 * password
 * phone
 * name
 * middleinitial
 * dob
 * dateofbirth
 * license
 * account
 * routing
 * password
 * social
 * socSec
 * ssn
 *
 * If there are any additions that need to be made please add
 * them to the keywordRegex array in makePIIPCIRegex function.
 */
export const datadogLog = (params: LogParams): void => {
  const { logType, message, context, error } = params;

  const { data, matches } = scrubPCIPIIData(context);

  /**
   * If we do catch any PCI/PII data being sent out then we will want to send
   * DD a seperate log as this will have an attached Monitor to it.
   */
  if (matches.length > 0) {
    datadogLogs.logger.warn('Disallowed PCI/PII data detected in a log!', {
      logOrigin: data.logOrigin,
      functionOrigin: data.functionOrigin,
      redactedTypes: matches,
      contextType: 'Disallowed PCI Error',
      severity: 'fatal',
    });
  }

  switch (logType) {
    case 'error':
      datadogLogs.logger.error(message, data, error);
      break;
    case 'warn':
      datadogLogs.logger.warn(message, data, error);
      break;
    case 'info':
      datadogLogs.logger.info(message, data, error);
      break;
    default:
      datadogLogs.logger.info(message, data, error);
      break;
  }
};

/** Sets the global context for both Logs and RUM at the same time. */
export const setGlobalContext = (key: string, value: ContextValue): void => {
  datadogLogs.setGlobalContextProperty(key, value);
  datadogRum.setGlobalContextProperty(key, value);
};

/** Custom tracker. */
export const trackDataDogUserAction = (params: UserActionParams): void => {
  const { name, context } = params;

  const { data } = scrubPCIPIIData(context);
  datadogRum.addAction(name, data);
};

/**
 * Track custom datadog views
 * @param { string | ViewOptions } options
 */
export const trackDataDogCustomView = (options: string | ViewOptions): void => {
  /**
   * The input for startView isn't string | ViewOptions, in RUM it's an overload function.
   * So we need to check the type of the input and call the correct overload function.
   */

  const optionsString = typeof options === 'string' ? options : null;
  const viewOptions = typeof options !== 'string' ? options : null;
  if (optionsString) datadogRum.startView(optionsString);
  else if (viewOptions) datadogRum.startView(viewOptions);
};

/** Removes cookie that drives Datadog session. */
export const clearDatadogSession = (): void => {
  removeCookie(SESSION_STORE_KEY);
};

/**
 * Assign 'customActionName' to the target name for frustration signals that are
 * tracked to avoid leaking PII/PCI data
 */
export const setCustomActionName = (event: RumEvent): boolean => {
  if (event.type === 'action' && event.action.type === 'click') {
    if (event.action.target) {
      event.action.target.name = 'customActionName';
    }
    if (event.action.frustration && event.action.frustration.type.length > 0) {
      return true;
    }

    return false;
  }

  return true;
};
