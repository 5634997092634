import { Payeezy } from '@homesite/payment-utils';
import dayjs from 'dayjs';

import * as interactionId from '@ecp/utils/analytics/interaction-id';
import { userAuth } from '@ecp/utils/auth';
import { uuid } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';

import { env } from '@ecp/env';
import type { PolicyPayment, ServicingError } from '@ecp/features/servicing/shared/types';

import { apiRoot, buildRequestHeaders } from '../servicingApiConfig';
import type { ApiRequestOptions, ServicingResponse } from '../servicingRequest';
import { servicingRequest } from '../servicingRequest';
import type {
  AutopayEnrollRequest,
  AutopayEnrollSuccess,
  AutopayUnenrollSuccess,
  CardRequestBody,
  CreateCardPaymentMethodResponse,
  CreateEftPaymentMethodResponse,
  CreatePaymentMethodRequest,
  DeletePaymentMethod,
  EftRequestBody,
  GetPaymentMethodRequest,
  GetPaymentOptions,
  GetPolicyPaymentsRequest,
  MakePaymentWithExistingMethod,
  MakePaymentWithExistingMethodRequest,
  MakePaymentWithNewMethodRequest,
  PayeezyCreditCard,
  PaymentUrlSuccess,
} from './types';

const requestId = uuid();

export const createPaymentMethod = async (
  request: CreatePaymentMethodRequest<CardRequestBody | EftRequestBody>,
  apiRequestOptions?: ApiRequestOptions,
): Promise<
  ServicingResponse<
    CreateCardPaymentMethodResponse | CreateEftPaymentMethodResponse | ServicingError
  >
> => {
  const { policyNumber, ...body } = request;
  const headers = await buildRequestHeaders({ withUserToken: true });

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-payment/v1/payments/${policyNumber}/paymentMethods`,
    options: {
      headers: headers,
      method: 'POST',
      body: JSON.stringify(body),
    },
    ...apiRequestOptions,
  });
};

export const getPaymentOptions = async (
  request: GetPaymentMethodRequest,
  apiRequestOptions?: ApiRequestOptions,
): Promise<ServicingResponse<GetPaymentOptions>> => {
  const { billingAccountNumber, policySource } = request;
  const headers = await buildRequestHeaders({ withUserToken: true });

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-payment/v1/payments/${billingAccountNumber}/paymentmethods?policySource=${policySource}`,
    options: {
      headers: headers,
      method: 'GET',
    },
    ...apiRequestOptions,
  });
};

export const getPolicyPayments = async (
  request: GetPolicyPaymentsRequest,
  apiRequestOptions?: ApiRequestOptions,
): Promise<ServicingResponse<PolicyPayment[]>> => {
  const { policyNumber } = request;
  const headers = await buildRequestHeaders({ withUserToken: true });

  return servicingRequest({
    baseUrl: `${apiRoot}/payments/${policyNumber}`,
    options: {
      headers: headers,
      method: 'GET',
    },
    ...apiRequestOptions,
  });
};

export const makePaymentWithExistingMethod = async (
  request: MakePaymentWithExistingMethodRequest,
  apiRequestOptions?: ApiRequestOptions,
): Promise<ServicingResponse<MakePaymentWithExistingMethod>> => {
  const { policyNumber } = request;
  const headers = await buildRequestHeaders({ withUserToken: true });
  const body: Omit<MakePaymentWithExistingMethodRequest, 'policyNumber'> = {
    policySource: request.policySource,
    savedPaymentMethod: request.savedPaymentMethod,
    auditInfo: request.auditInfo,
  };

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-payment/v1/payments/${policyNumber}`,
    options: {
      headers: headers,
      method: 'POST',
      body: JSON.stringify(body),
    },
    ...apiRequestOptions,
  });
};

export const payeezyTokenizeCreditCard = async (
  request: PayeezyCreditCard,
): Promise<string | undefined> => {
  const {
    fullName: cardHolderName,
    cardNumber: creditCardNumber,
    cardExpirationDate,
    policyNumber,
    cardType,
  } = request;
  const date = dayjs(cardExpirationDate);

  const response = await Payeezy.fetchPaymentToken({
    creditCardNumber,
    cardHolderName,
    cardType,
    expirationMonth: date.format('MM') as Payeezy.MM,
    expirationYear: date.format('YY') as Payeezy.YyFromNow,
    tokenServiceUri: env.creditCardTokenServiceUrl,
  });
  const [status, { serviceGuid, tokenValue, responseError }] = response;
  if (status !== 200 && responseError) {
    let message = '';
    if ('errorDescription' in responseError) message = responseError.errorDescription;
    else if ('messages' in responseError)
      message = responseError.messages.map((msg) => msg.errorDescription).join(' ');

    datadogLog({
      logType: 'error',
      message: `Error fetching Payeezy CreditCardToken - ${message} - serviceGuid: [${serviceGuid}]`,
      context: {
        requestId,
        interactionId: interactionId.get(),
        sessionOktaId: userAuth.userId,
        policyNumber,
        logOrigin: 'libs/features/servicing/shared/state/src/payment/api.ts',
        functionOrigin: 'payeezyTokenizeCreditCard',
      },
    });
  }

  return tokenValue;
};

export const deletePaymentMethod = async (
  request: DeletePaymentMethod,
  apiRequestOptions?: ApiRequestOptions,
): Promise<ServicingResponse<boolean>> => {
  const { policyNumber, primeKey, policySource, paymentMethodType } = request;
  const headers = await buildRequestHeaders({ withUserToken: true });

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-payment/v1/payments/${policyNumber}/paymentMethods/${primeKey}?policySource=${policySource}&type=${paymentMethodType}`,
    options: {
      headers: headers,
      method: 'DELETE',
    },
    ...apiRequestOptions,
  });
};

export const autoPayEnroll = async (
  autopayEnrollRequest: AutopayEnrollRequest,
  apiRequestOptions?: ApiRequestOptions,
): Promise<ServicingResponse<AutopayEnrollSuccess>> => {
  const { billingAccount, primeKey, ...body } = autopayEnrollRequest;

  const headers = await buildRequestHeaders({ withUserToken: true });

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-payment/v1/payments/${billingAccount}/paymentMethods/${primeKey}/autoPayEnrolled`,
    options: {
      headers: headers,
      method: 'PUT',
      body: JSON.stringify(body),
    },
    ...apiRequestOptions,
  });
};

export const autopayUnenroll = async (
  autopayEnrollRequest: AutopayEnrollRequest,
  apiRequestOptions?: ApiRequestOptions,
): Promise<ServicingResponse<AutopayUnenrollSuccess>> => {
  const { billingAccount, primeKey, ...body } = autopayEnrollRequest;

  const headers = await buildRequestHeaders({ withUserToken: true });

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-payment/v1/payments/${billingAccount}/paymentMethods/${primeKey}/autoPayEnrolled`,
    options: {
      headers: headers,
      method: 'PUT',
      body: JSON.stringify(body),
    },
    ...apiRequestOptions,
  });
};

export const getPaymentUrl = async (
  request: GetPaymentMethodRequest,
  apiRequestOptions?: ApiRequestOptions,
): Promise<ServicingResponse<PaymentUrlSuccess>> => {
  const { billingAccountNumber, policySource, callbackUrl } = request;
  const headers = await buildRequestHeaders({ withUserToken: true });

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-payment/v1/payments/${billingAccountNumber}/paymentUrl?policySource=${policySource}&callbackUrl=${callbackUrl}`,
    options: {
      headers: headers,
      method: 'GET',
    },
    ...apiRequestOptions,
  });
};

export const makePaymentWithNewMethod = async (
  request: MakePaymentWithNewMethodRequest,
  apiRequestOptions?: ApiRequestOptions,
): Promise<ServicingResponse<MakePaymentWithExistingMethod>> => {
  const { billingAccountNumber } = request;
  const headers = await buildRequestHeaders({ withUserToken: true });
  const body: Omit<MakePaymentWithNewMethodRequest, 'billingAccountNumber'> = {
    paymentTerm: request.paymentTerm,
    policySource: request.policySource,
    newPaymentMethod: request.newPaymentMethod,
    auditInfo: request.auditInfo,
  };

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-payment/v1/payments/${billingAccountNumber}`,
    options: {
      headers: headers,
      method: 'POST',
      body: JSON.stringify(body),
    },
    ...apiRequestOptions,
  });
};
