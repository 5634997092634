import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ContactUsPage' })((theme) => ({
  root: {},
  header: {
    alignSelf: 'center',
    marginLeft: 18,
  },
  hours: theme.typography.body1,
  message: theme.typography.body3,
  phone: { ...theme.typography.body4Underline, color: theme.palette.text.link },
  iconWrapper: { height: 52, width: 52 },
}));
