import { makeStyles } from '@ecp/themes/base';
const borderRadius = 4;
export const useStyles = makeStyles({ name: 'DocumentTable' })((theme) => ({
  root: {
    table: { borderCollapse: 'inherit', borderSpacing: 0, width: '100%' },
    '& th': { ...theme.typography.body1Bold, padding: '12px 10px' },
    '& td:last-child': { padding: 10 },
    '& th:first-child': { borderTopLeftRadius: borderRadius },
    '& th:last-child': {
      borderTopRightRadius: borderRadius,
      width: 117,
    },
    'tr:last-child td:first-child': { borderBottomLeftRadius: borderRadius },
    'tr:last-child td:last-child': { borderBottomRightRadius: borderRadius },
  },
  link: {
    ...theme.typography.body1Underline,
    overflowWrap: 'anywhere',
    whiteSpace: 'normal',
  },
  emptyDocs: theme.typography.body1Italics,
}));
