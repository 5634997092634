import { Alert } from '@ecp/components';
import { ContactUsLink, PolicyCard } from '@ecp/features/servicing/shared/components';

import { useStyles } from './PoliciesSection.styles';

export const PolicyErrorCard: React.FC<{ policyNumber: string }> = (props) => {
  const { policyNumber } = props;
  const { classes } = useStyles();

  return (
    <PolicyCard
      className={classes.policyErrorCard}
      headerDivider
      policyNumber={policyNumber}
      policyDisplayType='Policy'
      body={
        <Alert type='error' withIcon className={classes.policyErrorAlert}>
          Sorry, we're unable to access policy information at this time. Please{' '}
          <ContactUsLink className={classes.contactUsLinkText} /> or try again later.
        </Alert>
      }
    />
  );
};
