export const generateCodeVerifier = (length: number): string => {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
  const possibleLength = possible.length;
  const randomValues = new Uint8Array(length);

  window.crypto.getRandomValues(randomValues);

  for (let i = 0; i < length; i++) {
    text += possible.charAt(randomValues[i] % possibleLength);
  }

  return text;
};

// @see https://www.oauth.com/oauth2-servers/pkce/authorization-request/
export const generateCodeChallenge = async (codeVerifier: string): Promise<string> => {
  // SHA-256 is being used for PKCE which is safe pattern, suppressing sonarqube warning in the next line
  const digest = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(codeVerifier)); // NOSONAR

  return window
    .btoa(String.fromCharCode(...new Uint8Array(digest)))
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
};
