/** Condition to enable chat: application URL pathname regex or
 * any function that returns a boolean
 * (true to enable chat, false to disable chat).
 */
type EnableCondition = RegExp | (() => boolean);

/**
 * Configuration for the chat feature.
 */
export type Config = {
  /** The Deployment ID is linked to a unique Configuration set by Genesys admin which controls the visual appearance of the messaging widget. */
  deploymentId: string;
  /** This is used to segment the customer experience as well as pre-set information about the users,
   * for example, it determines the bot answer and live chat agents
   * the users are routed to
   */
  queueName: string;
  /** The Genesys environment, e.g., 'prod'. */
  environment: string;
  /** Time to wait before downloading and executing genesys bootstrap script */
  timeout: number;
  /**
   * Determines if the chat should be enabled based on the evaluation result.
   * If the condition evaluates to true, the chat is enabled.
   * If the condition evaluates to false, the chat is not enabled.
   * When chat is enabled, chat initialization will proceed.
   * When chat is not enabled, chat initialization will be skipped and
   * other Genesys chat functions will become no-operations (no-op).
   * */
  enabledCondition: EnableCondition;
};

export let _config: Config;

export const saveConfig = (config: Config): void => {
  _config = config;
};

export const getConfig = (): Config => {
  return _config;
};

/**
 * evaluate if the Condition is true
 *
 * @param condition condition to evaluate
 * if conditionc is not provided, it will use the condition from the config
 */
const evaluateEnabledCondition = (condition: EnableCondition): boolean => {
  let result = false;

  if (condition instanceof RegExp) {
    result = condition.test(window.location.href);
  } else if (condition instanceof Function) {
    result = condition();
  }

  return result;
};

/**
 * Use enabledCondition from config to determine if chat is enabled
 * If config is not initialized, return false
 */
export const getChatEnabled = (): boolean => {
  if (!_config) return false;

  return evaluateEnabledCondition(getConfig().enabledCondition);
};
