import { flagValues } from '@ecp/utils/flags';

import type { PolicyResponse } from '@ecp/features/servicing/shared/types';

import { getAddressByRefId } from './getAddressByRefId';
import { getProductLineFromPolicyResponse } from './getProductLineFromPolicyResponse';
import { getItemFromLoggedInSessionStorage } from './loggedInStorage';

export const isCaMileageFormRequired = (policyData: PolicyResponse | undefined): boolean => {
  const state = getAddressByRefId(policyData, policyData?.policy.primaryAddressRefId)?.state;
  const productLine = getProductLineFromPolicyResponse(policyData);
  const hasSubmittedInSession = !!getItemFromLoggedInSessionStorage(
    'caMileageForm',
    policyData?.policy.policyNumber,
  );

  return (
    flagValues.CA_MILEAGE &&
    productLine === 'AUTO' &&
    state === 'CA' &&
    !hasSubmittedInSession &&
    !!policyData?.policy.mockCaMileageFormNeeded
  );
};
