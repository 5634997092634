import { getTime } from '@ecp/utils/date';
import { sessionStorage } from '@ecp/utils/storage';

import { TokenUtil } from '../token';
import { AuthError } from '../util';
import { endToken, fetchTokens, fetchTokensFromRefreshToken } from './api';
import type { AuthConfig, UserTokens } from './types';
import type { AuthUtil } from './util';
import { UserAuthUtil } from './util';

let instance: UserAuthUtil;
const initialized = false;

/**
 * Initialize with config values and create UserAuthUtil instance.
 * Make sure to initialize `storage` before calling this function.
 *
 * @throws {AuthError} Calling this function more than once throws.
 */
const initialize = (authConfig: AuthConfig): AuthUtil => {
  if (initialized) throw new AuthError(`${UserAuthUtil.name} has already been intialized.`);

  const token = new TokenUtil<UserTokens>(getTime);
  const fetchTokensApi = fetchTokens(authConfig);
  const fetchTokensFromRefreshTokenApi = fetchTokensFromRefreshToken();
  const endTokenApi = endToken(authConfig);

  instance = new UserAuthUtil(
    token,
    fetchTokensApi,
    endTokenApi,
    fetchTokensFromRefreshTokenApi,
    sessionStorage,
  );

  // only used for testing purposes
  return instance;
};

export { initialize, instance };
