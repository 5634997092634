import { useParams } from 'react-router-dom';

import { ClaimsTextingConnect } from './connect';
import { ClaimsTextingHomesite } from './homesite';

export const ClaimsTextingPage: React.FC = () => {
  const { opco } = useParams();

  let claimsTexting;
  switch (opco) {
    case 'connect':
      claimsTexting = <ClaimsTextingConnect />;
      break;
    case 'homesite':
      claimsTexting = <ClaimsTextingHomesite />;
      break;
    default:
      claimsTexting = <p>Partner Specific Claims Texting.</p>;
  }

  return claimsTexting;
};
