import { useCallback } from 'react';

import { Stack } from '@mui/material';

import { maskEmail } from '@ecp/utils/common';

import { Button, PhoneLink } from '@ecp/components';
import {
  AddressDisplay,
  LoadingOverlay,
  PolicyCard,
} from '@ecp/features/servicing/shared/components';
import type { UseModalPathReturn as PaperlessLandingProps } from '@ecp/features/servicing/shared/routing';
import {
  getMailingAddress,
  getProductLineFromPolicyResponse,
  getVehicleDescription,
  isLegacyPolicy,
  useLinkedPolicies,
  usePolicy,
} from '@ecp/features/servicing/shared/state';
import { getBrandData } from '@ecp/features/servicing/shared/util';
import { IconMaterialDesignMapsDirectionsCar, IconUIMap } from '@ecp/themes/base';

import { useStyles } from './PaperlessLanding.styles';

export const PaperlessLanding: React.FC<PaperlessLandingProps> = (props) => {
  const { currentFlow, goForward, policyNumber } = props;
  const { classes } = useStyles();
  const { isLoading: isPolicyLoading, policyData: policyResponse } = usePolicy(policyNumber);
  const { linkedPolicies, isLoading: isLoadingLinkedPolicies } = useLinkedPolicies(policyNumber, {
    unenrollingPaperless: currentFlow === 'paperlessunenroll',
  });
  const productLine = getProductLineFromPolicyResponse(policyResponse);
  const customerServicePhone = getBrandData(policyResponse).mainCustServPhoneNum;

  const handleClick = useCallback(() => {
    goForward();
  }, [goForward]);

  const isLegacy = isLegacyPolicy(productLine, policyResponse?.policy.sourceSystemName);

  const isPaperlessEnroll = currentFlow === 'paperlessenroll';
  const showLinkedPolicies = isLegacy && !!linkedPolicies.length;

  const policyOrPolicies = linkedPolicies.length > 1 ? 'policies' : 'policy';
  const linkedPoliciesTitle = isPaperlessEnroll
    ? `The ${policyOrPolicies} below will also be enrolled if you go paperless.`
    : `Delivery preferences for the ${policyOrPolicies} below will also be updated.`;

  if (isPolicyLoading || isLoadingLinkedPolicies) return <LoadingOverlay />;

  const title = isPaperlessEnroll
    ? 'The following policy is currently set to receive documents in the mail at the address below.'
    : 'The following policy is currently enrolled in paperless.';

  return (
    <Stack spacing={3}>
      <h2>{title}</h2>
      <Stack spacing={2} className={classes.section}>
        <PolicyCard
          className={classes.card}
          policyDetails={policyResponse}
          headerDivider
          body={
            productLine === 'AUTO' ? (
              <Stack
                direction={{ sm: 'column', md: 'row' }}
                spacing={1}
                className={classes.cardContent}
              >
                <Stack spacing={1} direction='row' flex={6}>
                  <IconMaterialDesignMapsDirectionsCar className={classes.icon} />
                  <Stack>
                    {policyResponse?.vehicles?.map((vehicle) => (
                      <p key={vehicle.vehicleId}>{getVehicleDescription(vehicle)}</p>
                    ))}
                  </Stack>
                </Stack>
                <Stack spacing={1} direction='row' flex={6}>
                  <IconUIMap className={classes.icon} />
                  <div>
                    <AddressDisplay policyAddress={getMailingAddress(policyResponse)} />
                  </div>
                </Stack>
              </Stack>
            ) : (
              <Stack spacing={1} direction='row' className={classes.cardContent}>
                <IconUIMap className={classes.icon} />
                <div>
                  <AddressDisplay policyAddress={getMailingAddress(policyResponse)} />
                </div>
              </Stack>
            )
          }
        />
        {showLinkedPolicies && (
          <Stack spacing={2}>
            <h3>{linkedPoliciesTitle}</h3>
            <Stack component='ul' spacing={2} className={classes.linkedPoliciesList}>
              {linkedPolicies.map((policyDetails) => (
                <li key={policyDetails.policy.policyNumber}>
                  <PolicyCard className={classes.card} policyDetails={policyDetails} body={null} />
                </li>
              ))}
            </Stack>
          </Stack>
        )}
        {isPaperlessEnroll ? (
          <p>
            When you opt into paperless, notifications about new documents will be sent to{' '}
            <span className={classes.email}>{maskEmail(policyResponse?.persons[0].email)}</span>.
            Documents will be available to view and download online.
          </p>
        ) : (
          <p>
            Notifications about new documents are being sent to{' '}
            <span className={classes.email}>{maskEmail(policyResponse?.persons[0].email)}</span>.
            Documents are available to view and download online.
          </p>
        )}

        <p>
          To update your email or mailing address, call us at{' '}
          <PhoneLink number={customerServicePhone} withUnderlinedLinkStyle />
        </p>
      </Stack>
      <Button
        variant='primary'
        onClick={handleClick}
        className={classes.button}
        trackingName='go_paperless_button'
        trackingLabel='go_paperless_continue'
      >
        {isPaperlessEnroll ? 'GO PAPERLESS' : 'Edit'}
      </Button>
    </Stack>
  );
};
