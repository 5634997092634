import { useCallback } from 'react';

import { useIsMutating } from '@tanstack/react-query';

import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { FullScreenModal } from '@ecp/features/servicing/shared/components';
import { MODAL_FLOW, MODAL_STEP, useModalPath } from '@ecp/features/servicing/shared/routing';
import {
  getProductLineFromPolicyResponse,
  isLegacyPolicy,
  usePolicy,
  useSharedState,
} from '@ecp/features/servicing/shared/state';
import { getMakePaymentClassicMutationKey } from '@ecp/features/servicing/shared/state';
import type {
  ClassicPaymentToken,
  MakePaymentInformation,
} from '@ecp/features/servicing/shared/types';

import { MakePaymentConfirmation } from '../MakePaymentConfirmation';
import { MakePaymentEditClassic } from '../MakePaymentEditClassic';
import { MakePaymentEditPaymentClassic } from '../MakePaymentEditPaymentClassic';

const PaymentClassicModalContent: React.FC = () => {
  const modalPath = useModalPath();
  const policyNumber = modalPath.policyNumber;

  const [sharedState] = useSharedState<MakePaymentInformation>(modalPath.sharedStateKey);
  const [, setFinancialToken] = useSharedState<ClassicPaymentToken>(
    `financialAccountToken-${policyNumber}`,
  );
  const isPaymentClassicSubmitting = useIsMutating({
    mutationKey: getMakePaymentClassicMutationKey(policyNumber),
  });

  const ensureSharedState = useCallback(() => {
    // ensure if we are expecting shared state, but we don't have it, we reset to start of flow
    if (!sharedState && policyNumber) {
      modalPath.init(policyNumber, 'payclassic', 'landing');
    }

    return !!sharedState;
  }, [modalPath, policyNumber, sharedState]);

  const handleClose = useCallback(async () => {
    setFinancialToken(null);
    modalPath.reset();
  }, [modalPath, setFinancialToken]);

  const title = 'Make a payment';
  let body;

  switch (modalPath.currentStep) {
    case MODAL_STEP.MODAL_EDIT:
      body = <MakePaymentEditClassic {...modalPath} />;
      break;
    case MODAL_STEP.MODAL_REVIEW:
      body = ensureSharedState() ? <MakePaymentEditPaymentClassic {...modalPath} /> : null;
      break;
    case MODAL_STEP.MODAL_CONFIRMATION:
      body = ensureSharedState() ? <MakePaymentConfirmation {...modalPath} /> : null;
      break;
    default:
      body = <MakePaymentEditClassic {...modalPath} />;
  }

  return (
    <FullScreenModal
      title={title}
      onCloseModal={handleClose}
      showLoadingOverlay={!!isPaymentClassicSubmitting}
    >
      {body}
    </FullScreenModal>
  );
};

const PaymentClassicModalIfEligible: React.FC = () => {
  const modalPath = useModalPath();
  const policyResponse = usePolicy(modalPath.policyNumber, { throwOnError: false });
  const productLine = getProductLineFromPolicyResponse(policyResponse.policyData);
  const isClassic = isLegacyPolicy(productLine, policyResponse.policyData?.policy.sourceSystemName);

  if (flagValues[FeatureFlags.PAYMENTS_CLASSIC] && !!modalPath.policyNumber && isClassic)
    return <PaymentClassicModalContent />;
  else return null;
};

export const PaymentClassicModal: React.FC = () => {
  const modalPath = useModalPath();

  if (modalPath.currentFlow === MODAL_FLOW.PAY_CLASSIC) return <PaymentClassicModalIfEligible />;
  else return null;
};
