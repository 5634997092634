import { useEffect, useState } from 'react';

interface HeadingsMap {
  [key: string]: IntersectionObserverEntry;
}
export interface Heading {
  id: string;
  title: string;
  items: Heading[];
}

export const enum ScrollSpyMarkerClasses {
  LEVEL_ONE = 'ecp-scrollspy-levelOne',
  LEVEL_TWO = 'ecp-scrollspy-levelTwo',
  FOOTER = 'ecp-scrollspy-footer',
}

// Observe headings to listen for when they scroll in and out of view
export const useIntersectionObserver = (
  setActiveId: React.Dispatch<React.SetStateAction<string>>,
  // Add an additional use effect dependency, if needed
  updateListDependency?: Array<boolean | string>,
): void => {
  useEffect(() => {
    let mapHeadingElements = {};
    const callback = (headings: IntersectionObserverEntry[]): void => {
      mapHeadingElements = headings.reduce(
        (map: HeadingsMap, headingElement: IntersectionObserverEntry) => {
          map[headingElement.target.id] = headingElement;

          return map;
        },
        mapHeadingElements,
      );

      const visibleHeadings = Object.values(mapHeadingElements as IntersectionObserverEntry).filter(
        (headingElement) => headingElement.isIntersecting,
      );
      const getIndexFromId = (id: string): number =>
        headingElements.findIndex((heading) => heading.id === id);

      if (visibleHeadings.length === 1) {
        setActiveId(visibleHeadings[0].target.id);
      } else if (visibleHeadings.length > 1) {
        const sortedVisibleHeadings = visibleHeadings.sort(
          (a, b) => getIndexFromId(a.target.id) - getIndexFromId(b.target.id),
        );
        setActiveId(sortedVisibleHeadings[0].target.id);
      }
    };

    const observer = new IntersectionObserver(callback, {
      rootMargin: '-10px 0px -10px 0px',
    });

    const headingElements = Array.from(
      document.querySelectorAll(
        `.${ScrollSpyMarkerClasses.LEVEL_ONE}, .${ScrollSpyMarkerClasses.LEVEL_TWO}, .${ScrollSpyMarkerClasses.FOOTER}`,
      ),
    ) as HTMLElement[];
    headingElements.forEach((element) => observer.observe(element));

    return () => observer.disconnect();
  }, [setActiveId, updateListDependency]);
};

const getTitle = (header: string | null, specialNames?: Record<string, string>): string => {
  if (specialNames) {
    const specialPageKeys = Object.keys(specialNames);
    if (specialPageKeys.includes(header)) return specialNames[header as keyof typeof specialNames];
  }

  return header || '';
};

// Hook that grabs all Elements which have needed classes and ids and returns list of nested elements for
// ScrollSpy component in specific format
export const useNestedHeadings = (
  // Add an additional use effect dependency, if needed
  updateListDependency?: Array<boolean | string>,
  specialTitleNames?: Record<string, string>,
): Heading[][] => {
  const [nestedHeadings, setNestedHeadings] = useState<Heading[]>([]);
  const [footers, setFooters] = useState<Heading[]>([]);
  useEffect(() => {
    const newNestedHeadings: Heading[] = [];
    const newFooters: Heading[] = [];

    const headingElements = Array.from(
      document.querySelectorAll(
        `.${ScrollSpyMarkerClasses.LEVEL_ONE}, .${ScrollSpyMarkerClasses.LEVEL_TWO}, .${ScrollSpyMarkerClasses.FOOTER}`,
      ),
    ) as HTMLElement[];

    headingElements.forEach((heading, index) => {
      const { textContent, className, id } = heading;
      const title = getTitle(textContent, specialTitleNames);
      if (className.split(' ').includes(ScrollSpyMarkerClasses.LEVEL_ONE) && !!heading.id) {
        newNestedHeadings.push({ id, title, items: [] });
      } else if (
        className.split(' ').includes(ScrollSpyMarkerClasses.LEVEL_TWO) &&
        newNestedHeadings.length > 0 &&
        !!heading.id
      ) {
        newNestedHeadings[newNestedHeadings.length - 1].items.push({
          id,
          title,
          items: [],
        });
      } else if (className.split(' ').includes(ScrollSpyMarkerClasses.FOOTER) && !!heading.id) {
        newFooters.push({ id, title, items: [] });
      }
    });
    setNestedHeadings(newNestedHeadings);
    setFooters(newFooters);
  }, [updateListDependency, specialTitleNames]);

  return [nestedHeadings, footers];
};
