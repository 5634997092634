import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PaymentsDueCardBody' })((theme) => ({
  root: {
    border: `1px solid ${theme.palette.other.border}`,
    borderRadius: 4,
    padding: 16,
  },
  borderedButton: {
    border: `1px solid ${theme.palette.secondary.main}`,
    height: 'fit-content',
    paddingBottom: 12,
    paddingTop: 12,
    width: '100%',
  },
  buttonWrapper: { width: '100%' },
}));
