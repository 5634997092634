import { useTheme as muiUseTheme } from '@mui/material';

import type { Theme } from '../theme';

export type UseTheme<T = Theme> = () => T;

/**
 * Adapter for MUI useTheme typed with our base theme,
 * so you don't need to pass Theme generic in every useTheme call.
 */
export const useTheme: UseTheme = muiUseTheme;
