import { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { upperFirst } from '@ecp/utils/common';

import { replaceUrlParamsPlaceholdersWithActualParameters } from '@ecp/features/servicing/shared/routing';

/**
 * An individual breadcrumb
 * Title is the text that is presented in the breadcrumb link
 * URL is a string that can be used to find which page to navigate to later
 */
interface Breadcrumb {
  title: string;
  url: string;
}

/**
 * A prop object for the useBreadcrumbs hook
 * The key is the term found in the application URL that will be replaced
 * The value is the string that will be the new title for the breadcrumb
 */
export interface BreadcrumbTitleOverrides {
  [urlStringToBeReplaced: string]: string;
}

/**
 * useBreadcrumbs - hook that returns the breadcrumbs for a path
 * @param pathname string of the path to return breadcrumbs for
 * @param breadcrumbTitleOverrides object that contains desired titles for breadcrumbs
 * @returns a list of breadcrumbs
 * Each breadcrumb has a title and a url.
 * Does not include the "first" element of the path.  This should usually mean the dashboard breadcrumb is removed.
 * This is removed because we never want to show a breadcrumb to the dashboard.
 */
export const useBreadcrumbs = (
  pathname: string,
  breadcrumbTitleOverrides?: BreadcrumbTitleOverrides,
): {
  breadcrumbs: Breadcrumb[];
} => {
  const pageNames = pathname.split('/').filter((e) => e.length > 0);

  const params = useParams();
  const invertedPagePathWithParams = useMemo(
    () => replaceUrlParamsPlaceholdersWithActualParameters(params),
    [params],
  );

  const breadcrumbs = pageNames.map<Breadcrumb>((pageName, index) => {
    let url = '/'.concat(pageNames.slice(0, index + 1).join('/'));
    const urlWithOpco = `${url}/${params.opco}`;
    if (invertedPagePathWithParams[urlWithOpco]) {
      url = urlWithOpco;
    }

    if (breadcrumbTitleOverrides && breadcrumbTitleOverrides[pageName]) {
      return {
        title: breadcrumbTitleOverrides[pageName],
        url,
      };
    }

    return {
      title: upperFirst(pageName),
      url,
    };
  });
  breadcrumbs.splice(0, 1); // remove the first breadcrumb (should usually be a dashboard breadcrumb link)

  return { breadcrumbs };
};
