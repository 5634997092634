import { merge } from '@ecp/utils/common';

import { getProductLineFromPolicyResponse } from '@ecp/features/servicing/shared/state';
import type {
  Coverage,
  Discount,
  DiscountSapiKey,
  HomeCoverage,
  PolicyDiscount,
  PropertyPolicyCoverages,
  UiPolicy,
} from '@ecp/features/servicing/shared/types';
import type { CoveragesFields } from '@ecp/features/shared/coverages';
import { getFieldStub, unknownCoverageKey } from '@ecp/features/shared/coverages';
import type { Product } from '@ecp/features/shared/product';
import type { Option, OptionsMetadata } from '@ecp/types';

const getKeyFromCode = (keyCodeMap: Record<string, string[]>, servicingCode = ''): string => {
  return (
    Object.entries(keyCodeMap).find(([, servicingCodes]) =>
      servicingCodes.includes(servicingCode),
    )?.[0] ?? `${unknownCoverageKey}.${servicingCode}`
  );
};

export const buildFields = (
  coverages: PropertyPolicyCoverages,
  keyCodeMap: Record<string, string[]>,
  isDeductible: boolean,
  isLegacy = false,
  groupMap?: Record<string, string[]>,
): CoveragesFields =>
  Object.entries(coverages ?? {})
    .filter(
      ([key, coverage]) =>
        (isDeductible && !!coverage?.deductible) || // building the deductible fields, then check each coverage for the deductible property
        (!isDeductible && !!coverage?.limit) || // building the coverages fields
        (!isDeductible && !coverage?.limit && key === 'earthquake') || // Earthquake will appear under coverages with "Coverage Selected" label even if no limit label presented
        (!isDeductible && !coverage?.limitLabel && !coverage?.deductibleLabel), // building coverages that have no limitLabel or deductibleLabel
    )
    .reduce((prev, curr) => {
      const term: Coverage | undefined = curr[1];
      const key = getKeyFromCode(keyCodeMap, curr[0]);

      if (!key) return prev;

      let value: string, label: string;
      if (isDeductible) {
        value = term?.deductible ?? '';
        label = term?.deductibleLabel ?? '';
      } else {
        value = term?.limit ?? term?.deductible ?? '';
        label = term?.limitLabel ?? 'Coverage Selected';
      }
      const name = key;
      const options: Option[] = [{ label, value }];
      const group = groupMap ? getKeyFromCode(groupMap, key) : undefined;

      if (isLegacy && (curr[0] === 'lossOfUse' || curr[0] === 'identityTheftExpense')) return prev;

      return {
        ...prev,
        [key]: merge({}, getFieldStub(key), {
          props: { name, options, value },
          question: { name, options },
          additionalMetaData: {
            title: curr[1]?.description || '',
            group,
          },
        }),
      };
    }, {} as CoveragesFields);

export const getDiscountDescs = (
  discounts: Discount[] | undefined = [],
  keyCodeMap: Record<string, HomeCoverage[]>,
  metadata: OptionsMetadata,
  state: string | undefined,
): string[] => {
  const discountCodes = discounts.map((discount) => getKeyFromCode(keyCodeMap, discount.code));

  return Object.keys(metadata)
    .filter((key) => discountCodes?.includes(key))
    .map((key: keyof typeof metadata) => {
      const stateOption = metadata[key].stateOptions?.[state ?? ''];
      if (stateOption) {
        Object.entries(stateOption).forEach(([k, v]) => {
          // Inferred type of v is a union of all possible OptionMetadata values
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          metadata[key][k] = v as any;
        });
      }

      return metadata[key].title;
    });
};

export const getMetadataProductKey = (policyResponse: UiPolicy): Product => {
  // will need to enhance this once we have other supported carriers - could potentially use brandData.uwCompany to handle dynamically, but we also might get it back once using SAPI v4
  const productLine = getProductLineFromPolicyResponse(policyResponse);
  if (productLine === 'AUTO') {
    return 'connect.auto';
  } else if (productLine === 'HOME') {
    if (policyResponse.coverageOptions[0]?.policyTypeCode === 'HO4') return 'homesite.renters';

    return 'homesite.home';
  } else {
    throw new Error(`Unsupported product line [${productLine}]`);
  }
};

export const getDiscountList = (
  policyDiscounts?: Partial<Record<DiscountSapiKey, PolicyDiscount>>,
): string[] | [] => {
  return Object.values(policyDiscounts ?? []).map((discount) => discount.discountName);
};
