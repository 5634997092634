import { CircularProgress } from '@mui/material';

import { Card } from '@ecp/features/servicing/shared/components';
import type { UsePaymentInfoReturn } from '@ecp/features/servicing/shared/state';
import {
  sortPoliciesInPaymentSection,
  usePaymentInfoForAllPolicies,
} from '@ecp/features/servicing/shared/state';

import { PaymentsDueCard } from './PaymentsDueCard';
import { useStyles } from './PaymentsDueSection.styles';

const shouldHideCard = ({
  paymentInfo: { canMakePayment, billingAccount, isClassicBillingAccountStatusInPendingCancel },
  isError,
  isLoading,
}: UsePaymentInfoReturn): boolean => {
  if (isClassicBillingAccountStatusInPendingCancel) return false;
  else return ((!canMakePayment || !billingAccount?.minimumDue) && !isError) || isLoading;
};

export const PaymentsDueSection: React.FC = () => {
  const { classes, cx } = useStyles();

  const { paymentInfoResults, isLoading } = usePaymentInfoForAllPolicies({
    throwOnError: false,
  });
  if (isLoading) return <CircularProgress aria-label='Loading...' />;
  const noPaymentsDueBody = (
    <span className={classes.paymentsDueText}>No payments due at this time</span>
  );
  const noPaymentsDueSection = (
    <Card className={classes.paymentsDueSection} body={noPaymentsDueBody} divider={false} />
  );

  const noPaymentsDue = paymentInfoResults?.every(shouldHideCard);

  const sortedPolicies = sortPoliciesInPaymentSection(paymentInfoResults);

  return (
    <ul className={cx(classes.root, !noPaymentsDue && classes.listWrapper)}>
      {noPaymentsDue ? (
        <li>{noPaymentsDueSection}</li>
      ) : (
        sortedPolicies
          ?.filter((a) => !shouldHideCard(a))
          ?.map((sortedPolicy) => (
            <li key={sortedPolicy.paymentInfo.policyNumber}>
              <PaymentsDueCard paymentInfoResult={sortedPolicy} />
            </li>
          ))
      )}
    </ul>
  );
};
