import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PaperlessUnenrollEdit' })((theme) => ({
  root: {},
  button: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 'fit-content',
    },
  },
  destinationValue: theme.typography.body1Bold,
}));
