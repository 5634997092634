import { Stack } from '@mui/material';

export const PaymentModalDisabledBody: React.FC = () => {
  return (
    <Stack spacing={2}>
      <h4>We're Sorry</h4>
      <p>
        Online payments is currently unavailable. You can make your payments through our automated
        phone system, or with one of our knowledgeable agents.
      </p>
    </Stack>
  );
};
