import { useParams } from 'react-router-dom';

import { EmailFraudConnect } from './connect/EmailFraudConnect';

export const EmailFraudPage: React.FC = () => {
  const { opco } = useParams();

  let page;
  switch (opco) {
    case 'connect':
      page = <EmailFraudConnect />;
      break;
    default:
      page = <p>Partner Specific Email Fraud.</p>;
  }

  return page;
};
