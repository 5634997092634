import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'VerifyIdentityPage' })((theme) => ({
  root: {},
  input: { paddingTop: theme.spacing(1) },
  buttonWrapper: {
    margin: '0px 9px 0px 9px',
  },
  confirmButton: {
    width: 'fit-content',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  expiryLabel: { fontWeight: 'bold' },
  secondaryLink: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.text.link,
    alignSelf: 'flex-start',
    [theme.breakpoints.down('md')]: {
      alignSelf: 'center',
    },
  },
  emailLabel: theme.typography.body3Bold,
}));
