import { Stack } from '@mui/material';

import { NavLink } from '@ecp/features/servicing/shared/components';
import { generateHelpPath, PAGE_PATH } from '@ecp/features/servicing/shared/routing';

import { DisclosureWrapper } from '../../../components';

export const ProtectYourselfConnect: React.FC = () => {
  return (
    <DisclosureWrapper
      title='How You Can Protect Yourself'
      breadcrumbTitleOverrides={{ protectyourself: 'Protect Yourself' }}
    >
      <Stack spacing={4}>
        <Stack spacing={2}>
          <h2>Practice protective behaviors</h2>
          <h3>Create strong passwords: </h3>
          <ul>
            <li>
              Include numbers, symbols and special characters and use longer passwords to make them
              harder to crack.
            </li>
            <li>Change your passwords regularly and don't use the same password more than once.</li>
            <li>
              Get creative. Think of a memorable phrase and use the first letter of each word as
              your password, converting some letters into numbers or symbols that resemble letters.
            </li>
            <li>Don't use the same password for multiple online accounts.</li>
            <li>
              Don't use your personal information, your login name, or adjacent keys on the keyboard
              as passwords.
            </li>
            <li>
              Don't share your password and don't use the "remember my password" option on any
              computer that is not used solely by you.
            </li>
            <li>
              Avoid common words. Some hackers use programs that try every word in the dictionary.
            </li>
            <li>
              Remember that we will never ask you to provide your password over the phone or through
              email. Be wary of anyone who does.
            </li>
          </ul>
        </Stack>
        <Stack spacing={2}>
          <h3>Be attentive when logging in and out of your accounts </h3>
          <ul>
            <li>
              Check to make sure the URL address of the page begins with "https" instead of just
              "http." The "s" indicates it is a secure page.
            </li>
            <li>
              Look for an image of a closed padlock near the URL address in your browser. You can
              click on the padlock to confirm the identity of the site you are visiting.
            </li>
            <li>
              Always log out and close your browser window or mobile application after signing out.
            </li>
          </ul>
        </Stack>
        <Stack spacing={2}>
          <h3>Be smart when surfing the Web:</h3>
          <ul>
            <li>
              Go to websites directly. The safest way to get to any site is to type the URL address
              into your browser directly. You can then bookmark it for quicker access on future
              visits.
            </li>
            <li>
              Before clicking on links (in emails, pop-ups, downloads, etc.), use the 'hover' test:
              Roll over the link with your mouse to display the URL. If the URL looks suspicious, do
              not click on it.
            </li>
            <li>
              Use the "Remember my User ID" feature (but never from a public or shared computer).
              This feature lets your computer remember your ID, so when you return to the site to
              log in again, your User ID will automatically display in the log in box. A fake site
              will not be able to display your User ID.
            </li>
          </ul>
        </Stack>
        <Stack spacing={2}>
          <h2>Get the right equipment and tools and keep them updated</h2>
          <h3>Use the most current version of a modern browser:</h3>
          <ul>
            <li>The most current version will have the strongest security measures in place.</li>
            <li>
              Older versions are not always supported and are more prone to security problems.
            </li>
          </ul>
        </Stack>
        <Stack spacing={2}>
          <h3>
            Install security software that includes anti-spyware, antivirus and firewall features:
          </h3>
          <ul>
            <li>Choose one that can protect you against all types of malware.</li>
            <li>
              Set it to scan on a regular basis – at least once a week – and every time you start
              your computer, if possible.
            </li>
            <li>
              Delete any software programs that your antivirus software detects that you don't want
              on your computer.
            </li>
          </ul>
        </Stack>
        <Stack spacing={2}>
          <h3>Use a backup service or drive to protect your data:</h3>
          <ul>
            <li>
              Backup your personal files regularly to an offline (hard drive/DVD) or offsite (backup
              service) destination.
            </li>
          </ul>
        </Stack>
        <Stack spacing={2}>
          <h2>Recognize potential threats</h2>
          <h3>Spyware</h3>
          <ul>
            <li>
              Spyware is software that is installed on your computing device without your consent to
              monitors the way you use your computing device. It can be used to record keystrokes,
              meaning the software could record your usernames and passwords.
            </li>
          </ul>
          <p>Clues that your computing device may be infected with spyware include:</p>
          <ul>
            <li>Onslaught of pop-up ads</li>
            <li>Your browser taking you to sites you did not enter in the address box</li>
            <li>A change to your Internet home page that you did not make</li>
            <li>New toolbars</li>
            <li>New icons in your browser window</li>
            <li>Keys that don't work properly</li>
            <li>Random error messages</li>
            <li>Slow performance when opening programs or saving files</li>
          </ul>
        </Stack>
        <Stack spacing={2}>
          <h3>Email fraud</h3>
          <p>
            Email fraud – or phishing – refers to phony emails that appear to be from well-known
            companies and are designed to deceive you into providing personal, financial or account
            information – such as account user names, passwords, credit card information and Social
            Security numbers.{' '}
          </p>
          <p>
            For tips on how to spot and report email fraud, see{' '}
            <NavLink
              to={generateHelpPath(PAGE_PATH.DISCLOSURES_EMAIL_FRAUD, 'connect')}
              trackingName='about_email_fraud_link'
              trackingLabel='about_email_fraud'
            >
              About email fraud
            </NavLink>
            .
          </p>
        </Stack>
      </Stack>
    </DisclosureWrapper>
  );
};
