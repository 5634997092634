import { useMemo } from 'react';

import { useMutation, useQueries, useQuery } from '@tanstack/react-query';

import { emptyArray, isTruthy } from '@ecp/utils/common';
import { flagValues } from '@ecp/utils/flags';

import type { UiPolicy } from '@ecp/features/servicing/shared/types';

import { QUERY_KEYS } from '../constants';
import type { ResponseObject, ServicingRequestError } from '../servicingRequest';
import type { UseUserOptions } from '../users';
import { useUser } from '../users';
import {
  getProductLineFromPolicyResponse,
  isLegacyPolicy,
  isPolicyAllowedToSetPaperless,
} from '../util';
import { getUiPolicy } from './../util/getUiPolicy';
import {
  addAdditionalInterest,
  deleteAdditionalInterest,
  getPolicyByPolicyNumber,
  updateAdditionalInterest,
  updateCaMileage,
  updateVin,
} from './api';
import type {
  AdditionalInterestErrorResponse,
  AdditionalInterestRequest,
  AdditionalInterestResult,
  BookRolledPolicy,
  DeleteAdditionalInterestRequest,
  GetPoliciesByPolicyNumberRequest,
  GetPoliciesByPolicyNumberResponse,
  GetPolicyByPolicyNumberResponse,
  UpdateCaMileageErrorResponse,
  UpdateCaMileageRequest,
  UpdateCaMileageResponse,
  UpdateCaMileageResult,
  UpdateVinErrorResponse,
  UpdateVinRequest,
  UpdateVinResponse,
  UpdateVinResult,
  UseAddAdditionalInterestResponse,
  UseBookrollStatusReturn,
  UseDeleteAdditionalInterestResponse,
  UseLinkedPoliciesReturn,
  UserPolicy,
  UseUpdateAdditionalInterestResponse,
} from './types';

const getPolicyKey = (policyNumber?: string): Array<unknown> => [QUERY_KEYS.POLICY, policyNumber];

/** getGetPolicy naming is intentional. We are getting a function whose job it is to get the policy. This allows for consistent get function for
 * both getting a single or multiple policies
 */
const getGetPolicy = (policyNumber?: string) => async (): Promise<UiPolicy> => {
  if (!policyNumber) throw Error('cannot call policy api without a policy number');
  const { payload: policy } = await getPolicyByPolicyNumber(policyNumber as string);

  return getUiPolicy(policy);
};

export const usePolicy = (
  policyNumber: string | undefined,
  options?: UsePoliciesOptions,
): GetPolicyByPolicyNumberResponse => {
  const getPolicy = getGetPolicy(policyNumber);
  const { data, isLoading, isError, isFetching, refetch } = useQuery({
    queryKey: getPolicyKey(policyNumber),
    queryFn: getPolicy,
    enabled: !!policyNumber,
    throwOnError: options?.throwOnError ?? true,
  });

  return useMemo(
    () => ({
      policyData: data,
      isLoading,
      isError,
      isFetching,
      refetch,
    }),
    [data, isError, isLoading, isFetching, refetch],
  );
};

export const useLinkedPolicies = (
  policyNumber: string | undefined,
  options?: { throwOnError?: boolean; unenrollingPaperless?: boolean },
): UseLinkedPoliciesReturn => {
  const throwOnError = options?.throwOnError ?? true;
  const unenrollingPaperless = options?.unenrollingPaperless ?? false;
  const { policies, isError, isLoading } = useUserPolicies({
    throwOnUserError: throwOnError,
    throwOnPolicyError: throwOnError,
  });
  const targetPolicy = policies.find(({ policy }) => policy.policyNumber === policyNumber);

  const linkedPolicies = policies
    .filter((policy) =>
      isLegacyPolicy(
        getProductLineFromPolicyResponse(policy),
        targetPolicy?.policy.sourceSystemName,
      ),
    )
    ?.filter(({ policy }) => policy.policyNumber !== policyNumber)
    .filter(({ policy }) => policy.primaryInsuredRefId === targetPolicy?.policy.primaryInsuredRefId)
    .filter((policy) => {
      const { policyAllowedToSetPaperless, policyAllowedToUnsetPaperless } =
        isPolicyAllowedToSetPaperless(policy);

      return unenrollingPaperless ? policyAllowedToUnsetPaperless : policyAllowedToSetPaperless;
    });

  return useMemo(
    () => ({
      linkedPolicies,
      isError,
      isLoading,
    }),
    [linkedPolicies, isError, isLoading],
  );
};

interface UsePoliciesOptions {
  /** set to 'false' to not fail on api failures */
  throwOnError?: boolean;
}
export const usePoliciesByPolicyNumber = (
  request: GetPoliciesByPolicyNumberRequest,
  options?: UsePoliciesOptions,
): GetPoliciesByPolicyNumberResponse => {
  const { policyNumbers = [] } = request;
  const queries = policyNumbers.map((policyNumber) => {
    return {
      queryKey: getPolicyKey(policyNumber),
      queryFn: getGetPolicy(policyNumber),
      throwOnError: options?.throwOnError ?? true,
    };
  });

  const results = useQueries({ queries });

  return useMemo(() => {
    const policies = results.map((result) => result.data).filter(isTruthy);

    return {
      policies: policies.length ? policies : (emptyArray as unknown as typeof policies),
      policyResults: results.map((result, index) => {
        return {
          policyData: result.data,
          policyNumber: policyNumbers[index],
          isError: result.isError,
          isFetching: result.isFetching,
          isLoading: result.isLoading,
          refetch: result.refetch,
        };
      }),
      isLoading: results.some((result) => result.isLoading),
      isError: results.some((result) => result.isError),
      isFetching: results.some((result) => result.isFetching),
    };
  }, [results, policyNumbers]);
};

export const useUserPolicies = (
  options?: Omit<UseUserOptions, 'throwOnError'> & {
    throwOnUserError?: boolean;
    throwOnPolicyError?: boolean;
  },
): UserPolicy => {
  const {
    user,
    isLoading: isUserLoading,
    isError: isUserError,
    isFetching: isFetchingUser,
  } = useUser({
    ...options,
    throwOnError: options?.throwOnUserError ?? true,
  });
  const {
    policies,
    policyResults,
    isLoading: isPolicyLoading,
    isError: isPolicyError,
    isFetching: isFetchingPoliciesByPolicyNumber,
  } = usePoliciesByPolicyNumber(
    {
      policyNumbers: user?.policyNumbers,
    },
    { throwOnError: options?.throwOnPolicyError ?? true },
  );

  return useMemo(
    () => ({
      isLoading: isUserLoading || isPolicyLoading,
      isError: isUserError || isPolicyError,
      user,
      policies,
      policyResults: policyResults.map((result) => ({
        ...result,
        isError: result.isError,
        isFetching: result.isFetching,
        isLoading: result.isLoading,
        refetch: result.refetch,
      })),
      isFetching: isFetchingUser || isFetchingPoliciesByPolicyNumber,
    }),
    [
      isPolicyError,
      isPolicyLoading,
      isUserError,
      isUserLoading,
      policies,
      policyResults,
      user,
      isFetchingUser,
      isFetchingPoliciesByPolicyNumber,
    ],
  );
};

export const useUpdateVin = (): UpdateVinResponse<
  UpdateVinRequest,
  ResponseObject<UpdateVinResult, UpdateVinErrorResponse>
> => {
  const {
    mutateAsync,
    isPending: isSubmitting,
    isError,
  } = useMutation({
    mutationFn: async (request: UpdateVinRequest) => {
      let updatePreferenceError: UpdateVinErrorResponse | undefined = undefined;
      let success: UpdateVinResult | undefined;

      await updateVin(request)
        .then((res) => {
          success = res.payload;
        })
        .catch((error: ServicingRequestError) => {
          success = undefined;
          updatePreferenceError = 'unknown';
        });

      const response: ResponseObject<UpdateVinResult, UpdateVinErrorResponse> = {
        success,
        error: updatePreferenceError,
      };

      return response;
    },
  });

  return {
    updateVin: mutateAsync,
    isLoading: false,
    isError,
    isSubmitting,
  };
};

export const useAddAdditionalInterest = (): UseAddAdditionalInterestResponse<
  AdditionalInterestRequest,
  ResponseObject<AdditionalInterestResult, AdditionalInterestErrorResponse>
> => {
  const {
    mutateAsync,
    isPending: isSubmitting,
    isError,
  } = useMutation({
    mutationFn: async (request: AdditionalInterestRequest) => {
      let addError: AdditionalInterestErrorResponse | undefined = undefined;
      let success: AdditionalInterestResult | undefined;

      await addAdditionalInterest(request)
        .then((res) => {
          success = res.payload;
        })
        .catch((error: ServicingRequestError) => {
          success = undefined;
          addError = 'unknown';
        });

      const response: ResponseObject<AdditionalInterestResult, AdditionalInterestErrorResponse> = {
        success: success,
        error: addError,
      };

      return response;
    },
  });

  return {
    addAdditionalInterest: mutateAsync,
    isLoading: false,
    isError,
    isSubmitting,
  };
};

export const useUpdateAdditionalInterest = (): UseUpdateAdditionalInterestResponse<
  AdditionalInterestRequest,
  ResponseObject<AdditionalInterestResult, AdditionalInterestErrorResponse>
> => {
  const {
    mutateAsync,
    isPending: isSubmitting,
    isError,
  } = useMutation({
    mutationFn: async (request: AdditionalInterestRequest) => {
      let updateError: AdditionalInterestErrorResponse | undefined = undefined;
      let success: AdditionalInterestResult | undefined;

      await updateAdditionalInterest(request)
        .then((res) => {
          success = res.payload;
        })
        .catch((error: ServicingRequestError) => {
          success = undefined;
          updateError = 'unknown';
        });

      const response: ResponseObject<AdditionalInterestResult, AdditionalInterestErrorResponse> = {
        success: success,
        error: updateError,
      };

      return response;
    },
  });

  return {
    updateAdditionalInterest: mutateAsync,
    isLoading: false,
    isError,
    isSubmitting,
  };
};

export const useDeleteAdditionalInterest = (): UseDeleteAdditionalInterestResponse<
  DeleteAdditionalInterestRequest,
  ResponseObject<AdditionalInterestResult, AdditionalInterestErrorResponse>
> => {
  const {
    mutateAsync,
    isPending: isSubmitting,
    isError,
  } = useMutation({
    mutationFn: async (request: DeleteAdditionalInterestRequest) => {
      let updateError: AdditionalInterestErrorResponse | undefined = undefined;
      let success: AdditionalInterestResult | undefined;

      await deleteAdditionalInterest(request)
        .then((res) => {
          success = res.payload;
        })
        .catch((error: ServicingRequestError) => {
          success = undefined;
          updateError = 'unknown';
        });

      const response: ResponseObject<AdditionalInterestResult, AdditionalInterestErrorResponse> = {
        success: success,
        error: updateError,
      };

      return response;
    },
  });

  return {
    deleteAdditionalInterest: mutateAsync,
    isLoading: false,
    isError,
    isSubmitting,
  };
};

export const useUpdateCaMileage = (): UpdateCaMileageResponse<
  UpdateCaMileageRequest,
  ResponseObject<UpdateCaMileageResult, UpdateCaMileageErrorResponse>
> => {
  const {
    mutateAsync,
    isPending: isSubmitting,
    isError,
  } = useMutation({
    mutationFn: async (request: UpdateCaMileageRequest) => {
      let updatePreferenceError: UpdateCaMileageErrorResponse | undefined = undefined;
      let success: UpdateCaMileageResult | undefined;

      await updateCaMileage(request)
        .then((res) => {
          success = res.payload;
        })
        .catch((error: ServicingRequestError) => {
          success = undefined;
          updatePreferenceError = 'unknown';
        });

      const response: ResponseObject<UpdateCaMileageResult, UpdateCaMileageErrorResponse> = {
        success,
        error: updatePreferenceError,
      };

      return response;
    },
  });

  return {
    updateCaMileage: mutateAsync,
    isLoading: false,
    isError,
    isSubmitting,
  };
};

export const useBookRollPolicies = (): UseBookrollStatusReturn => {
  const { policies, isError, isLoading } = useUserPolicies({ throwOnPolicyError: false });
  const isBookrollEnabled = flagValues.BOOKROLL;
  const bookRolledConnectPolicyData = policies.filter(
    (policyResponse) => policyResponse.isInBookRoll,
  );
  const bookRolledHomesitePolicies: BookRolledPolicy[] = [];
  const bookRolledConnectPolicies: BookRolledPolicy[] = [];

  // For each bookrolled Connect policy, push it to the list of bookrolledConnectPolicies.
  // If the Homesite policy replacing the bookrolled Connect policy exists, then push it to the Homesite list.
  bookRolledConnectPolicyData.forEach((connectPolicyData) => {
    const replacedBy = connectPolicyData.associatedPolicies?.find(
      (policy) => policy.relationshipTypeCode === 'LEGACY_CONVERTED_FROM',
    )?.associatedPolicyNumber;
    const replacedByPolicyData = policies.find(
      (policyData) => policyData.policy.policyNumber === replacedBy,
    );

    const connectBookRolledPolicy: BookRolledPolicy = {
      policyData: connectPolicyData,
      replacedBy,
      transitionStatus: connectPolicyData.policy.transition?.status,
    };

    if (isBookrollEnabled) bookRolledConnectPolicies.push(connectBookRolledPolicy);

    if (isBookrollEnabled && replacedByPolicyData) {
      bookRolledHomesitePolicies.push({
        policyData: replacedByPolicyData,
        replaces: connectBookRolledPolicy.policyData.policy.policyNumber,
        transitionStatus: connectBookRolledPolicy.transitionStatus,
      });
    }
  });

  return { bookRolledConnectPolicies, bookRolledHomesitePolicies, isError, isLoading };
};
