import { Divider, Stack } from '@mui/material';

import { useStyles } from './Section.styles';

export const Section: React.FC<
  { title: string; titleId?: string; titleClassName?: string } & React.PropsWithChildren
> = (props) => {
  const { children, title, titleId, titleClassName } = props;
  const { classes } = useStyles();

  return (
    <Stack spacing={1.5}>
      <h2 id={titleId} className={titleClassName}>
        {title}
      </h2>
      <Stack spacing={{ xs: 0, md: 3 }}>
        <Divider className={classes.sectionDivider} />
        {children}
      </Stack>
    </Stack>
  );
};
