import { type RefObject, useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

import { scrollToTop } from '@ecp/utils/web';

export const useScrollToRef = (
  isLoading: boolean,
  ref: RefObject<HTMLDivElement>,
  name: string,
): void => {
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isLoading) {
        if (ref.current && searchParams.get(name))
          window.scrollTo({
            top:
              ref.current.getBoundingClientRect().top - document.body.getBoundingClientRect().top,
            behavior: 'smooth',
          });
        else scrollToTop();
      }
    }, 10);

    return () => clearTimeout(timeout);
  }, [isLoading, name, ref, searchParams]);
};
