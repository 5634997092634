import { Stack } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';

import {
  DashboardCardContent,
  ServicingFooter,
  ServicingHeader,
} from '@ecp/features/servicing/shared/components';

import { GraphicError } from './graphicError';
import { useStyles } from './MaintenancePage.styles';

const FooterErrorFallback: React.FC = () => null;

export const MaintenancePage: React.FC = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <ServicingHeader />
      <DashboardCardContent classes={{ root: classes.body }}>
        <Stack spacing={4} direction={{ xs: 'column', lg: 'row-reverse' }}>
          <div className={classes.iconWrapper}>
            <GraphicError className={classes.icon} />
          </div>
          <Stack spacing={1.5} className={classes.textWrapper}>
            <h2>Our site is temporarily down for maintenance</h2>
            <p>
              As we work to improve your experience, services are currently unavailable. Please
              check back soon. Thank you for your patience.
            </p>
          </Stack>
        </Stack>
      </DashboardCardContent>
      <ErrorBoundary FallbackComponent={FooterErrorFallback}>
        <ServicingFooter isLoggedIn={false} />
      </ErrorBoundary>
    </div>
  );
};
