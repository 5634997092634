import TagManager from 'react-gtm-module';

import * as interactionId from '@ecp/utils/analytics/interaction-id';

import type { env } from '@ecp/env';

import type { TrackingDimensionsNames, TrackingDimensionsObject } from './dimensions';
import { TrackingDimensions } from './dimensions';
import type { TrackingRequest } from './types';

/**
 * This object acts as a replacement for custom GA dimensions in the context of GA4.
 * The object gets populated as the app progresses via setDimension.
 * It's dynamic entries get passed in every `track` call.
 */
export const GLOBAL_GA_DIMENSIONS: TrackingDimensionsObject = {};

export const setGaDimension = (dimension: TrackingDimensionsNames, value: unknown): void => {
  GLOBAL_GA_DIMENSIONS[dimension] = String(value);
};

export const trackGa = ({ category, action, label, dimensions }: TrackingRequest): void => {
  const dataLayer = {
    event: 'event',
    ...GLOBAL_GA_DIMENSIONS,
    ...dimensions,
    eventProps: {
      category,
      action,
      label,
    },
  };

  TagManager.dataLayer({ dataLayer });
};

// !TODO Revisit implementation
export const logGaPageView = (path: string, title?: string): void => {
  // Set page in GA for events
  // ReactGA.set({ page: path });
  // ReactGA.pageview(path, [], title);
  const dataLayer = {
    event: 'pageview',
    ...GLOBAL_GA_DIMENSIONS,
    page: {
      url: path,
      title,
    },
  };

  TagManager.dataLayer({ dataLayer });
};

let initialized = false;

/**
 * Sets up Google Tag Manager by adding its snippets to HTML document.
 * So it's ready to enqueue (via dataLayer) any events without waiting for any tags to load.
 * A tag is usually a script consuming events from dataLayer, like Google Analytics.
 * Analysts are in control of what tags get loaded, which events each tag consumes and mapping between eventProps and tag's internal event shape.
 * Currently we load only Google Analytics.
 */
export const initializeGa = (
  gtmId: string,
  runtimeEnv?: typeof env.runtimeEnv,
  expId?: string,
): void => {
  if (initialized) return;

  setGaDimension(TrackingDimensions.USER_AGENT, window.navigator.userAgent);
  setGaDimension(TrackingDimensions.INTERACTION_ID, interactionId.get());
  if (runtimeEnv && expId)
    setGaDimension(TrackingDimensions.ENV_AND_EXPERIENCE_ID, `${runtimeEnv}-${expId}`);
  TagManager.initialize({ gtmId, dataLayer: { ...GLOBAL_GA_DIMENSIONS } });
  if (expId) {
    setGaDimension(TrackingDimensions.EXPERIENCE_ID_INITIAL, expId);
    setGaDimension(TrackingDimensions.EXPERIENCE_ID_CURRENT, expId);
  }
  initialized = true;
};
