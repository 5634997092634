import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PolicyIconSummaryCard' })((theme) => ({
  root: { height: 'auto' },
  date: { whiteSpace: 'nowrap' },
  inactiveAlert: theme.typography.body1Bold,
  policyDetailsSection: {
    paddingTop: 12,
  },
  icon: {
    ...theme.mixins.setColorSvg(theme.palette.grey[900]),
    height: 24,
    width: 24,
    flexShrink: 0,
  },
  iconCalendar: {
    height: 18,
    margin: 3,
    width: 18,
  },
  policyDetail: theme.typography.body1,
  button: {
    width: '100%',
    [theme.breakpoints.up('lg')]: { width: '50%' },
  },
  pendingCancelAlert: theme.typography.body1Bold,
  personSection: { [theme.breakpoints.down('lg')]: { display: 'none' } },
  policyPeriod: theme.typography.body1Bold,
}));
