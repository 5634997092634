import * as yup from 'yup';

import { isPastDate } from '@ecp/utils/date';
import type { YupSchemaMap } from '@ecp/utils/form';

export interface MakePaymentFormInputs {
  paymentAmount?: string;
  paymentAccount?: string;
  enrollAutopay?: string;
  ccExpirationDate?: string;
}

export const makePaymentSchema: YupSchemaMap<MakePaymentFormInputs> = {
  paymentAmount: yup.string().required('Payment amount is required'),
  paymentAccount: yup.string().when('ccExpirationDate', (ccExpirationDate) =>
    yup
      .string()
      .required('Payment account is required')
      .test('expiredCard', 'Expired card', (value) =>
        value ? !isPastDate(ccExpirationDate) : true,
      ),
  ),
  ccExpirationDate: yup.string(),
  enrollAutopay: yup.string(),
};
