import { memo, useCallback, useMemo } from 'react';

import { CircularProgress, Divider, Stack } from '@mui/material';

import { maskEmail } from '@ecp/utils/common';
import { DateConstants, formatDate } from '@ecp/utils/date';
import { flagValues } from '@ecp/utils/flags';
import { datadogLog } from '@ecp/utils/logger';

import { Button, Snackbar } from '@ecp/components';
import {
  AddACard,
  AddressDisplay,
  ApiAlert,
  InfoCardList,
} from '@ecp/features/servicing/shared/components';
import { MODAL_FLOW, MODAL_STEP, useModalPath } from '@ecp/features/servicing/shared/routing';
import {
  getAddressByRefId,
  getAddressString,
  getDwellingAddress,
  getMailingAddress,
  getPersonByRefId,
  getProductLineFromPolicyResponse,
  isLegacyPolicy,
  isPolicyAllowedToSetPaperless,
  usePaperless,
  usePolicy,
  usePreferences,
  useSharedState,
  useUserPolicies,
} from '@ecp/features/servicing/shared/state';
import { IconCardResponsibleHomeOwner, IconUICheck, IconUIEdit } from '@ecp/themes/base';

import { AdditionalInterestCard } from '../AdditionalInterestCard';
import { Membership } from './Membership/Membership';
import { useStyles } from './PolicyDetails.styles';

interface Props {
  policyNumber: string;
}

export const PolicyDetails: React.FC<Props> = memo((props) => {
  const { classes } = useStyles();
  const { policyNumber } = props;
  const { policyData, isLoading: isPolicyLoading } = usePolicy(policyNumber);
  const modalPath = useModalPath();
  const {
    isPaperlessOn,
    isError: isErrorPreferences,
    isLoading: isLoadingPaperless,
  } = usePreferences(policyNumber, {
    throwOnError: false,
  });
  const productLine = getProductLineFromPolicyResponse(policyData);
  const { isLoading: isLoadingUserPolicies } = useUserPolicies({
    throwOnPolicyError: false,
  });

  const [toastMessage, setToastMessage] = useSharedState<string | null>('snackBar');
  const handleUpdateLandlordMessageClose = useCallback(
    () => setToastMessage(null),
    [setToastMessage],
  );

  useMemo(() => {
    const firstVehicleAddress = getAddressByRefId(
      policyData,
      policyData?.vehicles?.[0]?.garagingAddressRefId,
    );
    const firstVehicleAddressString = getAddressString(firstVehicleAddress);

    const isOneAddressForAllVehicles = policyData?.vehicles?.every((vehicle) => {
      const currentVehicleAddress = getAddressByRefId(policyData, vehicle.garagingAddressRefId);

      return firstVehicleAddressString === getAddressString(currentVehicleAddress);
    });
    if (!isOneAddressForAllVehicles && !!policyData?.vehicles?.length && productLine === 'AUTO') {
      datadogLog({
        logType: 'info',
        message: 'Multiple vehicles with different garaged address',
        context: {
          logOrigin:
            'libs/features/servicing/policydetails/src/components/DetailsSection/PolicyDetails.tsx',
          functionOrigin: 'isOneAddressForAllVehicles',
        },
      });
    }
  }, [policyData, productLine]);

  const policyAddress =
    productLine === 'AUTO'
      ? getAddressByRefId(policyData, policyData?.vehicles?.at(0)?.garagingAddressRefId)
      : getDwellingAddress(policyData);

  const primaryNamedInsured = getPersonByRefId(policyData, policyData?.policy.primaryInsuredRefId);

  const secondaryNamedInsured = getPersonByRefId(
    policyData,
    policyData?.policy.secondaryInsuredRefId,
  );

  const namedInsureds = `${primaryNamedInsured?.firstName} ${primaryNamedInsured?.lastName}${
    secondaryNamedInsured
      ? ` and ${secondaryNamedInsured?.firstName} ${secondaryNamedInsured?.lastName}`
      : ''
  }`;

  const handlePaperlessLinkClick = useCallback(() => {
    isPaperlessOn
      ? modalPath.init(policyNumber, 'paperlessunenroll', MODAL_STEP.MODAL_LANDING)
      : modalPath.init(policyNumber, 'paperlessenroll', MODAL_STEP.MODAL_LANDING);
  }, [modalPath, policyNumber, isPaperlessOn]);

  const paperlessPreferenceText = isPaperlessOn
    ? 'Paperless notifications to the email above'
    : 'By mail to the address above';

  const paperlessPreferenceBody = isErrorPreferences ? (
    <ApiAlert apiDescription='document delivery information' />
  ) : isLoadingPaperless ? (
    <CircularProgress aria-label='Loading...' />
  ) : (
    <p>{paperlessPreferenceText}</p>
  );

  const emailAddress = maskEmail(primaryNamedInsured?.email);

  const { isAnyActivePolicyNotAllowingPaperless } = usePaperless({
    throwOnError: false,
  });

  const isRenters = policyData?.coverageOptions[0].policyTypeCode === 'HO4';
  const additionalInterests =
    policyData?.thirdPartyInterests?.filter(
      (thirdPartyInterest) => thirdPartyInterest.thirdPartyInterestType === 'AdditionalInterest',
    ) || [];
  const isClassic = isLegacyPolicy(productLine, policyData?.policy.sourceSystemName);

  const showAdditionalInterest =
    isRenters &&
    !isClassic &&
    flagValues.ADDITIONAL_INTEREST_SECTION &&
    (flagValues.ADDITIONAL_INTEREST_MANAGE || additionalInterests.length > 0);

  // Allow up to 2 additional interests to be displayed.
  additionalInterests.splice(2);

  const additionalInterestCards = additionalInterests.map((landLord) => (
    <AdditionalInterestCard
      key={landLord.thirdPartyInterestId}
      description={landLord.name}
      thirdPartyInterestRole={landLord.thirdPartyInterestRole}
    />
  ));

  const { policyAllowedToSetPaperless, policyAllowedToUnsetPaperless } =
    isPolicyAllowedToSetPaperless(policyData);
  const isSetPaperlessAllowed =
    !isAnyActivePolicyNotAllowingPaperless && policyAllowedToSetPaperless;
  const isUnsetPaperlessAllowed = policyAllowedToUnsetPaperless && isPaperlessOn;

  return (
    <Stack spacing={{ xs: 2, md: 3 }}>
      <Stack spacing={2}>
        <Stack spacing={2}>
          <h3>Policy information</h3>
          <Stack
            spacing={{ xs: 2, md: 4 }}
            direction={{ xs: 'column', md: 'row' }}
            className={classes.container}
          >
            <Stack spacing={0.5}>
              <p className={classes.subHeader}>Policy number</p>
              <p>{policyData?.policy.policyNumber}</p>
            </Stack>
            <Stack spacing={0.5}>
              <p className={classes.subHeader}>Policy period</p>
              <p>
                <span className={classes.date}>
                  {formatDate(
                    policyData?.policy.periodStartDate,
                    DateConstants.MONTH_FULL_NAME_LONG_FORMAT,
                  )}
                </span>{' '}
                -{' '}
                <span className={classes.date}>
                  {formatDate(
                    policyData?.policy.periodEndDate,
                    DateConstants.MONTH_FULL_NAME_LONG_FORMAT,
                  )}
                </span>
              </p>
            </Stack>
            <Stack spacing={0.5}>
              <p className={classes.subHeader}>
                {productLine === 'AUTO' ? 'Garaged' : 'Insured'} address
              </p>
              <AddressDisplay policyAddress={policyAddress} />
            </Stack>
          </Stack>
        </Stack>
        <Stack
          spacing={{ xs: 2, md: 4 }}
          direction={{ xs: 'column', md: 'row' }}
          className={classes.twoItemsContainer}
        >
          <Stack spacing={0.5}>
            <p className={classes.subHeader}>Named insureds</p>
            <p>{namedInsureds}</p>
          </Stack>
          {flagValues.MEMBERSHIP && <Membership policyNumber={policyNumber} />}
        </Stack>
      </Stack>
      <Divider />
      <Stack spacing={2}>
        <h3>Contact information</h3>
        <Stack spacing={2}>
          <Stack
            spacing={{ xs: 2, md: 4 }}
            direction={{ xs: 'column', md: 'row' }}
            className={classes.container}
          >
            <Stack spacing={0.5}>
              <p className={classes.subHeader}>Phone number</p>
              {primaryNamedInsured?.phoneNumbers?.map((phone, index) => {
                return (
                  <p key={index}>
                    {phone.phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}
                  </p>
                );
              })}
            </Stack>
            <Stack className={classes.email} spacing={0.5}>
              <p className={classes.subHeader}>Email</p>
              <p>{emailAddress}</p>
            </Stack>
            <Stack spacing={0.5}>
              <p className={classes.subHeader}>Mailing address</p>
              <AddressDisplay policyAddress={getMailingAddress(policyData)} />
            </Stack>
          </Stack>
          {(isSetPaperlessAllowed || isUnsetPaperlessAllowed) &&
            !isPolicyLoading &&
            !isLoadingUserPolicies && (
              <Stack spacing={0.5}>
                <Stack className={classes.titleBody} spacing={1} direction='row'>
                  <p className={classes.subHeader}>Document delivery preferences</p>
                  {!isErrorPreferences && (
                    <Button
                      className={classes.link}
                      variant='iconText'
                      onClick={handlePaperlessLinkClick}
                      icon={<IconUIEdit />}
                      trackingLabel='edit_continue'
                      trackingName='edit_link'
                      objectType='link'
                    >
                      Edit
                    </Button>
                  )}
                </Stack>
                {paperlessPreferenceBody}
              </Stack>
            )}
        </Stack>
      </Stack>
      {showAdditionalInterest && (
        <Stack spacing={3}>
          <Divider />
          <InfoCardList
            title='Landlords and third parties'
            id='additionalInterest'
            tooltip='This allows us to share your proof of insurance and notify about any changes to your policy.'
          >
            {additionalInterestCards}
            {additionalInterests.length <= 1 && flagValues.ADDITIONAL_INTEREST_MANAGE && (
              <AddACard
                icon={<IconCardResponsibleHomeOwner />}
                btnText='Add a landlord'
                flow={MODAL_FLOW.ADDITIONAL_INTEREST}
                step={MODAL_STEP.MODAL_EDIT}
                trackingLabel='add_landlord'
                trackingName='add_landlord_link'
                objectType='link'
              />
            )}
          </InfoCardList>
        </Stack>
      )}
      <Snackbar
        classes={{ root: classes.snackbar }}
        message={
          <Stack direction='row' spacing={1}>
            <div className={classes.iconContainer}>
              <IconUICheck className={classes.icon} />
            </div>
            <span>{toastMessage}</span>
          </Stack>
        }
        open={!!toastMessage}
        onClose={handleUpdateLandlordMessageClose}
        vertical='top'
        horizontal='center'
      />
    </Stack>
  );
});
