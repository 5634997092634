import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CardPaymentMethod' })((theme) => ({
  root: {},
  label: {
    '& .MuiFormLabel-root': {
      paddingTop: 16,
      paddingBottom: 3,
      marginLeft: 0,
    },
  },
  iconsSection: {
    background: theme.palette.other.backgroundContrastText,
    borderRadius: 4,
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      alignItems: 'start',
    },
  },
  iconTitle: {
    ...theme.typography.body3,
    [theme.breakpoints.up('md')]: {
      ...theme.typography.body1,
    },
  },
  image: {
    width: '100%',
    maxHeight: 50,
  },
  groupLabelStack: { alignItems: 'center', paddingBottom: 0 },
  fields: { maxWidth: 360 },
}));
