import { useCallback, useEffect, useState } from 'react';

import { Divider, Stack } from '@mui/material';

import { Form, useField, useForm } from '@ecp/utils/form';

import { Alert, Button, NumberFormat, Snackbar } from '@ecp/components';
import { AuthenticationCard, LoadingButton } from '@ecp/features/servicing/shared/components';
import {
  PAGE_PATH,
  QUERY_PARAMS_AND_VALUE,
  usePageFlow,
} from '@ecp/features/servicing/shared/routing';
import {
  useSharedState,
  useValidateUser,
  useVerifyOneTimeCode,
  useVerifyUserIdentity,
} from '@ecp/features/servicing/shared/state';
import type {
  VerifyOneTimeCodeRequest,
  VerifyOneTimeCodeSuccess,
  VerifyUserIdentityRequest,
} from '@ecp/features/servicing/shared/state';
import type { EmailOption } from '@ecp/features/servicing/shared/types';
import { IconUICheck } from '@ecp/themes/base';

import type { OneTimeCodeFormInputs } from './OneTimeCode.schema';
import { oneTimeCodeSchema } from './OneTimeCode.schema';
import { useStyles } from './OneTimeCodePage.styles';

export type OneTimeCodeProps = {
  recoverEmail?: boolean;
  recoverPassword?: boolean;
};

export const OneTimeCodePage: React.FC<OneTimeCodeProps> = (props) => {
  const { recoverEmail = false, recoverPassword = false } = props;
  const { classes, cx } = useStyles();
  const pageFlow = usePageFlow();
  const [sharedStateEmail] = useSharedState<EmailOption>('SelectedEmail');
  const { verifyOneTimeCode } = useVerifyOneTimeCode();
  const { verifyUserIdentity } = useVerifyUserIdentity();
  const { validateData } = useValidateUser();

  const handleBack = useCallback(async () => {
    pageFlow.goBack();
  }, [pageFlow]);

  const formContext = useForm({
    validations: oneTimeCodeSchema,
  });

  const { handleSubmit } = formContext;

  const oneTimeCodeField = useField({
    formContext,
    name: 'otp',
  });

  useEffect(() => {
    // If user refresh the page and validateData is undefined user redirect to createAccount/resetaccount page
    if (!validateData) {
      if (recoverEmail) pageFlow.navigate(PAGE_PATH.RECOVER_EMAIL);
      else if (recoverPassword) pageFlow.navigate(PAGE_PATH.RECOVER_PASSWORD);
      else pageFlow.navigate(PAGE_PATH.CREATE_ACCOUNT);
    }
  }, [validateData, pageFlow, recoverEmail, recoverPassword]);
  const [, setOtpVerifiedData] = useSharedState<Partial<VerifyOneTimeCodeSuccess>>(
    'OtpVerifiedData',
    {
      loginId: '',
      userId: '',
    },
  );
  const onSubmit = useCallback(() => {
    handleSubmit(async (data) => {
      if (validateData) {
        const { otp } = data as Required<OneTimeCodeFormInputs>;
        const verifyOneTimeCodeRequest: VerifyOneTimeCodeRequest = {
          otp: otp.toString(),
          userInfo: validateData.userInfo.payload,
        };
        const { error, success } = await verifyOneTimeCode(verifyOneTimeCodeRequest);
        if (error) {
          if (error === 'INVALID_OTP_CODE') {
            formContext.setError('otp', { message: 'Incorrect code, please try again' });
          } else if (error === 'EXPIRED_USER_TOKEN')
            pageFlow.navigate({
              pathname: PAGE_PATH.LOGIN,
              search: QUERY_PARAMS_AND_VALUE.TIMEOUT_TRUE,
            });
        } else if (success?.otpVerified) {
          setOtpVerifiedData({ loginId: success.loginId ?? '', userId: success.userId ?? '' });

          pageFlow.goForward();
        }
      }
    })();
  }, [handleSubmit, verifyOneTimeCode, setOtpVerifiedData, formContext, pageFlow, validateData]);

  const [showResendMessage, setShowResendMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handleResendCode = useCallback(async () => {
    if (validateData && sharedStateEmail?.value) {
      const verifyUserIdentityRequest: VerifyUserIdentityRequest = {
        userInfo: validateData?.userInfo.payload,
        factor: 'email',
        value: sharedStateEmail?.value,
      };
      const { error, success } = await verifyUserIdentity(verifyUserIdentityRequest);

      if (error) {
        setShowErrorMessage(true);
        setShowResendMessage(false);
      } else if (success?.otpSend) {
        setShowErrorMessage(false);
        setShowResendMessage(true);
      }
      oneTimeCodeField.value = '';
    }
  }, [oneTimeCodeField, validateData, sharedStateEmail?.value, verifyUserIdentity]);
  const handleResendMessageClose = useCallback(() => setShowResendMessage(false), []);
  const handleErrorMessageClose = useCallback(() => setShowErrorMessage(false), []);

  const pageTitle = 'Enter your one-time code';
  const showTryDifferentMethod = (validateData?.emails ?? []).length > 1;

  const body = (
    <>
      {showErrorMessage && (
        <Alert withIcon type='error' withAction onClose={handleErrorMessageClose}>
          <p>
            We apologize, but we encountered an error. Please try again now, or wait a few minutes
            and try again later.
          </p>
        </Alert>
      )}
      <Snackbar
        classes={{ root: classes.snackbar }}
        message={
          <Stack direction='row' className={classes.snackbarContent} spacing={0.5}>
            <div className={classes.iconContainer}>
              <IconUICheck className={classes.icon} />
            </div>
            <span className={classes.snackbarMessage}>
              Your one-time challenge code has been sent to {sharedStateEmail?.name}.
            </span>
          </Stack>
        }
        open={showResendMessage}
        onClose={handleResendMessageClose}
        vertical='top'
        horizontal='center'
      />
      <Form
        formProviderProps={formContext}
        onSubmit={onSubmit}
        name='oneTimeCodeForm'
        className={classes.root}
      >
        <Stack spacing={4}>
          <Stack spacing={2}>
            <h1>{pageTitle}</h1>
            <p>
              <span className={classes.p}>
                Enter the one-time code sent to {sharedStateEmail?.name}.{' '}
                <b>This code will remain active for 5 minutes.</b>
              </span>
            </p>
          </Stack>
          <NumberFormat
            {...oneTimeCodeField}
            label='One-time code'
            format='######'
            className={classes.input}
          />
          <Stack
            direction={{ xs: 'column', md: showTryDifferentMethod ? 'column' : 'row' }}
            spacing={4}
          >
            <LoadingButton
              type='submit'
              className={classes.confirmButton}
              trackingLabel='verify_code'
              trackingName='verify_code_button'
            >
              VERIFY CODE
            </LoadingButton>
            <Stack
              direction={{ xs: 'column', md: showTryDifferentMethod ? 'row' : 'column' }}
              className={cx(showTryDifferentMethod ? classes.links : classes.link)}
              divider={
                showTryDifferentMethod && (
                  <Divider
                    orientation='vertical'
                    flexItem
                    variant='middle'
                    className={classes.divider}
                  />
                )
              }
              spacing={showTryDifferentMethod ? 2 : 0}
            >
              <Button
                className={classes.secondaryLink}
                variant='iconText'
                onClick={handleResendCode}
                aria-label='Send a new code'
                trackingName='send_new_code_button'
                trackingLabel='send_new_code'
              >
                Send a new code
              </Button>
              {showTryDifferentMethod && (
                <Stack direction='row' spacing={2}>
                  <Button
                    className={classes.secondaryLink}
                    variant='iconText'
                    onClick={handleBack}
                    aria-label='Try a different method'
                    trackingName='try_a_different_method_link'
                    trackingLabel='try_a_different_method'
                    objectType='link'
                  >
                    Try a different method
                  </Button>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Form>
    </>
  );

  return <AuthenticationCard onBack={handleBack}>{body}</AuthenticationCard>;
};
