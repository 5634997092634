import { useMemo } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { AuthError, userAuth } from '@ecp/utils/auth';
import { datadogLog } from '@ecp/utils/logger';
import { localStorage } from '@ecp/utils/storage';

import type { ErrorCode } from '@ecp/types';

import { localStorageKeys } from '../constants';
import { QUERY_KEYS } from '../constants';
import type { ResponseObject, ServicingRequestError, ServicingResponse } from '../servicingRequest';
import {
  changeEmail,
  changePassword,
  createPassword,
  forgotPassword,
  getUser,
  validateUser,
  verifyOneTimeCode,
  verifyUserIdentity,
} from './api';
import type {
  ChangeEmailError,
  ChangeEmailRequest,
  ChangeEmailResponse,
  ChangeEmailSuccess,
  ChangePasswordError,
  ChangePasswordRequest,
  ChangePasswordResponse,
  ChangePasswordSuccess,
  CreatePasswordError,
  CreatePasswordRequest,
  CreatePasswordResponse,
  CreatePasswordSuccess,
  ForgotPasswordError,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  ForgotPasswordSuccess,
  GetUserResponse,
  GetValidateUserResponse,
  LoginError,
  LoginResponse,
  LoginSuccess,
  LoginUserRequest,
  ValidateUserError,
  ValidateUserRequest,
  ValidateUserSuccess,
  VerifyOneTimeCodeError,
  VerifyOneTimeCodeRequest,
  VerifyOneTimeCodeResponse,
  VerifyOneTimeCodeSuccess,
  VerifyUserIdentityError,
  VerifyUserIdentityRequest,
  VerifyUserIdentityResponse,
  VerifyUserIdentitySuccess,
} from './types';

export interface UseUserOptions {
  /** set to `true` for components that don't require user authentication. Will disable react query from calling the api */
  ignoreUserAuth?: boolean;
  /** set to 'false' to not fail on api failures */
  throwOnError?: boolean;
}

export const useUser = (
  { ignoreUserAuth = false, throwOnError = true }: UseUserOptions = {
    ignoreUserAuth: false,
    throwOnError: true,
  },
): GetUserResponse => {
  const { data, isLoading, isError, refetch, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.USER, userAuth.userId],
    queryFn: async () => {
      const { payload } = await getUser({ id: userAuth.userId as string });

      return payload;
    },
    enabled: !ignoreUserAuth || userAuth.isAuth,
    throwOnError,
  });

  return useMemo(
    () => ({
      isLoading,
      isError,
      user: data,
      refetch,
      isFetching,
    }),
    [isError, isLoading, data, refetch, isFetching],
  );
};

export const useValidateUser = (): GetValidateUserResponse<
  ValidateUserRequest,
  ResponseObject<ValidateUserSuccess, ValidateUserError>
> => {
  const queryClient = useQueryClient();
  const {
    mutateAsync,
    isPending: isSubmitting,
    isError,
  } = useMutation({
    mutationFn: async (request: ValidateUserRequest) => {
      let success: ValidateUserSuccess | undefined;
      let validateUserError: ValidateUserError | undefined;

      const expectedCodes = [404001, 404004, 404010] as const;
      await validateUser(request, { dontLogErrorsForErrorCode: expectedCodes })
        .then((res) => {
          success = res.payload;
          success.isLockedAccount = !success.accountActive && success.errorCode === 401011;
          queryClient.setQueryData([QUERY_KEYS.VALIDATE_USER], success);
        })
        .catch((error: ServicingRequestError) => {
          const errorCode = error.errorStack?.status.messages[0]
            .code as (typeof expectedCodes)[number];
          switch (errorCode) {
            case 404001:
              validateUserError = 'POLICY_NOT_FOUND';
              break;
            case 404004:
            case 404010:
              validateUserError = 'USER_NOT_FOUND';
              break;
            default:
              throw error;
          }
          success = undefined;
        });

      const response: ResponseObject<ValidateUserSuccess, ValidateUserError> = {
        success,
        error: validateUserError,
      };

      return response;
    },
  });

  return {
    validateUserAccount: mutateAsync,
    isLoading: false,
    isError,
    isSubmitting,
    validateData: queryClient.getQueryData([QUERY_KEYS.VALIDATE_USER]),
  };
};

export const useVerifyUserIdentity = (): VerifyUserIdentityResponse<
  VerifyUserIdentityRequest,
  ResponseObject<VerifyUserIdentitySuccess, VerifyUserIdentityError>
> => {
  let success: VerifyUserIdentitySuccess | undefined;
  let verifyUserIdentityError: VerifyUserIdentityError | undefined;

  const {
    mutateAsync,
    isPending: isSubmitting,
    isError,
  } = useMutation({
    mutationFn: async (request: VerifyUserIdentityRequest) => {
      const expectedCodes = [400019] as const;
      await verifyUserIdentity(request, { dontLogErrorsForErrorCode: expectedCodes })
        .then((response) => {
          success = response.payload;
        })
        .catch((error: ServicingRequestError) => {
          const errorCode = error.errorStack?.status.messages[0]
            .code as (typeof expectedCodes)[number];
          switch (errorCode) {
            case 400019:
              verifyUserIdentityError = 'EXPIRED_USER_TOKEN';
              break;
            default:
              throw error;
          }
          success = undefined;
        });

      const response: ResponseObject<VerifyUserIdentitySuccess, VerifyUserIdentityError> = {
        success,
        error: verifyUserIdentityError,
      };

      return response;
    },
  });

  return {
    verifyUserIdentity: mutateAsync,
    isLoading: false,
    isError,
    isSubmitting,
  };
};

export const useVerifyOneTimeCode = (): VerifyOneTimeCodeResponse<
  VerifyOneTimeCodeRequest,
  ResponseObject<VerifyOneTimeCodeSuccess, VerifyOneTimeCodeError>
> => {
  const {
    mutateAsync,
    isPending: isSubmitting,
    isError,
  } = useMutation({
    mutationFn: async (request: VerifyOneTimeCodeRequest) => {
      let success: VerifyOneTimeCodeSuccess | undefined;
      let verifyOtpError: VerifyOneTimeCodeError | undefined;

      const expectedCodes = [400019, 401007] as const;
      await verifyOneTimeCode(request, { dontLogErrorsForErrorCode: expectedCodes })
        .then((res) => {
          success = res.payload;
        })
        .catch((error) => {
          const errorCode = error.errorStack?.status.messages[0]
            .code as (typeof expectedCodes)[number];
          switch (errorCode) {
            case 400019:
              verifyOtpError = 'EXPIRED_USER_TOKEN';
              break;
            case 401007:
              verifyOtpError = 'INVALID_OTP_CODE';
              break;
            default:
              throw error;
          }
          success = undefined;
        });

      const response: ResponseObject<VerifyOneTimeCodeSuccess, VerifyOneTimeCodeError> = {
        success,
        error: verifyOtpError,
      };

      return response;
    },
  });

  return {
    verifyOneTimeCode: mutateAsync,
    isLoading: false,
    isError,
    isSubmitting,
  };
};

export const useCreatePassword = (): CreatePasswordResponse<
  CreatePasswordRequest,
  ResponseObject<CreatePasswordSuccess, CreatePasswordError>
> => {
  const {
    mutateAsync,
    isPending: isSubmitting,
    isError,
  } = useMutation({
    mutationFn: async (request: CreatePasswordRequest) => {
      let success: CreatePasswordSuccess | undefined;
      let createPasswordError: CreatePasswordError | undefined;

      const expectedCodes = [400019] as const;
      await createPassword(request, { dontLogErrorsForErrorCode: expectedCodes })
        .then((res) => {
          success = res.payload;
        })
        .catch((error) => {
          const errorCode = error.errorStack?.status.messages[0]
            .code as (typeof expectedCodes)[number];
          switch (errorCode) {
            case 400019:
              createPasswordError = 'EXPIRED_USER_TOKEN';
              break;
            default:
              throw error;
          }
          success = undefined;
        });

      const response: ResponseObject<CreatePasswordSuccess, CreatePasswordError> = {
        success,
        error: createPasswordError,
      };

      return response;
    },
  });

  return {
    createPassword: mutateAsync,
    isLoading: false,
    isError,
    isSubmitting,
  };
};

export const useForgotPassword = (): ForgotPasswordResponse<
  ForgotPasswordRequest,
  ResponseObject<ForgotPasswordSuccess, ForgotPasswordError>
> => {
  const {
    mutateAsync,
    isPending: isSubmitting,
    isError,
  } = useMutation({
    mutationFn: async (request: ForgotPasswordRequest) => {
      let success: ForgotPasswordSuccess | undefined;
      let forgotPasswordError: ForgotPasswordError | undefined;

      const expectedCodes = [400019] as const;
      await forgotPassword(request, { dontLogErrorsForErrorCode: expectedCodes })
        .then((res) => {
          success = res.payload;
        })
        .catch((error) => {
          const errorCode = error.errorStack?.status.messages[0]
            .code as (typeof expectedCodes)[number];
          switch (errorCode) {
            case 400019:
              forgotPasswordError = 'EXPIRED_USER_TOKEN';
              break;
            default:
              throw error;
          }
          success = undefined;
        });

      const response: ResponseObject<ForgotPasswordSuccess, ForgotPasswordError> = {
        success,
        error: forgotPasswordError,
      };

      return response;
    },
  });

  return {
    forgotPassword: mutateAsync,
    isLoading: false,
    isError,
    isSubmitting,
  };
};

export const useChangePassword = (): ChangePasswordResponse<
  ChangePasswordRequest,
  ResponseObject<ServicingResponse<ChangePasswordSuccess>, ChangePasswordError>
> => {
  const {
    mutateAsync,
    isPending: isSubmitting,
    isError,
  } = useMutation({
    mutationFn: async (request: ChangePasswordRequest) => {
      let changePasswordError: ChangePasswordError | undefined;
      let success: ServicingResponse<ChangePasswordSuccess> | undefined;

      const expectedCodes = [400026] as const;
      await changePassword(request, { dontLogErrorsForErrorCode: expectedCodes })
        .then((res) => (success = res))
        .catch((error: ServicingRequestError) => {
          const errorCode = error.errorStack?.status.messages[0]
            .code as (typeof expectedCodes)[number];
          if (errorCode === 400026) {
            changePasswordError = 'Invalid Credentials';
          } else changePasswordError = 'unknown';
          success = undefined;
        });

      const response: ResponseObject<
        ServicingResponse<ChangePasswordSuccess>,
        ChangePasswordError
      > = {
        success,
        error: changePasswordError,
      };

      return response;
    },
  });

  return {
    changePassword: mutateAsync,
    isLoading: false,
    isError,
    isSubmitting,
  };
};

export const useLogin = (): LoginResponse<
  LoginUserRequest,
  ResponseObject<LoginSuccess, LoginError>
> => {
  const {
    mutateAsync,
    isPending: isSubmitting,
    isError,
  } = useMutation({
    mutationFn: async ({ email, password }: LoginUserRequest) => {
      let loginError: LoginError | undefined;
      let success: LoginSuccess | undefined;
      await userAuth
        .login(email, password)
        .then(() => {
          success = { userId: email };
          localStorage.setItem(localStorageKeys.accountExistKey, true);
        })
        .catch((error) => {
          if (error instanceof AuthError) {
            const isInvalidCredentials = error.status === 401001 || error.status === 401008;
            const unauthorized = isInvalidCredentials || error.status === 401011;
            datadogLog({
              logType: unauthorized ? 'info' : 'error',
              message: unauthorized
                ? isInvalidCredentials
                  ? `Invalid Login Attempt - ${error?.message}`
                  : `Locked account - ${error?.message}`
                : `Authenticate Error - ${error?.message}`,
              context: {
                logOrigin: 'libs/features/servicing/shared/state/src/users/state.ts',
                functionOrigin: 'useLogin',
                responseStatus: error.status,
                errorMessage: error.message,
              },
              error,
            });
            if (unauthorized) {
              if (error.status === 401011) loginError = 'locked_account';
              else if (isInvalidCredentials) loginError = 'invalid_credentials';

              if (loginError) return;
              throw error;
            }
          }

          if (error instanceof TypeError) {
            const message = `Exception calling Auth Api - code: [0] - reason - [Network or CORS error; ${JSON.stringify(
              error,
            )}]`;
            datadogLog({
              logType: 'error',
              message,
              context: {
                logOrigin: 'libs/features/servicing/shared/state/src/users/state.ts',
                functionOrigin: 'useLogin',
                responseStatus: 'failed',
                responseReason: 'Network or CORS error',
              },
              error,
            });
          }

          throw error;
        });

      const response: ResponseObject<LoginSuccess, LoginError> = {
        success,
        error: loginError,
      };

      return response;
    },
  });

  return {
    login: mutateAsync,
    isLoading: false,
    isError,
    isSubmitting,
  };
};

export const useChangeEmail = (): ChangeEmailResponse<
  ChangeEmailRequest,
  ResponseObject<ServicingResponse<ChangeEmailSuccess>, ChangeEmailError>
> => {
  const {
    mutateAsync,
    isPending: isSubmitting,
    isError,
  } = useMutation({
    mutationFn: async (request: ChangeEmailRequest) => {
      if (!request.userId) {
        throw new Error('userId is undefined, cannot make a request without userId');
      }
      let successResponse: ServicingResponse<ChangeEmailSuccess> | undefined;
      let updateEmailError: ChangeEmailError | undefined;

      const expectedStatuses = [400] as const;
      await changeEmail(request, { dontLogErrorsForStatus: expectedStatuses })
        .then((res) => (successResponse = res))
        .catch((error: ServicingRequestError) => {
          const errorMessageCode = error.errorStack?.status.messages?.[0]?.code as ErrorCode;
          if (errorMessageCode === 400023) updateEmailError = 'Invalid Request';
          else {
            updateEmailError = 'unknown';
          }
          successResponse = undefined;
        });

      const response: ResponseObject<ServicingResponse<ChangeEmailSuccess>, ChangeEmailError> = {
        success: successResponse,
        error: updateEmailError,
      };

      return response;
    },
  });

  return {
    changeEmail: mutateAsync,
    isLoading: false,
    isError,
    isSubmitting,
  };
};
