import { StrictMode } from 'react';

import { CssBaseline } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { ReactQueryProvider } from '@ecp/features/servicing/shared/state';
import { theme, ThemeProvider } from '@ecp/themes/base';

import { App } from './app/App';
import { initializeGlobal } from './initialize';

// @ts-ignore need to remove path for partner overrides
import('./styles');

initializeGlobal().then(() => {
  const container = document.getElementById('root');
  const root = createRoot(container as HTMLElement);

  root.render(
    <StrictMode>
      <ThemeProvider theme={theme}>
        <ReactQueryProvider>
          <CssBaseline />
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ReactQueryProvider>
      </ThemeProvider>
    </StrictMode>,
  );
});
