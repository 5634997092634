import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CaMileageReview' })((theme) => ({
  root: {},
  closeButton: {
    ...theme.typography.body3Bold,
    [theme.breakpoints.up('md')]: {
      maxWidth: 'fit-content',
    },
  },
  actionIcon: {
    height: 64,
    width: 64,
  },
  successIconWrapper: {
    textAlign: 'center',
  },
  cancelButton: { alignSelf: 'self-start' },
  submitButton: theme.typography.body3Bold,
}));
