import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'DeleteAdditionalInterest' })((theme) => ({
  root: {},
  cancelButton: {
    [theme.breakpoints.up('md')]: {
      width: 'fit-content',
    },
  },
  circle: {
    borderRadius: '50%',
    backgroundColor: `${theme.palette.grey[700]}3F`,
    height: 42,
    width: 42,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    height: 30,
    width: 30,
  },
  iconWrapper: {
    alignSelf: 'center',
  },
}));
