import { useCallback, useEffect, useMemo, useState } from 'react';

import { CircularProgress, Stack } from '@mui/material';
import type { FieldValues } from 'react-hook-form';

import { formatDate } from '@ecp/utils/date';
import { useField, useFormContext } from '@ecp/utils/form';

import type { SelectProps } from '@ecp/components';
import { Button, PhoneLink, Select } from '@ecp/components';
import { MODAL_STEP } from '@ecp/features/servicing/shared/routing';
import type { UseModalPathReturn } from '@ecp/features/servicing/shared/routing';
import { usePaymentOptions, usePolicy, useSharedState } from '@ecp/features/servicing/shared/state';
import type {
  AutopayInformation,
  MakePaymentInformation,
} from '@ecp/features/servicing/shared/types';
import type { MakePaymentFormInputs } from '@ecp/features/servicing/shared/util';
import type { Option } from '@ecp/types';

import { ServicingModal } from '../ServicingModal';
import { useStyles } from './AccountSelect.styles';

export interface AccountSelectProps extends UseModalPathReturn {
  customerServicePhone: string;
  buttons?: boolean;
  showAddNewCC?: boolean;
}

export const AccountSelect: React.FC<AccountSelectProps> = (props) => {
  const {
    buttons = false,
    currentFlow,
    customerServicePhone,
    goTo,
    policyNumber,
    showAddNewCC = true,
    sharedStateKey,
  } = props;
  const { classes } = useStyles();
  const [showModal, setShowModal] = useState(false);
  const { control, setValue, clearErrors, trigger } = useFormContext<MakePaymentFormInputs>();

  const { isLoading: isPolicyLoading, policyData: policyBeingPayed } = usePolicy(policyNumber);

  const { isLoading: isPaymentOptionsLoading, paymentOptions } = usePaymentOptions({
    billingAccountNumber: policyBeingPayed?.policy.billingAccountNumber,
    policySource: policyBeingPayed?.policy.sourceSystemName,
  });
  const addPaymentLinks = useMemo(
    () => [
      {
        label: <div className={classes.link}>Add new bank account</div>,
        value: 'eft',
      },
      ...(showAddNewCC
        ? [
            {
              label: <div className={classes.link}>Add new debit/credit card</div>,
              value: 'cc',
            },
          ]
        : []),
    ],
    [classes.link, showAddNewCC],
  );

  const isPaymentFlow = currentFlow === 'pay';
  const [sharedState, setSharedState] = useSharedState<MakePaymentInformation | AutopayInformation>(
    sharedStateKey,
  );

  const accountTypeField = useField<FieldValues, string>({
    name: 'paymentAccount',
    control,
  });

  const isLoading = isPolicyLoading || isPaymentOptionsLoading;

  const selectOptions: Option[] = useMemo(
    () => [
      ...(paymentOptions || []).map((paymentOption) => {
        let result!: Option;
        if (paymentOption.type === 'CC')
          result = {
            label: (
              <>
                {`${paymentOption.cardType} (...${paymentOption.ccToken} Exp. ${formatDate(
                  paymentOption.ccExpirationDate,
                  'MM/YY',
                )})`}
              </>
            ),
            value: paymentOption.ccprimekey,
          };
        else if (paymentOption.type === 'EFT')
          result = {
            label: <>Bank account (...{paymentOption.accountNumber})</>,
            value: paymentOption.efmprimekey,
          };

        return result;
      }),
      ...addPaymentLinks,
    ],
    [paymentOptions, addPaymentLinks],
  );

  const setPaymentAccountValue = useCallback(
    (value?: string): void => {
      clearErrors('paymentAccount');
      setValue('paymentAccount', value);
      const ccPaymentOption = paymentOptions?.find(
        (option) => option.type === 'CC' && option.ccprimekey === value,
      );
      const expDate =
        ccPaymentOption?.type === 'CC' ? ccPaymentOption?.ccExpirationDate : undefined;
      setValue('ccExpirationDate', expDate);
    },
    [paymentOptions, clearErrors, setValue],
  );

  useEffect(() => {
    if (paymentOptions?.length) {
      const sharedStateSelectedPaymentMethod = sharedState?.paymentAccountMethod;
      const sharedStateSelectedValue =
        sharedStateSelectedPaymentMethod?.type === 'CC'
          ? sharedStateSelectedPaymentMethod.ccprimekey
          : sharedStateSelectedPaymentMethod?.efmprimekey;

      let value: string | undefined =
        sharedStateSelectedValue ?? (sharedState?.isPaymentAdded ? '' : selectOptions[0]?.value);

      if (currentFlow === 'autopaymanage') {
        const enrolledPaymentOption = paymentOptions.find(
          (paymentOption) => paymentOption.autopayEnrolled,
        );
        // set the default option of the account select to be the enrolled payment option
        const enrolledPaymentOptionKey =
          enrolledPaymentOption?.type === 'CC'
            ? enrolledPaymentOption.ccprimekey
            : enrolledPaymentOption?.efmprimekey;
        value = sharedStateSelectedValue ?? enrolledPaymentOptionKey;
      }
      setPaymentAccountValue(value);
      // To trigger validation on page load, if CC is expired will show error message
      trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentOptions, addPaymentLinks, setValue]);

  const handleOnChange = useCallback<NonNullable<SelectProps['actionOnChange']>>(
    (value) => {
      if (value === 'cc' && paymentOptions) {
        paymentOptions.length > 3 ? setShowModal(true) : goTo(MODAL_STEP.ADD_CC_ACCOUNT);
      } else if (value === 'eft' && paymentOptions) {
        paymentOptions.length > 3 ? setShowModal(true) : goTo(MODAL_STEP.ADD_EFT_ACCOUNT);
      } else {
        setPaymentAccountValue(value);
        setValue('enrollAutopay', '');
        if (isPaymentFlow)
          setSharedState({
            ...(sharedState as MakePaymentInformation),
            isPaymentAdded: false,
            enrollAutopay: false,
          });
        else
          setSharedState({
            ...(sharedState as AutopayInformation),
            isPaymentAdded: false,
            autopayEnrolled: 'false',
          });
      }
    },
    [
      paymentOptions,
      sharedState,
      setSharedState,
      setValue,
      goTo,
      setShowModal,
      setPaymentAccountValue,
      isPaymentFlow,
    ],
  );

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const handleRenderValue = useCallback(
    () => <span className={classes.placeholder}>Choose payment</span>,
    [classes.placeholder],
  );

  const handleAddCcClick = useCallback(() => goTo(MODAL_STEP.ADD_CC_ACCOUNT), [goTo]);
  const handleAddBankAcctClick = useCallback(() => goTo(MODAL_STEP.ADD_EFT_ACCOUNT), [goTo]);

  if (isLoading) return <CircularProgress aria-label='Loading...' />;

  const modalBody = (
    <Stack spacing={2}>
      <p>The limit of payment methods this policy can have has been reached.</p>
      <p>
        For help adding or removing, please call us:{' '}
        <PhoneLink withUnderlinedLinkStyle number={customerServicePhone} />.
      </p>
    </Stack>
  );

  const addAPaymentMethod = (
    <Stack spacing={2}>
      <span className={classes.addMethodHeader}>Payment method</span>
      {showAddNewCC && (
        <Button
          className={classes.addMethodBtn}
          variant='outlinePrimary'
          onClick={handleAddCcClick}
        >
          Add new credit/debt card
        </Button>
      )}
      <Button
        className={classes.addMethodBtn}
        variant='outlinePrimary'
        onClick={handleAddBankAcctClick}
      >
        Add new bank account
      </Button>
    </Stack>
  );

  return (
    <div className={classes.root}>
      {buttons && paymentOptions?.length === 0 ? (
        addAPaymentMethod
      ) : (
        <>
          <Select
            {...accountTypeField}
            id={accountTypeField.name}
            label='Payment method'
            disabled={!selectOptions?.length}
            options={selectOptions}
            variant='outlined'
            actionOnChange={handleOnChange}
            renderValue={
              !accountTypeField?.value || sharedState?.isPaymentAdded
                ? handleRenderValue
                : undefined
            }
            classes={{ option: classes.selectOption, root: classes.selectLabel }}
            trackingName='payment_type_tab'
          />
          <ServicingModal
            buttonText='CLOSE'
            onButtonClick={handleCloseModal}
            open={showModal}
            onClose={handleCloseModal}
            body={modalBody}
            title='Limit reached'
          />
        </>
      )}
    </div>
  );
};
