const letterACharCode = 'a'.charCodeAt(0);
const letterPCharCode = 'p'.charCodeAt(0);
const letterTCharCode = 't'.charCodeAt(0);
const letterWCharCode = 'w'.charCodeAt(0);
const getNumberFromLetterInPhoneNumber = (letter: string): string => {
  const letterCharCode = letter.charCodeAt(0);
  // numbers
  if (letterCharCode < letterACharCode) return letter;
  // w-z
  if (letterCharCode >= letterWCharCode) return '9';
  // t-v
  if (letterCharCode >= letterTCharCode) return '8';
  // p-s
  if (letterCharCode >= letterPCharCode) return '7';
  // a-o

  return String(Math.floor((letterCharCode - letterACharCode) / 3) + 2);
};

/**
 * @deprecated Ask OSI to remove this and replace letters in their number with numbers
 */
export const convertPhoneCharsToNumbers = (phoneNumber: string): string => {
  const lowerCasedPhoneNumber = phoneNumber.toLowerCase();

  if (lowerCasedPhoneNumber.includes('choicemspn')) return phoneNumber;

  return lowerCasedPhoneNumber.split('').map(getNumberFromLetterInPhoneNumber).join('');
};
