import { flagValues } from '@ecp/utils/flags';

import type { UiPolicy } from '@ecp/features/servicing/shared/types';

import { excludedPreferenceStates } from '../metadata';
import { getPolicyAddress } from './getPolicyAddress';
import { getProductLineFromPolicyResponse } from './getProductLineFromPolicyResponse';
import { isLegacyPolicy } from './isLegacyPolicy';

export const isPolicyAllowedToSetPaperless = (
  userPolicy: UiPolicy | undefined,
): { policyAllowedToSetPaperless: boolean; policyAllowedToUnsetPaperless: boolean } => {
  // Determine if is legacy policy.
  const productLine = getProductLineFromPolicyResponse(userPolicy);
  const policySource = userPolicy?.policy?.sourceSystemName;
  const isClassic = isLegacyPolicy(productLine, policySource);
  const insuredAddress = getPolicyAddress(userPolicy);

  const isActiveAndFeatureEnabled =
    !userPolicy?.isInactive &&
    flagValues.PAPERLESS &&
    (isClassic ? flagValues.PAPERLESS_CLASSIC : true);

  // Legacy policies can view paperless if the legacy paperless feature is enabled.
  const policyAllowedToSetPaperless =
    !excludedPreferenceStates.includes(insuredAddress?.state) && isActiveAndFeatureEnabled;

  // Conditions to check if a policy can unenroll from paperless
  const policyAllowedToUnsetPaperless = isActiveAndFeatureEnabled;

  return { policyAllowedToSetPaperless, policyAllowedToUnsetPaperless };
};
