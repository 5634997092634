import { forwardRef } from 'react';

import { Stack } from '@mui/material';

import { HoursList, PhoneLink } from '@ecp/components';
import { getBrandDataWithFallback } from '@ecp/features/servicing/shared/util';
import { getChatEnabled } from '@ecp/features/shared/chat';

import { Card } from '../Card';
import { ChatHelp } from '../Chat';
import { useStyles } from './ContactUs.styles';
import { PolicyRelatedContacts } from './PolicyRelatedContacts';

interface Props {
  hidePolicyContacts?: boolean;
}
export const ContactUs = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { hidePolicyContacts } = props;
  const { classes } = useStyles();

  const brandData = getBrandDataWithFallback('AUTO');
  const salesOperationHours = brandData.salesOperationHours.join(', ');
  const servicingOperationHours = brandData.mainOperationHours.join(', ');

  const phoneOperationHoursSection =
    brandData.salesPhoneNum === brandData.mainCustServPhoneNum &&
    salesOperationHours === servicingOperationHours ? (
      <div className={classes.gridItem}>
        <Stack spacing={1}>
          <p className={classes.subTitle}>Sales & Service</p>
          <div>
            <PhoneLink number={brandData.mainCustServPhoneNum} withUnderlinedLinkStyle />
            <HoursList weeklyHours='' hourArrays={brandData.mainOperationHours} />
          </div>
        </Stack>
      </div>
    ) : (
      <>
        <div className={classes.gridItem}>
          <Stack spacing={1}>
            <p className={classes.subTitle}>Sales</p>
            <div>
              <PhoneLink number={brandData.salesPhoneNum} withUnderlinedLinkStyle />
              <HoursList weeklyHours='' hourArrays={brandData.salesOperationHours} />
            </div>
          </Stack>
        </div>
        <div className={classes.gridItem}>
          <Stack spacing={1}>
            <p className={classes.subTitle}>Service</p>
            <div>
              <PhoneLink number={brandData.mainCustServPhoneNum} withUnderlinedLinkStyle />
              <HoursList weeklyHours='' hourArrays={brandData.mainOperationHours} />
            </div>
          </Stack>
        </div>
      </>
    );

  const ChatComponent = getChatEnabled() ? ChatHelp : undefined;

  return (
    <div ref={ref}>
      <Card
        className={classes.root}
        body={
          <Stack spacing={3}>
            <Stack spacing={0.5}>
              <h2>Contact us</h2>
              <h3>We're here to help.</h3>
            </Stack>
            <div className={classes.gridSection}>
              {ChatComponent && (
                <div className={classes.gridItem}>
                  <Stack spacing={1}>
                    <p className={classes.subTitle}>Chat with us</p>
                    {/* Note: Auto and Home service chat hours are the same for Day 1 */}

                    <HoursList weeklyHours='' hourArrays={brandData.serviceChatHours} />
                    <div>
                      <ChatComponent
                        displayText='Chat Now'
                        trackingName='chat_now_button'
                        trackingLabel='chat'
                        variant='primary'
                      />
                    </div>
                  </Stack>
                </div>
              )}
              {phoneOperationHoursSection}
              <div className={classes.gridItem}>
                <Stack spacing={1}>
                  <p className={classes.subTitle}>General Claims</p>
                  <PhoneLink number={brandData.claimPhoneNum} withUnderlinedLinkStyle />
                  Claims agents available 24/7
                </Stack>
              </div>
              <div className={classes.gridItem}>
                <Stack spacing={1}>
                  <p className={classes.subTitle}>Email a document</p>
                  <div>
                    <a
                      className={classes.links}
                      href={`mailto:${brandData.inboundServiceEmail}`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {brandData.inboundServiceEmail}
                    </a>
                    <p>Be sure to include your policy number.</p>
                  </div>
                </Stack>
              </div>
              {!hidePolicyContacts && <PolicyRelatedContacts />}
            </div>
          </Stack>
        }
      />
    </div>
  );
});
