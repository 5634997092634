import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<void, 'medium' | 'small'>({ name: 'ShowMoreOrLess' })(
  (...[theme, , classes]) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    button: {
      margin: '10px auto auto',
      width: '100%',
    },
    card: {
      background: theme.palette.grey['50'],
      borderRadius: 8,
      border: `1px solid ${theme.palette.grey['600']}`,
      cursor: 'pointer',
      marginLeft: 0,
      position: 'relative',
      transition: '0.3s',
      '&:hover': {
        background: theme.palette.grey['200'],
      },
      [theme.breakpoints.down('md')]: {
        width: '100% !important',
        marginTop: 10,
      },
      [theme.breakpoints.up('md')]: {
        marginRight: 12,
      },
      [`&.${classes.medium}, &.${classes.small}`]: {
        [theme.breakpoints.only('lg')]: {
          width: '149px !important',
        },
        [theme.breakpoints.only('md')]: {
          width: '168px !important',
        },
      },
    },
    text: {
      ...theme.typography.h5,
      marginTop: 5,
      color: theme.palette.text.link,
      textAlign: 'center', // The text should be center aligned and not left aligned
      // search for more - theme.palette.app
    },
    medium: {
      width: 178,
      minHeight: 190,
    },
    small: {
      width: 140,
      minHeight: 190,
    },
    addButton: {
      width: 34,
      height: 34,
      ...theme.mixins.setColorSvg(theme.palette.text.link),
    },
  }),
);
