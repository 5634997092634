import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { userAuth } from '@ecp/utils/auth';

import { PAGE_PATH } from '@ecp/features/servicing/shared/routing';

export const RequiredAuth: React.FC = () => {
  const location = useLocation();

  if (!userAuth.isAuth) {
    return <Navigate to={PAGE_PATH.AUTH_INDEX} replace state={{ from: location }} />;
  }

  // re-evaluate "auto-logout when token expired" once we have refresh token capabilities
  return userAuth.isTokenExpired ? (
    <Navigate to={PAGE_PATH.LOGOUT} replace state={{ from: location }} />
  ) : (
    <Outlet />
  );
};
