import { useParams } from 'react-router-dom';

import { PrivacyPolicyConnect } from './connect';
import { PrivacyPolicyHomesite } from './homesite';

export const PrivacyPolicyPage: React.FC = () => {
  const { opco } = useParams();

  let privacyPolicy;
  switch (opco) {
    case 'homesite':
      privacyPolicy = <PrivacyPolicyHomesite />;
      break;
    case 'connect':
      privacyPolicy = <PrivacyPolicyConnect />;
      break;
    default:
      privacyPolicy = <p>Partner Specific Privacy Policy.</p>;
  }

  return privacyPolicy;
};
