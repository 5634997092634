import { subscribeToEvent } from './util';

/**
 * Fires the callback when the database is ready
 * @see https://developer.genesys.cloud/commdigital/digital/webmessaging/messengersdk/SDKCommandsEvents/databasePlugin#database-ready
 */
export const subscribeToDatabaseReadyEvent = (callback: () => Promise<void>): void => {
  subscribeToEvent('Database.ready', callback);
};

/**
 * Triggers the callback when the Messenger (chatbox) is opened.
 * Does no-op when chat is not enabled.
 * @see [Genesys Developer Documentation for Messenger Opened Event](https://developer.genesys.cloud/commdigital/digital/webmessaging/messengersdk/SDKCommandsEvents/messengerPlugin#messenger-opened)
 *
 * @param callback - The function to be executed when the Messenger is opened.
 */
export const subscribeToMessengerOpenedEvent = (callback: () => Promise<void>): void => {
  subscribeToEvent('Messenger.opened', callback);
};

/**
 * Executes the callback when the conversation is reset.
 * Does no-op when chat is not enabled.
 * @see [Genesys Developer Documentation for Conversation Reset Event](https://developer.genesys.cloud/commdigital/digital/webmessaging/messengersdk/SDKCommandsEvents/messagingServicePlugin#messagingservice-conversationreset)
 *
 * @param callback - The function to be called when the conversation is reset.
 */
export const subscribeToConversationResetEvent = (callback: () => Promise<void>): void => {
  subscribeToEvent('MessagingService.conversationReset', callback);
};

/**
 * Executes the callback when the conversation is started.
 * Does no-op when chat is not enabled.
 * @see [Genesys Developer Documentation for Conversations Started Event](https://developer.genesys.cloud/commdigital/digital/webmessaging/messengersdk/SDKCommandsEvents/conversationsPlugin#conversations-started)
 *
 * @param callback - The function to be called when the conversation is started.
 */
export const subscribeToConversationStartedEvent = (callback: () => Promise<void>): void => {
  subscribeToEvent('Conversations.started', callback);
};

/**
 * Executes the callback when the conversation is cleared.
 * Does no-op when chat is not enabled.
 * @see [Genesys Developer Documentation for Conversation Cleared Event](https://developer.genesys.cloud/commdigital/digital/webmessaging/messengersdk/SDKCommandsEvents/messagingServicePlugin#messagingservice-conversationcleared)
 *
 * @param callback - The function to be called when the conversation is cleared.
 */
export const subscribeToConversationClearedEvent = (callback: () => Promise<void>): void => {
  subscribeToEvent('MessagingService.conversationCleared', callback);
};
