import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'Breadcrumbs' })((theme) => ({
  root: {},
  select: {
    minWidth: 100,
  },
  link: {
    [theme.breakpoints.down('md')]: { display: 'none' },
  },
  title: {
    [theme.breakpoints.down('md')]: { display: 'none' },
  },
  separator: {
    [theme.breakpoints.down('md')]: { display: 'none' },
  },
  dropdownSeparator: { display: 'inherit' },
  previousPageLink: { display: 'inherit' },
  icon: {
    maxHeight: 16,
    verticalAlign: 'text-bottom',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));
