import { useCallback } from 'react';

import type { QueryFilters } from '@tanstack/react-query';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { env } from '@ecp/env';
import type { Address, UiPolicy, User } from '@ecp/features/servicing/shared/types';

import { QUERY_KEYS } from '../constants';
import { useUserPolicies } from '../policy';
import {
  getAddressByRefId,
  getDwellingAddress,
  getPersonByRefId,
  getProductLineFromPolicyResponse,
} from '../util';
import { getClaimsContext } from './api';
import type {
  ClaimsAddress,
  ClaimsCallingContext,
  ClaimsCallingSubContext,
  ClaimsContext,
  ClaimsPerson,
  ClaimsReturn,
  CustomerInfo,
  Policy,
  Risk,
} from './types';

interface ClaimsContextReturn {
  isLoading: boolean;
  isError: boolean;
  claimsContext?: ClaimsContext;
}

const getAddress = (policyAddress: Address): ClaimsAddress => {
  // ideally the policy address would align with claims address, but they don't at this point...manually map
  return {
    addressLine1: policyAddress.line1 ?? '',
    city: policyAddress.city ?? '',
    state: policyAddress.state ?? '',
    zipCode5: policyAddress.zip ?? '',
  };
};

export const getPersons = (policyData: UiPolicy): ClaimsPerson[] => {
  const allPersons = [];
  const pni = getPersonByRefId(policyData, policyData.policy.primaryInsuredRefId);
  if (!pni) {
    throw new Error('Cannot create claims context without PNI');
  }
  const pniPerson: ClaimsPerson = {
    firstName: pni.firstName,
    lastName: pni.lastName,
    roleType: 'PrimaryNamedInsured',
  };
  allPersons.push(pniPerson);

  const sni = getPersonByRefId(policyData, policyData.policy.secondaryInsuredRefId);
  if (sni) {
    const sniPerson: ClaimsPerson = {
      firstName: sni.firstName,
      lastName: sni.lastName,
      roleType: 'SecondaryNamedInsured',
    };
    allPersons.push(sniPerson);
  }

  policyData.drivers?.forEach((driver) => {
    const person = getPersonByRefId(policyData, driver.personRefId);
    if (person) {
      const driverPerson: ClaimsPerson = {
        firstName: person.firstName,
        lastName: person.lastName,
        roleType: driver.driverStatus === 'INCLUDED' ? 'Driver' : 'ExcludedDriver',
      };
      allPersons.push(driverPerson);
    } else {
      console.error(`unknown person ref for driver - driver`);
    }
  });

  return allPersons;
};

const getRisks = (policyData: UiPolicy): Risk[] => {
  const productLine = getProductLineFromPolicyResponse(policyData);
  if (productLine === 'AUTO' && policyData.vehicles) {
    return policyData.vehicles.map((vehicle) => {
      return {
        addresses: [
          getAddress(getAddressByRefId(policyData, vehicle.garagingAddressRefId) as Address),
        ],
        vin: vehicle.vin,
        year: vehicle.year,
        make: vehicle.make,
        model: vehicle.model,
        vehicleTypeName: 'Truck', // TODO : update this when it is coming back
      };
    });
  } else if (productLine === 'HOME' && policyData.policy) {
    return [
      {
        addresses: [getAddress(getDwellingAddress(policyData) as Address)],
      },
    ];
  }

  return [];
};

const defaultPhone = '999-999-9999';
export const getCustomerInfo = (user: User): CustomerInfo => {
  const customerInfo: CustomerInfo = {
    firstName: user.firstName,
    lastName: user.lastName,
    emailAddress: user.email,
    phoneNumber: defaultPhone,
    roleType: env.static.isAgent ? 'agent' : 'customer',
  };

  return customerInfo;
};

export const getPolicies = (policies: UiPolicy[]): Policy[] =>
  policies
    .map((policyData) => {
      return {
        policyNumber: policyData.policy.policyNumber,
        persons: getPersons(policyData),
        risks: getRisks(policyData),
      };
    })
    .filter((policy) => !!policy.risks.length);

interface ClaimsContextOptions {
  context: ClaimsCallingContext;
  subContext: ClaimsCallingSubContext;
  claimNumber: string | undefined;
}

const useClaimsContext = (options: ClaimsContextOptions): ClaimsContextReturn => {
  const { context, subContext, claimNumber } = options;
  const { policies, user, isError, isLoading } = useUserPolicies({ throwOnPolicyError: false });

  if (!isLoading && isError && !policies[0]) {
    throw new Error('Cannot load claims iframe without any policies');
  }

  let claimsContext: ClaimsContext | undefined;
  if (!isLoading && policies[0] && user) {
    claimsContext = {
      callingContext: context,
      callingSubContext: subContext,
      fullClaimNumber: claimNumber,
      customerInfo: getCustomerInfo(user),
      policies: getPolicies(policies),
    };
  }

  return {
    claimsContext,
    isError,
    isLoading,
  };
};

export const useClaimsIframeUrl = (options: ClaimsContextOptions): ClaimsReturn => {
  const { claimsContext } = useClaimsContext(options);
  const { data, isLoading, isError } = useQuery({
    queryKey: [QUERY_KEYS.CLAIMS, options.context, options.subContext],
    queryFn: async () => {
      const { payload } = await getClaimsContext(claimsContext as ClaimsContext);

      return payload.url;
    },
    // iframe url expires after used, so we can never reuse
    gcTime: 0,
    enabled: !!claimsContext,
  });

  return {
    url: data,
    isLoading,
    isError,
  };
};

const predicate: QueryFilters['predicate'] = (query) => {
  return query.queryKey.some((key) => String(key).toLowerCase().startsWith('claims'));
};

export const useResetClaimsContext = (): { reset: () => Promise<void> } => {
  const queryClient = useQueryClient();
  const reset = useCallback(() => {
    const handleReset = async (): Promise<void> => {
      await queryClient.cancelQueries({ predicate });

      queryClient.removeQueries({ predicate });
    };

    return handleReset();
  }, [queryClient]);

  return {
    reset,
  };
};
