import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<void>({
  name: 'Table',
})((...[theme]) => ({
  root: {},

  tableContainer: {
    ...theme.mixins.tableContainer,
    marginTop: 20,
    borderCollapse: 'inherit',
    borderSpacing: 0,
  },
  tableRootContainer: {
    overflowX: 'auto',
  },
  firstRow: theme.mixins.tableFirstRow,
  subheader: theme.mixins.tableSubHeader,
  bold: {},
  link: {},
  tableBody: {
    ...theme.mixins.simpleTableBody,
    '& tr:not(:last-child) td': {
      borderBottom: `1px solid ${theme.palette.other.border}`,
      borderRadius: 0,
    },
    'tr:last-child td:first-child': {
      borderRadius: '0px 0px 0px 8px',
    },
    'tr td:not(:first-child)': {
      borderLeft: `1px solid ${theme.palette.other.border}`,
      borderRadius: 0,
    },
    'tr:last-child td:last-child': { borderBottomRightRadius: 8 },
  },
  tableText: {
    ...theme.typography.body1,
    maxWidth: 1000,
    marginTop: 20,
  },
  complexTable: theme.mixins.complexTable,
}));
