import { useCallback, useState } from 'react';

import { Stack } from '@mui/material';

import { Form, useForm } from '@ecp/utils/form';

import { Alert } from '@ecp/components';
import type { UseModalPathReturn as AddEftPaymentMethodProps } from '@ecp/features/servicing/shared/routing';
import {
  useCreatePaymentMethod,
  usePaymentOptions,
  usePolicy,
  useSharedState,
  useUser,
} from '@ecp/features/servicing/shared/state';
import type { BasePaymentInfo } from '@ecp/features/servicing/shared/types';
import { getBrandData } from '@ecp/features/servicing/shared/util';

import { LoadingOverlay } from '../LoadingOverlay';
import { PaymentsFormFooter } from '../PaymentsFormFooter';
import { AccountPaymentMethodFields } from './AccountPaymentMethodFields';
import { addAccountPaymentMethodSchema } from './AddEftPaymentMethod.schema';
import { useStyles } from './AddEftPaymentMethod.styles';

export const AddEftPaymentMethod: React.FC<AddEftPaymentMethodProps> = (props) => {
  const { goForward, sharedStateKey, policyNumber } = props;
  const [isErrorAlert, setIsErrorAlert] = useState(false);
  const { classes } = useStyles();
  const { policyData: policyBeingPayed, isLoading: isPolicyLoading } = usePolicy(policyNumber);
  const {
    paymentOptions,
    clearCache: clearPaymentOptionsCache,
    isLoading: isPaymentOptionsLoading,
  } = usePaymentOptions({
    billingAccountNumber: policyBeingPayed?.policy.billingAccountNumber,
    policySource: policyBeingPayed?.policy.sourceSystemName,
  });

  const [sharedState, setSharedState] = useSharedState<BasePaymentInfo>(sharedStateKey);

  const formContext = useForm({
    validations: addAccountPaymentMethodSchema,
  });
  const { user, isLoading: isUserLoading } = useUser();
  const { handleSubmit } = formContext;

  const { createPaymentMethod } = useCreatePaymentMethod();

  const onSubmit = useCallback(() => {
    handleSubmit(async (data) => {
      const email = user?.email;
      if (policyNumber) {
        if (email && policyNumber && policyBeingPayed?.policy.sourceSystemName && paymentOptions) {
          const { error, success } = await createPaymentMethod({
            paymentType: 'Bank account',
            email,
            policyNumber,
            policySource: policyBeingPayed?.policy.sourceSystemName,
            paymentOptions,
            ...data,
          });
          if (error) {
            setIsErrorAlert(true);
          } else if (success) {
            clearPaymentOptionsCache?.();
            goForward();
            setSharedState({ ...(sharedState as BasePaymentInfo), isPaymentAdded: true });
          }
        }
      }
    })();
  }, [
    setIsErrorAlert,
    goForward,
    handleSubmit,
    createPaymentMethod,
    clearPaymentOptionsCache,
    setSharedState,
    sharedState,
    paymentOptions,
    user?.email,
    policyNumber,
    policyBeingPayed?.policy.sourceSystemName,
  ]);
  const handleErrorAlertClose = useCallback(() => {
    setIsErrorAlert(false);
  }, []);
  const brandData = getBrandData(policyBeingPayed);

  const isLoading = isUserLoading || isPolicyLoading || isPaymentOptionsLoading;
  if (isLoading) return <LoadingOverlay />;

  return (
    <div className={classes.root}>
      <Form onSubmit={onSubmit} formProviderProps={formContext} name='addPaymentMethodModal'>
        {isErrorAlert && (
          <Alert withIcon type='error' withAction onClose={handleErrorAlertClose}>
            <>
              <b>We were unable to set up your payment method.</b>
              <p>Please try entering the payment information again or contact us for assistance.</p>
              <b>{brandData.mainCustServPhoneNum}</b>
              {brandData.mainOperationHours.map((hours) => (
                <p key={hours}>{hours}</p>
              ))}
            </>
          </Alert>
        )}
        <Stack spacing={3}>
          <AccountPaymentMethodFields />
          <PaymentsFormFooter
            buttonText='ADD BANK ACCOUNT'
            trackingName='add_banking_account_button'
            trackingLabel='pay_continue'
          />
        </Stack>
      </Form>
    </div>
  );
};
