import * as interactionId from '@ecp/utils/analytics/interaction-id';
import { uuid } from '@ecp/utils/common';
import * as flags from '@ecp/utils/flags';

import { env } from '@ecp/env';

import { trackDalAnalytics } from './dalApi';
import type { TrackingDimensionsNames, TrackingDimensionsObject } from './dimensions';
import { TrackingDimensions } from './dimensions';
import type { DalEventCategory, EventCategory, TrackingRequest } from './types';

export const GLOBAL_DAL_DIMENSIONS: TrackingDimensionsObject = {};

export const setDalDimension = (dimension: TrackingDimensionsNames, value: unknown): void => {
  GLOBAL_DAL_DIMENSIONS[dimension] = String(value ?? '');
};

// resolve differences in category names between GA and DAL requirements
const eventCategoryMapping: Record<EventCategory, DalEventCategory> = {
  Click: 'click',
  Hover: 'hover',
  'Began Question': 'Began Question',
  'Completed Question': 'Completed Question',
  Default: 'Default',
  'Entry Start': 'Entry Start',
  'Entry Complete': 'Entry Complete',
  Error: 'Error',
  page_render: 'page_render',
  PageView: 'PageView',
  Render: 'Render',
};
export const trackDal = ({
  category: eventCategory,
  action: eventName,
  label: eventLabel = '',
  dimensions,
  objectType,
  eventDetails = {},
  traceId: traceIdProp,
}: TrackingRequest): void => {
  const combinedDimensions = { ...GLOBAL_DAL_DIMENSIONS, ...dimensions };
  const traceId = traceIdProp ?? uuid();
  trackDalAnalytics(
    {
      customerIdentificationNumber: combinedDimensions.customer_id ?? '',
      partnerId: env.static.partnerId,
      policyNumber: combinedDimensions.active_policy_number ?? '',
      payload: {
        metadata: {
          app: {
            applicationId:
              combinedDimensions.application_id ?? env.static.applicationName ?? '--unset--',
            runtimeEnv: env.runtimeEnv,
            partnerId: env.static.partnerId,
            experienceId: combinedDimensions.current_experience_id ?? env.static.expId,
          },
          user: {
            id: combinedDimensions.user_id ?? '',
            sessionId: interactionId.get(),
            type: env.static.isAgent ? 'agent' : 'user',
            userAgent: globalThis.navigator.userAgent,
            emailUUID: '',
            optimizely: {
              featureFlags: flags.flagValues,
              visitorId: flags.getOptimizelyUserId(),
            },
          },
          request: {
            ecid: combinedDimensions.customer_id ?? '',
            inquiryId: combinedDimensions.inquiry_id ?? '',
            traceId,
          },
        },
        event: {
          pageHashNav: globalThis.window.location.hash,
          pageQueryString: globalThis.window.location.search,
          pageUrl: globalThis.window.location.pathname,
          pageTitle: globalThis.window.document.title,
          eventName,
          eventCategory: eventCategoryMapping[eventCategory],
          eventLabel,
          eventContext: '',
          eventDetails,
          modalName: combinedDimensions.modal_name ?? '',
          objectType,
        },
      },
    },
    traceId,
  );
};

export const logDalPageView = (path: string, title?: string): void => {
  // TODO log Dal page view
};

let initialized = false;

export const initializeDalAnalytics = (
  applicationId?: string,
  runtimeEnv?: typeof env.runtimeEnv,
  expId?: string,
): void => {
  if (initialized) return;
  if (applicationId) {
    setDalDimension(TrackingDimensions.APPLICATION_ID, applicationId);
  }

  if (runtimeEnv && expId)
    setDalDimension(TrackingDimensions.ENV_AND_EXPERIENCE_ID, `${runtimeEnv}-${expId}`);
  if (expId) {
    setDalDimension(TrackingDimensions.EXPERIENCE_ID_INITIAL, expId);
    setDalDimension(TrackingDimensions.EXPERIENCE_ID_CURRENT, expId);
  }
  initialized = true;
};
