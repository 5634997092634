import { generatePath } from 'react-router-dom';

import { userAuth } from '@ecp/utils/auth';

import { PAGE_TEMPLATE } from './constants';

export const generateHelpPath = (pagePath: string, opco?: string): string => {
  const normalizedPath = userAuth.isAuth
    ? pagePath
    : pagePath.replace(`${PAGE_TEMPLATE.DASHBOARD}`, PAGE_TEMPLATE.GUEST);
  const path = generatePath(normalizedPath, opco ? { opco } : undefined);

  return path;
};
