import '@amfament/embedded-components/add-driver/add-driver';

import { flagValues } from '@ecp/utils/flags';

import { env } from '@ecp/env';
import { MODAL_FLOW, useModalPath } from '@ecp/features/servicing/shared/routing';
import { showAddDriver, useBillingAccount, usePolicy } from '@ecp/features/servicing/shared/state';

import { MfeModal } from '../MfeModal';
import { useStyles } from './AddDriverModal.styles';

const AddDriverModalContent: React.FC = () => {
  const { classes } = useStyles();
  const modalPath = useModalPath();

  return (
    <MfeModal
      title='Add a driver'
      element={
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <add-driver />
      }
      elementName='add-driver'
      useMock={env.mockapi?.addDriver}
      classes={classes}
      {...modalPath}
    />
  );
};

const AddDriverModalIfEligible: React.FC = () => {
  const { policyNumber } = useModalPath();
  const { billingAccount } = useBillingAccount({ policyNumber });
  const { policyData } = usePolicy(policyNumber);
  const { showAddDriverLink } = showAddDriver(billingAccount, policyData);

  return showAddDriverLink ? <AddDriverModalContent /> : null;
};

export const AddDriverModal: React.FC = () => {
  const modalPath = useModalPath();

  if (modalPath.currentFlow === MODAL_FLOW.ADD_DRIVER && flagValues.ADD_DRIVER)
    return <AddDriverModalIfEligible />;
  else return null;
};
