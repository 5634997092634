import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ManageAutopayCard' })((theme) => ({
  root: {
    maxWidth: 498,
  },
  icon: {
    ...theme.mixins.setColorSvg(theme.palette.grey[900]),
    width: 36,
    height: 'auto',
  },
}));
