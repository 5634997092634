import { useCallback, useEffect, useMemo } from 'react';

import { Stack } from '@mui/material';

import { Form, useForm } from '@ecp/utils/form';

import { Button } from '@ecp/components';
import { LoadingButton, LoadingOverlay } from '@ecp/features/servicing/shared/components';
import type { UseModalPathReturn } from '@ecp/features/servicing/shared/routing';
import type { BillingResponse, PolicyBillingDetails } from '@ecp/features/servicing/shared/state';
import {
  getPolicyDisplayType,
  getProductLineFromPolicyResponse,
  isLegacyPolicy,
  useBillingDetails,
  useIsAutopayEligibleForPaymentMethod,
  usePaymentOptions,
  usePolicy,
  useSharedState,
} from '@ecp/features/servicing/shared/state';
import type { AutopayInformation } from '@ecp/features/servicing/shared/types';
import { makePaymentSchema } from '@ecp/features/servicing/shared/util';
import { IconCardAutomaticPayments } from '@ecp/themes/base';

import { AutopaySetup } from '../AutopaySetup';
import { useStyles } from './AutopayEdit.styles';
import { AutopayEditEnrollPaymentDue } from './AutopayEditEnrollPaymentDue';
import { AutopayEditUnenroll } from './AutopayEditUnenroll';

export const AutopayEdit: React.FC<UseModalPathReturn> = (props) => {
  const { goBack, goForward, init, sharedStateKey, currentFlow, policyNumber } = props;
  const { classes } = useStyles();
  const [sharedState, setSharedState] = useSharedState<AutopayInformation>(sharedStateKey);

  const {
    isLoading: isPolicyLoading,
    policyData: policyResponse,
    isFetching: isFetchingPolicy,
  } = usePolicy(policyNumber);
  const policyBeingEnrolled = policyResponse?.policy;

  const billingAccountNumber = policyBeingEnrolled?.billingAccountNumber;

  const {
    accounts,
    isLoading: isBillingLoading,
    refetch: refetchBilling,
    isFetching: isFetchingBillingDetails,
  } = useBillingDetails({
    billingAccountIds: billingAccountNumber ? [billingAccountNumber] : undefined,
  });
  useEffect(() => {
    refetchBilling();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const accountBeingEnrolled: BillingResponse | undefined = accounts.find(
    (account) => account.billingAccountId === policyBeingEnrolled?.billingAccountNumber,
  );
  const policyBillingDetailsBeingEnrolled: PolicyBillingDetails = {
    policyInfo: policyResponse,
    billingInfo: accountBeingEnrolled,
  };

  const productLine = getProductLineFromPolicyResponse(policyResponse);
  const productTypeDisplay = getPolicyDisplayType(policyResponse);
  const policySource = policyBeingEnrolled?.sourceSystemName;
  const {
    paymentOptions,
    isLoading: isPaymentOptionsLoading,
    isFetching: isFetchingPaymentOptions,
  } = usePaymentOptions(
    {
      billingAccountNumber: policyBeingEnrolled?.billingAccountNumber,
      policySource: policyBeingEnrolled?.sourceSystemName,
    },
    isLegacyPolicy(productLine, policySource),
  );

  const hasNoPaymentMethods = paymentOptions?.length === 0;

  const formContext = useForm({
    validations: makePaymentSchema,
  });
  const selectedPaymentOption = paymentOptions?.find((option) => {
    const selectedValue = formContext.getValues('paymentAccount') || sharedState?.paymentMethodId;
    if (option.type === 'CC') return option.ccprimekey === selectedValue;
    else if (option.type === 'EFT') return option.efmprimekey === selectedValue;

    return false;
  });
  const {
    isEligibleBillPlan,
    isLoading: isLoadingAutopayEligible,
    isFetching: isFetchingAutopayEligible,
  } = useIsAutopayEligibleForPaymentMethod(
    policyNumber,
    selectedPaymentOption?.type || paymentOptions?.at(0)?.type,
  );

  const isLoading =
    isBillingLoading ||
    isFetchingAutopayEligible ||
    isFetchingBillingDetails ||
    isFetchingPaymentOptions ||
    isFetchingPolicy ||
    isPaymentOptionsLoading ||
    isPolicyLoading ||
    isLoadingAutopayEligible;

  const paymentAdded = sharedState?.isPaymentAdded;
  const showError = useMemo(() => {
    if (isFetchingAutopayEligible || currentFlow === 'autopayunenroll') return false;
    if (paymentAdded === undefined) return !isEligibleBillPlan;
    else return !paymentAdded && !isEligibleBillPlan;
  }, [currentFlow, paymentAdded, isEligibleBillPlan, isFetchingAutopayEligible]);

  const handleBackClick = useCallback(
    () =>
      currentFlow === 'autopayunenroll' && policyNumber
        ? init(policyNumber, 'autopaymanage', 'landing')
        : goBack(),
    [currentFlow, policyNumber, goBack, init],
  );
  const { handleSubmit } = formContext;
  const onSubmit = useCallback(() => {
    if (currentFlow === 'autopayunenroll') {
      goForward();
    } else if (paymentOptions) {
      handleSubmit(async () => {
        setSharedState({
          paymentAccountMethod: selectedPaymentOption ?? paymentOptions[0],
          paymentMethodId: formContext.getValues('paymentAccount') ?? '',
          isError: false,
          isPaymentAdded: false,
        });
        goForward();
      })();
    }
  }, [
    currentFlow,
    formContext,
    goForward,
    handleSubmit,
    paymentOptions,
    selectedPaymentOption,
    setSharedState,
  ]);
  if (isLoading) return <LoadingOverlay />;
  const isDuePayment =
    accountBeingEnrolled &&
    accountBeingEnrolled.minimumDue !== undefined &&
    accountBeingEnrolled.minimumDue > 0;
  const isEnrollFlow = currentFlow === 'autopayenroll';
  const isManageFlow = currentFlow === 'autopaymanage';
  const isUnenrollFlow = currentFlow === 'autopayunenroll';

  const modalTitle = (() => {
    if (isManageFlow)
      return `AutoPay details for your ${productTypeDisplay} policy ${policyNumber}`;
    else if (isUnenrollFlow) return 'Unenroll the following policy from AutoPay';
    else return `Enroll your ${productTypeDisplay} policy ${policyNumber} in AutoPay`;
  })();

  const getAutopayEditBody = (): React.ReactElement => {
    if (isUnenrollFlow) {
      return <AutopayEditUnenroll {...props} />;
    } else if (isEnrollFlow && isDuePayment) {
      // Enroll flow when there is a payment due
      return <AutopayEditEnrollPaymentDue {...props} />;
    } else {
      // Enroll or Manage flow
      return (
        <AutopaySetup
          showError={showError}
          key={policyBeingEnrolled?.policyNumber}
          policyBillingDetails={policyBillingDetailsBeingEnrolled}
          hasNoPaymentMethods={hasNoPaymentMethods}
          {...props}
        />
      );
    }
  };

  return (
    <Form
      id='enrollAutopayForm'
      formProviderProps={formContext}
      onSubmit={onSubmit}
      className={classes.root}
    >
      <Stack spacing={3}>
        <IconCardAutomaticPayments className={classes.icon} />
        <span className={classes.title}>{modalTitle}</span>
        {getAutopayEditBody()}
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={4}>
          {!(isEnrollFlow && isDuePayment) && (
            <LoadingButton
              type='submit'
              trackingName='review_and_enroll'
              trackingLabel='enroll_continue'
              className={classes.submitButtton}
              disabled={showError || hasNoPaymentMethods}
            >
              CONTINUE
            </LoadingButton>
          )}
          {(isManageFlow || isUnenrollFlow) && (
            <Button
              className={classes.backButton}
              variant='iconTextMedium'
              onClick={handleBackClick}
            >
              Back
            </Button>
          )}
        </Stack>
      </Stack>
    </Form>
  );
};
