import { apiRoot, buildRequestHeaders } from '../servicingApiConfig';
import type { ApiRequestOptions, ServicingResponse } from '../servicingRequest';
import { servicingRequest } from '../servicingRequest';
import type { DocumentData, Documents, GetDocumentRequest, GetDocumentsRequest } from './types';

export const getPolicyDocument = async (
  getDocumentRequest: GetDocumentRequest,
): Promise<ServicingResponse<DocumentData>> => {
  const { requestNumber, documentId } = getDocumentRequest;
  const headers = await buildRequestHeaders({ withUserToken: true });

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-policy/v1/policies/${requestNumber}/documents/${documentId}`,
    options: {
      headers: headers,
      method: 'GET',
    },
    params: {},
  });
};

export const getPolicyDocuments = async (
  getDocumentsRequest: GetDocumentsRequest,
  apiRequestOptions?: ApiRequestOptions,
): Promise<ServicingResponse<Documents>> => {
  const { requestNumber } = getDocumentsRequest;
  const headers = await buildRequestHeaders({ withUserToken: true });

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-policy/v1/policies/${requestNumber}/documents`,
    options: {
      headers: headers,
      method: 'GET',
    },
    params: {},
    ...apiRequestOptions,
  });
};

export const getPolicyEOIDocument = async (
  getDocumentRequest: GetDocumentRequest,
): Promise<ServicingResponse<DocumentData>> => {
  const { requestNumber } = getDocumentRequest;
  const headers = await buildRequestHeaders({ withUserToken: true });

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-policy/v1/policies/${requestNumber}/documents/generate?documentType=EOI`,
    options: {
      headers: headers,
      method: 'GET',
    },
    params: {},
  });
};

export const getBillingDocument = async (
  getDocumentRequest: GetDocumentRequest,
): Promise<ServicingResponse<DocumentData>> => {
  const { requestNumber, documentId } = getDocumentRequest;
  const headers = await buildRequestHeaders({ withUserToken: true });

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-billing/v1/billings/${requestNumber}/documents/${documentId}`,
    options: {
      headers: headers,
      method: 'GET',
    },
    params: {},
  });
};

export const getBillingDocuments = async (
  getDocumentsRequest: GetDocumentsRequest,
  apiRequestOptions?: ApiRequestOptions,
): Promise<ServicingResponse<Documents>> => {
  const { requestNumber } = getDocumentsRequest;
  const headers = await buildRequestHeaders({ withUserToken: true });

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-billing/v1/billings/${requestNumber}/documents`,
    options: {
      headers: headers,
      method: 'GET',
    },
    params: {},
    ...apiRequestOptions,
  });
};
