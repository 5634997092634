import { useCallback } from 'react';

import { Stack } from '@mui/material';

import { Form, useForm } from '@ecp/utils/form';

import { LoadingButton, LoadingOverlay } from '@ecp/features/servicing/shared/components';
import type { UseModalPathReturn } from '@ecp/features/servicing/shared/routing';
import {
  getPolicyDisplayType,
  usePolicy,
  useSharedState,
} from '@ecp/features/servicing/shared/state';
import type { AutopayInformation } from '@ecp/features/servicing/shared/types';
import { autopayEnrollPaymentFrequencySchema } from '@ecp/features/servicing/shared/util';
import { IconCardAutomaticPayments } from '@ecp/themes/base';

import { AutopaySetupClassic } from '../AutopaySetup/AutopaySetupClassic';
import { useStyles } from './AutopayEdit.styles';

export const AutopayEditClassic: React.FC<UseModalPathReturn> = (props) => {
  const { goForward, sharedStateKey, policyNumber } = props;
  const { classes } = useStyles();
  const [, setSharedState] = useSharedState<AutopayInformation>(sharedStateKey);

  const {
    isFetching: isFetchingPolicy,
    isLoading: isPolicyLoading,
    policyData: policyResponse,
  } = usePolicy(policyNumber);
  const policyBeingEnrolled = policyResponse?.policy;

  const productTypeDisplay = getPolicyDisplayType(policyResponse);

  const formContext = useForm({
    validations: autopayEnrollPaymentFrequencySchema,
  });

  const isLoading = isFetchingPolicy || isPolicyLoading;

  const { handleSubmit } = formContext;
  const onSubmit = useCallback(() => {
    handleSubmit(async () => {
      setSharedState({
        paymentFrequency: formContext.getValues('paymentFrequency') ?? '',
      });
      goForward();
    })();
  }, [formContext, goForward, handleSubmit, setSharedState]);
  if (isLoading) return <LoadingOverlay />;

  const modalTitle = (() => {
    return `Enroll your ${productTypeDisplay} policy ${policyNumber} in AutoPay`;
  })();

  return (
    <Form
      id='enrollClassicAutopayForm'
      formProviderProps={formContext}
      onSubmit={onSubmit}
      className={classes.root}
    >
      <Stack spacing={3}>
        <IconCardAutomaticPayments className={classes.icon} />
        <span className={classes.title}>{modalTitle}</span>
        <AutopaySetupClassic key={policyBeingEnrolled?.policyNumber} {...props} />
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={4}>
          <LoadingButton
            type='submit'
            trackingName='review_and_continue'
            trackingLabel='enroll_continue'
            className={classes.submitButtton}
          >
            CONTINUE
          </LoadingButton>
        </Stack>
      </Stack>
    </Form>
  );
};
