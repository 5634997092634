import * as yup from 'yup';

// Regex to accept any char followed by space and another character after space
const namePattern = /^\S+\s\S/;

interface NameParameters {
  requiredMessage: string;
  invalidMessage?: string;
  maxCharactersInvalidMessage?: string;
  maxCharacters?: number;
}
export const getNameSchema = ({
  requiredMessage,
  invalidMessage = 'Please enter your first and last name separated by a space',
  maxCharactersInvalidMessage = 'Signature cannot exceed 40 characters',
  maxCharacters = 40,
}: NameParameters): yup.AnySchema => {
  return yup
    .string()
    .required(requiredMessage)
    .matches(namePattern, invalidMessage)
    .max(maxCharacters, maxCharactersInvalidMessage);
};
