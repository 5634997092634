import type { PolicyResponse } from '@ecp/features/servicing/shared/types';

import { apiRoot, buildRequestHeaders } from '../agentToolingApiConfig';
import type { DocumentData } from '../document/types';
import type { ApiRequestOptions, ServicingResponse } from '../servicingRequest';
import { servicingRequest } from '../servicingRequest';
import type {
  AccountStatusResponse,
  GetAgentEOIDocumentRequest,
  GetPolicyUserSearchRequest,
  PolicyUserSearchResponse,
  UpdateEmailRequest,
  UpdateEmailSuccess,
} from './types';

export const getPolicyForUserSearch = async (
  request: GetPolicyUserSearchRequest,
  apiRequestOptions?: ApiRequestOptions,
): Promise<ServicingResponse<PolicyUserSearchResponse[]>> => {
  const headers = await buildRequestHeaders({ withUserToken: true });

  return servicingRequest({
    baseUrl: `${apiRoot}/agents/userSearch`,
    params: { ...request },
    options: {
      headers: headers,
      method: 'GET',
    },
    ...apiRequestOptions,
  });
};

export const getAssociatedPolicy = async (
  policyNumber: string,
  apiRequestOptions?: ApiRequestOptions,
): Promise<ServicingResponse<PolicyResponse>> => {
  const headers = await buildRequestHeaders({ withUserToken: true });

  return servicingRequest({
    baseUrl: `${apiRoot}/agents/policies/${policyNumber}`,
    options: {
      headers: headers,
      method: 'GET',
    },
    ...apiRequestOptions,
  });
};

export const postDelinkPolicy = async (
  policyNumber: string,
  userId: string,
  partnerId: string,
  apiRequestOptions?: ApiRequestOptions,
): Promise<ServicingResponse<boolean>> => {
  const headers = await buildRequestHeaders({ withUserToken: true, partnerId });

  return servicingRequest({
    baseUrl: `${apiRoot}/agents/policies/${policyNumber}/delink`,
    options: {
      headers: headers,
      method: 'POST',
      body: JSON.stringify({ userId: userId }),
    },
    ...apiRequestOptions,
  });
};

export const postLinkPolicy = async (
  {
    policyNumber,
    userId,
    dateOfBirth,
    partnerId,
  }: {
    policyNumber: string;
    userId: string;
    dateOfBirth: string;
    partnerId: string;
  },
  apiRequestOptions?: ApiRequestOptions,
): Promise<ServicingResponse<boolean>> => {
  const headers = await buildRequestHeaders({ withUserToken: true, partnerId });

  return servicingRequest({
    baseUrl: `${apiRoot}/agents/policies/${policyNumber}/link`,
    options: {
      headers: headers,
      method: 'POST',
      body: JSON.stringify({ userId: userId, dateOfBirth: dateOfBirth }),
    },
    ...apiRequestOptions,
  });
};
export const postUnlockAccount = async (
  userId: string,
  partnerId: string,
  apiRequestOptions?: ApiRequestOptions,
): Promise<ServicingResponse<boolean>> => {
  const headers = await buildRequestHeaders({ withUserToken: true, partnerId });

  return servicingRequest({
    baseUrl: `${apiRoot}/agents/users/${userId}/unlockAccount`,
    options: {
      headers: headers,
      method: 'POST',
    },
    ...apiRequestOptions,
  });
};

export const getEOIDocument = async (
  getDocumentRequest: GetAgentEOIDocumentRequest,
  apiRequestOptions?: ApiRequestOptions,
): Promise<ServicingResponse<DocumentData>> => {
  const { policyNumber } = getDocumentRequest;
  const headers = await buildRequestHeaders({ withUserToken: true });

  return servicingRequest({
    baseUrl: `${apiRoot}/agents/policies/${policyNumber}/generateEOI`,
    options: {
      headers: headers,
      method: 'GET',
    },
    params: {},
    ...apiRequestOptions,
  });
};
export const postAccountStatus = async (
  userId: string,
  partnerId: string,
  apiRequestOptions?: ApiRequestOptions,
): Promise<ServicingResponse<AccountStatusResponse>> => {
  const headers = await buildRequestHeaders({ withUserToken: true, partnerId });

  return servicingRequest({
    baseUrl: `${apiRoot}/agents/users/${userId}/status`,
    options: {
      headers: headers,
      method: 'POST',
    },
    ...apiRequestOptions,
  });
};

export const updateEmail = async (
  body: UpdateEmailRequest,
  apiRequestOptions?: ApiRequestOptions,
): Promise<ServicingResponse<UpdateEmailSuccess>> => {
  const { userId, email, partnerId } = body;
  const headers = await buildRequestHeaders({ withUserToken: true, partnerId });

  return servicingRequest({
    baseUrl: `${apiRoot}/agents/users/${userId}/email`,
    options: {
      headers: headers,
      method: 'PUT',
      body: JSON.stringify({ email }),
    },
    ...apiRequestOptions,
  });
};
