import { Stack } from '@mui/material';

import { NavLink } from '@ecp/features/servicing/shared/components';
import { generateHelpPath, PAGE_PATH } from '@ecp/features/servicing/shared/routing';

import { DisclosureWrapper } from '../../../components';

export const TermsAndConditionsHomesite: React.FC = () => {
  return (
    <DisclosureWrapper
      title={`Homesite's Terms and Conditions`}
      breadcrumbTitleOverrides={{ terms: 'Terms & Conditions' }}
    >
      <Stack spacing={4}>
        <Stack spacing={1}>
          <p>
            Terms and Conditions of Use ALL RIGHTS RESERVED. No part of the materials made available
            may be reproduced, stored in a retrieval system, or transmitted, in any form or by any
            means electronic, mechanical, photocopying, recording, or otherwise without prior
            written approval.
          </p>
          <p>
            The precise coverage afforded by Homesite is subject to the terms and conditions of the
            Homesite Insurance policies as issued. These coverage summaries are provided for your
            convenience only and are not intended to replace or supersede the policy's terms and
            conditions. Whether and to what extent a particular loss is covered depends on the facts
            and circumstances of the loss, and the terms and conditions of the policy as issued.
          </p>
        </Stack>
        <Stack spacing={1}>
          <h2>Terms & Conditions of Use</h2>
          <Stack spacing={1}>
            <p>
              1. Except for information provided which is directly related the quotation or binding
              of insurance coverage, the materials made available, including materials in linked
              sites directly or indirectly accessible from here, are provided "AS IS" WITHOUT
              WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE
              PURSUANT TO APPLICABLE LAW, HOMESITE DISCLAIMS ALL WARRANTIES OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR NONINFRINGEMENT.
            </p>
            <p>
              HOMESITE DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE MATERIALS WILL BE
              UNINTERRUPTED OR ERRORFREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THIS SITE OR THE
              SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. EXCEPT
              FOR INFORMATION PROVIDED WHICH IS DIRECTLY RELATED TO THE QUOTATION OR BINDING OF
              INSURANCE COVERAGE, HOMESITE DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING
              THE USE OR THE RESULTS OF THE USE OF THE MATERIALS IN THIS SITE IN TERMS OF THEIR
              CORRECTNESS.
            </p>
            <p>
              IN THE EVENT THAT YOU DOWNLOAD SOFTWARE OR OTHER THIRD PARTY INFORMATION FROM THIS
              SITE, HOMEISTE IS NOT RESPONSIBLE FOR ITS ACCURACY OR, RELIABILITY. YOU (AND NOT
              HOMESITE) ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR, OR CORRECTION
              WHICH RESULTS FROM SAID DOWNLOADING. APPLICABLE LAW MAY NOT ALLOW THE EXCLUSION OF
              IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.
            </p>
            <p>
              2. The materials that can be accessed from linked sites are not maintained by us and
              we are not responsible for the contents thereof. Any reference to a linked site or any
              specific third party product or service by name does not constitute or imply its
              endorsement by us, and you assume all risk with respect to its use.
            </p>
            <p>
              3. Except for information provided which is directly related to the quotation or
              binding of insurance coverage, we, our affiliates, agents, and suppliers, shall not be
              liable for any damages, including without limitation, direct, indirect, incidental,
              special, punitive, consequential, or other damages (including without limitation lost
              profits, lost revenues, or similar economic loss), weather in contract, tort, or
              otherwise, arising out of the use or inability to use materials available here or in
              any linked site, even if we are advised of the possibility thereof, nor for any claim
              by a third party.
            </p>
            <p>
              4. This web site is for your use, and you agree to use this for lawful purposes only.
              You shall not copy, use, modify, transmit, distribute, reverse engineer, or in anyway
              exploit copyrighted or proprietary materials available from here, except as expressly
              permitted by the respective owner(s) thereof. Use of any software available for
              downloading is governed by the terms of the applicable license agreement accompanying
              or included with the software. All trademarks, service marks, and trade names are the
              marks of the respective owner(s), and any unauthorized use thereof is strictly
              prohibited.
            </p>
            <p>
              5. You agree to defend, indemnify, and hold us and our affiliates harmless from and
              against any and all claims, losses, liabilities, damages and expenses (including
              attorney's fees) arising out of your unlawful use.
            </p>
            <p>
              6. Homesite explicitly disclaims any responsibility for the accuracy, content, or
              availability of information found on sites that link to or from Homesite.com or sites
              that are owned by Homesite Group Inc. from third parties not associated with Homesite.
              Homesite cannot be held responsible for the accuracy, copyright compliance, legality,
              or decency of material contained in third-party sites, and you hereby irrevocably
              waive any claim against us with respect to such sites.
            </p>
            <p>
              7. The terms of use shall be governed in accordance with the laws of the Commonwealth
              of Massachusetts, U.S.A., excluding its conflict of law provisions. We reserve the
              right to add, delete, or modify any or all terms of use at any time with or without
              notice. Homesite Group Inc. 1999. All rights reserved. The Homesite web site may
              contain other proprietary notices and copyright information, which should be observed.
              Homesite trademarks including, but not limited to:
            </p>
            <Stack spacing={1}>
              <ul>
                <li>Homesite</li>
                <li>DirectPlatinum</li>
                <li>DirectGold</li>
                <li>DirectRent</li>
                <li>DirectCondo</li>
                <li>24Direct</li>
              </ul>
            </Stack>
          </Stack>
        </Stack>
        <Stack spacing={1}>
          <h2>Notice and Procedure for making claims of copyright infringement:</h2>
          <Stack spacing={1}>
            <p>
              Pursuant to Title 17, United States Code, Section 512(c)(2), notifications of claimed
              copyright infringement should be sent to Service Provider's Designated Agent. Written
              notification must be submitted to the following Designated Agent:
            </p>
            <Stack spacing={1}>
              <p>
                Homesite Group Incorporated
                <br /> General Counsel
                <br /> One Federal Street, Boston MA 02110
              </p>
            </Stack>
            <p>To be effective, the Notification must include the following:</p>
            <ol>
              <li>
                A physical or electronic signature of a person authorized to act on behalf of the
                owner of an exclusive right that is allegedly infringed;
              </li>
              <li>
                Identification of the copyrighted work claimed to have been infringed, or if
                multiple copyrighted works at a single online site are covered by a single
                notification, a representative list of such works at that site;
              </li>
              <li>
                Identification of the material that is claimed to be infringing or to be the subject
                of infringing activity and that is to be removed or access to which is to be
                disabled, and information reasonably sufficient to permit the service provider to
                locate the material;
              </li>
              <li>
                Information reasonably sufficient to permit the service provider to contact the
                Complaining Party, such as an address, telephone number, and if available, an
                electronic mail address at which the complaining party may be contacted;
              </li>
              <li>
                A statement that the Complaining Party has a good faith belief that use of the
                material in the manner complained of is not authorized by the copyright owner, its
                agent, or the law;
              </li>
              <li>
                A statement that the information in the notification is accurate, and under penalty
                of perjury, that the Complaining Party is authorized to act on behalf of the owner
                of an exclusive right that is allegedly infringed.
              </li>
            </ol>
          </Stack>
        </Stack>
        <Stack spacing={1}>
          <h2>Acceptance Of These Terms</h2>
          <p>
            By using this site, you signify your consent to Homesite's on-line Terms and Condition
            of Use. If you do not agree to these policies, please do not use this site. We reserve
            the right, at our discretion, to update, change, modify, add, or remove portions of this
            policy from time to time. Your continued use of this site and others that may be owned
            by Homesite following the posting of changes to these terms will mean you accept those
            changes. Any changes to this policy will be promptly disclosed in this area.
          </p>
          <p>
            Take a few minutes to get a free, no obligation homeowners insurance quote, and see just
            how affordable our rates can be.
          </p>
        </Stack>
        <Stack spacing={1}>
          <h2>Homesite Texting Programs - Terms & Conditions</h2>
          <p>
            For additional Claims Texting Terms & Conditions, please click{' '}
            <NavLink
              to={generateHelpPath(PAGE_PATH.TERMSANDCONDITIONS_CLAIMSTEXTING, 'homesite')}
              trackingName='claims_texting_terms_conditions_link'
              trackingLabel='claims_texting_terms_conditions'
              data-testid='claims_texting_terms'
            >
              here.
            </NavLink>
          </p>
          <p>
            For additional Billing, Account, and Policy Texting Terms & Conditions, please click{' '}
            <NavLink
              to={generateHelpPath(PAGE_PATH.TERMSANDCONDITIONS_BILLINGTEXTING, 'homesite')}
              trackingName='billing_account_policy_texting_terms_conditions_link'
              trackingLabel='billing_account_policy_texting_terms_conditions'
              data-testid='billing_account_policy_texting'
            >
              here.
            </NavLink>
          </p>
          <p>
            For additional Offerings & Quoting Texting Terms & Conditions, please click{' '}
            <NavLink
              to={generateHelpPath(PAGE_PATH.TERMSANDCONDITIONS_OFFERINGTEXTING, 'homesite')}
              trackingName='offerings_quoting_texting_terms_conditions_link'
              trackingLabel='offerings_quoting_texting_terms_conditions'
              data-testid='offerings_quoting_texting'
            >
              here.
            </NavLink>
          </p>
        </Stack>
      </Stack>
    </DisclosureWrapper>
  );
};
