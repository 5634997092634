import { Divider, Stack } from '@mui/material';

import { PhoneLink } from '@ecp/components';
import { MODAL_FLOW, MODAL_STEP } from '@ecp/features/servicing/shared/routing';
import {
  getDriverDescription,
  getPoiDocument,
  getProductLineFromPolicyResponse,
  getVehicleDescription,
  showAddDriver,
  showAddVehicle,
  usePaymentInfoForAllPolicies,
  usePolicy,
  useRequestDocuments,
} from '@ecp/features/servicing/shared/state';
import type { Person, PolicySource, Vehicle } from '@ecp/features/servicing/shared/types';
import { getBrandData } from '@ecp/features/servicing/shared/util';
import { IconCardAuto, IconUIDriver } from '@ecp/themes/base';

import { AddACard } from '../AddACard';
import { InfoCardList } from '../InfoCardList';
import { LoadingOverlay } from '../LoadingOverlay';
import { VehicleDriverSummaryCard } from '../VehicleDriverSummaryCard';
import { useStyles } from './AutoSummaryCardList.styles';

interface Props {
  vehicles?: Vehicle[];
  drivers?: Person[];
  policyNumber: string;
  policySource?: PolicySource;
}

export const AutoSummaryCardList: React.FC<Props> = (props) => {
  const { vehicles, drivers, policyNumber, policySource } = props;
  const { classes } = useStyles();

  const { documents } = useRequestDocuments({ documentClass: 'Policy', policyNumber });
  const policyResponse = usePolicy(policyNumber);
  const productLine = getProductLineFromPolicyResponse(policyResponse.policyData);

  const { paymentInfoResults, isLoading: isLoadingPaymentInfo } = usePaymentInfoForAllPolicies({
    throwOnError: false,
  });

  const policyPaymentInfo = paymentInfoResults?.find(
    (paymentInfo) => paymentInfo.paymentInfo.policyNumber === policyNumber,
  );

  const vehicleTitle = vehicles && vehicles.length > 1 ? 'Vehicles' : 'Vehicle';
  const driverTitle = drivers && drivers.length > 1 ? 'Drivers' : 'Driver';

  const customerServicePhone = getBrandData(policyResponse.policyData).mainCustServPhoneNum;

  const driverCards = drivers?.map((driver, index) => {
    return (
      <VehicleDriverSummaryCard
        key={driver.firstName ?? index}
        description={getDriverDescription(driver)}
        isDriver
        policySource={policySource}
        policyNumber={policyNumber}
        productLine={productLine}
        customerServicePhone={customerServicePhone}
      />
    );
  });

  const { showAddDriverLink, showDriverAlert } = showAddDriver(
    policyPaymentInfo?.paymentInfo.billingAccount,
    policyPaymentInfo?.paymentInfo.policyData,
  );
  const { showAddVehicleLink, showVehicleAlert } = showAddVehicle(
    policyPaymentInfo?.paymentInfo.billingAccount,
    policyPaymentInfo?.paymentInfo.policyData,
  );

  if (isLoadingPaymentInfo) return <LoadingOverlay />;

  return (
    <Stack spacing={2} className={classes.root}>
      {!!vehicles?.length && (
        <>
          <InfoCardList title={vehicleTitle} id='vehicles'>
            {vehicles?.map((vehicle, index) => (
              <VehicleDriverSummaryCard
                key={vehicle.vin ?? index}
                description={getVehicleDescription(vehicle)}
                poiDocument={getPoiDocument(vehicle, documents)}
                policySource={policySource}
                policyNumber={policyNumber}
                productLine={productLine}
                vehicleId={vehicle.vehicleId}
              />
            ))}
            {showAddVehicleLink && (
              <AddACard
                icon={<IconCardAuto className={classes.icon} />}
                btnText='Add a vehicle'
                flow={MODAL_FLOW.ADD_VEHICLE}
                step={MODAL_STEP.MODAL_EDIT}
                trackingLabel='add_vehicle_continue'
                trackingName='add_vehicle_link'
                objectType='link'
              />
            )}
          </InfoCardList>
          {showVehicleAlert && (
            <span className={classes.driverAlert}>
              You've reached the maximum number of vehicles allowed on this policy. For help
              managing your vehicles, call
              <PhoneLink
                number={customerServicePhone}
                withUnderlinedLinkStyle
                trackingName='manage_vehicle_phone_link'
                trackingLabel='manage_vehicle_phone_link_continue'
              />
              .
            </span>
          )}
        </>
      )}
      {!!drivers?.length && (
        <>
          <Divider />
          <InfoCardList title={driverTitle} id='drivers'>
            {driverCards}
            {showAddDriverLink && (
              <AddACard
                icon={<IconUIDriver className={classes.icon} />}
                btnText='Add a driver'
                flow={MODAL_FLOW.ADD_DRIVER}
                step={MODAL_STEP.MODAL_EDIT}
                trackingLabel='add_driver_continue'
                trackingName='add_driver_link'
                objectType='link'
              />
            )}
          </InfoCardList>
          {showDriverAlert && (
            <span className={classes.driverAlert}>
              You've reached the maximum number of drivers allowed on this policy. For help managing
              your drivers, call
              <PhoneLink
                number={customerServicePhone}
                withUnderlinedLinkStyle
                trackingName='manage_driver_phone_link'
                trackingLabel='manage_driver_phone_link_continue'
              />
              .
            </span>
          )}
        </>
      )}
    </Stack>
  );
};
