import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ContactUsMicro' })((theme) => ({
  root: { maxWidth: 498 },
  divider: {
    [theme.breakpoints.down('md')]: { display: 'none' },
  },
  body: {
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: { alignItems: 'center' },
  },
  button: {
    ...theme.typography.button2,
    height: 'fit-content',
    paddingBottom: 12,
    paddingTop: 12,
    [theme.breakpoints.down('md')]: { width: '100%' },
  },
  subHeading: theme.typography.body1Bold,
  bodyText: theme.typography.body1,
  iconMessage: { width: '100%' },
  iconText: { alignSelf: 'center' },
  iconWrapper: { display: 'flex' },
}));
