import type { Context } from '@datadog/browser-core';

import { uuid } from '@ecp/utils/common';
import { flagValues } from '@ecp/utils/flags';
import type { DataDogLogContext } from '@ecp/utils/logger';
import { datadogLog } from '@ecp/utils/logger';
import { Headers, makeUrl, typedFetch } from '@ecp/utils/network';
import { Queue } from '@ecp/utils/queue';

import type { EnterpriseStatus, EnterpriseStatusMessage, ServicingResponseBase } from '@ecp/types';

import type { ApiRequestOptions } from './dataRequestResponse.types';

/** This queue is the default for all requests, so only one happens at a time */
const servicingQueue = new Queue();

// ensure we only fetch using strings
type RequestInitBodyString = Omit<RequestInit, 'body'> & {
  body?: string | null;
} & Record<string, unknown>;

export interface ErrorStack {
  status: Omit<EnterpriseStatus, 'messages'> & { messages: EnterpriseStatusMessage[] };
}

export class ServicingRequestError extends Error {
  public requestUrl?: string;

  public requestId?: string;

  public requestOptions?: RequestInit;

  public response?: Response;

  public errorStack?: ErrorStack;

  public constructor(
    message?: string,
    requestUrl?: string,
    requestId?: string,
    requestOptions?: RequestInit,
    response?: Response,
    errorStack?: ErrorStack,
  ) {
    super(message);

    // Set the prototype explicitly to work properly with es5 - https://github.com/Microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
    Object.setPrototypeOf(this, ServicingRequestError.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ServicingRequestError);
    }

    this.name = 'ServicingRequestError';
    this.requestUrl = requestUrl;
    this.requestOptions = requestOptions;
    this.response = response;
    this.requestId = requestId;
    this.errorStack = errorStack;
  }
}

/**
 * Note: servicingRequest() and ServicingResponse do not `check` if the payload actually matches the declared type.
 * You may get runtime errors if the actual response has something outside the definition.
 */
export interface ServicingResponse<T> {
  status: number;
  payload: T;
  headers: Record<string, string>;
  requestId?: string;
}

interface ServicingRequestParams extends ApiRequestOptions {
  queue?: Queue;
  baseUrl: string;
  params?: Record<string, string>;
  options?: RequestInitBodyString;
  allResults?: boolean;
  key?: string;
  /**
   * set to true for workflows where we have potentially multiple POST/PUT calls that we want to ensure happen in the proper order (e.g. SAPI PATCH).
   * Default is `false`
   **/
  useQueue?: boolean;
}

// used by other files in this directory to define each
// kind of request, not used directly outside of api/servicing
export async function servicingRequest<T>({
  queue = servicingQueue,
  baseUrl,
  params,
  options: requestOptions = {},
  allResults = false,
  key,
  useQueue = false,
  dontLogErrorsForStatus = [],
  dontLogErrorsForErrorCode = [],
}: ServicingRequestParams): Promise<ServicingResponse<T>> {
  const requestUrl = makeUrl({ url: baseUrl, params, method: requestOptions.method });

  const requestId = uuid();

  const work = processRequest<T>({
    url: requestUrl,
    init: requestOptions,
    requestId,
  });

  const context: DataDogLogContext = {
    logOrigin: 'libs/features/servicing/shared/state/src/servicingRequest/servicingRequest.ts',
    functionOrigin: 'servicingRequest',
    requestUrl,
    requestId,
    requestHeaders: JSON.stringify(requestOptions.headers),
    severity: 'medium',
  };

  const res = await (useQueue ? queue.add({ key, work }) : work()).catch((e) => {
    const message = [
      'Error making request',
      `[${e instanceof Error ? e.message : ''}]`,
      `from ${requestUrl}`,
    ].join(' - ');
    datadogLog({ logType: 'error', message, context, error: e });
    throw e;
  });

  let payload: ServicingResponseBase<T>;
  try {
    payload = await res.json();
  } catch (e) {
    payload = {
      status: {
        code: 500,
        reason: 'Invalid format received',
        messages: [{ description: 'Could not parse the response into JSON format' }],
      },
    };
  }
  const payloadBody = JSON.stringify(payload).toLowerCase();

  /**
   * This is for DD to make sure that the full error body is completly
   * parsed. Sometimes there is an error array and the array items are
   * objects that, for some reason, aren't fully parsed. This should
   * solve that issue.
   */
  let parsedPayloadBody: ServicingResponseBase<T>;

  try {
    parsedPayloadBody = JSON.parse(payloadBody);
  } catch (e) {
    parsedPayloadBody = payload;
  }

  const headers = Object.fromEntries(res.headers.entries());

  if (!allResults) {
    const logAllServicingRequestsEnabled = flagValues.LOG_ALL_SERVICING_REQUESTS;
    const hadBadApiResponse = !res.ok && res.status !== 501;
    const logInfo =
      dontLogErrorsForStatus.some((error) => error === res.status) ||
      dontLogErrorsForErrorCode.some(
        (error) => parsedPayloadBody?.status?.messages?.[0].code === error,
      ) ||
      !hadBadApiResponse;
    const logType = logInfo ? 'info' : 'error';
    const message = hadBadApiResponse
      ? `API (response) has bad error status [${res.status}] [${
          parsedPayloadBody?.status?.messages?.at(0)?.code
        }] from ${baseUrl}`
      : `API (response) has status [${res.status}] from ${baseUrl}`;

    if (logAllServicingRequestsEnabled || hadBadApiResponse) {
      datadogLog({
        logType,
        message,
        context: {
          ...context,
          payloadBody,
          responseHeaders: headers,
          responsePayload: parsedPayloadBody as unknown as Context,
          payloadMessage: payload.status?.messages as unknown as Context[],
          responseStatus: res.status,
          statusText: res.statusText,
        },
      });
    }

    if (hadBadApiResponse) {
      throw new ServicingRequestError(
        `API (response) had bad error status [${res.status}] ${baseUrl}`,
        requestUrl,
        requestId,
        requestOptions,
        res,
        parsedPayloadBody as unknown as ErrorStack,
      );
    }
  }

  return { status: res.status, payload: payload.response as T, headers, requestId };
}

function processRequest<T>({
  url,
  init,
  requestId,
}: {
  url: string;
  init: RequestInit;
  requestId?: string;
}) {
  return async () => {
    const result = await typedFetch<ServicingResponseBase<T>>(url, {
      ...init,
      headers: {
        ...init.headers,
        [Headers.TRACE_ID]: requestId || uuid(),
      },
    });

    return result;
  };
}
