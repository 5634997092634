import { trackClick } from '@ecp/utils/analytics/tracking';
import { uuid } from '@ecp/utils/common';
import { useEffectOnce } from '@ecp/utils/react';

import { addConversationStartedListener, addCustomAttributes } from '@ecp/features/shared/chat';

export const useAnalytics = (): void => {
  useEffectOnce(() => {
    addConversationStartedListener(async () => {
      const traceId = uuid();
      addCustomAttributes({ actionTraceId: traceId });
      trackClick({
        action: 'chat_button',
        label: 'chat_open',
        objectType: 'button',
        traceId,
      });
    });
  });
};
