import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ContactUsModal' })((theme) => ({
  title: {
    ...theme.typography.h2,
    fontWeight: 'normal',
  },
  subtitle: {
    ...theme.typography.h3,
    color: theme.palette.primary.dark,
  },
  body: theme.typography.body1,
  link: {
    color: theme.palette.primary.dark,
    textDecoration: 'none',
  },
}));
