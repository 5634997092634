import { Stack, Typography } from '@mui/material';

import { HelpBox } from '@ecp/components';
import { NavLink } from '@ecp/features/servicing/shared/components';
import { PAGE_PATH } from '@ecp/features/servicing/shared/routing';
import { GraphicUIAgentAvatarImageUrl } from '@ecp/themes/base';

export interface AuthHelpBoxProps {
  title: string;
  subText: string;
  trackingName?: string;
  trackingLabel?: string;
}

export const AuthHelpBox: React.FC<AuthHelpBoxProps> = (props) => {
  const { title, subText, trackingName, trackingLabel } = props;

  return (
    <HelpBox
      image={GraphicUIAgentAvatarImageUrl}
      content={
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 0, md: 1 }}>
          <Typography variant='body1Bold'>{title}</Typography>
          <NavLink
            to={
              PAGE_PATH.RECOVER_EMAIL_RESET_PASSWORD
            } /* This component is in use only on recoveremail flow */
            trackingName={trackingName}
            trackingLabel={trackingLabel}
          >
            {subText}
          </NavLink>
        </Stack>
      }
    />
  );
};
