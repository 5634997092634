import { registerEvents, uuid } from '@ecp/utils/common';
import { getTime } from '@ecp/utils/date';
import type { PersistentStorage } from '@ecp/utils/storage';
import { storageProxy } from '@ecp/utils/storage';

import { EXPIRES_IN, moduleName } from './constants';

interface State {
  /** Interaction id value. */
  value: string;
  /** Creation time in seconds as UNIX timestamp (UTC). */
  timestamp: number;
}

/** Backup storage mechanism to initialize and persist local state. Must be updated each time state gets updated. */
let storage: PersistentStorage | undefined;
/** All reads except initialization must be from this local state. */
let state: State = {
  value: '',
  timestamp: 0,
};
/** Whether the module was initialized or not. */
let initialized: boolean;
/** How many seconds before the value gets reset. */
let expiresIn: number = EXPIRES_IN;
const events = registerEvents('change');

const isExpired = (): boolean => getTime() - state.timestamp > expiresIn;

const refreshExpires = (): void => {
  state.timestamp = getTime();
};

export const reset = (): State['value'] => {
  state.value = uuid();
  refreshExpires();
  events.notifyEventListeners('change');

  return state.value;
};

export const get = (): State['value'] => {
  if (!state || !state.value || !state.timestamp || isExpired()) return reset();

  refreshExpires();

  return state.value;
};

export const addEventListener = events.addEventListener;
export const removeEventListener = events.removeEventListener;

/**
 * If we want to use storage mechanism to persist the module state,
 * we must first initialize the module before calling any of the module functions.
 *
 * @param params.expiresIn How many seconds before the value gets reset.
 * @param params.storage Backup storage mechanism to initialize and persist the module local state.
 */
export const initialize = (params: {
  expiresIn?: typeof expiresIn;
  storage?: typeof storage;
}): void => {
  if (initialized) return;

  if (params.expiresIn) expiresIn = params.expiresIn;
  if (params.storage) {
    storage = params.storage;
    const savedState = { ...(storage.getItem(moduleName) as unknown as State) };
    state = storageProxy(savedState, storage, moduleName);
  }
  initialized = true;
};
