import { useParams } from 'react-router-dom';

import { BillingTextingHomesite } from './homesite';

export const BillingTextingPage: React.FC = () => {
  const { opco } = useParams();

  let billingTexting;
  switch (opco) {
    case 'homesite':
      billingTexting = <BillingTextingHomesite />;
      break;
    default:
      billingTexting = <p>Partner Specific Billing Texting.</p>;
  }

  return billingTexting;
};
