import { useCallback, useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material';
import Iframe from 'react-iframe';

import { PAGE_PATH, type UseModalPathReturn } from '@ecp/features/servicing/shared/routing';
import { usePaymentUrl, usePolicy, useSharedState } from '@ecp/features/servicing/shared/state';
import type { ClassicPaymentToken } from '@ecp/features/servicing/shared/types';

import { useStyles } from './PaymentsIFrame.styles';
import { useOnMessage } from './useOnMessage';

export const PaymentsIFrame: React.FC<UseModalPathReturn> = (props) => {
  const { policyNumber } = props;
  const { classes } = useStyles();

  const [, setClassicPaymentToken] = useSharedState<ClassicPaymentToken>(
    `financialAccountToken-${policyNumber}`,
  );

  const { policyData, isLoading: isLoadingPolicy } = usePolicy(policyNumber);
  const {
    url,
    isLoading: isLoadingPaymentUrl,
    isError: isErrorPaymentUrl,
  } = usePaymentUrl({
    billingAccountNumber: policyNumber,
    policySource: policyData?.policy.sourceSystemName,
    callbackUrl: window.location.origin + PAGE_PATH.CALLBACK_WORLDPAY,
  });

  useEffect(() => {
    if (isErrorPaymentUrl)
      setClassicPaymentToken({ financialAccountToken: '', isValid: true, isError: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorPaymentUrl]);

  const { onMessage, setLoaded, loaded } = useOnMessage(policyNumber);
  useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => window.removeEventListener('message', onMessage);
  }, [onMessage, setLoaded]);

  // try to keep our loading overlay up as long as possible before closing in favor of iframe
  const handleLoad = useCallback(() => {
    const timeout = setTimeout(() => setLoaded(true), 500);

    return () => clearTimeout(timeout);
  }, [setLoaded]);

  const [delay, setDelay] = useState(600);
  useEffect(() => {
    const timer = setInterval(() => {
      setDelay(delay - 1);
    }, 1000);

    if (delay === 0)
      setClassicPaymentToken({ financialAccountToken: '', isValid: false, isError: false });

    return () => {
      clearInterval(timer);
    };
  }, [delay, setClassicPaymentToken]);

  return (
    <div className={classes.root}>
      {(isLoadingPaymentUrl || isLoadingPolicy || !loaded || !url) && (
        <div className={classes.spinner}>
          <CircularProgress aria-label='Loading...' />
        </div>
      )}
      {url && (
        <>
          <Iframe url={url} title='Payment' className={classes.iframe} onLoad={handleLoad} />
          {/* {env.static.nodeEnv === 'development' && (
            <PaymentsIFrameTestHarness onMessage={onMessage} />
          )} */}
        </>
      )}
    </div>
  );
};
