import { Divider, Stack } from '@mui/material';

import type { ButtonVariant } from '@ecp/components';
import { ScrollSpyMarkerClasses } from '@ecp/components';
import { GraphicUIAgentAvatar } from '@ecp/themes/base';

import { Card } from '../Card';
import { ContactUsLink } from '../ContactUsLink';
import { useStyles } from './ContactUsMicro.styles';

export interface Props {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  buttonVariant?: ButtonVariant;
}
export const ContactUsMicro: React.FC<Props> = (props) => {
  const { buttonVariant = 'primary' } = props;
  const { classes, cx } = useStyles(undefined, { props });

  return (
    <Card
      className={classes.root}
      body={
        <Stack
          spacing={{ xs: 2, md: 3 }}
          direction={{ xs: 'column', md: 'row' }}
          className={classes.body}
          divider={<Divider orientation='vertical' className={classes.divider} flexItem />}
        >
          <Stack spacing={2} direction='row' className={classes.iconMessage}>
            <div className={classes.iconWrapper}>
              <GraphicUIAgentAvatar />
            </div>
            <Stack spacing={0} className={classes.iconText}>
              <span
                id='need_help'
                className={cx(ScrollSpyMarkerClasses.FOOTER, classes.subHeading)}
              >
                Need help?
              </span>
              <span className={classes.bodyText}>Contact our customer care center.</span>
            </Stack>
          </Stack>
          <ContactUsLink
            variant={buttonVariant}
            className={classes.button}
            objectType='button'
            trackingName='contact_card_button'
            trackingLabel='help_continue'
          >
            Contact Us
          </ContactUsLink>
        </Stack>
      }
    />
  );
};
