import { makeStyles } from '@ecp/themes/base';

const borderRadius = 8;
export const useStyles = makeStyles<void, 'checked' | 'svg'>({ name: 'ButtonCards' })(
  (...[theme, , classes]) => ({
    productDesc: {
      backgroundColor: theme.palette.primary.contrastText,
      borderRadius: borderRadius,
      '& .MuiAvatar-root, & .MuiAvatar-circular': { boxShadow: 'none' },
    },
    buttonGrid: {
      alignContent: 'center',
      alignItems: 'center',
      position: 'relative',
      textAlign: 'center',
    },
    button: {
      width: 95,
      height: 85,
      minHeight: 100,
      border: '1px solid transparent',
      borderRadius: borderRadius,
      padding: '10px 10px 15px 10px',
      textTransform: 'none',
      textAlign: 'center',
      color: theme.palette.text.primary,
      borderColor: theme.palette.grey[600],
      backgroundColor: theme.palette.primary.contrastText,
      [theme.breakpoints.up('md')]: {
        minHeight: 85,
      },
      '& > *': {
        display: 'block', // mui label reset
      },
      [`&.${classes.checked}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
    buttonLabel: {
      ...theme.typography.body1Bold,
      textAlign: 'center',
      whiteSpace: 'normal',
    },
    svg: {
      '& svg': {
        maxHeight: 33,
      },
      '& .zone04, & .zone03': {
        fill: 'transparent',
      },
      [`.${classes.checked} & .zone01, & .zone02`]: {
        fill: theme.palette.primary.contrastText,
      },
    },
    buttonText: { color: theme.palette.primary.contrastText },
    checked: {
      '&:after': {
        backgroundColor: theme.palette.grey[50],
        bottom: -22,
        clipPath: 'inset(0px 0px -10px -10px)',
        content: '""',
        height: 20,
        left: '50%',
        position: 'absolute',
        right: '50%',
        transform: 'translate(-50%, 50%) rotate(135deg)',
        width: 20,
        zIndex: 1,
      },
      [`& .${classes.svg} > svg > path`]: {
        fill: theme.palette.primary.contrastText,
      },
    },
    helpBoxContainer: {
      position: 'absolute',
      left: 0,
      paddingTop: 16,
      zIndex: 1,
      width: '100%',
      '& >div': {
        backgroundColor: theme.palette.primary.contrastText,
      },
    },
  }),
);
