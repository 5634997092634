import type { Address, UiPolicy } from '@ecp/features/servicing/shared/types';

import { getAddressByRefId } from './getAddressByRefId';

/**
 * Returns the insured address of a home policy
 * @param policyResponse A response from the policy api
 * @returns The insured address of a home policy
 */
export const getDwellingAddress = (policyResponse: UiPolicy | undefined): Address | undefined =>
  getAddressByRefId(policyResponse, policyResponse?.dwellings?.at(0)?.dwellingAddressRefId);
