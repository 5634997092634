import type { CardPattern, CardType } from './types';

const cardPatterns: CardPattern[] = [
  { type: 'amex', pattern: /^3/ },
  { type: 'discover', pattern: /^6/ },
  { type: 'mastercard', pattern: /^2|^5/ },
  { type: 'visa', pattern: /^4/ },
];

export const getCreditCardType = (cardNumber: string): CardType | null | undefined => {
  return cardPatterns.find(({ pattern }) => cardNumber.match(pattern))?.type;
};
