import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutopayEnrolledPendingDraft' })((theme) => ({
  root: {},
  balanceAmount: theme.typography.h4,
  dateHeading: { ...theme.typography.body3, paddingBottom: 4 },
  paymentBorder: { borderRadius: 8 },
  cardContent: { padding: 15 },
  closeButton: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 'fit-content',
    },
  },
}));
