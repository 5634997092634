import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PaymentStatusDisplay' })((theme) => ({
  root: {},
  button: {
    ...theme.typography.button,
    [theme.breakpoints.down('md')]: { ...theme.typography.buttonSmall, height: 40 },
  },
  buttonEnroll: { backgroundColor: theme.palette.grey[50] },
  buttons: {
    width: '100%',
    '> *': {
      width: 'auto',
      [theme.breakpoints.down('md')]: { width: '50%' },
    },
  },
  fullWidthButton: {
    '> *': {
      width: 'auto',
      [theme.breakpoints.down('md')]: { width: '100%' },
    },
  },
  nextDueDate: theme.typography.body1Bold,
  amountDue: theme.typography.h1,
  paidDisplayText: theme.typography.body4Bold,
  dueOnDate: { ...theme.typography.body4, whiteSpace: 'nowrap', paddingRight: 16 },
  dueDate: theme.typography.body1Bold,
  overDuePayment: theme.typography.body3,
  errorIcon: {
    verticalAlign: 'text-bottom',
    fontSize: 22,
    ...theme.mixins.setColorSvg(theme.palette.error.dark),
  },
  link: {
    ...theme.typography.body3Underline,
    color: theme.palette.primary.darkText,
  },
  paymentSection: {
    minWidth: 300,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 24,
      marginRight: 16,
      marginLeft: 16,
    },
  },
  renewalDate: {
    ...theme.typography.body1Bold,
    [theme.breakpoints.down('md')]: { display: 'block' },
  },
  subtitle: theme.typography.subtitle,
  phoneSection: { textAlign: 'center' },
  autopayOn: theme.typography.body3,
  errorText: { color: theme.palette.error.dark },
  errorAlert: { alignItems: 'center' },
  escrowPaidInFull: { padding: '10px 0' },
  classicNoPayDue: { padding: '10px 0' },
  makePaymentButton: { [theme.breakpoints.down('md')]: { height: 40 } },
  autoPayButton: { [theme.breakpoints.down('md')]: { height: 40 } },
  scheduledText: theme.typography.body4Bold,
  scheduledIcon: {
    marginRight: 4,
    width: 24,
    height: 24,
  },
  autopayStatusIndicator: { paddingLeft: 3 },
  noPaymentsDueText: {
    ...theme.typography.body3Bold,
    color: theme.palette.text.disabled,
    margin: 'auto',
    textAlign: 'center',
  },
}));
