import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'EnterPasswordPage' })((theme) => ({
  root: {},
  subHeader: theme.typography.body1,
  label: theme.typography.body3,
  email: theme.typography.body4,
  navlink: {
    color: theme.palette.primary.dark,
  },
  button: {
    width: 'fit-content',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  passwordField: {
    maxWidth: 360,
  },
}));
