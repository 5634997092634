import * as yup from 'yup';

import type { YupSchemaMap } from '@ecp/utils/form';

import type { VehicleInput } from '@ecp/features/servicing/shared/types';
import { getNameSchema } from '@ecp/features/servicing/shared/util';

export interface CaMileageEditFormInputs {
  electronicSignature?: string;
  vehicles: VehicleInput[];
}

const odometerRequired = 'Current Odometer Reading field cannot be empty';
const annualMileageRequired = 'Total Annual Mileage field cannot be empty';
export const caMileageEditSchema: YupSchemaMap<CaMileageEditFormInputs> = {
  vehicles: yup.array().of(
    yup.object().shape({
      currentOdometer: yup
        .number()
        .typeError(odometerRequired) // if user types in alpha chars, the value gets set as ''
        .required(odometerRequired)
        .test({
          message: 'Mileage entered is less than last reported reading',
          test: (value = 0, context: yup.TestContext<VehicleInput>) => {
            const { existingCurrentOdometer = 0 } = context.parent as VehicleInput;

            return value >= existingCurrentOdometer;
          },
        }),

      existingCurrentOdometer: yup.number(),
      totalAnnualMileage: yup
        .number()
        .typeError(annualMileageRequired) // if user types in alpha chars, the value gets set as ''
        .required(annualMileageRequired)
        .moreThan(9, 'Invalid Total Annual Mileage'),
      vin: yup.string(),
    }),
  ),
  electronicSignature: getNameSchema({
    requiredMessage: 'Signature required to submit California Mileage Verification',
  }),
};
