import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PaymentsIFrame' })((theme) => ({
  root: {},
  iframe: {
    border: 'none',
    width: '100%',
    minHeight: 350,
  },
  spinner: { display: 'flex', justifyContent: 'center' },
}));
