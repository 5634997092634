import { useCallback, useMemo } from 'react';

import { Stack } from '@mui/material';

import { DateConstants, formatDate } from '@ecp/utils/date';
import { Form, useForm } from '@ecp/utils/form';

import { LoadingOverlay, PaymentsFormFooter } from '@ecp/features/servicing/shared/components';
import type { UseModalPathReturn as MakePaymentEditProps } from '@ecp/features/servicing/shared/routing';
import type { PolicyBillingDetails } from '@ecp/features/servicing/shared/state';
import {
  useIsAutopayEligible,
  useIsAutopayEligibleForPaymentMethod,
  useSharedState,
} from '@ecp/features/servicing/shared/state';
import type {
  AutopayInformation,
  MakePaymentInformation,
} from '@ecp/features/servicing/shared/types';
import { makePaymentSchema } from '@ecp/features/servicing/shared/util';

import { AutopayEnrolledPendingDraft } from '../AutopayEnrolledPendingDraft';
import { MakePaymentSetup } from '../MakePaymentSetup';
import { useStyles } from './MakePaymentEdit.styles';

export const MakePaymentEdit: React.FC<MakePaymentEditProps> = (props) => {
  const { goForward, sharedStateKey, policyNumber } = props;
  const { classes } = useStyles();
  const {
    isEscrow,
    isAutopayEnabled,
    isLoading: isAutopayEligibleLoading,
    policyData,
    billingAccount,
    paymentOptions,
  } = useIsAutopayEligible(policyNumber);

  const policyBeingPayed = policyData?.policy;
  const enrolledPaymentOption = paymentOptions?.find(
    (paymentOption) => paymentOption.autopayEnrolled,
  );

  const policyBillingDetailsBeingPayed: PolicyBillingDetails = {
    policyInfo: policyData,
    billingInfo: billingAccount,
  };

  const formContext = useForm({
    validations: makePaymentSchema,
  });

  const { handleSubmit } = formContext;

  const paymentDate = formatDate(new Date().toString(), DateConstants.MONTH_FULL_NAME_LONG_FORMAT);
  const dueDate = policyBillingDetailsBeingPayed.billingInfo?.dueDate;

  const [sharedState, setSharedState] = useSharedState<MakePaymentInformation>(sharedStateKey);
  const [, setSharedStateAutopay] = useSharedState<AutopayInformation>(
    `autopayEnroll-${policyNumber}`,
  );

  const selectedPaymentOption = paymentOptions?.find((option) => {
    const paymentAccount = formContext.getValues('paymentAccount');
    if (paymentAccount) {
      if (option.type === 'CC') return option.ccprimekey === paymentAccount;
      else if (option.type === 'EFT') return option.efmprimekey === paymentAccount;
      else return false;
    } else return paymentOptions?.at(0);
  });

  const selectedPaymentOptionType = selectedPaymentOption?.type;

  const onSubmit = useCallback(() => {
    if (policyBeingPayed && paymentOptions) {
      const paymentAmount = formContext.getValues('paymentAmount');
      const paymentAmountLabel =
        paymentAmount === String(billingAccount?.fullPayBalance) || !billingAccount
          ? 'Pay in full'
          : 'Amount due';
      const dueOrFull = sharedState?.dueOrFull ?? paymentAmountLabel;
      handleSubmit(async (data) => {
        const enrollAutopay = !!data.enrollAutopay;
        setSharedState({
          enrollAutopay,
          paymentAccountMethod: selectedPaymentOption,
          paymentMethodId: formContext.getValues('paymentAccount') ?? '',
          dueOrFull,
          paymentAmount: Number(paymentAmount),
          paymentDate,
          paymentDateDescription: 'Earliest date',
          isError: false,
        });
        if (enrollAutopay)
          setSharedStateAutopay({
            paymentMethodId: formContext.getValues('paymentAccount') ?? '',
            paymentAccountMethod: selectedPaymentOption,
          });
        goForward();
      })();
    }
  }, [
    billingAccount,
    formContext,
    goForward,
    handleSubmit,
    paymentDate,
    paymentOptions,
    policyBeingPayed,
    selectedPaymentOption,
    setSharedState,
    setSharedStateAutopay,
    sharedState?.dueOrFull,
  ]);

  const {
    isEligibleBillPlan,
    isLoading: isLoadingAutopayEligibleForPaymentMethod,
    isFetching: isFetchingAutopayEligible,
  } = useIsAutopayEligibleForPaymentMethod(policyNumber, selectedPaymentOptionType);

  const paymentAdded = sharedState?.isPaymentAdded;
  const showErrorAutopay = useMemo(() => {
    if (isFetchingAutopayEligible) return false;
    if (paymentAdded === undefined) return !isEligibleBillPlan;
    else return !paymentAdded && !isEligibleBillPlan;
  }, [paymentAdded, isEligibleBillPlan, isFetchingAutopayEligible]);

  if (isAutopayEligibleLoading || isLoadingAutopayEligibleForPaymentMethod)
    return <LoadingOverlay />;

  const makePaymentSetupBody = (
    <Form
      id='makePaymentForm'
      formProviderProps={formContext}
      onSubmit={onSubmit}
      className={classes.root}
    >
      <Stack spacing={3}>
        <MakePaymentSetup
          key={policyBeingPayed?.policyNumber}
          policyBillingDetailsBeingPayed={policyBillingDetailsBeingPayed}
          paymentMethod={selectedPaymentOptionType}
          isEscrow={isEscrow}
          isAutopayEnabled={isAutopayEnabled}
          {...props}
          showErrorAutopay={showErrorAutopay}
        />
        <PaymentsFormFooter
          buttonText='Review and Pay'
          trackingName='review_and_pay'
          trackingLabel='pay_continue'
        />
      </Stack>
    </Form>
  );

  const body =
    isInPaymentDraft(dueDate) &&
    enrolledPaymentOption &&
    !!billingAccount?.minimumDue &&
    billingAccount?.minimumDue > 0 ? (
      <AutopayEnrolledPendingDraft
        policyPaymentDisplayProps={{
          policyBillingDetails: policyBillingDetailsBeingPayed,
        }}
      />
    ) : (
      makePaymentSetupBody
    );

  return body;
};

const isInPaymentDraft = (dueDate?: string): boolean => {
  const todaysDate = formatDate(new Date().toString());
  const formattedDueDate = formatDate(dueDate);

  const subtractedDate = new Date(formattedDueDate ?? '');
  // subtract a day
  subtractedDate.setDate(subtractedDate.getDate() - 1);

  return todaysDate === formattedDueDate || formatDate(subtractedDate.toString()) === todaysDate;
};
