import { useEffect, useState } from 'react';

import Confetti from 'react-dom-confetti';

import { useStyles } from './ConfettiComp.styles';

interface Props {
  colors?: string[];
}
interface ConfettiConfig {
  angle: number;
  spread: number;
  startVelocity: number;
  elementCount: number;
  dragFriction: number;
  duration: number;
  stagger: number;
  perspective: number;
  colors?: string[];
}

const confettiConfig: ConfettiConfig = {
  angle: 90,
  spread: 360,
  startVelocity: 40,
  elementCount: 100,
  dragFriction: 0.22,
  duration: 3000,
  stagger: 4,
  perspective: 500,
};

export const ConfettiComp: React.FC<Props> = (props) => {
  const { colors } = props;
  const [isActiveConfetti, setIsActiveConfetti] = useState(false);
  const { classes } = useStyles();

  if (colors) confettiConfig.colors = colors;

  useEffect(() => {
    const timeout = setTimeout(() => setIsActiveConfetti(true), 1000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div data-testid='payment-success-confetti' className={classes.root}>
      <Confetti active={isActiveConfetti} config={confettiConfig} />
    </div>
  );
};
