import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'EditVinEdit' })((theme) => ({
  root: {},
  button: {
    width: 'fit-content',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  cancelButton: {
    padding: '16px 25px',
  },
  vehicleName: theme.typography.body3,
  destinationValue: theme.typography.body1Bold,
  contentCard: {
    padding: 16,
    borderRadius: 4,
    backgroundColor: theme.palette.grey[50],
  },
  formField: { maxWidth: 360 },
}));
