import { useCallback } from 'react';

import { Button } from '@ecp/components';
import { useModalPath } from '@ecp/features/servicing/shared/routing';

import { ContactUsMicro } from '../ContactUsMicro';
import { useStyles } from './CloseButtonNAgentHelp.styles';

export const CloseButtonNAgentHelp: React.FC = () => {
  const { classes } = useStyles();
  const modalPath = useModalPath();

  const handleClose = useCallback(() => {
    modalPath.reset();
  }, [modalPath]);

  return (
    <>
      <Button className={classes.closeButton} variant='primary' onClick={handleClose}>
        CLOSE
      </Button>
      <ContactUsMicro buttonVariant='outlinePrimary' />
    </>
  );
};
