import { useSearchParams } from 'react-router-dom';

import { QUERY_PARAMS } from '@ecp/features/servicing/shared/routing';

import { decodeUrlValue, useDecodedParams } from './urlEncoding';

export const usePolicyNumberFromUrl = (): string | undefined => {
  const { policyNumber: policyNumberParam } = useDecodedParams();
  const [searchParams] = useSearchParams();
  const policyNumberSearcParam = searchParams.get(QUERY_PARAMS.POLICY_NUMBER);
  const policyNumberSearchParamDecoded = policyNumberSearcParam
    ? decodeUrlValue(policyNumberSearcParam)
    : undefined;
  const policyNumber = policyNumberParam ?? policyNumberSearchParamDecoded;

  return policyNumber;
};
