import { memo } from 'react';

import { Stack } from '@mui/material';

import { useEvent } from '@ecp/utils/react';

import { Button } from '@ecp/components';
import { usePageFlow } from '@ecp/features/servicing/shared/routing';

import { HeaderNav } from './HeaderNav';
import { useStyles } from './ServicingHeaderBody.styles';
import { useMenuItems } from './useMenuItems';

export const ServicingHeaderBody: React.FC = memo((props) => {
  const { classes, cx } = useStyles();

  const { navigate } = usePageFlow();
  const { activeItem, secondaryItems } = useMenuItems();

  const handleMenuClick = useEvent((event) => {
    const url = event.currentTarget.dataset.url;
    if (!url) throw Error('cannot navigate without url');
    navigate(url);
  });

  return (
    <>
      <div className={classes.gridMenu}>
        <HeaderNav />
      </div>

      <div className={classes.gridIcons}>
        <Stack spacing={3} direction='row'>
          {secondaryItems.map((item) => (
            <Button
              key={item.url}
              variant='iconText'
              icon={item.icon}
              onClick={handleMenuClick}
              className={cx(classes.button, activeItem?.url === item.url && classes.active)}
              data-url={item.url}
              trackingName={item.trackingName}
              trackingLabel={item.trackingLabel}
              trackingDimensions={item.trackingDimensions}
              objectType={item.objectType}
            >
              {item.title}
            </Button>
          ))}
        </Stack>
      </div>
    </>
  );
});
