import { LogoPrimaryImageUrl } from '@ecp/themes/base';

import { useStyles } from './PartnerLogo.styles';

export interface PartnerLogoProps {
  opcoLogo?: string;
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
}

export const PartnerLogo: React.FC<PartnerLogoProps> = (props) => {
  const { opcoLogo } = props;
  const { classes } = useStyles(undefined, { props });

  return (
    <div className={classes.root}>
      <img
        data-testid={opcoLogo ? 'partnerLogo2' : 'partnerLogo'}
        className={classes.image}
        src={opcoLogo || LogoPrimaryImageUrl}
        alt={opcoLogo ? 'Second insurance logo' : 'Insurance logo'}
      />
    </div>
  );
};
