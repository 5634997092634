import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<{ marginTop: number; marginBottom: number }>({ name: 'Grid' })(
  (...[, { marginTop, marginBottom }]) => ({
    root: {
      marginTop,
      marginBottom,
      '&:empty': {
        display: 'none',
      },
    },
  }),
);
