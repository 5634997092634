import { Divider } from '@mui/material';

import { PartnerLogo as PartnerLogoBase } from '@ecp/components';
import { getBrandDataWithFallback } from '@ecp/features/servicing/shared/util';
import { LogoOpcoImageUrl } from '@ecp/themes/base';

import { useStyles } from './PartnerLogo.styles';

type Props = React.ComponentProps<typeof PartnerLogoBase>;

// Servicing Logo can display partner logo, opco logo or co-branded logo depending on brandtype.
export const PartnerLogo: React.FC<Props> = (props) => {
  const { ...rest } = props;
  const { classes } = useStyles(undefined, { props });
  const opcoLogo = LogoOpcoImageUrl;

  let logo;
  switch (getBrandDataWithFallback().brandingType) {
    case 'Cobranded':
      logo = (
        <div className={classes.gridLogo}>
          <PartnerLogoBase classes={{ root: classes.cobrandedLogo }} {...rest} />
          <Divider aria-hidden='true' orientation='vertical' className={classes.divider} />
          <PartnerLogoBase
            opcoLogo={opcoLogo}
            classes={{ root: classes.cobrandedLogo }}
            {...rest}
          />
        </div>
      );
      break;
    case 'Private Label':
      logo = <PartnerLogoBase opcoLogo={opcoLogo} classes={{ image: classes.image }} {...rest} />;
      break;
    case 'White Label':
      logo = <PartnerLogoBase classes={{ image: classes.image }} {...rest} />;
      break;
    default:
      logo = <PartnerLogoBase classes={{ image: classes.image }} {...rest} />;
  }

  return logo;
};
