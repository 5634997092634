import { useCallback } from 'react';

import { withAnalytics } from '@ecp/utils/analytics/tracking';

import { IconUIPhone } from '@ecp/themes/base';

import { useStyles } from './PhoneLink.styles';
import { convertPhoneCharsToNumbers } from './util';

type PhoneLinkVariant = 'small' | 'medium' | 'large' | 'extraLarge';
interface Props extends React.PropsWithRef<JSX.IntrinsicElements['a']> {
  ref?: React.RefObject<HTMLAnchorElement>;
  number: string;
  /** Whether to show a phone icon. */
  withIcon?: boolean;
  /** display in default link style? */
  withLinkStyle?: boolean;
  /** display in phone number style like link color and underlined */
  withUnderlinedLinkStyle?: boolean;
  variant?: PhoneLinkVariant;
}

export type PhoneLinkProps = React.ComponentPropsWithRef<typeof PhoneLink>;

export const PhoneLink = withAnalytics<Props>((props) => {
  const {
    className,
    number,
    onClick,
    withIcon,
    withLinkStyle,
    withUnderlinedLinkStyle,
    variant,
    ...rest
  } = props;
  const { classes, cx } = useStyles();

  // extract the phone number part of the number prop
  // only consider it a phone number if it exists and of reasonable length
  // also consider a bracketed string as a number (this is the way numbers should occur when developing)
  // split by spaces based on change for CPUI-4331
  const matchNumber = convertPhoneCharsToNumbers(number.split(' ')[0]).match(
    /[-\d()]{7,}|^\[.*\]$/,
  );
  const parsedNumber = matchNumber ? matchNumber[0] : null;
  // the parsedNumber is used for the link, the number prop is displayed as-is
  const href = parsedNumber ? `tel:${parsedNumber}` : `#`;

  const handlePhoneLinkClick: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
    (event) => {
      // If clicked on phone link and it has an href attribute with the value #
      if (event.currentTarget.getAttribute('href') === '#') {
        // Do not redirect to #
        event.preventDefault();
      }
      onClick?.(event);
    },
    [onClick],
  );

  return (
    <>
      {' '}
      <a
        {...rest}
        onClick={handlePhoneLinkClick}
        href={href}
        className={cx(
          classes.root,
          withUnderlinedLinkStyle && classes.underlinedLink,
          variant && classes[variant],
          withLinkStyle && classes.link,
          !withLinkStyle && !withUnderlinedLinkStyle && classes.noLink,
          className,
        )}
      >
        {withIcon && (
          <>
            <IconUIPhone role='img' aria-label='Phone' className={classes.icon} />
            &nbsp;
          </>
        )}
        <span>{number}</span>
      </a>
    </>
  );
}, 'link');
