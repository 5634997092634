import * as yup from 'yup';

import type { YupSchemaMap } from '@ecp/utils/form';

export interface AutopayUnenrollReviewInputs {
  consent?: string;
}

export const autopayUnenrollReviewSchema: YupSchemaMap<AutopayUnenrollReviewInputs> = {
  consent: yup.string().required('Authorization is required to unenroll from AutoPay'),
};
