import { trackClick } from '@ecp/utils/analytics/tracking';

export const trackCoverageChangeToggleClick = (coverageTitle: string, expanded: boolean): void => {
  trackClick({
    action: `${coverageTitle}_accordion`,
    label: expanded ? 'Expand' : 'Collapse',
    objectType: 'accordion',
  });
};

export const trackCoverageReadMoreLessClick = (coverageTitle: string, expanded: boolean): void =>
  trackClick({
    action: `Read${expanded ? 'Less' : 'More'}_${coverageTitle}`,
    label: expanded ? 'Less' : 'More',
    objectType: 'link',
  });

export const trackViewPIPClick = (coverageTitle: string): void =>
  trackClick({ action: `View_${coverageTitle}_PIP`, label: 'ViewPIP' });
