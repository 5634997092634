import * as yup from 'yup';

export interface PasswordRequirements {
  minLength?: number;
  maxLength?: number;
}

export const getPasswordSchema = (requirements: PasswordRequirements = {}): yup.AnySchema => {
  const { minLength, maxLength } = requirements;

  return yup
    .string()
    .required()
    .concat(getPasswordMinimumLengthSchema(minLength))
    .concat(getPasswordMaximumLengthSchema(maxLength))
    .concat(getPasswordLowercaseSchema())
    .concat(getPasswordUppercaseSchema())
    .concat(getPasswordNumberSchema())
    .concat(getPasswordSpecialCharacterSchema());
};

export const getPasswordMinimumLengthSchema = (minLength = 8): yup.StringSchema =>
  yup.string().min(minLength);

export const getPasswordMaximumLengthSchema = (maxLength = 16): yup.StringSchema =>
  yup.string().max(maxLength);

export const getPasswordLowercaseSchema = (lowercaseCount = 1): yup.StringSchema =>
  yup.string().test('lowercase', (value) => {
    const stringValue = value ? String(value) : '';

    return (stringValue.match(/[a-z]/g) || []).length >= lowercaseCount;
  });

export const getPasswordUppercaseSchema = (uppercaseCount = 1): yup.StringSchema =>
  yup.string().test('uppercase', (value) => {
    const stringValue = value ? String(value) : '';

    return (stringValue.match(/[A-Z]/g) || []).length >= uppercaseCount;
  });

export const getPasswordNumberSchema = (numberCount = 1): yup.StringSchema =>
  yup.string().test('numbers', (value) => {
    const stringValue = value ? String(value) : '';

    return (stringValue.match(/\d/g) || []).length >= numberCount;
  });

export const getPasswordSpecialCharacterSchema = (specialCharactersCount = 1): yup.StringSchema =>
  yup.string().test('special-characters', (value) => {
    const stringValue = value ? String(value) : '';

    return (
      (stringValue.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g) || []).length >=
      specialCharactersCount
    );
  });
