import { CircularProgress } from '@mui/material';

import { Card, PaymentStatusCardBody } from '@ecp/features/servicing/shared/components';
import {
  hasAnotherActivePolicy,
  usePaymentInfo,
  usePolicy,
  useUserPolicies,
} from '@ecp/features/servicing/shared/state';

import { useStyles } from './PaymentsSection.styles';

interface Props {
  policyNumber: string;
}

export const PaymentsSection: React.FC<Props> = (props) => {
  const { policyNumber } = props;
  const { classes } = useStyles();
  const { policies } = useUserPolicies({ throwOnPolicyError: false });

  const { policyData: policyInfo } = usePolicy(policyNumber);

  const { paymentInfo, isLoading } = usePaymentInfo({ policyNumber });

  if (!policyInfo || isLoading) {
    return <CircularProgress aria-label='Loading...' />;
  }

  return (
    <Card
      className={classes.root}
      body={
        <PaymentStatusCardBody
          hasAnotherActivePolicy={hasAnotherActivePolicy(policyInfo, policies)}
          paymentInfo={paymentInfo}
        />
      }
    />
  );
};
