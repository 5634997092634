import { Stack } from '@mui/material';

import { userAuth } from '@ecp/utils/auth';

import type { BreadcrumbTitleOverrides } from '@ecp/features/servicing/shared/components';
import { Breadcrumbs, DashboardCard } from '@ecp/features/servicing/shared/components';

interface Props {
  title?: string;
  subtitle?: string | React.ReactNode;
  breadcrumbTitleOverrides?: BreadcrumbTitleOverrides;
}

export const DisclosureWrapper: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { title, subtitle, breadcrumbTitleOverrides, children } = props;

  return (
    <DashboardCard>
      <Stack spacing={{ xs: 7, md: 5 }}>
        <Stack spacing={1}>
          {userAuth.isAuth && <Breadcrumbs breadcrumbTitleOverrides={breadcrumbTitleOverrides} />}
          <Stack spacing={2}>
            {title && <h1>{title}</h1>}
            {subtitle && <p>{subtitle}</p>}
          </Stack>
        </Stack>
        {children}
      </Stack>
    </DashboardCard>
  );
};
