import { useCallback } from 'react';

import { Backdrop } from '@mui/material';
import type { FieldValues, UseFormReturn } from 'react-hook-form';
import { FormProvider } from 'react-hook-form';

import type { ValidationContext } from '../../util';
import { useStyles } from './Form.styles';

interface FormProps<TFieldValues extends FieldValues>
  extends React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> {
  showBackdrop?: boolean;
  component?: React.ElementType;
  formProviderProps?: UseFormReturn<TFieldValues, ValidationContext<FieldValues>, TFieldValues>;
}

export const Form = <TFieldValues extends FieldValues = FieldValues>(
  props: FormProps<TFieldValues>,
): JSX.Element => {
  const {
    children,
    className,
    onSubmit,
    showBackdrop,
    // lowecase `component` will trigger react issue assuming it to be native/intrinsic
    // eslint-disable-next-line @typescript-eslint/naming-convention
    component: Component = 'form',
    formProviderProps,
    ...rest
  } = props;
  const { classes, cx } = useStyles();

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (onSubmit) {
        onSubmit(e);
      }
    },
    [onSubmit],
  );

  const form = (
    <Component {...rest} onSubmit={handleSubmit} noValidate className={cx(classes.root, className)}>
      <Backdrop
        open={showBackdrop ?? !!formProviderProps?.formState.isSubmitting}
        invisible
        className={classes.backdrop}
      />
      {children}
    </Component>
  );

  if (!formProviderProps) return form;

  return <FormProvider {...formProviderProps}>{form}</FormProvider>;
};
