import type { UiPolicy } from '@ecp/features/servicing/shared/types';

import type { BillingResponse } from '../billing';
import { getProductLineFromPolicyResponse } from './getProductLineFromPolicyResponse';
import { isLegacyPolicy } from './isLegacyPolicy';

export const getClassicBillingAccountStatusInPendingCancel = (
  policyData: UiPolicy | undefined,
  billingInfo: BillingResponse | undefined,
): boolean => {
  if (!policyData || !billingInfo) return false;

  return (
    isLegacyPolicy(
      getProductLineFromPolicyResponse(policyData),
      policyData.policy.sourceSystemName,
    ) &&
    billingInfo?.billingAccountStatus === 'pending cancel' &&
    !policyData?.isInactive &&
    !!policyData?.policy.pendingNonPayCancelInd
  );
};
