import { brand } from '@ecp/features/servicing/shared/brand-data';
import { LegalCopy as LegalCopyWrapper } from '@ecp/features/servicing/shared/components';
interface Props {
  isAuto?: boolean;
  isClassic?: boolean;
}
export const LegalCopy: React.FC<Props> = (props) => {
  const { isAuto = false, isClassic = false } = props;

  if (!isClassic) {
    return (
      <LegalCopyWrapper>
        I authorize Homesite Group Incorporated (Homesite) and its subsidiaries to automatically
        debit future payments from my bank account or the credit card indicated above for the
        purpose of paying insurance premiums and related charges for this policy. I understand that
        payment amounts, due dates and any applicable fees will be indicated on my billing
        statement. This Authorization shall apply to any renewal, reinstated, or amended policy with
        Homesite unless revoked by me. The charge will appear on my bank account or credit card
        statement as "Homeowners Insurance." I understand that Homesite has the right to discontinue
        my enrollment at any time. Refunds or credits, if any, may be made directly to my account or
        card. Changes to my policy or premium may change the amount debited. I understand it is my
        responsibility to notify Homesite if I change financial institutions. I can cancel my
        payment deductions by calling Homesite at {brand.home?.mainCustServPhoneNum}. To unenroll
        from autopay, go to "Manage AutoPay" for this policy and select "Unenroll from AutoPay".
      </LegalCopyWrapper>
    );
  } else {
    return isAuto ? (
      <LegalCopyWrapper>
        By selecting credit or debit card billing, you authorize American Family Connect Property
        and Casualty Insurance Company to bill your credit or debit card account for the auto
        insurance premiums at the frequency selected for your payment option. Payments will be
        automatically billed to your credit or debit card account and this arrangement will remain
        in effect until you notify American Family Connect Property and Casualty Insurance Company.
        In the event of an unauthorized transfer to American Family Connect Property and Casualty
        Insurance Company, or if you desire to cancel this agreement at any time, please contact our
        office.
      </LegalCopyWrapper>
    ) : (
      <LegalCopyWrapper>
        By selecting credit or debit card billing, you authorize American Family Connect Property
        and Casualty Insurance Company to bill your credit or debit card account for the home
        insurance premiums at the frequency selected for your payment option. Payments will be
        automatically billed to your credit or debit card account and this arrangement will remain
        in effect until you notify American Family Connect Property and Casualty Insurance Company.
        In the event of an unauthorized transfer to American Family Connect Property and Casualty
        Insurance Company, or if you desire to cancel this agreement at any time, please contact our
        office.
      </LegalCopyWrapper>
    );
  }
};
