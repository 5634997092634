import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import type { UiPolicy } from '@ecp/features/servicing/shared/types';

import type { BillingResponse } from '../billing';
import type { GetPaymentOptionsSuccess } from '../payment';
import { getProductLineFromPolicyResponse } from './getProductLineFromPolicyResponse';
import { isLegacyPolicy } from './isLegacyPolicy';

// Util method to map conditions for paymentOptions and billingInfo and
// check if payment enabled for specific policy.
export const isPaymentOn = (
  policyResponse: UiPolicy | undefined,
  billingInfo: BillingResponse | undefined,
  paymentOptions: GetPaymentOptionsSuccess | undefined,
): boolean => {
  const paymentsClassicFlag = flagValues[FeatureFlags.PAYMENTS_CLASSIC];
  const isClassicPolicy = isLegacyPolicy(
    getProductLineFromPolicyResponse(policyResponse),
    policyResponse?.policy.sourceSystemName,
  );
  if (!policyResponse || !billingInfo || (!isClassicPolicy && !paymentOptions)) return false;
  const { minimumDue, fullPayBalance, billingAccountStatus } = billingInfo;

  if (isClassicPolicy && minimumDue === undefined && billingAccountStatus === 'pending cancel')
    return false;
  else {
    let isMakePaymentOn = true;
    // Policies with No Amount Due

    if (
      (minimumDue !== undefined && minimumDue < 0) ||
      (fullPayBalance !== undefined && fullPayBalance < 0)
    ) {
      isMakePaymentOn = false;
    } else if (minimumDue === 0) {
      isMakePaymentOn = false;
      if (fullPayBalance !== undefined && fullPayBalance > 0) isMakePaymentOn = true;
    }
    // Canceled policies
    if (policyResponse?.isInactive) {
      isMakePaymentOn = false;
    }

    // Legacy policies
    if (!paymentsClassicFlag && isClassicPolicy) {
      isMakePaymentOn = false;
    }

    return isMakePaymentOn;
  }
};
