import type { CardType } from '@ecp/utils/payment';

import type { ServicingCardType } from '@ecp/features/servicing/shared/types';

import type { PayeezyCardType } from './types';

export const PAYMENT_TYPE = {
  CC: 'Credit/Debit card',
  EFT: 'Bank account',
} as const;

export const CARD_TYPE: Record<CardType, ServicingCardType> = {
  discover: 'Discover',
  mastercard: 'Mastercard',
  amex: 'Amex',
  visa: 'Visa',
} as const;

export const PAYEEZY_CARD_TYPE: Record<CardType, PayeezyCardType> = {
  discover: 'Discover',
  mastercard: 'Mastercard',
  amex: 'American Express',
  visa: 'Visa',
} as const;
