import { Divider, Stack } from '@mui/material';

import { parseDollar } from '@ecp/utils/common';
import { DateConstants, formatDate } from '@ecp/utils/date';
import { flagValues } from '@ecp/utils/flags';

import { Alert, PhoneLink } from '@ecp/components';
import {
  AutopayStatusIndicator,
  ContactUsLink,
  PaymentDue,
} from '@ecp/features/servicing/shared/components';
import {
  getItemFromLoggedInSessionStorage,
  getPaymentStatus,
  getProductLineFromPolicyResponse,
  isInPendingCancelStatus,
} from '@ecp/features/servicing/shared/state';
import type { GetPaymentInfoReturn } from '@ecp/features/servicing/shared/state';
import { getBrandData } from '@ecp/features/servicing/shared/util';
import { IconMaterialDesignActionUpdate } from '@ecp/themes/base';
import { IconUICheckCircleSolid } from '@ecp/themes/base';

import { useStyles } from './PaymentStatusDisplay.styles';
import { useInfoFromPaymentOptions } from './useInfoFromPaymentOptions';

export interface PaymentStatusDisplayProps {
  hasAnotherActivePolicy?: boolean;
  paymentInfo: GetPaymentInfoReturn;
}

const checkIcon = <IconUICheckCircleSolid viewBox='4 0 24 24' />;

export const PaymentStatusDisplay: React.FC<PaymentStatusDisplayProps> = (
  props: PaymentStatusDisplayProps,
) => {
  const { hasAnotherActivePolicy = true, paymentInfo } = props;

  const {
    isClassicPolicy,
    policyData,
    paymentOptions,
    billingAccount: billingInfo,
    isAutopayEnabled,
    policyNumber = '',
    isClassicBillingAccountStatusInPendingCancel,
  } = paymentInfo;

  const { classes, cx } = useStyles();

  const isInactive = policyData?.isInactive;
  const { isPaymentOptionsTypeUnknown } = useInfoFromPaymentOptions(paymentOptions);
  const isEscrow = paymentInfo.isEscrow;
  const paymentSubmitted = getItemFromLoggedInSessionStorage('payment', policyNumber);
  const isPaymentSubmitted = !!paymentSubmitted;
  const isPendingCancellation = isInPendingCancelStatus({
    billingResponse: billingInfo,
    productLine: getProductLineFromPolicyResponse(policyData),
    policySource: policyData?.policy.sourceSystemName,
    policyData,
  });
  const paymentStatus = getPaymentStatus(billingInfo, isPendingCancellation, isPaymentSubmitted);
  const paymentSubmittedContent = (
    <Stack direction='row'>
      <div className={classes.scheduledIcon}>
        <IconMaterialDesignActionUpdate />
      </div>
      <div className={classes.scheduledText}>
        Payment of {parseDollar(paymentSubmitted)} scheduled
      </div>
    </Stack>
  );

  const paidInFull = (
    <Stack direction='row' spacing={1.5} alignItems='center'>
      <Stack direction='row'>
        {checkIcon}
        <div className={classes.paidDisplayText}>Paid in full</div>
      </Stack>
      <Divider orientation='vertical' flexItem />
      <span>
        Renewal date{' '}
        <span className={classes.renewalDate}>
          {formatDate(policyData?.policy.periodEndDate, DateConstants.MONTH_FULL_NAME_LONG_FORMAT)}
        </span>
      </span>
    </Stack>
  );

  const escrowPaidInFull = (
    <Stack direction='row' spacing={1.5} alignItems='center' className={classes.escrowPaidInFull}>
      <Stack direction='row'>
        {checkIcon}
        <div className={classes.paidDisplayText}>Paid in full</div>
      </Stack>
      <Divider orientation='vertical' flexItem />
      <p>Billed to mortgage company</p>
    </Stack>
  );

  const noPaymentDue = (
    <Stack direction='row' alignItems='center' className={classes.classicNoPayDue}>
      {checkIcon}
      <div className={classes.paidDisplayText}>Paid</div>
    </Stack>
  );

  const canceled = (
    <Stack spacing={2}>
      This policy is inactive. <br />
      {!hasAnotherActivePolicy && (
        <>
          Call to get a quote today and enjoy peace of mind knowing that you and your assets are
          protected by the best in the business.
          <div className={classes.phoneSection}>
            {getBrandData(paymentInfo.policyData)?.salesPhoneNum}
          </div>
        </>
      )}
    </Stack>
  );
  const noPaymentsDueBody = (
    <div className={classes.noPaymentsDueText}>No payments due at this time</div>
  );

  let paymentSectionContent;
  let paymentLineOne;

  switch (paymentStatus) {
    case 'Due':
    case 'PendingCancellation':
    case 'PastDue':
      paymentLineOne = isClassicBillingAccountStatusInPendingCancel ? (
        ''
      ) : (
        <PaymentDue
          billingAccount={paymentInfo.billingAccount}
          isAutopayEnabled={paymentInfo.isAutopayEnabled}
          isEscrow={isEscrow}
        />
      );
      break;
    case 'NoPaymentDue':
      paymentLineOne = isEscrow ? escrowPaidInFull : noPaymentDue;
      break;
    case 'PaidInFull':
      paymentLineOne = isEscrow ? escrowPaidInFull : paidInFull;
      break;
  }

  paymentSectionContent = (
    <Stack spacing={2}>
      <Stack spacing={1}>
        {isPaymentSubmitted ? paymentSubmittedContent : paymentLineOne}
        {(!isClassicPolicy || flagValues.CLASSIC_AUTOPAY_INDICATOR) && !isEscrow && (
          <div className={cx(isPaymentSubmitted && classes.autopayStatusIndicator)}>
            <AutopayStatusIndicator isAutoPayOn={isAutopayEnabled} />
          </div>
        )}
      </Stack>
    </Stack>
  );
  const helpNumber = getBrandData(paymentInfo.policyData).mainCustServPhoneNum;

  if (isInactive) {
    paymentSectionContent = canceled;
  } else if (paymentStatus === 'NegativePaymentBalance') {
    paymentSectionContent = noPaymentsDueBody;
  } else if (
    paymentInfo.isClassicPolicy &&
    paymentInfo.isClassicBillingAccountStatusInPendingCancel
  ) {
    return (
      <Alert withIcon type='warning' className={classes.errorAlert}>
        <b>Payment past due.</b> Policy will be canceled if payment is not received. Please call{' '}
        <PhoneLink number={helpNumber} /> to arrange payment.
      </Alert>
    );
  } else {
    if (!paymentStatus || isPaymentOptionsTypeUnknown)
      return (
        <Alert withIcon type='error' className={classes.errorAlert}>
          We're sorry, we're unable to display your payment information. Please <ContactUsLink />{' '}
          for assistance.
        </Alert>
      );
  }

  return (
    <Stack className={classes.root} spacing={2}>
      <div>{paymentSectionContent}</div>
    </Stack>
  );
};
