import type { ChangeEventHandler, FocusEventHandler } from 'react';
import { useCallback, useState } from 'react';

import { Stack } from '@mui/material';
import type { FieldValues } from 'react-hook-form';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import {
  isMasked,
  maskPaymentAccountNumber,
  resetMaskedPaymentAccountNumber,
} from '@ecp/utils/common';
import { useField } from '@ecp/utils/form';
import { restrictSpaceChars } from '@ecp/utils/web';

import { Select, TextField } from '@ecp/components';
import {
  GraphicSampleCheckAccountNumberImageUrl,
  GraphicSampleCheckRoutingNumberImageUrl,
  IconUISecure,
} from '@ecp/themes/base';

import { LabelWithTooltip } from '../LabelWithTooltip';
import { useStyles } from './AccountPaymentMethodFields.styles';

const accountTypeOptions = [
  { label: 'Choose account', value: '' },
  {
    label: 'Checking',
    value: 'checking',
  },
  {
    label: 'Savings',
    value: 'savings',
  },
  {
    label: 'Business Account',
    value: 'businessAccount',
  },
];

export const AccountPaymentMethodFields: React.FC = () => {
  const { classes } = useStyles();

  const routingNumberField = useField({ name: 'routingNumber' });
  const accountNumberField = useField({
    name: 'accountNumber',
  });
  const accountTypeField = useField<FieldValues, string>({
    name: 'accountType',
    defaultValue: '',
  });
  const verifyAccountNumberField = useField({
    name: 'verifyAccountNumber',
  });

  const accountNumberValue = accountNumberField.value;
  const [accountDisplayValue, setAccountDisplayValue] = useState<string | undefined>(
    accountNumberValue ? maskPaymentAccountNumber(accountNumberValue) : undefined,
  );
  const verifyAccountNumberValue = verifyAccountNumberField.value;
  const [verifyAccountDisplayValue, setVerifyAccountDisplayValue] = useState<string | undefined>(
    verifyAccountNumberValue ? maskPaymentAccountNumber(verifyAccountNumberValue) : undefined,
  );

  const handleBlurAccount = useCallback<
    NonNullable<FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>>
  >((e) => {
    setAccountDisplayValue(maskPaymentAccountNumber(e.target.value));
  }, []);
  const handleBlurVerifyAccount = useCallback<
    NonNullable<FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>>
  >((e) => {
    setVerifyAccountDisplayValue(maskPaymentAccountNumber(e.target.value));
  }, []);

  const handleAccountChange = useCallback<
    NonNullable<ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>>
  >(
    (e) => {
      let valueTrimmed = e.target.value.trim();
      if (isMasked(valueTrimmed)) {
        if (accountDisplayValue && valueTrimmed.length === accountDisplayValue?.length) return; // user clicks into field and leaves
        valueTrimmed = resetMaskedPaymentAccountNumber(valueTrimmed, accountDisplayValue);
      }
      setAccountDisplayValue(undefined);
      setAccountDisplayValue(valueTrimmed);
    },
    [accountDisplayValue],
  );
  const handleVerifyAccountChange = useCallback<
    NonNullable<ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>>
  >(
    (e) => {
      let valueTrimmed = e.target.value.trim();
      if (isMasked(valueTrimmed)) {
        if (verifyAccountDisplayValue && valueTrimmed.length === verifyAccountDisplayValue?.length)
          return; // user clicks into field and leaves
        valueTrimmed = resetMaskedPaymentAccountNumber(valueTrimmed, verifyAccountDisplayValue);
      }
      setVerifyAccountDisplayValue(undefined);
      setVerifyAccountDisplayValue(valueTrimmed);
    },
    [verifyAccountDisplayValue],
  );

  return (
    <Stack className={classes.root} spacing={2}>
      <Stack direction='row' className={classes.groupLabelStack} spacing={1}>
        <IconUISecure />
        <h2>Add a bank account</h2>
      </Stack>
      <Stack spacing={3}>
        <Select
          {...accountTypeField}
          id={accountTypeField.name}
          label='Account type'
          disabled={!accountTypeOptions?.length}
          options={accountTypeOptions}
          fullWidth
          variant='outlined'
          trackingName='account_type_selection'
        />
        <TextField
          {...routingNumberField}
          placeholder='Enter 9-digit routing number'
          className={classes.label}
          trackingName='bank_routing_number'
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
          groupLabel={
            <LabelWithTooltip
              label='Routing number'
              iconUrl={GraphicSampleCheckRoutingNumberImageUrl}
              altText='Routing number'
              trackingName='bank_routing_number_hover'
              tooltipText='This is the 9-digit code on the bottom left of your check.'
            />
          }
          maxLength={9}
          onKeyDown={restrictSpaceChars}
          autoComplete='off'
        />
        <TextField
          {...accountNumberField}
          placeholder='Enter account number'
          trackingName='account_number'
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
          groupLabel={
            <LabelWithTooltip
              label='Account number'
              iconUrl={GraphicSampleCheckAccountNumberImageUrl}
              altText='Account number'
              trackingName='account_number_hover'
              tooltipText='This is the 10-digit code on the bottom center of your check.'
            />
          }
          className={classes.label}
          onBlur={handleBlurAccount}
          value={accountDisplayValue ?? accountNumberField.value}
          onChange={handleAccountChange}
          maxLength={17}
          onKeyDown={restrictSpaceChars}
          autoComplete='off'
        />
        <TextField
          {...verifyAccountNumberField}
          placeholder='Verify account number'
          label='Retype account number'
          trackingName='verify_account_number'
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
          onBlur={handleBlurVerifyAccount}
          value={verifyAccountDisplayValue ?? verifyAccountNumberField.value}
          onChange={handleVerifyAccountChange}
          maxLength={17}
          onKeyDown={restrictSpaceChars}
          autoComplete='off'
        />
      </Stack>
    </Stack>
  );
};
