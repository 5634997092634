import { memo, useCallback } from 'react';

import { Grid, Stack } from '@mui/material';

import type { ButtonProps } from '@ecp/components';
import { Button, HelpBox } from '@ecp/components';
import { GraphicUIAgentAvatarImageUrl } from '@ecp/themes/base';

import { useStyles } from './ButtonCards.styles';

export interface ButtonCardProps extends Omit<ButtonProps, 'variant'> {
  key: string;
  title: string;
  icon: React.ReactElement;
  displayText: React.ReactElement | string;
}
export interface Props {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  options: ButtonCardProps[];
  onButtonClick?(newValue: string): void;
  value?: string;
}

export const ButtonCards: React.FC<Props> = memo((props) => {
  const { options, onButtonClick, value } = props;
  const { classes, cx } = useStyles(undefined, { props });
  const handleButtonClick = useCallback<NonNullable<ButtonProps['onClick']>>(
    (event) => {
      onButtonClick?.(event?.currentTarget.id);
    },
    [onButtonClick],
  );

  const buttonCards = options.map((option: ButtonCardProps) => {
    const isSelected: boolean = option.key === value;
    const colSize = 12 / options.length;
    let xsSize = 4;
    if (colSize >= 4) {
      xsSize = colSize;
    }

    return (
      <Grid item key={option.key} xs={xsSize} md={colSize}>
        <Button
          id={option.key}
          variant={isSelected ? 'primary' : 'outlinePrimary'}
          onClick={handleButtonClick}
          className={cx(classes.button, isSelected && classes.checked, classes.button)}
          title={option.title}
        >
          <Stack direction='column'>
            <div className={classes.svg}>{option.icon}</div>
            <p className={cx(classes.buttonLabel, isSelected && classes.buttonText)}>
              {option.title}
            </p>
          </Stack>
        </Button>
        {isSelected && (
          <div className={classes.helpBoxContainer}>
            <HelpBox
              classes={{ root: classes.productDesc }}
              image={GraphicUIAgentAvatarImageUrl}
              content={option?.displayText}
            />
          </div>
        )}
      </Grid>
    );
  });

  return (
    <Stack direction='row' className={classes.buttonGrid} spacing={1}>
      {buttonCards}
    </Stack>
  );
});
