import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ReviewPolicyDetails' })((theme) => ({
  root: {},

  itemSection: {
    marginLeft: 16,
    width: '100%',
  },
  subText: {
    ...theme.typography.body2,
    color: theme.palette.text.tertiary,
  },
  value: theme.typography.body3Bold,
  title: {
    ...theme.typography.body3,
    color: theme.palette.text.tertiary,
  },
}));
