import { useCallback, useEffect, useMemo, useState } from 'react';

import { Stack } from '@mui/material';

import { parseDollar } from '@ecp/utils/common';
import { DateConstants, formatDate } from '@ecp/utils/date';
import { Form, useForm } from '@ecp/utils/form';

import { Alert, Button } from '@ecp/components';
import { env } from '@ecp/env';
import {
  ElectronicSignatureField,
  LoadingButton,
  LoadingOverlay,
  ModalError,
  ReviewPolicyDetails,
} from '@ecp/features/servicing/shared/components';
import type { UseModalPathReturn as AutopayReviewProps } from '@ecp/features/servicing/shared/routing';
import type { BillingResponse } from '@ecp/features/servicing/shared/state';
import {
  getBillingPlanDescription,
  getPolicyDisplayType,
  getProductLineFromPolicyResponse,
  isLegacyPolicy,
  useAmountDueNDueDateInfo,
  useAutopayEnroll,
  useBillingDetails,
  usePaymentOptions,
  usePolicy,
  useSharedState,
  useUser,
} from '@ecp/features/servicing/shared/state';
import type {
  AutopayInformation,
  MakePaymentInformation,
} from '@ecp/features/servicing/shared/types';

import { useStyles } from './AutopayReview.styles';
import { autopayReviewSchema } from './AutopayReviewInputs.schema';
import { LegalCopy } from './LegalCopy';

export const AutopayReview: React.FC<AutopayReviewProps> = (props) => {
  const { currentFlow, currentStep, goForward, goBack, policyNumber, sharedStateKey } = props;
  const { classes } = useStyles();
  const [submitError, setSubmitError] = useState<unknown>();
  const isAutopayManage = currentFlow === 'autopaymanage';
  const isAutopayEnroll = currentFlow === 'autopayenroll';
  const isEnrollReview = currentStep === 'enrollreview';

  const [sharedState, setSharedState] = useSharedState<AutopayInformation>(
    currentFlow === 'pay' ? `autopayEnroll-${policyNumber}` : sharedStateKey,
  );
  const [sharedStatePay] = useSharedState<MakePaymentInformation>(`paymentInfo-${policyNumber}`);

  const { policyData: policyResponse } = usePolicy(policyNumber);
  const policyType = getPolicyDisplayType(policyResponse);
  const { user, isLoading: isUserLoading } = useUser();
  const billingAccountNumber = policyResponse?.policy.billingAccountNumber;
  const {
    accounts,
    isLoading: isBillingLoading,
    refetch: refetchBilling,
    isFetching: isFetchingBilling,
  } = useBillingDetails({
    billingAccountIds: billingAccountNumber ? [billingAccountNumber] : undefined,
  });
  const accountBeingEnrolled: BillingResponse | undefined = accounts.find(
    (account) => account.billingAccountId === billingAccountNumber,
  );

  useEffect(() => {
    if (isAutopayEnroll || isEnrollReview) refetchBilling(accountBeingEnrolled?.billingAccountId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const paymentMethodValue =
    sharedState?.paymentAccountMethod?.type === 'CC'
      ? `${sharedState?.paymentAccountMethod?.cardType}`
      : 'Bank account';

  const paymentMethodHelperText = useMemo(() => {
    if (sharedState?.paymentAccountMethod?.type === 'CC')
      return `(...${sharedState?.paymentAccountMethod?.ccToken})`;
    else if (sharedState?.paymentAccountMethod?.type === 'EFT')
      return `(...${sharedState?.paymentAccountMethod?.accountNumber})`;
    else return '';
  }, [sharedState?.paymentAccountMethod]);

  const formContext = useForm({
    validations: autopayReviewSchema,
  });
  const { handleSubmit } = formContext;

  const productLine = getProductLineFromPolicyResponse(policyResponse);
  const policySource = policyResponse?.policy.sourceSystemName;
  const { clearCache, refetch: refetchPaymentOptions } = usePaymentOptions(
    {
      billingAccountNumber: policyResponse?.policy.billingAccountNumber,
      policySource: policyResponse?.policy.sourceSystemName,
    },
    isLegacyPolicy(productLine, policySource),
  );

  let flow = 'Enroll in AutoPay';
  switch (currentFlow) {
    case 'autopaymanage':
      flow = 'Manage AutoPay';
      break;
  }
  const step =
    (currentStep === 'review' || currentStep === 'enrollreview') && 'Review AutoPay Details';

  const reviewPageLoadTime = useMemo(() => new Date().toISOString(), []);

  const { autoPay } = useAutopayEnroll();
  const onSubmit = useCallback(() => {
    if (!user) throw new Error('User is undefined');
    handleSubmit(async (data) => {
      const todaysDateAndTime = new Date();
      const consumerStep = `${flow} - ${step}`;
      if (!data.electronicSignature) throw Error('Electronic signature is undefined');
      try {
        const response = await autoPay({
          primeKey: sharedState?.paymentMethodId,
          billingAccount: policyResponse?.policy.billingAccountNumber,
          policySource: policyResponse?.policy.sourceSystemName,
          paymentMethodType: sharedState?.paymentAccountMethod?.type,
          autopayEnrolled: true,
          paymentAmount: accountBeingEnrolled?.minimumDue,
          auditInfo: {
            requestedDateTime: reviewPageLoadTime,
            requestedBy: user.email,
            requestingSystem: env.static.sourceId,
            consumerStep: consumerStep,
            acknowledgement: data.electronicSignature,
            whenAccepted: formatDate(todaysDateAndTime.toString(), DateConstants.ANSWERS_FORMAT),
          },
        });
        if (response.success) {
          setSharedState({
            ...(sharedState as AutopayInformation),
            paymentAmountForEnrollmentConfirmation: response.success?.paymentAmount,
            nextPaymentDate: response.success?.nextPaymentDate,
            autopayEnrolled: response.success?.autopayEnrolled.toLowerCase(),
          });
          if (isAutopayManage) {
            setSharedState({
              ...(sharedState as AutopayInformation),
              isSuccess: true,
            });
          }
          clearCache();
          await refetchPaymentOptions();
          goForward();
        } else if (response.error) {
          throw new Error('Failed to update autopay');
        }
      } catch (error) {
        setSharedState({ ...(sharedState as AutopayInformation), isError: true });
        if (currentFlow === 'autopayenroll') {
          setSubmitError(error);
        } else if (isAutopayManage || currentFlow === 'pay') {
          goForward();
        } else {
          goBack();
        }
      }
    })();
  }, [
    clearCache,
    goForward,
    sharedState,
    setSharedState,
    goBack,
    autoPay,
    user,
    policyResponse?.policy.sourceSystemName,
    refetchPaymentOptions,
    handleSubmit,
    flow,
    step,
    reviewPageLoadTime,
    policyResponse?.policy.billingAccountNumber,
    currentFlow,
    accountBeingEnrolled?.minimumDue,
    isAutopayManage,
  ]);

  const {
    paymentAmount,
    paymentAmountDescription,
    isLoading: isLoadingAmountDueNDueDateInfo,
    isFetching: isFetchingAmountDueNDueDateInfo,
  } = useAmountDueNDueDateInfo({
    policyNumber,
    modalFlow: currentFlow,
    modalStep: currentStep,
  });
  const displayAmount = paymentAmount ? parseDollar(paymentAmount) : paymentAmountDescription;

  const isLoading =
    isFetchingAmountDueNDueDateInfo ||
    isFetchingBilling ||
    isBillingLoading ||
    isLoadingAmountDueNDueDateInfo ||
    isUserLoading;

  if (isLoading) return <LoadingOverlay />;
  if (submitError) {
    const errorDesc = (
      <span>
        enrolling your{' '}
        <b>
          {policyType} policy {policyNumber}
        </b>{' '}
        in AutoPay
      </span>
    );

    return <ModalError errorTitle='enroll your policy in AutoPay' errorDescription={errorDesc} />;
  }

  return (
    <Stack spacing={3}>
      {sharedStatePay?.isSuccess && (
        <h2>Your payment was processed successfully. Next, let's review your AutoPay details</h2>
      )}
      <Form onSubmit={onSubmit} formProviderProps={formContext} name='updateEnrollAutopay'>
        <Stack spacing={3} className={classes.root}>
          {!sharedStatePay?.isSuccess && (
            <span className={classes.title}>Review AutoPay details</span>
          )}
          <Stack className={classes.payInfoSection} spacing={2}>
            <ReviewPolicyDetails
              name='Policy'
              helperText={policyNumber}
              value={getPolicyDisplayType(policyResponse) ?? ''}
            />
            <ReviewPolicyDetails
              name='Payment frequency'
              helperText={isAutopayManage ? '' : 'Starting next bill cycle'}
              value={getBillingPlanDescription(accountBeingEnrolled?.billingPlan) ?? ''}
            />
            <ReviewPolicyDetails
              name='Payment method'
              helperText={paymentMethodHelperText}
              value={paymentMethodValue}
            />
            <ReviewPolicyDetails
              name='Payment amount'
              helperText={isAutopayManage ? 'Amount per payment' : 'Estimated payment'}
              value={displayAmount}
            />
          </Stack>
          {!isAutopayManage && (
            <Alert withIcon type='info'>
              <p>
                The date and amount of the first withdrawal will be calculated and displayed on the
                next screen.
              </p>
            </Alert>
          )}
          <LegalCopy />
          <Stack spacing={1.5}>
            <span className={classes.signText}>Sign below to enroll in AutoPay</span>
            <ElectronicSignatureField />
          </Stack>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            className={classes.buttonGroup}
            spacing={3}
          >
            <LoadingButton
              type='submit'
              trackingName='enroll_autopay_button'
              trackingLabel='enroll_review_continue'
              className={classes.submitButtton}
            >
              {isAutopayManage ? 'SAVE' : 'Enroll and finish'}
            </LoadingButton>
            {!isEnrollReview && (
              <Button variant='iconTextMedium' onClick={goBack} className={classes.backButton}>
                Back
              </Button>
            )}
          </Stack>
        </Stack>
      </Form>
    </Stack>
  );
};
