import { Fragment, memo } from 'react';

import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import type { TableProps } from '@ecp/types';

import { useStyles } from './Table.styles';

interface Props extends TableProps {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
}

export const Table: React.FC<Props> = memo((props) => {
  const { tableHeaderText, tableFooterText, tableDataHeaders, tableData } = props;

  const { classes } = useStyles(undefined, { props });

  const tableContainsSubHeaders = tableDataHeaders?.some((header) => Array.isArray(header));

  return (
    <div className={classes.root}>
      {tableHeaderText && <p className={classes.tableText}>{tableHeaderText}</p>}

      {tableData && (
        <TableContainer className={classes.tableRootContainer}>
          <MuiTable aria-label='dynamic table' className={classes.tableContainer}>
            {tableDataHeaders && (
              <TableHead>
                <TableRow className={classes.firstRow}>
                  {tableDataHeaders?.map((heading, headingKey) => {
                    const hasSubheader = typeof heading !== 'string';
                    const cols = hasSubheader ? Object.keys(heading).length - 1 : 1;

                    return hasSubheader ? (
                      <TableCell key={headingKey} colSpan={cols}>
                        {heading[0]}
                      </TableCell>
                    ) : (
                      <TableCell key={headingKey} colSpan={cols} rowSpan={2}>
                        {heading}
                      </TableCell>
                    );
                  })}
                </TableRow>
                {tableContainsSubHeaders && (
                  <TableRow className={classes.subheader}>
                    {tableDataHeaders?.map((heading) => {
                      return (
                        typeof heading !== 'string' &&
                        Object.keys(heading).length !== 1 && (
                          <Fragment key={heading[0]}>
                            {heading
                              .filter((_, index) => index > 0)
                              .map((subheaders, subheaderKey) => {
                                return <TableCell key={subheaderKey}>{subheaders}</TableCell>;
                              })}
                          </Fragment>
                        )
                      );
                    })}
                  </TableRow>
                )}
              </TableHead>
            )}
            <TableBody className={classes.tableBody}>
              {tableData.map((data, dataKey) => (
                <TableRow key={dataKey}>
                  {Object.entries(data).map(([key, value], valueKey) => (
                    <TableCell key={valueKey} data-label={key}>
                      {value}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </MuiTable>
        </TableContainer>
      )}
      {tableFooterText && <p className={classes.tableText}>{tableFooterText}</p>}
    </div>
  );
});
