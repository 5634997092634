import { AuthError } from '../util';
import { generateCodeChallenge, generateCodeVerifier } from './pkce';
import type {
  AuthConfig,
  AuthnRequest,
  AuthnResponse,
  AuthTokenRequest,
  FetchAuthResponseBody,
} from './types';
import { authn, logout, token } from './userAuthnApi';

export const endToken = (authConfig: AuthConfig) => {
  return async (): Promise<void> => {
    await logout(authConfig);
  };
};

export const fetchTokens = (authConfig: AuthConfig) => {
  return async (username: string, password: string): Promise<FetchAuthResponseBody> => {
    // get code challenge
    const codeVerifier = generateCodeVerifier(45);
    const codeChallenge = await generateCodeChallenge(codeVerifier);

    // get code
    const authnRequest: AuthnRequest = {
      username,
      password,
      codeChallenge,
      clientId: authConfig.clientId,
      redirectUri: authConfig.redirectUri,
    };
    const authnRawResponse = await authn(authnRequest, authConfig);

    const authnResponseObject = await authnRawResponse.json();

    // short circuit and return error response
    if (!authnRawResponse.ok && authnResponseObject.status.messages) {
      throw new AuthError(
        authnResponseObject.status.messages[0].description,
        authnResponseObject.status.messages[0].code,
      );
    }
    const authnResponse = authnResponseObject.response as AuthnResponse;
    const userId = authnResponse.userId;

    // get tokens
    const tokenRequest: AuthTokenRequest = {
      client_id: authConfig.clientId,
      code: authnResponse.code,
      code_verifier: codeVerifier,
      grant_type: authnResponse.grant_type,
      redirect_uri: authConfig.redirectUri,
    };

    const tokenRawResponse = await token(authnResponse.uri, tokenRequest);
    const tokenResponse = await tokenRawResponse.json();

    if (!tokenRawResponse.ok || !tokenResponse) {
      throw new AuthError('Error in token response', tokenRawResponse.status);
    }

    return { ...tokenResponse, userId };
  };
};

export const fetchTokensFromRefreshToken = () => {
  return (): Promise<Response> =>
    new Promise((_, reject) =>
      reject('User token expired, but have no way to refresh tokens. Please refresh browser'),
    );
};
