import { useCallback, useMemo, useState } from 'react';

import { Divider, Link, Stack } from '@mui/material';

import { Button } from '@ecp/components';
import { ContactUsModal } from '@ecp/features/servicing/shared/components';
import type { ProductLine } from '@ecp/features/servicing/shared/types';
import { createSalesUrl } from '@ecp/features/servicing/shared/util';
import { IconCardAuto, IconCardHome, IconCardHomeAuto } from '@ecp/themes/base';

import type { ButtonCardProps } from '../ButtonCards';
import { ButtonCards } from '../ButtonCards';
import { useStyles } from './StartYourQuote.styles';

const buttonCardOptions: ButtonCardProps[] = [
  {
    key: 'AUTO',
    title: 'Auto',
    icon: <IconCardAuto />,
    displayText: <div>Shop for coverage for your car, light truck or SUV.</div>,
  },
  {
    key: 'HOME',
    title: 'Home',
    icon: <IconCardHome />,
    displayText: <div>Protect the investment in your house.</div>,
  },
  {
    key: 'AutoPlusHome',
    title: 'Auto + Home',
    icon: <IconCardHomeAuto />,
    displayText: (
      <div>
        Great choice – you save more when you combine coverage for your car, light truck or SUV with
        coverage for your home.
      </div>
    ),
  },
];

export const StartYourQuote: React.FC = () => {
  const { classes } = useStyles();
  const [selectedButtonCard, setSelectedButtonCard] = useState<ProductLine>('AUTO');

  const handleButtonCardClick = useCallback(
    (value: ProductLine) => {
      setSelectedButtonCard(value);
    },
    [setSelectedButtonCard],
  );

  const salesUrl = useMemo((): string => {
    return createSalesUrl(selectedButtonCard);
  }, [selectedButtonCard]);

  const [showHelpModal, setShowHelpModal] = useState(false);
  const openHelpModal = useCallback(() => setShowHelpModal(true), []);
  const closeHelpModal = useCallback(() => setShowHelpModal(false), []);

  return (
    <Stack direction='column' className={classes.cardContainer} spacing={2}>
      <Divider className={classes.titleLine}>Start your online quote</Divider>
      <ButtonCards
        options={buttonCardOptions}
        value={selectedButtonCard}
        onButtonClick={handleButtonCardClick}
      />
      <div className={classes.button}>
        <Button variant='primary' href={salesUrl} target='_blank'>
          GET A QUOTE
        </Button>
      </div>
      <div className={classes.contactUs}>
        Were you expecting to see a policy and it's not shown?{' '}
        <Link component='button' onClick={openHelpModal} className={classes.contactUsLink}>
          Contact Us
        </Link>
        {showHelpModal && <ContactUsModal open={showHelpModal} onClose={closeHelpModal} />}
      </div>
    </Stack>
  );
};
