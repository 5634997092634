import type { AvatarProps } from '@mui/material';
import { Avatar as MuiAvatar } from '@mui/material';

import { useStyles } from './HelpBox.styles';

type Props = Omit<AvatarProps, 'content' | 'icon' | 'src'> & {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  content?: React.ReactElement | string;
  icon?: React.ReactElement;
  image?: string;
};

export const HelpBox: React.FC<Props> = (props) => {
  const {
    image: src,
    content,
    classes: _, // unused, just don't want to pass along in rest
    ...rest
  } = props;
  const { classes } = useStyles(undefined, { props });

  return (
    <div className={classes.root}>
      {src && <MuiAvatar className={classes.icon} alt='Help Box Icon' src={src} {...rest} />}
      <div className={classes.content}>{content}</div>
    </div>
  );
};
