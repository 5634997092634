import { Stack } from '@mui/material';

import { useStyles } from './ReviewPolicyDetails.styles';

export interface ReviewPolicyDetailsProps {
  name: string;
  value?: string;
  helperText?: string;
}

export const ReviewPolicyDetails: React.FC<ReviewPolicyDetailsProps> = (props) => {
  const { name, value, helperText } = props;
  const { classes, cx } = useStyles();

  return (
    <Stack direction='row'>
      <p className={cx(classes.itemSection, classes.title)}>{name}</p>
      <div className={classes.itemSection}>
        <p className={classes.value}>{value}</p>
        <p className={classes.subText}>{helperText}</p>
      </div>
    </Stack>
  );
};
