import { apiRoot, buildRequestHeaders } from '../servicingApiConfig';
import type { ServicingResponse } from '../servicingRequest';
import { servicingRequest } from '../servicingRequest';
import type { ClaimsContext, ClaimsResponse } from './types';

const postContext = async (
  claimsContext: ClaimsContext,
): Promise<ServicingResponse<ClaimsResponse>> => {
  const headers = await buildRequestHeaders({ withUserToken: true });

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-claims-context/v1/claims/create`,
    options: {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(claimsContext),
    },
  });
};

export const getClaimsContext = async (
  claimsContext: ClaimsContext,
): Promise<ServicingResponse<ClaimsResponse>> => postContext(claimsContext);
