import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'Tooltip' })(() => ({
  root: {},
  tooltip: {
    '& > p': {
      color: 'inherit',
    },
  },
}));
