import { keyframes, makeStyles } from '@ecp/themes/base';

const spinnerKeyframes = keyframes({
  from: {
    opacity: 1,
  },
  to: {
    opacity: 0,
    marginTop: '3px',
    marginLeft: '-3px',
    width: '6px',
    height: '6px',
  },
});

export const useStyles = makeStyles<{ loaderPositionStyle: 'absolute' | 'fixed' }>({
  name: 'ModuleSpinnerStyles',
})((theme, { loaderPositionStyle }) => ({
  spinnerText: {
    marginTop: 40,
    width: 200,
    marginLeft: -60,
    ...theme.typography.body3Bold,
  },
  loader: {
    boxSizing: 'border-box',
    position: loaderPositionStyle,
    left: '50%',
    top: '50%',
    padding: '28px',
    width: '86px',
    height: '86px',
    marginLeft: '-43px',
    marginTop: '-43px',
    ' .c, .d, .e, .f, .g, .h, .i, .j, .k, .l, .m, .n': {
      position: 'absolute',
      top: '0',
      left: '40px',
      width: '10px',
      height: '10px',
      backgroundColor: '#abb4ba',
      borderRadius: '10px',
      transformOrigin: '5px 43px',
      animation: `1.2s linear infinite ${spinnerKeyframes}`,
    },
    ' .c': {
      transform: 'rotate(0deg)',
    },
    ' .d': {
      transform: 'rotate(30deg)',
      animationDelay: '-1.1s',
    },
    ' .e': {
      transform: 'rotate(60deg)',
      animationDelay: '-1s',
    },
    ' .f': {
      transform: 'rotate(90deg)',
      animationDelay: '-.9s',
    },
    ' .g': {
      transform: 'rotate(120deg)',
      animationDelay: '-.8s',
    },
    ' .h': {
      transform: 'rotate(150deg)',
      animationDelay: '-.7s',
    },
    ' .i': {
      transform: 'rotate(180deg)',
      animationDelay: '-.6s',
    },
    ' .j': {
      transform: 'rotate(210deg)',
      animationDelay: '-.5s',
    },
    ' .k': {
      transform: 'rotate(240deg)',
      animationDelay: '-.4s',
    },
    ' .l': {
      transform: 'rotate(270deg)',
      animationDelay: '-.3s',
    },
    ' .m': {
      transform: 'rotate(300deg)',
      animationDelay: '-.2s',
    },
    ' .n': {
      transform: 'rotate(330deg)',
      animationDelay: '-.1s',
    },
    ' img': {
      height: '30px',
    },
  },
  logo: {},
}));
