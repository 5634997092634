import * as yup from 'yup';

import type { YupSchemaMap } from '@ecp/utils/form';

export interface AdditionalInterestInputs {
  name: string;
  emailAddress?: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zipCode: string;
}

export const additionalInterestSchema: YupSchemaMap<AdditionalInterestInputs> = {
  name: yup.string().required('Landlord or company name is required'),
  emailAddress: yup.string().email('Invalid email address'),
  address1: yup.string().required('Address line 1 is required'),
  address2: yup.string(),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  zipCode: yup.string().max(5).required('ZIP code is required'),
};
