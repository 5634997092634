import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PolicyDetails' })((theme) => ({
  root: {},
  date: { whiteSpace: 'nowrap' },
  subHeader: theme.typography.body1Bold,
  container: {
    [theme.breakpoints.up('md')]: {
      '& > *': {
        width: '33%',
      },
      '& > :first-child': { maxWidth: 180 },
    },
  },
  titleBody: {
    placeItems: 'end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
    },
  },
  link: {
    ...theme.typography.button2,
    textDecoration: 'none',
    textAlign: 'left',
    width: 'fit-content',
  },
  email: { overflowWrap: 'break-word' },
  twoItemsContainer: {
    [theme.breakpoints.up('md')]: {
      '& > :first-child': { width: '33%', maxWidth: 180 },
    },
  },
  snackbar: {
    '.MuiSnackbarContent-message': {
      ...theme.typography.body1,
      padding: 0,
    },
  },
  icon: {
    ...theme.mixins.setColorSvg(theme.palette.common.white),
  },
  iconContainer: { height: 20, width: 20 },
}));
