import * as yup from 'yup';

import type { YupSchemaMap } from '@ecp/utils/form';

export interface LoginFormInputs {
  email?: string;
  password?: string;
  isRememberOnDevice?: boolean;
}

export const loginSchema: YupSchemaMap<LoginFormInputs> = {
  email: yup.string().email().required(),
  password: yup.string().required(),
  isRememberOnDevice: yup.boolean().notRequired(),
};
