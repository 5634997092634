import type { Breakpoint } from '@mui/material';

import { makeStyles } from '@ecp/themes/base';

type SecondaryContentBreakBehavior = 'hide' | 'bottom';
export interface StyleProps {
  /** default 'lg' */
  secondaryContentBreakpoint?: Breakpoint;
  /** default 'bottom' */
  secondaryContentBreakBehavior?: SecondaryContentBreakBehavior;
}

export const useStyles = makeStyles<StyleProps>({
  name: 'DashboardCard',
})((theme, { secondaryContentBreakpoint = 'lg', secondaryContentBreakBehavior = 'bottom' }) => ({
  childWrapper: {
    width: '100%',
  },
  linkContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  linkButton: {
    border: 0,
    background: 'none',
    boxShadow: 'none',
    borderRadius: 0,
    cursor: 'pointer',
  },
  headerContainer: {
    paddingBottom: 0,
  },
  secondaryContent: {
    [theme.breakpoints.up(secondaryContentBreakpoint)]: { minWidth: 241 },
    [theme.breakpoints.down(secondaryContentBreakpoint)]: {
      display: secondaryContentBreakBehavior === 'hide' ? 'none' : 'block',
    },
  },
  title: theme.typography.h1,
  wrapper: {},
}));
