import { memo } from 'react';

import { CircularProgress, Stack } from '@mui/material';

import { parseDollar } from '@ecp/utils/common';
import { useField, useFormContext } from '@ecp/utils/form';

import { Alert, Card } from '@ecp/components';
import { AccountSelect } from '@ecp/features/servicing/shared/components';
import type { UseModalPathReturn } from '@ecp/features/servicing/shared/routing';
import {
  type PolicyBillingDetails,
  useAmountDueNDueDateInfo,
} from '@ecp/features/servicing/shared/state';
import { getBillingPlanDescription } from '@ecp/features/servicing/shared/state';
import { getBrandData } from '@ecp/features/servicing/shared/util';
import type { MakePaymentFormInputs } from '@ecp/features/servicing/shared/util';

import { useStyles } from './AutopaySetup.styles';

export interface AutopaySetupProps extends UseModalPathReturn {
  policyBillingDetails: PolicyBillingDetails;
  hasNoPaymentMethods?: boolean;
  showError: boolean;
}

export const AutopaySetup: React.FC<AutopaySetupProps> = memo((props) => {
  const {
    currentFlow,
    currentStep,
    policyBillingDetails,
    hasNoPaymentMethods,
    showError,
    policyNumber,
  } = props;

  const inManageFlow = currentFlow === 'autopaymanage';
  const noPaymentDue = (policyBillingDetails.billingInfo?.minimumDue as number) <= 0;
  const shouldShowAddButtons = hasNoPaymentMethods && noPaymentDue;

  const { classes } = useStyles();
  const {
    paymentAmount,
    paymentAmountDescription,
    isLoading: isLoadingAmountDueNDueDateInfo,
    isFetching: isFetchingAmountDueNDueDateInfo,
  } = useAmountDueNDueDateInfo({
    policyNumber,
    modalFlow: currentFlow,
    modalStep: currentStep,
    throwOnError: true,
  });
  const nextDueAmount = paymentAmount ? parseDollar(paymentAmount) : paymentAmountDescription;
  const { control } = useFormContext<MakePaymentFormInputs>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const paymentAmountField = useField({
    name: 'paymentAmount',
    defaultValue: nextDueAmount,
    control,
  });

  const cardBodyPaymentAmount = (
    <Stack direction='row' className={classes.amountStack}>
      <span className={classes.amountTitle}>
        {inManageFlow ? 'Amount per payment' : 'Estimated payment'}
      </span>
      <span className={classes.amount}>{nextDueAmount}</span>
    </Stack>
  );

  const frequency = getBillingPlanDescription(policyBillingDetails.billingInfo?.billingPlan);
  const cardBodyPaymentFrequency = <span className={classes.todayDate}>{frequency}</span>;

  const customerServicePhone = getBrandData(policyBillingDetails.policyInfo).mainCustServPhoneNum;

  const showAddNewCC = ['ONE-PAY PLAN', 'TWELVE-PAY PLAN'].includes(
    policyBillingDetails.billingInfo?.billingPlan,
  );

  if (isFetchingAmountDueNDueDateInfo || isLoadingAmountDueNDueDateInfo)
    return (
      <div className={classes.spinner}>
        <CircularProgress aria-label='Loading...' />
      </div>
    );

  return (
    <Stack className={classes.root} spacing={3}>
      <Stack spacing={1.5}>
        <AccountSelect
          {...props}
          customerServicePhone={customerServicePhone}
          buttons
          showAddNewCC={showAddNewCC}
        />
        {showError && (
          <Alert withIcon type='info'>
            <p>Enrollment in AutoPay requires a bank account be selected as your payment method.</p>
          </Alert>
        )}
      </Stack>
      {!shouldShowAddButtons && (
        <>
          <Stack spacing={1.5}>
            <div>
              <p className={classes.dateHeading}>Payment frequency</p>
              <Card
                classes={{ content: classes.cardContent, root: classes.paymentBorder }}
                divider={false}
                body={cardBodyPaymentFrequency}
              />
            </div>
            {!inManageFlow && (
              <Alert withIcon type='info'>
                <p>AutoPay will begin on the next payment due date</p>
              </Alert>
            )}
          </Stack>
          <div>
            <p className={classes.dateHeading}>
              {inManageFlow ? 'Payment amount' : 'AutoPay amount'}
            </p>
            <Card
              classes={{ content: classes.cardContent, root: classes.paymentBorder }}
              divider={false}
              body={cardBodyPaymentAmount}
            />
          </div>
        </>
      )}
    </Stack>
  );
});
