import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<void, 'icon'>({ name: 'PhoneLink' })((theme) => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  link: {
    ...theme.mixins.underlinedLink,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      ...theme.mixins.setColorSvg('theme.palette.text.hover'),
      color: theme.palette.text.hover,
      textDecoration: 'underline',
    },
  },
  noLink: {
    color: 'inherit',
    textDecoration: 'none',
    cursor: 'default',
  },
  underlinedLink: {
    ...theme.mixins.underlinedLink,
    '& :hover': {
      ...theme.mixins.setColorSvg('theme.palette.text.hover'),
      color: theme.palette.text.hover,
      textDecoration: 'underline',
    },
  },
  icon: theme.mixins.setColorSvg(theme.palette.text.link),
  // use these variants if a Link doesn't share the same Typography as the content.
  extraLarge: theme.typography.subtitle,
  large: theme.typography.body4,
  medium: theme.typography.button2,
  small: theme.typography.button2Small,
}));
