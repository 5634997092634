import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<void>({ name: 'MobileNav' })((theme) => ({
  root: {},
  drawerPaper: { width: 280 },
  iconButton: { padding: 0 },
  itemLast: { borderBottom: `2px solid ${theme.palette.primary.main}` },
  list: { padding: 0 },
  secondaryItem: { color: theme.palette.text.link },
  secondaryActiveItem: {},
}));
