import { Stack } from '@mui/material';

import type { Document, DocumentCategory } from '@ecp/features/servicing/shared/state';
import {
  IconUIIconOther,
  IconUIPolicy,
  IconUIPreparingYourQuote,
  IconUIRefresh,
} from '@ecp/themes/base';

import { useStyles } from './AccordionTitle.styles';
interface AccordionTitleProps {
  documentDataList: Document[];
  title: DocumentCategory;
}

export const AccordionTitle: React.FC<AccordionTitleProps> = (props) => {
  const { documentDataList, title } = props;
  const { classes, cx } = useStyles();
  let logo;
  if (title) {
    switch (title) {
      case 'Policy':
        logo = (
          <IconUIPolicy
            className={cx(classes.icon, documentDataList?.length ? '' : classes.iconDisabled)}
          />
        );
        break;
      case 'Billing':
        logo = (
          <IconUIPreparingYourQuote
            className={cx(classes.icon, documentDataList?.length ? '' : classes.iconDisabled)}
          />
        );
        break;
      case 'Renewal':
        logo = (
          <IconUIRefresh
            className={cx(classes.icon, documentDataList?.length ? '' : classes.iconDisabled)}
          />
        );
        break;
      case 'Other':
        logo = (
          <IconUIIconOther
            className={cx(classes.icon, documentDataList?.length ? '' : classes.iconDisabled)}
          />
        );
        break;
    }
  }

  return (
    <Stack direction='row' spacing={1} className={classes.root}>
      {logo}
      <span className={classes.title}>{title}</span>
      <span className={classes.titleParentheses}>({documentDataList?.length})</span>
    </Stack>
  );
};
