import { flagValues } from '@ecp/utils/flags';

import { FullScreenModal } from '@ecp/features/servicing/shared/components';
import { MODAL_FLOW, MODAL_STEP, useModalPath } from '@ecp/features/servicing/shared/routing';
import {
  getProductLineFromPolicyResponse,
  isLegacyPolicy,
  usePolicy,
} from '@ecp/features/servicing/shared/state';

import { AdditionalInterestConfirmation } from '../AdditionalInterestConfirmation';
import { AdditionalInterestEdit } from '../AdditionalInterestEdit';
import { DeleteAdditionalInterest } from '../DeleteAdditionalInterest';
import { UpdateAdditionalInterest } from '../UpdateAdditionalInterest';

const AdditionalInterestModalContent: React.FC = () => {
  const modalPath = useModalPath();

  const isBackButton = false;
  let title = 'Add a landlord';
  let body;

  switch (modalPath.currentStep) {
    case MODAL_STEP.MODAL_EDIT:
      body = <AdditionalInterestEdit {...modalPath} />;
      break;
    case MODAL_STEP.MODAL_CONFIRMATION:
      body = <AdditionalInterestConfirmation {...modalPath} />;
      break;
    case MODAL_STEP.UPDATE_ADDITIONAL_INTEREST:
      body = <UpdateAdditionalInterest {...modalPath} />;
      title = 'Edit landlord';
      break;
    case MODAL_STEP.DELETE_ADDITIONAL_INTEREST:
      body = <DeleteAdditionalInterest {...modalPath} />;
      title = 'Delete landlord';
      break;

    default:
      if (modalPath.policyNumber && modalPath.currentFlow) {
        modalPath.init(modalPath.policyNumber, modalPath.currentFlow, MODAL_STEP.MODAL_EDIT);
      }
  }

  return (
    <FullScreenModal
      title={title}
      isBackButton={isBackButton}
      onClickBack={modalPath.goBack}
      onCloseModal={modalPath.reset}
    >
      {body}
    </FullScreenModal>
  );
};

export const AdditionalInterestModal: React.FC = () => {
  const modalPath = useModalPath();
  const { policyData } = usePolicy(modalPath.policyNumber, { throwOnError: false });
  const productLine = getProductLineFromPolicyResponse(policyData);
  const isClassic = isLegacyPolicy(productLine, policyData?.policy.sourceSystemName);
  if (
    modalPath.currentFlow === MODAL_FLOW.ADDITIONAL_INTEREST &&
    flagValues.ADDITIONAL_INTEREST_MANAGE &&
    !isClassic
  )
    return <AdditionalInterestModalContent />;
  else return null;
};
