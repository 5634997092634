import { useCallback } from 'react';

import { Divider, Stack } from '@mui/material';

import { flagValues } from '@ecp/utils/flags';

import { Button } from '@ecp/components';
import { InfoCard } from '@ecp/features/servicing/shared/components';
import { MODAL_FLOW, MODAL_STEP, useModalPath } from '@ecp/features/servicing/shared/routing';
import type { ThirdPartyInterestRole } from '@ecp/features/servicing/shared/types';
import { IconCardResponsibleHomeOwner, IconUIDelete, IconUIEdit } from '@ecp/themes/base';

import { useStyles } from './AdditionalInterestCard.styles';
interface Props {
  description: string;
  thirdPartyInterestRole: ThirdPartyInterestRole;
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
}

export const AdditionalInterestCard: React.FC<Props> = (props) => {
  const { description, thirdPartyInterestRole } = props;

  const { classes } = useStyles(undefined, { props });
  const modalPath = useModalPath();
  const policyNumber = modalPath.policyNumber;

  const handleEditClick = useCallback(() => {
    if (policyNumber)
      modalPath.init(
        policyNumber,
        MODAL_FLOW.ADDITIONAL_INTEREST,
        MODAL_STEP.UPDATE_ADDITIONAL_INTEREST,
        { thirdPartyInterestRole },
      );
  }, [modalPath, policyNumber, thirdPartyInterestRole]);
  const handleDeleteClick = useCallback(() => {
    if (policyNumber)
      modalPath.init(
        policyNumber,
        MODAL_FLOW.ADDITIONAL_INTEREST,
        MODAL_STEP.DELETE_ADDITIONAL_INTEREST,
        { thirdPartyInterestRole },
      );
  }, [modalPath, policyNumber, thirdPartyInterestRole]);

  return (
    <InfoCard
      Icon={IconCardResponsibleHomeOwner}
      title={description}
      classes={{
        root: classes.root,
        icon: classes.titleIcon,
      }}
      body={
        flagValues.ADDITIONAL_INTEREST_MANAGE ? (
          <Stack spacing={1.5} divider={<Divider />}>
            <Stack direction='row'>
              <IconUIEdit className={classes.icon} />
              <Button
                variant='iconTextMedium'
                onClick={handleEditClick}
                classes={{ root: classes.button }}
              >
                Edit
              </Button>
            </Stack>
            <Stack direction='row'>
              <IconUIDelete className={classes.icon} />
              <Button
                variant='iconTextMedium'
                onClick={handleDeleteClick}
                classes={{ root: classes.button }}
              >
                Delete
              </Button>
            </Stack>
          </Stack>
        ) : undefined
      }
    />
  );
};
