import { useCallback, useMemo } from 'react';

import { useQueries } from '@tanstack/react-query';

import { emptyArray, isTruthy } from '@ecp/utils/common';

import { QUERY_KEYS } from '../constants';
import { usePolicy, useUserPolicies } from '../policy';
import type { ServicingResponse } from '../servicingRequest';
import { isBillingAccountStatusInCancellation, shouldMakeBillingCall } from '../util';
import { getBillingDetailsByAccountId } from './api';
import type {
  BillingIdsResponse,
  BillingResponse,
  GetBillingDetailsRequest,
  UseAllBillingAccountsReturn,
  UseBillingAccountReturn,
} from './types';

export const getBillingDetailsKey = (billingAccountId: string): Array<unknown> => [
  QUERY_KEYS.BILLING_DETAILS,
  billingAccountId,
];

const getBillingDetailsQuery = async (
  billingAccountId: string,
): Promise<ServicingResponse<BillingResponse>> =>
  await getBillingDetailsByAccountId(billingAccountId);

export const useBillingDetails = (
  request: GetBillingDetailsRequest,
  throwOnError = true,
): BillingIdsResponse => {
  const { billingAccountIds = emptyArray as unknown as string[] } = request;
  const queries = billingAccountIds.map((billingAccountId) => {
    return {
      queryKey: getBillingDetailsKey(billingAccountId),
      queryFn: () => getBillingDetailsQuery(billingAccountId),
      throwOnError,
    };
  });

  const results = useQueries({ queries });

  const refetch = useCallback(
    (billingAccountId?: string) => {
      results
        .find(
          (billingAccount) => billingAccount.data?.payload.billingAccountId === billingAccountId,
        )
        ?.refetch();
    },
    [results],
  );

  const billingAccounts: BillingIdsResponse = useMemo(
    () => ({
      accounts: results.map((result) => result.data?.payload).filter(isTruthy),
      accountResults: results
        .map((result, index) => ({
          account: result.data?.payload,
          billingAccountId: billingAccountIds[index],
          isLoading: result.isLoading,
          isError: result.isError,
          isFetching: result.isFetching,
        }))
        .filter(isTruthy),
      isLoading: results.some((result) => result.isLoading),
      isError: results.some((result) => result.isError),
      refetch,
      isFetching: results.some((result) => result.isFetching),
    }),
    [billingAccountIds, refetch, results],
  );

  return billingAccounts;
};

/**
 * Convenience hook for getting billing account from a policy.
 * Takes care of conditionally calling if policy is not valid to call.
 */
export const useBillingAccount = (
  { policyNumber }: { policyNumber: string | undefined },
  options?: { throwOnError?: boolean },
): UseBillingAccountReturn => {
  const { throwOnError = true } = options ?? {};
  const {
    policyData,
    isLoading: isLoadingPolicy,
    isError: isErrorPolicy,
    isFetching: isFetchingPolicy,
  } = usePolicy(policyNumber, { throwOnError });

  const billingAccountNumber = policyData?.policy.billingAccountNumber;
  const billingAccountIds =
    shouldMakeBillingCall(policyData) && billingAccountNumber ? [billingAccountNumber] : undefined;

  const {
    accounts,
    refetch: refetchBillingDetails,
    isLoading: isLoadingBilling,
    isError: isErrorBilling,
    isFetching: isFetchingBillingDetails,
  } = useBillingDetails({ billingAccountIds }, throwOnError);
  const billingAccount = accounts?.at(0);

  const refetch = useCallback(
    () => refetchBillingDetails(billingAccountNumber),
    [refetchBillingDetails, billingAccountNumber],
  );

  return {
    billingAccount,
    isBillingAccountStatusInCancellation: isBillingAccountStatusInCancellation(billingAccount),
    refetch,
    isLoading: isLoadingBilling || isLoadingPolicy,
    isError: isErrorBilling || isErrorPolicy,
    isFetching: isFetchingBillingDetails || isFetchingPolicy,
  };
};

/**
 * Convenience hook for getting billing accounts for all policies.
 * Takes care of conditionally calling if policy is not valid to call.
 */
export const useAllBillingAccounts = (options?: {
  throwOnError?: boolean;
}): UseAllBillingAccountsReturn => {
  const { throwOnError = true } = options ?? {};

  const {
    policies,
    isLoading: isLoadingPolicy,
    isError: isErrorPolicy,
    isFetching: isFetchingUserPolicies,
  } = useUserPolicies({ throwOnPolicyError: throwOnError, throwOnUserError: throwOnError });

  const billingAccountIds = policies
    .filter(shouldMakeBillingCall)
    .map((policyData) => policyData.policy.billingAccountNumber);

  const {
    accounts,
    accountResults,
    refetch,
    isLoading: isLoadingBilling,
    isError: isErrorBilling,
    isFetching: isFetchingBillingDetails,
  } = useBillingDetails({ billingAccountIds }, throwOnError);

  return {
    accounts,
    accountResults,
    refetch,
    isLoading: isLoadingBilling || isLoadingPolicy,
    isError: isErrorBilling || isErrorPolicy,
    isFetching: isFetchingBillingDetails || isFetchingUserPolicies,
  };
};
