import * as yup from 'yup';

import type { YupSchemaMap } from '@ecp/utils/form';

import type { PreferenceOption } from '@ecp/features/servicing/shared/state';

export interface PaperlessUnenrollEditFormInputs {
  deliveryOption?: PreferenceOption;
}

export const paperlessUnenrollEditSchema: YupSchemaMap<PaperlessUnenrollEditFormInputs> = {
  deliveryOption: yup.string().required('You must select an option to continue'),
};
