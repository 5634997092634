import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AppErrorBoundary' })((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  body: {
    display: 'flex',
    justifyContent: 'start',
  },
  image: {
    display: 'block',
    height: 'auto',
    maxWidth: 'fit-content',
    width: '100%',
  },
  imageContainer: {
    alignSelf: 'center',
  },
  backButton: {
    width: 'fit-content',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));
