import type { YupSchemaMap } from '@ecp/utils/form';

import { getNameSchema } from '@ecp/features/servicing/shared/util';

export interface AutopayReviewFormInputs {
  electronicSignature?: string;
}

export const autopayReviewSchema: YupSchemaMap<AutopayReviewFormInputs> = {
  electronicSignature: getNameSchema({
    requiredMessage: 'Signature required to enroll in AutoPay',
  }),
};
