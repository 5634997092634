import { useCallback, useMemo } from 'react';

import { createSearchParams, generatePath, useSearchParams } from 'react-router-dom';

import { useSharedState } from '@ecp/features/servicing/shared/state';
import type { SharedStateRequestKey } from '@ecp/features/servicing/shared/types/';
import {
  encodeUrlValue,
  generateEncodedPath,
  usePolicyNumberFromUrl,
} from '@ecp/features/servicing/shared/util';

import { flowUrlPath, QUERY_PARAMS } from './constants';
import { usePageFlow } from './pageFlowRouting';
import { useCurrentRoute } from './useCurrentRoute';

export const MODAL_FLOW = {
  ADD_DRIVER: 'addDriver',
  ADD_VEHICLE: 'addVehicle',
  ADDITIONAL_INTEREST: 'additionalInterest',
  AUTOPAY_ENROLL: 'autopayenroll',
  AUTOPAY_MANAGE: 'autopaymanage',
  AUTOPAY_UNENROLL: 'autopayunenroll',
  CA_MILEAGE: 'camileage',
  EDIT_MEMBERSHIP: 'editmembership',
  EDIT_VIN: 'editvin',
  PAPERLESS_ENROLL: 'paperlessenroll',
  PAPERLESS_UNENROLL: 'paperlessunenroll',
  PAY: 'pay',
  PAY_CLASSIC: 'payclassic',
} as const;

export const MODAL_STEP = {
  UPDATE_ADDITIONAL_INTEREST: 'updateAdditionalInterest',
  DELETE_ADDITIONAL_INTEREST: 'deleteAdditionalInterest',
  MODAL_LANDING: 'landing',
  MODAL_EDIT: 'edit',
  MODAL_REVIEW: 'review',
  MODAL_CONFIRMATION: 'confirmation',
  ADD_EFT_ACCOUNT: 'addeft',
  ADD_CC_ACCOUNT: 'addcc',
  PAY_ENROLL_REVIEW: 'enrollreview',
} as const;

export type ModalFlow = (typeof MODAL_FLOW)[keyof typeof MODAL_FLOW];

type ModalStepKey = keyof typeof MODAL_STEP;
export type ModalStep = (typeof MODAL_STEP)[keyof typeof MODAL_STEP];
type ModalSearchParamsKeys = (typeof QUERY_PARAMS)[keyof typeof QUERY_PARAMS];

export interface UseModalPathReturn {
  currentStep: ModalStep | undefined;
  currentFlow: ModalFlow | undefined;
  goBack: () => void;
  goForward: () => void;
  goTo: (path: ModalStep) => void;
  init: (
    policyNumber: string,
    flow: ModalFlow,
    step?: ModalStep,
    additionalSearchParams?: Partial<Record<ModalSearchParamsKeys, string>>,
  ) => void;
  policyNumber: string | undefined;
  reset: () => void;
  sharedStateKey: SharedStateRequestKey;
  getSearchParam: (key: ModalSearchParamsKeys) => string | null;
}

type GetModalStep = (flow: ModalFlow) => ModalStep;
interface NextPreviousPaths {
  next: ModalStep | GetModalStep;
  previous: ModalStep;
}

const sharedStateMap = {
  addDriver: 'addDriver',
  addVehicle: 'addVehicle',
  additionalInterest: 'additionalInterest',
  autopayenroll: 'autopayEnroll',
  autopaymanage: 'autopayManage',
  autopayunenroll: 'autopayUnenroll',
  camileage: 'caMileage',
  editmembership: 'editmembership',
  editvin: 'editVin',
  pay: 'paymentInfo',
  paperlessenroll: 'paperlessEnroll',
  paperlessunenroll: 'paperlessUnenroll',
  payclassic: 'payClassic',
} as const;

const getCurrentPathRoot = (currentPath: string): string =>
  currentPath.substring(0, currentPath.indexOf(flowUrlPath) - 1);

/**
 * useModalPath provides an abstraction layer to help navigation through payment/enroll autopay flow
 *
 * goBack and goForward use the current open screen inside modal and helps navigate to previous
 * and next screens inside modal, goTo() provides ability to go to the any target screen from MODAL_STEP,
 * and currentStep returns current payment/enrollAutopay path that is shown in modal
 */
export const useModalPath = (): UseModalPathReturn => {
  const currentRoute = useCurrentRoute();
  const [searchParams] = useSearchParams();

  const params = currentRoute.params;
  const step = params.step as ModalStep | undefined;
  const flow = params.flow as ModalFlow | undefined;

  const pageFlow = usePageFlow();
  if (
    // Check if flow or step from url is valid
    (params?.flow && !Object.values(MODAL_FLOW).includes(flow)) ||
    (params?.step && !Object.values(MODAL_STEP).includes(step))
  )
    pageFlow.navigate(getCurrentPathRoot(currentRoute.pattern.path));

  const policyNumber = usePolicyNumberFromUrl();

  const goTo = useCallback(
    (step: ModalStep) => {
      pageFlow.navigate(
        `${generatePath(currentRoute.pattern.path, {
          ...currentRoute.params,
          step,
        })}?${createSearchParams(searchParams)}`,
      );
    },
    [currentRoute.pattern.path, currentRoute.params, pageFlow, searchParams],
  );

  const goBack = useCallback((): void => {
    const currentStepIndex = Object.keys(MODAL_STEP).find(
      (key) => MODAL_STEP[key] === step,
    ) as ModalStepKey;

    const previousStep = previousNextPathMatrix[currentStepIndex].previous;
    goTo(previousStep);
  }, [goTo, step]);

  const goForward = useCallback((): void => {
    const currentStepIndex = Object.keys(MODAL_STEP).find(
      (key) => MODAL_STEP[key] === step,
    ) as ModalStepKey;

    const currentStep = previousNextPathMatrix[currentStepIndex];

    const nextPage =
      typeof currentStep.next === 'function' && !!flow
        ? (currentStep.next as GetModalStep)(flow)
        : (currentStep.next as ModalStep);

    goTo(nextPage);
  }, [goTo, flow, step]);

  const sharedStateKey: SharedStateRequestKey = `${sharedStateMap[flow ?? 'pay']}-${policyNumber}`;
  const [, setState] = useSharedState<unknown>(sharedStateKey);

  const getSearchParam = useCallback(
    (key: ModalSearchParamsKeys): string | null => searchParams.get(key),
    [searchParams],
  );

  const reset = useCallback(() => {
    const path = getCurrentPathRoot(currentRoute.pattern.path);

    setState(null);

    pageFlow.navigate(generatePath(path, currentRoute.params));
  }, [currentRoute.params, currentRoute.pattern.path, pageFlow, setState]);

  const init: UseModalPathReturn['init'] = useCallback(
    (policyNumber, flow, step, additionalSearchParams) => {
      const basePath = currentRoute.pattern.path;
      const path = basePath.includes(flowUrlPath) ? basePath : `${basePath}/${flowUrlPath}`;

      const initSearchParams = createSearchParams(
        !params.policyNumber
          ? {
              [QUERY_PARAMS.POLICY_NUMBER]: encodeUrlValue(policyNumber),
              ...additionalSearchParams,
            }
          : { ...additionalSearchParams },
      ).toString();

      pageFlow.navigate(
        `${generateEncodedPath(path, {
          ...currentRoute.params,
          flow,
          [QUERY_PARAMS.POLICY_NUMBER]: policyNumber,
          step: step ?? MODAL_STEP.MODAL_LANDING,
        })}?${initSearchParams}`,
      );
    },
    [currentRoute.params, currentRoute.pattern.path, pageFlow, params.policyNumber],
  );

  return useMemo(
    () => ({
      currentFlow: flow,
      currentStep: step,
      getSearchParam,
      goBack,
      goForward,
      goTo,
      init,
      policyNumber,
      reset,
      sharedStateKey,
    }),
    [
      flow,
      getSearchParam,
      goBack,
      goForward,
      goTo,
      init,
      policyNumber,
      reset,
      sharedStateKey,
      step,
    ],
  );
};

const previousNextPathMatrix: Record<ModalStepKey, NextPreviousPaths> = {
  MODAL_LANDING: {
    next: (flow) => (flow === 'paperlessenroll' ? MODAL_STEP.MODAL_REVIEW : MODAL_STEP.MODAL_EDIT),
    previous: MODAL_STEP.MODAL_LANDING,
  },
  MODAL_EDIT: {
    next: (flow) =>
      flow === 'paperlessunenroll' ||
      flow === 'editvin' ||
      flow === 'editmembership' ||
      flow === 'additionalInterest'
        ? MODAL_STEP.MODAL_CONFIRMATION
        : MODAL_STEP.MODAL_REVIEW,
    previous: MODAL_STEP.MODAL_LANDING,
  },
  MODAL_REVIEW: {
    next: (flow) =>
      flow === 'autopaymanage' ? MODAL_STEP.MODAL_LANDING : MODAL_STEP.MODAL_CONFIRMATION,
    previous: MODAL_STEP.MODAL_EDIT,
  },
  MODAL_CONFIRMATION: {
    next: (flow) => (flow === 'autopayenroll' ? MODAL_STEP.MODAL_EDIT : MODAL_STEP.MODAL_LANDING),
    previous: MODAL_STEP.MODAL_REVIEW,
  },
  ADD_EFT_ACCOUNT: {
    next: MODAL_STEP.MODAL_EDIT,
    previous: MODAL_STEP.MODAL_EDIT,
  },
  ADD_CC_ACCOUNT: {
    next: MODAL_STEP.MODAL_EDIT,
    previous: MODAL_STEP.MODAL_EDIT,
  },
  PAY_ENROLL_REVIEW: {
    next: MODAL_STEP.MODAL_CONFIRMATION,
    previous: MODAL_STEP.MODAL_EDIT,
  },
  UPDATE_ADDITIONAL_INTEREST: {
    next: MODAL_STEP.UPDATE_ADDITIONAL_INTEREST,
    previous: MODAL_STEP.UPDATE_ADDITIONAL_INTEREST,
  },
  DELETE_ADDITIONAL_INTEREST: {
    next: MODAL_STEP.DELETE_ADDITIONAL_INTEREST,
    previous: MODAL_STEP.DELETE_ADDITIONAL_INTEREST,
  },
};
