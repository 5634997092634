import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'Card' })((theme) => ({
  root: {
    borderRadius: 4,
    '& .MuiCardContent-root': {
      padding: 0,
    },
    height: 'fit-content',
    padding: 20,
    [theme.breakpoints.down('md')]: { padding: 16 },
  },
  headerDivider: { paddingTop: 8 },
}));
