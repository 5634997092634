import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'OneTimeCodePage' })((theme) => ({
  root: {},
  input: {
    maxWidth: 360,
    paddingTop: theme.spacing(1),
    '& .MuiFormControl-root': {
      marginBottom: 7,
    },
  },
  confirmButton: {
    width: 'fit-content',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  secondaryLink: {
    ...theme.typography.button2,
    cursor: 'pointer',
    color: theme.palette.text.link,
  },
  p: theme.typography.body1,
  b: theme.typography.body1Bold,
  icon: {
    ...theme.mixins.setColorSvg(theme.palette.common.white),
  },
  iconContainer: { height: 20, width: 20 },
  snackbar: {
    minWidth: 343,
    height: 70,
  },
  snackbarContent: { textAlign: 'start' },
  snackbarMessage: theme.typography.body1,
  divider: {
    height: 24,
  },
  links: {
    alignSelf: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      alignSelf: 'center',
      '& > hr': {
        display: 'none',
      },
    },
  },
  link: {
    alignSelf: 'center',
    alignItems: 'baseline',
  },
}));
