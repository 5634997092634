import { env } from '@ecp/env';
import type { ProductLine } from '@ecp/features/servicing/shared/types';

type SalesProductLine = ProductLine | 'AutoPlusHome';

export const createSalesUrl = (productLine?: SalesProductLine): string => {
  const baseUrl = env.salesAppBaseUrl;
  switch (productLine) {
    case 'AutoPlusHome':
      return `${baseUrl}?product=all`;
    case 'HOME':
      return `${baseUrl}?product=homespecial`;
    case 'AUTO':
      return `${baseUrl}?product=auto`;
    default:
      return baseUrl;
  }
};
