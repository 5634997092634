import { forwardRef } from 'react';

import { Stack } from '@mui/material';

import type { BrandAddress } from '@ecp/features/servicing/shared/brand-data';
import {
  getProductLineFromPolicyResponse,
  isLegacyPolicy,
  useUserPolicies,
} from '@ecp/features/servicing/shared/state';
import { getBrandDataByType, getBrandDataWithFallback } from '@ecp/features/servicing/shared/util';

import { useStyles } from './ContactUs.styles';

export const PolicyRelatedContacts = forwardRef<HTMLDivElement>((_, ref) => {
  const { classes } = useStyles();
  const { policies } = useUserPolicies({
    ignoreUserAuth: true,
    throwOnPolicyError: false,
    throwOnUserError: false,
  });

  const hasAutoPolicy = policies.some(
    (policyData) => getProductLineFromPolicyResponse(policyData) === 'AUTO',
  );
  const hasHomeClassic = policies.some(
    (policyData) =>
      getProductLineFromPolicyResponse(policyData) === 'HOME' &&
      isLegacyPolicy(
        getProductLineFromPolicyResponse(policyData),
        policyData.policy.sourceSystemName,
      ),
  );
  const hasHomeHomesite = policies.some(
    (policyData) =>
      getProductLineFromPolicyResponse(policyData) === 'HOME' &&
      !isLegacyPolicy(
        getProductLineFromPolicyResponse(policyData),
        policyData.policy.sourceSystemName,
      ),
  );
  const classicOnly = policies.every((policy) =>
    isLegacyPolicy(getProductLineFromPolicyResponse(policy), policy.policy.sourceSystemName),
  );
  const hasHomePolicy = hasHomeClassic || hasHomeHomesite;
  const hasHomePolicyClassicAndHomesite = hasHomeClassic && hasHomeHomesite;

  const brandData = getBrandDataWithFallback('AUTO');
  const autoBrandData = getBrandDataByType('AUTO');
  const homeBrandData = getBrandDataByType('HOME');

  const getAddress = (address: BrandAddress): React.ReactElement => (
    <>
      <p>{address.addressLine1}</p>
      {address.attention && <p>{address.attention}</p>}
      {address.addressLine2 && <p>{address.addressLine2}</p>}
      <p>
        {address.city}, {address.state} {address.zip}
        {address.zipPlus4 && `-${address.zipPlus4}`}
      </p>
    </>
  );

  if (hasHomePolicyClassicAndHomesite) return null;

  return (
    <>
      {classicOnly ? (
        <div className={classes.gridItem}>
          <Stack spacing={1}>
            <p className={classes.subTitle}>Billing address</p>
            <div>{getAddress(brandData.paymentsAddress)}</div>
          </Stack>
        </div>
      ) : (
        <>
          {hasHomePolicy && homeBrandData && (
            <div className={classes.gridItem}>
              <Stack spacing={1}>
                <p className={classes.subTitle}>Home billing address</p>
                <div>{getAddress(homeBrandData.paymentsAddress)}</div>
              </Stack>
            </div>
          )}
          {hasAutoPolicy && autoBrandData && (
            <div className={classes.gridItem}>
              <Stack spacing={1}>
                <p className={classes.subTitle}>Auto billing address</p>
                <div>{getAddress(autoBrandData.paymentsAddress)}</div>
              </Stack>
            </div>
          )}
        </>
      )}
    </>
  );
});
