import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PaperlessLanding' })((theme) => ({
  root: {},
  section: {
    width: '100%',
    padding: '16px 20px',
    borderRadius: 4,
    backgroundColor: theme.palette.grey[50],
  },
  card: {
    borderRadius: 4,
    border: `1px solid ${theme.palette.other.border}`,
    padding: '12px 16px',
  },
  cardContent: { paddingTop: 14 },
  button: {
    width: 'fit-content',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  icon: {
    ...theme.mixins.setColorSvg(theme.palette.grey[900]),
    minWidth: 24,
  },
  email: theme.typography.body1Bold,
  linkedPoliciesList: { listStyle: 'none', padding: 0 },
}));
