import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutopayConfirmation' })((theme) => ({
  root: {},
  title: {
    ...theme.typography.h2,
    color: theme.palette.text.primary,
  },
  other: { ...theme.typography.body3, color: theme.palette.text.primary },
  mapIcon: {
    height: 24,
    width: 24,
    marginRight: 10,
  },
  policyCard: {
    paddingTop: 12,
  },
}));
