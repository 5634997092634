import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'DocumentAccordionGroup' })((theme) => ({
  root: {},
  docTable: {
    '& td': {
      padding: 16,
    },
    '& td:last-child': {
      padding: '0px 16px',
      textAlign: 'right',
    },
    '& tr:last-child': { display: 'none' },
  },
  accordion: {
    '&::before': {
      display: 'block',
    },
  },
  accordionPadding: {
    '& .MuiAccordionDetails-root': { padding: 0 },
    '& .MuiAccordionSummary-root.Mui-expanded': { minHeight: 24 },
  },
}));
