import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'InfoCardList' })((theme) => ({
  root: {},
  child: { height: '100%' },
  list: {
    display: 'grid',
    gridAutoRows: 'auto',
    gridTemplateColumns: 'repeat(2, 1fr)',
    listStyle: 'none',
    padding: 0,
    margin: 0,
    gap: 16,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
    '> li': {
      // fixes half pixels causing dashed border of children (e.g. add vehicle button)
      // to be semi-transparent when width resolves to half pixels (i.e. screen width is odd)
      width: 'round(down, 100%, 1px)',
    },
  },
  title: {
    marginBottom: 16,
  },
  tooltip: {
    marginLeft: 8,
  },
}));
