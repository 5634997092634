import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'BookRollInfoModal' })((theme) => ({
  root: {},
  bookRollModalButton: { width: '100%', [theme.breakpoints.up('md')]: { width: 'fit-content' } },
  bookRollModalContainer: { padding: 0 },
  bookRollModalBody: { paddingBottom: 15 },
  bookRollModalList: { margin: 0, paddingInlineStart: 25, paddingBottom: 20 },
  bookRollFooter: { width: '100%' },
  titleBarCloseButton: { ...theme.mixins.setColorSvg(theme.palette.primary.main) },
  titleBar: { borderColor: theme.palette.other.divider },
}));
