import * as yup from 'yup';

import type { YupSchemaMap } from '@ecp/utils/form';

export interface VerifyCodeFormInput {
  code?: string;
}

export const verifyCodeSchema: YupSchemaMap<VerifyCodeFormInput> = {
  code: yup
    .number()
    .required('Required field')
    .positive()
    .test('len', 'Enter all six digits of your code', (value) => String(value).length === 6), // require 6 digits
};
