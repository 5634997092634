import { Stack } from '@mui/material';

import { env } from '@ecp/env';
import { PAGE_PATH } from '@ecp/features/servicing/shared/routing';
import { generateHelpPath } from '@ecp/features/servicing/shared/routing';
import {
  getProductLineFromPolicyResponse,
  useUserPolicies,
} from '@ecp/features/servicing/shared/state';
import { getBrandDataByType } from '@ecp/features/servicing/shared/util';

import { Link } from '../../Link';
import { NavLink } from '../../NavLink';
import { useStyles } from './LegalTerms.styles';

interface Props {
  showCaliforniaNotice?: boolean;
}

export const LegalTerms: React.FC<Props> = (props) => {
  const { showCaliforniaNotice = true } = props;
  const { policies } = useUserPolicies({
    ignoreUserAuth: true,
    throwOnPolicyError: false,
    throwOnUserError: false,
  });

  const hasAutoPolicy = policies.some(
    (policyData) => getProductLineFromPolicyResponse(policyData) === 'AUTO',
  );
  const hasHomePolicy = policies.some(
    (policyData) => getProductLineFromPolicyResponse(policyData) === 'HOME',
  );

  const { classes } = useStyles();

  // if user has auto policy, show auto copyright
  // if user has home policy, show home copyright
  // if user has both or neither, show both
  const autoCopyrightName =
    hasAutoPolicy || !hasHomePolicy ? getBrandDataByType('AUTO')?.uwCompanyCopyright : '';
  const homeCopyrightName =
    hasHomePolicy || !hasAutoPolicy ? getBrandDataByType('HOME')?.uwCompanyCopyright : '';

  const copyrights = [homeCopyrightName, autoCopyrightName]
    .sort()
    .filter((a) => !!a)
    .join(', ');

  return (
    <Stack className={classes.root} spacing={2}>
      <p className={classes.text}>
        © {new Date().getFullYear()} {copyrights}. All rights reserved.
      </p>
      <p className={classes.text}>
        View our &nbsp;
        <NavLink
          className={classes.links}
          to={generateHelpPath(PAGE_PATH.DISCLOSURES)}
          trackingName='legal_disclosures_policies_terms_service_footer_link'
          trackingLabel='View our Legal disclosures, policies, and terms of service'
        >
          Legal disclosures, policies, and terms of service
        </NavLink>
      </p>
      {showCaliforniaNotice && (
        <p>
          California Consumer Privacy Act Notice:&nbsp;
          {/* NOTE: External link will included in the footer section for day 1, regardless of partner domain. */}
          <Link
            className={classes.links}
            href={env.ccpaUrl}
            target='_blank'
            rel='noreferrer'
            trackingName='do_not_sell_personal_information_footer_link'
            trackingLabel='California Consumer Privacy Act Notice: Do Not Sell My Personal Information'
          >
            Do Not Sell My Personal Information
          </Link>
        </p>
      )}
    </Stack>
  );
};
