/* eslint-disable no-bitwise */

// lookup table byte to hex
const byte2hex: string[] = [...new Array(256)].map((_, index) =>
  (index + 0x100).toString(16).substr(1),
);

/**
 * Generates a cryptographically strong pseudo-random UUID.
 */
export const uuid = (): string => {
  const r = new Uint32Array(4);
  window.crypto.getRandomValues(r);

  return (
    byte2hex[r[0] & 0xff] +
    byte2hex[(r[0] >>> 8) & 0xff] +
    byte2hex[(r[0] >>> 16) & 0xff] +
    byte2hex[(r[0] >>> 24) & 0xff] +
    '-' +
    byte2hex[r[1] & 0xff] +
    byte2hex[(r[1] >>> 8) & 0xff] +
    '-' +
    byte2hex[((r[1] >>> 16) & 0x0f) | 0x40] +
    byte2hex[(r[1] >>> 24) & 0xff] +
    '-' +
    byte2hex[(r[2] & 0x3f) | 0x80] +
    byte2hex[(r[2] >>> 8) & 0xff] +
    '-' +
    byte2hex[(r[2] >>> 16) & 0xff] +
    byte2hex[(r[2] >>> 24) & 0xff] +
    byte2hex[r[3] & 0xff] +
    byte2hex[(r[3] >>> 8) & 0xff] +
    byte2hex[(r[3] >>> 16) & 0xff] +
    byte2hex[(r[3] >>> 24) & 0xff]
  );
};
