import { Stack } from '@mui/material';

import type { TrackingProps } from '@ecp/utils/analytics/tracking';
import { useField, useFormContext } from '@ecp/utils/form';

import { Button } from '@ecp/components';
import type { PaymentFormFooterInputs } from '@ecp/features/servicing/shared/util';

import { CheckboxField } from '../CheckboxField';
import { LoadingButton } from '../LoadingButton';
import { useStyles } from './PaymentsFormFooter.styles';

export interface PaymentsFormFooterProps extends TrackingProps {
  buttonText: string;
  onBackButtonClick?: () => void;
  checkBoxConfirmation?: boolean;
  isProcessing?: boolean;
  infoText?: string;
}

export const PaymentsFormFooter: React.FC<PaymentsFormFooterProps> = (props) => {
  const {
    onBackButtonClick,
    buttonText,
    checkBoxConfirmation = false,
    trackingName,
    trackingLabel,
    infoText,
  } = props;
  const { classes } = useStyles();
  const { control } = useFormContext<PaymentFormFooterInputs>();
  const consentField = useField({
    control,
    name: 'consent',
  });

  if (!(onBackButtonClick || buttonText)) return null;

  return (
    <Stack spacing={3} className={classes.root}>
      {checkBoxConfirmation && (
        <CheckboxField
          {...consentField}
          id='authorizeThisPayment'
          checked={!!consentField.value}
          label='I authorize this payment to be made.'
          trackingName='authorize_payment_checkbox'
        />
      )}
      {infoText && <h3>{infoText}</h3>}
      <Stack direction={{ xs: 'column', md: 'row' }} className={classes.buttonGroup} spacing={3}>
        {buttonText && (
          <LoadingButton
            type='submit'
            trackingName={trackingName}
            trackingLabel={trackingLabel}
            className={classes.submitButtton}
          >
            {buttonText}
          </LoadingButton>
        )}
        {onBackButtonClick && (
          <Button
            variant='iconTextMedium'
            onClick={onBackButtonClick}
            className={classes.backButton}
            trackingName='makepayment_backarrow_link'
            trackingLabel='pay_continue'
          >
            Back
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
