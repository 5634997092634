import type { Address, PolicyResponse } from '@ecp/features/servicing/shared/types';

export const getAddressByRefId = (
  policyResponse: PolicyResponse | undefined,
  referenceId: string | undefined,
): Address | undefined => {
  if (!policyResponse || !referenceId) return undefined;

  return policyResponse.addresses.find((address) => address.addressId === referenceId);
};
