import { memo } from 'react';

import type { TooltipProps } from '@mui/material';
import { IconButton } from '@mui/material';

import { IconUITooltip } from '@ecp/themes/base';

import { Tooltip } from './Tooltip';
import { useStyles } from './TooltipWithIcon.styles';

type Props = Omit<TooltipProps, 'children'> & {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  trackingName?: string;
};

export const TooltipWithIcon: React.FC<Props> = memo((props) => {
  const { className, title } = props;
  const { classes, cx } = useStyles(undefined, { props });

  return (
    <Tooltip className={cx(classes.root, className)} {...props}>
      <IconButton
        color='primary'
        className={classes.tooltipWithIconButton}
        aria-label={title as string}
        size='large'
      >
        <IconUITooltip className={classes.icon} />
      </IconButton>
    </Tooltip>
  );
});
