import type { EventName, Subscribe } from '../types';
import { genesys } from '../util';

/**
 * A promise wrapper for genesys "subscribe".
 * This function does no-op when chat is not enabled.
 *
 * @see https://developer.genesys.cloud/commdigital/digital/webmessaging/messengersdk/SDKCommandsEvents/
 *
 * @param eventName - the event name to be subscribed to, such as 'Messenger.open'
 */
export const subscribeToEvent = <T>(
  eventName: EventName,
  callback: (data: T | undefined) => Promise<void>,
): void => {
  (genesys as Subscribe)('subscribe', eventName, callback);
};
