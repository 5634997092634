import { Stack } from '@mui/material';

import { useStyles } from './LegalCopy.styles';

export const LegalCopy: React.FC<React.PropsWithChildren> = (props) => {
  const { classes } = useStyles();
  const { children } = props;

  return (
    <Stack spacing={3} className={classes.root}>
      {children}
    </Stack>
  );
};
