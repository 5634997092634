import * as yup from 'yup';

import type { YupSchemaMap } from '@ecp/utils/form';

import { getNameSchema } from '@ecp/features/servicing/shared/util';
export interface PaperlessEnrollReviewFormInputs {
  electronicSignature?: string;
}
export interface PaperlessEnrollReviewFormInputsCa {
  electronicSignature?: string;
  consent?: string;
}

const electronicSignatureSchema = getNameSchema({
  requiredMessage: 'Signature required to enroll in paperless',
});

export const paperlessEnrollReviewSchema: YupSchemaMap<PaperlessEnrollReviewFormInputs> = {
  electronicSignature: electronicSignatureSchema,
};
export const caPaperlessEnrollReviewSchema: YupSchemaMap<PaperlessEnrollReviewFormInputsCa> = {
  electronicSignature: electronicSignatureSchema,
  consent: yup.string().required('Consent required to enroll in paperless'),
};
