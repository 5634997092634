import { useMemo } from 'react';

import { useSharedState } from '@ecp/features/servicing/shared/state';

const errorHashName = '#isErrorPage';

interface UseIsErrorStateReturn {
  errorHashName: string;
  isErrorState: boolean | null | undefined;
  setIsErrorState: (arg0: boolean | null | undefined) => void;
}

export const useIsErrorState = (): UseIsErrorStateReturn => {
  const [isErrorState, setIsErrorState] = useSharedState<boolean>('AppErrorBoundary');

  return useMemo(
    () => ({
      errorHashName,
      isErrorState,
      setIsErrorState,
    }),
    [isErrorState, setIsErrorState],
  );
};
