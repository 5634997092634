import type { PolicyResponse } from '@ecp/features/servicing/shared/types';

import { apiRoot, buildRequestHeaders } from '../servicingApiConfig';
import type { ServicingResponse } from '../servicingRequest';
import { servicingRequest } from '../servicingRequest';
import type {
  AdditionalInterestRequest,
  AdditionalInterestResult,
  DeleteAdditionalInterestRequest,
  UpdateCaMileageRequest,
  UpdateCaMileageResult,
  UpdateVinRequest,
  UpdateVinResult,
} from './types';

export const getPolicyByPolicyNumber = async (
  policyNumber: string,
): Promise<ServicingResponse<PolicyResponse>> => {
  const headers = await buildRequestHeaders({ withUserToken: true });

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-policy/v1/policies/${policyNumber}`,
    options: {
      headers: headers,
      method: 'GET',
    },
  });
};

export const updateVin = async (
  updateVinRequest: UpdateVinRequest,
): Promise<ServicingResponse<UpdateVinResult>> => {
  const { policyNumber, ...body } = updateVinRequest;

  const headers = await buildRequestHeaders({ withUserToken: true });

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-policy/v1/policies/${policyNumber}/updateVin`,
    options: {
      headers: headers,
      method: 'PUT',
      body: JSON.stringify(body),
    },
  });
};

export const addAdditionalInterest = async (
  addLanlordRequest: AdditionalInterestRequest,
): Promise<ServicingResponse<AdditionalInterestResult>> => {
  const { policyNumber, ...body } = addLanlordRequest;

  const headers = await buildRequestHeaders({ withUserToken: true });

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-policy/v1/policies/${policyNumber}/additionalInterests`,
    options: {
      headers: headers,
      method: 'POST',
      body: JSON.stringify(body),
    },
  });
};

export const updateAdditionalInterest = async (
  addLandlordRequest: AdditionalInterestRequest,
): Promise<ServicingResponse<AdditionalInterestResult>> => {
  const { policyNumber, ...body } = addLandlordRequest;

  const headers = await buildRequestHeaders({ withUserToken: true });

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-policy/v1/policies/${policyNumber}/additionalInterests`,
    options: {
      headers: headers,
      method: 'PUT',
      body: JSON.stringify(body),
    },
  });
};
export const deleteAdditionalInterest = async (
  deleteLandlordRequest: DeleteAdditionalInterestRequest,
): Promise<ServicingResponse<AdditionalInterestResult>> => {
  const { policyNumber, policySourceSystemName, isPrimary } = deleteLandlordRequest;

  const headers = await buildRequestHeaders({ withUserToken: true });
  const role = isPrimary ? 'primary' : 'secondary';

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-policy/v1/policies/${policyNumber}/additionalInterests/${role}`,
    options: {
      headers: headers,
      method: 'DELETE',
    },
    params: { policySourceSystemName },
  });
};

export const updateCaMileage = async (
  updateCaMileageRequest: UpdateCaMileageRequest,
): Promise<ServicingResponse<UpdateCaMileageResult>> => {
  const { policyNumber, ...body } = updateCaMileageRequest;

  const headers = await buildRequestHeaders({ withUserToken: true });

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-policy/v1/policies/${policyNumber}/updateCaMileage`,
    options: {
      headers: headers,
      method: 'PUT',
      body: JSON.stringify(body),
    },
  });
};
