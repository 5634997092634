import { useParams } from 'react-router-dom';

import { ProtectYourInformationConnect } from './connect';

export const ProtectYourInformationPage: React.FC = () => {
  const { opco } = useParams();

  let protectYourInformation;
  switch (opco) {
    case 'connect':
      protectYourInformation = <ProtectYourInformationConnect />;
      break;
    default:
      protectYourInformation = <p>Partner Specific protecting your information.</p>;
  }

  return protectYourInformation;
};
