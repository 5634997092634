import { forwardRef, memo, useCallback, useEffect } from 'react';

import type { MenuProps, SelectProps as MuiSelectProps } from '@mui/material';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { emptyArray } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';
import { isHTMLElementWithValue } from '@ecp/utils/web';

import { IconUICheck, IconUIExpandMore } from '@ecp/themes/base';
import type { Option } from '@ecp/types';

import { formatLabel, getSortedOptions } from '../utils';
import { useStyles } from './Select.styles';

export type SelectProps<T extends string = string> = Omit<MuiSelectProps<T>, 'error' | 'onBlur'> & {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  compact?: boolean;
  error?: string;
  groupLabel?: React.ReactElement | string;
  groupSubText?: string;
  helperText?: React.ReactElement | string;
  inputAriaLabel?: string;
  inputButtonAriaLabel?: string;
  options?: Option<T>[];
  /** Pre-select the option from the options array if it's the only item in the array and value prop is empty. */
  autoSelect?: boolean;
  value: T;
  trackingName?: string;
  trackingLabel?: string;
  disabled?: boolean;
  withIcon?: boolean;
  actionOnChange?: (value: T | string) => void;
  actionOnComplete?: (value: T | string) => void;
  id: string;
  container?: HTMLElement | null;
  sortByKey?: boolean;
};

export const Select = memo(
  forwardRef(
    <T extends string = string>(
      props: SelectProps<T>,
      ref: React.ForwardedRef<unknown>,
    ): React.ReactElement<SelectProps<T>> | null => {
      const {
        classes: _, // unused, just don't want to pass along in rest
        className,
        compact,
        error,
        fullWidth = true,
        groupLabel,
        groupSubText,
        helperText,
        id,
        label,
        inputAriaLabel,
        inputButtonAriaLabel,
        name,
        options: optionsProp = emptyArray as unknown as Option<T>[],
        autoSelect,
        value: valueProp = '',
        trackingName,
        trackingLabel,
        disabled,
        inputRef,
        withIcon = false,
        onChange,
        actionOnChange,
        actionOnComplete,
        container,
        sortByKey,
        ...selectProps
      } = props;
      const { classes, cx } = useStyles(undefined, { props });

      const menuProps: Partial<MenuProps> = {
        classes: { root: classes.selectMenu, list: classes.list },
        PopoverClasses: { paper: classes.popover },
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        transformOrigin: { vertical: 'top', horizontal: 'left' },
        container,
      };

      const labelId = (name && `${name}-label`) || (id && `${id}-label`);

      const ariaLabelledbyValue = `${labelId || ''}${labelId && id ? ' ' : ''}${id || ''}`;

      // optionsProp won't default to emptyArray when null is passed, so doing it here.
      const options =
        sortByKey && optionsProp?.length
          ? getSortedOptions(optionsProp)
          : optionsProp ?? emptyArray;

      useEffect(() => {
        if (autoSelect && !valueProp && options.length === 1) actionOnChange?.(options[0].value);
      }, [actionOnChange, autoSelect, options, valueProp]);

      const handleBlur = useCallback<NonNullable<MuiSelectProps<T>['onBlur']>>(
        (event) => {
          if (!actionOnComplete) return;
          if (!isHTMLElementWithValue(event.target)) {
            const message = 'Not a valid event target for Select';
            datadogLog({
              logType: 'error',
              message,
              context: {
                logOrigin: 'libs/components/src/Select/Select.tsx',
                functionOrigin: 'handleBlur',
              },
            });
            throw new Error(message);
          }
          actionOnComplete(event.target.value);
        },
        [actionOnComplete],
      );

      const handleChange = useCallback<NonNullable<MuiSelectProps<T>['onChange']>>(
        (event) => {
          if (!actionOnChange) return;
          actionOnChange(event.target.value);
          onChange?.(event, null);
          if (trackingName) {
            trackClick({ action: trackingName, label: trackingLabel || event.target.value });
          }
        },
        [actionOnChange, onChange, trackingName, trackingLabel],
      );

      /**
       * Browser auto fill will fire the onChange event only when fill value match the select option value.
       * By default an onInput event will always fire on the embedded input element when browser autofill is triggered.
       */
      const handleAutoFill = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
        (event) => {
          const newValue = event.target.value;
          const selected = options.find(
            // dealing with case like {value: "STATE_CODE.AL", label: "AL"}
            ({ label }) => newValue === label,
          );
          const isClearForm = !newValue;
          if (isClearForm || selected) {
            event.preventDefault();
            event.stopPropagation();
          }
          if (isClearForm) actionOnChange?.(newValue);
          else if (selected) actionOnChange?.(selected?.value);
        },
        [actionOnChange, options],
      );

      return (
        <div className={cx(classes.root, className)}>
          {groupLabel && (
            <FormLabel
              component='legend'
              error={false}
              focused={false}
              className={classes.label}
              id={name && `${name}-label`}
            >
              {formatLabel(groupLabel, classes.textTertiary)}
            </FormLabel>
          )}
          {groupSubText && <p className={classes.subText}>{groupSubText}</p>}
          <FormControl
            className={classes.formControl}
            error={!!error}
            fullWidth={fullWidth}
            variant='outlined'
            role='group'
          >
            {label && (
              <InputLabel
                id={groupLabel ? undefined : labelId}
                htmlFor={`${id}Select`}
                aria-label={inputAriaLabel}
                disabled={disabled}
              >
                {formatLabel(label, classes.textTertiary)}
              </InputLabel>
            )}
            <MuiSelect<T>
              {...selectProps}
              disabled={disabled}
              className={className}
              classes={{ select: classes.control }}
              id={id}
              labelId={labelId}
              inputRef={inputRef || ref}
              IconComponent={IconUIExpandMore}
              name={name}
              value={valueProp}
              MenuProps={menuProps}
              onBlur={handleBlur}
              onChange={handleChange}
              SelectDisplayProps={{
                'aria-labelledby': ariaLabelledbyValue === '' ? undefined : ariaLabelledbyValue,
              }}
              displayEmpty
              inputProps={{
                id: `${id}Select`,
                classes: { root: classes.input, disabled: classes.fieldDisabled },
                onInput: handleAutoFill,
              }}
            >
              {options.map(({ label: optionLabel, value: optionValue }) => {
                return (
                  <MenuItem key={optionValue} value={optionValue} className={classes.option}>
                    {(withIcon || optionValue === valueProp) && (
                      <IconUICheck
                        className={cx(
                          classes.icon,
                          optionValue === valueProp && classes.iconSelected,
                        )}
                        data-testid='check-icon'
                      />
                    )}
                    {optionLabel}
                  </MenuItem>
                );
              })}
            </MuiSelect>
            {(error || helperText) && (
              <FormHelperText role='alert' data-testid='hidden-helper'>
                {error || helperText}
              </FormHelperText>
            )}
          </FormControl>
        </div>
      );
    },
  ),
) as <T extends string = string>(
  props: SelectProps<T>,
) => React.ReactElement<SelectProps<T>> | null;
