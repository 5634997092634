import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'NumTriesExceeded' })((theme) => ({
  root: {},
  label: {
    alignSelf: 'center',
  },
  secondaryLink: {
    fontWeight: 'bold',
    color: theme.palette.primary.dark,
  },
  information: {
    ...theme.typography.body3,
  },
  resetButton: {
    width: '100%',
    color: theme.palette.primary.light,
    textDecoration: 'none',
    cursor: 'pointer',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: { ...theme.mixins.setColorSvg(theme.palette.warning.main), width: 52, height: 52 },
  hours: theme.typography.body1,
  message: theme.typography.body3,
  phone: {
    ...theme.typography.body4Underline,
    color: theme.palette.text.link,
  },
}));
