import { useCallback } from 'react';

import { Divider, Stack } from '@mui/material';

import { flagValues } from '@ecp/utils/flags';

import { Button, PhoneLink } from '@ecp/components';
import type { ButtonProps } from '@ecp/components';
import { MODAL_FLOW, MODAL_STEP, useModalPath } from '@ecp/features/servicing/shared/routing';
import type { Document } from '@ecp/features/servicing/shared/state';
import {
  isInPendingCancelStatus,
  isLegacyAutoPolicy,
  useBillingAccount,
  useDownloadDocument,
} from '@ecp/features/servicing/shared/state';
import type { PolicySource, ProductLine } from '@ecp/features/servicing/shared/types';
import { IconCardAuto, IconUIDriver, IconUIEdit, IconUIID } from '@ecp/themes/base';

import { InfoCard } from '../InfoCard';
import { useStyles } from './VehicleDriverSummaryCard.styles';
interface Props {
  customerServicePhone?: string;
  description: string;
  isDriver?: boolean;
  poiDocument?: Document;
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  policySource?: PolicySource;
  policyNumber: string | undefined;
  productLine: ProductLine | undefined;
  vehicleId?: string;
}

export const VehicleDriverSummaryCard: React.FC<Props> = (props) => {
  const {
    customerServicePhone,
    description,
    poiDocument,
    isDriver = false,
    policySource,
    policyNumber,
    productLine,
    vehicleId,
  } = props;
  const { downloadDocument, isLoading: isDocLoading } = useDownloadDocument({
    policyDoc: poiDocument,
  });
  const { billingAccount } = useBillingAccount({ policyNumber });
  const isPendingCancelled = isInPendingCancelStatus({
    billingResponse: billingAccount,
    productLine,
    policySource,
  });

  const { classes } = useStyles(undefined, { props });
  const Icon = isDriver ? IconUIDriver : IconCardAuto;
  const isClassicDocsSupported = !flagValues.DISABLE_DOCUMENTS_CLASSIC;
  const isClassicAuto = isLegacyAutoPolicy('AUTO', policySource);
  const editVinFlag = flagValues.EDIT_VIN_CLASSIC;
  const modalPath = useModalPath();

  const handleEditVinClick = useCallback(() => {
    vehicleId &&
      modalPath.init(policyNumber ?? '', MODAL_FLOW.EDIT_VIN, MODAL_STEP.MODAL_EDIT, { vehicleId });
  }, [modalPath, policyNumber, vehicleId]);

  const buttons: ButtonProps[] = [];
  if (!isDriver) {
    if (!!poiDocument && (!isClassicAuto || (isClassicAuto && isClassicDocsSupported)))
      buttons.push({
        children: 'Get ID card',
        isProcessing: isDocLoading,
        onClick: downloadDocument,
        trackingName: 'get_id_link',
        trackingLabel: 'get_id',
        icon: <IconUIID className={classes.icon} />,
      });
    if (!isPendingCancelled && isClassicAuto && editVinFlag)
      buttons.push({
        children: 'Edit VIN',
        onClick: handleEditVinClick,
        icon: <IconUIEdit className={classes.icon} />,
      });
  }

  let body = buttons.length ? (
    <Stack spacing={1.5} divider={<Divider />}>
      {buttons.map((button, key) => {
        return (
          <Stack direction='row' spacing={0.5} key={key}>
            {button.icon}
            <Button
              variant='iconTextMedium'
              isProcessing={button.isProcessing}
              onClick={button.onClick}
              trackingName={button.trackingName}
              trackingLabel={button.trackingLabel}
              classes={{ root: classes.button }}
            >
              {button.children}
            </Button>
          </Stack>
        );
      })}
    </Stack>
  ) : undefined;

  if (isDriver && customerServicePhone) {
    body = (
      <>
        <span className={classes.deleteMessage}>To delete call</span>
        <PhoneLink
          number={customerServicePhone}
          withUnderlinedLinkStyle
          trackingName='delete_driver_phone_link'
          trackingLabel='delete_driver_phone_link_continue'
        />
        .
      </>
    );
  }

  return <InfoCard Icon={Icon} title={description} body={body} />;
};
