import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'Checkbox' })((theme) => ({
  root: {
    padding: 0,
  },
  icon: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[400],
    borderRadius: 3,
    backgroundColor: theme.palette.grey[50],
    'input:disabled ~ &': {
      borderColor: theme.palette.actions.disabledBg,
    },
  },
  iconVertical: {
    width: 24,
    height: 24,
  },
  iconHorizontal: {
    width: 15,
    height: 15,
  },
  iconChecked: theme.mixins.setColorSvg(theme.palette.primary.main),
}));
