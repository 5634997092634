import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PaymentsFormFooter' })((theme) => ({
  root: {},
  buttonGroup: {
    justifyContent: 'left',
  },
  submitButtton: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  backButton: {
    [theme.breakpoints.down('md')]: {
      minWidth: 80,
    },
  },
}));
