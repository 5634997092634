import { memo, useCallback, useEffect, useRef } from 'react';

import { debounce } from '@ecp/utils/common';

import { useIsMobile } from '@ecp/themes/base';

import { useStyles } from './AutoAdjustableGrid.styles';

export type Props = React.PropsWithChildren<
  JSX.IntrinsicElements['div'] & {
    spacing?: number;
    forceNumItemsPerRow?: number;
    className?: string;
  }
>;

export const AutoAdjustableGrid: React.FC<Props> = memo((props) => {
  const {
    spacing = 0,
    forceNumItemsPerRow: forceNumItemsPerRowProp,
    className,
    children,
    ...rest
  } = props;
  const { classes, cx } = useStyles();
  const ref = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();
  const forceNumItemsPerRow = forceNumItemsPerRowProp ? forceNumItemsPerRowProp : Number(isMobile);

  const autoAdjust = useCallback(() => {
    const container: HTMLDivElement | null = ref.current;
    if (!container || !container.parentElement) return;

    container.style.setProperty('--item-width', 'auto');

    const items = [...container.children] as HTMLElement[];
    const itemWidths = items.map((element) => element.offsetWidth + spacing);
    const containerWidth = container.parentElement.offsetWidth;
    const maxItemsWithinRow = forceNumItemsPerRow
      ? forceNumItemsPerRow
      : Math.floor((containerWidth + spacing) / Math.max(...itemWidths));

    let itemWidth = (containerWidth + spacing) / maxItemsWithinRow;
    if (maxItemsWithinRow > 1) itemWidth -= spacing;

    let itemWidthVar = maxItemsWithinRow > 1 ? `${itemWidth}px` : '100%';

    if (forceNumItemsPerRow) {
      itemWidthVar = `calc(${100 / forceNumItemsPerRow}% - ${
        (spacing * (forceNumItemsPerRow - 1)) / forceNumItemsPerRow
      }px)`;
      container.style.setProperty('width', '100%');
    }

    container.style.setProperty('--spacing', `${spacing}px`);
    container.style.setProperty('--max-items-per-row', `${maxItemsWithinRow}`);
    container.style.setProperty('--item-width', itemWidthVar);
  }, [forceNumItemsPerRow, spacing]);

  useEffect(() => {
    autoAdjust();
  }, [autoAdjust]);

  useEffect(() => {
    const debouncedAutoAdjust = debounce(autoAdjust, 500);
    if (ref.current) window.addEventListener('resize', debouncedAutoAdjust);

    return () => window.removeEventListener('resize', debouncedAutoAdjust);
  }, [autoAdjust]);

  return (
    <div
      className={cx(classes.root, className)}
      ref={ref}
      {...rest}
      data-testid='AutoAdjustableGrid'
    >
      {children}
    </div>
  );
});
