/* eslint-disable @typescript-eslint/no-explicit-any */
import { getChatEnabled } from './config';

/**
 * A wrapper function for window.Genesys. This function conditionally executes window.Genesys
 * based on the chat's enabled status.
 *
 * When the chat is disabled, the function performs a no-operation (no-op).
 * When the chat is enabled, it invokes window.Genesys with the provided arguments.
 *
 * @param args - Arguments to be passed to window.Genesys
 */
export const genesys = (...args: any[]): void => {
  const chatEnabled = getChatEnabled();

  if (!chatEnabled) {
    return;
  }

  return (window.Genesys as any)(...args);
};
