import { useCallback } from 'react';

import { Stack } from '@mui/material';

import type { TrackingProps } from '@ecp/utils/analytics/tracking';

import { Button } from '@ecp/components';
import type { ModalFlow, ModalStep } from '@ecp/features/servicing/shared/routing';
import { useModalPath } from '@ecp/features/servicing/shared/routing';
import { IconUIPlus } from '@ecp/themes/base';

import { useStyles } from './AddACard.styles';

interface AddACardProps {
  btnText: string;
  flow: ModalFlow;
  step: ModalStep;
  icon: React.ReactElement;
}

export const AddACard: React.FC<AddACardProps & TrackingProps> = (props) => {
  const { classes, cx } = useStyles();
  const { btnText, flow, step, icon, ...restTrackingProps } = props;
  const modalPath = useModalPath();
  const policyNumber = modalPath.policyNumber;
  const handleClick = useCallback((): void => {
    if (!policyNumber) {
      throw Error('policyNumber is undefined');
    }
    modalPath.init(policyNumber, flow, step);
  }, [policyNumber, flow, step, modalPath]);

  if (!policyNumber) return null;

  return (
    <div className={classes.root}>
      <Stack direction='row' spacing={1.5} className={classes.addLandlordCard}>
        <div className={classes.iconSection}>
          <div className={cx(classes.iconCircle, classes.icon)}>{icon}</div>
        </div>
        <Button
          className={classes.button}
          variant='iconText'
          onClick={handleClick}
          icon={<IconUIPlus />}
          {...restTrackingProps}
        >
          {btnText}
        </Button>
      </Stack>
    </div>
  );
};
