import { useCallback, useMemo } from 'react';

import { CircularProgress, Stack } from '@mui/material';

import { isTruthy, parseDollar } from '@ecp/utils/common';

import { Alert, PhoneLink } from '@ecp/components';
import { AutopayConfirmation } from '@ecp/features/servicing/autopay';
import type { OtherPoliciesEligibleProps } from '@ecp/features/servicing/shared/components';
import {
  CheckIcon,
  CloseButtonNAgentHelp,
  OtherPoliciesEligible,
} from '@ecp/features/servicing/shared/components';
import type { UseModalPathReturn as MakePaymentConfirmationProps } from '@ecp/features/servicing/shared/routing';
import { MODAL_FLOW, MODAL_STEP } from '@ecp/features/servicing/shared/routing';
import {
  getItemFromLoggedInSessionStorage,
  getPolicyDisplayType,
  usePaymentInfoForAllPolicies,
  usePolicy,
  useSharedState,
} from '@ecp/features/servicing/shared/state';
import type {
  AutopayInformation,
  MakePaymentClassicInformation,
  MakePaymentInformation,
} from '@ecp/features/servicing/shared/types';
import { getBrandData } from '@ecp/features/servicing/shared/util';

import { ConfettiComp } from '../Confetti';
import { useStyles } from './MakePaymentConfirmation.styles';
import metadata from './metadata';

export const MakePaymentConfirmation: React.FC<MakePaymentConfirmationProps> = (props) => {
  const { currentFlow, policyNumber, sharedStateKey, init } = props;
  const [sharedState] = useSharedState<MakePaymentInformation>(sharedStateKey);
  const [sharedStateAutopay] = useSharedState<AutopayInformation>(`autopayEnroll-${policyNumber}`);
  const [sharedStatePayClassic] = useSharedState<MakePaymentClassicInformation>(
    `payClassic-${policyNumber}`,
  );
  const { paymentInfos } = usePaymentInfoForAllPolicies({
    throwOnError: false,
  });
  const isAutopayError = sharedStateAutopay?.isError;
  const isAutopayReview = sharedState?.enrollAutopay;
  const isPayClassic = currentFlow === 'payclassic';

  const { classes } = useStyles();

  const { policyData: policyResponse, isLoading: isPolicyLoading } = usePolicy(policyNumber);
  const policyDisplayType = getPolicyDisplayType(policyResponse);
  const customerServicePhone = getBrandData(policyResponse).mainCustServPhoneNum;

  const otherPoliciesToPay = useMemo(
    () =>
      paymentInfos
        ?.filter(
          ({ canMakePayment, policyData }) =>
            canMakePayment && policyData?.policy.policyNumber !== policyNumber,
        )
        ?.filter(
          ({ policyData }) =>
            // payment already submitted
            !getItemFromLoggedInSessionStorage('payment', policyData?.policy.policyNumber),
        )
        ?.map((policyInfo) => policyInfo?.policyData)
        .filter(isTruthy),
    [paymentInfos, policyNumber],
  );
  const otherPoliciesToAutoPay = useMemo(
    () =>
      paymentInfos
        ?.filter(
          ({ isAutopayEligible, policyData }) =>
            isAutopayEligible && policyData?.policy.policyNumber !== policyNumber,
        )
        ?.map((policyInfo) => policyInfo?.policyData)
        .filter(isTruthy),
    [paymentInfos, policyNumber],
  );
  const handleOtherPayClick = useCallback<NonNullable<OtherPoliciesEligibleProps['onClick']>>(
    (policyNumber) =>
      init(
        policyNumber,
        !isPayClassic ? MODAL_FLOW.PAY : MODAL_FLOW.PAY_CLASSIC,
        MODAL_STEP.MODAL_EDIT,
      ),
    [init, isPayClassic],
  );

  const handleOtherAutopayClick = useCallback<NonNullable<OtherPoliciesEligibleProps['onClick']>>(
    (policyNumber) => init(policyNumber, MODAL_FLOW.AUTOPAY_ENROLL, MODAL_STEP.MODAL_EDIT),
    [init],
  );

  if (isPolicyLoading) return <CircularProgress aria-label='Loading...' />;

  return (
    <div className={classes.root}>
      {metadata.showConfetti && <ConfettiComp />}
      <Stack spacing={5}>
        <Stack spacing={3}>
          <CheckIcon />
          <h2>Thank you for your payment</h2>
          <Stack spacing={1.5}>
            <p>
              Your payment of <strong>{parseDollar(sharedState?.paymentAmount)}</strong> has been
              scheduled for <strong>{sharedState?.paymentDate}</strong> and applied to your{' '}
              <strong>
                {policyDisplayType} policy {policyNumber}
              </strong>
              .
            </p>
            {!isAutopayReview && !isPayClassic && (
              <p>
                If you made the payment on or before the payment due date, it will be received in
                time. Please be advised that it may take 3 business days for the payment to be
                processed by financial institutions.
              </p>
            )}
            {(isAutopayReview || isPayClassic) && (
              <p>Please allow up to 3 business days for the payment to be processed.</p>
            )}
            {isPayClassic && (
              <p>
                Your confirmation code is <strong>{sharedStatePayClassic?.confirmationId}.</strong>
              </p>
            )}
          </Stack>
        </Stack>
        {isAutopayReview && !isAutopayError && <AutopayConfirmation payEnrollConfirm {...props} />}
        {isAutopayError && (
          <Alert withIcon type='error'>
            There was an error enrolling your policy in AutoPay. Please try again later or call
            <PhoneLink number={customerServicePhone} /> to contact our customer care center.
          </Alert>
        )}
        <Stack spacing={4}>
          <OtherPoliciesEligible
            title='Other policies in need of payment'
            buttonText='Make payment'
            otherPolicies={otherPoliciesToPay}
            onClick={handleOtherPayClick}
            showDivider={!isPayClassic}
            isLinkClassic={isPayClassic}
          />
          {isAutopayReview && !isAutopayError && (
            <OtherPoliciesEligible
              title='Other policies that can be enrolled in AutoPay'
              buttonText='Enroll in AutoPay'
              otherPolicies={otherPoliciesToAutoPay}
              onClick={handleOtherAutopayClick}
            />
          )}
        </Stack>
        <CloseButtonNAgentHelp />
      </Stack>
    </div>
  );
};
