import { useCallback, useEffect, useMemo } from 'react';

import { Divider, Stack } from '@mui/material';

import { parseDollar } from '@ecp/utils/common';

import type { CardProps } from '@ecp/components';
import { Alert, PhoneLink } from '@ecp/components';
import { Link } from '@ecp/components';
import {
  LoadingOverlay,
  PaymentDetails,
  PolicyCard,
} from '@ecp/features/servicing/shared/components';
import { MODAL_FLOW, MODAL_STEP, useModalPath } from '@ecp/features/servicing/shared/routing';
import type { GetPaymentInfoReturn } from '@ecp/features/servicing/shared/state';
import {
  getBillingPlanDescription,
  getProductLineFromPolicyResponse,
  isLegacyPolicy,
  useAmountDueNDueDateInfo,
  useBillingAccount,
  usePaymentOptions,
} from '@ecp/features/servicing/shared/state';
import { getBrandData } from '@ecp/features/servicing/shared/util';

import { useStyles } from './AutopayEnrolledPolicyCard.styles';

interface AutopayEnrolledPolicyCardProps extends Omit<CardProps, 'divider' | 'header' | 'body'> {
  policyPaymentInfo: GetPaymentInfoReturn;
  isManageAutopay: boolean;
}

export const AutopayEnrolledPolicyCard: React.FC<AutopayEnrolledPolicyCardProps> = (props) => {
  const { policyPaymentInfo, isManageAutopay } = props;
  const { classes } = useStyles();
  const modalPath = useModalPath();

  const policyNumberInView = policyPaymentInfo?.policyData?.policy.policyNumber;
  const productLine = getProductLineFromPolicyResponse(policyPaymentInfo.policyData);
  const policySource = policyPaymentInfo.policyData?.policy.sourceSystemName;

  const {
    isLoading: isLoadingBilling,
    isFetching: isFetchingBilling,
    refetch: refetchBilling,
    isBillingAccountStatusInCancellation,
  } = useBillingAccount({
    policyNumber: policyNumberInView,
  });
  const {
    isLoading: isPaymentOptionsLoading,
    isFetching: isPaymentOptionsFetching,
    refetch: refetchPaymentOptions,
  } = usePaymentOptions(
    {
      billingAccountNumber: policyPaymentInfo?.policyData?.policy.billingAccountNumber,
      policySource,
    },
    isLegacyPolicy(productLine, policySource),
  );

  useEffect(() => {
    if (modalPath.currentFlow === 'autopaymanage') {
      refetchBilling?.();
      refetchPaymentOptions?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    paymentAmount,
    paymentAmountDescription,
    policyNumber,
    dueDate,
    dueDateDescription,
    isLoading: isLoadingAmountDueNDueDateInfo,
    isFetching: isFetchingAmountDueNDueDateInfo,
  } = useAmountDueNDueDateInfo({
    policyNumber: policyNumberInView,
    throwOnError: true,
    modalFlow: 'autopaymanage',
    modalStep: 'landing',
  });

  const paymentMethod = useMemo(() => {
    return policyPaymentInfo?.paymentOptions?.find(
      (paymentOption) => !!paymentOption.autopayEnrolled,
    );
  }, [policyPaymentInfo?.paymentOptions]);

  const handleEditClick = useCallback(() => {
    policyPaymentInfo.policyData?.policy.policyNumber &&
      modalPath.init(
        policyPaymentInfo.policyData.policy.policyNumber,
        MODAL_FLOW.AUTOPAY_MANAGE,
        MODAL_STEP.MODAL_EDIT,
      );
  }, [policyPaymentInfo.policyData?.policy.policyNumber, modalPath]);

  const handleUnenrollClick = useCallback(() => {
    policyNumberInView &&
      modalPath.init(policyNumberInView, MODAL_FLOW.AUTOPAY_UNENROLL, MODAL_STEP.MODAL_EDIT);
  }, [policyNumberInView, modalPath]);

  const policyCode = policyPaymentInfo.policyData?.coverageOptions.at(0)?.policyTypeCode;

  const customerServicePhone = getBrandData(policyPaymentInfo.policyData).mainCustServPhoneNum;

  const isLoading =
    isFetchingAmountDueNDueDateInfo ||
    isLoadingAmountDueNDueDateInfo ||
    isLoadingBilling ||
    isPaymentOptionsLoading ||
    isFetchingBilling ||
    isPaymentOptionsFetching;
  if (isLoading) return <LoadingOverlay />;

  const billingPlan = policyPaymentInfo.billingAccount?.billingPlan;
  const shouldDisplayCallAlert =
    modalPath.currentFlow === 'autopaymanage' &&
    productLine === 'HOME' &&
    billingPlan !== 'TWO-PAY PLAN' &&
    isBillingAccountStatusInCancellation;

  return (
    <Stack spacing={2} divider={<Divider />} className={classes.root}>
      {shouldDisplayCallAlert ? (
        <PolicyCard
          classes={{ body: classes.body }}
          policyDetails={policyPaymentInfo.policyData}
          body={
            <Alert type='info' withIcon className={classes.alert}>
              <p>
                Call <PhoneLink number={customerServicePhone} /> to manage the AutoPay details for
                this policy.
              </p>
            </Alert>
          }
        />
      ) : (
        <PaymentDetails
          key={policyNumber}
          paymentAmount={paymentAmount ? parseDollar(paymentAmount) : paymentAmountDescription}
          billingPlan={getBillingPlanDescription(billingPlan)}
          nextPaymentDate={dueDate}
          nextPaymentDateDescription={dueDateDescription}
          policyResponse={policyPaymentInfo.policyData}
          paymentAccountMethod={paymentMethod}
          footer={
            <div className={classes.cardFooter}>
              <Stack spacing={2}>
                <Link className={classes.link} component='button' onClick={handleEditClick}>
                  Edit AutoPay details
                </Link>
                {policyCode !== 'HO4' ? (
                  <Link className={classes.link} component='button' onClick={handleUnenrollClick}>
                    Unenroll from AutoPay
                  </Link>
                ) : (
                  <p className={classes.unenrollText}>
                    Unenroll from AutoPay is unavailable for this policy right now. Call{' '}
                    <PhoneLink number={customerServicePhone} /> to speak with an agent.
                  </p>
                )}
              </Stack>
            </div>
          }
          isLoading={isLoading}
          isManageAutopay={isManageAutopay}
        />
      )}
    </Stack>
  );
};
