import { NavLink as ReactRouterNavLink } from 'react-router-dom';

import { withAnalytics } from '@ecp/utils/analytics/tracking';

import { useStyles } from './NavLink.styles';

interface Props extends Omit<React.ComponentProps<typeof ReactRouterNavLink>, 'className'> {
  className?: string;
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
}

// can move to shared components once sales upgrades to use react-router v6
export const NavLink = withAnalytics<Props>((props) => {
  const { className } = props;
  const { classes, cx } = useStyles(undefined, { props });

  return <ReactRouterNavLink {...props} className={cx(classes.root, className)} />;
}, 'link');
