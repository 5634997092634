import { useCallback, useMemo, useState } from 'react';

import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';

import { Link, Select } from '@ecp/components';
import { usePageFlow } from '@ecp/features/servicing/shared/routing';
import { IconUIArrowLeft } from '@ecp/themes/base';

import { useStyles } from './Breadcrumbs.styles';
import type { BreadcrumbTitleOverrides } from './useBreadcrumbs';
import { useBreadcrumbs } from './useBreadcrumbs';

interface Props<T extends string = string> {
  dropdownList?: T[];
  breadcrumbTitleOverrides?: BreadcrumbTitleOverrides;
}

export const Breadcrumbs = <T extends string = string>(
  props: Props<T>,
): React.ReactElement<Props<T>> => {
  const { dropdownList, breadcrumbTitleOverrides } = props;
  const { opco } = useParams();
  const { classes, cx } = useStyles();
  const { pathname } = useLocation();
  const pageFlow = usePageFlow();
  const { breadcrumbs } = useBreadcrumbs(
    pathname
      .split('/')
      .filter((part) => part !== opco)
      .join('/'),
    breadcrumbTitleOverrides,
  );

  /*
    Handle a breadcrumb being clicked
    */
  const handleLinkClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      const url = event.currentTarget.dataset.url; // URL of breadcrumb
      if (!url) throw new Error('data-url not set');
      pageFlow.navigate(url);
    },
    [pageFlow],
  );

  const dropdownOptions = useMemo(() => {
    return dropdownList?.map((option) => {
      return { value: option, label: option };
    });
  }, [dropdownList]);

  // Dropdown value
  const [dropdownValue, setValue] = useState(dropdownOptions?.[0].value || ''); // select first option initially
  const handleDropdownChange = useCallback(
    (value: string) => setValue(value?.toString()),
    [setValue],
  );

  return (
    <div className={classes.root}>
      <MuiBreadcrumbs
        separator={
          <p className={cx(classes.separator, dropdownOptions && classes.dropdownSeparator)}>/</p>
        } // If no dropdown, then don't show separator on mobile
      >
        {/* Extra separator for breadcrumbs with dropdown */}
        {dropdownOptions ? <div /> : null}

        {breadcrumbs.map((breadcrumb, index) => {
          return index === breadcrumbs.length - 1 ? (
            dropdownOptions ? (
              // If this is the last breadcrumb and we have dropdown options, show them
              <Select
                className={classes.select}
                key={index}
                value={dropdownValue}
                options={dropdownOptions}
                actionOnChange={handleDropdownChange}
                name={breadcrumb.title}
                id={breadcrumb.title}
              />
            ) : (
              // If we do not have dropdown options, just show the last page's name (unless mobile size)
              <p className={classes.title} key={index}>
                {breadcrumb.title}
              </p>
            )
          ) : (
            // Provide a link for normal breadcrumbs
            <Link
              key={index}
              component='button'
              data-url={breadcrumb.url}
              id={breadcrumb.title}
              onClick={handleLinkClick}
              className={cx(
                classes.link,
                index === breadcrumbs.length - 2 && classes.previousPageLink,
              )} // On mobile, just show the second to last breadcrumb
            >
              {!dropdownOptions && <IconUIArrowLeft className={classes.icon} />}
              {breadcrumb.title}
            </Link>
          );
        })}
      </MuiBreadcrumbs>
    </div>
  );
};
