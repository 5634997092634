import { useCallback } from 'react';

import type { LinkProps } from '@ecp/components';
import { Link } from '@ecp/components';
import { IconUIArrowRight } from '@ecp/themes/base';

import { useStyles } from './OtherPolicyLink.styles';

export interface OtherPolicyLinkProps extends LinkProps {
  linkText: string | undefined;
  policyNumber: string | undefined;
  actionOnClick?: (policyNumber: string) => void;
}

export const OtherPolicyLink: React.FC<OtherPolicyLinkProps> = (props) => {
  const { linkText, policyNumber, onClick, actionOnClick, ...rest } = props;
  if (!policyNumber) {
    throw Error('policyNumber is undefined');
  }
  const { classes } = useStyles();

  const handleClick = useCallback<NonNullable<LinkProps['onClick']>>(
    (event) => {
      onClick?.(event);
      actionOnClick?.(policyNumber);
    },
    [policyNumber, onClick, actionOnClick],
  );

  return (
    <Link
      component='button'
      className={classes.root}
      {...rest}
      onClick={handleClick}
      trackingDimensions={{ active_policy_number: policyNumber }}
    >
      {linkText} {policyNumber}
      <IconUIArrowRight className={classes.arrowIcon} />
    </Link>
  );
};
