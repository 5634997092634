import type { PolicyDescription, PolicyTypeCode } from '@ecp/features/servicing/shared/types';

export const productPolicyDescriptionMap: Record<PolicyTypeCode, PolicyDescription> = {
  HO6: 'Condo',
  HO3: 'Home',
  HO2: 'Michigan home',
  HO4: 'Renters',
  HF9: 'Second Home',
  RL8: 'Tenant liability',
  '': '',
};
