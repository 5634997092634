import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AdditionalInterestCard' })((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    width: 'max-content',
  },
  icon: {
    ...theme.mixins.setColorSvg(theme.palette.secondary.main),
    width: 24,
    height: 24,
  },
  titleIcon: {
    width: 42,
    height: 42,
  },
}));
