import { Divider } from '@mui/material';

import { LegalTerms } from '../LegalTerms';
import { SiteWideLinks } from '../SiteWideLinks';
import { useStyles } from './ServicingFooter.styles';
interface Props {
  isLoggedIn: boolean;
}

export const ServicingFooter: React.FC<Props> = (props) => {
  const { classes } = useStyles();
  const { isLoggedIn } = props;

  return (
    <footer role='contentinfo' className={classes.root}>
      {isLoggedIn && (
        <>
          <div className={classes.contentWrapper}>
            <SiteWideLinks />
          </div>
          <Divider variant='fullWidth' className={classes.divider} />
        </>
      )}
      <div className={classes.contentWrapper}>
        <LegalTerms />
      </div>
    </footer>
  );
};
