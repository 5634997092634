import * as yup from 'yup';

import type { YupSchemaMap } from '@ecp/utils/form';

import { makePaymentConsentSchema } from '@ecp/features/servicing/shared/util';

export interface MakePaymentFormInputs {
  paymentAmount?: string;
  consent?: string;
}

export const makePaymentClassicSchema: YupSchemaMap<MakePaymentFormInputs> = {
  ...makePaymentConsentSchema,
  paymentAmount: yup.string(),
};
