import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'LoginLink' })((theme) => ({
  root: {
    '& svg, &': {
      height: 24,
      width: 24,
    },
  },
}));
