import { useCallback, useState } from 'react';

import { Stack } from '@mui/material';
import type { Location } from 'react-router-dom';
import { useLocation, useSearchParams } from 'react-router-dom';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { Form, useField, useForm } from '@ecp/utils/form';
import { localStorage } from '@ecp/utils/storage';

import { Alert, Switch, TextField } from '@ecp/components';
import {
  AuthenticationCard,
  LoadingButton,
  NavLink,
} from '@ecp/features/servicing/shared/components';
import { PAGE_PATH, QUERY_PARAMS, usePageFlow } from '@ecp/features/servicing/shared/routing';
import { localStorageKeys, useLogin } from '@ecp/features/servicing/shared/state';
import type { LoginUserRequest } from '@ecp/features/servicing/shared/state';

import { InvalidLogin, NumTriesExceeded, RecoveryLinks } from '../../components';
import type { LoginFormInputs } from './Login.schema';
import { loginSchema } from './Login.schema';
import { useStyles } from './LoginPage.styles';

export interface PropState {
  from: Location;
}

export const LoginPage: React.FC = () => {
  const pageFlow = usePageFlow();
  const { classes } = useStyles();
  const formContext = useForm({
    validations: loginSchema,
  });
  const [searchParams] = useSearchParams();
  const [errorAlert, setErrorAlert] = useState(!!searchParams.get(QUERY_PARAMS.TIMEOUT));
  const { login } = useLogin();
  const { handleSubmit, setValue } = formContext;

  const rememberMeEmail = (localStorage.getItem(localStorageKeys.emailStorageKey) ?? '') as string;

  const emailField = useField({
    name: 'email',
    formContext,
    defaultValue: rememberMeEmail,
  });
  const passwordField = useField({
    name: 'password',
    formContext,
    defaultValue: '',
  });
  const isRememberOnDeviceField = useField({
    name: 'isRememberOnDevice',
    formContext,
    defaultValue: !!rememberMeEmail,
  });

  // error display logic
  const [errorType, setErrorType] = useState('');

  const location = useLocation();
  const locationFrom = location.state as PropState;

  const onSubmit = useCallback(() => {
    handleSubmit(async (data) => {
      const { email, password, isRememberOnDevice } = data as Required<LoginFormInputs>;
      const loginUserRequest: LoginUserRequest = { email, password };
      const { error, success } = await login(loginUserRequest);

      if (error === 'invalid_credentials') {
        setErrorType('invalid_credentials');
        formContext.setError('email', {
          message: 'Invalid log in credentials',
        });
        formContext.setError('password', {
          message: 'Invalid log in credentials',
        });
      } else if (error === 'locked_account') {
        setErrorType('locked_account');
      } else if (success) {
        if (isRememberOnDevice) {
          localStorage.setItem(localStorageKeys.emailStorageKey, email);
        } else {
          localStorage.removeItem(localStorageKeys.emailStorageKey);
        }

        pageFlow.navigate(locationFrom?.from ?? PAGE_PATH.DASHBOARD_INDEX);
      }
    })();
  }, [handleSubmit, login, locationFrom, pageFlow, setErrorType, formContext]);

  const handleErrorAlertClose = useCallback(() => {
    setErrorAlert(false);
    pageFlow.navigate(PAGE_PATH.LOGIN);
  }, [pageFlow]);

  const handleEmailFieldChange = useCallback(
    (value: string) => setValue('email', value.trim()),
    [setValue],
  );

  const message = (
    <>
      The email and password you entered are incorrect. For your security, your account has been
      locked.{' '}
      <NavLink
        to={PAGE_PATH.RECOVER_PASSWORD}
        className={classes.navlink}
        trackingName='reset_account_link'
        trackingLabel='reset_account_continue'
      >
        Recover your account here
      </NavLink>{' '}
      to unlock it.
    </>
  );

  return (
    <AuthenticationCard>
      {errorType === 'locked_account' ? (
        <NumTriesExceeded
          title='Account locked'
          message={message}
          subMessage='If you need help now, please contact us at the number below.'
        />
      ) : (
        <Form
          onSubmit={onSubmit}
          formProviderProps={formContext}
          className={classes.root}
          name='loginForm'
        >
          <Stack spacing={4}>
            {errorAlert && (
              <Alert withIcon type='warning' withAction onClose={handleErrorAlertClose}>
                Your session has expired
              </Alert>
            )}
            <Stack spacing={1.5}>
              <h1>Log in</h1>
              <p>
                First time here?{' '}
                <NavLink
                  to={PAGE_PATH.CREATE_ACCOUNT}
                  className={classes.navlink}
                  trackingName='create_account_link'
                  trackingLabel='create_account_continue'
                >
                  Create an account
                </NavLink>
              </p>
            </Stack>
            {errorType === 'invalid_credentials' && <InvalidLogin title='Invalid log in' />}
            <TextField
              {...emailField}
              className={classes.input}
              actionOnChange={handleEmailFieldChange}
              label='Email'
              trackingName='email_address'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
            />
            <TextField
              {...passwordField}
              className={classes.input}
              label='Password'
              type='password'
              trackingName='password'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
            />
            <Switch
              {...isRememberOnDeviceField}
              checked={isRememberOnDeviceField.value}
              label='Remember me on this device'
              trackingName='remember_me_button'
            />
            <LoadingButton
              type='submit'
              className={classes.loginButton}
              trackingName='login_button'
              trackingLabel='Log In'
            >
              LOG IN
            </LoadingButton>
            {!errorType && (
              <p>
                <RecoveryLinks />
              </p>
            )}
          </Stack>
        </Form>
      )}
    </AuthenticationCard>
  );
};
