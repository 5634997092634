import { forwardRef } from 'react';

import { useField } from '@ecp/utils/form';

import { TextField } from '@ecp/components';
import type { TextFieldProps } from '@ecp/components';

import { useStyles } from './ElectronicSignatureField.styles';

export interface Props extends TextFieldProps {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
}

export const ElectronicSignatureField: React.FC<Props> = forwardRef((props, ref) => {
  const { classes } = useStyles(undefined, { props });

  const electronicSigField = useField({
    name: 'electronicSignature',
  });

  return (
    <TextField
      {...props}
      maxLength={65}
      className={classes.root}
      label={props.label ?? 'Electronic signature'}
      {...electronicSigField}
      ref={ref}
      placeholder='Full name'
      helperText='Please type your full name. This information serves as your legal signature & acknowledges the information provided on this page.'
    />
  );
});
