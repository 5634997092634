import { Alert, PhoneLink } from '@ecp/components';

interface Props {
  isPendingCancellation: boolean | undefined;
  isEscrow: boolean | undefined;
  helpNumber?: string;
  isClassicBillingAccountStatusInPendingCancel?: boolean;
}

export const PaymentAlert: React.FC<Props> = (props) => {
  const {
    isPendingCancellation,
    isEscrow,
    helpNumber = '',
    isClassicBillingAccountStatusInPendingCancel = false,
  } = props;

  const alertText =
    isPendingCancellation && isClassicBillingAccountStatusInPendingCancel ? (
      <p>
        <b>Payment past due.</b> Policy will be canceled if payment is not received. Please call
        <PhoneLink number={helpNumber} /> to arrange payment.
      </p>
    ) : isPendingCancellation ? (
      <p>
        <b>Payment past due.</b> Policy changes disabled until payment is received. 'Make Payment'
        below or call{' '}
        <PhoneLink
          number={helpNumber}
          trackingLabel='phone_number'
          trackingName='payment_past_due_phone_number_link'
        />{' '}
        for help.
      </p>
    ) : isEscrow ? (
      <p>
        <b>Your policy is at risk of being canceled.</b> Make a payment today or contact your
        mortgage company to arrange payment.
      </p>
    ) : (
      'Payment is past due.'
    );

  return (
    <Alert type='warning' withIcon>
      {alertText}
    </Alert>
  );
};
