import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'TextField' })((theme) => ({
  root: {
    ...theme.typography.body4,
    textAlign: 'inherit',
    '& fieldset legend button': { display: 'none' },
  },
  formControl: {
    'input[type=password]::-ms-reveal,input[type=password]::-ms-clear': {
      display: 'none',
    },
  },
  input: {
    ...theme.typography.body1,
    color: theme.palette.text.tertiary,
    backgroundColor: theme.palette.grey[50],
    borderColor: theme.palette.grey[600],
  },
  iconAdornment: {
    color: theme.palette.primary.main,
  },
  icon: {
    maxWidth: 24,
    ...theme.mixins.setColorSvg(theme.palette.primary.main),
  },
  label: {
    paddingBottom: 15,
  },
  textTertiary: {
    color: theme.palette.text.tertiary,
  },
  fieldDisabled: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.primary,
  },
  inputPlaceholderDefault: {
    color: theme.palette.text.tertiary,
    '&::placeholder': {
      opacity: 1,
    },
  },
}));
