import { PhoneLink } from '@ecp/components';
import type { ServicingModalProps } from '@ecp/features/servicing/shared/components';
import { ServicingModal } from '@ecp/features/servicing/shared/components';

import { useStyles } from './BookRollInfoModal.styles';

interface Props extends Omit<ServicingModalProps, 'body' | 'buttonText' | 'title'> {
  number: string;
}

export const BookRollInfoModal: React.FC<Props> = (props) => {
  const { number, open, onClose, onButtonClick } = props;
  const { classes } = useStyles();

  const bookRollModalBody = (
    <div className={classes.bookRollModalBody}>
      <p>You'll be receiving a packet in the mail that includes the following:</p>
      <ul className={classes.bookRollModalList}>
        <li>Your updated policy and declaration page, including your new policy number</li>
        <li>An overview of your new coverages</li>
        <li>A billing statement and an explanation of available payment options</li>
      </ul>
      <p>
        For help, please call us at <PhoneLink withUnderlinedLinkStyle number={number} />.
      </p>
    </div>
  );

  return (
    <ServicingModal
      title='What you need to know'
      open={open}
      onClose={onClose}
      body={bookRollModalBody}
      onButtonClick={onButtonClick}
      buttonText='CLOSE'
      classes={{
        titleBarCloseButton: classes.titleBarCloseButton,
        titleBar: classes.titleBar,
      }}
      servicingModalClasses={{
        button: classes.bookRollModalButton,
        container: classes.bookRollModalContainer,
        footer: classes.bookRollFooter,
      }}
    />
  );
};
