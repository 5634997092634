import { useCallback } from 'react';

import { ErrorBoundary } from 'react-error-boundary';

import { flagValues } from '@ecp/utils/flags';

import { AutopayReview } from '@ecp/features/servicing/autopay';
import {
  AddCcPaymentMethod,
  AddEftPaymentMethod,
  ApiAlert,
  FullScreenModal,
} from '@ecp/features/servicing/shared/components';
import { MODAL_FLOW, MODAL_STEP, useModalPath } from '@ecp/features/servicing/shared/routing';
import {
  getProductLineFromPolicyResponse,
  isLegacyPolicy,
  usePolicy,
  useSharedState,
} from '@ecp/features/servicing/shared/state';
import type {
  AutopayInformation,
  MakePaymentInformation,
} from '@ecp/features/servicing/shared/types';

import { MakePaymentConfirmation, MakePaymentEdit, MakePaymentReview } from '../../components';
import { PaymentModalDisabledBody } from './PaymentModalDisabledBody';

const PaymentModalFallback: React.FC = () => <ApiAlert apiDescription='payments information' />;

const title = 'Make a payment';

const PaymentModalDisabledContent: React.FC = () => {
  const modalPath = useModalPath();

  return (
    <FullScreenModal title={title} onCloseModal={modalPath.reset}>
      <ErrorBoundary FallbackComponent={PaymentModalFallback}>
        <PaymentModalDisabledBody />
      </ErrorBoundary>
    </FullScreenModal>
  );
};

const PaymentModalContent: React.FC = () => {
  const modalPath = useModalPath();

  const [sharedState] = useSharedState<MakePaymentInformation>(modalPath.sharedStateKey);
  const [sharedStateAutopay] = useSharedState<AutopayInformation>(
    `autopayEnroll-${modalPath.policyNumber}`,
  );

  const ensureSharedState = useCallback(() => {
    // ensure if we are expecting shared state, but we don't have it, we reset to start of flow
    if (!sharedState && modalPath.policyNumber) {
      modalPath.init(modalPath.policyNumber, 'pay', 'landing');
    }

    return !!sharedState;
  }, [modalPath, sharedState]);

  const ensureSharedStateAutopay = useCallback(() => {
    // ensure if we are expecting shared state, but we don't have it, we reset to start of flow
    if (!sharedStateAutopay && modalPath.policyNumber) {
      modalPath.init(modalPath.policyNumber, 'pay', 'landing');
    }

    return !!sharedStateAutopay;
  }, [modalPath, sharedStateAutopay]);

  const handleClickBack = useCallback(() => {
    modalPath.currentStep === 'landing' ? modalPath.reset() : modalPath.goBack();
  }, [modalPath]);

  let isBackButton = false;
  let body = null;

  switch (modalPath.currentStep) {
    case MODAL_STEP.MODAL_EDIT:
      body = <MakePaymentEdit {...modalPath} />;
      break;
    case MODAL_STEP.MODAL_REVIEW:
      body = ensureSharedState() ? <MakePaymentReview {...modalPath} /> : null;
      break;
    case MODAL_STEP.MODAL_CONFIRMATION:
      body = ensureSharedState() ? <MakePaymentConfirmation {...modalPath} /> : null;
      break;
    case MODAL_STEP.ADD_EFT_ACCOUNT:
      isBackButton = true;
      body = <AddEftPaymentMethod {...modalPath} />;
      break;
    case MODAL_STEP.ADD_CC_ACCOUNT:
      isBackButton = true;
      body = <AddCcPaymentMethod {...modalPath} />;
      break;
    case MODAL_STEP.PAY_ENROLL_REVIEW:
      body =
        ensureSharedState() && ensureSharedStateAutopay() ? <AutopayReview {...modalPath} /> : null;
      break;
    default:
      body = <MakePaymentEdit {...modalPath} />;
  }

  return (
    <FullScreenModal
      title={title}
      isBackButton={isBackButton}
      onClickBack={handleClickBack}
      onCloseModal={modalPath.reset}
    >
      <ErrorBoundary FallbackComponent={PaymentModalFallback}>{body}</ErrorBoundary>
    </FullScreenModal>
  );
};

export const PaymentModalIfEligible: React.FC = () => {
  const modalPath = useModalPath();

  const policyResponse = usePolicy(modalPath.policyNumber, { throwOnError: false });
  const productLine = getProductLineFromPolicyResponse(policyResponse.policyData);
  const isClassic = isLegacyPolicy(productLine, policyResponse.policyData?.policy.sourceSystemName);

  if (
    !flagValues.DISABLE_PAYMENTS &&
    modalPath.currentFlow === MODAL_FLOW.PAY &&
    !!modalPath.policyNumber &&
    !isClassic
  )
    return <PaymentModalContent />;
  else if (flagValues.DISABLE_PAYMENTS && !!modalPath.policyNumber && !isClassic)
    return <PaymentModalDisabledContent />;
  else return null;
};

export const PaymentModal: React.FC = () => {
  const modalPath = useModalPath();

  if (modalPath.currentFlow === MODAL_FLOW.PAY) return <PaymentModalIfEligible />;
  else return null;
};
