import { Stack } from '@mui/material';

import { Button, PhoneLink } from '@ecp/components';
import { usePolicy } from '@ecp/features/servicing/shared/state';
import { getBrandData } from '@ecp/features/servicing/shared/util';
import { GraphicErrorGenericErrorLandscape } from '@ecp/themes/base';

import { useStyles } from './ModalErrorFallback.styles';

export type ModalErrorFallbackProps = {
  reset: () => void;
  errorDescription: string;
  policyNumber?: string;
};

export const ModalErrorFallback: React.ComponentType<ModalErrorFallbackProps> = (props) => {
  const { reset, errorDescription, policyNumber } = props;
  const { classes } = useStyles();
  const { policyData } = usePolicy(policyNumber);
  const customerServicePhoneNumber = getBrandData(policyData).mainCustServPhoneNum;

  return (
    <Stack spacing={3} justifyContent='center'>
      <GraphicErrorGenericErrorLandscape className={classes.image} />
      <h2>Something went wrong</h2>
      <p className={classes.text}>{errorDescription}</p>
      {!customerServicePhoneNumber || !policyNumber ? (
        <p className={classes.text}>Please try again later.</p>
      ) : (
        <p>
          For help, please call{' '}
          <PhoneLink withUnderlinedLinkStyle number={customerServicePhoneNumber} />.
        </p>
      )}
      <Button className={classes.button} variant='primary' onClick={reset}>
        CLOSE
      </Button>
    </Stack>
  );
};
