import * as interactionId from '@ecp/utils/analytics/interaction-id';
import { uuid } from '@ecp/utils/common';
import type { TypedResponse } from '@ecp/utils/network';
import { Headers, typedFetch } from '@ecp/utils/network';

import type { ServicingResponseBase } from '@ecp/types';

import { userAuth } from '.';
import type {
  AuthConfig,
  AuthnRequest,
  AuthnResponse,
  AuthTokenRequest,
  FetchTokensResponse,
  FetchTokensResponseBody,
} from './types';

export const authn = async (
  request: AuthnRequest,
  { afeApiKey, authBaseUrl, sourceId, ...rest }: AuthConfig,
): Promise<TypedResponse<ServicingResponseBase<AuthnResponse>>> => {
  const traceId = uuid();

  return typedFetch<ServicingResponseBase<AuthnResponse>>(
    `${authBaseUrl}/ent-customer-auth/v1/users/authn`,
    {
      headers: {
        'Content-Type': 'application/json',
        [Headers.API_KEY]: afeApiKey,
        ...(rest?.expId && { [Headers.EXP_ID]: rest.expId }),
        ...(rest?.partnerId && { [Headers.PARTNER_ID]: rest.partnerId }),
        [Headers.SESSION_ID]: interactionId.get(),
        [Headers.TRACE_ID]: traceId,
        ...(sourceId && { [Headers.SOURCE_ID]: sourceId }),
      },
      method: 'POST',
      body: JSON.stringify(request),
    },
  );
};

export const logout = async ({
  afeApiKey,
  authBaseUrl,
  sourceId,
  ...rest
}: AuthConfig): Promise<TypedResponse<ServicingResponseBase<boolean>>> => {
  const traceId = uuid();

  const userTokens = await userAuth.token;

  return typedFetch<ServicingResponseBase<boolean>>(
    `${authBaseUrl}/ent-customer-auth/v1/users/logout`,
    {
      headers: {
        'Content-Type': 'application/json',
        [Headers.API_KEY]: afeApiKey,
        ...(rest?.expId && { [Headers.EXP_ID]: rest.expId }),
        ...(rest?.partnerId && { [Headers.PARTNER_ID]: rest.partnerId }),
        [Headers.SESSION_ID]: interactionId.get(),
        [Headers.TRACE_ID]: traceId,
        ...(sourceId && { [Headers.SOURCE_ID]: sourceId }),
        Authorization: `Bearer ${userTokens?.accessToken}`,
      },
      method: 'POST',
    },
  );
};

export const token = async (
  uri: string,
  authTokenRequest: AuthTokenRequest,
): Promise<FetchTokensResponse> => {
  const urlParams = new URLSearchParams();
  Object.entries(authTokenRequest).forEach(([key, value]) => urlParams.append(key, value));

  return typedFetch<FetchTokensResponseBody>(uri, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    method: 'POST',
    body: urlParams,
  });
};
