import type { ChipProps } from '@mui/material';

import type { BookRolledPolicy } from '@ecp/features/servicing/shared/state';
import type { UiPolicy } from '@ecp/features/servicing/shared/types';

interface Props {
  convertedFromClassic: BookRolledPolicy | undefined;
  isClassic: boolean;
  isPendingCancelStatus: boolean;
  policyDetails: UiPolicy | undefined;
  showInactiveAlert: boolean;
}
export const getChipProps = (props: Props): ChipProps | undefined => {
  const {
    convertedFromClassic,
    isClassic,
    isPendingCancelStatus,
    policyDetails,
    showInactiveAlert,
  } = props;

  if (isPendingCancelStatus) {
    return { label: 'CANCELLATION PENDING' };
  }
  if (showInactiveAlert) {
    return { label: 'CANCELED', color: 'error' };
  }
  if (isClassic && policyDetails?.isInactive) {
    return { label: 'INACTIVE' };
  }
  if (!!convertedFromClassic && policyDetails?.policy.status === 'Scheduled') {
    return { label: 'COMING SOON' };
  }

  return undefined;
};
