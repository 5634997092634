import type { /* CamelCasedPropertiesDeep, */ Constructor, ReadonlyDeep } from 'type-fest';

import { isEqual, transform } from './lodash';
import { lowerFirst } from './string';

/** Immutable empty object. Use it as a default immutable value instead of undefined and null where an object is expected.
 *  It is helpful to prevent unnecessary re-renders when e.g. a default value for React component prop needs to be an object. */
export const emptyObject = Object.freeze({});

export const isKeyOf = <T extends string | number | symbol, U extends Record<string, unknown>>(
  key: T,
  object: U,
): key is T & keyof U => key in object;

export const isType = <T>(obj: unknown, klass: Constructor<T>): obj is T => obj instanceof klass;

export const deepFreeze = <T>(obj: T): ReadonlyDeep<T> => {
  if (obj === null || typeof obj !== 'object') return obj as ReadonlyDeep<T>;
  // Retrieve the keys defined on object
  const keys = Object.getOwnPropertyNames(obj) as unknown as Array<keyof T>;

  // Recursively freeze keys before freezing self
  for (const key of keys) {
    const value = obj[key];
    if (typeof value === 'object') {
      deepFreeze(value);
    }
  }

  return Object.freeze(obj) as ReadonlyDeep<T>;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const deepDiff = <T extends {}, U extends {}>(obj: T, base: U): Partial<T & U> => {
  return transform(obj, (result, value, key) => {
    const objKey = key as unknown as keyof U;
    if (!isEqual(value, base[objKey])) {
      // @ts-ignore Typing this one is not straight-forward
      result[objKey] =
        isType(value, Object) && isType(base[objKey], Object)
          ? deepDiff(value, base[objKey] as unknown as Record<string, unknown>)
          : value;
    }
  });
};

// TODO Try typing return type correctly e.g.
// T extends Record<string, unknown>
// ? CamelCasedPropertiesDeep<T>
// : T extends any[]
// ? CamelCasedPropertiesDeep<T[number]>[]
// : T
// eslint-disable-next-line @typescript-eslint/ban-types
export const camelizeKeys = <T extends {} = {}>(obj: T): T => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (Array.isArray(obj)) return (obj as any).map(camelizeKeys);

  if (isType(obj, Object))
    return Object.keys(obj).reduce((acc, key) => {
      const camelizedKey = lowerFirst(key);
      // @ts-ignore Typing this one is not straight-forward
      acc[camelizedKey] = camelizeKeys(obj[key]);

      return acc;
      // }, {} as CamelCasedPropertiesDeep<T>);
    }, {} as T);

  return obj;
};
