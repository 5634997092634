import { useCallback } from 'react';

import { type ObjectType } from '@ecp/utils/analytics/tracking';

import type { ButtonVariant } from '@ecp/components';
import { Button } from '@ecp/components';
import { useDownloadDocument } from '@ecp/features/servicing/shared/state';
import type { Document } from '@ecp/features/servicing/shared/state';

import { useStyles } from './DocumentLink.styles';

export interface DocumentLinkProps {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  document?: Document;
  policyNumber?: string;
  documentTitle?: string;
  variant?: ButtonVariant;
  trackingName?: string;
  trackingLabel?: string;
  objectType?: ObjectType;
  idPostfix?: string;
}

const getNormalizedString = (input: string | undefined): string =>
  input ? input.replace(/\s+/g, '_').toLowerCase() : '';

export const DocumentLink: React.FC<DocumentLinkProps> = (props) => {
  const {
    document,
    policyNumber,
    documentTitle: documentTitleProp,
    variant = 'outlinePrimary',
    trackingName: trackingNameProp,
    trackingLabel: trackingLabelProp,
    objectType = 'button',
    idPostfix = '',
  } = props;
  const { classes } = useStyles(undefined, { props });
  const { downloadDocument, isLoading } = useDownloadDocument({
    policyDoc: document,
    policyNumber,
  });

  const handleDocumentClick: React.ComponentProps<typeof Button>['onClick'] = useCallback(
    () => downloadDocument(),
    [downloadDocument],
  );

  const documentTitle =
    documentTitleProp ?? document?.documentTitle ?? 'Request proof of insurance';

  const trackingName =
    trackingNameProp ??
    (policyNumber
      ? 'request_proof_of_insurance_link'
      : `${getNormalizedString(documentTitle)}_link`);
  const trackingLabel =
    trackingLabelProp ??
    (policyNumber ? 'request_proof_of_insurance' : `${getNormalizedString(documentTitle)}`);

  return (
    <Button
      id={document ? `${document?.documentId}${idPostfix}` : 'POI'}
      variant={variant}
      className={classes.root}
      onClick={handleDocumentClick}
      isProcessing={isLoading}
      trackingName={trackingName}
      trackingLabel={trackingLabel}
      trackingDimensions={{ active_policy_number: policyNumber }}
      objectType={objectType}
    >
      {documentTitle}
    </Button>
  );
};
