import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'EditVinConfirmation' })((theme) => ({
  root: {},
  successIcon: {
    ...theme.mixins.setColorSvg(theme.palette.success.main),
    height: 64,
    width: 64,
  },
  successIconWrapper: {
    textAlign: 'center',
  },
  subHeader: theme.typography.body1Bold,
  listContent: theme.typography.body1,
  closeButton: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 'fit-content',
    },
  },
  list: { paddingInlineStart: 20 },
}));
