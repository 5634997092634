import { useParams } from 'react-router-dom';

import { UnderwritingHomesite } from './homesite';

export const UnderwritingPage: React.FC = () => {
  const { opco } = useParams();

  let underwriting;
  switch (opco) {
    case 'homesite':
      underwriting = <UnderwritingHomesite />;
      break;
    default:
      underwriting = <p>Partner Specific Underwriting.</p>;
  }

  return underwriting;
};
