import { useMemo } from 'react';

import { matchPath, useLocation } from 'react-router-dom';

import type { TrackingProps } from '@ecp/utils/analytics/tracking';

import { PAGE_MAP, PAGE_PATH } from '@ecp/features/servicing/shared/routing';
import {
  compareProductLine,
  getPolicyDisplayType,
  useUserPolicies,
} from '@ecp/features/servicing/shared/state';
import { generateEncodedPath } from '@ecp/features/servicing/shared/util';
import { IconUILogout, IconUIPerson } from '@ecp/themes/base';

type MenuItemStaticConfig<T extends string = keyof typeof PAGE_MAP> = {
  icon?: React.ReactElement;
  items?: MenuItemConfig[];
  title: T;
  url: string;
} & TrackingProps;

export interface MenuItemConfig extends MenuItemStaticConfig<string> {
  url: string;
}

export interface UseMenuItemsReturn {
  activeItem?: MenuItemConfig;
  activeSubItem?: MenuItemConfig;
  primaryItems: MenuItemConfig[];
  secondaryItems: Readonly<MenuItemConfig[]>;
}

const menuStaticConfig: Readonly<MenuItemStaticConfig[]> = [
  {
    title: 'Overview',
    url: PAGE_PATH.DASHBOARD_INDEX,
    trackingName: 'overview_link',
    trackingLabel: 'overview',
  },
  {
    title: 'Policies',
    url: PAGE_PATH.DASHBOARD_POLICYDETAILS,
    trackingName: 'policies_link',
    trackingLabel: 'policies',
  },
  {
    title: 'Claims',
    url: PAGE_PATH.DASHBOARD_CLAIMS_OVERVIEW,
    trackingName: 'claims_link',
    trackingLabel: 'claims',
  },
  {
    title: 'Help & support',
    url: PAGE_PATH.FAQ,
    trackingName: 'help_and_support_link',
    trackingLabel: 'help_support',
  },
];

const secondaryMenuConfigs: Readonly<MenuItemConfig[]> = [
  {
    title: 'My profile',
    url: PAGE_PATH.PROFILE,
    icon: <IconUIPerson />,
    trackingName: 'profile_icon_link',
    trackingLabel: 'profile',
    objectType: 'link',
  },
  {
    title: 'Log out',
    url: PAGE_PATH.LOGOUT,
    icon: <IconUILogout />,
    trackingName: 'logout_link',
    trackingLabel: 'logout',
  },
];

export function getBestMatchForPath<T>(
  objs: T[],
  pathname: string,
  getUrl: (obj: T) => string,
): T | undefined {
  const match =
    objs.find((obj) => matchPath(getUrl(obj), pathname)) ??
    objs
      // filter out those that don't match any parts
      .filter((obj) => !!pathname.match(new RegExp(getUrl(obj))))
      // sort matches and take longest match (should be best) (i.e. `/paymenthistory/123` instead of `/paymenthistory`)
      .sort((a, b) => {
        const matchA = pathname.match(new RegExp(getUrl(a)))?.[0] as string;
        const matchB = pathname.match(new RegExp(getUrl(b)))?.[0] as string;

        return matchA?.localeCompare(matchB);
      })
      .pop();

  return match;
}

export const useMenuItems = (): UseMenuItemsReturn => {
  const { pathname } = useLocation();
  const { policies } = useUserPolicies({
    ignoreUserAuth: true,
    throwOnPolicyError: false,
    throwOnUserError: false,
  });

  const primaryItems = useMemo(() => {
    const sortedPolicies = policies
      .filter((policy) => !policy.isInactive)
      .sort((a, b) => a.policy.policyNumber.localeCompare(b.policy.policyNumber))
      .sort((a, b) => compareProductLine(a, b))
      .slice(0, 10);
    const items = menuStaticConfig
      .map<MenuItemConfig>((item) => {
        const url = item.url ?? item.items?.at(0)?.url;
        if (!url) throw Error(`Cannot find url for menu item [${item.title}]`);

        if (item.title === 'Policies') {
          // add in policies/sub items dynamically
          item.items = [];
          sortedPolicies.forEach((policy) => {
            const policyNumber = policy.policy.policyNumber;
            const policyType = getPolicyDisplayType(policy);
            item.items?.push({
              title: `${policyType} (...${policyNumber.substring(policyNumber.length - 4)})`,
              url: generateEncodedPath(PAGE_PATH.DASHBOARD_POLICYDETAILS, {
                policyNumber,
              }),
              trackingName: 'policy_details_link',
              trackingLabel: 'policy_details',
              trackingDimensions: { active_policy_number: policyNumber },
              objectType: 'link',
            });
          });
        }

        return { ...item, url };
      })
      // filter out if there are no policies/submenu items (while loading or when only ineligible policies)
      .filter((item) => !item.items || !!item.items.length);

    return items;
  }, [policies]);

  const activeMainTitle = useMemo(() => {
    const activePage =
      (Object.keys(PAGE_MAP) as (keyof typeof PAGE_MAP)[]).find((pageKey) =>
        getBestMatchForPath(Object.values(PAGE_MAP[pageKey]), pathname, (url) => url),
      ) || primaryItems[0].title;

    return activePage;
  }, [pathname, primaryItems]);

  const activeItem = [...primaryItems, ...secondaryMenuConfigs].find(
    (link) => link.title === activeMainTitle,
  );
  const activeSubItem = useMemo(
    () =>
      activeItem?.items
        ? getBestMatchForPath(activeItem?.items, pathname, (item) => item.url)
        : undefined,
    [activeItem?.items, pathname],
  );

  // update items to ensure updated urls
  const activeUrl = activeItem?.url ?? activeSubItem?.url;
  const updatedActiveItem =
    activeItem?.title && activeUrl ? { ...activeItem, url: activeUrl } : undefined;

  return {
    activeItem: updatedActiveItem,
    activeSubItem,
    primaryItems,
    secondaryItems: secondaryMenuConfigs,
  };
};
