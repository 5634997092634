import { emptyObject } from '@ecp/utils/common';

import type { Location } from './types';
import { useSearch } from './wouter';

/**
 * Returns an object of parsed query string params https://developer.mozilla.org/en-US/docs/Web/API/Location/search.
 *
 * NOTE: Don't use this function in routing-related logic outside of the routing library.
 * Instead, use `location.search` to read and `navigate({ search: { foo: 'bar' } })` to navigate.
 * However, use it as a utility in e.g. API-related logic.
 */
export const parseSearchParams = <T extends Location['search']>(
  searchParams?: string | undefined | null,
): { [key in keyof T]: T[key] } => {
  if (!searchParams) return emptyObject as { [key in keyof T]: T[key] };

  return Object.fromEntries(new URLSearchParams(searchParams)) as { [key in keyof T]: T[key] };
};

/**
 * NOTE: Don't use this function in routing-related logic outside of the routing library.
 * Instead, use `location.search` to read and `navigate({ search: { foo: 'bar' } })` to navigate.
 * However, use it as a utility in e.g. API-related logic.
 */
export const stringifySearchParams = (
  searchParams?: Location['search'] | string | undefined | null,
): `?${string}` | '' => {
  if (!searchParams) return '';

  const urlSearchParams = new URLSearchParams(
    searchParams as Record<string, string> | string,
  ).toString();
  if (!urlSearchParams) return '';

  return `?${urlSearchParams}`;
};

/**
 * Returns an object of parsed query string params https://developer.mozilla.org/en-US/docs/Web/API/Location/search.
 *
 * This is re-export of useSearch from 'wouter/use-location' wrapped in a parser.
 *
 * @see https://github.com/molefrog/wouter/releases/tag/v2.10.0
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useSearchParams = <T extends Location['search']>() =>
  parseSearchParams<T>(useSearch());
