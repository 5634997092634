import { useEvent } from '@ecp/utils/react';

import type { LinkProps } from '@ecp/components';
import { Link } from '@ecp/components';
import { PAGE_PATH, usePageFlow } from '@ecp/features/servicing/shared/routing';
import { generateEncodedPath } from '@ecp/features/servicing/shared/util';

export const ReviewDocumentsLink: React.FC<
  LinkProps & { policyNumber: string; linkText?: string }
> = (props) => {
  const {
    policyNumber,
    trackingLabel,
    trackingName,
    linkText = 'review your policy documents',
  } = props;
  const { navigate } = usePageFlow();
  const handleClick = useEvent(() => {
    navigate(generateEncodedPath(PAGE_PATH.DASHBOARD_POLICY_DOCUMENTS, { policyNumber }));
  });

  return (
    <Link
      component='button'
      onClick={handleClick}
      trackingLabel={trackingLabel}
      trackingName={trackingName}
    >
      {linkText}
    </Link>
  );
};
