import { useCallback } from 'react';

import { Stack } from '@mui/material';

import { parseDollar } from '@ecp/utils/common';
import { DateConstants, formatDate } from '@ecp/utils/date';
import { Form, useForm } from '@ecp/utils/form';

import { env } from '@ecp/env';
import { PaymentsFormFooter, ReviewPolicyDetails } from '@ecp/features/servicing/shared/components';
import type { UseModalPathReturn as MakePaymentReviewProps } from '@ecp/features/servicing/shared/routing';
import { MODAL_STEP } from '@ecp/features/servicing/shared/routing';
import {
  getPolicyDisplayType,
  getProductLineFromPolicyResponse,
  saveInLoggedInSessionStorage,
  useBillingDetails,
  useMakePaymentWithExistingMethod,
  usePolicy,
  useSharedState,
} from '@ecp/features/servicing/shared/state';
import type { MakePaymentInformation } from '@ecp/features/servicing/shared/types';
import { makePaymentConsentSchema } from '@ecp/features/servicing/shared/util';

import { LegalCopy } from './components/LegalCopy';
import { useStyles } from './MakePaymentReview.styles';

export const MakePaymentReview: React.FC<MakePaymentReviewProps> = (props) => {
  const { goForward, goBack, goTo, sharedStateKey, policyNumber } = props;

  if (!policyNumber) {
    throw new Error('Cannot call payment api without policyNumber');
  }

  const { classes } = useStyles();
  const [sharedState, setSharedState] = useSharedState<MakePaymentInformation>(sharedStateKey);

  const { makePayment } = useMakePaymentWithExistingMethod();

  const { policyData: policyResponse } = usePolicy(policyNumber);
  const policySource = policyResponse?.policy?.sourceSystemName;
  const productLine = getProductLineFromPolicyResponse(policyResponse);
  const policyDisplayType = getPolicyDisplayType(policyResponse);

  const { refetch } = useBillingDetails({
    billingAccountIds: policyResponse?.policy?.billingAccountNumber
      ? [policyResponse.policy.billingAccountNumber]
      : undefined,
  });

  const formContext = useForm({
    validations: makePaymentConsentSchema,
  });
  const { handleSubmit } = formContext;
  const onSubmit = useCallback(() => {
    handleSubmit(async () => {
      try {
        const todaysDateAndTime = new Date();
        setSharedState({
          ...(sharedState as MakePaymentInformation),
          paymentDate: formatDate(
            todaysDateAndTime.toString(),
            DateConstants.MONTH_FULL_NAME_LONG_FORMAT,
          ),
        });
        const response = await makePayment({
          policyNumber,
          policySource,
          savedPaymentMethod: {
            paymentAmount: sharedState?.paymentAmount,
            paymentMethodId: sharedState?.paymentMethodId,
            paymentMethodType: sharedState?.paymentAccountMethod?.type,
          },
          auditInfo: {
            requestedDateTime: todaysDateAndTime.toISOString(),
            requestedBy: policyNumber,
            requestingSystem: env.static.sourceId,
          },
        });
        if (response) {
          setSharedState({ ...(sharedState as MakePaymentInformation), isSuccess: true });
          saveInLoggedInSessionStorage('payment', policyNumber, response.amount);
          refetch(policyResponse?.policy.billingAccountNumber);
          sharedState?.enrollAutopay ? goTo(MODAL_STEP.PAY_ENROLL_REVIEW) : goForward();
        }
      } catch (error) {
        setSharedState({ ...(sharedState as MakePaymentInformation), isError: true });
        goBack();
      }
    })();
  }, [
    policyNumber,
    policySource,
    sharedState,
    policyResponse?.policy.billingAccountNumber,
    handleSubmit,
    goForward,
    goBack,
    goTo,
    refetch,
    makePayment,
    setSharedState,
  ]);

  const paymentMethodValue =
    sharedState?.paymentAccountMethod?.type === 'CC'
      ? sharedState?.paymentAccountMethod?.cardType
      : 'Bank account';

  let paymentMethodHelperText = '';
  if (sharedState?.paymentAccountMethod?.type === 'CC')
    paymentMethodHelperText = `(...${sharedState?.paymentAccountMethod?.ccToken})`;
  else if (sharedState?.paymentAccountMethod?.type === 'EFT')
    paymentMethodHelperText = `(...${sharedState?.paymentAccountMethod?.accountNumber})`;

  return (
    <Form
      id='makePaymentForm'
      formProviderProps={formContext}
      onSubmit={onSubmit}
      className={classes.root}
    >
      <Stack className={classes.root} spacing={3}>
        <h2>Review payment details</h2>

        <Stack className={classes.payInfoSection} spacing={2}>
          <ReviewPolicyDetails
            name='Policy'
            helperText={policyNumber}
            value={policyDisplayType ?? ''}
          />
          <ReviewPolicyDetails
            name='Payment method'
            helperText={paymentMethodHelperText}
            value={paymentMethodValue}
          />
          <ReviewPolicyDetails
            name='Payment amount'
            helperText={sharedState?.dueOrFull}
            value={parseDollar(sharedState?.paymentAmount)}
          />
          <ReviewPolicyDetails
            name='Payment date'
            value={formatDate(sharedState?.paymentDate, DateConstants.MONTH_FULL_NAME_LONG_FORMAT)}
          />
        </Stack>
        {!!productLine && <LegalCopy productLine={productLine} />}
        <PaymentsFormFooter
          buttonText={sharedState?.enrollAutopay ? 'PAY AND CONTINUE' : 'PAY NOW'}
          onBackButtonClick={goBack}
          checkBoxConfirmation
          trackingName='pay_now_button'
          trackingLabel='review_continue'
        />
      </Stack>
    </Form>
  );
};
