import { getBrandDataWithFallback } from '@ecp/features/servicing/shared/util';

export const helpTextMetadata = {
  dob: 'This should be the date of birth of the primary insured for your policy. The primary insured is the person who is the main beneficiary of the policy. They are typically the person who applied for and purchased the policy.',
  lastName:
    'This should be the last name of the primary insured for your policy. The primary insured is the person who is the main beneficiary of the policy. They are typically the person who applied for and purchased the policy.',
  policyNumber:
    'If you have multiple policies with us, you may use any of them to set up your account.',
  contactPhone: getBrandDataWithFallback().mainCustServPhoneNum,
} as const;
