import { useState } from 'react';

import { Stack } from '@mui/material';

import {
  AccordionTitle,
  DocumentTable,
  LoadingOverlay,
  ServicingAccordion,
} from '@ecp/features/servicing/shared/components';
import type { Document } from '@ecp/features/servicing/shared/state';
import {
  billingDocuments,
  classicAutoBillingDocuments,
  classicAutoPolicyDocuments,
  classicAutoRenewalDocuments,
  classicHomeBillingDocuments,
  classicHomePolicyDocuments,
  classicHomeRenewalDocuments,
  policyDocuments,
  renewalDocuments,
  usePolicy,
} from '@ecp/features/servicing/shared/state';
import { isPoiDoc, useDecodedParams } from '@ecp/features/servicing/shared/util';

import { isVehicleIdDoc } from '../../util';
import { useStyles } from './DocumentAccordionGroup.styles';

interface DocumentAccordionGroupProps {
  allDocuments: Document[];
  isLegacy: boolean;
  isHomeProduct: boolean;
}

const normalizeTitle = (title: string): string =>
  title
    .toLowerCase()
    .replace(' ', '')
    .replace(/[\s0-9]+$/, '');

const isMatchDoc = (titles: string[], doc: Document): boolean => {
  return titles.map(normalizeTitle).includes(normalizeTitle(doc.documentTitle));
};

const getMatch = (titles: string[]) => (doc: Document) => isMatchDoc(titles, doc);

export const DocumentAccordionGroup: React.FC<DocumentAccordionGroupProps> = (props) => {
  const { allDocuments, isLegacy, isHomeProduct } = props;
  const [expanded, setExpanded] = useState<string | false>(false);
  const { classes, cx } = useStyles();
  const { policyNumber = '' } = useDecodedParams();

  const { policyData, isLoading: isLoadingPolicy } = usePolicy(policyNumber, {
    throwOnError: false,
  });

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Filter any id card which does not belong to the policy anymore
  const filterInvalidIds = (allDocuments: Document[]): Document[] =>
    allDocuments.filter((doc) => {
      if (doc.documentTitle.indexOf('Identification Card-') < 0) return doc;
      else {
        let isValidPOI = false;
        policyData?.vehicles?.forEach((vehicle) => {
          isValidPOI = isPoiDoc(doc.documentTitle, vehicle);
        });
        if (isValidPOI) return doc;
        else {
          return undefined;
        }
      }
    });

  let policyDocs;
  if (isHomeProduct) {
    policyDocs = allDocuments.filter(
      getMatch(isLegacy ? classicHomePolicyDocuments : policyDocuments),
    );
  } else {
    policyDocs = allDocuments.filter(
      (document) => isMatchDoc(classicAutoPolicyDocuments, document) || isVehicleIdDoc(document),
    );
  }
  const filteredPolicyDocs = isHomeProduct ? policyDocs : filterInvalidIds(policyDocs);

  let billingTitles = billingDocuments;
  if (isLegacy) {
    billingTitles = isHomeProduct ? classicHomeBillingDocuments : classicAutoBillingDocuments;
  }
  const billingDocs = allDocuments.filter(getMatch(billingTitles));

  let renewalTitles = renewalDocuments;
  if (isLegacy) {
    renewalTitles = isHomeProduct ? classicHomeRenewalDocuments : classicAutoRenewalDocuments;
  }
  const renewalDocs = allDocuments.filter(getMatch(renewalTitles));

  const bucketDocs = [...filteredPolicyDocs, ...billingDocs, ...renewalDocs];

  const otherDocs = allDocuments.filter((doc) => !bucketDocs.includes(doc));
  const filteredOtherDocs = isHomeProduct ? otherDocs : filterInvalidIds(otherDocs);

  if (isLoadingPolicy) return <LoadingOverlay />;

  return (
    <Stack>
      <ServicingAccordion
        trackingName='policy_documents_accordion'
        id='Policy'
        accordionTitle={<AccordionTitle documentDataList={filteredPolicyDocs} title='Policy' />}
        accordionDetails={
          <DocumentTable
            documentDataList={filteredPolicyDocs}
            hideTableHeadings
            classes={{ root: classes.docTable }}
            addDivider
          />
        }
        key='Policy'
        onChange={handleChange('Policy')}
        expanded={expanded === 'Policy'}
        hasDetailContent={filteredPolicyDocs.length > 0}
        classes={{ root: classes.accordionPadding }}
      />
      <ServicingAccordion
        trackingName='billing_documents_accordion'
        id='Billing'
        accordionTitle={<AccordionTitle documentDataList={billingDocs} title='Billing' />}
        accordionDetails={
          <DocumentTable
            documentDataList={billingDocs}
            hideTableHeadings
            classes={{ root: classes.docTable }}
            addDivider
          />
        }
        key='Billing'
        onChange={handleChange('Billing')}
        expanded={expanded === 'Billing'}
        hasDetailContent={billingDocs.length > 0}
        classes={{
          root: cx(classes.accordionPadding, expanded === 'Policy' ? undefined : classes.accordion),
        }}
      />
      <ServicingAccordion
        trackingName='renewal_documents_accordion'
        id='Renewal'
        accordionTitle={<AccordionTitle documentDataList={renewalDocs} title='Renewal' />}
        accordionDetails={
          <DocumentTable
            documentDataList={renewalDocs}
            hideTableHeadings
            classes={{ root: classes.docTable }}
            addDivider
          />
        }
        key='Renewal'
        onChange={handleChange('Renewal')}
        expanded={expanded === 'Renewal'}
        hasDetailContent={renewalDocs.length > 0}
        classes={{
          root: cx(
            classes.accordionPadding,
            expanded === 'Billing' ? undefined : classes.accordion,
          ),
        }}
      />
      <ServicingAccordion
        trackingName='other_documents_accordion'
        id='Other'
        accordionTitle={<AccordionTitle documentDataList={filteredOtherDocs} title='Other' />}
        accordionDetails={
          <DocumentTable
            documentDataList={filteredOtherDocs}
            hideTableHeadings
            classes={{ root: classes.docTable }}
            addDivider
          />
        }
        key='Other'
        onChange={handleChange('Other')}
        expanded={expanded === 'Other'}
        hasDetailContent={filteredOtherDocs.length > 0}
        classes={{
          root: cx(
            classes.accordionPadding,
            expanded === 'Renewal' ? undefined : classes.accordion,
          ),
        }}
      />
    </Stack>
  );
};
