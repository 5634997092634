import { QUERY_KEYS } from '../constants';
import type { ApiRequestOptions } from '../servicingRequest';
import { getPolicyDocuments } from './api';
import type { Documents } from './types';

export const getPolicyDocumentKey = (
  policyNumber: string | undefined,
  documentId?: string | undefined,
): Array<unknown> => [
  QUERY_KEYS.POLICY_DOCUMENT,
  policyNumber,
  documentId === '' ? 'EOI' : documentId,
];

export const getPolicyDocumentsKey = (policyNumber: string): Array<unknown> => [
  QUERY_KEYS.POLICY_DOCUMENTS,
  policyNumber,
];

export const getPolicyDocumentsQuery = async (
  policyNumber: string,
  apiRequestOptions?: ApiRequestOptions,
): Promise<Documents> =>
  (await getPolicyDocuments({ requestNumber: policyNumber }, apiRequestOptions)).payload;
