import { useLinkClickHandler } from 'react-router-dom';

import type { LinkProps } from '@ecp/components';
import { Link } from '@ecp/components';
import { PAGE_PATH } from '@ecp/features/servicing/shared/routing';
import { generateEncodedPath } from '@ecp/features/servicing/shared/util';

import { useStyles } from './DocumentSection.styles';

export const SeeAllDocumentsLink: React.FC<LinkProps & { policyNumber: string }> = (props) => {
  const { policyNumber, ...rest } = props;
  const { classes } = useStyles();
  const handleClick = useLinkClickHandler(
    generateEncodedPath(PAGE_PATH.DASHBOARD_POLICY_DOCUMENTS, { policyNumber }),
  );

  return (
    <Link
      component='button'
      className={classes.link}
      onClick={handleClick}
      trackingName='see_all_documents_link'
      trackingLabel='dashboarddocuments_continue'
      {...rest}
    >
      See all documents
    </Link>
  );
};
