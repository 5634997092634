import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<void, 'expandable' | 'item' | 'submenu' | 'submenuOpen'>({
  name: 'MobileNavItem',
})((...[theme, , classes]) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    padding: 0,
  },
  expandable: { flexDirection: 'column' },
  expandIcon: { height: 16, width: 16 },
  expandIconMore: { transform: 'rotate(-90deg)' },
  icon: { height: 22, width: 22 },
  item: {
    ...theme.typography.body3,
    ...theme.mixins.setColorSvg(theme.palette.text.link),
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.other.divider}`,
    color: theme.palette.text.primary,
    display: 'flex',
    padding: theme.spacing(2, 3),
    textDecoration: 'none',
    width: '100%',
    '&:hover': {
      ...theme.mixins.setColorSvg(theme.palette.text.primary),
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },
    [`.${classes.expandable} &`]: theme.mixins.setColorSvg(theme.palette.text.primary),
    [`.${classes.expandable}.${classes.submenuOpen} &`]: { borderBottom: 'none' },
  },
  itemActive: {
    ...theme.typography.body3Bold,
    backgroundColor: theme.palette.primary.main,
    '&, &:hover': {
      ...theme.mixins.setColorSvg(theme.palette.common.white),
      color: theme.palette.common.white,
      textDecoration: 'none',
    },
    [`.${classes.expandable} &`]: {
      ...theme.typography.body3,
      backgroundColor: 'unset',
      color: theme.palette.text.primary,
      '&:hover': theme.mixins.setColorSvg(theme.palette.text.primary),
    },
    [`.${classes.expandable} .${classes.submenu} &`]: {
      ...theme.typography.body3Bold,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  submenu: {
    borderBottom: `1px solid ${theme.palette.other.divider}`,
    width: '100%',
  },
  submenuList: {
    padding: theme.spacing(0, 0, 1.5),
    [`& .${classes.item}`]: {
      padding: theme.spacing(1.5, 1.5, 1.5, 5),
      borderBottom: 'none',
    },
  },
  submenuOpen: {},
}));
