import { useCallback, useState } from 'react';

import { ErrorBoundary } from 'react-error-boundary';

import { flagValues } from '@ecp/utils/flags';

import {
  AddCcPaymentMethod,
  AddEftPaymentMethod,
  FullScreenModal,
  LoadingOverlay,
  ModalErrorFallback,
} from '@ecp/features/servicing/shared/components';
import type { ModalFlow } from '@ecp/features/servicing/shared/routing';
import { MODAL_FLOW, MODAL_STEP, useModalPath } from '@ecp/features/servicing/shared/routing';
import {
  getProductLineFromPolicyResponse,
  isLegacyPolicy,
  useAmountDueNDueDateInfo,
  usePolicy,
  useSharedState,
} from '@ecp/features/servicing/shared/state';

import { AutopayClassicConfirmation } from '../AutopayClassicConfirmation';
import { AutopayConfirmation } from '../AutopayConfirmation';
import { AutopayEdit } from '../AutopayEdit';
import { AutopayEditClassic } from '../AutopayEdit';
import { AutopayLanding } from '../AutopayLanding';
import { AutopayReview, AutopayReviewClassic } from '../AutopayReview';
import { AutopayUnenrollReview } from '../AutopayUnenrollReview';

const AutopayModalFallback: React.FC = () => {
  const modalPath = useModalPath();

  return (
    <ModalErrorFallback
      errorDescription='Your AutoPay information is unavailable at this time.'
      reset={modalPath.reset}
      policyNumber={modalPath.policyNumber}
    />
  );
};

interface Props {
  isClassic?: boolean;
}

const AutopayModalContent: React.FC<Props> = (props) => {
  const { isClassic = false } = props;
  const modalPath = useModalPath();
  const { isError: isErrorAmountDueNDueDateInfo, isLoading } = useAmountDueNDueDateInfo({
    policyNumber: modalPath.policyNumber,
    modalFlow: modalPath.currentFlow,
    modalStep: modalPath.currentStep,
    throwOnError: false,
  });

  const [sharedState] = useSharedState<unknown>(modalPath.sharedStateKey);
  const [isError, setIsError] = useState(false);
  const ensureSharedState = useCallback(() => {
    if (modalPath.currentFlow === 'autopayunenroll') return true;
    // ensure if we are expecting shared state, but we don't have it, we reset to start of flow
    if (!sharedState && modalPath.policyNumber) {
      if (modalPath.currentFlow === 'autopayenroll')
        modalPath.init(modalPath.policyNumber, 'autopayenroll', 'edit');
      else modalPath.init(modalPath.policyNumber, 'autopaymanage', 'landing');
    }

    return !!sharedState;
  }, [modalPath, sharedState]);

  let isBackButton = false;
  const title =
    isError || isErrorAmountDueNDueDateInfo
      ? 'AutoPay'
      : modalPath.currentFlow === 'autopayenroll'
      ? 'Enroll in AutoPay'
      : 'Manage AutoPay';

  const ConfirmationScreenComponent = !isClassic ? AutopayConfirmation : AutopayClassicConfirmation;
  let body = null;
  if (isLoading) {
    body = <LoadingOverlay />;
  } else if (isErrorAmountDueNDueDateInfo) {
    body = <AutopayModalFallback />;
  } else {
    switch (modalPath.currentStep) {
      case MODAL_STEP.MODAL_LANDING:
        body = <AutopayLanding {...modalPath} />;
        break;
      case MODAL_STEP.MODAL_EDIT:
        body = isClassic ? <AutopayEditClassic {...modalPath} /> : <AutopayEdit {...modalPath} />;
        break;
      case MODAL_STEP.MODAL_REVIEW:
        if (modalPath.currentFlow === MODAL_FLOW.AUTOPAY_UNENROLL) {
          body = <AutopayUnenrollReview {...modalPath} />;
        } else {
          body = ensureSharedState() ? (
            isClassic ? (
              <AutopayReviewClassic {...modalPath} />
            ) : (
              <AutopayReview {...modalPath} />
            )
          ) : null;
        }
        break;
      case MODAL_STEP.MODAL_CONFIRMATION:
        body = ensureSharedState() ? <ConfirmationScreenComponent {...modalPath} /> : null;
        break;
      case MODAL_STEP.ADD_EFT_ACCOUNT:
        isBackButton = true;
        body = <AddEftPaymentMethod {...modalPath} />;
        break;
      case MODAL_STEP.ADD_CC_ACCOUNT:
        isBackButton = true;
        body = <AddCcPaymentMethod {...modalPath} />;
        break;
    }
  }

  const handleError = useCallback(() => {
    setIsError(true);
  }, [setIsError]);

  const handleClickBack = useCallback(() => {
    modalPath.currentStep === 'landing' ? modalPath.reset() : modalPath.goBack();
  }, [modalPath]);

  return (
    <FullScreenModal
      title={title}
      isBackButton={isBackButton}
      onClickBack={handleClickBack}
      onCloseModal={modalPath.reset}
    >
      <ErrorBoundary FallbackComponent={AutopayModalFallback} onError={handleError}>
        {body}
      </ErrorBoundary>
    </FullScreenModal>
  );
};

const AutopayModalIfEligible: React.FC = () => {
  const modalPath = useModalPath();
  const policyResponse = usePolicy(modalPath.policyNumber, { throwOnError: false });
  const productLine = getProductLineFromPolicyResponse(policyResponse.policyData);
  const isClassic = isLegacyPolicy(productLine, policyResponse.policyData?.policy.sourceSystemName);

  if (
    (modalPath.currentFlow === 'autopaymanage' || modalPath.currentFlow === 'autopayunenroll') &&
    isClassic
  )
    return null;
  else if (
    ((!isClassic && !flagValues.DISABLE_AUTOPAY) || (isClassic && flagValues.AUTOPAY_CLASSIC)) &&
    !!modalPath.policyNumber
  )
    return <AutopayModalContent isClassic={isClassic} />;
  else return null;
};

export const AutopayModal: React.FC = () => {
  const modalPath = useModalPath();
  const autopayFlows: ModalFlow[] = ['autopayenroll', 'autopaymanage', 'autopayunenroll'];

  if (autopayFlows.includes(modalPath.currentFlow)) return <AutopayModalIfEligible />;
  else return null;
};
