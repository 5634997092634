import { IconButton } from '@mui/material';

import type { CardProps } from '@ecp/components';
import { Card } from '@ecp/components';
import { IconUICheck, IconUIExpandLess, IconUIExpandMore } from '@ecp/themes/base';

import { useStyles } from './ListItems.styles';

export interface Item {
  title: string;
  primaryImage?: React.ReactElement | string;
  classes?: string[];
}

export interface ListItemsProps
  extends Pick<CardProps, 'className' | 'needExpand' | 'expand' | 'footer'> {
  cardClasses?: CardProps['classes'];
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  items: Item[];
  handleExpandOrCollapse?: () => void;
  bannerItem?: string;
}

export const ListItems: React.FC<ListItemsProps> = (props) => {
  const {
    className,
    cardClasses,
    items,
    needExpand,
    expand,
    handleExpandOrCollapse,
    footer,
    bannerItem,
  } = props;
  const { classes } = useStyles(undefined, { props });

  const bannerText = (expand ? 'Hide ' : 'Show ') + bannerItem;

  const listItems = items.map((item) => {
    return (
      <li className={classes.item} key={item.title}>
        <div className={classes.svg}>
          {item.primaryImage || <IconUICheck className={classes.icon} />}
        </div>
        {item.title}
      </li>
    );
  });

  const body =
    needExpand && bannerItem ? (
      <div className={classes.root}>{items.map((item) => item.title).join(', ')}</div>
    ) : (
      <ul className={classes.root}>{listItems}</ul>
    );

  const getBannerBody = (expand: boolean | undefined): React.ReactElement | null => {
    if (expand) return <IconUIExpandLess className={classes.expandIcon} />;
    else if (listItems.length > 0)
      return (
        <>
          {!bannerItem && `+${listItems.length}`}
          <IconUIExpandMore className={classes.expandIcon} />
        </>
      );
    else return null;
  };

  const banner = needExpand ? (
    <div className={classes.banner}>
      {bannerItem ? <p className={classes.bannerText}>{bannerText}</p> : listItems.shift()}
      <IconButton
        className={classes.expandIconButton}
        onClick={handleExpandOrCollapse}
        aria-expanded={expand}
        aria-label='show more'
        size='large'
      >
        {getBannerBody(expand)}
      </IconButton>
    </div>
  ) : undefined;

  const showDivider = !!(footer && !needExpand && listItems.length);

  return (
    <Card
      className={className}
      classes={cardClasses}
      body={body}
      banner={banner}
      divider={showDivider}
      needExpand={needExpand}
      expand={expand}
      collapseDivider={expand}
      footer={footer}
    />
  );
};
