import { cloneElement, forwardRef } from 'react';

import type { FormControlLabelProps } from '@mui/material';
import { ButtonBase, FormControlLabel } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { useEvent } from '@ecp/utils/react';

import type { AnswerValue, Option } from '@ecp/types';

import { useStyles } from './ButtonFormControlLabel.styles';

export interface ButtonFormControlLabelProps extends Omit<FormControlLabelProps, 'label'> {
  option: Pick<Option, 'iconProduct' | 'label' | 'value'>;
  variant?: 'button' | 'yesNoButton' | 'roundButton';
  autoFocus?: boolean;
  isDisabled?: boolean;
  size?: 'small' | 'medium' | 'large';
  onButtonClick?(val: AnswerValue): void;
  actionOnComplete?(value: AnswerValue): void;
  trackingLabel?: string;
  trackingName?: string;
}

export const ButtonFormControlLabel = forwardRef<HTMLLabelElement, ButtonFormControlLabelProps>(
  (props, ref) => {
    const {
      checked,
      className,
      control,
      isDisabled,
      option: { iconProduct, label, value },
      variant,
      autoFocus = false,
      onButtonClick,
      actionOnComplete,
      trackingName,
      trackingLabel = value,
      size = 'medium',
      ...rest
    } = props;
    const { classes, cx } = useStyles();

    const handleLabelClick = useEvent<NonNullable<FormControlLabelProps['onClick']>>((event) => {
      event.stopPropagation();
    });
    const handleClick = useEvent(() => {
      onButtonClick?.(value);
      if (actionOnComplete) {
        const newValue: AnswerValue =
          variant === 'yesNoButton' ? value === 'true' || value !== 'false' : value;
        actionOnComplete(newValue);
        if (trackingName) {
          trackClick({ action: trackingName, label: trackingLabel });
        }
      }
    });

    // NOTE FormControlLabel must wrap ButtonBase instead of other way around for ADA compliance.
    // Only is an issue when multiple questions on the same page. Fixes - This field label is not unique.
    return (
      <FormControlLabel
        {...rest}
        checked={checked}
        className={cx(classes.root, className)}
        control={cloneElement(control, { className: classes.control })}
        disabled={isDisabled}
        key={value}
        label={
          <ButtonBase
            className={cx(
              classes.labelWrapper,
              checked && classes.checked,
              size === 'large' && classes.largeLabelWrapper,
              variant === 'roundButton' && classes.roundButton,
              iconProduct && classes.iconProduct,
            )}
            onClick={handleClick}
            aria-label={`${label}`}
            aria-pressed={checked}
            autoFocus={autoFocus}
            disabled={isDisabled}
            focusRipple={variant === 'roundButton'}
          >
            {iconProduct}
            {label}
          </ButtonBase>
        }
        onClick={handleLabelClick}
        ref={ref}
        value={value}
      />
    );
  },
);
