import { LegalCopy as LegalCopyWrapper } from '@ecp/features/servicing/shared/components';
import type { UiPolicy } from '@ecp/features/servicing/shared/types';
import { getBrandData } from '@ecp/features/servicing/shared/util';

type LegalCopyProps = {
  policyResponse: UiPolicy | undefined;
  isCaliforniaState: boolean;
};

export const LegalCopy: React.FC<LegalCopyProps> = (props) => {
  const { isCaliforniaState, policyResponse } = props;

  const brandData = getBrandData(policyResponse);

  const otherStateLegalText = (
    <LegalCopyWrapper>
      <p>
        This Disclosure and Consent applies to the servicing and administration of your insurance
        policies with us which include but are not limited the following: legal and regulatory
        disclosure, forms, notices and communications associated with the purchasing, renewing,
        changing and/or maintaining your insurance policy(ies) as well as cancellation and
        nonrenewal notices as permitted by law, and related to claims and the processing of claims
        with {brandData.uwCompanyCopyright} (Communications). In the event that you opt for
        electronic billing, you also agree that this includes billing statements and premium
        notices.
      </p>
      <p>
        All Communications that we provide electronically will be provided online. You will always
        have the ability to download, save and/or print documents for your records during and after
        they are signed or provided. We will not send you a paper copy of these Communications
        unless you request it, or we otherwise deem it appropriate to do so.
      </p>
      <p>
        You may obtain a paper copy of any Communication we provide to you at no charge by calling
        our customer care department, at {brandData.mainCustServPhoneNum}.
      </p>
      <p>
        You may withdraw this consent at any time at no cost by indicating your preference at our
        website or by calling our customer care department at {brandData.mainCustServPhoneNum}.
      </p>
      <p>
        If you decide not to complete any signing electronically, you can complete it by requesting
        the form from our customer care department at {brandData.mainCustServPhoneNum} and returning
        it once you've signed. This process will take longer than had you completed the transaction
        electronically.
      </p>
      <>
        <p>
          In order to access, view, sign and retain electronic communications, you must have access
          to a personal computer with internet connectivity and at least one of the following:
        </p>
        <ul>
          <li>
            For Windows Operating System:
            <ul>
              <li>Browsers: Use current version of Edge, Firefox or Google Chrome</li>
              <li>Portable Document Reader: Use current version of Adobe Acrobat Reader</li>
            </ul>
          </li>
          <li>
            For Macintosh Operating System:
            <ul>
              <li>Browsers: Use current version of Safari, Firefox or Google Chrome</li>
              <li>Portable Document Reader: Use current version of Adobe Acrobat Reader</li>
            </ul>
          </li>
        </ul>
      </>
      <p>
        You acknowledge and agree that your consent to electronic Communications is being provided
        in connection with a transaction affecting interstate commerce that is subject to the
        federal Electronic Signatures in Global and National Commerce Act, and that you and we both
        intend that the Act apply to the fullest extent possible to validate our ability to conduct
        business with you by electronic means.
      </p>
      <b>
        By consenting electronically to the terms and conditions of this Disclosure, you are
        verifying that you have read and agree to the terms and conditions and wish to sign
        communications electronically.
      </b>
    </LegalCopyWrapper>
  );
  const californiaLegalText = (
    <LegalCopyWrapper>
      <p>
        If you consent, {brandData.uwCompanyCopyright} will transmit documents related to your
        policy(ies) by electronic means, to the extent that electronic transmission is consistent
        with applicable state and federal law. Any document that {brandData.uwCompanyCopyright}{' '}
        sends by electronic means and which complies with applicable law will have the same force
        and effect as if that document was sent in paper format. Records that will be transmitted
        electronically include but may not be limited to: legal and regulatory disclosure, forms,
        notices and communications associated with the purchasing, renewing, changing and/or
        maintaining your insurance policy(ies) as well as cancellation and nonrenewal notices as
        permitted by law, and related to claims and the processing of claims with{' '}
        {brandData.uwCompanyCopyright}. In the event that you opt for electronic billing, you also
        agree that this includes billing statements and premium notices.
      </p>
      <p>
        {brandData.uwCompanyCopyright} will transmit documents to you electronically only if you
        consent. Your consent is voluntary.
      </p>
      <p>{brandData.uwCompanyCopyright} will not charge you more if you do not consent.</p>
      <p>
        If you decide that you want to receive documents electronically,{' '}
        {brandData.uwCompanyCopyright} will provide one paper copy per year of any document, at no
        charge to you, upon your request by calling {brandData.mainCustServPhoneNum}.
      </p>
      <p>
        You can change your mind at any time and have {brandData.uwCompanyCopyright} transmit
        documents via paper mail by notifying {brandData.uwCompanyCopyright} by changing your
        preference in your online account at {brandData.servicingUrl} or by calling
        {brandData.mainCustServPhoneNum}.
      </p>
      <p>
        All documents that we provide electronically will be provided online. You will always have
        the ability to download, save and/or print documents for your records during and after they
        are signed or provided. We will not send you a paper copy of these documents unless you
        request it, or we otherwise deem it appropriate to do so.
      </p>{' '}
      <p>
        If you wish to correct or change your email address that {brandData.uwCompanyCopyright} uses
        to inform you of your documents, you can do so at any time by notifying{' '}
        {brandData.uwCompanyCopyright} or by calling us at {brandData.mainCustServPhoneNum}.
      </p>
    </LegalCopyWrapper>
  );

  return isCaliforniaState ? californiaLegalText : otherStateLegalText;
};
