import { useCallback, useMemo, useState } from 'react';

import { Stack } from '@mui/material';

import { parseDollar } from '@ecp/utils/common';
import { DateConstants, formatDate } from '@ecp/utils/date';
import { Form, useField, useForm } from '@ecp/utils/form';

import { Alert, Button } from '@ecp/components';
import { env } from '@ecp/env';
import {
  CheckboxField,
  LoadingButton,
  LoadingOverlay,
  ModalError,
} from '@ecp/features/servicing/shared/components';
import type { UseModalPathReturn as AutopayUnenrollReviewProps } from '@ecp/features/servicing/shared/routing';
import {
  getPolicyDisplayType,
  getProductLineFromPolicyResponse,
  isLegacyPolicy,
  useAutopayUnenroll,
  usePaymentInfo,
  usePaymentOptions,
  useUser,
} from '@ecp/features/servicing/shared/state';

import { useStyles } from './AutopayUnenrollReview.styles';
import { autopayUnenrollReviewSchema } from './AutopayUnenrollReviewInputs.schema';

export const AutopayUnenrollReview: React.FC<AutopayUnenrollReviewProps> = (props) => {
  const { goForward, goBack, policyNumber } = props;
  const { classes } = useStyles();
  const [submitError, setSubmitError] = useState<unknown>();
  const {
    paymentInfo: { billingAccount, paymentOptions, policyData },
    isLoading: isLoadingPayment,
  } = usePaymentInfo({ policyNumber });

  const { user, isLoading: isUserLoading } = useUser();
  const paymentOption = paymentOptions?.find(({ autopayEnrolled }) => autopayEnrolled);
  const paymentMethodValue =
    paymentOption?.type === 'CC' ? `${paymentOption?.cardType}` : 'Bank account';

  const paymentMethodNumbers =
    (paymentOption?.type &&
      `(...${
        paymentOption?.type === 'CC' ? paymentOption?.ccToken : paymentOption?.accountNumber
      })`) ??
    '';

  const formContext = useForm({
    validations: autopayUnenrollReviewSchema,
  });
  const { handleSubmit } = formContext;
  const consentField = useField({
    formContext,
    name: 'consent',
  });

  const productLine = getProductLineFromPolicyResponse(policyData);
  const policySource = policyData?.policy.sourceSystemName;
  const { refetch } = usePaymentOptions(
    {
      billingAccountNumber: policyData?.policy.billingAccountNumber,
      policySource,
    },
    isLegacyPolicy(productLine, policySource),
  );

  const reviewPageLoadTime = useMemo(() => new Date().toISOString(), []);
  const primeKey =
    paymentOption?.type === 'CC' ? paymentOption.ccprimekey : paymentOption?.efmprimekey;

  const { autopayUnenroll } = useAutopayUnenroll();
  const onSubmit = useCallback(() => {
    if (!user) throw new Error('User is undefined');
    handleSubmit(async () => {
      const todaysDateAndTime = new Date();
      try {
        const response = await autopayUnenroll({
          primeKey,
          billingAccount: policyData?.policy.billingAccountNumber,
          policySource,
          paymentMethodType: paymentOption?.type,
          autopayEnrolled: false,
          auditInfo: {
            requestedDateTime: reviewPageLoadTime,
            requestedBy: user.email,
            requestingSystem: env.static.sourceId,
            consumerStep: 'Unenroll from AutoPay - Authorize autopay unenrollment',
            acknowledgement: 'AutoPay unenrollment selected',
            whenAccepted: formatDate(todaysDateAndTime.toString(), DateConstants.ANSWERS_FORMAT),
          },
        });
        if (response.success) {
          await refetch();
          goForward();
        } else if (response.error) throw new Error('Failed to update autopay unenroll');
      } catch (error) {
        setSubmitError(error);
      }
    })();
  }, [
    goForward,
    autopayUnenroll,
    user,
    policySource,
    refetch,
    handleSubmit,
    reviewPageLoadTime,
    paymentOption?.type,
    policyData?.policy.billingAccountNumber,
    primeKey,
  ]);

  const isLoading = isLoadingPayment || isUserLoading;
  const displayPolicyType = getPolicyDisplayType(policyData);
  const paymentAmount = !!billingAccount?.minimumDue && (
    <>
      of <b>{parseDollar(billingAccount?.minimumDue)}</b>
    </>
  );

  if (isLoading) return <LoadingOverlay />;

  if (submitError) {
    const policyType = getPolicyDisplayType(policyData);
    const errorDesc = (
      <span>
        unenrolling your{' '}
        <b>
          {policyType} policy {policyNumber}
        </b>{' '}
        from AutoPay
      </span>
    );

    return (
      <ModalError errorTitle='unenroll your policy from AutoPay' errorDescription={errorDesc} />
    );
  }

  return (
    <Form onSubmit={onSubmit} formProviderProps={formContext}>
      <Stack spacing={5} className={classes.root}>
        <Stack spacing={3}>
          <h2>Review unenrollment details</h2>
          <div className={classes.explanation}>
            <p>
              By unenrolling your{' '}
              <b>
                {displayPolicyType} policy {policyNumber}
              </b>{' '}
              from AutoPay, your payments {paymentAmount} will no longer be automatically withdrawn
              from your <b>{paymentMethodValue} </b>
              <span className={classes.explanationNumbers}>{paymentMethodNumbers}</span>. You will
              have to make manual payments instead.
            </p>
          </div>

          <CheckboxField
            {...consentField}
            checked={!!consentField.value}
            label='I authorize AutoPay unenrollment'
          />
        </Stack>

        <Alert withIcon type='warning'>
          <p>Make sure you don't miss a payment to avoid a lapse in coverage.</p>
        </Alert>

        <Stack direction={{ xs: 'column', md: 'row' }}>
          <LoadingButton
            type='submit'
            // TODO tracking
            // trackingName='unenroll_autopay_button'
            // trackingLabel='unenroll_review_continue'
            className={classes.submitButtton}
          >
            Save
          </LoadingButton>
          <Button variant='iconTextMedium' onClick={goBack} className={classes.backButton}>
            Back
          </Button>
        </Stack>
      </Stack>
    </Form>
  );
};
