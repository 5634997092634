import { flagValues } from '@ecp/utils/flags';

import type { UiPolicy } from '@ecp/features/servicing/shared/types';

import { getProductLineFromPolicyResponse } from './getProductLineFromPolicyResponse';
import { isLegacyPolicy } from './isLegacyPolicy';

export const shouldMakePaymentOptionsCall = (policyResponse: UiPolicy | undefined): boolean => {
  if (!policyResponse) return false;

  if (
    isLegacyPolicy(
      getProductLineFromPolicyResponse(policyResponse),
      policyResponse.policy.sourceSystemName,
    )
  )
    return flagValues.CLASSIC_AUTOPAY_INDICATOR && !policyResponse.isInactive;

  return !policyResponse.isInactive;
};
