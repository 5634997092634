import { memo } from 'react';

import type { UiPolicy } from '@ecp/features/servicing/shared/types';

import { getDiscountList } from '../../util/coveragesUtil';
import { Discounts } from '../Discounts';

interface Props {
  policyData: UiPolicy;
}

export const HomeDiscounts: React.FC<Props> = memo((props) => {
  const { policyData } = props;

  // TODO Wrap with useMemo here or in getDiscountList
  const discounts = getDiscountList(policyData?.premium?.policyDiscounts);

  return <Discounts discounts={discounts} />;
});
