import type { ModalProps } from '@ecp/components';
import { Button, Modal } from '@ecp/components';

import { useStyles } from './ServicingModal.styles';

export interface ServicingModalProps extends ModalProps {
  servicingModalClasses?: Partial<ReturnType<typeof useStyles>['classes']>;
  open: boolean;
  title: string;
  body: React.ReactNode;
  linkButtonText?: string;
  buttonText?: string;
  isProcessing?: boolean;
  onClose: () => void;
  onButtonLinkClick?: () => void;
  onButtonClick?: () => void;
}

export const ServicingModal: React.FC<ServicingModalProps> = (props) => {
  const {
    open,
    linkButtonText,
    buttonText,
    title,
    body,
    isProcessing,
    onClose,
    onButtonLinkClick,
    onButtonClick,
    size,
    servicingModalClasses,
    ...rest
  } = props;
  const { classes, cx } = useStyles(undefined, { props: { classes: servicingModalClasses } });

  const bodyElement = typeof body === 'string' ? <p className={classes.title}>{body}</p> : body;

  const bodyContainer = <div className={classes.container}>{bodyElement}</div>;

  const footer =
    onButtonLinkClick || onButtonClick ? (
      <div className={classes.footer}>
        {onButtonLinkClick && (
          <Button variant='text' className={classes.link} onClick={onButtonLinkClick}>
            {linkButtonText}
          </Button>
        )}
        {onButtonClick && (
          <Button
            variant='primary'
            className={classes.button}
            type='submit'
            onClick={onButtonClick}
            isProcessing={isProcessing}
          >
            {buttonText}
          </Button>
        )}
      </div>
    ) : undefined;

  return (
    <Modal
      title={title}
      open={open}
      onClose={onClose}
      footer={footer}
      className={cx(classes.modal, !size && classes.defaultWidth)}
      backdrop
      size={size}
      {...rest}
    >
      {bodyContainer}
    </Modal>
  );
};
