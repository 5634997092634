import { Fragment, memo } from 'react';

interface Props {
  weeklyHours?: string;
  className?: string;
  hourArrays?: string[];
}

export const HoursList: React.FC<Props> = memo(({ weeklyHours, className, hourArrays }) => {
  let hoursArray: string[] = [];
  if (weeklyHours) hoursArray = weeklyHours.split(',');
  else if (hourArrays) hoursArray = hourArrays;
  const renderBreak = (index: number): boolean => index < hoursArray.length - 1;

  return hoursArray.length ? (
    <p className={className}>
      {hoursArray.map((hours, i) => (
        <Fragment key={i}>
          {hours}
          {renderBreak(i) && <br />}
        </Fragment>
      ))}
    </p>
  ) : null;
});
