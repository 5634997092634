import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<void, 'checked'>({ name: 'RadioCardFormControlLabel' })(
  (...[theme, , classes]) => ({
    root: {
      alignItems: 'flex-start',
      outline: 0,
      backgroundColor: theme.palette.grey[50],
      border: `1px solid ${theme.palette.grey[600]}`,
      borderRadius: 3,
      '&:hover': {
        backgroundColor: theme.palette.grey[300],
      },
      [`&.${classes.checked}`]: {
        borderColor: theme.palette.primary.main,
        // box-shadow gives appearance of thicker border when checked without the screen shift issue
        boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
      },
      padding: 15,
    },
    labelWrapper: {
      ...theme.typography.body1,
      display: 'flex',
      paddingLeft: 15,
      transition: '0.3s, border 0.3s',
    },
    labelRoot: {
      width: '100%',
    },
    label: {
      textAlign: 'start',
      width: '100%',
      fontSize: 14,
      fontWeight: 400,
    },
    captionLabelWrapper: { flexDirection: 'column', alignItems: 'flex-start' },
    captionWithDescription: theme.typography.body2,
    largeTextDescription: {
      ...theme.typography.h4,
    },
    largeTextLabel: theme.typography.body3,
    largeTextLabelBold: theme.typography.body3Bold,
    largeTextLabelWithCaption: theme.typography.body4,
    largeTextLabelWrapper: { ...theme.typography.body4 },
    largeTextRoot: {
      '.MuiFormControlLabel-label': { width: '100%' },
    },
    labelToTheRight: {
      textAlign: 'right',
      width: 'auto',
    },
    caption: theme.typography.body2,
    checked: { fontWeight: 700 },
    icon: {
      ...theme.mixins.setColorSvg(theme.palette.icon.stroke),
      margin: 'auto auto auto 13px',
      [theme.breakpoints.down('md')]: {
        margin: 0,
      },
    },
    iconChecked: theme.mixins.setColorSvg(theme.palette.primary.main),
    svgHorizontal: {
      ...theme.mixins.svg(),
      margin: 0,
    },
    iconDisabled: {
      opacity: 0.3,
    },
  }),
);
