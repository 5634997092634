import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PasswordField' })((theme) => ({
  passwordFieldContainer: {
    marginBottom: 16,
    '& .MuiTextField-root': { marginBottom: 5 },
  },
  requirementIcon: {
    ...theme.mixins.setColorSvg(theme.palette.grey[900]),
    width: 24,
    height: 24,
  },
  requirementArrow: theme.mixins.setColorSvg(theme.palette.grey[600]),
  requirementError: theme.mixins.setColorSvg(theme.palette.error.main),
  body2: theme.typography.body2,
  requirementText: { ...theme.typography.body1, verticalAlign: 'super' },
}));
