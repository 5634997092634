import { useMemo, useRef } from 'react';

import { Stack } from '@mui/material';

import { unique } from '@ecp/utils/common';

import {
  ContactUs,
  DashboardCard,
  LoadingOverlay,
} from '@ecp/features/servicing/shared/components';
import { QUERY_PARAMS } from '@ecp/features/servicing/shared/routing';
import type { FaqCategory } from '@ecp/features/servicing/shared/state';
import { useFaqs } from '@ecp/features/servicing/shared/state';
import { useScrollToRef } from '@ecp/features/servicing/shared/util';

import { FaqSection } from '../../components';

const categoryOrder: FaqCategory[] = [
  'Manage my insurance policy',
  'About your insurance',
  'Billing and payments',
  'Coverages and discounts',
  'About your insurance company',
];

export const FaqPage: React.FC = () => {
  const { faqs, isLoading, isError } = useFaqs();
  const ref = useRef<HTMLDivElement>(null);

  useScrollToRef(isLoading, ref, QUERY_PARAMS.CONTACT_US);

  // sort all by priority
  faqs.sort((a, b) => a.priority - b.priority);

  // build and sort categories
  const faqCategories = useMemo(
    () =>
      unique(faqs.map((item) => item.category)).sort((a, b) => {
        const indexA = categoryOrder.indexOf(a);
        const indexB = categoryOrder.indexOf(b);

        return indexA - indexB;
      }),
    [faqs],
  );

  if (isLoading) return <LoadingOverlay />;

  return (
    <DashboardCard>
      <Stack spacing={4}>
        <div>
          <h1>Frequently asked questions</h1>
        </div>
        {faqCategories.map(
          (faqCategory) =>
            faqs.some((q) => q.category === faqCategory) && (
              <Stack spacing={2} key={faqCategory}>
                <FaqSection
                  id={faqCategory}
                  title={faqCategory}
                  questions={faqs.filter((q) => q.category === faqCategory)}
                />
              </Stack>
            ),
        )}
        <ContactUs ref={ref} hidePolicyContacts={isError} />
      </Stack>
    </DashboardCard>
  );
};
