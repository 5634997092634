import { useCallback, useMemo, useState } from 'react';

import { Stack } from '@mui/material';

import { Button } from '@ecp/components';
import type { OtherPoliciesEligibleProps } from '@ecp/features/servicing/shared/components';
import {
  LoadingOverlay,
  ModalError,
  OtherPoliciesEligible,
} from '@ecp/features/servicing/shared/components';
import type { UseModalPathReturn as CaMileageConfirmationProps } from '@ecp/features/servicing/shared/routing';
import { MODAL_FLOW, MODAL_STEP, useModalPath } from '@ecp/features/servicing/shared/routing';
import {
  isCaMileageFormRequired,
  useSharedState,
  useUserPolicies,
} from '@ecp/features/servicing/shared/state';
import type { ErrorResponse, UseUpdateCaMileageReturn } from '@ecp/features/servicing/shared/state';
import { IconMaterialDesignActionCheckCircle } from '@ecp/themes/base';

import { useStyles } from './CaMileageConfirmation.styles';

export const CaMileageConfirmation: React.FC<CaMileageConfirmationProps> = (props) => {
  const { init, sharedStateKey, policyNumber } = props;

  const { classes } = useStyles();
  const [submitError] = useState<ErrorResponse>();
  const modalPath = useModalPath();
  const { policies, isLoading } = useUserPolicies();

  const [sharedState] = useSharedState<UseUpdateCaMileageReturn>(sharedStateKey);

  const confirmationId = sharedState?.confirmationId;
  const handleClose = useCallback(() => {
    modalPath.reset();
  }, [modalPath]);

  const handleOtherClick = useCallback<NonNullable<OtherPoliciesEligibleProps['onClick']>>(
    (policyNumber) => init(policyNumber, MODAL_FLOW.CA_MILEAGE, MODAL_STEP.MODAL_EDIT),
    [init],
  );
  const otherPolicies = useMemo(
    () =>
      policies.filter(
        (policy) => policy?.policy.policyNumber !== policyNumber && isCaMileageFormRequired(policy),
      ),
    [policies, policyNumber],
  );

  if (submitError) {
    return (
      <ModalError
        errorTitle='update the mileage for your policy'
        errorDescription='updating the mileage for your policy'
      />
    );
  }

  return isLoading ? (
    <LoadingOverlay />
  ) : (
    <Stack spacing={4} classes={classes.root}>
      <Stack spacing={3}>
        <div className={classes.successIconWrapper}>
          <IconMaterialDesignActionCheckCircle className={classes.successIcon} />
        </div>

        <h2>Your form was successfully submitted!</h2>
        <Stack spacing={1.5}>
          <p>
            <b>Confirmation {confirmationId}</b>
          </p>
          <p>We have received your California Mileage Form.</p>
        </Stack>
        <p>
          If your premium changes, the new premium will be reflected on the updated declaration page
          and your billing will be adjusted.
        </p>
      </Stack>
      <OtherPoliciesEligible
        otherPolicies={otherPolicies}
        title={`Update your California Mileage Form for your other ${
          otherPolicies?.length && otherPolicies.length > 1 ? 'policies' : 'policy'
        }`}
        buttonText='Update'
        onClick={handleOtherClick}
      />
      <Button className={classes.closeButton} variant='primary' onClick={handleClose}>
        CLOSE
      </Button>
    </Stack>
  );
};
