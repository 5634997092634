import dayjs from 'dayjs';
import type { ManipulateType } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';

import { DateConstants } from './constants';

dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);

export const isDate = (date: string, format?: string): boolean =>
  dayjs(date, format, true).isValid();

export const formatDate = (
  date: string | undefined,
  format: string = DateConstants.DISPLAY_FORMAT,
): string => (date ? dayjs(date).format(format) : '');

export const getAge = (dobString: string): number | undefined => {
  const dob = dayjs(dobString, DateConstants.ANSWERS_FORMAT);

  return dob.isValid() ? dayjs().diff(dob, 'y') : undefined;
};
export const formatMonth = (month: string): string => String(month).padStart(2, '0');

export const isDateExpired = (date: string): boolean => {
  return dayjs(date).isBefore(dayjs().startOf('d').add(1, 'd'));
};

export const isPastDate = (date: string): boolean => {
  return dayjs(date).isBefore(dayjs().startOf('d'));
};

export const isDateInBetween = (date: string, minDate: string, maxDate: string): boolean => {
  dayjs.extend(isBetween);

  return dayjs(date).isBetween(dayjs(minDate), dayjs(maxDate), null, '[]');
};

export const isOlderThan = (timeAmount: number, format: ManipulateType, date: string): boolean =>
  dayjs(date).isBefore(dayjs().startOf('d').subtract(timeAmount, format));

/**
 * Returns current time in seconds as UNIX timestamp (UTC).
 */
export const getTime = (): number => +(Date.now() / 1000);

export const isFutureDate = (date: string): boolean => dayjs(date).isAfter(dayjs());

export const isFutureOrTodayDate = (date: string): boolean =>
  isFutureDate(date) || dayjs(date).startOf('d').isSame(dayjs().startOf('d'));

export const getYearMonth = (value: string): { month: string; year: string } => {
  const values = value.trim().split('/');
  const month = values[0].trim();
  const year = values.length > 1 && values[1].trim() ? values[1].trim() : '0';

  return { month, year };
};

/**
 * Returns abbreviated month if full month is more than 5 characters.
 */
export const getShortMonth = (dateString: string): string => {
  const d = dayjs(dateString);

  return d.format('MMMM').length > 5 ? `${d.format('MMM')}.` : d.format('MMMM');
};

/**
 * Returns formatted date in 'YYYY-MM-DD' format after adding number of days from today.
 */
export const getAnswersFormattedDateAfterDays = (numberOfDaysFromToday: number): string =>
  dayjs().add(numberOfDaysFromToday, 'd').format(DateConstants.ANSWERS_FORMAT);

// date should be in the format of 'YYYY-MM-DD'
export const isMoreThan60DaysFromToday = (date: string): boolean =>
  dayjs(date).diff(dayjs(), 'd') > 60;

// date should be in the format of 'YYYY-MM-DD'
export const isToday = (date: string): boolean =>
  dayjs().format(DateConstants.ANSWERS_FORMAT) === date;

/**
 * Compares two dates in string format to determine if the first date is greater than the second.
 *
 * @param {string} date - The first date in string format 'YYYY-MM-DD'
 * @param {string} limitDate - The limit date in string format 'YYYY-MM-DD'
 * @returns {boolean} `true` if `date` is greater than `limitDate`, `false` otherwise.
 */
export const isDateGreaterThanLimitDate = (date: string, limitDate: string): boolean => {
  const date1 = new Date(date);
  const date2 = new Date(limitDate);

  return date1.getTime() > date2.getTime();
};
