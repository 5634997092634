import { useParams } from 'react-router-dom';

import { ProtectYourselfConnect } from './connect';

export const ProtectYourselfPage: React.FC = () => {
  const { opco } = useParams();

  let protectYourself;
  switch (opco) {
    case 'connect':
      protectYourself = <ProtectYourselfConnect />;
      break;
    default:
      protectYourself = <p>Partner Specific ProtectYourself.</p>;
  }

  return protectYourself;
};
