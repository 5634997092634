import { useCallback, useMemo } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import type { FallbackProps } from 'react-error-boundary';

import {
  FullScreenModal,
  LoadingOverlay,
  ModalErrorFallback,
} from '@ecp/features/servicing/shared/components';
import type { ModalFlow } from '@ecp/features/servicing/shared/routing';
import { MODAL_STEP, useModalPath } from '@ecp/features/servicing/shared/routing';
import {
  isPolicyAllowedToSetPaperless,
  usePaperless,
  usePolicy,
  usePreferences,
} from '@ecp/features/servicing/shared/state';

import { PaperlessConfirmation } from '../PaperlessConfirmation';
import { PaperlessEnrollReview } from '../PaperlessEnrollReview';
import { PaperlessLanding } from '../PaperlessLanding';
import { PaperlessUnenrollEdit } from '../PaperlessUnenrollEdit';

const PaperlessModalContent: React.FC = () => {
  const modalPath = useModalPath();

  const title = 'Document delivery';
  const isBackButton = false;
  let body;

  switch (modalPath.currentStep) {
    case MODAL_STEP.MODAL_LANDING:
      body = <PaperlessLanding {...modalPath} />;
      break;
    case MODAL_STEP.MODAL_EDIT:
      body = <PaperlessUnenrollEdit {...modalPath} />;
      break;
    case MODAL_STEP.MODAL_CONFIRMATION:
      body = <PaperlessConfirmation {...modalPath} />;
      break;
    case MODAL_STEP.MODAL_REVIEW:
      body = <PaperlessEnrollReview {...modalPath} />;
      break;

    default:
      if (modalPath.policyNumber && modalPath.currentFlow) {
        modalPath.init(modalPath.policyNumber, modalPath.currentFlow, 'landing');
      }
  }

  const errorDescription = useMemo(() => {
    switch (modalPath.currentFlow) {
      case 'paperlessenroll':
        return 'We are unable to enroll your policy in paperless delivery.';
      case 'paperlessunenroll':
        return 'We are unable to unenroll your policy from paperless delivery.';
      default:
        return '';
    }
  }, [modalPath.currentFlow]);

  const getFallbackComponent = useCallback<NonNullable<React.ComponentType<FallbackProps>>>(
    (props: FallbackProps) => (
      <ModalErrorFallback reset={modalPath.reset} errorDescription={errorDescription} />
    ),
    [errorDescription, modalPath.reset],
  );

  return (
    <FullScreenModal
      title={title}
      isBackButton={isBackButton}
      onClickBack={modalPath.goBack}
      onCloseModal={modalPath.reset}
    >
      <ErrorBoundary FallbackComponent={getFallbackComponent}>{body}</ErrorBoundary>
    </FullScreenModal>
  );
};

const PaperlessModalIfEligible: React.FC = () => {
  const modalPath = useModalPath();

  const { isAnyActivePolicyNotAllowingPaperless, isLoading: isPaperlessLoading } = usePaperless({
    throwOnError: false,
  });

  const policyNumber = modalPath.policyNumber;

  const { policyData, isLoading: isPolicyLoading } = usePolicy(policyNumber, {
    throwOnError: false,
  });

  const { isPaperlessOn, isLoading: isLoadingPreferences } = usePreferences(policyNumber ?? '', {
    throwOnError: false,
  });

  const { policyAllowedToSetPaperless, policyAllowedToUnsetPaperless } =
    isPolicyAllowedToSetPaperless(policyData);
  const isSetPaperlessAllowed =
    !isAnyActivePolicyNotAllowingPaperless && policyAllowedToSetPaperless;
  const isUnsetPaperlessAllowed = policyAllowedToUnsetPaperless && isPaperlessOn;

  if (
    (isSetPaperlessAllowed ||
      isUnsetPaperlessAllowed ||
      // A user has only 1 restricted state policy, which is already enrolled in Paperless.
      // When user tries to unenroll from paperless both isSetPaperlessAllowed  isUnsetPaperlessAllowed  are ending up as false and user never sees the confirmation screen.
      modalPath.currentStep === MODAL_STEP.MODAL_CONFIRMATION) &&
    !isPolicyLoading &&
    (!isPaperlessLoading || modalPath.currentStep === MODAL_STEP.MODAL_CONFIRMATION) &&
    !isLoadingPreferences &&
    !!policyNumber
  )
    return <PaperlessModalContent />;
  else return <LoadingOverlay />;
};

export const PaperlessModal: React.FC = () => {
  const modalPath = useModalPath();
  const paperlessFlows: ModalFlow[] = ['paperlessenroll', 'paperlessunenroll'];

  if (paperlessFlows.includes(modalPath.currentFlow)) return <PaperlessModalIfEligible />;
  else return null;
};
