import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ModalInterrupter' })((theme) => ({
  root: {},
  iconWrapper: { display: 'flex', justifyContent: 'center' },
  icon: {
    height: 64,
    width: 64,
  },
  button: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 140,
    },
  },
}));
