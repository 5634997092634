import { type JSX } from 'react';

import { Stack } from '@mui/material';

import { Button, PhoneLink } from '@ecp/components';
import { getBrandDataWithFallback } from '@ecp/features/servicing/shared/util';
import { IconUIExclaimTriangle } from '@ecp/themes/base';

import { useStyles } from './NumTriesExceeded.styles';

export interface NumTriesExceededProps {
  title: string;
  message: string | JSX.Element;
  subMessage?: string;
  btnText?: string;
  lnkBtnText?: string;
}

export const NumTriesExceeded: React.FC<NumTriesExceededProps> = (props) => {
  const { title, message, subMessage, btnText, lnkBtnText } = props;
  const { classes } = useStyles();
  const brandData = getBrandDataWithFallback();

  const body = (
    <Stack spacing={4} className={classes.root}>
      <Stack spacing={1.5}>
        <Stack direction='row' spacing={1}>
          <IconUIExclaimTriangle className={classes.icon} />
          <h1 className={classes.label}>{title}</h1>
        </Stack>
        <p className={classes.information}>{message}</p>
        {subMessage && <p className={classes.information}>{subMessage}</p>}
      </Stack>
      <Stack spacing={1.5}>
        <PhoneLink
          number={brandData.mainCustServPhoneNum}
          withUnderlinedLinkStyle
          className={classes.phone}
        />
        <div>
          {brandData.mainOperationHours.map((hours) => (
            <p key={hours} className={classes.hours}>
              {hours}
            </p>
          ))}
        </div>
      </Stack>
      {btnText && (
        <Button type='button' variant='primary' className={classes.resetButton}>
          {btnText}
        </Button>
      )}

      {lnkBtnText && (
        <Button variant='iconText' className={classes.secondaryLink}>
          {lnkBtnText}
        </Button>
      )}
    </Stack>
  );

  return body;
};
