import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutopayLanding' })((theme) => ({
  root: {},
  icon: {
    width: 50,
    height: 50,
    alignSelf: 'center',
  },
  title: theme.typography.body3,
  button: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 'fit-content',
    },
  },
  alertCloseBtn: {
    '#manageAutopayErrorAlert': {
      minWidth: 20,
    },
  },
  closeButton: {},
}));
