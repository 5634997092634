import { useState } from 'react';

import { CircularProgress, Stack } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { maskEmail } from '@ecp/utils/common';
import { Form, useField, useForm } from '@ecp/utils/form';
import { useEvent } from '@ecp/utils/react';

import type { TextFieldProps } from '@ecp/components';
import { Button, PhoneLink, TextField } from '@ecp/components';
import { LoadingButton } from '@ecp/features/servicing/shared/components';
import { useChangeEmail, useUser, useUserPolicies } from '@ecp/features/servicing/shared/state';
import type { ChangeEmailRequest } from '@ecp/features/servicing/shared/state';
import { getBrandData } from '@ecp/features/servicing/shared/util';

import type { EmailSection } from '../../pages/ProfilePage';
import type { UpdateEmailFormInput } from './UpdateEmail.schema';
import { updateEmailSchema } from './UpdateEmail.schema';
import { useStyles } from './UpdateEmail.styles';

export interface UpdateEmailProps {
  setEmailSection: (emailSection: EmailSection) => void;
  setNewEmail: (email: string) => void;
  onSuccess?(value: string): void;
  onError?(value: React.ReactNode): void;
}

export const UpdateEmail: React.FC<UpdateEmailProps> = (props) => {
  const { setEmailSection, onError, onSuccess } = props;
  const [displayedEmail, setDisplayedEmail] = useState('');
  const [displayedConfirmEmail, setDisplayedConfirmEmail] = useState('');
  const { classes } = useStyles();
  const formContext = useForm({
    validations: updateEmailSchema,
  });
  const { handleSubmit, setValue } = formContext;
  const emailField = useField({ name: 'email', formContext });
  const confirmEmailField = useField({ name: 'confirmEmail', formContext });
  const { user, refetch, isLoading: isLoadingUser } = useUser();
  const { policies, isLoading: isLoadingPolicies } = useUserPolicies();
  const { changeEmail } = useChangeEmail();

  const handleBlurEmail = useEvent<NonNullable<TextFieldProps['onBlur']>>((e) => {
    setDisplayedEmail(maskEmail(e.target.value));
  });
  const handleBlurConfirmEmail = useEvent<NonNullable<TextFieldProps['onBlur']>>((e) => {
    setDisplayedConfirmEmail(maskEmail(e.target.value));
  });

  const handleFocusEmail = useEvent<NonNullable<TextFieldProps['onFocus']>>(() => {
    setDisplayedEmail('');
  });
  const handleFocusConfirmEmail = useEvent<NonNullable<TextFieldProps['onBlur']>>(() => {
    setDisplayedConfirmEmail('');
  });

  const customerServicePhoneNumber = getBrandData(policies[0]).mainCustServPhoneNum;

  const onSubmit = useEvent(() => {
    handleSubmit(async (data) => {
      const { email } = data as Required<UpdateEmailFormInput>;
      if (user?.userId) {
        const changeEmailRequest: ChangeEmailRequest = {
          email,
          userId: user.userId,
        };
        const { error, success } = await changeEmail(changeEmailRequest);
        if (error) {
          if (error === 'Invalid Request') {
            setValue('confirmEmail', '');
            setValue('email', '');
            setDisplayedConfirmEmail('');
            setDisplayedEmail('');
            onError?.(
              <>
                We apologize, but we encountered an error while updating your user ID. For help,
                please call
                <PhoneLink withUnderlinedLinkStyle number={customerServicePhoneNumber} />.
              </>,
            );
          } else
            onError?.(
              'We apologize, but we encountered an error while updating your user ID. Please try again now, or wait a few minutes and try again later.',
            );
        } else if (success) {
          refetch();
          onSuccess?.('Successfully updated the User ID for sign-in.');
          setEmailSection('Current Email');
        }
      }
    })();
  });

  const onCancelClick = useEvent(() => {
    setEmailSection('Current Email');
  });

  const handleEmailFieldChange = useEvent((value: string) => setValue('email', value.trim()));
  const handleConfirmEmailFieldChange = useEvent((value: string) =>
    setValue('confirmEmail', value.trim()),
  );

  const isLoading = isLoadingPolicies || isLoadingUser;
  if (isLoading) return <CircularProgress aria-label='Loading...' />;

  return (
    <Form
      onSubmit={onSubmit}
      formProviderProps={formContext}
      className={classes.root}
      name='updateEmailForm'
    >
      <Stack spacing={3}>
        <TextField
          {...emailField}
          actionOnChange={handleEmailFieldChange}
          label='New email'
          className={classes.textInput}
          onBlur={handleBlurEmail}
          onFocus={handleFocusEmail}
          value={displayedEmail || emailField.value}
          trackingName='new_email'
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
        />
        <TextField
          {...confirmEmailField}
          actionOnChange={handleConfirmEmailFieldChange}
          label='Confirm new email'
          className={classes.textInput}
          onBlur={handleBlurConfirmEmail}
          onFocus={handleFocusConfirmEmail}
          value={displayedConfirmEmail || confirmEmailField.value}
          trackingName='confirm_new_email'
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
        />
        <Stack spacing={3} direction='row'>
          <LoadingButton
            type='submit'
            variant='primary'
            trackingName='save_email_button'
            trackingLabel='save_email_continue'
          >
            SAVE
          </LoadingButton>
          <Button
            variant='iconText'
            onClick={onCancelClick}
            trackingName='cancel_edit_email_link'
            trackingLabel='cancel_edit_email_continue'
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Form>
  );
};
