import { useCallback, useMemo } from 'react';

import { useMutation, useQueries } from '@tanstack/react-query';

import { isTruthy } from '@ecp/utils/common';
import { flagValues } from '@ecp/utils/flags';

import { env } from '@ecp/env';

import { QUERY_KEYS } from '../constants';
import { useLinkedPolicies, usePoliciesByPolicyNumber, useUserPolicies } from '../policy';
import type { ServicingRequestError, ServicingResponse } from '../servicingRequest';
import { useUser } from '../users';
import { getPersonByRefId, isPolicyAllowedToSetPaperless } from '../util';
import { getPreferences, updatePreferenceByLabel } from './api';
import type {
  ErrorResponse,
  GetPaperlessByPolicyNumbersResponse,
  GetPreferencesReturn,
  Preference,
  UpdatePaperlessRequest,
  UpdatePaperlessResponse,
  UpdatePreferencesRequest,
  UsePreferencesForPolicyNumbersReturn,
  UseRefetchLinkedPolicyPreferencesReturn,
  UseUpdatePaperlessReturn,
} from './types';

const getPreferencesKey = (policyNumber?: string): Array<unknown> => [
  QUERY_KEYS.PREFERENCES,
  policyNumber,
];

const isPaperless = (preference: Preference): boolean =>
  preference.preferenceLabel === 'POLICY_DOCUMENT_DELIVERY' &&
  preference.preferenceOption === 'EMAIL';

export const usePreferencesForPolicyNumbers = (
  policyNumbers: string[],
  options?: {
    throwOnError?: boolean;
  },
): UsePreferencesForPolicyNumbersReturn => {
  const {
    policies,
    isLoading: isLoadingPolicies,
    isError: isErrorPolicies,
    isFetching: isFetchingPolcies,
  } = usePoliciesByPolicyNumber({ policyNumbers }, options);
  const queries = policies.map((policyDetails) => {
    const { policyAllowedToSetPaperless, policyAllowedToUnsetPaperless } =
      isPolicyAllowedToSetPaperless(policyDetails);

    return {
      queryKey: getPreferencesKey(policyDetails.policy.policyNumber),
      queryFn: async () => (await getPreferences(policyDetails.policy.policyNumber)).payload,
      enabled: policyAllowedToSetPaperless || policyAllowedToUnsetPaperless,
      throwOnError: options?.throwOnError,
    };
  });

  const results = useQueries({ queries });

  return useMemo(() => {
    return {
      preferenceResults: results.map((result, index) => {
        const policyResponse = policies[index];
        const { policyAllowedToSetPaperless } = isPolicyAllowedToSetPaperless(policyResponse);

        return {
          isPaperlessOn: result.isLoading ? undefined : result.data?.preferences?.some(isPaperless),
          policyAllowedToSetPaperless,
          policyResponse,
          policyNumber: policyResponse?.policy.policyNumber,
          preferences: result.data?.preferences,

          refetch: result.refetch,
          isError: result.isError,
          isLoading: result.isLoading,
          isFetching: result.isFetching,
        };
      }),
      isError: isErrorPolicies || results.some((result) => result.isError),
      isFetching: isFetchingPolcies || results.some((result) => result.isFetching),
      isLoading: isLoadingPolicies || results.some((result) => result.isLoading),
    };
  }, [policies, results, isLoadingPolicies, isErrorPolicies, isFetchingPolcies]);
};

export const usePreferences = (
  policyNumber: string,
  options?: {
    throwOnError?: boolean;
  },
): GetPreferencesReturn => {
  const { preferenceResults, isLoading, isError, isFetching } = usePreferencesForPolicyNumbers(
    [policyNumber],
    options,
  );

  const result = preferenceResults.at(0);

  const refetch = useCallback(() => {
    if (!result?.refetch) {
      throw Error('Cannot refetch before we have at least started to load preferences');
    }

    return result?.refetch();
  }, [result]);

  return useMemo(
    () => ({
      ...result,
      refetch,
      isError,
      isFetching,
      isLoading,
    }),
    [isError, isFetching, isLoading, refetch, result],
  );
};

export const useRefetchLinkedPolicyPreferences = (
  policyNumber: string | undefined,
  options?: { throwOnError?: boolean; unenrollingPaperless?: boolean },
): UseRefetchLinkedPolicyPreferencesReturn => {
  const {
    linkedPolicies,
    isError: isErrorPolicies,
    isLoading: isLoadingPolicies,
  } = useLinkedPolicies(policyNumber, options);
  const linkedPolicyNumbers = linkedPolicies.map(({ policy }) => policy.policyNumber);
  const {
    preferenceResults,
    isError: isErrorPrefs,
    isLoading: isLoadingPrefs,
  } = usePreferencesForPolicyNumbers(linkedPolicyNumbers);

  const refetchLinkedPolicies = useCallback(async () => {
    const prefRefreshPromises = preferenceResults
      .filter((result) => !!result.preferences)
      .map((result) => result.refetch());

    return await Promise.all(prefRefreshPromises);
  }, [preferenceResults]);

  return {
    refetchLinkedPolicies,
    isLoading: isLoadingPolicies || isLoadingPrefs,
    isError: isErrorPolicies || isErrorPrefs,
  };
};

export const usePaperless = (options?: {
  throwOnError?: boolean;
}): GetPaperlessByPolicyNumbersResponse => {
  const throwOnError = options?.throwOnError ?? true;
  const {
    policies,
    isLoading: isLoadingPolicies,
    isError: isErrorPolicies,
  } = useUserPolicies({ throwOnPolicyError: throwOnError, throwOnUserError: throwOnError });

  const { preferenceResults } = usePreferencesForPolicyNumbers(
    policies.map(({ policy }) => policy.policyNumber),
    { throwOnError },
  );

  const activePolicies = policies
    .filter((policy) => !policy.isInactive)
    .filter((policy) => {
      return flagValues.PAPERLESS_CLASSIC === false
        ? policy.policy.sourceSystemName === 'HRSN_Horison'
        : policy;
    });
  const preferences = useMemo(
    () => preferenceResults.map((response) => response.preferences).filter(isTruthy),
    [preferenceResults],
  );

  return useMemo(() => {
    const isLoading = isLoadingPolicies || preferenceResults.some((result) => result.isLoading);

    return {
      isError: isErrorPolicies || preferenceResults.some((result) => result.isError),
      isLoading,
      hasPoliciesWithPaperlessOn: isLoading
        ? undefined
        : preferences.some((prefs) => prefs.some(isPaperless)),
      isAllPoliciesPaperlessOn: isLoading
        ? undefined
        : preferences.every((prefs) => prefs.some(isPaperless)),
      isAnyActivePolicyNotAllowingPaperless: isLoading
        ? undefined
        : activePolicies.length === 0
        ? true
        : !activePolicies.every((activePolicy) => {
            const { policyAllowedToSetPaperless } = isPolicyAllowedToSetPaperless(activePolicy);

            return policyAllowedToSetPaperless && !isErrorPolicies;
          }),
      allPoliciesAreInactive: isLoading ? undefined : policies.every((policy) => policy.isInactive),
      preferencesInfo: preferenceResults,
    };
  }, [
    isErrorPolicies,
    isLoadingPolicies,
    preferenceResults,
    preferences,
    activePolicies,
    policies,
  ]);
};

export const useUpdatePaperless = (
  policyNumber: string,
  unenrollingPaperless?: boolean,
): UpdatePaperlessResponse<UpdatePaperlessRequest, UseUpdatePaperlessReturn> => {
  const { user, isLoading: isUserLoading, isError: isUserError } = useUser();

  const {
    preferences,
    policyResponse: policyData,
    refetch,
    isError: isPreferencesError,
    isLoading: isPreferencesLoading,
  } = usePreferences(policyNumber);

  const {
    isLoading: isLoadingRefetchLinkedPolicies,
    isError: isErrorRefetchLinkedPolicies,
    refetchLinkedPolicies,
  } = useRefetchLinkedPolicyPreferences(policyNumber, {
    throwOnError: false,
    unenrollingPaperless,
  });

  const preferenceId = useMemo(
    () =>
      preferences?.find((pref) => pref.preferenceLabel === 'POLICY_DOCUMENT_DELIVERY')
        ?.preferenceId,
    [preferences],
  );

  const {
    mutateAsync,
    isPending: isSubmitting,
    isError,
  } = useMutation({
    mutationFn: async (request: UpdatePaperlessRequest) => {
      const { acknowledgement, preferenceOption, requestedDateTime, whenAccepted, consumerStep } =
        request;
      let updatePreferenceError: ErrorResponse | undefined = undefined;
      let success: ServicingResponse<void> | undefined;
      let response: UseUpdatePaperlessReturn = {
        success,
        error: updatePreferenceError,
      };

      const policySourceSystemName = policyData?.policy.sourceSystemName;

      if (!policySourceSystemName) {
        response.error = 'Policy data is undefined';

        return response;
      }

      if (!user) {
        response.error = 'User data is undefined';

        return response;
      }

      const primaryNamedInsured = getPersonByRefId(
        policyData,
        policyData.policy.primaryInsuredRefId,
      );

      const requestBody: UpdatePreferencesRequest = {
        policyNumber: policyNumber,
        preferenceLabel: 'POLICY_DOCUMENT_DELIVERY',
        policySourceSystemName,
        preference: {
          preferenceId,
          preferenceOption,
          deliveryDestination: preferenceOption === 'EMAIL' ? primaryNamedInsured?.email : '',
        },
        auditInfo: {
          requestedDateTime,
          requestedBy: user.email,
          requestingSystem: env.static.sourceId,
          consumerStep,
          acknowledgement,
          whenAccepted,
        },
      };

      await updatePreferenceByLabel(requestBody)
        .then(async (res) => {
          success = res;
          await Promise.all([refetch(), refetchLinkedPolicies()]);
        })
        .catch((error: ServicingRequestError) => {
          success = undefined;
          updatePreferenceError = 'unknown';
        });

      response = {
        success,
        error: updatePreferenceError,
      };

      return response;
    },
  });

  return {
    updatePaperless: mutateAsync,
    isLoading: isPreferencesLoading || isUserLoading || isLoadingRefetchLinkedPolicies,
    isError: isError || isPreferencesError || isUserError || isErrorRefetchLinkedPolicies,
    isSubmitting,
  };
};
