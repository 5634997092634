import { forwardRef, memo, useCallback, useState } from 'react';

import type { InputProps, TextFieldProps as MuiTextFieldProps } from '@mui/material';
import { FormLabel, IconButton, InputAdornment, TextField as MuiTextField } from '@mui/material';

import { trackEntryComplete, trackEntryStart } from '@ecp/utils/analytics/tracking';
import { upperFirst } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';
import { isHTMLElementWithValue } from '@ecp/utils/web';

import { IconUIVisibility, IconUIVisibilityOff } from '@ecp/themes/base';

import { formatLabel } from '../utils';
import { useStyles } from './TextField.styles';

const errorHelperTextProps = { role: 'alert' };

const getAriaLabel = ({
  ariaLabel,
  ariaLabelDisabled,
  groupLabel,
  label,
}: TextFieldProps): string | undefined => {
  if (ariaLabelDisabled) return undefined;
  if (ariaLabel) return ariaLabel;
  if (label && typeof label === 'string') return label;
  if (groupLabel && typeof groupLabel === 'string') return groupLabel;

  return undefined;
};

type InputAndMuiTextFieldProps = Omit<InputProps, 'error' | 'classes'> &
  Omit<
    MuiTextFieldProps,
    'error' | 'margin' | 'onKeyDown' | 'onKeyUp' | 'ref' | 'type' | 'classes' | 'InputProps'
  >;

export interface TextFieldProps extends InputAndMuiTextFieldProps {
  ariaDisabled?: boolean;
  disabled?: boolean;
  error?: string | null;
  fullWidth?: boolean;
  groupLabel?: React.ReactNode;
  id?: string;
  label?: React.ReactNode;
  ariaLabel?: string;
  ariaLabelDisabled?: boolean;
  ariaLabelledby?: string;
  name?: string;
  trackingName?: string;
  trackingLabel?: string;
  placeholder?: string;
  required?: boolean;
  icon?: React.ReactElement;
  maxLength?: number;
  pattern?: string;
  // The following actions are optional because sometimes if TextField is part
  // of some other component, material-ui or we might want the events to be handled
  // differently. See for NumberInputFieldWithPopup, PercentageGroup etc.
  actionOnChange?(value: string): void;
  actionOnComplete?(value: string): void;
}

export const TextField: React.FC<TextFieldProps> = memo(
  forwardRef((props, ref) => {
    const {
      className,
      disabled,
      error,
      fullWidth = true,
      groupLabel,
      id,
      placeholder,
      label,
      ariaDisabled,
      ariaLabel,
      ariaLabelDisabled,
      ariaLabelledby,
      required,
      helperText,
      name,
      trackingName,
      trackingLabel,
      value: valueProp,
      inputRef,
      maxLength,
      actionOnChange,
      actionOnComplete,
      onFocus,
      onBlur,
      onChange,
      type,
      icon,
      variant = 'outlined',
      pattern,
      inputProps = {},
      ...rest
    } = props;
    const value = valueProp || '';
    const { classes, cx } = useStyles();
    const ariaLabelComputed = getAriaLabel(props);

    const handleFocus = useCallback<NonNullable<TextFieldProps['onFocus']>>(
      (event) => {
        if (trackingName) {
          trackEntryStart({ action: trackingName, label: trackingLabel || (value as string) });
        }
        onFocus?.(event);
      },
      [trackingName, onFocus, trackingLabel, value],
    );

    const handleBlur = useCallback<NonNullable<TextFieldProps['onBlur']>>(
      (event) => {
        if (trackingName) {
          trackEntryComplete({
            action: trackingName,
            label: trackingLabel || event.target.value,
          });
        }
        if (!isHTMLElementWithValue(event.target)) {
          const message = 'Not a valid event target for Textfield';
          datadogLog({
            logType: 'error',
            message,
            context: {
              logOrigin: 'libs/components/src/TextField/TextField.tsx',
              functionOrigin: 'handleBlur',
            },
          });
          throw new Error(message);
        }
        actionOnComplete?.(event.target.value);
        onBlur?.(event);
      },
      [actionOnComplete, onBlur, trackingName, trackingLabel],
    );

    const handleChange = useCallback<NonNullable<TextFieldProps['onChange']>>(
      (event) => {
        actionOnChange?.(event.target.value);
        onChange?.(event);
      },
      [onChange, actionOnChange],
    );

    const capitalizedError = error && upperFirst(error);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = useCallback(() => {
      setShowPassword((prev) => !prev);
    }, []);

    const PasswordIcon = showPassword ? IconUIVisibilityOff : IconUIVisibility;

    return (
      <div className={cx(classes.root, className)}>
        {groupLabel && (
          <FormLabel
            component='legend'
            error={false}
            focused={false}
            className={classes.label}
            id={name && `${name}-label`}
          >
            {groupLabel}
          </FormLabel>
        )}
        <MuiTextField
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          className={classes.formControl}
          disabled={disabled}
          error={!!error}
          fullWidth={fullWidth}
          FormHelperTextProps={error ? errorHelperTextProps : undefined}
          // wrap help text in span to keep element in DOM so min-height keeps height the same with/without help/error text
          helperText={capitalizedError || helperText}
          id={id}
          type={type === 'password' && showPassword ? 'text' : type}
          inputProps={{
            ...inputProps,
            pattern: pattern,
            inputMode: rest.inputMode,
            'aria-label': ariaLabelComputed,
            'aria-labelledby': ariaLabelledby,
            'aria-disabled': ariaDisabled,
            maxLength,
            className: !value ? classes.inputPlaceholderDefault : undefined,
          }}
          InputProps={{
            notched: false,
            endAdornment: (
              <InputAdornment position='end'>
                {type === 'password' && (
                  <IconButton
                    onClick={handleClickShowPassword}
                    size='large'
                    aria-label='Show/Hide Password'
                  >
                    <PasswordIcon className={classes.icon} />
                  </IconButton>
                )}
                {icon || <div />}
              </InputAdornment>
            ),
            ...rest,
            classes: {
              root: classes.input,
              adornedStart: classes.iconAdornment,
              adornedEnd: classes.iconAdornment,
              disabled: classes.fieldDisabled,
            },
            value,
          }}
          InputLabelProps={{
            id: groupLabel ? undefined : name && `${name}-label`,
            shrink: !!placeholder,
          }}
          inputRef={inputRef || ref}
          label={formatLabel(label, classes.textTertiary)}
          name={name}
          placeholder={placeholder}
          role='group'
          required={required}
          // https://github.com/mui-org/material-ui/issues/15697
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          variant={variant as any}
        />
      </div>
    );
  }),
);
