import { useCallback, useMemo } from 'react';

import { generatePath, type Params, useLocation, useNavigate, useParams } from 'react-router-dom';

import type { PageKey } from '@ecp/features/servicing/shared/types';

import { INVERTED_PAGE_PATH, PAGE_PATH, URL_PARAM_PLACEHOLDER } from './constants';
import type { NavOptions, PagePreviousNextPaths, UsePageFlow } from './types';

/**
 * usePageFlow is a React Hook which provides an abstraction layer for the page-level routing service
 *
 * goBack and goForward use the current location pathname to determine their sibling routes on either side
 * navigate is simply exposing the usual useNavigate() functionality
 */
export const usePageFlow = (): UsePageFlow => {
  const navigate = useNavigate();
  const location = useLocation();
  const paramsJson = JSON.stringify(useParams());
  const params = useMemo(() => JSON.parse(paramsJson), [paramsJson]);
  const pathname = location.pathname as (typeof PAGE_PATH)[PageKey];

  const goBack = useCallback(
    (options?: NavOptions): void => {
      // only use this function in callback to ensure params are loaded from react router
      const invertedPagePathWithParams = replaceUrlParamsPlaceholdersWithActualParameters(params);
      const pagePreviousNext: PagePreviousNextPaths =
        pagePreviousNextPathMatrix[invertedPagePathWithParams[pathname]];
      if (pagePreviousNext) {
        const previousPage =
          typeof pagePreviousNext.previous === 'function'
            ? pagePreviousNext.previous(options || {})
            : pagePreviousNext.previous;
        navigate(previousPage, options);
      } else {
        console.warn('unmapped pathname in @ecp/features/servicing/shared/routing', pathname);
      }
    },
    [navigate, params, pathname],
  );
  const goForward = useCallback(
    (options?: NavOptions): void => {
      // only use this function in callback to ensure params are loaded from react router
      const invertedPagePathWithParams = replaceUrlParamsPlaceholdersWithActualParameters(params);
      const pagePreviousNext: PagePreviousNextPaths =
        pagePreviousNextPathMatrix[invertedPagePathWithParams[pathname]];
      if (pagePreviousNext) {
        const nextPage =
          typeof pagePreviousNext.next === 'function'
            ? pagePreviousNext.next(options || {})
            : pagePreviousNext.next;
        navigate(nextPage, options);
      } else {
        console.warn('unmapped pathname in @ecp/features/servicing/shared/routing', pathname);
      }
    },
    [navigate, params, pathname],
  );

  const currentPage = INVERTED_PAGE_PATH[pathname];

  return useMemo(
    () => ({
      goBack,
      goForward,
      navigate,
      currentPage,
    }),
    [goBack, goForward, navigate, currentPage],
  );
};

/**
 * A route matrix which provides a previous and next page for a given pathname
 */
const pagePreviousNextPathMatrix: Record<string, PagePreviousNextPaths> = {
  LOGOUT: {
    next: PAGE_PATH.LOGIN,
    previous: PAGE_PATH.LOGIN,
  },
  // create account
  CREATE_ACCOUNT: {
    previous: PAGE_PATH.AUTH_INDEX,
    next: PAGE_PATH.CREATE_ACCOUNT_VERIFY_IDENTITY,
  },
  CONTACTUS: {
    previous: PAGE_PATH.CREATE_ACCOUNT,
    next: '',
  },
  CREATE_ACCOUNT_VERIFY_IDENTITY: {
    previous: PAGE_PATH.CREATE_ACCOUNT,
    next: PAGE_PATH.CREATE_ACCOUNT_ONE_TIME_CODE,
  },
  CREATE_ACCOUNT_ONE_TIME_CODE: {
    previous: PAGE_PATH.CREATE_ACCOUNT_VERIFY_IDENTITY,
    next: PAGE_PATH.CREATE_ACCOUNT_CREATE_PASSWORD,
  },
  CREATE_ACCOUNT_CREATE_PASSWORD: {
    previous: PAGE_PATH.CREATE_ACCOUNT_ONE_TIME_CODE,
    next: PAGE_PATH.DASHBOARD_INDEX,
  },
  // recover email
  RECOVER_EMAIL: {
    previous: PAGE_PATH.AUTH_INDEX,
    next: PAGE_PATH.RECOVER_EMAIL_VERIFY_IDENTITY,
  },
  RECOVER_EMAIL_VERIFY_IDENTITY: {
    previous: PAGE_PATH.RECOVER_EMAIL,
    next: PAGE_PATH.RECOVER_EMAIL_ONE_TIME_CODE,
  },
  RECOVER_EMAIL_ONE_TIME_CODE: {
    previous: PAGE_PATH.RECOVER_EMAIL_VERIFY_IDENTITY,
    next: PAGE_PATH.RECOVER_EMAIL_ENTER_PASSWORD,
  },
  RECOVER_EMAIL_ENTER_PASSWORD: {
    previous: PAGE_PATH.RECOVER_EMAIL_ONE_TIME_CODE,
    next: PAGE_PATH.RECOVER_EMAIL_RESET_PASSWORD,
  },
  RECOVER_EMAIL_RESET_PASSWORD: {
    previous: PAGE_PATH.RECOVER_EMAIL_ENTER_PASSWORD,
    next: PAGE_PATH.DASHBOARD_INDEX,
  },
  // recover password
  RECOVER_PASSWORD: {
    previous: PAGE_PATH.AUTH_INDEX,
    next: PAGE_PATH.RECOVER_PASSWORD_VERIFY_IDENTITY,
  },
  RECOVER_PASSWORD_VERIFY_IDENTITY: {
    previous: PAGE_PATH.RECOVER_PASSWORD,
    next: PAGE_PATH.RECOVER_PASSWORD_ONE_TIME_CODE,
  },
  RECOVER_PASSWORD_ONE_TIME_CODE: {
    previous: PAGE_PATH.RECOVER_PASSWORD_VERIFY_IDENTITY,
    next: PAGE_PATH.RECOVER_PASSWORD_RESET_PASSWORD,
  },
  RECOVER_PASSWORD_RESET_PASSWORD: {
    previous: PAGE_PATH.RECOVER_PASSWORD_ONE_TIME_CODE,
    next: PAGE_PATH.DASHBOARD_INDEX,
  },
};

/**
 * removing the prefix from our page paths (like /auth) as the prefix is routed in App.tsx but needed elsewhere to match the full pathname
 */
export const removePageFlowPrefixFromPaths = (prefix: string): Record<PageKey, string> =>
  Object.entries(PAGE_PATH).reduce((pathObject, [key, value]) => {
    const pathPieces = value.split(prefix);

    return {
      ...pathObject,
      [key]: pathPieces[pathPieces.length - 1], // the last piece of the path, excluding /auth
    };
  }, {} as Record<PageKey, string>);

/**
 * Method replaces url parameter placeholders with the current url parameter values to allow for page flow matching
 * Example: the route https://example.com/test/123 is defined as "/test/:id" and with this function is updated to "/test/123"
 * @param params
 */
export const replaceUrlParamsPlaceholdersWithActualParameters = (
  params: Readonly<Params<string>> | undefined,
): Record<string, string> => {
  let invertedPagePathWithParams = INVERTED_PAGE_PATH;
  // no need to do logic if params is undefined
  if (params) {
    invertedPagePathWithParams = Object.entries(INVERTED_PAGE_PATH).reduce(
      (pathObject, [key, value]) => {
        let updatedKey = key;
        // first check if there's an url param placeholder in the route key
        if (updatedKey.indexOf(URL_PARAM_PLACEHOLDER) !== -1) {
          try {
            // try to generate an interpolated key using the params available
            updatedKey = generatePath(key as string, params) as (typeof PAGE_PATH)[PageKey];
          } catch (error) {
            // purposefully do nothing, it means that the key has params references which aren't available in the object
          }
        }

        return {
          ...pathObject,
          [updatedKey]: value,
        };
      },
      {} as Record<(typeof PAGE_PATH)[PageKey], PageKey>,
    );
  }

  return invertedPagePathWithParams;
};
