import * as yup from 'yup';

import type { YupSchemaMap } from '@ecp/utils/form';
import { accountRegex, routingRegex } from '@ecp/utils/payment';

export interface AddPaymentMethodInputs {
  routingNumber: string;
  accountNumber: string;
  verifyAccountNumber: string;
  accountType: string;
}

export const addAccountPaymentMethodSchema: YupSchemaMap<AddPaymentMethodInputs> = {
  routingNumber: yup
    .string()
    .required('Routing number is required')
    .matches(routingRegex, 'Please enter a valid routing number'),
  accountNumber: yup
    .string()
    .required('Account number is required')
    .matches(accountRegex, 'Please enter a valid account number'),
  verifyAccountNumber: yup
    .string()
    .required('Verify account number is required')
    .matches(accountRegex, 'Please enter a valid verify account number')
    .oneOf([yup.ref('accountNumber'), null], 'Account numbers do not match'),
  accountType: yup.string().required('Account type is required'),
};
