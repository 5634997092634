import type { TableProps } from '@ecp/types';

export const PersonalInjuryProtectionMetadata: {
  [productKey: string]: Record<string, TableProps>;
} = {
  'amfam.auto': {
    FL: {
      tableHeaderText:
        'PIP provides benefits for an insured injured in a car-related accident, no matter who is at fault. No-fault does not cover damage to any property, such as cars.',
      tableFooterText:
        'The descriptions contained herein are only a general description of terms available coverages and are not a statement of contract. All coverages are subject to all policy provisions and applicable endorsements.',
      tableDataHeaders: [
        'Coverages',
        'Personal Injury Protection',
        'Extended Personal Injury Protection',
      ],
      tableData: [
        {
          coverages: 'Medical Expenses',
          personalInjuryProtection: '80%',
          extendedPersonalInjuryProtection: (
            <>
              <p>100% - Named Insured, spouse and dependent relatives</p>
              <p>80% - Anyone other then named insured, spouse and dependent relatives</p>
            </>
          ),
        },
        {
          coverages: 'Work Loss',
          personalInjuryProtection: '60%',
          extendedPersonalInjuryProtection: (
            <>
              <p>80% - Named Insured, spouse and dependent relatives</p>
              <p>60% - Anyone other then named insured, spouse and dependent relatives</p>
            </>
          ),
        },
        {
          coverages: 'Replacement Services',
          personalInjuryProtection: '100%',
          extendedPersonalInjuryProtection: '100%',
        },
        {
          coverages: 'Death Benefits',
          personalInjuryProtection: '$5,000',
          extendedPersonalInjuryProtection: '$5,000',
        },
      ],
    },
    KS: {
      tableHeaderText:
        'Personal Injury Protection provides benefits for an insured injured in a car-related accident, no matter who is at fault. No-fault does not cover damage to any property, such as cars.',
      tableFooterText:
        'The descriptions contained herein are only a general description of terms available coverages and are not a statement of contract. All coverages are subject to all policy provisions and applicable endorsements.',
      tableDataHeaders: ['Coverages', 'Basic', 'Extra', 'Deluxe'],
      tableData: [
        {
          coverages: 'Medical Expense (per person)',
          basic: '$4,500',
          extra: '$4,500',
          deluxe: '$27,500',
        },
        {
          coverages: 'Rehabilitation Expenses (per person)',
          basic: '$4,500',
          extra: '$4,500',
          deluxe: '$4,500',
        },
        {
          coverages: 'Work Loss (per month per person)',
          basic: '$900',
          extra: '$1,750',
          deluxe: '$1,750',
        },
        {
          coverages: 'Essentials Services Expense (per day 365 days max)',
          basic: '$25',
          extra: '$25',
          deluxe: '$25',
        },
        {
          coverages: 'Funeral Expenses (per person)',
          basic: '$2,000',
          extra: '$2,000',
          deluxe: '$2,000',
        },
        {
          coverages: 'Survivors Loss (per month max)',
          basic: '$900',
          extra: '$900',
          deluxe: '$900',
        },
      ],
    },
    MN: {
      tableHeaderText:
        'Personal Injury Protection provides benefits for an insured injured in a car-related accident, no matter who is at fault. No-fault does not cover damage to any property, such as cars.',
      tableFooterText:
        'The descriptions contained herein are only a general description of terms available coverages and are not a statement of contract. All coverages are subject to all policy provisions and applicable endorsements.',
      tableDataHeaders: ['Coverages', 'A', 'B', 'C', 'D', 'F', 'H', 'K', 'M'],
      tableData: [
        {
          coverages: 'Total Aggregate Limit (All Coverages)',
          a: '$40,000',
          b: '$50,000',
          c: '$60,000',
          d: '$70,000',
          f: '$60,000',
          h: '$70,000',
          k: '$80,000',
          m: '$90,000',
        },
        {
          coverages: 'Medical Expense',
          a: '$20,000',
          b: '$30,000',
          c: '$40,000',
          d: '$50,000',
          f: '$20,000',
          h: '$30,000',
          k: '$40,000',
          m: '$50,000',
        },
        {
          coverages:
            'Aggregate Limit for Workloss, Essential Services Expenses, Funeral Expenses, and Survivors Loss',
          a: '$20,000',
          b: '$20,000',
          c: '$20,000',
          d: '$20,000',
          f: '$40,000',
          h: '$40,000',
          k: '$40,000',
          m: '$40,000',
        },
        {
          coverages: 'Work Loss (per week)',
          a: '$250',
          b: '$250',
          c: '$250',
          d: '$250',
          f: '$400',
          h: '$400',
          k: '$400',
          m: '$400',
        },
        {
          coverages: 'Essential Services Expense (per week)',
          a: '$200',
          b: '$200',
          c: '$200',
          d: '$200',
          f: '$200',
          h: '$200',
          k: '$200',
          m: '$200',
        },
        {
          coverages: 'Funeral Expenses',
          a: '$2,000',
          b: '$2,000',
          c: '$2,000',
          d: '$2,000',
          f: '$2,000',
          h: '$2,000',
          k: '$2,000',
          m: '$2,000',
        },
        {
          coverages: 'Survivors Economic Loss (per week)',
          a: '$200',
          b: '$200',
          c: '$200',
          d: '$200',
          f: '$200',
          h: '$200',
          k: '$200',
          m: '$200',
        },
        {
          coverages: 'Survivors Replacement Services Loss (per week)',
          a: '$200',
          b: '$200',
          c: '$200',
          d: '$200',
          f: '$200',
          h: '$200',
          k: '$200',
          m: '$200',
        },
      ],
    },
    ND: {
      tableHeaderText:
        'Personal Injury Protection provides benefits for an insured injured in a car-related accident, no matter who is at fault. No-fault does not cover damage to any property, such as cars.',
      tableFooterText:
        'The descriptions contained herein are only a general description of terms available coverages and are not a statement of contract. All coverages are subject to all policy provisions and applicable endorsements.',
      tableDataHeaders: ['Coverages', 'A', 'B', 'C'],
      tableData: [
        {
          coverages: 'Basic Total Aggregator Limits (all coverages)',
          a: '$30,000',
          b: '$80,000',
          c: '$110,000',
        },
        {
          coverages: 'Medical Expenses (per person)',
          a: 'Subject to Aggregate Limit',
          b: 'Subject to Aggregate Limit',
          c: 'Subject to Aggregate Limit',
        },
        {
          coverages: 'Rehabilitation Expenses (per person)',
          a: 'Subject to Aggregate Limit',
          b: 'Subject to Aggregate Limit',
          c: 'Subject to Aggregate Limit',
        },
        {
          coverages: 'Basic Work Loss (per week)',
          a: '$150',
          b: '$350',
          c: '$350',
        },
        {
          coverages: 'Replacement Services Loss (per day)',
          a: '$15',
          b: '$15',
          c: '$15',
        },
        {
          coverages: 'Survivors Income Loss (per week)',
          a: '$150',
          b: '$150',
          c: '$150',
        },
        {
          coverages: 'Survivors Replacement Services Loss (per day)',
          a: '$15',
          b: '$15',
          c: '$15',
        },
        {
          coverages: 'Funeral Expenses',
          a: '$3,500',
          b: '$3,500',
          c: '$3,500',
        },
      ],
    },
    NJ: {
      tableHeaderText:
        'Personal Injury Protection provides benefits for an insured injured in a car-related accident, no matter who is at fault. No-fault does not cover damage to any property, such as cars.',
      tableFooterText:
        'The descriptions contained herein are only a general description of terms available coverages and are not a statement of contract. All coverages are subject to all policy provisions and applicable endorsements.',
      tableDataHeaders: [
        'Options',
        ['Income Continuation', 'Weekly Benefit', 'Maximum Benefit'],
        ['Essential Services', 'Daily Benefit', 'Maximum Benefit'],
        ['Funeral Expense', 'Maximum Benefit'],
        'Death Benefit',
      ],
      tableData: [
        {
          Options: 'Base',
          incomeContinuationWeeklyBenefit: '$100',
          incomeContinuationMaximumBenefit: '$5,200',
          essentialServicesDailyBenefit: '$12',
          essentialServicesMaximumBenefit: '$4,380',
          funeralExpenseMaximumBenefit: '$1,000',
          deathBenefit: '(Uncollected "Base" Income Continuation and Essential Services Benefits)',
        },
        {
          Options: '1',
          incomeContinuationWeeklyBenefit: '$100',
          incomeContinuationMaximumBenefit: '$10,400',
          essentialServicesDailyBenefit: '$12',
          essentialServicesMaximumBenefit: '$8,760',
          funeralExpenseMaximumBenefit: '$2,000',
          deathBenefit: '$10,000',
        },
        {
          Options: '2',
          incomeContinuationWeeklyBenefit: '$125',
          incomeContinuationMaximumBenefit: '$13,000',
          essentialServicesDailyBenefit: '$20',
          essentialServicesMaximumBenefit: '$14,600',
          funeralExpenseMaximumBenefit: '$2,000',
          deathBenefit: '$10,000',
        },
        {
          Options: '3',
          incomeContinuationWeeklyBenefit: '$175',
          incomeContinuationMaximumBenefit: '$18,200',
          essentialServicesDailyBenefit: '$20',
          essentialServicesMaximumBenefit: '$14,600',
          funeralExpenseMaximumBenefit: '$2,000',
          deathBenefit: '$10,000',
        },
        {
          Options: '4',
          incomeContinuationWeeklyBenefit: '$250',
          incomeContinuationMaximumBenefit: '$26,000',
          essentialServicesDailyBenefit: '$20',
          essentialServicesMaximumBenefit: '$14,600',
          funeralExpenseMaximumBenefit: '$2,000',
          deathBenefit: '$10,000',
        },
        {
          Options: '5',
          incomeContinuationWeeklyBenefit: '$400',
          incomeContinuationMaximumBenefit: '$41,600',
          essentialServicesDailyBenefit: '$20',
          essentialServicesMaximumBenefit: '$14,600',
          funeralExpenseMaximumBenefit: '$2,000',
          deathBenefit: '$10,000',
        },
        {
          Options: '6',
          incomeContinuationWeeklyBenefit: '$500',
          incomeContinuationMaximumBenefit: '$52,000',
          essentialServicesDailyBenefit: '$20',
          essentialServicesMaximumBenefit: '$14,600',
          funeralExpenseMaximumBenefit: '$2,000',
          deathBenefit: '$10,000',
        },
        {
          Options: '7',
          incomeContinuationWeeklyBenefit: '$600',
          incomeContinuationMaximumBenefit: '$62,400',
          essentialServicesDailyBenefit: '$20',
          essentialServicesMaximumBenefit: '$14,600',
          funeralExpenseMaximumBenefit: '$2,000',
          deathBenefit: '$10,000',
        },
        {
          Options: '8',
          incomeContinuationWeeklyBenefit: '$700',
          incomeContinuationMaximumBenefit: '$72,800',
          essentialServicesDailyBenefit: '$20',
          essentialServicesMaximumBenefit: '$14,600',
          funeralExpenseMaximumBenefit: '$2,000',
          deathBenefit: '$10,000',
        },
        {
          Options: '9',
          incomeContinuationWeeklyBenefit: '$100',
          incomeContinuationMaximumBenefit: 'Unlimited',
          essentialServicesDailyBenefit: '$12',
          essentialServicesMaximumBenefit: '$8,760',
          funeralExpenseMaximumBenefit: '$2,000',
          deathBenefit: '$10,000',
        },
        {
          Options: '10',
          incomeContinuationWeeklyBenefit: '$125',
          incomeContinuationMaximumBenefit: 'Unlimited',
          essentialServicesDailyBenefit: '$20',
          essentialServicesMaximumBenefit: '$14,600',
          funeralExpenseMaximumBenefit: '$2,000',
          deathBenefit: '$10,000',
        },
        {
          Options: '11',
          incomeContinuationWeeklyBenefit: '$175',
          incomeContinuationMaximumBenefit: 'Unlimited',
          essentialServicesDailyBenefit: '$20',
          essentialServicesMaximumBenefit: '$14,600',
          funeralExpenseMaximumBenefit: '$2,000',
          deathBenefit: '$10,000',
        },
        {
          Options: '12',
          incomeContinuationWeeklyBenefit: '$250',
          incomeContinuationMaximumBenefit: 'Unlimited',
          essentialServicesDailyBenefit: '$20',
          essentialServicesMaximumBenefit: '$14,600',
          funeralExpenseMaximumBenefit: '$2,000',
          deathBenefit: '$10,000',
        },
        {
          Options: '13',
          incomeContinuationWeeklyBenefit: '$400',
          incomeContinuationMaximumBenefit: 'Unlimited',
          essentialServicesDailyBenefit: '$20',
          essentialServicesMaximumBenefit: '$14,600',
          funeralExpenseMaximumBenefit: '$2,000',
          deathBenefit: '$10,000',
        },
        {
          Options: '14',
          incomeContinuationWeeklyBenefit: '$500',
          incomeContinuationMaximumBenefit: 'Unlimited',
          essentialServicesDailyBenefit: '$20',
          essentialServicesMaximumBenefit: '$14,600',
          funeralExpenseMaximumBenefit: '$2,000',
          deathBenefit: '$10,000',
        },
        {
          Options: '15',
          incomeContinuationWeeklyBenefit: '$600',
          incomeContinuationMaximumBenefit: 'Unlimited',
          essentialServicesDailyBenefit: '$20',
          essentialServicesMaximumBenefit: '$14,600',
          funeralExpenseMaximumBenefit: '$2,000',
          deathBenefit: '$10,000',
        },
        {
          Options: '16',
          incomeContinuationWeeklyBenefit: '$700',
          incomeContinuationMaximumBenefit: 'Unlimited',
          essentialServicesDailyBenefit: '$20',
          essentialServicesMaximumBenefit: '$14,600',
          funeralExpenseMaximumBenefit: '$2,000',
          deathBenefit: '$10,000',
        },
      ],
    },
    OR: {
      tableHeaderText:
        'Personal Injury Protection provides benefits for an insured injured in a car-related accident, no matter who is at fault. No-fault does not cover damage to any property, such as cars.',
      tableFooterText:
        'The descriptions contained herein are only a general description of terms available coverages and are not a statement of contract. All coverages are subject to all policy provisions and applicable endorsements.',
      tableDataHeaders: [
        'Options',
        'Deductible (Per Accident)',
        'Medical Expense Limit',
        ['Work Loss Reimbursement', '% of Wages', 'Max/Per Month', 'Months'],
        ['Essential Services Expenses', '(Per Day - 52 Week Max)'],
        ['Funeral Expenses', '(Per Person)'],
        ['Child Care Expenses', '$25/Day /n $750 Aggregate'],
      ],
      tableData: [
        {
          Options: 'Α',
          deductible: 'None',
          medicalExpenseLimit: '$15,000',
          workLossReimbursementOfWages: '70%',
          workLossReimbursementMaxPerMonth: '$3,000',
          workLossReimbursementMonths: '12',
          essentialServicesExpensesPerDay: '$30',
          funeralExpensesPerPerson: '$5,000',
          childCareExpensesByDay: '$25',
        },
        {
          Options: 'B',
          deductible: '$250',
          medicalExpenseLimit: '$15,000',
          workLossReimbursementOfWages: '70%',
          workLossReimbursementMaxPerMonth: '$3,000',
          workLossReimbursementMonths: '12',
          essentialServicesExpensesPerDay: '$30',
          funeralExpensesPerPerson: '$5,000',
          childCareExpensesByDay: '$25',
        },
        {
          Options: 'C',
          deductible: 'None',
          medicalExpenseLimit: '$25,000',
          workLossReimbursementOfWages: '70%',
          workLossReimbursementMaxPerMonth: '$3,000',
          workLossReimbursementMonths: '12',
          essentialServicesExpensesPerDay: '$30',
          funeralExpensesPerPerson: '$5,000',
          childCareExpensesByDay: '$25',
        },
        {
          Options: 'D',
          deductible: '$250',
          medicalExpenseLimit: '$25,000',
          workLossReimbursementOfWages: '70%',
          workLossReimbursementMaxPerMonth: '$3,000',
          workLossReimbursementMonths: '12',
          essentialServicesExpensesPerDay: '$30',
          funeralExpensesPerPerson: '$5,000',
          childCareExpensesByDay: '$25',
        },
        {
          Options: 'E',
          deductible: 'None',
          medicalExpenseLimit: '$50,000',
          workLossReimbursementOfWages: '70%',
          workLossReimbursementMaxPerMonth: '$3,000',
          workLossReimbursementMonths: '12',
          essentialServicesExpensesPerDay: '$30',
          funeralExpensesPerPerson: '$5,000',
          childCareExpensesByDay: '$25',
        },
        {
          Options: 'F',
          deductible: 'None',
          medicalExpenseLimit: '$100,000',
          workLossReimbursementOfWages: '70%',
          workLossReimbursementMaxPerMonth: '$3,000',
          workLossReimbursementMonths: '12',
          essentialServicesExpensesPerDay: '$30',
          funeralExpensesPerPerson: '$5,000',
          childCareExpensesByDay: '$25',
        },
      ],
    },
    UT: {
      tableHeaderText:
        'Personal Injury Protection provides benefits for an insured injured in a car-related accident, no matter who is at fault. No-fault does not cover damage to any property, such as cars.',
      tableFooterText:
        'The descriptions contained herein are only a general description of terms available coverages and are not a statement of contract. All coverages are subject to all policy provisions and applicable endorsements.',
      tableDataHeaders: ['Coverages', 'A', 'B', 'C', 'D', 'E'],
      tableData: [
        {
          coverages: 'Medical Expense',
          a: '$3,000',
          b: '$5,000',
          c: '$10,000',
          d: '$25,000',
          E: '$100,000',
        },
        {
          coverages: 'Work Loss',
          a: '$250',
          b: '$250',
          c: '$250',
          d: '$250',
          E: '$300',
        },
        {
          coverages: 'Essentials Services',
          a: '$20',
          b: '$20',
          c: '$20',
          d: '$20',
          E: '$20',
        },
        {
          coverages: 'Funeral Expenses',
          a: '$1,500',
          b: '$1,500',
          c: '$1,500',
          d: '$1,500',
          E: '$2,500',
        },
        {
          coverages: "Survivor's Loss",
          a: '$3,000',
          b: '$3,000',
          c: '$5,000',
          d: '$5,000',
          E: '$10,000',
        },
      ],
    },
    WA: {
      tableHeaderText:
        'Personal Injury Protection provides benefits for an insured injured in a car-related accident, no matter who is at fault. No-fault does not cover damage to any property, such as cars.',
      tableFooterText:
        'The descriptions contained herein are only a general description of terms available coverages and are not a statement of contract. All coverages are subject to all policy provisions and applicable endorsements.',
      tableDataHeaders: ['Coverages', 'A', 'B'],
      tableData: [
        {
          coverages: 'Medical Expense and Hospital Benefits',
          a: '$10,000',
          b: '$35,000',
        },
        {
          coverages: 'Funeral Expense Benefit',
          a: '$2,000',
          b: '$2,000',
        },
        {
          coverages: 'Income Contribution - Max Total Benefits',
          a: '$10,000',
          b: '$35,000',
        },
        {
          coverages: 'Income Contribution - 85% of Weekly Income',
          a: '$200',
          b: '$700',
        },
        {
          coverages: 'Loss of Services - Max Total Benefit',
          a: '$5,000',
          b: '$14,600',
        },
        {
          coverages: 'Loss of Services - Maximum Daily Benefit',
          a: '$40',
          b: '$40',
        },
        {
          coverages: 'Loss of Services - Maximum Weekly Benefit',
          a: '$200',
          b: '$280',
        },
      ],
    },
  },
  'connect.auto': {
    KS: {
      tableHeaderText:
        'Personal Injury Protection may provide benefits to cover an eligible insured’s injuries caused by an accident arising out of the ownership, operation, maintenance or use of a motor vehicle, no matter who causes the accident.',
      tableFooterText:
        'These are only general descriptions of available coverages. All coverages are subject to policy provisions and applicable endorsements.',
      tableDataHeaders: ['Coverages', 'Basic', 'Additional Option A', 'Additional Option B'],
      tableData: [
        {
          coverages: 'Medical Expense (per person)',
          basic: '$4,500',
          extra: '$12,500',
          deluxe: '$27,500',
        },
        {
          coverages: 'Rehabilitation Expenses (per person)',
          basic: '$4,500',
          extra: '$12,500',
          deluxe: '$27,500',
        },
        {
          coverages: 'Work Loss (per month per person)',
          basic: '$900',
          extra: '$1,050',
          deluxe: '$1,250',
        },
        {
          coverages: 'Essentials Services Expense (per day)',
          basic: '$25',
          extra: '$25',
          deluxe: '$25',
        },
        {
          coverages: 'Funeral Expenses (per person)',
          basic: '$2,000',
          extra: '$2,000',
          deluxe: '$2,500',
        },
        {
          coverages: 'Survivors Loss (per month max)',
          basic: '$900',
          extra: '$1,050',
          deluxe: '$1,550',
        },
        {
          coverages: 'Benefit Period',
          basic: '1 year',
          extra: '1 year',
          deluxe: '2 years',
        },
      ],
    },
  },
};
