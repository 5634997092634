import { useCallback, useState } from 'react';

import { Divider, Stack } from '@mui/material';

// import { DateConstants, formatDate } from '@ecp/utils/date';
import { Form, useForm } from '@ecp/utils/form';

import { Button } from '@ecp/components';
// import { env } from '@ecp/env';
import {
  ElectronicSignatureField,
  LoadingButton,
  LoadingOverlay,
  ModalError,
} from '@ecp/features/servicing/shared/components';
import type { UseModalPathReturn as AutopayReviewProps } from '@ecp/features/servicing/shared/routing';
import {
  getPolicyDisplayType,
  // useAutopayEnroll,
  usePolicy,
  useSharedState,
  useUser,
} from '@ecp/features/servicing/shared/state';
import type { AutopayInformation } from '@ecp/features/servicing/shared/types';
import {
  GraphicPaymentAmexImageUrl,
  GraphicPaymentMastercardImageUrl,
  GraphicPaymentVisaImageUrl,
  IconUISecure,
} from '@ecp/themes/base';

import { useStyles } from './AutopayReview.styles';
import { autopayReviewSchema } from './AutopayReviewInputs.schema';
import { LegalCopy } from './LegalCopy';

export const AutopayReviewClassic: React.FC<AutopayReviewProps> = (props) => {
  const { currentFlow, goForward, goBack, policyNumber, sharedStateKey } = props;
  const { classes } = useStyles();
  const [submitError, setSubmitError] = useState<unknown>();

  const [sharedState, setSharedState] = useSharedState<AutopayInformation>(sharedStateKey);

  const { policyData: policyResponse } = usePolicy(policyNumber);
  const policyType = getPolicyDisplayType(policyResponse);
  const isAuto = policyResponse?.coverageOptions[0].productLine === 'AUTO';
  const { user, isLoading: isUserLoading } = useUser();

  const formContext = useForm({
    validations: autopayReviewSchema,
  });
  const { handleSubmit } = formContext;
  // TODO: Uncomment all commented code bellow on api integration, temporary commented for testing confirmation screen
  // const reviewPageLoadTime = useMemo(() => new Date().toISOString(), []);

  // const { autoPay } = useAutopayEnroll();
  const onSubmit = useCallback(() => {
    if (!user) throw new Error('User is undefined');
    // POL-6713 Below code will need an update after iframe and api integration. We do have any information about payload for classic autopay endpoint.
    handleSubmit(async (data) => {
      if (!data.electronicSignature) throw Error('Electronic signature is undefined');
      try {
        // const todaysDateAndTime = new Date();
        // const response = await autoPay({
        //   primeKey: sharedState?.paymentMethodId,
        //   billingAccount: policyResponse?.policy.billingAccountNumber,
        //   policySource: policyResponse?.policy.sourceSystemName,
        //   paymentMethodType: sharedState?.paymentAccountMethod?.type,
        //   autopayEnrolled: true,
        //   paymentAmount: 0,
        //   auditInfo: {
        //     requestedDateTime: reviewPageLoadTime,
        //     requestedBy: user.email,
        //     requestingSystem: env.static.sourceId,
        //     consumerStep: 'Enroll in AutoPay - Review AutoPay Details',
        //     acknowledgement: data.electronicSignature,
        //     whenAccepted: formatDate(todaysDateAndTime.toString(), DateConstants.ANSWERS_FORMAT),
        //   },
        // });
        const response = {
          success: {
            policyNumber: '73564676',
            type: 'EFT',
            autopayEnrolled: 'true',
            paymentAmount: '0.0',
            accountNumber: '9355',
            routingNumber: '056008849',
            efmprimekey: '0003295098',
            nextPaymentDate: '10/08/2023',
            nickname: 'null',
          },
        };
        if (response.success) {
          setSharedState({
            ...(sharedState as AutopayInformation),
            paymentAmountForEnrollmentConfirmation: response.success?.paymentAmount,
            nextPaymentDate: response.success?.nextPaymentDate,
            autopayEnrolled: response.success?.autopayEnrolled.toLowerCase(),
          });

          goForward();
        }
        // else if (response.error) {
        //   throw new Error('Failed to update autopay');
        // }
      } catch (error) {
        setSharedState({ ...(sharedState as AutopayInformation), isError: true });
        if (currentFlow === 'autopayenroll') {
          setSubmitError(error);
        } else {
          goBack();
        }
      }
    })();
  }, [
    goForward,
    sharedState,
    setSharedState,
    goBack,
    // autoPay,
    user,
    // policyResponse?.policy.sourceSystemName,
    handleSubmit,
    // reviewPageLoadTime,
    // policyResponse?.policy.billingAccountNumber,
    currentFlow,
  ]);

  const handleBackClick = useCallback(() => goBack(), [goBack]);

  const isLoading = isUserLoading;

  if (isLoading) return <LoadingOverlay />;
  if (submitError) {
    const errorDesc = (
      <span>
        enrolling your{' '}
        <b>
          {policyType} policy {policyNumber}
        </b>{' '}
        in AutoPay
      </span>
    );

    return <ModalError errorTitle='enroll your policy in AutoPay' errorDescription={errorDesc} />;
  }

  return (
    <Stack spacing={3}>
      <Form onSubmit={onSubmit} formProviderProps={formContext} name='updateEnrollAutopayClassic'>
        <Stack spacing={3}>
          <Stack direction='row' className={classes.groupLabelStack} spacing={1}>
            <IconUISecure />
            <h3>Enter your payment information</h3>
          </Stack>
          <Stack direction='row' spacing={2}>
            <p className={classes.iconTitle}>Accepted cards</p>
            <Stack direction='row' spacing={1.5}>
              <img className={classes.image} src={GraphicPaymentVisaImageUrl} alt='visa-card' />
              <img
                className={classes.image}
                src={GraphicPaymentMastercardImageUrl}
                alt='master-card'
              />
              <img
                className={classes.imageWithPadding}
                src={GraphicPaymentAmexImageUrl}
                alt='amex-card'
              />
            </Stack>
          </Stack>
          <p>IFrame will be added here</p>
          <Divider />
          <LegalCopy isAuto={isAuto} isClassic />
          <Stack spacing={1.5}>
            <span className={classes.signText}>Sign below to enroll in AutoPay</span>
            <ElectronicSignatureField />
          </Stack>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            className={classes.buttonGroup}
            spacing={3}
          >
            <LoadingButton
              type='submit'
              trackingName='enroll_autopay_button'
              trackingLabel='enroll_review_continue'
              className={classes.submitButtton}
            >
              Enroll and finish
            </LoadingButton>
            <Button
              className={classes.backButton}
              variant='iconTextMedium'
              onClick={handleBackClick}
            >
              Back
            </Button>
          </Stack>
        </Stack>
      </Form>
    </Stack>
  );
};
