import { Stack } from '@mui/material';

import { useLoggerWithAuth } from '@ecp/features/servicing/shared/state';

import type { StyleProps } from './DashboardCard.styles';
import { useStyles } from './DashboardCard.styles';
import { DashboardCardContent } from './DashboardCardContent';

interface Props extends StyleProps {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  header?: React.ReactNode;
  secondaryContent?: React.ReactElement;
  /** default 'top' */
  secondaryContentAlign?: 'children' | 'top';
}

export const DashboardCard: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    children,
    header,
    secondaryContent,
    secondaryContentBreakpoint = 'lg',
    secondaryContentAlign = 'top',
  } = props;
  const { classes, cx } = useStyles(props, { props });

  const styledSecondaryContent = secondaryContent && (
    <div
      className={cx(
        classes.secondaryContent,
        secondaryContent?.props.className,
        secondaryContent?.props.classes?.root,
      )}
    >
      {secondaryContent}
    </div>
  );

  useLoggerWithAuth();

  const content = <div className={classes.childWrapper}>{children}</div>;

  const headerContent =
    typeof header === 'string' ? <h1 className={classes.title}>{header}</h1> : header;

  return (
    <DashboardCardContent>
      <Stack direction={{ xs: 'column', [secondaryContentBreakpoint]: 'row' }} spacing={5}>
        <Stack spacing={{ xs: 3, md: 5 }} className={classes.childWrapper}>
          {headerContent}
          <Stack direction={{ xs: 'column', [secondaryContentBreakpoint]: 'row' }} spacing={5}>
            {content}
            {secondaryContentAlign === 'children' && styledSecondaryContent}
          </Stack>
        </Stack>
        {secondaryContentAlign === 'top' && styledSecondaryContent}
      </Stack>
    </DashboardCardContent>
  );
};
