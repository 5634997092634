import { memo, useCallback, useState } from 'react';

import { Stack, Typography } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';

import { DateConstants, formatDate, isOlderThan } from '@ecp/utils/date';
import { flagValues } from '@ecp/utils/flags';

import { Alert, PhoneLink, ScrollSpy, ScrollSpyMarkerClasses } from '@ecp/components';
import { AutopayModal } from '@ecp/features/servicing/autopay';
import { CaMileageAlert, CaMileageModal } from '@ecp/features/servicing/ca-mileage';
import { PaymentClassicModal, PaymentModal } from '@ecp/features/servicing/payments';
import { PaperlessModal } from '@ecp/features/servicing/preferences';
import {
  ApiAlert,
  ContactUsMicro,
  DashboardCard,
  MakePaymentButton,
  Section,
} from '@ecp/features/servicing/shared/components';
import {
  getPolicyDisplayType,
  getProductLineFromPolicyResponse,
  isLegacyPolicy,
  useBookRollPolicies,
  usePolicy,
} from '@ecp/features/servicing/shared/state';
import { getBrandData, useDecodedParams } from '@ecp/features/servicing/shared/util';

import { DetailsSection, DocumentSection, EditVinModal } from '../../components';
import { AddDriverModal } from '../../components/AddDriverModal';
import { AdditionalInterestModal } from '../../components/AdditionalInterestModal';
import { AddVehicleModal } from '../../components/AddVehicleModal';
import { CoveragesSection } from '../../components/CoveragesSection';
import { MembershipModal } from '../../components/DetailsSection/Membership';
import { DiscountsSection } from '../../components/DiscountsSection';
import { PaymentsSection } from '../../components/PaymentsSection';
import { useStyles } from './PolicyDetailsPage.styles';

export interface SectionFailuresList {
  payment: boolean;
  policy: boolean;
  document: boolean;
}
type SectionName = keyof SectionFailuresList;

const SectionFallback: React.FC<{ type: string }> = ({ type }) => (
  <ApiAlert apiDescription={type} />
);

const PolicyErrorSectionFallback: React.FC<{ type: string }> = ({ type }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.unavailableSection}>
      <p>Your {type} information is currently unavailable.</p>
    </div>
  );
};

const DetailsDefaultFallback: React.FC = () => <SectionFallback type='policy' />;

const DetailsPolicyApiFallback: React.FC = () => <PolicyErrorSectionFallback type='policy' />;

const PaymentsDefaultFallback: React.FC = () => (
  <Alert type='error' withIcon>
    <Typography variant='body1Bold'>Your payment cannot be made online at this time.</Typography>
    <p>Please try again later.</p>
  </Alert>
);

const CoveragesDefaultFallback: React.FC = () => <SectionFallback type='coverages' />;
const CoveragesPolicyApiFallback: React.FC = () => <PolicyErrorSectionFallback type='coverages' />;
const DiscountsDefaultFallback: React.FC = () => <SectionFallback type='discounts' />;
const DiscountsPolicyApiFallback: React.FC = () => <PolicyErrorSectionFallback type='discount' />;
const DocumentsSectionFallback: React.FC = () => <SectionFallback type='documents' />;
const PaymentsPolicyApiFallback: React.FC = () => <PolicyErrorSectionFallback type='payment' />;

export const PolicyDetailsPage: React.FC = memo(() => {
  const [errorState, setErrorState] = useState<SectionFailuresList>({
    payment: false,
    policy: false,
    document: false,
  });
  const { classes } = useStyles();

  const updateErrorState = useCallback(
    (apiName: SectionName) => {
      setErrorState((prev) => {
        return { ...prev, [apiName]: true };
      });
    },
    [setErrorState],
  );

  // Throws an error if all apis are fail
  if (Object.values(errorState).every((apiFailure: boolean) => apiFailure === true)) {
    throw new Error('All sections failed to load on PolicyDetailsPage');
  }

  const updatePaymentErrorState = useCallback(
    () => updateErrorState('payment'),
    [updateErrorState],
  );
  const updatePolicyErrorState = useCallback(() => updateErrorState('policy'), [updateErrorState]);
  const updateDocumentsErrorState = useCallback(
    () => updateErrorState('document'),
    [updateErrorState],
  );

  const { policyNumber = '' } = useDecodedParams();

  const policyResponse = usePolicy(policyNumber, { throwOnError: false });
  const bookRollPolicies = useBookRollPolicies();
  const convertedFromClassic = bookRollPolicies.bookRolledHomesitePolicies?.find(
    (policy) => policy.policyData.policy.policyNumber === policyNumber,
  );

  const shouldShowInForceBookRollAlert =
    convertedFromClassic &&
    policyResponse?.policyData?.policy.status === 'In Force' &&
    !isOlderThan(30, 'day', policyResponse?.policyData?.policy.periodStartDate);
  const shouldShowScheduledBookRollAlert =
    convertedFromClassic && policyResponse?.policyData?.policy.status === 'Scheduled';
  const productLine = getProductLineFromPolicyResponse(policyResponse.policyData);
  const isClassic = isLegacyPolicy(productLine, policyResponse.policyData?.policy.sourceSystemName);
  const isClassicDocsSupported = !flagValues.DISABLE_DOCUMENTS_CLASSIC;
  const paymentsClassicFlag = flagValues.PAYMENTS_CLASSIC;
  const policyType = getPolicyDisplayType(policyResponse.policyData);
  const title = `Your ${policyType?.toLocaleLowerCase() ?? ''} policy`;
  const onlyPolicyApiFailed = errorState.policy && !errorState.document;
  const customerServicePhone = getBrandData(policyResponse.policyData).mainCustServPhoneNum;
  const header = (
    <Stack spacing={{ xs: 3, md: 5 }}>
      {policyResponse.policyData?.isInBookRoll && (
        <Alert type='warning' withIcon>
          Certain changes to your policy cannot be made right now. For help, call{' '}
          <PhoneLink number={customerServicePhone} />.
        </Alert>
      )}
      {shouldShowInForceBookRollAlert && (
        <Alert type='info' withIcon withAction hideOnClose>
          This is your new policy. It has replaced your previous {policyType} policy{' '}
          {convertedFromClassic.replaces}.
        </Alert>
      )}
      {shouldShowScheduledBookRollAlert && (
        <Alert type='info' withIcon withAction hideOnClose>
          Effective{' '}
          {formatDate(
            convertedFromClassic.policyData.policy.periodStartDate,
            DateConstants.MONTH_FULL_NAME_LONG_FORMAT,
          )}
          , this will be your new policy.
        </Alert>
      )}
      <CaMileageAlert policyData={policyResponse.policyData} />

      <Stack spacing={1}>
        <h1>{title}</h1>
        {onlyPolicyApiFailed && <ApiAlert apiDescription='policy & payment information' />}
      </Stack>
    </Stack>
  );

  const scrollspy = (
    <ScrollSpy title={title} updateListDependency={[policyNumber, policyResponse.isLoading]} />
  );

  const body = (
    <Stack spacing={{ xs: 4, md: 5 }} className={classes.root}>
      <Stack spacing={1}>
        <Section
          title='Payments'
          titleId='payments'
          titleClassName={ScrollSpyMarkerClasses.LEVEL_ONE}
        >
          {paymentsClassicFlag || !isClassic ? (
            <ErrorBoundary
              FallbackComponent={
                onlyPolicyApiFailed ? PaymentsPolicyApiFallback : PaymentsDefaultFallback
              }
              onError={updatePaymentErrorState}
            >
              <PaymentsSection policyNumber={policyNumber} />
            </ErrorBoundary>
          ) : (
            <div className={classes.unavailableSection}>
              <p className={classes.paymentTitle}>
                Review your billing details and make a payment on our payment site.
              </p>
              <MakePaymentButton
                policyNumber={policyNumber}
                classes={{ root: classes.buttonWrapper, button: classes.button }}
                isClassic
                productLine={productLine}
              />
            </div>
          )}
        </Section>
      </Stack>
      <Section
        title='Policy details'
        titleId='policy_details'
        titleClassName={ScrollSpyMarkerClasses.LEVEL_ONE}
      >
        <ErrorBoundary
          FallbackComponent={
            onlyPolicyApiFailed ? DetailsPolicyApiFallback : DetailsDefaultFallback
          }
          onError={updatePolicyErrorState}
        >
          <DetailsSection policyNumber={policyNumber} />
        </ErrorBoundary>
      </Section>
      {policyResponse.policyData?.premium?.policyDiscounts && (
        <Section
          title='Discounts'
          titleId='discounts'
          titleClassName={ScrollSpyMarkerClasses.LEVEL_ONE}
        >
          <ErrorBoundary
            FallbackComponent={
              onlyPolicyApiFailed ? DiscountsPolicyApiFallback : DiscountsDefaultFallback
            }
            onError={updatePolicyErrorState}
          >
            <DiscountsSection policyNumber={policyNumber} />
          </ErrorBoundary>
        </Section>
      )}
      {(!isClassic || isClassicDocsSupported) && (
        <>
          <Section
            title='Coverages'
            titleId='coverages'
            titleClassName={ScrollSpyMarkerClasses.LEVEL_ONE}
          >
            <ErrorBoundary
              FallbackComponent={
                onlyPolicyApiFailed ? CoveragesPolicyApiFallback : CoveragesDefaultFallback
              }
              onError={updatePolicyErrorState}
            >
              <CoveragesSection policyNumber={policyNumber} />
            </ErrorBoundary>
          </Section>
          <Section
            title='Documents'
            titleId='documents'
            titleClassName={ScrollSpyMarkerClasses.LEVEL_ONE}
          >
            <ErrorBoundary
              FallbackComponent={DocumentsSectionFallback}
              onError={updateDocumentsErrorState}
            >
              <DocumentSection policyNumber={policyNumber} />
            </ErrorBoundary>
          </Section>
        </>
      )}
      <ContactUsMicro />
    </Stack>
  );

  return (
    <DashboardCard
      header={header}
      secondaryContent={scrollspy}
      secondaryContentBreakBehavior='hide'
    >
      {body}
      <AddDriverModal />
      <AddVehicleModal />
      <AdditionalInterestModal />
      <AutopayModal />
      <CaMileageModal />
      <EditVinModal />
      <MembershipModal />
      <PaymentModal />
      <PaymentClassicModal />
      <PaperlessModal />
    </DashboardCard>
  );
});
