import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'LoadingOverlay' })((theme) => ({
  root: {
    zIndex: 9999,
    margin: '0 !important', // handle when put inside a Stack
    position: 'fixed',
    left: 0,
    top: 0,
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    background: `${theme.palette.actions.disabledBg}80`,
  },
  wrapper: {
    textAlign: 'center',
  },
  textContainer: {
    marginBottom: 76,
    padding: '0 40px',
    [theme.breakpoints.down('md')]: {
      marginBottom: 40,
    },
  },
  title: {
    ...theme.typography.h1,
    textAlign: 'center',
  },
  subtitle: {
    ...theme.typography.h2,
    fontWeight: 'normal',
    textAlign: 'center',
  },
}));
