import { useQuery } from '@tanstack/react-query';

import { unique } from '@ecp/utils/common';

import { env } from '@ecp/env';
import type {
  PolicySource,
  PolicyTypeCode,
  ProductLine,
} from '@ecp/features/servicing/shared/types';

import { QUERY_KEYS } from '../constants';
import { useUserPolicies } from '../policy';
import { getProductLineFromPolicyResponse } from '../util';
import type { FaqProduct, FaqQuestion, GetFaqResponse } from './types';

const getFaqProduct = (policyTypeCode: PolicyTypeCode | undefined): FaqProduct => {
  if (!policyTypeCode) return 'auto';

  return policyTypeCode;
};

const useFaq = (
  lobList: ProductLine[],
  productList: FaqProduct[],
  sourceSystemNames: PolicySource[],
): GetFaqResponse => {
  const {
    isFetching,
    isError,
    data = [],
  } = useQuery({
    queryKey: [QUERY_KEYS.FAQ, lobList, productList],
    queryFn: async () => {
      const faqPartnerQuestions = (): Promise<FaqQuestion[]> => {
        return (async () => {
          const _module = await import(`./questions/faq.${env.static.partnerName}`);

          return _module.default;
        })();
      };

      const faqGenericQuestions = (): Promise<FaqQuestion[]> => {
        return (async () => {
          const _module = await import('./questions/faq.default');

          return _module.default;
        })();
      };

      // ensure keep partner questions first, since if there is a duplicate, we will keep the first one (partner faq used instead of base)
      const faqs = [...(await faqPartnerQuestions()), ...(await faqGenericQuestions())];

      const filteredFaqs =
        // Check if policy API failed productList and lobList will be empty, then keep in filteredFaqs only generic faq's,
        // otherwise filter by produt type and lob of the policy
        (
          !productList.length && !lobList.length && !sourceSystemNames.length
            ? faqs.filter(
                (faq) => !faq.product.length && !faq.lob.length && !faq.sourceSystemName?.length,
              )
            : faqs
                // Filter FAQs based on LineOfBusiness (Property/Auto)
                .filter((faq) => lobList.some((lob) => !faq.lob.length || faq.lob.includes(lob)))
                // Filter FAQs based on Product (HO3/HO4/etc)
                .filter((faq) =>
                  productList.some(
                    (faqProduct) => !faq.product.length || faq.product.includes(faqProduct),
                  ),
                )
                .filter((faq) =>
                  sourceSystemNames.some(
                    (name) => !faq.sourceSystemName?.length || faq.sourceSystemName.includes(name),
                  ),
                )
        ).filter((faq) => !faq.disabled);

      // dedup after filtering to ensure we could have the same question for different lob/product. Any duplicate found at this point
      // is a true duplicate since we have already assured it is valid for the lob/product
      const dedupedFilteredFaqs = filteredFaqs.reduce((existingFaqs, newFaq) => {
        const exists = existingFaqs.some((faq) => faq.question === newFaq.question);
        if (!exists) {
          existingFaqs.push(newFaq);
        }

        return existingFaqs;
      }, [] as FaqQuestion[]);

      return dedupedFilteredFaqs;
    },
  });

  return { faqs: data, isLoading: isFetching, isError };
};

export const useFaqs = (): GetFaqResponse => {
  const {
    policies,
    isLoading: isLoadingUserPolicies,
    isError: isErrorUserPolicies,
  } = useUserPolicies({
    throwOnPolicyError: false,
    throwOnUserError: false,
  });

  const lobList = unique(
    policies.map((policyData) => getProductLineFromPolicyResponse(policyData) as ProductLine),
  );
  const productList = unique(
    policies.map((policyData) => getFaqProduct(policyData.coverageOptions.at(0)?.policyTypeCode)),
  );
  const sourceSystemNames = unique(
    policies.map((policyData) => policyData.policy.sourceSystemName),
  );

  const {
    faqs,
    isLoading: isLoadingFaq,
    isError: isErrorFaq,
  } = useFaq(lobList, productList, sourceSystemNames);

  return {
    isLoading: isLoadingUserPolicies || isLoadingFaq,
    isError: isErrorUserPolicies || isErrorFaq,
    faqs,
  };
};
