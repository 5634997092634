import { noop } from '@ecp/utils/common';

import { GraphicAutoCoverageEmergencyRoadsideImageUrl } from '@ecp/themes/base';
import type { Field } from '@ecp/types';

import type { OptionData } from '../types';
/**
 * Stubs file/function naming is intentional vs using `mock`. Mock implies functionality or use of a mocking framework like jest. In that case,
 * these objects would not be able to be used in stories or live code. Stubs are just a default (mostly empty) implementation.
 */

/** Stub for an instance of Field. This can be used in tests, stories, or even in real code (in the case of readonly coverages for servicing). */
export const getFieldStub = (key: string): Field => ({
  props: {
    name: key,
    value: 'Coverage value',
    actionOnChange: noop,
    actionOnComplete: () => new Promise(noop),
  },
  errors: [],
  exists: true,
  key,
  question: { answerType: 'String' },
  value: 'Coverage value',
  patch: () => new Promise(noop),
  reset: noop,
  update: noop,
  validate: () => [],
  validateAndPatch: () => new Promise(noop),
  validateAndUpdate: () => [],
  validateUpdateAndPatch: () => new Promise(noop),
});

/** Stub for an instance of OptionData. This can be used in tests, stories. Should not be used for real coverages (should be build using proper metadata). */
export const getOptionDataStub = (key: string, title?: string): OptionData => ({
  primaryImage: GraphicAutoCoverageEmergencyRoadsideImageUrl,
  title: title ?? 'Emergency Roadside Assistance',
  primaryText:
    'Provides help if you have a flat tire, dead battery, get locked out of your car, or run out of gas. Coverage also includes towing costs for the insured vehicle when it is disabled.',
  secondaryText:
    'Insureds will have access to multiple roadside services, which include lockout service, gas delivery, jump start service, and towing to the nearest facility to make repairs. Included as a Executive Member Benefit to Executive Members.',
  displayValue: '$1,000',
  isCovered: true,
  editable: false,
  field: getFieldStub(key),
});
