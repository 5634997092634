import { LegalCopy as LegalCopyWrapper } from '@ecp/features/servicing/shared/components';
import type { ProductLine } from '@ecp/features/servicing/shared/types';
import { getBrandDataByType } from '@ecp/features/servicing/shared/util';

interface LegalCopyProps {
  productLine: ProductLine;
}

export const LegalCopy: React.FC<LegalCopyProps> = (props) => {
  const { productLine } = props;
  let legalCopy = `By clicking "Submit" I am providing my written authorization to allow American Family Mutual
  Insurance Company, S,I,. or any of its subsidiary companies to directly deduct payment as
  early as today from my checking or savings account based on the account information I have
  provided. A returned bank item fee of up to $25.00 may be charged if the deduction is
  rejected by my financial institution. (Note: subsidiary is a link to URL:
  https://www.amfam.com/companyid)`;

  const brandData = getBrandDataByType(productLine);
  if (brandData?.uwCompany === 'Homesite')
    legalCopy = `I authorize Homesite Group, Incorporated and its affiliates to initiate the payment listed on this page from my checking, savings, or debit/credit card account. I understand that payment will be submitted once I select "Pay Now". If you have questions regarding this payment, please contact us at ${brandData.mainCustServPhoneNum}`;
  else if (brandData?.uwCompany === 'CONNECT')
    legalCopy = `I authorize CONNECT, powered by American Family Insurance, and its affiliates to initiate the payment listed on this page from my checking, savings, or debit/credit card account. I understand that payment will be submitted once I select "Pay Now". If you have questions regarding this payment, please contact us at ${brandData.mainCustServPhoneNum}`;

  return <LegalCopyWrapper>{legalCopy}</LegalCopyWrapper>;
};
