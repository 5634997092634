import type { Person, UiPolicy } from '@ecp/features/servicing/shared/types';

export const getPersonByRefId = (
  policyResponse: UiPolicy | undefined,
  personRefId: string | undefined,
): Person | undefined => {
  if (!policyResponse) return undefined;

  return policyResponse.persons?.find((person) => person.personId === personRefId);
};
