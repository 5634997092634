import { Stack } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';

import { flagValues } from '@ecp/utils/flags';

import { ApiAlert, DashboardCard } from '@ecp/features/servicing/shared/components';
import { GraphicErrorGenericErrorLandscapeImageUrl } from '@ecp/themes/base';

import { ClaimsIframe, RightRail } from '../../components';
import { useStyles } from './ClaimsPage.styles';

const ClaimsFallback: React.FC = () => <ApiAlert apiDescription='claims' />;

export const ClaimsPage: React.FC = () => {
  const { classes } = useStyles();
  const isClaimsSupported = !flagValues.DISABLE_CLAIMS_PAGE;

  return (
    <DashboardCard header='Claims' secondaryContent={<RightRail />}>
      <Stack spacing={5}>
        <ErrorBoundary FallbackComponent={ClaimsFallback}>
          {isClaimsSupported ? (
            <ClaimsIframe />
          ) : (
            <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
              <Stack spacing={2}>
                <h2>We're sorry.</h2>
                <p className={classes.pleaseCallText}>
                  Online claims service is currently unavailable. For help with a claim, please call
                  us.
                </p>
              </Stack>
              <img
                className={classes.errorImage}
                src={GraphicErrorGenericErrorLandscapeImageUrl}
                alt='Error computer'
              />
            </Stack>
          )}
        </ErrorBoundary>
      </Stack>
    </DashboardCard>
  );
};
