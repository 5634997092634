import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutopayEditEnrollPaymentDue' })((theme) => ({
  root: {},
  bodyWrapper: { paddingTop: 8 },
  text: theme.typography.body3,
  paymentBorder: {
    borderRadius: 4,
    border: `1px solid ${theme.palette.other.border}`,
    padding: 16,
  },
  button: {
    ...theme.typography.button,
    border: `1px solid ${theme.palette.secondary.main}`,
    height: 'fit-content',
    paddingBottom: 12,
    paddingTop: 12,
    width: '100%',
  },
}));
