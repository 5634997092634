import { QueryClient } from '@tanstack/react-query';

import { errorCodeToRetry } from './constants';
import type { ServicingRequestError } from './servicingRequest';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: Infinity,
      staleTime: Infinity,
      throwOnError: true,
      refetchOnWindowFocus: false,
      // One more attempt for failed requests with Error code from **errorCodeToRetry** list,
      // Total: 2 requests for Errors from list, just 1 for others.
      retry: (failureCount, error) => {
        return (
          errorCodeToRetry.has((error as ServicingRequestError)?.response?.status) &&
          failureCount < 1
        );
      },
    },
    mutations: {
      throwOnError: true,
    },
  },
});
