import * as yup from 'yup';

import type { YupSchemaMap } from '@ecp/utils/form';

export interface EnterPasswordFormInputs {
  email: string;
  password?: string;
}

export const enterPasswordSchema: YupSchemaMap<EnterPasswordFormInputs> = {
  email: yup.string().required(),
  password: yup.string().required(),
};
