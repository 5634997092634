import { Stack } from '@mui/material';
import { sanitize } from 'dompurify';

import { ServicingAccordion } from '@ecp/features/servicing/shared/components';
import type { FaqQuestion } from '@ecp/features/servicing/shared/state';

import { useStyles } from './FaqSection.styles';
export interface FaqSectionProps {
  id: string;
  title: string;
  questions: FaqQuestion[];
}

export const FaqSection: React.FC<FaqSectionProps> = (props) => {
  const { id, questions, title } = props;
  const { classes } = useStyles();

  const body = (
    <Stack spacing={2} className={classes.root}>
      <h2 id={id}>{title}</h2>

      <div>
        {questions.map((question) => (
          <ServicingAccordion
            trackingName={question?.trackingName ?? ''}
            id={question?.question.split(' ').join('-')}
            accordionTitle={<p className={classes.title}>{question?.question}</p>}
            accordionDetails={
              <div dangerouslySetInnerHTML={{ __html: sanitize(question?.answer ?? '') }} />
            }
            key={question.question}
            classes={{ root: classes.accordion }}
          />
        ))}
      </div>
    </Stack>
  );

  return body;
};
