import type { AnswerValue } from './types';

type AnswerType = 'String' | 'Number' | 'Boolean' | 'Reference' | 'List';

/**
 * Convert answer value type to the actual type.
 * Answer values in the inquiry `questions` and `answers` objects are
 * passed between the client and SAPI in `string` type.
 * To be able to conveniently work with the values in the client code,
 * strings must be converted into appropriate types supported in JS.
 *
 * @param value answer value.
 * @param answerType which type to convert the value into; only used if the value is of `string` type.
 * @returns answer value converted into desired type if the value param is of `string` type, otherwise returns back the value.
 *
 * TODO This function directly belongs to the inquiry service, we must not be using it across our codebase.
 * We must process returned from SAPI answers and questions objects with this function only once
 * and assume the actual value types in the rest of our code.
 * The goal is to rewrite our logic in a few modules this way and then move this function over to inquiry module.
 * This would simplify all dependent code.
 */
export function castAnswerType<T extends AnswerValue, U extends AnswerType>(
  value: T,
  answerType: U,
): T extends string
  ? T extends ''
    ? null
    : U extends 'List'
    ? U
    : U extends 'Boolean'
    ? boolean | null
    : U extends 'Number'
    ? number | null
    : T
  : T;
export function castAnswerType(value: AnswerValue, answerType: AnswerType): AnswerValue {
  // TODO There is no validation if value is of AnswerValue type, so this line will make
  // this function to return any type
  if (typeof value !== 'string') return value;

  if (typeof value === 'string' && value === '') {
    return null;
  }

  if (answerType === 'List') {
    return value?.split(/\s*,\s*/) || [];
  }

  if (answerType === 'Boolean') {
    if (value === 'true') return true;
    if (value === 'false') return false;

    return null;
  }

  if (answerType === 'Number') {
    if (value) {
      const numValue = Number(value);
      if (Number.isNaN(numValue)) return null;

      return numValue;
    }

    return null;
  }

  return value;
}

/** TODO Add validation to castAnswerType for value if it's of type AnswerValue and remove `as` assertion here. */
export const castToBoolean = (value: unknown): boolean =>
  !!castAnswerType(value as AnswerValue, 'Boolean');

/**
 * TODO This function doesn't do what it claims to if non-string value gets passed.
 * Add validation to castAnswerType for value if it's of type AnswerValue and remove `as` assertions here.
 */
export const castToNumberOrNull = (value: unknown): number | null =>
  castAnswerType(value as AnswerValue, 'Number') as number | null;

export const ensureStringOrNull = (value: unknown): string | null => {
  if (typeof value !== 'string') return null;

  return value;
};

/**
 * Checks if a value is a zip code string (trimming the whitespaces)
 * and returns trimmed zip code string (if everything checks out) or null otherwise.
 */
export const ensureZipCode = (value: unknown): string => {
  const ensuredValue = ensureStringOrNull(value);
  if (!ensuredValue) return '';

  const match = ensuredValue.match(/\b\d{5}\b/);
  if (!match) return '';

  return match[0];
};

export const addHyphensToCreditCardNumber = (value: string): string => {
  const numValue = value.replace(/[^0-9]/gi, '').trim();
  const isAmex = ['34', '37'].some((number) => numValue.startsWith(number));
  const parts = [];

  if (!isAmex) {
    for (let i = 0, len = numValue.length; i < len; i += 4) {
      parts.push(numValue.substring(i, i + 4));
    }
  } else if (isAmex) {
    const block1 = numValue.substring(0, 4);
    block1.length && parts.push(block1.trim());

    const block2 = numValue.substring(4, 10);
    block2.length && parts.push(block2.trim());

    const block3 = numValue.substring(10, 15);
    block3.length && parts.push(block3.trim());
  }
  if (parts.length) {
    return parts.join('-');
  } else {
    return value;
  }
};
