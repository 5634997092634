import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'MakePaymentButton' })((theme) => ({
  root: {},
  button: {
    ...theme.typography.button,
    width: '100%',
  },
  checkCircleIcon: theme.mixins.setColorSvg(theme.palette.success.main),
  submitSection: { alignItems: 'center', height: '100%' },
  subtitle: theme.typography.body1,
}));
