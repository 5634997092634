import { useCallback, useMemo, useState } from 'react';

import { Stack } from '@mui/material';

import { Form, useForm } from '@ecp/utils/form';

import { Alert } from '@ecp/components';
import {
  LegalCopy,
  LoadingOverlay,
  PaymentsFormFooter,
} from '@ecp/features/servicing/shared/components';
import type { UseModalPathReturn as MakePaymentEditClassicProps } from '@ecp/features/servicing/shared/routing';
import type { BillingResponse, PolicyBillingDetails } from '@ecp/features/servicing/shared/state';
import { useBillingDetails, usePolicy, useSharedState } from '@ecp/features/servicing/shared/state';
import type {
  ClassicPaymentToken,
  MakePaymentClassicInformation,
} from '@ecp/features/servicing/shared/types';
import { getBrandData } from '@ecp/features/servicing/shared/util';

import { MakePaymentSetup } from '../MakePaymentSetup';
import type { MakePaymentFormInputs } from './makePaymentClassicSchema.schema';
import { makePaymentClassicSchema } from './makePaymentClassicSchema.schema';
import { useStyles } from './MakePaymentEditClassic.styles';

export const MakePaymentEditClassic: React.FC<MakePaymentEditClassicProps> = (props) => {
  const { goForward, sharedStateKey, policyNumber } = props;
  const { classes } = useStyles();
  const { isLoading: isPolicyLoading, policyData: policyResponse } = usePolicy(policyNumber);
  const policyBeingPayed = policyResponse?.policy;
  const billingAccountNumber = policyResponse?.policy.billingAccountNumber;
  const { accounts, isLoading: isBillingLoading } = useBillingDetails({
    billingAccountIds: billingAccountNumber ? [billingAccountNumber] : undefined,
  });
  const paymentDate = useMemo(() => new Date().toISOString(), []);
  const formContext = useForm<MakePaymentFormInputs>({ validations: makePaymentClassicSchema });
  const [, setSharedState] = useSharedState<MakePaymentClassicInformation>(sharedStateKey);
  const [financialToken, setFinancialToken] = useSharedState<ClassicPaymentToken>(
    `financialAccountToken-${policyNumber}`,
  );
  const [hideAlert, setHideAlert] = useState<boolean>(financialToken?.isValid ?? true);
  const handleOnClose = useCallback(() => {
    setHideAlert(true);
    setFinancialToken(null);
  }, [setHideAlert, setFinancialToken]);

  const accountBeingPayed: BillingResponse | undefined = accounts.find(
    (account) => account.billingAccountId === policyBeingPayed?.billingAccountNumber,
  );

  const isLoading = isBillingLoading || isPolicyLoading;

  const policyBillingDetailsBeingPayed: PolicyBillingDetails = {
    policyInfo: policyResponse,
    billingInfo: accountBeingPayed,
  };

  const paymentAmount = formContext.getValues('paymentAmount');

  const { handleSubmit } = formContext;

  const dueOrFull =
    paymentAmount === String(accountBeingPayed?.fullPayBalance) || !accountBeingPayed
      ? 'Pay in full'
      : 'Amount due';

  const onSubmit = useCallback(() => {
    handleSubmit(async ({ paymentAmount }) => {
      setSharedState({
        dueOrFull,
        paymentAmount: Number(paymentAmount),
        paymentDate,
      });
      setFinancialToken({ financialAccountToken: '', isValid: true, isError: false });
      goForward();
    })();
  }, [dueOrFull, handleSubmit, goForward, setSharedState, paymentDate, setFinancialToken]);

  if (isLoading) return <LoadingOverlay />;

  const customerServicePhone = getBrandData(policyResponse).mainCustServPhoneNum;
  const legalCopy = (
    <Stack spacing={1}>
      <p>
        I authorize American Family Connect Property and Casualty Insurance Company, Inc. and its
        affiliates to initiate the payment listed on this page from my debit/credit card account
        identified on the following page. I understand that I must check the authorization box below
        to allow payment to be withdrawn from my account and that payment will be submitted once I
        select "Pay Now" on the following page.
      </p>
      <p>
        If you have questions regarding this payment, please contact us at {customerServicePhone}.
      </p>
    </Stack>
  );

  return (
    <Stack spacing={3}>
      {!hideAlert && (
        <Alert withIcon type='warning' withAction onClose={handleOnClose}>
          Your payment session expired and was not processed, please try again.
        </Alert>
      )}
      <Form
        id='makePaymentFormClassic'
        formProviderProps={formContext}
        className={classes.root}
        onSubmit={onSubmit}
      >
        <Stack spacing={3}>
          <MakePaymentSetup
            key={policyBeingPayed?.policyNumber}
            policyBillingDetailsBeingPayed={policyBillingDetailsBeingPayed}
            isClassic
            {...props}
          />
          <LegalCopy>{legalCopy}</LegalCopy>
          <PaymentsFormFooter
            buttonText='Continue'
            checkBoxConfirmation
            infoText={`Next, we'll collect your credit/debit card information.`}
          />
        </Stack>
      </Form>
    </Stack>
  );
};
