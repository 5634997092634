import { CircularProgress, Stack } from '@mui/material';

import { DocumentTable } from '@ecp/features/servicing/shared/components';
import { useBillingPolicyDocuments } from '@ecp/features/servicing/shared/state';

import { useStyles } from './DocumentSection.styles';
import { SeeAllDocumentsLink } from './SeeAllDocumentsLink';

interface DocumentSectionProps {
  policyNumber: string;
}

export const DocumentSection: React.FC<DocumentSectionProps> = (props) => {
  const { classes } = useStyles();
  const { policyNumber } = props;

  const { isLoading, isError, documents } = useBillingPolicyDocuments({
    policyNumbers: [policyNumber],
    bypassPolicyFilter: true,
  });

  if (isError) throw new Error('Unable to access documents information');

  if (isLoading) return <CircularProgress aria-label='Loading...' />;

  return (
    <Stack spacing={2} className={classes.root}>
      <DocumentTable
        documentDataList={documents.documentDataList}
        maxDocs={5}
        classes={{ root: classes.docTable }}
      />
      <SeeAllDocumentsLink policyNumber={policyNumber} />
    </Stack>
  );
};
