import { useCallback, useState } from 'react';

import { Collapse } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';

import { IconUIExpandLess, IconUIExpandMore } from '@ecp/themes/base';

import { Button } from '../Button';
import { useStyles } from './ScrollSpyItem.styles';
import type { Heading } from './util';

interface Props {
  id: string;
  title: string;
  items: Heading[];
  activeId: string;
  idsList: string[];
  isSubstep?: boolean;
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  isNonClickable?: boolean;
}

const scrollIntoViewWithOffset = (selector: string, offset: number): void => {
  const element = document.querySelector(selector);
  if (element) {
    window.scrollTo({
      behavior: 'smooth',
      top: element.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset,
    });
  }
};

export const ScrollSpyItem: React.FC<Props> = (props) => {
  const { classes, cx } = useStyles(undefined, { props });
  const [collapseOpen, setCollapseOpen] = useState(true);

  const { id, title, items, activeId, idsList, isSubstep, isNonClickable } = props;

  const isActive = id === activeId;
  const isBorderActive = idsList.indexOf(id) <= idsList.indexOf(activeId);
  const isExpandable = !!items?.length;

  const handleCollapseClick = useCallback(() => setCollapseOpen(!collapseOpen), [collapseOpen]);

  const handleItemClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      const id = event.currentTarget.dataset.id;
      if (id) {
        trackClick({ action: id + '_side_scroll', label: id + '_continue', objectType: 'link' });
        const isUp = idsList.indexOf(activeId) > idsList.indexOf(id);

        scrollIntoViewWithOffset(`#${id}`, isUp ? 105 : 0);
      }
    },
    [idsList, activeId],
  );

  const submenuItems = isExpandable && (
    <Collapse in={collapseOpen}>
      <ul className={classes.list}>
        {items.map((item) => (
          <ScrollSpyItem {...props} {...item} isSubstep key={item.id} />
        ))}
      </ul>
    </Collapse>
  );

  {
    const ExpandIcon = collapseOpen ? IconUIExpandLess : IconUIExpandMore;

    return (
      <li key={id} className={classes.listItem}>
        <span className={classes.stepItem}>
          <a
            href={`#${id}`}
            className={cx(
              classes.link,
              isNonClickable && classes.disabledLink,
              isActive && classes.linkActive,
              isBorderActive && classes.borderActive,
              isSubstep && classes.substepLink,
            )}
            data-id={id}
            onClick={handleItemClick}
          >
            {title}
          </a>
          {isExpandable && (
            <Button
              aria-label='scrollSpy-collappse-button'
              variant='iconText'
              data-testid='expandCollapse'
              icon={<ExpandIcon />}
              className={classes.icon}
              data-id={id}
              onClick={handleCollapseClick}
              trackingLabel={`${title.replace(' ', '_').toLowerCase()}_side_scroll_accordion`}
              trackingName={collapseOpen ? 'Collapse' : 'Expand'}
              objectType='accordion'
            />
          )}
        </span>
        {submenuItems}
      </li>
    );
  }
};
