import { useCallback, useState } from 'react';

import { Stack } from '@mui/material';

import { Button } from '@ecp/components';
import {
  LoadingButton,
  LoadingOverlay,
  ModalError,
} from '@ecp/features/servicing/shared/components';
import { QUERY_PARAMS } from '@ecp/features/servicing/shared/routing';
import type { UseModalPathReturn as UpdateAdditionalInterestReturn } from '@ecp/features/servicing/shared/routing';
import type { ErrorResponse } from '@ecp/features/servicing/shared/state';
import {
  getAddressByRefId,
  useDeleteAdditionalInterest,
  usePolicy,
} from '@ecp/features/servicing/shared/state';
import { IconUIQuestion } from '@ecp/themes/base';

import { useStyles } from './DeteleAdditionalInterest.styles';

export const DeleteAdditionalInterest: React.FC<UpdateAdditionalInterestReturn> = (props) => {
  const { policyNumber, reset, getSearchParam } = props;
  const {
    policyData,
    isLoading: isPolicyLoading,
    refetch: refetchPolicyData,
    isFetching,
  } = usePolicy(policyNumber);

  const policySourceSystemName = policyData?.policy.sourceSystemName;
  const additionalInterests = policyData?.thirdPartyInterests || [];
  const { deleteAdditionalInterest, isSubmitting } = useDeleteAdditionalInterest();
  const [submitError, setSubmitError] = useState<ErrorResponse>();
  const thirdPartyInterestRole = getSearchParam(QUERY_PARAMS.THIRD_PARTY_INTEREST_ROLE);
  const currentAdditionalInterest = additionalInterests.find(
    (interest) => interest.thirdPartyInterestRole === thirdPartyInterestRole,
  );
  const address = getAddressByRefId(policyData, currentAdditionalInterest?.addressRefId);

  if (!policyNumber) {
    throw new Error('policyNumber is undefined');
  }
  if (!thirdPartyInterestRole) {
    throw new Error('thirdPartyInterestRole is undefined');
  }

  const { classes } = useStyles();

  const handleSubmit = useCallback(async () => {
    if (!currentAdditionalInterest?.thirdPartyInterestRole)
      throw Error('thirdPartyInterestRole can not be undefined');
    if (!policySourceSystemName) throw Error('policySourceSystemName is requred');
    const { success, error } = await deleteAdditionalInterest({
      policyNumber,
      policySourceSystemName,
      isPrimary: currentAdditionalInterest.thirdPartyInterestRole === 'PRIMARY',
    });
    if (success) {
      await refetchPolicyData();
      reset();
    } else if (error) {
      setSubmitError(error);
    }
  }, [
    deleteAdditionalInterest,
    policyNumber,
    policySourceSystemName,
    reset,
    refetchPolicyData,
    currentAdditionalInterest,
  ]);

  if (submitError)
    return (
      <ModalError
        errorTitle='process your request at this time'
        errorDescription='processing your request'
      />
    );

  if (isPolicyLoading) return <LoadingOverlay />;

  return (
    <Stack spacing={3}>
      <div className={classes.iconWrapper}>
        <div className={classes.circle}>
          <IconUIQuestion className={classes.icon} />
        </div>
      </div>
      <Stack spacing={1.5} classes={classes.root}>
        <h2>Are you sure you want to remove {currentAdditionalInterest?.name}?</h2>
        <p>Notification of this change will be sent to the following address:</p>

        <Stack>
          <p>{currentAdditionalInterest?.name}</p>
          <p>{address?.line1}</p>
          <p>
            {address?.city}, {address?.state} {address?.zip}
          </p>
        </Stack>
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2.5}>
        <LoadingButton
          variant='danger'
          onClick={handleSubmit}
          isProcessing={isFetching || isSubmitting}
        >
          DELETE LANDLORD
        </LoadingButton>
        <Button variant='outlinePrimary' onClick={reset}>
          BACK
        </Button>
      </Stack>
      <Button variant='iconTextMedium' onClick={reset} className={classes.cancelButton}>
        Cancel
      </Button>
    </Stack>
  );
};
