import { Stack } from '@mui/material';

import { TooltipWithIcon } from '@ecp/components';

import { useStyles } from './LabelWithTooltip.styles';

interface BaseProps {
  altText?: string;
  iconUrl?: string;
  label: string;
  title?: string;
  trackingName?: string;
  tooltipText?: string;
}

interface ImageTooltipProps extends BaseProps {
  altText: string;
  iconUrl: string;
}

interface TextTooltipProps extends BaseProps {
  title: string;
}

export type Props = ImageTooltipProps | TextTooltipProps;

export const LabelWithTooltip: React.FC<Props> = (props) => {
  const { label, altText, iconUrl, title, tooltipText, ...rest } = props;
  const { classes } = useStyles();

  return (
    <Stack direction='row' className={classes.groupLabelStack} spacing={0.5}>
      {label}
      <TooltipWithIcon
        classes={{
          popper: classes.popper,
          tooltipWithIconButton: classes.tooltipWithIconButton,
          icon: classes.icon,
        }}
        title={
          title ?? (
            <Stack spacing={1.5}>
              <img src={iconUrl} alt={altText} data-testid='tooltip-help-image' />
              {tooltipText && <p className={classes.tooltipText}>{tooltipText}</p>}
            </Stack>
          )
        }
        {...rest}
      />
    </Stack>
  );
};
