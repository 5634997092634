import { NavLink } from '@ecp/features/servicing/shared/components';
import { PAGE_PATH } from '@ecp/features/servicing/shared/routing';

import { useStyles } from './RecoveryLinks.styles';

export const RecoveryLinks: React.FC = () => {
  const { classes } = useStyles();

  return (
    <span>
      Forgot your{' '}
      <NavLink
        to={PAGE_PATH.RECOVER_EMAIL}
        className={classes.navlink}
        trackingName='recover_email_link'
        trackingLabel='recover_email_continue'
      >
        email
      </NavLink>{' '}
      or{' '}
      <NavLink
        to={PAGE_PATH.RECOVER_PASSWORD}
        className={classes.navlink}
        trackingName='recover_password_link'
        trackingLabel='recover_password_continue'
      >
        password
      </NavLink>
      ?
    </span>
  );
};
