import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<void, 'checked' | 'iconProduct'>({
  name: 'ButtonFormControlLabel',
})((...[theme, , classes]) => ({
  root: {
    display: 'block',
    '& .Mui-disabled > *': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.grey[200],
    },
  },
  roundButton: {},
  checked: {
    ...theme.typography.body1Bold,
    color: theme.palette.text.primary,
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
    [`&.${classes.iconProduct}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      borderWidth: 1,
      ...theme.mixins.setColorSvg(theme.palette.primary.contrastText),
    },
  },
  control: {
    display: 'none',
  },
  iconProduct: {
    ...theme.typography.body1Bold,
    ...theme.mixins.setColorSvg(theme.palette.text.primary),
    flexDirection: 'column',
    padding: '5px 5px 7px',
    borderRadius: 8,
    whiteSpace: 'break-spaces',
    textAlign: 'center',
    height: 85,
  },
  label: {
    textAlign: 'center',
    width: '100%',
  },
  labelWrapper: {
    ...theme.typography.body1,
    color: theme.palette.text.primary,
    alignItems: 'center',
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[600]}`,
    borderRadius: 3,
    display: 'flex',
    minHeight: 40,
    padding: '10px 20px',
    transition: '0.3s, border 0s',
    width: '100%',
  },
  largeLabelWrapper: {
    padding: '15px 60px',
  },
}));
