import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PoliciesSection' })((theme) => ({
  root: {},
  policyCards: {
    listStyle: 'none',
    padding: 0,
    display: 'grid',
    gridAutoRows: 'auto',
    rowGap: 24,
    columnGap: 24,
    gridTemplateColumns: 'repeat(1, 1fr)',
    [theme.breakpoints.between('md', 'lg')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  policyErrorAlert: {
    paddingTop: 12,
  },
  policyErrorCard: {
    height: 'auto',
  },
  noPoliciesCard: { height: 'auto' },
  noPoliciesCardTitle: theme.typography.body3Bold,
  contactUsLinkText: theme.typography.body1Underline,
}));
