import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'HelpBox' })((theme) => ({
  root: {
    ...theme.typography.body1,
    display: 'flex',
    color: theme.palette.text.primary,
    borderRadius: 8,
    padding: 10,
    backgroundColor: theme.palette.grey[200],
    width: '100%',
  },
  content: {
    paddingLeft: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiLink-underlineHover': theme.typography.body1Underline,
    '& .MuiLink-root': theme.typography.body1Underline,
  },
  icon: {
    fontSize: 46,
  },
}));
