import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CheckboxField' })((theme) => ({
  root: {},
  error: { margin: '10px 0 0 36px' },
  label: {
    ...theme.typography.body1,
    color: theme.palette.text.primary,
    marginLeft: 12,
  },
}));
