import { Navigate, Route, Routes } from 'react-router-dom';

import { ClaimsPage } from '@ecp/features/servicing/claims';
import {
  BillingTextingPage,
  ClaimsTextingPage,
  DisclosurePage,
  EmailFraudPage,
  LegalNoticesPage,
  OfferingTextingPage,
  PrivacyPolicyPage,
  ProtectYourInformationPage,
  ProtectYourselfPage,
  TermsAndConditionsPage,
  TermsOfUsePage,
  UnderwritingPage,
} from '@ecp/features/servicing/disclosures';
import { PolicyDocumentsPage } from '@ecp/features/servicing/documents';
import { FaqPage } from '@ecp/features/servicing/faq';
import { DashboardPage } from '@ecp/features/servicing/landing';
import { PolicyDetailsPage } from '@ecp/features/servicing/policydetails';
import { ProfilePage } from '@ecp/features/servicing/profile';
import { ServicingHeader } from '@ecp/features/servicing/shared/components';
import { PAGE_PATH, removePageFlowPrefixFromPaths } from '@ecp/features/servicing/shared/routing';

import { useStyles } from './DashboardLayout.styles';

const routerPath = removePageFlowPrefixFromPaths(PAGE_PATH.DASHBOARD_INDEX);

const Layout: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;
  const { classes } = useStyles(undefined, { props });

  return (
    <div className={classes.root}>
      <ServicingHeader />
      <main className={classes.main}>{children}</main>
    </div>
  );
};

export const DashboardLayout: React.FC<unknown> = () => {
  return (
    <Layout>
      <Routes>
        <Route index element={<DashboardPage />} />
        <Route path={routerPath.DASHBOARD_FLOW} element={<DashboardPage />} />
        <Route path={routerPath.DASHBOARD_CLAIMS_OVERVIEW} element={<ClaimsPage />} />
        <Route path={routerPath.DASHBOARD_POLICYDETAILS} element={<PolicyDetailsPage />} />
        <Route path={routerPath.DASHBOARD_POLICYDETAILS_FLOW} element={<PolicyDetailsPage />} />
        <Route path={routerPath.DASHBOARD_POLICY_DOCUMENTS} element={<PolicyDocumentsPage />} />
        <Route
          path={routerPath.DASHBOARD_POLICY_DOCUMENTS_FLOW}
          element={<PolicyDocumentsPage />}
        />
        <Route path={routerPath.DISCLOSURES} element={<DisclosurePage />} />
        <Route path={routerPath.DISCLOSURES_CLAIMSTEXTING} element={<ClaimsTextingPage />} />
        <Route path={routerPath.DISCLOSURES_EMAIL_FRAUD} element={<EmailFraudPage />} />
        <Route path={routerPath.DISCLOSURES_LEGALNOTICES} element={<LegalNoticesPage />} />
        <Route path={routerPath.DISCLOSURES_PRIVACYPOLICY} element={<PrivacyPolicyPage />} />
        <Route
          path={routerPath.DISCLOSURES_PROTECTYOURINFORMATION}
          element={<ProtectYourInformationPage />}
        />
        <Route path={routerPath.DISCLOSURES_PROTECTYOURSELF} element={<ProtectYourselfPage />} />

        <Route
          path={routerPath.DISCLOSURES_TERMSANDCONDITIONS}
          element={<TermsAndConditionsPage />}
        />
        <Route path={routerPath.DISCLOSURES_TERMSOFUSE} element={<TermsOfUsePage />} />
        <Route
          path={routerPath.TERMSANDCONDITIONS_OFFERINGTEXTING}
          element={<OfferingTextingPage />}
        />
        <Route path={routerPath.DISCLOSURES_UNDERWRITING} element={<UnderwritingPage />} />
        <Route path={routerPath.TERMSANDCONDITIONS_CLAIMSTEXTING} element={<ClaimsTextingPage />} />
        <Route
          path={routerPath.TERMSANDCONDITIONS_BILLINGTEXTING}
          element={<BillingTextingPage />}
        />

        <Route path={routerPath.FAQ} element={<FaqPage />} />
        <Route path={routerPath.PROFILE} element={<ProfilePage />} />
        <Route path={routerPath.PROFILE_FLOW} element={<ProfilePage />} />
        {/* redirect to dashboard if no match */}
        <Route path='*' element={<Navigate replace to={routerPath.DASHBOARD_INDEX} />} />
      </Routes>
    </Layout>
  );
};
