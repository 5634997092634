import { useCallback, useMemo } from 'react';

import { Stack } from '@mui/material';

import { isTruthy } from '@ecp/utils/common';

import { Alert, Button, PhoneLink } from '@ecp/components';
import type { OtherPoliciesEligibleProps } from '@ecp/features/servicing/shared/components';
import { LoadingOverlay, OtherPoliciesEligible } from '@ecp/features/servicing/shared/components';
import { MODAL_FLOW, MODAL_STEP } from '@ecp/features/servicing/shared/routing';
import type { UseModalPathReturn } from '@ecp/features/servicing/shared/routing';
import {
  usePaymentInfoForAllPolicies,
  usePolicy,
  useSharedState,
} from '@ecp/features/servicing/shared/state';
import { getPolicyDisplayType } from '@ecp/features/servicing/shared/state';
import type { AutopayInformation } from '@ecp/features/servicing/shared/types';
import { getBrandData } from '@ecp/features/servicing/shared/util';
import { IconCardAutomaticPayments } from '@ecp/themes/base';

import { AutopayEnrolledPolicyCard } from '../AutopayEnrolledPolicyCard';
import { useStyles } from './AutopayLanding.styles';

export const AutopayLanding: React.FC<UseModalPathReturn> = (props) => {
  const { currentFlow, init, reset, policyNumber, sharedStateKey } = props;
  const { classes } = useStyles();

  const { paymentInfos, isLoading: isLoadingAllPolicies } = usePaymentInfoForAllPolicies({
    throwOnError: false,
  });

  const policiesInfoAutopayEnrolled = paymentInfos?.filter(
    (paymentInfo) => paymentInfo.isManageAutopayEligible,
  );

  const { isLoading: isPolicyLoading, policyData: policyResponse } = usePolicy(policyNumber);

  const [sharedState] = useSharedState<AutopayInformation>(sharedStateKey);

  const customerServicePhone = getBrandData(policyResponse).mainCustServPhoneNum;
  const autopayAlert = sharedState?.isSuccess ? (
    <Alert withIcon type='success' withAction hideOnClose>
      <p>
        AutoPay details for your {getPolicyDisplayType(policyResponse)} policy {policyNumber} were
        successfully updated.
      </p>
    </Alert>
  ) : sharedState?.isError && currentFlow === 'autopaymanage' ? (
    <Alert
      className={classes.alertCloseBtn}
      id='manageAutopayErrorAlert'
      withIcon
      type='error'
      withAction
      hideOnClose
    >
      <p>
        There was an error updating the AutoPay details for your{' '}
        {getPolicyDisplayType(policyResponse)} policy {policyNumber}. Please try again later or call{' '}
        <PhoneLink number={customerServicePhone} /> to contact our customer care center.
      </p>
    </Alert>
  ) : null;

  const otherPolicies = useMemo(
    () =>
      paymentInfos
        ?.filter(
          ({ isAutopayEligible, policyData }) =>
            isAutopayEligible && policyData?.policy.policyNumber !== policyNumber,
        )
        ?.map((policyInfo) => policyInfo?.policyData)
        .filter(isTruthy),
    [paymentInfos, policyNumber],
  );

  const handleOtherClick = useCallback<NonNullable<OtherPoliciesEligibleProps['onClick']>>(
    (policyNumber) => init(policyNumber, MODAL_FLOW.AUTOPAY_ENROLL, MODAL_STEP.MODAL_EDIT),
    [init],
  );

  const isLoading = isLoadingAllPolicies || isPolicyLoading;
  if (isLoading) return <LoadingOverlay />;

  return (
    <Stack spacing={2.5}>
      {autopayAlert}
      <Stack spacing={3}>
        <IconCardAutomaticPayments className={classes.icon} />
        <h2>
          The following {policiesInfoAutopayEnrolled?.length !== 1 ? 'policies are' : 'policy is'}{' '}
          currently enrolled in AutoPay
        </h2>

        <Stack spacing={3}>
          {policiesInfoAutopayEnrolled?.map((policyPaymentInfo, index) => (
            <AutopayEnrolledPolicyCard
              key={index}
              policyPaymentInfo={policyPaymentInfo}
              isManageAutopay={currentFlow === 'autopaymanage'}
            />
          ))}
        </Stack>
        <OtherPoliciesEligible
          title='Other policies that can be enrolled in AutoPay'
          buttonText='Enroll in AutoPay'
          otherPolicies={otherPolicies}
          onClick={handleOtherClick}
        />
        <Button className={classes.button} variant='primary' onClick={reset}>
          CLOSE
        </Button>
      </Stack>
    </Stack>
  );
};
