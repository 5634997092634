import { useCallback, useEffect, useState } from 'react';

import { Stack } from '@mui/material';

import { Form, useField, useForm } from '@ecp/utils/form';

import { TextField } from '@ecp/components';
import {
  AuthenticationCard,
  LoadingButton,
  NavLink,
} from '@ecp/features/servicing/shared/components';
import { PAGE_PATH, usePageFlow } from '@ecp/features/servicing/shared/routing';
import {
  queryClient,
  useLogin,
  useSharedState,
  useValidateUser,
} from '@ecp/features/servicing/shared/state';
import type {
  LoginError,
  LoginUserRequest,
  VerifyOneTimeCodeSuccess,
} from '@ecp/features/servicing/shared/state';
import type { EmailOption } from '@ecp/features/servicing/shared/types';

import { AuthHelpBox, NumTriesExceeded } from '../../components';
import type { EnterPasswordFormInputs } from './EnterPasswordPage.schema';
import { enterPasswordSchema } from './EnterPasswordPage.schema';
import { useStyles } from './EnterPasswordPage.styles';

export const EnterPasswordPage: React.FC = () => {
  const { classes } = useStyles();
  const pageFlow = usePageFlow();

  const { validateData } = useValidateUser();
  const [selectedEmail] = useSharedState<EmailOption>('SelectedEmail');
  const [otpVerifiedData] = useSharedState<VerifyOneTimeCodeSuccess>('OtpVerifiedData');

  const { login } = useLogin();
  const email = otpVerifiedData?.loginId;

  const handleBack = useCallback(async () => {
    pageFlow.goBack();
  }, [pageFlow]);

  const formContext = useForm({
    validations: enterPasswordSchema,
  });

  const { handleSubmit } = formContext;

  const emailField = useField({
    name: 'email',
    formContext,
    defaultValue: otpVerifiedData?.loginId,
  });
  const passwordField = useField({ name: 'password', formContext, defaultValue: '' });

  useEffect(() => {
    // If no data from verify OTP step, user redirect to recover email page
    if (!selectedEmail) pageFlow.navigate(PAGE_PATH.RECOVER_EMAIL);
  }, [pageFlow, selectedEmail]);
  const [errorType, setErrorType] = useState<LoginError>();

  const onSubmit = useCallback(() => {
    if (email) {
      handleSubmit(async (data) => {
        const { password } = data as Required<EnterPasswordFormInputs>;
        const loginUserRequest: LoginUserRequest = { email, password };
        const { error, success } = await login(loginUserRequest);
        if (error) {
          setErrorType(error);
          if (error === 'locked_account') {
            queryClient.setQueryData(['validateUser'], null);
          }
        } else if (success) pageFlow.navigate(PAGE_PATH.DASHBOARD_INDEX);
      })();
    }
  }, [handleSubmit, login, pageFlow, email]);

  const isLockedAccount = validateData?.isLockedAccount;

  const pageHeader = 'Continue to log in to your account';

  const message = (
    <>
      The email and password you entered are incorrect. For your security, your account has been
      locked.{' '}
      <NavLink
        to={PAGE_PATH.RECOVER_PASSWORD}
        className={classes.navlink}
        trackingName='reset_account_link'
        trackingLabel='reset_account_continue'
        objectType='link'
      >
        Recover your account here
      </NavLink>{' '}
      to unlock it.
    </>
  );

  return (
    <AuthenticationCard onBack={handleBack}>
      {errorType === 'locked_account' ? (
        <NumTriesExceeded
          title='Account locked'
          message={message}
          subMessage='If you need help now, please contact us at the number below.'
        />
      ) : (
        <Form
          formProviderProps={formContext}
          onSubmit={onSubmit}
          name='enterPasswordForm'
          className={classes.root}
        >
          <Stack spacing={4}>
            <Stack spacing={2}>
              <h1>{pageHeader}</h1>
              {isLockedAccount && <p className={classes.subHeader}>Continue to log in.</p>}
            </Stack>
            {errorType === 'invalid_credentials' && (
              <AuthHelpBox title='Invalid login' subText='Update your password and log in' />
            )}
            <TextField
              {...emailField}
              label='Email'
              type='email'
              className={classes.passwordField}
              ariaDisabled
              disabled
            />
            <TextField
              {...passwordField}
              label='Password'
              type='password'
              trackingName='password'
              className={classes.passwordField}
            />
            <LoadingButton
              type='submit'
              className={classes.button}
              trackingName='log_in_button'
              trackingLabel='log_in'
            >
              LOG IN
            </LoadingButton>
            {!isLockedAccount && (
              <NavLink
                to={PAGE_PATH.RECOVER_EMAIL_RESET_PASSWORD}
                className={classes.navlink}
                trackingName='update_password_button'
                trackingLabel='update_password'
              >
                Forgot your password?
              </NavLink>
            )}
          </Stack>
        </Form>
      )}
    </AuthenticationCard>
  );
};
