import { memo } from 'react';

import { flagValues } from '@ecp/utils/flags';

import {
  getAddressByRefId,
  getProductLineFromPolicyResponse,
  isLegacyPolicy,
} from '@ecp/features/servicing/shared/state';
import type { UiPolicy } from '@ecp/features/servicing/shared/types';
import { buildCoverageItems, HomeCoverageMetadata } from '@ecp/features/shared/coverages';

import { buildFields, getMetadataProductKey } from '../../util/coveragesUtil';
import { CoveragesSubSection } from '../CoveragesSubSection';
import { deductiblesOrder } from './DeductiblesOrder';
import {
  groupHomeCoveragesMap,
  groupRentersCoveragesMap,
  homeCoveragesGroupList,
  homeCoveragesMap,
  rentersCoveragesGroupList,
  rentersCoveragesMap,
} from './homeCoverageMap';
import { groupAndSortCoverages, sortDeductibles } from './utils';
interface Props {
  policyData: UiPolicy;
}

export const HomeCoverages: React.FC<Props> = memo((props) => {
  const { policyData } = props;

  const productLine = getProductLineFromPolicyResponse(policyData);
  const isLegacy = isLegacyPolicy(productLine, policyData.policy.sourceSystemName);

  if (isLegacy && !flagValues.CLASSIC_COVERAGES_HOME) return null;

  const productKey = getMetadataProductKey(policyData);
  const policyCode = policyData.coverageOptions.at(0)?.policyTypeCode;
  const coveragesMap = policyCode === 'HO4' ? rentersCoveragesMap : homeCoveragesMap;
  const coveragesGroupList =
    policyCode === 'HO4' ? rentersCoveragesGroupList : homeCoveragesGroupList;
  const groupCoveragesMap = policyCode === 'HO4' ? groupRentersCoveragesMap : groupHomeCoveragesMap;

  const state = getAddressByRefId(policyData, policyData.policy.primaryAddressRefId)?.state;
  const coverages = policyData.coverageOptions[0].policyCoverages;

  const deductibleFields = buildFields(coverages ?? {}, coveragesMap, true, isLegacy);
  const limitFields = buildFields(
    coverages ?? {},
    coveragesMap,
    false,
    isLegacy,
    groupCoveragesMap,
  );

  let deductibleCoverageItems = buildCoverageItems(
    HomeCoverageMetadata[productKey],
    deductibleFields,
    state,
  );

  // Only AOP and Wind Hail should be shown for Legacy policies in Deductible section
  if (isLegacy) {
    const includedLegacyDeductibles = [
      'deductibles.deductible',
      'deductibles.windStorm',
      'deductibles.hurricane',
      'deductibles.WindstormDed_TX_WindHail',
      'deductibles.WindstormDed_AL_WindHail',
      'deductibles.WindstormDed_SC_WindHail',
    ];
    deductibleCoverageItems = deductibleCoverageItems.filter((deductibleCoverageItem) =>
      includedLegacyDeductibles.includes(deductibleCoverageItem.field.key),
    );
  }
  const sortedDeductibles = sortDeductibles(deductibleCoverageItems, deductiblesOrder);

  let limitCoverageItems = buildCoverageItems(HomeCoverageMetadata[productKey], limitFields, state);

  // Some of the coverages have to be hidden for Classic policies
  if (isLegacy) {
    const excludedLegacyCoverages = [
      'coverages.acvRoofCoverage',
      'coverages.includeServiceLine',
      'coverages.includeComputers',
      'coverages.includeEquipmentBreakdown',
    ];
    limitCoverageItems = limitCoverageItems.filter(
      (coverageItem) => !excludedLegacyCoverages.includes(coverageItem.field.key),
    );

    // Loss Assessment only applies to HO6 Connect policies
    if (policyCode !== 'HO6')
      limitCoverageItems = limitCoverageItems.filter(
        (coverageItem) => coverageItem.field.key !== 'coverages.lossAssessment',
      );
  }

  const groupedCoverages = groupAndSortCoverages(limitCoverageItems, groupCoveragesMap);

  return (
    <>
      <CoveragesSubSection coverageItems={sortedDeductibles} isDeductible title='Deductibles' />
      {coveragesGroupList.map((group) =>
        groupedCoverages?.[group] ? (
          <CoveragesSubSection key={group} coverageItems={groupedCoverages[group]} title={group} />
        ) : null,
      )}
    </>
  );
});
