/* eslint-disable @typescript-eslint/no-restricted-imports */
import { Link as LinkWouter } from 'wouter';
import type { LinkProps as LinkPropsWouter } from 'wouter';
import matcher from 'wouter/matcher';

export type LinkProps = LinkPropsWouter & React.RefAttributes<HTMLAnchorElement>;
export const Link: React.FC<LinkProps> = LinkWouter;

export const matcherWithCache = matcher();

export { Redirect, Route, Switch, Router, useRoute } from 'wouter';
export type { RouteProps } from 'wouter';
export { navigate, useSearch } from 'wouter/use-location';
