import { useCallback, useEffect } from 'react';

import { Stack } from '@mui/material';

import { Form, useField, useForm } from '@ecp/utils/form';

import { TextField } from '@ecp/components';
import {
  AuthenticationCard,
  LoadingButton,
  PasswordField,
} from '@ecp/features/servicing/shared/components';
import {
  navState,
  PAGE_PATH,
  QUERY_PARAMS_AND_VALUE,
  usePageFlow,
} from '@ecp/features/servicing/shared/routing';
import {
  useCreatePassword,
  useForgotPassword,
  useLogin,
  useSharedState,
  useValidateUser,
} from '@ecp/features/servicing/shared/state';
import type {
  CreatePasswordRequest,
  LoginUserRequest,
  VerifyOneTimeCodeSuccess,
} from '@ecp/features/servicing/shared/state';
import type { EmailOption, User } from '@ecp/features/servicing/shared/types';

import type { CreatePasswordFormInputs } from './CreatePassword.schema';
import { createPasswordSchema } from './CreatePassword.schema';
import { useStyles } from './CreatePasswordPage.styles';

export type CreatePasswordProps = {
  recoverEmail?: boolean;
  recoverPassword?: boolean;
};

export const CreatePasswordPage: React.FC<CreatePasswordProps> = (props) => {
  const { recoverEmail = false, recoverPassword = false } = props;
  const pageFlow = usePageFlow();
  const { classes } = useStyles();
  const [sharedOtpVerifiedData] = useSharedState<VerifyOneTimeCodeSuccess>('OtpVerifiedData');
  const [selectedEmail] = useSharedState<EmailOption>('SelectedEmail');

  const { validateData } = useValidateUser();
  const { createPassword } = useCreatePassword();
  const { forgotPassword } = useForgotPassword();
  const { login } = useLogin();

  useEffect(() => {
    // If user refresh the page and validateData is undefined user redirect to createAccount page
    if (!validateData) {
      if (recoverEmail) pageFlow.navigate(PAGE_PATH.RECOVER_EMAIL);
      else if (recoverPassword) pageFlow.navigate(PAGE_PATH.RECOVER_PASSWORD);
      else pageFlow.navigate(PAGE_PATH.CREATE_ACCOUNT);
    }
  }, [validateData, pageFlow, recoverEmail, recoverPassword]);

  const formContext = useForm({
    validations: createPasswordSchema,
  });
  const { handleSubmit, trigger } = formContext;
  const passwordField = useField({
    formContext,
    name: 'password',
  });

  const confirmPasswordField = useField({
    formContext,
    name: 'confirmPassword',
  });

  const emailUserId =
    recoverEmail || recoverPassword ? sharedOtpVerifiedData?.loginId : selectedEmail?.name;
  const emailField = useField({
    formContext,
    name: 'email',
    defaultValue: emailUserId,
  });

  const handleBlur = useCallback(() => {
    // checks for match if passwordField was edited after confirmPasswordField if filled out
    if (confirmPasswordField.value) {
      trigger('confirmPassword');
    }
  }, [trigger, confirmPasswordField]);

  const onSubmit = useCallback(() => {
    handleSubmit(async (data) => {
      if (validateData) {
        const { password } = data as Required<CreatePasswordFormInputs>;
        const createPasswordRequest: CreatePasswordRequest = {
          password: password,
          userInfo: validateData.userInfo.payload,
        };
        const { error, success } =
          recoverEmail || recoverPassword
            ? await forgotPassword(createPasswordRequest)
            : await createPassword(createPasswordRequest);
        if (error) {
          if (error === 'EXPIRED_USER_TOKEN') {
            pageFlow.navigate({
              pathname: PAGE_PATH.LOGIN,
              search: QUERY_PARAMS_AND_VALUE.TIMEOUT_TRUE,
            });
          }
        } else if (success) {
          const loginUserRequest: LoginUserRequest = {
            email:
              recoverEmail || recoverPassword
                ? sharedOtpVerifiedData?.loginId ?? ''
                : (success as User).email ?? '',
            password,
          };
          const { success: successLogin } = await login(loginUserRequest);

          if (successLogin) {
            if (recoverEmail || recoverPassword) pageFlow.navigate(PAGE_PATH.DASHBOARD_INDEX);
            else pageFlow.goForward({ pathName: PAGE_PATH.DASHBOARD_INDEX, state: navState });
          }
        }
      }
    })();
  }, [
    handleSubmit,
    pageFlow,
    createPassword,
    validateData,
    login,
    forgotPassword,
    recoverEmail,
    recoverPassword,
    sharedOtpVerifiedData,
  ]);

  const trackingLabel =
    validateData?.isLockedAccount || recoverEmail || recoverPassword
      ? 'reset_password'
      : 'create_account';

  const trackingName =
    validateData?.isLockedAccount || recoverEmail || recoverPassword
      ? 'reset_password_button'
      : 'create_account_button';

  const pageTitle =
    validateData?.isLockedAccount || recoverEmail || recoverPassword
      ? 'Reset your password'
      : 'Create your password';
  const body = (
    <Form
      formProviderProps={formContext}
      onSubmit={onSubmit}
      name='createPasswordForm'
      className={classes.root}
    >
      <Stack spacing={5}>
        <h1>{pageTitle}</h1>
        <Stack spacing={4.5}>
          <Stack spacing={4}>
            <TextField
              {...emailField}
              label='Email'
              type='email'
              className={classes.inputField}
              ariaDisabled
              disabled
            />
            <PasswordField
              {...passwordField}
              passwordRequirements={{ showRequirements: true }}
              label='Password'
              groupLabel=''
              onBlur={handleBlur}
              className={classes.inputField}
            />
            <TextField
              type='password'
              {...confirmPasswordField}
              label='Confirm password'
              className={classes.inputField}
            />
          </Stack>
          <LoadingButton
            type='submit'
            className={classes.button}
            trackingName={trackingName}
            trackingLabel={trackingLabel}
          >
            {validateData?.isLockedAccount || recoverEmail || recoverPassword
              ? 'RESET PASSWORD'
              : 'CREATE ACCOUNT'}
          </LoadingButton>
        </Stack>
      </Stack>
    </Form>
  );

  return <AuthenticationCard onBack={pageFlow.goBack}>{body}</AuthenticationCard>;
};
