import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'Modal' })((theme) => ({
  // base
  modal: {
    ...theme.mixins.shadow1,
    position: 'relative',
    outline: 'none',
    margin: '5vh auto',
    height: 'auto',
    width: 'calc(100% - 10px)',
    maxWidth: 600,
    maxHeight: '90vh',
    overflow: 'auto',
    borderRadius: 8,
    backgroundColor: theme.palette.info.contrastText,
    padding: 15,
  },
  modalSmall: {
    maxWidth: 345,
  },
  modalLarge: {
    maxWidth: 800,
  },
  // title bar
  titleBar: {
    paddingBottom: 5,
    borderColor: theme.palette.secondary.dark,
    borderWidth: 1,
    borderBottomStyle: 'solid',
    marginBottom: 15,
  },
  titleBarTitle: theme.typography.header1,
  titleBarCloseButton: {
    height: 'auto',
    padding: '0 !important',
    minWidth: 'auto',
    marginBottom: 10,
  },
  titleBarCloseButtonIcon: {
    fontSize: 14,
    padding: '0 !important',
  },
  // footer bar
  footerBar: {
    flexWrap: 'nowrap',
    marginTop: 30,
    '& > *:not(:first-child)': {
      marginLeft: 20,
    },
  },
}));
