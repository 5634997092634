import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'DatePicker' })((theme) => ({
  root: {},
  paper: {
    '& .MuiPaper-root': theme.mixins.shadow1,
    '& .MuiPickersToolbar-root': {
      backgroundColor: theme.palette.primary.main,
      padding: 20,
      '& .MuiPickersToolbar-penIconButton': {
        display: 'none',
      },
      '& .MuiDatePickerToolbar-title': {
        ...theme.typography.subtitle,
        color: theme.palette.primary.contrastText,
      },
    },
    '& .MuiCalendarPicker-root': {
      '& > *:first-of-type': {
        padding: 0,
        // hide year picker
        '& > *:first-of-type': {
          display: 'none',
        },
        '& .MuiPickersArrowSwitcher-spacer': {
          display: 'none',
        },
        '& .MuiPickersArrowSwitcher-root': {
          justifyContent: 'space-between',
          width: '100%',
        },
      },
      '& .MuiCalendarPicker-viewTransitionContainer': {
        '& *': theme.typography.body2,
        '& .MuiPickersDay-root': theme.typography.body1,
      },
      '& .MuiDialogActions-root': {
        display: 'none',
      },
    },
    '& .MuiDialogActions-root': {
      '& button:first-child': {
        ...theme.typography.button2,
        color: theme.palette.text.link,
        hover: {
          color: theme.palette.secondary.dark,
          backgroundColor: theme.palette.other.background,
          textDecoration: 'underline',
        },
      },
      '& button:last-child': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
      },
    },
  },
  iconAdornment: {
    marginRight: 0,
    ...theme.mixins.setColorSvg(theme.palette.primary.main),
  },
  monthPickerText: {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
    marginTop: 15,
  },
  pickerIcon: {
    '&:hover': {
      backgroundColor: `${theme.palette.primary.light} !important`,
    },
    ...theme.mixins.setColorSvg(theme.palette.primary.main),
    '&.Mui-disabled path': {
      fill: theme.palette.actions.disabledText,
    },
  },
  mobileCalendarIcon: {
    ...theme.mixins.setColorSvg(theme.palette.primary.main),
    padding: 13,
  },
}));
