import type { CSSObject } from '@ecp/themes/base';
import { makeStyles } from '@ecp/themes/base';

import type { RadioGroupProps } from './RadioGroup';

export const useStyles = makeStyles<
  RadioGroupProps & { cardCount: number },
  'small' | 'medium' | 'large'
>({
  name: 'RadioGroup',
})((theme, { label, helperText, variant, cardCount }, classes) => {
  /** Helper function to make sure there is no margin on the last row of radio cards */
  const lastRowMargin = (columnCount: number): CSSObject => {
    const lastRowCount = cardCount % columnCount;
    const result: CSSObject = {};

    for (let i = 1; i <= columnCount; i++) {
      if (lastRowCount >= i || lastRowCount === 0) {
        result[`&:nth-last-child(${i})`] = {
          marginBottom: 0,
          marginRight: i === 1 ? 0 : 12,
        };
      }
    }

    if (lastRowCount === 0) {
      result[`&:nth-last-child(${columnCount})`] = {
        marginBottom: 0,
      };
    }

    result[`&:nth-child(${columnCount}n)`] = {
      marginRight: 0,
    };

    return result;
  };

  const group = (): CSSObject => {
    switch (variant) {
      case 'largeRadioCard':
      case 'radioCard':
        return {
          '& > * + *': {
            marginTop: 8,
          },
        };
      case 'classic':
        return {};
      case 'buttonCard':
        return {
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
        };
      case 'buttonCardWithRadioButton':
        return {
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
        };
      default:
        return {
          '& > *': {
            margin: variant?.toLowerCase().includes('card') ? '0 0 10px' : '0',
            [theme.breakpoints.up('md')]: {
              marginRight: 12,
              '&:nth-last-child(1)': {
                marginRight: 0,
              },
            },
            [`.${classes.small}&`]: {
              [theme.breakpoints.between('md', 'xl')]: lastRowMargin(4),
              [theme.breakpoints.up('xl')]: lastRowMargin(5),
            },
            [`.${classes.medium}&`]: {
              [theme.breakpoints.up('md')]: lastRowMargin(4),
            },
            [theme.breakpoints.down('md')]: {
              marginRight: variant?.toLowerCase().includes('card') ? 0 : 12,
              '&:nth-last-child(1)': {
                marginBottom: 0,
                marginRight: 0,
              },
            },
          },
        };
    }
  };

  return {
    root: {
      '& .MuiFormHelperText-root.Mui-error': {
        marginTop: 10,
      },
    },
    group: group(),
    small: {},
    medium: {},
    large: {},
    label: {
      // When no label or helper text provided, add dummy legend tag for accessibility reasons and hide it
      ...(!(label || helperText) && theme.mixins.visuallyHidden),
      paddingTop: 0,
      paddingBottom: 15,
      width: '100%',
    },
    largeRadioLabel: { ...theme.typography.body3, paddingBottom: 4 },
    errorText: {
      marginTop: -6,
      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
      },
    },
    formControl: {},
    classicRoot: {
      pointerEvents: 'none',
      marginBottom: 15,
      '&:last-child': {
        marginBottom: 0,
      },
    },
    classicRadio: {
      pointerEvents: 'auto',
    },
    buttonCardRadio: {
      width: '33%',
    },
    control: {},
    classicLabel: {
      ...theme.typography.body1,
      marginRight: 10, // row display
      marginLeft: 12,
    },
    textTertiary: {
      color: theme.palette.text.tertiary,
    },
  };
});
