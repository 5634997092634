import { useCallback } from 'react';

import type { ButtonVariant } from '@ecp/components';
import { Button } from '@ecp/components';
import { PAGE_PATH, usePageFlow } from '@ecp/features/servicing/shared/routing';
import { IconUILogout } from '@ecp/themes/base';

import { useStyles } from './LoginLink.styles';

type Props = {
  icon?: React.ReactElement;
  variant?: ButtonVariant;
};

export const LoginLink: React.FC<Props> = (props) => {
  const { variant = 'iconTextMedium', icon } = props;
  const { classes } = useStyles();
  const buttonIcon = icon || <IconUILogout />;
  const { navigate } = usePageFlow();
  const handleLoginClick = useCallback(() => {
    navigate(PAGE_PATH.LOGIN);
  }, [navigate]);

  return (
    <Button
      icon={variant === 'text' ? undefined : buttonIcon}
      variant={variant}
      onClick={handleLoginClick}
      className={classes.root}
      trackingLabel='log_in'
      trackingName='log_in_button'
    >
      Log in
    </Button>
  );
};
