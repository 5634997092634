/**
 * A method to create URL for download PDF file from base64 string data.
 */
export const getFileUrl = (data: string): string => {
  const byteCharacters = atob(data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  const file = new Blob([byteArray], { type: 'application/pdf' });
  const url = URL.createObjectURL(file);

  return url;
};
