import { Stack } from '@mui/material';

import { useStyles } from './AutopayStatusIndicator.styles';

interface Props {
  isAutoPayOn: boolean | undefined;
}

export const AutopayStatusIndicator: React.FC<Props> = (props) => {
  const { isAutoPayOn } = props;
  const { classes, cx } = useStyles();

  return (
    <Stack direction='row' className={classes.root}>
      <div className={cx(classes.statusIndicator, isAutoPayOn && classes.statusIndicatorOn)} />
      <p className={classes.statusLabel}>
        AutoPay <span className={classes.status}>{isAutoPayOn ? 'ON' : 'OFF'}</span>
      </p>
    </Stack>
  );
};
