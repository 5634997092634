import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PaperlessEnrollReview' })((theme) => ({
  root: {},
  title: theme.typography.h3,
  formBody: {
    backgroundColor: theme.palette.grey[50],
    padding: 16,
  },
  signText: theme.typography.body1,
  submitButtton: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: 'fit-content',
    },
  },
}));
