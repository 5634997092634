import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'LegalCopy' })((theme) => ({
  root: {
    ...theme.typography.body1,
    border: `1px solid ${theme.palette.grey[600]}`,
    borderRadius: 4,
    overflowY: 'auto',
    maxHeight: 252,
    padding: 10,
    backgroundColor: theme.palette.grey[50],
  },
}));
