import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ServicingAccordion' })((theme) => ({
  root: {
    borderRadius: 0,
    boxShadow: 'none',
    width: '100%',
    '& .MuiAccordionSummary-expandIconWrapper': theme.mixins.setColorSvg(
      theme.palette.secondary.main,
    ),
    '& .Mui-disabled': { opacity: 'unset' },
  },
}));
