import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'Authentication' })((theme) => ({
  root: {
    alignContent: 'start',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.light,
  },

  disabledWrapper: {
    alignItems: 'center',
    paddingTop: theme.spacing(3),
  },

  wrapper: {
    '& .MuiPaper-rounded': {
      borderRadius: 5,
    },

    margin: `${theme.spacing(5)} ${theme.spacing(2)}`,
    maxWidth: 476,
    width: '100%',
  },
  withLoginLinkWrapper: {
    marginTop: 0,
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2.5),
    },
  },
  loginLink: {
    textAlign: 'right',
    marginRight: theme.spacing(5),
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2.5),
    },
  },
}));
