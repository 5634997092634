import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<void, 'active'>({ name: 'ServicingHeaderBody' })(
  (...[theme, , classes]) => ({
    root: {},
    active: {
      borderBottomColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
    button: {
      ...theme.mixins.setColorSvg(theme.palette.primary.main),
      borderBottom: '2px solid transparent',
      borderRadius: 0,
      color: theme.palette.primary.main,
      padding: '8px 0',
      '&:hover': {
        ...theme.mixins.setColorSvg(theme.palette.text.primary),
        color: theme.palette.text.primary,
        textDecoration: 'none',
        [`&.${classes.active}`]: { borderBottomColor: theme.palette.text.primary },
      },
    },
    gridIcons: {
      display: 'flex',
      order: 3,
      position: 'relative',
      marginLeft: 'auto',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
      height: 38,
    },
    gridMenu: {
      order: 2,
      flexGrow: 1,
      maxWidth: 900,
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        justifyContent: 'end',
      },
    },
  }),
);
