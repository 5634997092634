import { useState } from 'react';

import { useStyles } from './ScrollSpy.styles';
import { ScrollSpyItem } from './ScrollSpyItem';
import { useIntersectionObserver, useNestedHeadings } from './util';

interface Props {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  title: string;
  updateListDependency?: Array<boolean | string>;
  specialTitleNames?: Record<string, string>;
}

/**
 * This is a component that spies on current elements in viewport and creates a table content from headings
 * of elements, It will be able to scroll up/down to element by clicking by link in list.
 * Requires to apply Id and className using `ScrollSpyMarkerClasses` to elements that would be spied on.
 */
export const ScrollSpy: React.FC<Props> = (props) => {
  const { title, updateListDependency, specialTitleNames } = props;
  const [activeId, setActiveId] = useState('');

  const { classes, cx } = useStyles(undefined, { props });
  const [nestedHeadings, footers] = useNestedHeadings(updateListDependency, specialTitleNames);

  // Ids list to keep track of progress and current active Id
  const idsList: string[] = [];
  nestedHeadings.forEach((step) => {
    idsList.push(step.id);
    if (step.items.length) {
      step.items.forEach((substep) => idsList.push(substep.id));
    }
  });
  footers.forEach((step) => idsList.push(step.id));

  useIntersectionObserver(setActiveId, updateListDependency);
  const ScrollSpyTitleItem = { id: 'scrollSpyTitle', title, items: [] };

  return (
    <nav aria-label='scroll-spy' className={classes.root}>
      <ul className={classes.list}>
        <ScrollSpyItem
          {...ScrollSpyTitleItem}
          activeId={activeId}
          idsList={idsList}
          isNonClickable
        />
      </ul>
      <ul className={classes.list}>
        {nestedHeadings.map((item) => (
          <ScrollSpyItem {...item} activeId={activeId} idsList={idsList} key={item.id} />
        ))}
      </ul>
      <ul className={cx(classes.list, classes.footerWrapper)}>
        {footers.map((item) => (
          <ScrollSpyItem {...item} activeId={activeId} idsList={idsList} key={item.id} />
        ))}
      </ul>
    </nav>
  );
};
