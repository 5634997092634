import type { MuiRefTarget } from '@ecp/types';

/**
 * Scrolls to the specified `element` accounting for the header height.
 * Pass additionalOffset directly or through `data-offset` attribute, attached to the specified `element`.
 * Default additionalOffset = 30.
 * If passed a focusElement, it will also focus on that element after scrolling.
 */
export const scrollToElement = (props: {
  element: Element;
  additionalOffset?: number;
  focusElement?: HTMLElement | MuiRefTarget | null;
}): void => {
  if (!props.element) return;

  const {
    element,
    additionalOffset = element.getAttribute('data-offset') === undefined
      ? 30
      : Number(element.getAttribute('data-offset')),
    focusElement,
  } = props;

  const headerHeight = document.querySelector('header')?.offsetHeight || 0;

  // We can't scroll to negative position
  const top = Math.max(
    0,
    window.scrollY + element.getBoundingClientRect().top - headerHeight - additionalOffset,
  );

  // Focus the field after scrolling
  if (focusElement) {
    const onScroll = (): void => {
      if (window.pageYOffset.toFixed() === top.toFixed()) {
        window.removeEventListener('scroll', onScroll);
        // TODO: this is causing an issue with async custom form validation errors that trigger on complete
        // When the field focuses it forces the blur action to occur which triggers onComplete and if an async
        // custom validaiton is not fast enough to finish before the validation is triggered then it allows you
        // to go forward with invalid data in a field (specifically the VIN field on the Auto Delta page)
        focusElement?.focus();
      }
    };

    window.addEventListener('scroll', onScroll);
    // This call is added in the case of scroll position already being correct, in which the callback won't fire
    onScroll();
  }

  window.scroll({
    top,
    behavior: 'smooth',
  });
};

/** Prevents window scrolling. Use it when need to prevent unwanted default browser scrolling behavior. */
export const preventScroll = (): void => {
  const { scrollX: x, scrollY: y } = window;
  const scrollBack = (): void => {
    // Scroll window to the saved position.
    window.scroll(x, y);
    window.removeEventListener('scroll', scrollBack);
  };
  window.addEventListener('scroll', scrollBack);
};

export const scrollToTop = (): void => window.scroll({ top: 0, left: 0, behavior: 'smooth' });
