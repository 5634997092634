import { isPastDate } from '@ecp/utils/date';

import type { PolicySource, ProductLine, UiPolicy } from '@ecp/features/servicing/shared/types';

import type { BillingResponse } from '../billing';
import { getClassicBillingAccountStatusInPendingCancel } from './getClassicBillingAccountStatusInPendingCancel';
import { isLegacyPolicy } from './isLegacyPolicy';

export interface IsInPendingCancelStatusParams {
  billingResponse: BillingResponse | undefined;
  productLine: ProductLine | undefined;
  policySource: PolicySource | undefined;
  policyData?: UiPolicy;
}
export const isInPendingCancelStatus = ({
  billingResponse,
  productLine,
  policySource,
  policyData,
}: IsInPendingCancelStatusParams): boolean => {
  const isClassicBillingAccountStatusInPendingCancel =
    getClassicBillingAccountStatusInPendingCancel(policyData, billingResponse);

  if (isClassicBillingAccountStatusInPendingCancel) return true;
  else if (
    billingResponse &&
    billingResponse.dueDate !== undefined &&
    billingResponse.minimumDue !== undefined
  )
    return (
      isLegacyPolicy(productLine, policySource) &&
      isPastDate(billingResponse.dueDate) &&
      billingResponse.minimumDue > 0
    );
  else return false;
};
