import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PolicyCardHeader' })((theme) => ({
  root: {},
  policyType: { ...theme.typography.body4, alignSelf: 'center' },
  policyNumber: {
    ...theme.typography.body1,
    color: theme.palette.text.tertiary,
    alignSelf: 'center',
  },
  outerStack: {
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.up('lg')]: { alignItems: 'center' },
  },
  linkCta: {
    ...theme.typography.button2,
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
}));
