import { memo, useMemo } from 'react';

import { Divider, Stack } from '@mui/material';

import { unique } from '@ecp/utils/common';

import { DiscountsBlock } from '@ecp/features/shared/coverages';

import { useStyles } from './Discounts.styles';

interface ItemDiscounts {
  discounts: string[];
  title: string;
}

interface Props {
  discounts: string[];
  itemDiscountsTitle?: string;
  itemDiscountsList?: ItemDiscounts[];
}

export const Discounts: React.FC<Props> = memo((props) => {
  const { discounts, itemDiscountsList, itemDiscountsTitle } = props;
  const { classes } = useStyles();

  const discountsListItems = useMemo(
    () => unique(discounts.map((discount) => ({ title: discount ?? '' }))).sort(),
    [discounts],
  );

  return (
    <Stack spacing={2} divider={<Divider />}>
      <DiscountsBlock
        items={discountsListItems}
        classes={{ root: classes.root, item: classes.item, list: classes.list, svg: classes.svg }}
      />

      {!!itemDiscountsList?.length && (
        <div>
          {itemDiscountsTitle && <h2>{itemDiscountsTitle}</h2>}
          <Stack spacing={2} divider={<Divider />}>
            {itemDiscountsList.map(({ title, discounts }) => (
              <div>
                <h3>{title}</h3>
                <div>{discounts.join(', ')}</div>
              </div>
            ))}
          </Stack>
        </div>
      )}
    </Stack>
  );
});
