import { useParams } from 'react-router-dom';

import { TermsOfUseConnect } from './connect';

export const TermsOfUsePage: React.FC = () => {
  const { opco } = useParams();

  let termsOfUse;
  switch (opco) {
    case 'connect':
      termsOfUse = <TermsOfUseConnect />;
      break;
    default:
      termsOfUse = <p>Partner Specific Terms Of Use.</p>;
  }

  return termsOfUse;
};
