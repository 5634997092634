import { useCallback } from 'react';

import { Stack } from '@mui/material';

import { Button } from '@ecp/components';
import { CheckIcon } from '@ecp/features/servicing/shared/components';
import { useModalPath } from '@ecp/features/servicing/shared/routing';
import type { UseModalPathReturn } from '@ecp/features/servicing/shared/routing';
import { useDownloadDocument, useSharedState } from '@ecp/features/servicing/shared/state';
import type { AdditionalInterest } from '@ecp/features/servicing/shared/state';
import { IconUIPDFdownload } from '@ecp/themes/base';

import { useStyles } from './AdditionalInterestConfirmation.styles';

export const AdditionalInterestConfirmation: React.FC<UseModalPathReturn> = (props) => {
  const { policyNumber, sharedStateKey } = props;
  const { classes } = useStyles();
  const modalPath = useModalPath();
  const [addLandlordResponse] = useSharedState<AdditionalInterest>(sharedStateKey);

  const { downloadDocument: downloadEOIDocument, isLoading: isLoadingEOI } = useDownloadDocument({
    policyNumber,
  });

  const handleClose = useCallback(() => {
    modalPath.reset();
  }, [modalPath]);

  if (!addLandlordResponse?.name) throw Error('Name can not be undefined');

  return (
    <Stack spacing={4}>
      <CheckIcon />
      <h2>Your request was successfully submitted!</h2>

      <p>
        {addLandlordResponse.name} has been added to your renters policy. They will be notified by
        mail with your proof of insurance.
      </p>

      <Button
        variant='iconTextSmall'
        onClick={downloadEOIDocument}
        isProcessing={isLoadingEOI}
        className={classes.linkButton}
      >
        <IconUIPDFdownload className={classes.downloadIcon} />
        Download Proof of Insurance
      </Button>

      <Button className={classes.closeButton} variant='primary' onClick={handleClose}>
        CLOSE
      </Button>
    </Stack>
  );
};
