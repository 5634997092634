import type { CardProps as MuiCardProps } from '@mui/material';
import { CardActions, CardContent, Collapse, Divider, Card as MuiCard } from '@mui/material';

import { useStyles } from './Card.styles';

export interface CardProps extends MuiCardProps {
  banner?: React.ReactNode;
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  header?: React.ReactNode;
  body: React.ReactNode;
  footer?: React.ReactNode;
  divider?: boolean;
  headerDivider?: boolean;
  needExpand?: boolean;
  expand?: boolean;
  collapseDivider?: boolean;
}

export const Card: React.FC<CardProps> = (props) => {
  const {
    className,
    classes: _, // unused, just don't want to pass along in rest
    banner,
    header = null,
    body,
    footer = null,
    divider = true,
    headerDivider = false,
    needExpand = false,
    expand = false,
    collapseDivider = false,
    ...rest
  } = props;
  const { classes, cx } = useStyles(undefined, { props });

  const content = (
    <>
      <CardContent className={classes.content}>
        {header}
        {headerDivider && <Divider aria-hidden='true' className={classes.headerDivider} />}
        <div className={classes.body}>{body}</div>
      </CardContent>
      {divider && <Divider aria-hidden='true' className={classes.headerDivider} />}
      {footer && (
        <CardActions disableSpacing className={classes.footer}>
          {footer}
        </CardActions>
      )}
    </>
  );

  return (
    <MuiCard className={cx(classes.root, className)} {...rest}>
      {banner}
      {collapseDivider && <Divider aria-hidden='true' />}
      {needExpand && (
        <Collapse in={expand} timeout='auto' unmountOnExit>
          {content}
        </Collapse>
      )}
      {!needExpand && content}
    </MuiCard>
  );
};
