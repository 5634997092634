import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ScrollSpy' })((theme) => ({
  root: {
    position: 'sticky',
    left: 100,
    top: 145,
    height: 'fit-content',
    padding: 0,
    margin: 0,
  },
  list: {
    listStyle: 'none',
    paddingLeft: 0,
    margin: 0,
  },
  footerWrapper: {
    /** BorderTop created with boxShadow because border is not a whole width
    of element and paddings are 0px */
    boxShadow: `0px -15px 0px -14px ${theme.palette.other.divider}`,
  },
}));
