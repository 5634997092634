import { useCallback } from 'react';

import { flagValues } from '@ecp/utils/flags';

import { Button } from '@ecp/components';
import { env } from '@ecp/env';
import { DocumentLink } from '@ecp/features/servicing/shared/components';
import { PAGE_PATH, usePageFlow } from '@ecp/features/servicing/shared/routing';
import {
  getProductLineFromPolicyResponse,
  isLegacyPolicy,
} from '@ecp/features/servicing/shared/state';
import type { UiPolicy } from '@ecp/features/servicing/shared/types';
import { generateEncodedPath } from '@ecp/features/servicing/shared/util';

import { useStyles } from './PolicyIconSummaryCard.styles';

interface Props {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  policyDetails: UiPolicy | undefined;
}

export const DocumentButton: React.FC<Props> = (props) => {
  const { policyDetails } = props;
  const { classes } = useStyles(undefined, { props });
  const pageFlow = usePageFlow();
  const isClassicDocsSupported = !flagValues.DISABLE_DOCUMENTS_CLASSIC;
  const productLine = getProductLineFromPolicyResponse(policyDetails);
  const isHomeProduct = productLine === 'HOME';
  const getDocumentBtnText = isHomeProduct ? 'GET PROOF OF INSURANCE' : 'GET ID CARD';
  const isClassic = isLegacyPolicy(productLine, policyDetails?.policy.sourceSystemName);

  const handleClassicDocumentClick = useCallback(() => {
    if (isClassic && !isClassicDocsSupported) {
      window.open(
        productLine === 'HOME' ? `${env.legacyPolicyServiceUrl}home` : env.legacyPolicyServiceUrl,
        '_blank',
      );
    } else {
      pageFlow.navigate(
        generateEncodedPath(PAGE_PATH.DASHBOARD_POLICY_DOCUMENTS, {
          policyNumber: policyDetails?.policy.policyNumber,
        }),
      );
    }
  }, [
    isClassic,
    isClassicDocsSupported,
    pageFlow,
    productLine,
    policyDetails?.policy.policyNumber,
  ]);

  let documentButton;

  if (isClassic)
    documentButton = (
      <Button
        className={classes.button}
        variant='outlinePrimary'
        onClick={handleClassicDocumentClick}
        trackingName='get_id_link'
        trackingLabel='get_id'
        trackingDimensions={{ active_policy_number: policyDetails?.policy.policyNumber }}
        objectType='link'
      >
        {getDocumentBtnText}
      </Button>
    );
  else
    documentButton = (
      <DocumentLink
        classes={{ root: classes.button }}
        policyNumber={policyDetails?.policy.policyNumber}
        documentTitle={getDocumentBtnText}
        variant='outlinePrimary'
        trackingName='get_id_link'
        trackingLabel='get_id'
        objectType='link'
      />
    );

  return documentButton;
};
