import type { ChipProps } from '@mui/material';
import { Stack } from '@mui/material';

import { Alert } from '@ecp/components';
import type { CardProps } from '@ecp/features/servicing/shared/components';
import { Card } from '@ecp/features/servicing/shared/components';
import {
  getPolicyDisplayType,
  getProductLineFromPolicyResponse,
} from '@ecp/features/servicing/shared/state';
import type { PolicyTypeCode, ProductLine, UiPolicy } from '@ecp/features/servicing/shared/types';

import { PolicyCardHeader } from '../PolicyCardHeader';
import { useStyles } from './PolicyCard.styles';

interface PolicyCardBaseProps extends Omit<CardProps, 'divider' | 'header' | 'onClick'> {
  buttonText?: string;
  onClick?: (policyNumber: string) => void;
  alertsProps?: React.ComponentProps<typeof Alert>[];
  chipProps?: ChipProps;

  // has policy
  policyDetails?: UiPolicy;

  // no policy
  policyDisplayType?: string | undefined;
  policyNumber?: string | undefined;
  policyType?: PolicyTypeCode | undefined;
  productLine?: ProductLine | undefined;
}

interface PolicyCardWithPolicyProps extends PolicyCardBaseProps {
  policyDetails: UiPolicy | undefined;
}

interface PolicyCardWithNoPolicyProps extends PolicyCardBaseProps {
  policyNumber: string | undefined;
  policyDisplayType: string | undefined;
  policyType?: PolicyTypeCode | undefined;
  productLine?: ProductLine | undefined;
}

type PolicyCardProps = PolicyCardWithPolicyProps | PolicyCardWithNoPolicyProps;

export const PolicyCard: React.FC<PolicyCardProps> = (props) => {
  const {
    alertsProps,
    chipProps,
    buttonText,
    className,
    onClick,
    policyDetails,
    policyDisplayType: policyDisplayTypeProp,
    policyNumber: policyNumberProp,
    policyType: policyTypeProp,
    productLine: productLineProp,
    ...rest
  } = props;
  const { classes } = useStyles(undefined, { props });

  const policyDisplayType = policyDisplayTypeProp ?? getPolicyDisplayType(policyDetails);
  const policyNumber = policyNumberProp ?? policyDetails?.policy.policyNumber;
  const policyTypeCode = policyTypeProp ?? policyDetails?.coverageOptions?.at(0)?.policyTypeCode;
  const productLine = productLineProp ?? getProductLineFromPolicyResponse(policyDetails);

  return (
    <Card
      className={className}
      header={
        <Stack spacing={2}>
          {alertsProps && alertsProps.map((alertProps, key) => <Alert key={key} {...alertProps} />)}
          <PolicyCardHeader
            productLine={productLine}
            policyType={policyTypeCode}
            policyDisplayType={policyDisplayType}
            policyNumber={policyNumber}
            buttonText={buttonText}
            chipProps={chipProps}
            onButtonClick={onClick}
          />
        </Stack>
      }
      {...rest}
      classes={classes}
    />
  );
};
