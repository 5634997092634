import { useEffect } from 'react';

import { useInteractionId } from '@ecp/utils/analytics/interaction-id';
import { datadogRum } from '@ecp/utils/logger';

import * as hotjar from './hotjar';

type Props = {
  trackingId?: string;
  snippetVersion: string;
};

/** Install Hotjar and reinstall whenever trackingId changes. */
export const useHotjar = (props: Props): void => {
  const { trackingId, snippetVersion } = props;

  useEffect(() => {
    if (trackingId && snippetVersion) {
      hotjar.initialize(trackingId, snippetVersion);
      const datadogSessionId = datadogRum.getInternalContext()?.session_id;
      if (datadogSessionId) hotjar.setUserAttributes({ datadogSessionId });
    }

    // In agent experience need to tear down every time trackingId changes before reinstalling.
    return hotjar.teardown;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingId]);

  const interactionId = useInteractionId().get();
  useEffect(() => {
    if (interactionId && trackingId) hotjar.setUserAttributes({ interactionId });
  }, [interactionId, trackingId]);
};
