import { useCallback, useState } from 'react';

import { Stack } from '@mui/material';
import dayjs from 'dayjs';

import { Form, useField, useForm } from '@ecp/utils/form';

import { Alert, Button, DatePicker, TextField } from '@ecp/components';
import {
  LabelWithTooltip,
  LoadingButton,
  LoadingOverlay,
  ModalError,
} from '@ecp/features/servicing/shared/components';
import {
  type UseModalPathReturn as EditVinEditProps,
  QUERY_PARAMS,
} from '@ecp/features/servicing/shared/routing';
import type { UseUpdateVinReturn } from '@ecp/features/servicing/shared/state';
import {
  getVehicleDescription,
  usePolicy,
  useSharedState,
  useUpdateVin,
} from '@ecp/features/servicing/shared/state';

import { useStyles } from './EditVinEdit.styles';
import { vinUpdateSchema } from './vinUpdateSchema.schema';

const helpText =
  'The vehicle identification number (VIN) is a tracking number issued to vehicles by the manufacturer. It is usually located on the dashboard where the windshield and the dashboard meet';

export const EditVinEdit: React.FC<EditVinEditProps> = (props) => {
  const { goForward, policyNumber, reset, getSearchParam, sharedStateKey } = props;

  if (!policyNumber) {
    throw new Error('Cannot call vin api without policyNumber');
  }

  const { classes } = useStyles();
  const { isLoading: isPolicyLoading, policyData } = usePolicy(policyNumber);
  const { updateVin } = useUpdateVin();

  const vehicleId = getSearchParam(QUERY_PARAMS.VEHICLE_ID);
  const vehicle = policyData?.vehicles?.find((vehicle) => vehicle.vehicleId === vehicleId);

  const vehicleDescription = getVehicleDescription(vehicle);

  const formContext = useForm({
    validations: vinUpdateSchema,
  });
  const { handleSubmit, setValue } = formContext;
  const vinField = useField({ name: 'vinNumber', formContext });
  const dateOfChangeField = useField({
    name: 'effectiveDate',
    formContext,
    defaultValue: dayjs().format('YYYY-MM-DD'),
  });
  const [, setEditVinResponse] = useSharedState<UseUpdateVinReturn>(sharedStateKey);
  const [submitError, setSubmitError] = useState<boolean>();

  const handleVinChange = useCallback<
    NonNullable<React.ComponentProps<typeof TextField>['actionOnChange']>
  >((value) => setValue('vinNumber', value.trim().toUpperCase()), [setValue]);

  const onSubmit = useCallback(() => {
    handleSubmit(async (data) => {
      const { vinNumber, effectiveDate } = data;
      const { success, error } = await updateVin({
        policyNumber,
        vinNumber,
        effectiveDate,
      });
      if (success) {
        setEditVinResponse(success);
        goForward();
      } else if (error) {
        setSubmitError(true);
      }
    })();
  }, [goForward, handleSubmit, policyNumber, updateVin, setEditVinResponse]);

  if (submitError)
    return (
      <ModalError
        errorTitle='process your request at this time'
        errorDescription='processing your request'
      />
    );

  return isPolicyLoading ? (
    <LoadingOverlay />
  ) : (
    <Stack spacing={3} classes={classes.root}>
      <h1 id='edit-vin-edit-title'>Edit VIN</h1>
      <Alert withIcon type='warning'>
        Please note that updating your VIN online will not appear in your account until the next
        business day.
      </Alert>
      <Form onSubmit={onSubmit} formProviderProps={formContext}>
        <Stack spacing={3}>
          <Stack spacing={2} className={classes.contentCard}>
            <Stack spacing={1}>
              <h2>Request to edit vehicle identification number</h2>
              <div className={classes.vehicleName}>{vehicleDescription}</div>
            </Stack>
            <TextField
              {...vinField}
              className={classes.formField}
              actionOnChange={handleVinChange}
              label={
                <LabelWithTooltip label='Vehicle identification number (VIN)' title={helpText} />
              }
            />
            <div className={classes.formField}>
              <DatePicker
                {...dateOfChangeField}
                fullWidth
                label='Effective date of change'
                format='MM/DD/YYYY'
                maxDate={dayjs()}
                minDate={dayjs().subtract(30, 'day')}
              />
            </div>
          </Stack>
          <Stack direction={{ xs: 'column', md: 'row' }}>
            <LoadingButton variant='primary' type='submit' className={classes.button}>
              Submit request
            </LoadingButton>
            <Button variant='iconTextMedium' onClick={reset} className={classes.cancelButton}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Form>
    </Stack>
  );
};
