import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AdditionalInterestForm' })((theme) => ({
  root: {},
  button: {
    width: 'fit-content',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  cancelButton: {
    padding: '16px 25px',
  },
  formField: { maxWidth: 360 },
}));
