import { useCallback } from 'react';

import { Grid, Stack } from '@mui/material';

import { isTruthy } from '@ecp/utils/common';
import { DateConstants, formatDate } from '@ecp/utils/date';

import { Button } from '@ecp/components';
import { AddressDisplay, PolicyCard } from '@ecp/features/servicing/shared/components';
import { PAGE_PATH, usePageFlow } from '@ecp/features/servicing/shared/routing';
import {
  getDwellingAddress,
  getPersonByRefId,
  getProductLineFromPolicyResponse,
  getVehicleDescription,
  isInPendingCancelStatus,
  isLegacyPolicy,
  useBillingAccount,
  useBookRollPolicies,
} from '@ecp/features/servicing/shared/state';
import type { UiPolicy } from '@ecp/features/servicing/shared/types';
import { generateEncodedPath } from '@ecp/features/servicing/shared/util';
import {
  IconMaterialDesignMapsDirectionsCar,
  IconUICalendar,
  IconUIMap,
  IconUIPerson,
} from '@ecp/themes/base';

import { DocumentButton } from './DocumentButton';
import { getAlertProps } from './getAlertProps';
import { getChipProps } from './getChipProps';
import { useStyles } from './PolicyIconSummaryCard.styles';

interface Props {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  policyDetails: UiPolicy | undefined;
}

export const PolicyIconSummaryCard: React.FC<Props> = (props) => {
  const { policyDetails } = props;
  const { classes } = useStyles(undefined, { props });
  const bookRollPolicies = useBookRollPolicies();
  const convertedFromClassic = bookRollPolicies.bookRolledHomesitePolicies?.find(
    (policy) => policy.policyData.policy.policyNumber === policyDetails?.policy.policyNumber,
  );
  const productLine = getProductLineFromPolicyResponse(policyDetails);
  const isHomeProduct = productLine === 'HOME';
  const productSubIcon = isHomeProduct ? (
    <IconUIMap className={classes.icon} data-testid='icon-ui-map' />
  ) : (
    <IconMaterialDesignMapsDirectionsCar
      className={classes.icon}
      data-testid='icon-material-design-maps-directions-car'
    />
  );

  const addressOrCars = isHomeProduct ? (
    <AddressDisplay
      classes={{ text: classes.policyDetail }}
      policyAddress={getDwellingAddress(policyDetails)}
    />
  ) : (
    policyDetails?.vehicles?.map((vehicle) => (
      <span className={classes.policyDetail} key={vehicle.vehicleId}>
        {getVehicleDescription(vehicle)}
      </span>
    ))
  );

  const primaryInsuredRefId = policyDetails?.policy.primaryInsuredRefId;
  const includedDrivers = policyDetails?.drivers?.filter(
    (driver) => driver.driverStatus === 'INCLUDED',
  );
  const primaryDriver = [
    getPersonByRefId(
      policyDetails,
      includedDrivers?.find((driver) => driver.personRefId === primaryInsuredRefId)?.personRefId,
    ),
  ];
  const notPrimaryDrivers = includedDrivers
    ?.map((driver) => getPersonByRefId(policyDetails, driver.personRefId))
    ?.filter((person) => person?.personId !== primaryInsuredRefId)
    .filter(isTruthy);

  const sortedDrivers = primaryDriver.concat(notPrimaryDrivers);

  const policyPeople = isHomeProduct
    ? [
        getPersonByRefId(policyDetails, primaryInsuredRefId),
        getPersonByRefId(policyDetails, policyDetails?.policy.secondaryInsuredRefId),
      ].filter(isTruthy)
    : sortedDrivers;

  const policyNumber = policyDetails?.policy.policyNumber;
  const pageFlow = usePageFlow();
  const isClassic = isLegacyPolicy(productLine, policyDetails?.policy.sourceSystemName);
  const billingResponse = useBillingAccount(
    { policyNumber },
    { throwOnError: false },
  ).billingAccount;
  const isPendingCancelStatus = isInPendingCancelStatus({
    billingResponse,
    productLine,
    policySource: policyDetails?.policy.sourceSystemName,
    policyData: policyDetails,
  });

  const handleViewPolicyClick = useCallback(() => {
    pageFlow.navigate(
      generateEncodedPath(PAGE_PATH.DASHBOARD_POLICYDETAILS, {
        policyNumber,
      }),
    );
  }, [pageFlow, policyNumber]);

  const body = (
    <Stack spacing={2}>
      <Stack spacing={2.5}>
        <div>
          <Grid
            container
            className={classes.policyDetailsSection}
            columnSpacing={3}
            direction='row'
            rowSpacing={2}
          >
            <Grid item xs={12} lg={4}>
              <Stack spacing={1} direction='row'>
                {productSubIcon}
                <Stack>{addressOrCars}</Stack>
              </Stack>
            </Grid>

            <Grid className={classes.personSection} item xs={12} lg={4}>
              <Stack spacing={1} direction='row'>
                <IconUIPerson className={classes.icon} />
                <Stack>
                  {policyPeople?.map((person) => (
                    <span
                      className={classes.policyDetail}
                      key={`${policyDetails?.policy.policyNumber}-${person?.personId}`}
                    >
                      {person?.firstName} {person?.lastName}
                    </span>
                  ))}
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Stack spacing={1} direction='row'>
                <div>
                  <div className={classes.icon}>
                    <IconUICalendar className={classes.iconCalendar} />
                  </div>
                </div>
                <div>
                  <p className={classes.policyPeriod}>Policy period</p>
                  <p>
                    <span className={classes.date}>
                      {formatDate(
                        policyDetails?.policy.periodStartDate,
                        DateConstants.MONTH_FULL_NAME_LONG_FORMAT,
                      )}
                    </span>{' '}
                    -{' '}
                    <span className={classes.date}>
                      {formatDate(
                        policyDetails?.policy.periodEndDate,
                        DateConstants.MONTH_FULL_NAME_LONG_FORMAT,
                      )}
                    </span>
                  </p>
                </div>
              </Stack>
            </Grid>
          </Grid>
        </div>
        {!policyDetails?.isInactive && (
          <Stack spacing={1.5} direction={{ xs: 'column', lg: 'row' }}>
            <DocumentButton policyDetails={policyDetails} classes={classes} />
            <Button
              className={classes.button}
              variant='outlinePrimary'
              onClick={handleViewPolicyClick}
              trackingName='view_policy_link'
              trackingLabel='view_policy'
              trackingDimensions={{ active_policy_number: policyNumber }}
              objectType='link'
            >
              VIEW POLICY
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
  const bookrollInProgress =
    policyDetails?.isInBookRoll &&
    (policyDetails.policy.transition?.status === 'InProgress' ||
      (policyDetails.policy.transition?.status === 'Complete' &&
        !policyDetails.associatedPolicies?.[0]?.associatedPolicyNumber));

  const showInactiveAlert = !!(
    policyDetails?.isInactive &&
    (!policyDetails?.isInBookRoll || bookrollInProgress)
  );

  const alertProps = getAlertProps({
    policyDetails,
    classes,
    convertedFromClassic,
    showInactiveAlert,
  });

  const chipProps = getChipProps({
    convertedFromClassic,
    isClassic,
    isPendingCancelStatus,
    policyDetails,
    showInactiveAlert,
  });

  return (
    <PolicyCard
      className={classes.root}
      headerDivider
      policyDetails={policyDetails}
      body={body}
      alertsProps={alertProps}
      chipProps={chipProps}
    />
  );
};
