import { getCreditCardType } from '@ecp/utils/payment';

export const maskPaymentAccountNumber = (value: string, hideLength = 4): string => {
  const valLength = value.length;
  let index = 0;
  const cardType = getCreditCardType(value);
  if (valLength >= hideLength) {
    index = cardType === 'amex' ? valLength - 3 : valLength - 4;
  }

  return '*'.repeat(index) + value.substring(index);
};

export const maskMortgageLoanNumber = (value: string, hideLength = 4): string => {
  const valLength = value.length;
  const index = valLength > hideLength ? valLength - hideLength : 0;

  return '*'.repeat(index) + value.substring(index);
};

// for use when resetting a masked input
// returns last character of the input string, when input string is longer than the display string, "user enters a character"
// returns a ' ', when input string is not longer than the display string, "user entered a backspace"
export const resetMaskedPaymentAccountNumber = (
  inputString: string,
  displayString: string | undefined,
): string => {
  if (displayString && inputString.length > displayString?.length) {
    inputString = inputString[inputString.length - 1];
  } else {
    inputString = ' ';
  }

  return inputString;
};

export const maskEmail = (email: string | undefined): string => {
  if (!email) return '';

  const index = email.indexOf('@');
  if (index === -1) return '';

  // replace username part on * symbol exept first character
  const maskedEmail = email[0] + '*'.repeat(index - 1) + email.substring(index);

  return maskedEmail;
};

export const maskPIIData = (value: string): string => {
  const valLength = value.length;
  const index = valLength - 2;

  return '*'.repeat(index) + value.substring(index);
};
