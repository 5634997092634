import { useCallback } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import type { FallbackProps } from 'react-error-boundary';

import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { FullScreenModal, ModalErrorFallback } from '@ecp/features/servicing/shared/components';
import { MODAL_FLOW, MODAL_STEP, useModalPath } from '@ecp/features/servicing/shared/routing';
import {
  getProductLineFromPolicyResponse,
  isLegacyPolicy,
  usePolicy,
  useSharedState,
} from '@ecp/features/servicing/shared/state';

import { CaMileageConfirmation } from '../CaMileageConfirmation';
import { CaMileageEdit } from '../CaMileageEdit';
import { CaMileageReview } from '../CaMileageReview';
import { useStyles } from './CaMileageModal.styles';

const CaMileageModalContent: React.FC = () => {
  const { classes } = useStyles();
  const modalPath = useModalPath();

  const title = 'California Mileage Form';
  const isBackButton = false;
  let body;

  const [sharedState] = useSharedState(modalPath.sharedStateKey);

  const ensureSharedState = useCallback(() => {
    // ensure if we are expecting shared state, but we don't have it, we reset to start of flow
    if (!sharedState && modalPath.policyNumber) {
      modalPath.init(modalPath.policyNumber, MODAL_FLOW.CA_MILEAGE, MODAL_STEP.MODAL_EDIT);
    }

    return !!sharedState;
  }, [modalPath, sharedState]);

  switch (modalPath.currentStep) {
    case MODAL_STEP.MODAL_CONFIRMATION:
      body = ensureSharedState() ? <CaMileageConfirmation {...modalPath} /> : null;
      break;
    case MODAL_STEP.MODAL_EDIT:
      body = <CaMileageEdit {...modalPath} />;
      break;
    case MODAL_STEP.MODAL_REVIEW:
      body = ensureSharedState() ? <CaMileageReview {...modalPath} /> : null;
      break;
    default:
      if (modalPath.policyNumber && modalPath.currentFlow) {
        modalPath.init(modalPath.policyNumber, modalPath.currentFlow, 'edit');
      }
  }

  const getFallbackComponent = useCallback<NonNullable<React.ComponentType<FallbackProps>>>(
    (props: FallbackProps) => (
      <ModalErrorFallback
        reset={modalPath.reset}
        errorDescription='We are unable to verify your California Mileage'
      />
    ),
    [modalPath.reset],
  );

  const isEditStep = modalPath.currentStep === 'edit';

  return (
    <FullScreenModal
      title={title}
      isBackButton={isBackButton}
      onClickBack={modalPath.goBack}
      onCloseModal={modalPath.reset}
      classes={{ content: isEditStep ? classes.modalContent : undefined }}
    >
      <ErrorBoundary FallbackComponent={getFallbackComponent}>{body}</ErrorBoundary>
    </FullScreenModal>
  );
};

const CaMileageModalIfEligible: React.FC = () => {
  const modalPath = useModalPath();
  const policyResponse = usePolicy(modalPath.policyNumber, { throwOnError: false });
  const productLine = getProductLineFromPolicyResponse(policyResponse.policyData);
  const isClassic = isLegacyPolicy(productLine, policyResponse.policyData?.policy.sourceSystemName);

  if (flagValues[FeatureFlags.CA_MILEAGE] && !!modalPath.policyNumber && isClassic)
    return <CaMileageModalContent />;
  else return null;
};

export const CaMileageModal: React.FC = () => {
  const modalPath = useModalPath();

  if (modalPath.currentFlow === MODAL_FLOW.CA_MILEAGE) return <CaMileageModalIfEligible />;
  else return null;
};
