import { Stack } from '@mui/material';

import { IconUIExclaimTriangle } from '@ecp/themes/base';

import { CloseButtonNAgentHelp } from '../CloseButtonNAgentHelp';
import { useStyles } from './ModalError.styles';

export interface ModalErrorProps {
  errorTitle: string;
  errorDescription: React.ReactNode;
}

export const ModalError: React.FC<ModalErrorProps> = (props) => {
  const { errorTitle, errorDescription } = props;
  const { classes } = useStyles();

  return (
    <Stack spacing={3}>
      <div className={classes.errorIconWrapper}>
        <IconUIExclaimTriangle className={classes.icon} />
      </div>
      <h2>We were unable to {errorTitle}</h2>
      <p className={classes.text}>There was an error {errorDescription}. Please try again later.</p>
      <CloseButtonNAgentHelp />
    </Stack>
  );
};
