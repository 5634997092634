import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PrivacyPolicyHomesite' })((theme) => ({
  root: {},
  boldText: theme.typography.body1Bold,
  italicText: theme.typography.body1Italics,
  thinHeader: theme.typography.body4,
  orderedListAlphabetical: {
    listStyleType: 'lower-alpha',
  },
  withListInside: {
    listStyleType: 'none',
  },
  links: theme.typography.body1Underline,
  indentedParagraph: { paddingLeft: 20 },
  paragraphWithPadding: { paddingLeft: 20, paddingTop: 20 },
  listItemTitle: theme.typography.body3BoldItalics,
}));
