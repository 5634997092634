import { useEffect, useState } from 'react';

import { instance as userAuth } from './userAuth';

/**
 * Update a component whenever `login` and `logout` events dispatch.
 * Note: Will not update in instances where we don't read auth token (directly or indirectly via fetch calls),
 * like in `libs/features/servicing/landing/src/pages/claims/components/ClaimsIframe/ClaimsIframe.tsx`.
 * Will be addressed in https://theexperimentationlab.atlassian.net/browse/ECP-2182
 */
export const useUserAuth = (): void => {
  const [, setForceUpdate] = useState(false);
  useEffect(() => {
    const listener = (): void => {
      setForceUpdate(true);
      setTimeout(() => setForceUpdate(false), 0);
    };
    userAuth?.addEventListener('login', listener);
    userAuth?.addEventListener('logout', listener);

    return () => {
      userAuth?.removeEventListener('login', listener);
      userAuth?.removeEventListener('logout', listener);
    };
  }, []);
};
