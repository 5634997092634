import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'DevDiagnostic' })((theme) => ({
  root: {
    backgroundColor: theme.palette.error.light,
    '& p': {
      color: theme.palette.error.main,
      fontFamily: 'monospace',
      wordWrap: 'break-word',
      wordBreak: 'break-all',
    },
  },
  expandIcon: theme.mixins.setColorSvg(theme.palette.error.main),
}));
