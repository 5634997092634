/** @deprecated Revisit applicability */
export const enum GoogleAnalyticsEventActions {
  START_TRANSITION = 'startTransition',
  START_LOAD = 'startLoad',
  END_LOAD = 'endLoad',
}

/** @deprecated Revisit applicability */
export const enum GoogleAnalyticsEventCategories {
  PAGE_TRANSITION_TIMING = 'Page Transition Timing',
  PAGE_TRANSITION_DURATION = 'Page Transition Duration',
}

export const enum GoogleAnalyticsLabels {
  REDACTED = 'REDACTED',
  CONTINUE = 'continue',
  FOOTER = 'Footer',
}
